import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Footer from "../ptsComponentes/footer/Footer";
import ApplicationLayoutHeader from "../ptsComponentes/header/ApplicationLayoutHeader";
import Page from "../ptsComponentes/page/Page";
import PageContent from "../ptsComponentes/pageContent/PageContent";
import PageHeader from "../ptsComponentes/pageHeader/PageHeader";
import routes from "../ptsComponentes/views";

export class SearchPageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="app-body">
          <ApplicationLayoutHeader
            history={this.props.history}
            {...this.props}
            ref={this.headerRef}
            routes={routes}
          ></ApplicationLayoutHeader>
          <PageHeader
            history={this.props.history}
            {...this.props}
            ref={this.headerRef}
            routes={routes}
            history={this.props}
          />
          <Page>
            <PageContent style={{ minHeight: "100vh" }}>
              <Switch>
                {routes.map((page, key) => (
                  <Route
                    path={page.path}
                    component={page.component}
                    key={key}
                  />
                ))}
                <Redirect to="/home" />
              </Switch>
            </PageContent>
          </Page>

          <Footer></Footer>
        </div>
      </div>
    );
  }
}
export default withRouter(SearchPageLayout);
