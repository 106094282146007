import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import { countries } from "country-data";
import React, { Component } from "react";
import { withRouter } from "react-router";
import "react-slideshow-image/dist/styles.css";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import advanceFilterAction from "../../redux/advanceFilter/advanceFilterAction";
import filterSectorAction from "../../redux/filterSector/filterSectorAction";
import staticDataAction from "../../redux/staticData/staticDataAction";
import store from "../../redux/store";
import userSelectedIndustriesAction from "../../redux/userSelectedIndustries/userSelectedIndustriesAction";
import userSelectedRegionAction from "../../redux/userSelectedRegion/userSelectedRegionAction";
import GenericButton from "../buttons/GenericButton";
import "./SearchWithResult.css";

class SearchWithResult extends Component {
  countryList = store.getState().staticData
    ? store.getState().staticData.countryCodesForFlag
    : [];

  constructor(props) {
    super(props);
    let countries = Utils.getCountryData();
    if ((!this.countryList || this.countryList.length <= 0) && countries) {
      store.dispatch(staticDataAction.setCountryCodeForFlag(countries));
    }
    let pageId = "";
    if (props.pageId === "/home") {
      pageId = "/home";
    }

    this.state = {
      isAdvanceSearch: false,
      color: props.color,
      pageId: pageId,
      isOpen: false,
      searchCompany: "",
      searchResult: [],
      displayList: false,
      searchText: "",
      textLength: "",
      oldSearchText: "",
    };
    window.scrollTo(0, 0);
    this.PtsAutocomplateRef = React.createRef();
  }

  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    if (
      rowData.country.trim().toUpperCase() === "KOREA (SOUTH)" ||
      rowData.countryCode === "410"
    ) {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/KR/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VENEZUELA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/VE/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "TANZANIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/TZ/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "PALESTINE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/PS/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "MACEDONIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/MK/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "BOLIVIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/BO/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "UNITED KINGDOM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/GB/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "COTE D'IVOIRE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/CI/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VIETNAM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/VN/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/${rowData.codeCountry}/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    }
    return getCountryWithFlag;
  }

  userEnterSearch = (userEnterText) => {
    if (userEnterText.length > 0) {
      const postObject = {
        searchText: userEnterText.toUpperCase(),
      };
      FetchServerData.callPostService("searchService/search", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let searchResult = [];
            searchResult = output.data.result;
            if (searchResult === null) {
              this.setState({
                searchResult: [],
                displayList: false,
              });
            } else {
              for (let i = 0; i < this.countryList.length; i++) {
                for (let j = 0; j < searchResult.length; j++) {
                  if (
                    Utils.equalsIgnoreCase(
                      this.countryList[i].name,
                      searchResult[j].country
                    )
                  ) {
                    searchResult[j].codeCountry = this.countryList[i].alpha2;
                  }
                }
              }

              this.setState({
                searchResult: searchResult,
                displayList: true,
                searchText: userEnterText,
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    }
  };
  handleOnHover = (result) => {
    // the item hovered
  };

  handleOnSelect = (item) => {
    // the item selected
    this.toCompanyDetails(item);
  };
  toCompanyDetails = (e, companyData) => {
    if (this.props.history.location.pathname === "/company") {
      window.location.href = "#/companysnapdetails?" + companyData.cusip;
      // this.props.history.push({
      //   pathname: "/companysnapdetails",
      //   state: {
      //     data: companyData,
      //     pageId: "company_Search",
      //     cusip: companyData.cusip,
      //   },
      // });
    } else {
      window.location.href = "#/company?" + companyData.cusip;
      // this.props.history.push({
      //   pathname: "/company",
      //   state: {
      //     data: companyData,
      //     pageId: "company_Search",
      //     cusip: companyData.cusip,
      //   },
      // });
    }
  };
  handleOnFocus = () => {};

  // formatResult = (item) => {
  //   let searchOption = "";
  //   for (let i = 0; i < this.state.searchResult.length; i++) {
  //     if (item === this.state.searchResult[i].companyName) {
  //       searchOption = (
  //         <>
  //           {this.getCountryWithFlag(this.state.searchResult[i])}
  //           <span style={{ fontSize: "0.8em" }}>
  //             {item}{" "}
  //             <span style={{ fontWeight: "bold" }}>
  //               {" - " + "(" + this.state.searchResult[i].ticker + ")"}
  //             </span>
  //           </span>
  //         </>
  //       );
  //       return searchOption;
  //     }
  //   }
  // };
  performAdvanceSearch() {
    if (this.props.history) {
      this.props.history.push({
        pathname: "/search",
        state: {
          searchText: this.state.searchText,
          searchType: "advance search",
        },
      });
    }
  }
  getAdvanceSearch() {
    if (this.state.color === "white") {
      return (
        <a
          style={{
            cursor: "pointer",
            fontSize: "small",
            color: "#fff",

            textAlign: "center",
            outline: "none",
            width: "100%",
            margin: "auto",
          }}
          onClick={this.performAdvanceSearch.bind(this)}
        >
          {" "}
          &nbsp;&nbsp;
          <strong>
            <i>
              <u>Advanced Search</u>
            </i>
          </strong>
        </a>
      );
    } else {
      return (
        <a
          style={{
            cursor: "pointer",
            fontSize: "small",
            color: "#F26D21",

            textAlign: "right",
            outline: "none",
            width: "100%",
            marginTop: "1em",
          }}
          onClick={this.performAdvanceSearch.bind(this)}
        >
          {" "}
          &nbsp;&nbsp;
          <strong>
            <i>
              <u>Advanced Search</u>
            </i>
          </strong>
        </a>
      );
    }
  }

  performSearch(e) {
    if (this.state.searchText && this.state.searchText.length > 0) {
      this.props.history.push({
        pathname: "/search",
        state: {
          searchText: this.state.searchText,
          searchType: "simple search",
        },
      });
    }
  }
  handleSearchTextChange(e) {
    store.dispatch(advanceFilterAction.setAdvanceFilter({}));

    store.dispatch(userSelectedRegionAction.setRegionFilter([]));
    store.dispatch(filterSectorAction.setSectorFilter([]));
    store.dispatch(userSelectedIndustriesAction.setIndustryFilter([]));
    this.setState({
      searchText: e.target.value,
    });
    e.stopPropagation();
    if (e.charCode === 13) {
      /*Enter key pressed */
      if (this.state.searchText && this.state.searchText.length > 0) {
        this.props.history.push({
          pathname: "/search",
          state: {
            searchText: this.state.searchText,
            searchType: "simple search",
          },
        });
      }
      return;
    }

    if (
      this.state.oldSearchText.toString().length > 0 &&
      e.target.value.toString() === this.state.oldSearchText
    ) {
      return;
    }
    if (e.target.value.toString().length === 0) {
      this.setState({
        displayList: false,
        searchResult: [],
      });
      this.userEnterSearch(e.target.value);
    } else {
      this.setState({ oldSearchText: e.target.value });
      this.userEnterSearch(e.target.value);
    }
  }
  // handleSearchTextChange(e) {
  //   store.dispatch(advanceFilterAction.setAdvanceFilter({}));

  //   store.dispatch(userSelectedRegionAction.setRegionFilter([]));
  //   store.dispatch(filterSectorAction.setSectorFilter([]));
  //   store.dispatch(userSelectedIndustriesAction.setIndustryFilter([]));
  //   this.setState({
  //     searchText: e.target.value,
  //   });
  //   e.stopPropagation();
  //   if (e.charCode === 13) {
  //     /*Enter key pressed */
  //     if (this.state.searchText && this.state.searchText.length > 0) {
  //       this.props.history.push({
  //         pathname: "/search",
  //         state: {
  //           searchText: this.state.searchText,
  //           searchType: "simple search",
  //         },
  //       });
  //     }
  //     return;
  //   }

  //   if (
  //     this.state.oldSearchText.toString().length > 0 &&
  //     e.target.value.toString() === this.state.oldSearchText
  //   ) {
  //     return;
  //   }

  //   if (e.target.value.toString().length === 0) {
  //     this.setState({
  //       displayList: false,
  //     });
  //   } else {
  //     this.setState({ oldSearchText: e.target.value });
  //     this.userEnterSearch(e.target.value);
  //   }
  // }
  handlePopoverClose() {
    this.setState({ displayList: false });
  }
  onMouseEnterShow() {
    if (this.state.searchText.length === 0) {
      this.setState({ searchResult: [] });
    }
    if (this.state.searchResult.length > 0) {
      this.setState({ displayList: true });
    } else {
      this.setState({ displayList: false });
    }
  }
  render() {
    let searchCompanyList = [];
    this.state.searchResult.forEach((element, index) => {
      searchCompanyList.push(
        <>
          <li
            style={{
              borderBottom: "1px solid gray",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={(e) => this.toCompanyDetails(e, element)}
          >
            {Utils.getCountryFlag(element.country)}
            <span style={{ fontSize: "0.8em" }}>
              {" " + element.companyName}{" "}
              <span style={{ fontWeight: "bold" }}>
                {" - " + element.ticker}
              </span>
            </span>
          </li>
        </>
      );
    });

    if (this.state.pageId === "/home") {
      return (
        <>
          <Grid container>
            <Grid item className="generic_search_box_home">
              <input
                type="text"
                id="searchText"
                name="searchText"
                autocomplete="off"
                placeholder="Company Name or Ticker"
                style={{
                  border: "1px solid #566573",
                  width: "100%",
                  borderRadius: "0",
                  fontSize: "small",
                  outline: "none",
                  height: "2.5em",
                }}
                pattern="[^\s]+"
                required
                value={this.state.searchText}
                onChange={this.handleSearchTextChange.bind(this)}
                onKeyPress={this.handleSearchTextChange.bind(this)}
                // onMouseOut={this.handlePopoverClose.bind(this)}
                // onMouseEnter={this.onMouseEnterShow.bind(this)}
                // onClick={this.handlePopoverClose.bind(this)}
              />
              <br></br>

              {this.state.displayList && (
                <div className="company_list_box_home">
                  <ul style={{ marginLeft: "-2em", marginBottom: "0" }}>
                    {searchCompanyList}
                  </ul>
                </div>
              )}
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "10%" }}
            >
              <GenericButton
                tooltip="Search"
                pageId="ciHome"
                onClick={this.performSearch.bind(this)}
                // pageId={this.state.pageId}
                icon={<SearchIcon />}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              className="gridSearchClass"
              // style={{ display: "flex", width: "30%" }}
            >
              {this.getAdvanceSearch()}
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container>
            <Grid item className="generic_search_box header_search">
              <input
                type="text"
                id="searchText"
                name="searchText"
                autocomplete="off"
                placeholder="Company Name or Ticker"
                style={{
                  border: "1px solid #566573",
                  width: "100%",

                  borderRadius: "0",
                  fontSize: "small",
                  outline: "none",
                  height: "2em",
                }}
                required
                onChange={this.handleSearchTextChange.bind(this)}
                onKeyPress={this.handleSearchTextChange.bind(this)}
                // onMouseOut={this.handlePopoverClose.bind(this)}
                // onMouseEnter={this.onMouseEnterShow.bind(this)}
              />
              <br></br>

              {this.state.displayList && (
                <div className="company_list_box_header">
                  <ul style={{ marginLeft: "-2em", marginBottom: "0" }}>
                    {searchCompanyList}
                  </ul>
                </div>
              )}
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "10%" }}
            >
              <GenericButton
                tooltip="Search"
                onClick={this.performSearch.bind(this)}
                pageId="header"
                icon={<SearchIcon />}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              className="gridSearchClass"
              // style={{ display: "flex", width: "30%" }}
            >
              {this.getAdvanceSearch()}
            </Grid>
          </Grid>
        </>
      );
    }
  }
}
export default withRouter(SearchWithResult);
