import React, { Component } from "react";
import "./CompanyQualityRating.css";
import Container from "@material-ui/core/Container";

export default class CompanyQualityRating extends Component {
  render() {
    return (
      <>
        <Container className="conCompQua">
          <section className="secCompQua">
            <div className="headTxt">
              <h2>Company Quality Rating - Explanation</h2>
            </div>

            <div>
              <p>
                Since 1970, Wright Investors' Service has rated all of the
                companies in its database. The following chart provides details
                about the rating system and how each company is rated on four
                criteria, and what the minimum criteria are for being listed on
                the Approved Wright Investment List (AWIL).
              </p>
              <p>
                Wright Quality Ratings are based on established principles using
                5-6 years of corporate record and other investment data. The
                ratings consist of three letters and a number. The letters
                indicate investment acceptance, financial strength, and
                profitability and stablity, and represent a composite
                qualitative measurement of individual standards which may be
                summarized as follows: A = Outstanding; B = Excellent; C = Good;
                D = Fair; L = Limited; N = Not Rated. The Growth rating may vary
                from 0 (lowest) to 20 (highest), and is a composite measurement
                of the annual corporate growth, based on earnings and modified
                by growth rates of equity, dividends, and sales per common
                share.
              </p>

              <table className="tableRatingcss" cellPadding="0" cellSpacing="0">
                <tr>
                  <th>Investment Acceptance</th>
                  <th>Financial Strength</th>
                  <th>Profitability & Stability</th>
                  <th>Growth</th>
                </tr>

                <tr className="trHead">
                  <td>A</td>
                  <td>A</td>
                  <td>A</td>
                  <td>20</td>
                </tr>
                <tr className="trAWIL">
                  <td>AWIL Minimum: B</td>
                  <td>AWIL Minimum: B</td>
                  <td>AWIL Minimum: B</td>
                  <td>Normal Minimum: 6</td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Total Market Value</h6>
                      <p>All Shares Outstanding 3 Year Average Current</p>
                      <p>
                        <a href="#row11" className="anchorCss">
                          103.10
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>$1000 mil</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Total Shareholders' Equity</h6>
                      <p>Capital & Surplus</p>
                      <p>
                        <a href="#row12" className="anchorCss">
                          104.10
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: $150 mil</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>
                        Profit Rate of Earnings on Equity Capital Time-Wtd
                        Normal
                      </h6>
                      <p>Adjusted Rate1</p>
                      <p>
                        <a href="#row13" className="anchorCss">
                          105.10
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>11%</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Normal Earn Growth</h6>
                      <p>Adjusted Annual Rate Per Share1</p>
                      <p>
                        <a href="#row14" className="anchorCss">
                          106.10
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>+6%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Public Market Value</h6>
                      <p>Excluding Closely Held 3 Year Average Current</p>
                      <p>
                        <a href="#row21" className="anchorCss">
                          103.11
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>$1000 mil</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Shareholder's Equity as % of Capital</h6>
                      <p>Equity, Pfd Stock & Long Term Debt</p>
                      <p>
                        <a href="#row22" className="anchorCss">
                          104.11-104.12
                        </a>
                      </p>
                      <p>AWIL Min: 50%</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Profit Rate of Earnings on Equity Capital</h6>
                      <p>Adjusted Annual Rate1</p>
                      <p>
                        <a href="#row23" className="anchorCss">
                          105.11
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>No Significant Decline</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Cash Earn Per Share Growth</h6>
                      <p>Adjusted Annual Rate Per Share</p>
                      <p>
                        <a href="#row24" className="anchorCss">
                          106.20
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>+6%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Trading Volume</h6>
                      <p>3 Year Average Last 12 Months</p>
                      <p>
                        <a href="#row31" className="anchorCss">
                          103.20
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: $250 mil Varies with Exchange</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Preferred Stock</h6>
                      <p>% of Capital Equity, Pfd Stock & Long Term Debt</p>
                      <p>
                        <a href="#row32" className="anchorCss">
                          104.20-104.23
                        </a>
                      </p>
                      <p>AWIL Max: Depends</p>
                      <p>on Capital Structure</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>
                        Cash Earn Return on Equity Time-Wtd Average & Trend
                      </h6>
                      <p>Adjusted Annual Rates</p>
                      <p>
                        <a href="#row33" className="anchorCss">
                          105.20
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum: 20%; Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Cash Earn Per Share Stability Index</h6>
                      {/* <p>Adjusted Annual Rates</p> */}
                      <p>
                        <a href="#row34" className="anchorCss">
                          106.12
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: 60%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Turnover Rate</h6>
                      <p>3 Year Average Current</p>
                      <p>
                        <a href="#row41" className="anchorCss">
                          103.30
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum: 25%</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Long Term Debt</h6>
                      <p>% of Capital Equity, Pfd Stock & Long Term Debt</p>
                      <p>
                        <a href="#row42" className="anchorCss">
                          104.30-104.33
                        </a>
                      </p>
                      <p>AWIL Max: 50%</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Cash Earn Return on Equity</h6>
                      <p>Stability Index</p>
                      <p>
                        <a href="#row43" className="anchorCss">
                          105.22
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum: 60%</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Earned Equity Growth</h6>
                      <p>Adjusted Annual Rate Per Share1</p>
                      <p>
                        <a href="#row44" className="anchorCss">
                          106.30
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>+6%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Exchanges/ Markets</h6>
                      {/* <p>Adjusted Annual Rate Per Share1</p> */}
                      <p>
                        <a href="#row51" className="anchorCss">
                          103.40
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>NYSE</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Total Debt % of Capital</h6>
                      <p>Equity, Pfd Stock & Long Term Debt</p>
                      <p>
                        <a href="#row52" className="anchorCss">
                          104.40-104.42
                        </a>
                      </p>
                      <p>AWIL Pref Max: 60%</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Return On Assets Time-Wtd Average</h6>
                      <p>Annual Rate</p>
                      <p>
                        <a href="#row53" className="anchorCss">
                          105.30
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum: 5%; Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Dividend Growth</h6>
                      <p>Annual Rate Per Share2</p>
                      <p>
                        <a href="#row54" className="anchorCss">
                          106.40
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum:+6%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Institutional Investors</h6>
                      {/* <p>Annual Rate Per Share2</p> */}
                      <p>
                        <a href="#row61" className="anchorCss">
                          103.50
                        </a>
                      </p>
                      <p>AWIL Preference</p>
                      <p>Minimum: 40</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Fixed Charge Coverage Ratio</h6>
                      <p>
                        Ratio of Pretax Inc to (a) Int Exp & Pfd Divs, and (b)
                        Net Int Inc & Pfd Divs
                      </p>
                      <p>
                        <a href="#row62" className="anchorCss">
                          104.50-104.54
                        </a>
                      </p>
                      <p>AWIL Pref Max: 3.5:1</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Pre-Tax Inc To Total Assets Time-Wtd Average</h6>
                      <p>Annual Rate</p>
                      <p>
                        <a href="#row63" className="anchorCss">
                          105.40-105.41
                        </a>
                      </p>
                      <p>AWIL Pref. Min:6%;</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Operating Inc Growth</h6>
                      <p>Annual Rate Per Share</p>
                      <p>
                        <a href="#row64" className="anchorCss">
                          106.50
                        </a>
                      </p>
                      <p>AWIL Minimum:</p>
                      <p>+6%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Share- holders</h6>
                      {/* <p>Annual Rate Per Share</p> */}
                      <p>
                        <a href="#row71" className="anchorCss">
                          103.60
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: 1000</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Liquidity Quick Ratio</h6>
                      <p>Cash & Receivables / Current Liabilities</p>
                      <p>
                        <a href="#row72" className="anchorCss">
                          104.60-104.64
                        </a>
                      </p>
                      <p>AWIL Pref Min:0.5:1</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Oper Inc To Total Assets Time-Wtd Average</h6>
                      <p>Annual Rate</p>
                      <p>
                        <a href="#row73" className="anchorCss">
                          105.50-105.51
                        </a>
                      </p>
                      <p>AWIL Pref Min:8%;</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Assets Growth</h6>
                      <p>Annual Rate Per Share</p>
                      <p>
                        <a href="#row74" className="anchorCss">
                          106.60
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: +4%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <h6>Closely Held Shares</h6>
                      <p>% of Total</p>
                      <p>
                        <a href="#row81" className="anchorCss">
                          103.70
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Maximum: 30%</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Working Capital Current Ratio</h6>
                      <p>Current Assets / Current Liabilities</p>
                      <p>
                        <a href="#row82" className="anchorCss">
                          104.70-104.74
                        </a>
                      </p>
                      <p>AWIL Pref Min: 2:1</p>
                      <p>Varies with Industry</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Operating Income</h6>
                      <p>% of Total Capital Adjusted Rate</p>
                      <p>
                        <a href="#row83" className="anchorCss">
                          105.60-105.62
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: 15%</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <h6>Sales/Rev Growth</h6>
                      <p>Annual Rate Per Share</p>
                      <p>
                        <a href="#row84" className="anchorCss">
                          106.70
                        </a>
                      </p>
                      <p>AWIL Preference:</p>
                      <p>Minimum: +4%</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <p>
                      All profitability and growth records are time-weighted
                      annual rates, per share adjusted for non-recurring
                      charges, credits and trends.
                      <br></br>
                      <b>NOTES:</b> Ratings for the average Approved Wright
                      Investment List stock are based on the latest data
                      available in our database. (1) Adjusted for trends,
                      cyclical variations, strikes and non-recurring charges and
                      credits; (2): Adjusted for trends only; (3): Compound
                      Annual Rate based on earnings reported for last 12 months
                      vs 5 years ago; (4): The composite growth rating is
                      derived after adjusting the time-weighted "normal" growth
                      by stability and is expressed on a 0-20 scale.
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <section>
            <div className="spancss">
              <p>
                {" "}
                Following is a detailed explanation of Wright Investment Quality
                Ratings, the minimum standards for listing on "The Approved
                Wright Investment List" and general policies and procedures for
                listing, review, and delisting. Wright Quality Standards have
                been published regularly since 1970, are presented here with
                subsequent refinements and improvements, and are subject to
                continuous review and, when advisable, appropriate modification,
                without notice, by the WIS Investment Committee.
              </p>
              <p>
                <span>THE APPROVED WRIGHT INVESTMENT LIST</span> consists of
                common stocks, selected by Wright Investors’ Service, which meet
                high standards of investment QUALITY, and which are suitable for
                investment consideration.
              </p>
            </div>
          </section>

          <section>
            <div className="spancss">
              <h2>WRIGHT INVESTMENT QUALITY RATINGS</h2>
              <p>
                <span>WRIGHT QUALITY RATINGS</span> measure the overall
                investment quality of a company’s stock. The rating is based on
                32 individual measures of quality grouped into four principal
                components: (1) Investment Acceptance, (2) Financial Strength,
                (3) Profitability & Stability, and (4) Growth.
              </p>
              <p>
                The rating is made up of three letters and a number. The letters
                indicate: (1) Investment Acceptance (103-103.7), (2) Financial
                Strength (104-104.7), and (3) Profitability and Stability
                (105-105.6). Each letter rating reflects a composite qualitative
                measurement of 8 individual standards which may be summarized
                "A": indicating Outstanding, "B": Excellent, "C": Good, "D":
                Fair, "L": Limited, and "N": Not Rated.
              </p>
              <p>
                The number is a composite of eight individual standards
                (106-106.7), measures the annual Corporate Growth Rate and may
                range from 0 to as high as 20.
              </p>
            </div>
          </section>
          <section>
            <div className="spancss">
              <h2>WIS STANDARDS OF INVESTMENT QUALITY INVESTMENT ACCEPTANCE</h2>
              <p>
                <span>103.00 INVESTMENT ACCEPTANCE</span> is a general quality
                which reflects the acceptability of a security by, and its
                distribution and marketability among, individual and
                institutional investors and the adequacy of the floating supply
                of its common shares for the investment of substantial funds.
              </p>
              <p>
                <span>103.01</span> The composite investment acceptance rating
                is a weighted average which takes into account the following
                specific sub-components (103.1-103.7) for which AWIL standards
                are indicated.
              </p>
              <p id="row11">
                <span>103.10 Market Value</span> of all outstanding shares
                should total at least $1,000 million at the average price during
                the last 3 years and or the Current Price.
              </p>
              <p id="row21">
                <span>103.11 Publicly owned shares,</span> excluding all shares
                known to be "closely held" by officers, directors,their
                families, trusts, foundations, and shareholders owning
                individually 10% or more, should have a market value of at least
                $1,000 million at average market price during the last 3 years
                and or the Current Price.
              </p>
              <p id="row31">
                <span>
                  103.20 The minimum market value of all shares traded,
                </span>{" "}
                if reported, should preferably have averaged at least $250
                million per year during the last 3 years and last 12 months.
                This requirement may be reduced for stocks whose market values
                are substantially above the minimum.
              </p>
              <p id="row41">
                <span>103.30 A moderate turnover rate</span> (Minimum: 25%) is
                desirable since a lower rate would indicate that the stock is
                not actively traded.
              </p>
              <p id="row51">
                <span>
                  103.40 Listing on one or more established national or regional
                  stock exchange
                </span>{" "}
                or The National Association of Securities Dealers Automated
                Quotation Service (NASDAQ) are preferred. NYSE listing, although
                still very desirable, is no longer an absolute requirement.
              </p>
              <p id="row61">
                <span>103.50 Significant institutional ownership</span> is
                desirable but not an absolute requirement.
              </p>
              <p id="row71">
                <span>103.60 Broad public ownership</span> should be evidenced
                by a preferred minimum of about 1000 shareholders.
              </p>
              <p id="row81">
                <span>
                  103.70 Not more than 30% of outstanding shares may be "closely
                  held"
                </span>{" "}
                by officers, directors, their families, trusts, foundations, and
                shareholders owning individually 10% or more. The preferred
                maximum may, however, be increased by the Investment Committee
                provided that (1) closely held shareholders are of long standing
                and (2) there has been reasonable recent assurance that no
                distribution of closely held shares is contemplated.
              </p>
            </div>
          </section>
          <section>
            <div className="spancss">
              <h2>
                WIS STANDARDS OF INVESTMENT QUALITY CORPORATE FINANCIAL STRENGTH
              </h2>
              <p>
                <span>104.00 CORPORATE FINANCIAL STRENGTH </span>represents the
                amount and availability of the corporation’s resources in
                relation to current and potential requirements and is measured
                by aggregate common shareholders’ equity and long-term debt
                capital, the ratio of equity capital to total capital, the
                adequacy of net working capital, its fixed charges coverage
                ratio and a minimum of complex, restrictive, or convertible
                securities. Corporate Financial Strength is made up of a group
                of elements (104.1-104.7) for each of which AWIL standards are
                indicated.
              </p>
              <p>
                <span>104.01 </span>The composite financial strength rating is a
                weighted average which takes into account the following specific
                sub-components (104.1-104.73) and the trends of each.
              </p>
              <p id="row12">
                <span>
                  104.10 Common shareholders’ equity capital investment
                </span>{" "}
                at corporate book value should total at least $150 million.
              </p>
              <p id="row22">
                <span>104.11 Equity</span> should normally be at least 50% (30%
                for utilities, 25% for financial companies, of total capital
                (common and preferred stock plus long-term debt). This
                requirement is modified when an excess of debt is more than
                balanced by an excess of working capital. A recent or proposed
                substantial increase in a company’s long-term debt may be
                acceptable only if the increase may confidently be expected to
                produce reasonable, constructive growth and will not
                substantially increase risk. Equity as a percent of total
                capital should not be below the average for similar companies in
                the same industry group.
              </p>
              <p>
                <span>104.12</span> For insurance companies, Total Debt (Long
                Term including capitalized leases plus Short Term and current
                maturities of long term debt) should normally not exceed 60% of
                Total Capital (common and preferred stock plus long term and
                short term debt).
              </p>
              <p id="row32">
                <span>104.20 Preferred stock</span> is generally undesirable in
                large amounts. There is, however, no absolute maximum
                limitations to preferred stock as a percent of total capital.
              </p>
              <p>
                <span>104.21</span> For banks and bank holding companies, equity
                should preferably be at least 6.0% of total assets.
              </p>
              <p>
                <span> 104.22</span> For insurance companies, Liquid Assets
                (Cash, Short Term Investments plus marketable securities) should
                preferably be at least 75% of Total Investments.
              </p>
              <p>
                <span>104.23 </span>For securities brokerage companies, equity
                should preferably be at least 10.0% of total assets.
              </p>
              <p id="row42">
                <span>104.30 Long-term debt</span> (currently outstanding
                including capitalized leases plus any scheduled offerings)
                including convertible debentures should normally not exceed 50%
                (60% for utilities, 70% for financial companies, of total
                capital (common and preferred stock plus long-term debt). This
                requirement may, however, be modified when the excess of debt is
                more than balanced by an excess of working capital.
              </p>
              <p>
                <span>104.31</span> A company which has a recent or proposed
                substantial increase in its long-term debt may be acceptable
                only if the increase will produce reasonable, constructive
                growth and will not result in substantial speculative risk. Debt
                as a percent of total capital should not be above the average
                for similar companies in the same industry group.
              </p>
              <p>
                <span>104.32</span> For banks and bank holding companies, Common
                Equity adjusted for Loan Loss Reserves and Non-performing Loans,
                should be at least 6% of the Total Assets.
              </p>
              <p>
                <span>104.33</span> For insurance companies, investment in
                Non-Investment Grade Bonds should not exceed 3% of the Total
                Investments.
              </p>
              <p id="row52">
                <span>104.40 Total Debt</span> (Long Term including capitalized
                leases plus Short Term and current maturities of long term debt)
                should normally not exceed 60% (65% for Utilities and Securities
                Brokerage Companies, 80% for financial companies) of Total
                Capital (common and preferred stock plus long term debt and
                capitalized leases plus short term debt including current
                maturities of long term debt). This requirement may, however, be
                modified when excess of total debt is more than balanced by an
                excess of Quick Ratio.
              </p>
              <p>
                <span>104.41</span> For banks and bank holding companies, it is
                preferable that total capital should normally be at least 9% of
                total deposits.
              </p>
              <p>
                <span>104.42 </span>For insurance companies, investment in
                Common stocks plus Real Estate plus Mortgages should not
                normally exceed 100% of the Total Equity for Property & Casualty
                Insurance Companies, and 150% for Life Insurance Companies.
              </p>
              <p id="row62">
                <span>104.50 </span> The average ratio of (1) pretax income plus
                interest expense to fixed charges and (2) pretax income plus net
                fixed charges should preferably be at least 3.5 to 1 (2 to 1 for
                utilities and Securities Brokerage Companies, 2.5-3.5 to 1 for
                transportation companies). The first ratio indicates the
                coverage of costs associated with fixed corporate debt
                obligations (currently outstanding debt and any scheduled
                offerings) by pretax income and is computed as the ratio of
                pretax income plus interest expense to fixed charges (total
                interest expense plus preferred dividends). The second ratio
                indicates the coverage of cost associated with fixed corporate
                debt obligations after adjusting for interest income from
                investment securities by pretax income plus net interest to
                adjusted fixed charges (net interest plus preferred dividends).
                Preferred dividends are adjusted to pretax earnings equivalent
                to place them on a comparable pretax basis.
              </p>
              <p>
                <span>104.51 </span> For banks and bank holding companies, it is
                desirable that cash and securities including amounts due from
                banks should normally be 40% of total deposits.
              </p>
              <p>
                <span>104.52</span> For insurance companies, Reserves for unpaid
                losses and loss adjustment expenses should be adequate enough to
                meet the liabilities payable for current year vs the actual loss
                experience of average of last six years.
              </p>
              <p>
                <span>104.53</span> For commercial finance companies and
                Personal Loan Companies it is also desirable that net
                receivables be at least 110% of total debt (long term + short
                term).
              </p>
              <p>
                <span>104.54</span> For securities brokerage companies long term
                debt should normally not exceed 3% of total assets.
              </p>
              <p id="row72">
                <span>104.60 </span> Quick Ratio or Ratio of cash and
                receivables to current liabilities of 0.5 to 1 for all
                industrial companies, is preferred.
              </p>
              <p>
                <span>104.61 </span> For banks and bank holding companies,
                Non-performing Loans (loans that banks foresee difficulty in
                collecting) as a % of Loan Loss Reserves should not preferably
                exceed 125%.
              </p>
              <p>
                <span>104.62</span> For insurance companies, Benefit and Claim
                Reserves (representing the present value of future benefits to
                be paid on behalf of or to policyholders) should not exceed 350%
                of Common Equity for Property & Casualty Insurance Companies and
                700% for Life Insurance Companies.
              </p>
              <p>
                <span>104.63</span> For utilities and transportation companies a
                ratio of Operating Cash (representing funds from operations) to
                Fix Charges (total interest expense plus preferred dividend
                adjusted to pretax earnings equivalent to place them on a
                comparable pretax basis), is desirable to have at least 2.5x for
                Utilities, 3.0x for Securities Brokerage Companies and 5.0x for
                Transportation Companies.
              </p>
              <p>
                <span>104.64</span> For securities brokerage companies, it is
                preferable that total capital should normally be at least 15% of
                total assets.
              </p>
              <p id="row82">
                <span>
                  104.70 Ratio of current assets to current liabilities
                </span>{" "}
                of 2 to 1, or a ratio which is at least equal to the average of
                the relevant industry group (e.g., 1.2 to 1 for transportation
                companies) is preferred. This requirement may be reduced for
                industrial companies provided that equity as a percent of total
                capital is sufficiently high to balance the ratio of current
                assets to current liabilities. This requirement does not apply
                to banks, insurance companies, financial companies, and
                utilities.
              </p>
              <p>
                <span>104.71</span> For banks and bank holding companies, it is
                also desirable that loan losses net of recoveries should not
                have exceeded 0.60% of total loans outstanding in the most
                recent year, the average of the last three years, or the average
                of the last five years.
              </p>
              <p>
                <span>104.72</span> For utilitie and financial companies, the
                preferred minimum coverage ratio of annual dividends paid to
                common shareholders to total corporate cash flow before payment
                of common dividends should be at least 2.5 to 1 and 4.0:1
                respectively. This measures the coverage of annual dividends by
                cash flow. The higher the cash dividend coverage ratio, the
                greater is the ability to fund capital expenditures internally.
                Total cash flow consists of after-tax income available to the
                common stocks plus depreciation, net deferred taxes and other
                miscellaneous cash flows, if any.
              </p>
              <p>
                <span>104.73 </span>For securities brokerage companies, net
                capital requirement should preferably be at least 10.0% of the
                debit balances.
              </p>
              <p>
                <span>104.74</span> For insurance companies Net Premium Written
                should not exceed 250% of total equity capital for Property &
                Casualty Insurance Companies.
              </p>
            </div>
          </section>
          <section>
            <div className="spancss">
              <h2>
                WIS STANDARDS OF INVESTMENT QUALITY CORPORATE PROFITABILITY AND
                STABILITY
              </h2>

              <p>
                <span>105.00 CORPORATE PROFITABILITY</span> measures the record
                of a corporation’s management in terms of (1) the rate of (a)net
                return and (b) cash return on shareholders’ equity capital
                investment at corporate book value, (2) net return before
                preferred dividends and interest on assets, (3)pretax and
                operating return on assets and (4) the profits or losses of the
                corporation during generally adverse economic periods, and thus
                its ability to withstand adverse financial developments. This
                quality is measured by the following components (105.1-105.6)
                for which AWIL standards are indicated. The average of Profit
                Rate on net income and cash earnings, Pretax Returns on assets,
                Operating Return on assets, Net Return on assets and Operating
                Income as a percentage of Total Capital are time-weighted annual
                rates adjusted for trends and variation beyond the control of
                management unless otherwise noted.
              </p>
              <p>
                <span>105.01</span> The Profitability Rating is based on
                extensive mathematical analysis and evaluation of the company’s
                record. Subjective evaluations are excluded except when
                specifically noted. The Profitability Rating is a composite
                based primarily on the rate of several returns on shareholders’
                equity capital and assets but modified by (1) nonrecurring
                adjustments to book value, earnings and pretax
                profit,(2)operating income as a percentage of capital, and (3)
                corporate trends.
              </p>
              <p>
                <span>105.02</span> The composite profitability rating is a
                weighted computation which takes into account the following
                specific sub-components (105.1-105.6) and the trends of each.
              </p>
              <p id="row13">
                <span>105.10</span> The "normal" profit rate, time-weighted and
                adjusted for trends and non-recurring charges and credits,
                should be at least 11%. This may be reduced for companies in
                temporarily depressed industries provided that the company’s
                average adjusted profit rate has been at least equal to that of
                similar companies in its industry. This requirement does not
                apply to utilities.
              </p>
              <p id="row22">
                <span>105.11 The trend of profit rate</span> should show no
                significant decline unless there has been an equal or greater
                decline for comparable companies in its industry. The
                acceptability of a declining trend takes into account the degree
                by which the normal profit rate exceeds the AWIL minimum.
              </p>
              <p id="row33">
                <span>105.20 The rate of return of cash earnings</span> on
                shareholder's equity capital should be at least 20%. This may be
                reduced for companies in temporarily depressed industries
                provided that that of similar companies in its industry. For
                banks, the required rate of return of cash earnings is reduced
                to 20% of earnings.
              </p>
              <p>
                <span>
                  105.21 The trend of rate of return of cash earnings{" "}
                </span>
                should show no significant decline unless there has been an
                equal or greater decline for comparable companies in its
                industry. The acceptability of a decline in trend takes into
                account the degree by which the rate of return of cash earnings
                exceeds the AWIL minimum.
              </p>
              <p id="row43">
                <span>
                  105.22 The stability of a company’s annual rate of return of
                  cash earnings{" "}
                </span>
                should normally be higher than the average of about 400
                industrial companies which make up the S&P Industrial Average
                (82%), although a minimum of 60% may be acceptable in particular
                companies with compensating virtues. The stability index is the
                complement of the standard deviation of the company's rate of
                cash earnings return for the last 5-6 years, as measured from a
                linear regression slope line determined by the average rate of
                growth and expressed as a percentage
              </p>
              <p id="row53">
                <span>105.30 The return on total assets</span> should be at
                least 5% (4% for insurance companies, and 1.2% for banks). This
                may be reduced for companies in temporarily depressed industries
                provided that the company's average adjusted return on assets
                has been at least equal to that of similar companies in its
                industry.
              </p>
              <p id="row63">
                <span>105.40 The pretax income return on total assets</span>{" "}
                should be at least 6% (5% for insurance companies, and 1.5% for
                banks). This may be reduced for companies in temporarily
                depressed industries provided that the company's average
                adjusted pretax return on assets has been at least equal to that
                of similar companies in its industry.
              </p>
              <p>
                <span>
                  105.41 For insurance companies, Investment Income % of
                  Investment Assets
                </span>{" "}
                should at least be 9.0%, during the last 3-5 years.
              </p>
              <p id="row73">
                <span>105.50 The operating income return on total assets</span>{" "}
                should be at least 8% (4% for insurance companies, 2% for banks,
                and 6% for oil & gas companies). This may be reduced for
                companies in temporarily depressed industries provided that the
                company's average adjusted operating return on assets has been
                at least equal to that of similar companies in its industry.
              </p>
              <p>
                <span>
                  105.51 For insurance companies, Earnings before interest and
                  taxes (EBIT)
                </span>{" "}
                should preferably be at least 15% of the Total Capital at the
                beginning of the year during the last year.
              </p>
              <p id="row83">
                <span>105.60 Pretax operating income </span> during the last 5-6
                years should preferably have averaged at least 15% of total
                invested capital (common and preferred equity plus minority
                interest plus long term debt including capitalized leases). This
                requirement may be reduced proportionately, but not to less than
                10% for companies whose corporate tax rates are significantly
                less than 35% because of continuing tax benefits (as
                distinguished from loss carry-forward, etc.). This requirement
                does not apply to banks, insurance companies, and utilities.
              </p>
              <p>
                <span>
                  106.61 For non-life insurance companies, a Combined Ratio
                </span>{" "}
                (Claim & Claim expense as a percent of Premium Earned plus
                Underwriting expenses as a percent of Net Premium Earned) of at
                least 100%, is desirable, during the last 3-5 years. If this is
                unavailable, 5 year average loss ratio is used and if it is
                unavailable, 5 year average loss ratio is used.
              </p>
              <p>
                <span>
                  106.62 For life insurance companies trend of EBIT as % of
                  Total Capital
                </span>{" "}
                based on last 5-6 years is substituted.
              </p>
              <p>
                <span>106.63 AWIL standard requirements</span> for rate of
                return described above may be modified when there has been a
                nonrecurring loss of profit which was not avoidable by
                management (or which was a temporary by-product of an
                acquisition) and which is not considered to be a continuing
                risk, provided that(1) the company's profitability has been
                clearly superior to the average of similar companies in an
                industry which has been cyclically depressed, or (2) both the
                rate and stability of its cash earnings growth have been clearly
                superior to those of similar companies in its industry.
              </p>
            </div>
          </section>
          <section>
            <div className="spancss">
              <h2>WIS STANDARDS OF INVESTMENT QUALITY CORPORATE GROWTH</h2>
              <p>
                <span>106.00 CORPORATE GROWTH</span> is the element which
                distinguishes the return on equity investments from fixed-income
                on dollar-obligated securities. This is primarily the growth per
                common share of a corporation’s earnings, cash earnings,
                dividends, retained earnings, assets and sales - rather than the
                corporations's overall growth of dollar sales, assets and
                income. All items are adjusted for corporate trends and
                variations beyond the control of management including
                nonrecurring items. The Growth Rating reflects the annual rate
                of growth during the last 5-6 years. The rating is a composite
                based on time-weighted averages, adjusted for trends, of the
                annual rate of growth per share earnings, adjusted for
                nonrecurring items and modified by the company’s basic
                profitability, stability and growth rates of per share revenues,
                assets, cash earnings, dividends and retained earnings. Wright
                Growth Ratings are based on extensive mathematical analysis and
                evaluation of the company’s record, as distinguished from
                subjective investment evaluations which are excluded except when
                specifically noted.
              </p>
              <p>
                <span>106.01 The Growth Rating </span>is measured by the
                following subcomponents (106.1-106.7) for which AWIL standards
                are indicated. The AWIL standard minimum requirement for growth
                rates of earnings per share (106.1), cash earnings per share
                (106.2), earned equity per share (106.4), and sales or revenues
                per share(106.7) may be modified when there has been a
                nonrecurring loss or profit reduction which was not avoidable by
                management (or which was a temporary byproduct of an acquisition
                or restructuring or disposal of assets) and which is not
                considered to be a continuing risk provided that (1) the
                company’s profitability has been clearly superior to the average
                of similar companies in its industry which has been cyclically
                depressed or (2) both the rate and consistency of its earnings
                growth have been clearly superior to those of similar companies
                in its industry, and a profit rate of at least 10% is projected
                for the next 12 months. These requirements may (rarely) also be
                modified if the company is one of the leading companies in its
                industry group. The Growth Rating will be shown as "N" (Not
                Rated) if the data is not available for at least 5 years.
              </p>
              <p>
                <span>106.02 The composite growth rating </span>is a weighted
                average which takes into account the following specific
                subcomponents (106.1-106.7) adjusted for trends of each.
              </p>
              <p id="row14">
                <span>106.10 Normal earnings growth </span>per common share (a
                time-weighted, 5-6 year average adjusted for trends and
                nonrecurring charges and credits) should have averaged at least
                +6% per year, although a minimum of +4% is acceptable for
                companies with superior profit and dividend rates.
              </p>
              <p id="row24">
                <span>106.20 Cash earnings growth </span>per common share should
                have averaged at least +6% per year, although a minimum of +4%
                is acceptible for companies with superior profit and dividend
                rates.
              </p>
              <p>
                <span>
                  106.21 The stability of a company’s cash earnings growth
                </span>{" "}
                should be a minimum of 60%.
              </p>
              <p id="row44">
                <span>106.30 Earned equity growth</span> (net earnings retained
                after dividend payout, expressed as a percentage of
                shareholders’ equity capital at the beginning of the year) per
                common share should have averaged at least +6% per year during
                the last 5-6 years. This requirement for earned equity growth
                per common share does not, however, apply to banks, financial
                companies, insurance companies and utilities.
              </p>
              <p id="row54">
                <span>106.40 Dividend growth</span> per common share should have
                averaged at least +6% per year during the last 5-6 years. This
                requirement may be modified when a company chooses to reduce the
                dividend payout rate in order to increase the growth of retained
                earnings. Dividend growth rate will be shown as "N" (not rated)
                if annual dividends do not exceed 2% of equity capital.
              </p>
              <p id="row64">
                <span>106.50 Operating income growth</span> per share should
                have averaged at least +6% per year during the last 5-6 years.
              </p>
              <p id="row74">
                <span>106.60 Asset growth</span> per share should have averaged
                at least +4% per year during the last 5-6 years.
              </p>
              <p id="row84">
                <span>106.70 Sales or revenue growth</span> per share should
                have averaged at least +4% per year during the last 5-6 years
                (excluding divestitures).
              </p>
            </div>
          </section>
        </Container>
      </>
    );
  }
}
