import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./PerShareData.css";
export default class PerShareData extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }

    let type = props.type;
    this.state = {
      isPdfReport: isPdfReport,
      type: type,
      perShareListData: [],
      render: false,
      data: props.data,
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  componentDidMount() {
    let indType = this.state.data.ind_TYPE;
    this.currency = this.state.data.currency;
    this.unit = this.state.data.unit;
    let perShareList = this.state.data.perShareList.slice(0, 5);
    if (perShareList.length > 0) {
      perShareList.reverse();
    }
    let sales = [];
    let operatingIncome = [];
    let pretaxIncome = [];
    let netIncome = [];
    let netIncomeAvailableToCommonShares = [];
    let fullyDilutedEarnings = [];
    let commonDividends = [];
    let cashEarnings = [];
    let bookValue = [];
    let retainedEarnings = [];
    let assets = [];
    let LabelList = [];
    let netIncomeBeforeExtraItems = [];
    let extraordinaryItems = [];
    let netIncomeAfterExtraordinaryItems = [];
    for (let i = 0; i < perShareList.length; i++) {
      sales.push(perShareList[i].sales);
      operatingIncome.push(perShareList[i].operatingIncome);
      pretaxIncome.push(perShareList[i].pretaxIncome);
      netIncome.push(perShareList[i].netIncomeContinuingOperations);
      netIncomeAvailableToCommonShares.push(
        perShareList[i].netIncomeAvailabletoCommonShares
      );
      fullyDilutedEarnings.push(perShareList[i].fullyDilutedEarnings);
      commonDividends.push(perShareList[i].commonDividends);
      cashEarnings.push(perShareList[i].cashEarnings);
      bookValue.push(perShareList[i].bookValue);
      retainedEarnings.push(perShareList[i].retainedEarnings);
      assets.push(perShareList[i].assets);
      LabelList.push(perShareList[i].fiscalYear);
      netIncomeBeforeExtraItems.push(perShareList[i].netIncomeBeforeExtraItems);
      extraordinaryItems.push(perShareList[i].extraordinaryItems);
      netIncomeAfterExtraordinaryItems.push(
        perShareList[i].netIncomeAfterExtraordinaryItems
      );
    }
    this.setState({
      perShareListData: perShareList,
      render: true,
      indType: indType,
      netIncomeAfterExtraordinaryItems: netIncomeAfterExtraordinaryItems,
      extraordinaryItems: extraordinaryItems,
      netIncomeBeforeExtraItems: netIncomeBeforeExtraItems,
      LabelList: LabelList,
      sales: sales,
      operatingIncome: operatingIncome,
      pretaxIncome: pretaxIncome,
      netIncome: netIncome,
      netIncomeAvailableToCommonShares: netIncomeAvailableToCommonShares,
      fullyDilutedEarnings: fullyDilutedEarnings,
      commonDividends: commonDividends,
      cashEarnings: cashEarnings,
      bookValue: bookValue,
      retainedEarnings: retainedEarnings,
      assets: assets,
    });
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.sales,
          labelData: this.state.LabelList,
          yAxisTitle: "Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pre-tax Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Pre-tax Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income Available to Common Shares") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncomeAvailableToCommonShares,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income Available to Common Shares",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Fully Diluted Earnings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.fullyDilutedEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Fully Diluted Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Dividends") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonDividends,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Earnings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Book Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.bookValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Book Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Retained Earnings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.retainedEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Retained Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.assets,
          labelData: this.state.LabelList,
          yAxisTitle: "Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income Before Extra Items") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncomeBeforeExtraItems,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income Before Extra Items",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Items") {
      this.setState({
        graphDetails: {
          graphValues: this.state.extraordinaryItems,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Items",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income After Extraordinary Items") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncomeAfterExtraordinaryItems,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income After Extraordinary Items",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
              <span>
                <span className="section_header"> Per Share Data</span>
              </span>
              <br></br>

              <span className="unit_str" style={{ marginLeft: "-2px" }}>
                Figures are expressed as per unit of respective shares.
              </span>

              <br></br>
              <span className="unit_str" style={{ marginLeft: "-2px" }}>
                All Figures in&nbsp; {this.currency}
              </span>
              <Table responsive className="perShare_data">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.perShareListData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.perShareListData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Sales</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.sales !== null
                          ? Utils.valueFormater(data.sales)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th>
                    <span className="">Operating Income</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.operatingIncome !== null
                          ? Utils.valueFormater(data.operatingIncome)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Pre-tax Income</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.pretaxIncome !== null
                          ? Utils.valueFormater(data.pretaxIncome)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Net Income (Continuing Operations)</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.netIncomeContinuingOperations !== null
                          ? Utils.valueFormater(
                              data.netIncomeContinuingOperations
                            )
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Income Before Extra Items</th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.netIncomeBeforeExtraItems !== null
                          ? Utils.valueFormater(data.netIncomeBeforeExtraItems)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items</th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.extraordinaryItems !== null
                          ? Utils.valueFormater(data.extraordinaryItems)
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Income After Extraordinary Items</th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.netIncomeAfterExtraordinaryItems !== null
                          ? Utils.valueFormater(
                              data.netIncomeAfterExtraordinaryItems
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Net Income Available to Common Shares
                    </span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.netIncomeAvailabletoCommonShares !== null
                          ? Utils.valueFormater(
                              data.netIncomeAvailabletoCommonShares
                            )
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Fully Diluted Earnings</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.fullyDilutedEarnings !== null
                          ? Utils.valueFormater(data.fullyDilutedEarnings)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Common Dividends</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.commonDividends !== null
                          ? Utils.valueFormater(data.commonDividends)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Cash Earnings</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.cashEarnings !== null
                          ? Utils.valueFormater(data.cashEarnings)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Book Value</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.bookValue !== null
                          ? Utils.valueFormater(data.bookValue)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Retained Earnings</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.retainedEarnings !== null
                          ? Utils.valueFormater(data.retainedEarnings)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Assets</span>
                  </th>
                  {this.state.perShareListData.map((data) => {
                    return (
                      <td>
                        {data.assets !== null
                          ? Utils.valueFormater(data.assets)
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <span>
                  <span className="section_header"> Per Share Data</span>
                </span>
                <br></br>

                <span className="unit_str" style={{ marginLeft: "-2px" }}>
                  Figures are expressed as per unit of respective shares.
                </span>
                <br></br>
                <span className="unit_str" style={{ marginLeft: "-2px" }}>
                  All Figures in&nbsp; {this.currency}
                </span>
                <Table responsive className="perShare_data">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.perShareListData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.perShareListData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Sales</span>

                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("Sales")}
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.sales !== null
                            ? Utils.valueFormater(data.sales)
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>
                      <span className="">Operating Income</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.operatingIncome !== null
                            ? Utils.valueFormater(data.operatingIncome)
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Pre-tax Income</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pre-tax Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.pretaxIncome !== null
                            ? Utils.valueFormater(data.pretaxIncome)
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        Net Income (Continuing Operations)
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.netIncomeContinuingOperations !== null
                            ? Utils.valueFormater(
                                data.netIncomeContinuingOperations
                              )
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Net Income Before Extra Items</th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.netIncomeBeforeExtraItems !== null
                            ? Utils.valueFormater(
                                data.netIncomeBeforeExtraItems
                              )
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items</th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.extraordinaryItems !== null
                            ? Utils.valueFormater(data.extraordinaryItems)
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Net Income After Extraordinary Items</th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.netIncomeAfterExtraordinaryItems !== null
                            ? Utils.valueFormater(
                                data.netIncomeAfterExtraordinaryItems
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        Net Income Available to Common Shares
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income Available to Common Shares"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.netIncomeAvailabletoCommonShares !== null
                            ? Utils.valueFormater(
                                data.netIncomeAvailabletoCommonShares
                              )
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Fully Diluted Earnings</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Fully Diluted Earnings")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.fullyDilutedEarnings !== null
                            ? Utils.valueFormater(data.fullyDilutedEarnings)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Common Dividends</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Dividends")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.commonDividends !== null
                            ? Utils.valueFormater(data.commonDividends)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Cash Earnings</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash Earnings")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.cashEarnings !== null
                            ? Utils.valueFormater(data.cashEarnings)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Book Value</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Book Value")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.bookValue !== null
                            ? Utils.valueFormater(data.bookValue)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Retained Earnings</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Retained Earnings")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.retainedEarnings !== null
                            ? Utils.valueFormater(data.retainedEarnings)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Assets</span>

                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("Assets")}
                      ></GraphIconButton>
                    </th>
                    {this.state.perShareListData.map((data) => {
                      return (
                        <td>
                          {data.assets !== null
                            ? Utils.valueFormater(data.assets)
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Per Share Data
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp; {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
