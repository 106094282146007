import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./PriceAnalysis.css";
export default class PriceAnalysis extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      render: false,
      isPdfReport: isPdfReport,
      data: props.data,
      priceAnalysisData: [],
      priceValues: [],
    };
  }

  componentDidMount() {
    let priceAnalysisData = [];
    for (let i = 0; i < this.state.data.priceAnalysisList.length; i++) {
      priceAnalysisData.push(
        this.state.data.priceAnalysisList[i].quarterlyPriceList
      );
    }
    let arr = [];
    for (var i = 0; i < priceAnalysisData.length; i++) {
      for (var j = 0; j < priceAnalysisData[i].length; j++) {
        arr.push(priceAnalysisData[i][j]);
      }
    }
    let arr1 = [];
    let priceValues = [];
    let highPrice = [];
    let lowPrice = [];
    let quarterlyPercentChange = [];
    let twelveMonthsPercentChange = [];
    let labelData = [];
    for (let j = 0; j < this.state.data.priceAnalysisList.length; j++) {
      let arr2 = this.state.data.priceAnalysisList[j].quarterlyPriceList;

      arr1.push(...arr2);
    }
    var items = arr.slice(Math.max(arr.length - 40, 0));

    for (let j = 0; j < arr1.length; j++) {
      if (arr1[j].quarter === null) {
        labelData.push(arr1[j].date);
      } else {
        labelData.push(arr1[j].year + " " + arr1[j].endingMonth);
      }

      priceValues.push(arr1[j].closingPrice);
      highPrice.push(arr1[j].highPrice);
      lowPrice.push(arr1[j].lowPrice);
      quarterlyPercentChange.push(arr1[j].quarterlyPercentChange);
      twelveMonthsPercentChange.push(arr1[j].yearlyPercentChange);
    }
    this.setState({
      currency: this.state.data.currency,
      priceAnalysisData: items,
      twelveMonthsPercentChange: twelveMonthsPercentChange,
      quarterlyPercentChange: quarterlyPercentChange,
      highPrice: highPrice,
      lowPrice: lowPrice,
      render: true,
      labelData: labelData,
      priceValues: priceValues,
      priceSeries: [
        {
          name: "High Price",
          data: highPrice,
        },
        {
          name: "Low Price",
          data: lowPrice,
        },
        {
          name: "Closing Price",
          data: priceValues,
        },
      ],
      priceOption: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: labelData,
        },
        legend: {
          position: "top",
        },
      },
      TableOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  to: 0,
                  from: -100000000000,
                  color: "#F15B46",
                },
              ],
            },
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    });
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "High Price") {
      this.setState({
        showModal: true,
        graphDetails: {
          graphValues: this.state.highPrice,
          labelData: this.state.labelData,
          yAxisTitle: "High Price",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else if (graphType === "Low Price") {
      this.setState({
        showModal: true,
        graphDetails: {
          graphValues: this.state.lowPrice,
          labelData: this.state.labelData,
          yAxisTitle: "Low Price",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else if (graphType === "Closing Price") {
      this.setState({
        showModal: true,
        graphDetails: {
          graphValues: this.state.priceValues,
          labelData: this.state.labelData,
          yAxisTitle: "Closing Price",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else if (graphType === "Quarterly % Change") {
      this.setState({
        showModal: true,
        graphDetails: {
          graphValues: this.state.quarterlyPercentChange,
          labelData: this.state.labelData,
          yAxisTitle: "Quarterly % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else {
      this.setState({
        showModal: true,
        graphDetails: {
          graphValues: this.state.twelveMonthsPercentChange,
          labelData: this.state.labelData,
          yAxisTitle: "12 months % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  getQuarterYear(priceAnalysisData) {
    let quarterYear = "";
    if (
      priceAnalysisData.quarter !== null &&
      priceAnalysisData.quarter === "JAN-MAR"
    ) {
      quarterYear = priceAnalysisData.year + " " + priceAnalysisData.quarter;
    } else {
      quarterYear = priceAnalysisData.quarter;
    }
    return quarterYear;
  }
  render() {
    let itemRow = this.state.priceAnalysisData.map((priceAnalysisData, i) => {
      return (
        <tr
          style={i % 2 ? { background: "#cae1ff" } : { background: "white" }}
          key={i}
        >
          <td
            style={
              i % 4
                ? { borderBottomWidth: " 0px" }
                : { borderTop: "1px solid", borderBottomWidth: " 0px" }
            }
            className="quarterYear"
          >
            {priceAnalysisData.quarter !== null ? (
              this.getQuarterYear(priceAnalysisData)
            ) : (
              <span>{moment(priceAnalysisData.date).format("MM/DD/YYYY")}</span>
            )}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {priceAnalysisData.highPrice !== null
              ? Utils.valueFormat(priceAnalysisData.highPrice.toFixed(2))
              : "n/c"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {priceAnalysisData.lowPrice !== null
              ? Utils.valueFormat(priceAnalysisData.lowPrice.toFixed(2))
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {priceAnalysisData.closingPrice !== null
              ? Utils.valueFormat(priceAnalysisData.closingPrice.toFixed(2))
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {priceAnalysisData.quarterlyPercentChange !== null
              ? priceAnalysisData.quarterlyPercentChange.toFixed(2) + "%"
              : "n/c"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {priceAnalysisData.yearlyPercentChange !== null
              ? priceAnalysisData.yearlyPercentChange.toFixed(2) + "%"
              : "n/c"}
          </td>
        </tr>
      );
    });
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <ReactApexCharts
                  options={this.state.priceOption}
                  series={this.state.priceSeries}
                  type="line"
                  height={350}
                />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Table
                  responsive
                  style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                          borderBottom: "transparent",
                        }}
                        title="Quarter (and Year) for which Price information is being reported"
                      >
                        Quarter
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("High Price")
                        }
                        title="Represents the highest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - High represents the price of the primary share."
                      >
                        High Price
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Low Price")
                        }
                        title="Represents the lowest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - Low represents the price of the primary share."
                      >
                        Low Price
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Closing Price")
                        }
                        title="Represents the lowest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - Low represents the price of the primary share."
                      >
                        Closing Price
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Quarterly % Change")
                        }
                        title="Represents [(Market Price at Close of Current Quarter divided by Market Price at close of previous Quarter) - 1] * 100"
                      >
                        Quarterly % Change
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("12 months % Change")
                        }
                        title="Represents [(Market Price at Close of Current Quarter divided by Market Price at close of Quarter one year ago) - 1] * 100"
                      >
                        12 months % Change
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header"> Price Analysis</span>
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp;
                      <span>
                        {this.state.currency !== null
                          ? this.state.currency
                          : ""}
                      </span>
                    </span>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table
                  responsive
                  style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                          borderBottom: "transparent",
                        }}
                        title="Quarter (and Year) for which Price information is being reported"
                      >
                        Quarter
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the highest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - High represents the price of the primary share."
                      >
                        High Price
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the lowest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - Low represents the price of the primary share."
                      >
                        Low Price
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the lowest price in the Quarter. For companies with more than one type of common/ordinary share, Market Price - Low represents the price of the primary share."
                      >
                        Closing Price
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Closing Price")
                          }
                        ></GraphIconButton>
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents [(Market Price at Close of Current Quarter divided by Market Price at close of previous Quarter) - 1] * 100"
                      >
                        Quarterly % Change
                      </th>
                      <th
                        className="price_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents [(Market Price at Close of Current Quarter divided by Market Price at close of Quarter one year ago) - 1] * 100"
                      >
                        12 months % Change
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="xl"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Price Analysis
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <div id="chart">
                      <SingleAxisGenericGraph
                        graphData={this.state.graphDetails}
                      ></SingleAxisGenericGraph>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
