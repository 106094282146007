import {
  GET_REGION_FILTER,
  SET_REGION_FILTER,
} from "./userSelectedRegionTypes";

export const getRegionFilter = () => {
  return {
    type: GET_REGION_FILTER,
  };
};

export const setRegionFilter = (data) => {
  return {
    type: SET_REGION_FILTER,
    payload: data,
  };
};

export default { getRegionFilter, setRegionFilter };
