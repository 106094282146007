import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import React, { Component } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
  MdAddBox,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdFolder,
  MdFolderOpen,
  MdIndeterminateCheckBox,
  MdInsertDriveFile,
} from "react-icons/md";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import advanceFilterAction from "../../redux/advanceFilter/advanceFilterAction";
import filterSectorAction from "../../redux/filterSector/filterSectorAction";
import staticDataAction from "../../redux/staticData/staticDataAction";
import store from "../../redux/store";
import userSelectedIndustriesAction from "../../redux/userSelectedIndustries/userSelectedIndustriesAction";
import userSelectedRegionAction from "../../redux/userSelectedRegion/userSelectedRegionAction";
import "./AdvanceSearch.css";

export default class AdvanceSearch extends Component {
  // regionCountryList = store.getState().staticData.regionCountryData;
  // industrySubIndustryList = store.getState().staticData.industrySubIndustryData;
  userSelectedRegion = store.getState().regionFilterData.selectedRegions;
  userselectedIndustries =
    store.getState().industryFilterData.selectedIndustries;
  constructor(props) {
    super(props);

    let selectedRegionList = [];
    let selectedCountryList = [];
    let checkedRegionCountryList = [];
    let checkedSectorIndustryList = [];
    let filterData = Object.getOwnPropertyNames(props.data);
    if (this.userSelectedRegion.length > 0) {
      checkedRegionCountryList = this.userSelectedRegion;
    }
    if (this.userselectedIndustries.length > 0) {
      checkedSectorIndustryList = this.userselectedIndustries;
    }

    this.state = {
      isAdvanceSearch: props.isAdvanceSearch,
      isSimpleSearch: props.isSimpleSearch,
      isAlphabetSearch: props.isAlphabetSearch,
      industrySubIndustryForTreeList: [],
      regionCountryForTreeList: [],
      selectedRegionList: [],
      selectedCountryList: [],
      selectedSectorList: [],
      selectedIndustryList: [],
      selectedCountryCodes: [],
      selectedSectorCodes: [],
      onCancel: props.onCancel,
      onAdd: props.onAdd,
      onOptionChange: props.onOptionChange,
      checkedRegionCountryList: checkedRegionCountryList,
      checkedSectorIndustryList: checkedSectorIndustryList,
      expanded: [],
      checkedSectorCompanyList: [],
      expanded1: [],
      onRegionSelection: props.onRegionSelection,
      onSectorSelection: props.onSectorSelection,
      onResetSearch: props.onResetSearch,
      onApplyFilter: props.onApplyFilter,
      render: false,
    };
    // if (!this.regionCountryList || this.regionCountryList.length <= 5) {
    // this.regionCountryData();
    // }
    // if (
    //   !this.industrySubIndustryList ||
    //   this.industrySubIndustryList.length <= 5
    // ) {

    // }
  }
  componentDidMount() {
    const postObject = {};
    FetchServerData.callPostService("regionCountryService/getList", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let newRegionList = this.uniqueBy(output.data, "region");
          let regionCountryList = [];
          for (let i = 0; i < newRegionList.length; i++) {
            let countryList = this.getCountryInRegion(
              output.data,
              newRegionList[i]
            );

            regionCountryList.push({
              region: newRegionList[i],
              regionCnt:
                countryList && countryList.length > 0
                  ? countryList[0].regionCnt
                  : 0,
              countries: countryList,
            });
          }
          store.dispatch(
            staticDataAction.setRegionCountryData(regionCountryList)
          );
          this.regionCountryList = regionCountryList;
          this.industryServiceData();
        }
      })
      .catch((error) => {});
  }
  resetAdvanceSearch() {
    this.setState({
      checkedSectorIndustryList: [],
      checkedRegionCountryList: [],
    });
    store.dispatch(advanceFilterAction.setAdvanceFilter({}));
    store.dispatch(userSelectedRegionAction.setRegionFilter([]));
    store.dispatch(filterSectorAction.setSectorFilter([]));
    store.dispatch(userSelectedIndustriesAction.setIndustryFilter([]));
    if (this.state.onResetSearch) {
      this.state.onResetSearch();
    }
  }

  toSimpleSearch = (event, value) => {
    this.state.checkedRegionCountryList = [];
    this.state.checkedSectorIndustryList = [];
    this.setState({
      isSimpleSearch: true,
      isAdvanceSearch: false,
      isAlphabetSearch: false,
      checkedRegionCountryList: [],
      checkedSectorIndustryList: [],
    });
    if (this.state.onSectorSelection) {
      this.state.onSectorSelection([]);
    }
    if (this.state.onRegionSelection) {
      this.state.onRegionSelection([]);
    }
    if (this.state.onOptionChange) {
      this.state.onOptionChange("simple");
    }
  };
  getIndustriesInCategory(array, value) {
    let industryArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].majorIndustry === value) {
        industryArray.push(array[i]);
      }
    }
    return industryArray;
  }
  industryServiceData() {
    const postObject = {};
    FetchServerData.callPostService(
      "industryCountryService/getList",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let newCategoryList = this.uniqueBy(output.data, "majorIndustry");
          let categoryIndustryList = [];
          for (let i = 0; i < newCategoryList.length; i++) {
            let industryList = this.getIndustriesInCategory(
              output.data,
              newCategoryList[i]
            );

            categoryIndustryList.push({
              category: newCategoryList[i],
              categoryCnt:
                industryList && industryList.length > 0
                  ? industryList[0].corpInfoIndCnt
                  : 0,

              industries: industryList,
            });
          }
          store.dispatch(
            staticDataAction.setIndustrySubIndustryData(categoryIndustryList)
          );
          this.industrySubIndustryList = categoryIndustryList;
          this.setState({
            render: true,
          });
        }
      })
      .catch((error) => {});
  }

  getCountryInRegion(array, value) {
    let countryArray = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].region === value) {
        countryArray.push(array[i]);
      }
    }
    return countryArray;
  }
  regionCountryData() {
    const postObject = {};
    FetchServerData.callPostService("regionCountryService/getList", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let newRegionList = this.uniqueBy(output.data, "region");
          let regionCountryList = [];
          for (let i = 0; i < newRegionList.length; i++) {
            let countryList = this.getCountryInRegion(
              output.data,
              newRegionList[i]
            );

            regionCountryList.push({
              region: newRegionList[i],
              regionCnt:
                countryList && countryList.length > 0
                  ? countryList[0].regionCnt
                  : 0,
              countries: countryList,
            });
          }
          store.dispatch(
            staticDataAction.setRegionCountryData(regionCountryList)
          );
          this.regionCountryList = regionCountryList;
        }
      })
      .catch((error) => {});
  }

  uniqueBy(arr, prop) {
    return arr.reduce((a, d) => {
      if (!a.includes(d[prop])) {
        a.push(d[prop]);
      }
      return a;
    }, []);
  }

  getUniqueSelectedRegionsName(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[0];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  getUniqueSelectedCountryName(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[1];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  getUniqueSelectedCountryCodes(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[2];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  isAllCountriesSelectedInRegion(region, selectedRegions) {
    let availableCountries = 0;
    for (let i = 0; i < this.regionCountryList.length; i++) {
      if (region === this.regionCountryList[i].region) {
        availableCountries = this.regionCountryList[i].countries.length;
      }
    }
    let selectedCountries = 0;
    for (let i = 0; i < selectedRegions.length; i++) {
      let regionCountry = selectedRegions[i].split("####")[0];
      if (region === regionCountry) {
        selectedCountries = selectedCountries + 1;
      }
    }

    if (availableCountries === selectedCountries) {
      return true;
    } else {
      return false;
    }
  }
  setCheckedRegionCountryList(selectedRegions) {
    localStorage.setItem("selectedRegions", selectedRegions);
    store.dispatch(userSelectedRegionAction.setRegionFilter(selectedRegions));
    /* Deep Copy Checked Regions */
    let tempSelectedRegions = JSON.parse(JSON.stringify(selectedRegions));
    /* Get Unique Regions */
    let selectedRegionList = this.getUniqueSelectedRegionsName(selectedRegions);

    /* For Selected Region check if All Countries selected */

    for (let i = 0; i < selectedRegionList.length; i++) {
      /* Check if All countries are selected for Region */
      let isAllSelected = this.isAllCountriesSelectedInRegion(
        selectedRegionList[i],
        selectedRegions
      );

      if (isAllSelected === true) {
        /* Remove Countries for Region where All are selected */
        let newArray = [];
        for (let j = 0; j < tempSelectedRegions.length; j++) {
          let regionCountry = tempSelectedRegions[j].split("####")[0];

          if (selectedRegionList[i] === regionCountry) {
            continue;
          } else {
            newArray.push(tempSelectedRegions[j]);
          }
        }
        tempSelectedRegions = newArray;
      }
    }
    let selectedCountryList =
      this.getUniqueSelectedCountryName(tempSelectedRegions);
    let selectedCountryCodes =
      this.getUniqueSelectedCountryCodes(tempSelectedRegions);
    this.setState({
      checkedRegionCountryList: selectedRegions,
      selectedRegionList: selectedRegionList,
      selectedCountryList: selectedCountryList,
      selectedCountryCodes: selectedCountryCodes,
    });

    if (this.state.onRegionSelection) {
      this.state.onRegionSelection(
        selectedCountryList,
        selectedRegionList,
        selectedCountryCodes
      );
    }
  }

  getUniqueSelectedSectorName(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[0];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  getUniqueSelectedSectorIndustryName(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[1];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  getUniqueSelectedSectorCodes(arr) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
      let val = arr[i].split("####")[2];
      if (!a.includes(val)) {
        a.push(val);
      }
    }
    return a;
  }

  isAllIndustriesSelectedInSector(sector, selectedIndustries) {
    let availableIndustries = 0;
    for (let i = 0; i < this.industrySubIndustryList.length; i++) {
      if (sector === this.industrySubIndustryList[i].category) {
        availableIndustries = this.industrySubIndustryList[i].industries.length;
      }
    }
    let selectedIndustriesCnt = 0;
    for (let i = 0; i < selectedIndustries.length; i++) {
      let sectorIndustry = selectedIndustries[i].split("####")[0];
      if (sector === sectorIndustry) {
        selectedIndustriesCnt = selectedIndustriesCnt + 1;
      }
    }

    if (availableIndustries === selectedIndustriesCnt) {
      return true;
    } else {
      return false;
    }
  }

  setCheckedSectorIndustryList(selectedSectors) {
    store.dispatch(
      userSelectedIndustriesAction.setIndustryFilter(selectedSectors)
    );
    /* Deep Copy Checked Regions */
    let tempSelectedSectors = JSON.parse(JSON.stringify(selectedSectors));

    /* Get Unique Regions */
    let selectedSectorList = this.getUniqueSelectedSectorName(selectedSectors);

    /* For Selected Region check if All Countries selected */

    for (let i = 0; i < selectedSectorList.length; i++) {
      /* Check if All countries are selected for Region */
      let isAllSelected = this.isAllIndustriesSelectedInSector(
        selectedSectorList[i],
        selectedSectors
      );

      if (isAllSelected === true) {
        /* Remove Countries for Region where All are selected */
        let newArray = [];
        for (let j = 0; j < tempSelectedSectors.length; j++) {
          let sectorIndustry = tempSelectedSectors[j].split("####")[0];

          if (selectedSectorList[i] === sectorIndustry) {
            continue;
          } else {
            newArray.push(tempSelectedSectors[j]);
          }
        }
        tempSelectedSectors = newArray;
      }
    }
    let selectedIndustryList =
      this.getUniqueSelectedSectorIndustryName(tempSelectedSectors);

    let majorIndustryCodes = [];
    for (let i = 0; i < selectedSectors.length; i++) {
      let industryCode = selectedSectors[i].split("####")[2];
      if (!majorIndustryCodes.includes(industryCode)) {
        majorIndustryCodes.push(industryCode);
      }
    }

    this.setState({
      checkedSectorIndustryList: selectedSectors,
      selectedSectorList: selectedSectorList,
      selectedIndustryList: selectedIndustryList,
      majorIndustryCodes: majorIndustryCodes,
    });
    store.dispatch(filterSectorAction.setSectorFilter(selectedSectorList));
    if (this.state.onSectorSelection) {
      this.state.onSectorSelection(
        selectedIndustryList,
        selectedSectorList,
        majorIndustryCodes
      );
    }
  }

  applyFilter() {
    if (this.state.onApplyFilter) {
      this.state.onApplyFilter();
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isSimpleSearch && this.state.isSimpleSearch === true) {
        return <></>;
      }
      if (this.state.isAlphabetSearch && this.state.isAlphabetSearch === true) {
        return <></>;
      }
      let regionNodes = [];
      for (let i = 0; i < this.regionCountryList.length; i++) {
        let region = this.regionCountryList[i];
        let regionText = region.region.toString().trim();
        let node = {
          key: { i },
          value: regionText,
          label:
            regionText + "(" + (region.regionCnt ? region.regionCnt : "") + ")",
        };
        node.children = [];
        for (let j = 0; j < region.countries.length; j++) {
          let regionText = region.region.toString().trim();
          let countryText = region.countries[j].country.toString().trim();
          let countryCode = region.countries[j].countryCode.toString().trim();
          node.children.push({
            key: regionText + "####" + countryText + "####" + countryCode,
            value: regionText + "####" + countryText + "####" + countryCode,
            label: countryText + "(" + region.countries[j].countryCnt + ")",
          });
        }
        regionNodes.push(node);
      }

      let industryNodes = [];
      for (let i = 0; i < this.industrySubIndustryList.length; i++) {
        let category = this.industrySubIndustryList[i];
        let node = {
          key: { i },
          value: category.category,
          label: category.category + "(" + category.categoryCnt + ")",
        };
        node.children = [];
        for (let j = 0; j < category.industries.length; j++) {
          node.children.push({
            value:
              category.category +
              "####" +
              category.industries[j].industryCode +
              "####" +
              category.industries[j].corpInfoIndustry,
            label:
              category.industries[j].industryCode +
              "(" +
              category.industries[j].companyCnt +
              ")",
          });
        }
        industryNodes.push(node);
      }
      const icons = {
        check: <MdCheckBox className="rct-icon rct-icon-check" />,
        uncheck: (
          <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />
        ),
        halfCheck: <MdCheckBox className="rct-icon rct-icon-check" />,
        expandClose: <MdAddBox className="rct-icon rct-icon-expand-all" />,
        expandOpen: (
          <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
        ),
        expandAll: (
          <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
        ),
        collapseAll: (
          <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
        ),
        parentClose: (
          <MdFolder
            style={{ display: "none" }}
            className="rct-icon rct-icon-parent-close"
          />
        ),
        parentOpen: (
          <MdFolderOpen
            style={{ display: "none" }}
            className="rct-icon rct-icon-parent-open"
          />
        ),
        leaf: (
          <MdInsertDriveFile
            style={{ display: "none" }}
            className="rct-icon rct-icon-leaf-close"
          />
        ),
      };
      return (
        <Col
          md={4}
          style={{
            background: "#f1cccc",
            boxShadow: "2px 2px 2px gray",
            margin: "0",
            padding: "0",
            paddingLeft: "0.7em",
            transition:
              "width 3s, height 3s, background-color 3s, transform 3s",
            height: "max-content",
          }}
        >
          <Row style={{ margin: "auto", display: "flex" }}>
            <Col style={{ margin: "auto" }}>
              <strong style={{ float: "left" }}>Advanced Search</strong>

              <Tooltip title="Cancel Advance Search">
                <CancelIcon
                  size="small"
                  style={{
                    color: "black",
                    margin: "5px",
                    float: "right",
                    background: "white",
                    boxShadow: "1px 1px 3px white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.toSimpleSearch()}
                />
              </Tooltip>
              <Tooltip title="Reset Search Criterias">
                <RotateLeftOutlinedIcon
                  size="small"
                  style={{
                    color: "white",
                    background: "gray",
                    boxShadow: "1px 1px 3px white",
                    margin: "5px",
                    float: "right",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.resetAdvanceSearch()}
                />
              </Tooltip>
              <Tooltip title="Apply Filter">
                <CheckCircleIcon
                  size="small"
                  style={{
                    color: "#1b7189",
                    background: "#fff",
                    margin: "5px",
                    float: "right",
                    boxShadow: "1px 1px 3px white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={(e) => this.applyFilter()}
                />
              </Tooltip>
            </Col>
          </Row>

          <Row>
            <Tabs
              defaultIndex={0}
              style={{
                width: "100%",
                outline: "none",
                margin: "0",
                padding: "1em",
              }}
            >
              <TabList
                style={{
                  outline: "none",
                  margin: "0",
                }}
              >
                <Tab>Global Regions</Tab>
                <Tab>Industry Categories</Tab>
              </TabList>
              <TabPanel
                style={{
                  margin: "auto",
                  fontSize: "large",
                  padding: "1em",
                  overflow: "scroll",
                  maxHeight: "400px",
                  minHeight: "400px",
                }}
              >
                <h6>
                  <b>Select Global Region </b>(optional)
                </h6>

                <CheckboxTree
                  icons={icons}
                  nodes={regionNodes}
                  checked={this.state.checkedRegionCountryList}
                  expanded={this.state.expanded}
                  style={{ fontSize: "1.2em" }}
                  onCheck={(checkedRegionCountry) =>
                    this.setCheckedRegionCountryList(checkedRegionCountry)
                  }
                  onExpand={(expanded) => this.setState({ expanded })}
                />
              </TabPanel>
              <TabPanel
                style={{
                  margin: "auto",
                  fontSize: "large",
                  padding: "1em",
                  overflow: "scroll",
                  maxHeight: "400px",
                  minHeight: "400px",
                }}
              >
                <h6>
                  <b>Select Global Industries </b>(optional)
                </h6>

                <CheckboxTree
                  icons={icons}
                  nodes={industryNodes}
                  checked={this.state.checkedSectorIndustryList}
                  expanded={this.state.expanded1}
                  onCheck={(checkedSectorIndustry) =>
                    this.setCheckedSectorIndustryList(checkedSectorIndustry)
                  }
                  onExpand={(expanded1) => this.setState({ expanded1 })}
                />
              </TabPanel>
            </Tabs>
          </Row>
        </Col>
      );
    }
  }
}
