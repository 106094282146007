import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { CardBody, Col, Row } from "reactstrap";
import store from "../../redux/store";
import GenericButton from "../buttons/GenericButton";
import Advertisement from "../ciadvertisement/Advertisement";
export default class SimpleSearch extends React.Component {
  regionCountryList = store.getState().staticData.regionCountryData;
  industrySubIndustryList = store.getState().staticData.industrySubIndustryData;
  filterSector = store.getState().sectorFilterData.selectedSectors;
  userselectedIndustries =
    store.getState().industryFilterData.selectedIndustries;
  constructor(props) {
    super(props);

    let regions = "";
    let countries = "";
    let sectors = "";
    let industries = "";
    let countryCodesPar = "";
    let countryCodes = [];
    let subIndustries = [];
    let code = "";
    let filterData = Object.getOwnPropertyNames(props.filterData);
    if (filterData.length > 0) {
      regions = props.filterData.regionPar;
      countries = props.filterData.countryPar;
      // if (props.filterData.industryList.length)
      // industries = props.filterData.industryList.toString();
      let datatype = typeof props.filterData.countryCodesPar;
      if (datatype === "object") {
        countryCodesPar = props.filterData.countryCodesPar.toString();
      }
      countryCodes = countryCodesPar.split(",");

      // for (let i = 0; i < this.industrySubIndustryList.length; i++) {
      //   for (let j = 0; j < industryCodes.length; j++) {}
      // }
    }
    if (this.userselectedIndustries.length > 0) {
      for (let i = 0; i < this.userselectedIndustries.length; i++) {
        code = this.userselectedIndustries[i].split("####")[1];
        subIndustries.push(this.userselectedIndustries[i].split("####")[1]);
      }
      industries = subIndustries.toString();
    }
    if (this.filterSector.length > 0) {
      sectors = this.filterSector.toString();
    }
    this.state = {
      searchText: props.searchText,
      regions: regions,
      countries: countries,
      countryCodes: countryCodes,
      sectors: sectors,
      industries: industries,
      industryCodes: [],
      industryList: [],
      isAdvanceSearch: props.isAdvanceSearch,
      isSimpleSearch: props.isSimpleSearch,
      isAlphabetSearch: props.isAlphabetSearch,
      onOptionChange: props.onOptionChange,
      onSearch: props.onSearch,
    };
  }

  resetAdvanceSearch() {
    this.setState({
      regions: "",
      countries: "",
      countryCodes: [],
      sectors: "",
      industries: "",
      industryCodes: [],
      industryList: [],
    });
  }
  handleSearchTextChange(e) {
    this.setState({ searchText: e.target.value });
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.performSearch();
    }
  };

  performSearch() {
    if (this.state.onSearch) {
      this.state.onSearch(
        this.state.searchText,
        this.state.regions,
        this.state.countries,
        this.state.countryCodes,
        this.state.sectors,
        this.state.industries,
        this.state.isAdvanceSearch,
        this.state.industryCodes,
        this.state.industryList,
        true
      );
    }
  }
  toAdvanceSearch = (event, value) => {
    this.setState({
      isSimpleSearch: false,
      isAdvanceSearch: true,
      isAlphabetSearch: false,
    });
    if (this.state.onOptionChange) {
      this.state.onOptionChange("advance");
    }
  };

  getAdvanceSearch() {
    if (this.state.isAdvanceSearch === false) {
      return (
        <a
          // href="#"
          style={{
            cursor: "pointer",
            fontSize: "small",
            color: "#f26d21",

            textAlign: "center",
            outline: "none",
            width: "100%",
            margin: "auto",
          }}
          onClick={this.toAdvanceSearch.bind(this)}
        >
          {" "}
          &nbsp;&nbsp;
          <strong>
            <i>
              <u>Advanced Search</u>
            </i>
          </strong>
        </a>
      );
    } else {
      return "";
    }
  }

  render() {
    return (
      <Row md={12} style={{ display: "flex", margin: "5px" }}>
        <Col md={4} style={{ display: "flex", alignItems: "center" }}>
          <Grid container>
            <Grid item style={{ width: "50%" }}>
              <input
                type="text"
                id="searchText"
                name="searchText"
                value={this.state.searchText}
                autocomplete="off"
                placeholder="Company Name or Ticker"
                style={{
                  border: "1px solid #d0cfcf",
                  width: "100%",
                  height: "100%",
                  borderRadius: "0",
                  fontSize: "small",
                  outline: "none",
                  boxShadow: "1px 1px 1px #bdbbbb",
                }}
                required
                onChange={this.handleSearchTextChange.bind(this)}
                onKeyPress={this.handleKeyPress}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "10%" }}
            >
              {" "}
              <GenericButton
                tooltip="Search"
                onClick={this.performSearch.bind(this)}
                icon={<SearchIcon />}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "40%" }}
            >
              {this.getAdvanceSearch()}
            </Grid>
          </Grid>
        </Col>
        <Col md={3} style={{ display: "flex" }}>
          <CardBody style={{ width: "100%" }}>
            <Row md={12} style={{ alignItems: "flex-end", width: "104%" }}>
              <Col
                md={4}
                style={{
                  color: "#b75815",
                  padding: "0",
                  fontWeight: "bold",
                  fontSize: "small",
                }}
              >
                Ticker/Company
              </Col>
              <Col md={8} style={{ padding: "0" }}>
                <i
                  style={{
                    color: "black",
                    fontSize: "small",
                  }}
                >
                  {this.state.searchText && this.state.searchText.length > 0
                    ? this.state.searchText
                    : "   "}
                </i>
              </Col>
            </Row>
            {this.state.isAdvanceSearch && this.state.isAdvanceSearch === true && (
              <>
                <Row md={12} style={{ alignItems: "flex-end", width: "100%" }}>
                  <Col
                    md={4}
                    style={{
                      color: "#b75815",
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "small",
                    }}
                  >
                    Regions
                  </Col>
                  <Col md={8} style={{ padding: "0" }}>
                    <i
                      style={{
                        color: "black",
                        fontSize: "small",
                      }}
                    >
                      {this.state.regions && this.state.regions.length > 0
                        ? this.state.regions
                        : "   "}
                    </i>
                  </Col>
                </Row>

                <Row md={12} style={{ alignItems: "flex-end", width: "100%" }}>
                  <Col
                    md={4}
                    style={{
                      color: "#b75815",
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "small",
                    }}
                  >
                    Countries
                  </Col>
                  <Col md={8} style={{ padding: "0" }}>
                    <i
                      style={{
                        color: "black",
                        fontSize: "small",
                      }}
                    >
                      {this.state.countries && this.state.countries.length > 0
                        ? this.state.countries
                        : "   "}
                    </i>
                  </Col>
                </Row>
                <Row md={12} style={{ alignItems: "flex-end", width: "100%" }}>
                  <Col
                    md={4}
                    style={{
                      color: "#b75815",
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "small",
                    }}
                  >
                    <span> Industries</span>
                  </Col>
                  <Col md={8} style={{ padding: "0" }}>
                    <i
                      style={{
                        color: "black",
                        fontSize: "small",
                      }}
                    >
                      {this.state.sectors && this.state.sectors.length > 0
                        ? this.state.sectors
                        : "   "}
                    </i>
                  </Col>
                </Row>
                <Row md={12} style={{ alignItems: "flex-end", width: "100%" }}>
                  <Col
                    md={4}
                    style={{
                      color: "#b75815",
                      padding: "0",
                      fontWeight: "bold",
                      fontSize: "small",
                    }}
                  >
                    Sub Industries
                  </Col>
                  <Col md={8} style={{ padding: "0" }}>
                    <i
                      style={{
                        color: "black",
                        fontSize: "small",
                      }}
                    >
                      {this.state.industries && this.state.industries.length > 0
                        ? this.state.industries
                        : "   "}
                    </i>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Col>
        <Col md={4} style={{ display: "flex" }}>
          <Advertisement advertisementType="SearchPageHorizontalAddUnit" />
        </Col>
        <Col md={1} style={{ display: "flex" }}></Col>
      </Row>
    );
  }
}
