import {
  GET_INDUSTRY_FILTER,
  SET_INDUSTRY_FILTER,
} from "./userSelectedIndustriesTypes";

export const getIndustryFilter = () => {
  return {
    type: GET_INDUSTRY_FILTER,
  };
};

export const setIndustryFilter = (data) => {
  return {
    type: SET_INDUSTRY_FILTER,
    payload: data,
  };
};

export default { getIndustryFilter, setIndustryFilter };
