import { GET_REPORT_PRICE, SET_REPORT_PRICE } from "./reportPriceTypes";

export const getPriceData = () => {
  return {
    type: GET_REPORT_PRICE,
  };
};

export const setPriceData = (data) => {
  return {
    type: SET_REPORT_PRICE,
    payload: data,
  };
};

export default { getPriceData, setPriceData };
