import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import React, { useEffect, useReducer } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import "./IndividualReportCheckout.css";
const fetchCheckoutSession = async ({ quantity }) => {
  let input = { quantity: quantity };
  Axios.post("pg/create-checkout-session", input).then((response) => {});
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case "useEffectUpdate":
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case "increment":
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case "decrement":
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case "setLoading":
      return { ...state, loading: action.payload.loading };
    case "setError":
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const IndividualReportCheckout = (props) => {
  let userData = "";

  let selectedPlan = "";
  let totalPrice = "";
  if (props.history && props.history.location && props.history.location.state) {
    selectedPlan = props.history.location.state.planDetails;
    totalPrice = props.history.location.state.totalPrice;
    userData = props.history.location.state.userDetails;
  }
  let reportList = [];
  for (let i = 0; i < selectedPlan.length; i++) {
    reportList.push(
      <tr>
        <td colSpan={2} style={{ fontSize: "1em" }}>
          <span>
            {selectedPlan[i].planId === "3" ? (
              <img
                src="/home/assets/img/CartReportImage.gif"
                alt="Company Report"
                height="20%"
                style={{ float: "left" }}
              ></img>
            ) : (
              <img
                src="/home/assets/img/ind_cart_rpt.gif"
                alt="Company Report"
                height="20%"
                style={{ float: "left" }}
              ></img>
            )}
          </span>
        </td>
        <td colSpan={2} style={{ fontSize: "1em" }}>
          <span style={{ textAlign: "center" }}>
            {selectedPlan[i].type ? selectedPlan[i].type + " - " : ""}
            {selectedPlan[i].companyName}
          </span>
        </td>
        <td colSpan={2} style={{ fontSize: "1em" }}>
          <span style={{ float: "right" }}>{"$" + selectedPlan[i].price}</span>
        </td>
      </tr>
    );
  }
  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
    planDetails: selectedPlan,
    totalPrice: totalPrice,
    userData: userData,
  });

  useEffect(() => {
    async function fetchConfig() {
      await FetchServerData.callPostService("pg/config", "")
        .then((output) => {
          if (output.status === "SUCCESS") {
            let responseData = output.data;
            loadStripe(responseData.publicKey).then((stripeResp) => {
              dispatch({
                type: "useEffectUpdate",
                payload: {
                  unitAmount: responseData.unitAmount,
                  currency: responseData.currency,
                  stripe: stripeResp,
                },
              });
            });
          }
        })
        .catch((error) => {});
    }
    fetchConfig();
  }, []);

  const handleClick = async (event) => {
    dispatch({ type: "setLoading", payload: { loading: true } });
    let sessionId = "";
    let orderItems = [];
    for (let i = 0; i < selectedPlan.length; i++) {
      let orderObj = {};
      if (selectedPlan[i].type === "Company") {
        orderObj = {
          type: selectedPlan[i].type,
          description: selectedPlan[i].companyName,
          plan: { id: selectedPlan[i].planId },
          uid: selectedPlan[i].cusip,
        };
      } else {
        orderObj = {
          type: selectedPlan[i].type,
          description: selectedPlan[i].companyName,
          plan: { id: selectedPlan[i].planId },
          uid: selectedPlan[i].pdf,
        };
      }

      orderItems.push(orderObj);
    }
    let input = {
      user: {
        firstName: state.userData.firstName,
        lastName: state.userData.lastName,
        email: state.userData.email,
      },
      order: {
        email: state.userData.email,
        price: state.totalPrice,
        type: "OneTime",
        orderItems: orderItems,
      },
    };

    FetchServerData.callPostService("pg/create-checkout-session", input)
      .then((output) => {
        if (output.status === "SUCCESS") {
          sessionId = output.data.sessionId;
          let responseData = output.data;
          store.dispatch(cartAction.setCart([]));
          const { error } = state.stripe.redirectToCheckout({
            sessionId,
          });
          if (error) {
            dispatch({ type: "setError", payload: { error } });
            dispatch({ type: "setLoading", payload: { loading: false } });
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <div style={{ minHeight: "50em" }}>
      <Row style={{ marginTop: "5em" }}>
        <Col md={12}>
          <Card
            style={{
              width: "100%",
              marginTop: "3em",
              background: "transparent",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              <u>Review Your Order &amp; Complete Checkout</u>
            </span>
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "1em",
          marginLeft: "4em",
          marginRight: "4em",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Col md={8} style={{ display: "flex" }}>
          <Card
            style={{
              width: "100%",
              boxShadow:
                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
            }}
          >
            <CardHeader
              style={{
                background: "#2b7d6d",
                minHeight: "2em",
                padding: "0.5em",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "0.5em",
                }}
              >
                Order Details
              </span>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12}>
                  <table className="table table-striped">
                    <tbody>{reportList}</tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} style={{ display: "flex", flexDirection: "column" }}>
          <Card
            style={{
              width: "100%",
              boxShadow:
                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
            }}
          >
            <CardHeader
              style={{
                background: "#2b7d6d",
                minHeight: "2em",
                padding: "0.5em",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  padding: "0.5em",
                }}
              >
                Billing Details
              </span>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={12} style={{ margin: "0.5em" }}>
                  <span
                    style={{
                      fontSize: "1em",
                      textAlign: "left",
                      padding: "0.5em",
                    }}
                  >
                    {userData.firstName + " " + userData.lastName}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ margin: "0.5em" }}>
                  <span
                    style={{
                      fontSize: "1em",
                      textAlign: "left",
                      padding: "0.5em",
                    }}
                  >
                    {userData.email}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ margin: "0.5em" }}>
                  <span
                    style={{
                      fontSize: "1em",
                      textAlign: "left",
                    }}
                  >
                    {userData.phone}
                  </span>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "100%",
              marginTop: "2em",
              boxShadow:
                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
            }}
          >
            <CardHeader
              style={{
                background: "#2b7d6d",
                minHeight: "2em",
                padding: "0.5em",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  margin: "0.5em",
                }}
              >
                Payments
              </span>
            </CardHeader>
            <CardBody>
              <Row style={{ margin: "0.5em", alignItems: "center" }}>
                <Col md={6} style={{ margin: "0 auto" }}>
                  <b>
                    <span>Order Total : </span>
                    <span style={{ color: "green" }}>
                      {totalPrice ? "$" + totalPrice.toFixed(2) : ""}
                    </span>
                  </b>
                </Col>
                <Col md={6} style={{ margin: "0 auto" }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: "#f26d21",
                      color: "#fff",

                      borderBottom: "3px solid #636262",
                      borderRadius: "5px",
                      margin: "0 auto",
                      width: "100%",
                    }}
                    onClick={handleClick}
                  >
                    {state.loading || !state.price ? `Loading...` : `Pay Now`}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default IndividualReportCheckout;
