import Container from '@material-ui/core/Container';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Login from '../loginComponent/Login';
import GenericSearch from '../search/GenericSearch';
import './Payment.css';
export default class Payment extends Component {
  loginRefList = [];
  constructor(props) {
    super(props);
    let selectedPlan = '';
    let modalShow = '';
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      selectedPlan = props.history.location.state.selectedPlan;
      modalShow = props.history.location.state.modalShow;
    }
    this.state = {
      showModal: true,
      modalShow: modalShow,
      selectedPlan: selectedPlan,
    };
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    this.loginRefList = React.createRef();
    return (
      <>
        <section className="breadcrumbs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4>Payment</h4>
              </div>
              <div className="col-lg-6">
                <GenericSearch history={this.props.history} />
              </div>
            </div>
          </div>
        </section>

        <section>
          <Row style={{ margin: '-3em 0 0 0' }}>
            <Col md={1}></Col>
            <Col md={10}>
              <div>
                <div style={{ float: 'left' }}>
                  <img
                    src="/home/assets/img/SignUpImage.gif"
                    className="img-fluid"
                    alt=""
                    align="left"
                    style={{ width: '140px' }}
                  />
                </div>
                <div className="textDiv">
                  <h6 style={{ color: '#003B6D', fontWeight: 'revert' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Purchase Subscription
                    </span>
                    Wright Research Report Content
                  </h6>
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </section>

        <Container maxWidth="lg" className="contcss">
          <Card>
            <CardHeader>
              <Row>
                <Col md={8}>Description</Col>
                <Col md={4}>Price</Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={8}>
                  <span className="bodyTxtBold">
                    Global Basic Subscription - Paid Monthly
                  </span>
                  <br></br>
                  Month-to-Month Single Seat Subscription to Wright's Global
                  Basic Subscription service. Billed through your Amazon
                  account; this subscription renew's automatically each month.
                </Col>
                <Col md={4}>{this.state.selectedPlan}</Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Login
          ref={this.loginRefList}
          data={this.state.selectedPlan}
          pageId="plans"
          history={this.props.history}
          isLogin={true}
          modalShow={this.state.modalShow}
          showPage="Login"
        ></Login>
      </>
    );
  }
}
