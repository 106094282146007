import { GET_ADVANCE_FILTER, SET_ADVANCE_FILTER } from "./advanceFilterTypes";

const initialState = {
  advanceFilter: {},
};

const advanceFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVANCE_FILTER:
      return {
        ...state,
      };
    case SET_ADVANCE_FILTER:
      return {
        ...state,
        advanceFilter: action.payload,
      };
    default:
      return state;
  }
};

export default advanceFilterReducer;
