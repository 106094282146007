import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import {
  Card,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./SummaryAnalysisPerShare.css";
export default class SummaryAnalysisPerShare extends Component {
  constructor(props) {
    super(props);

    var d = new Date();
    var currentYear = d.getFullYear();
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      render: false,
      data: props.data,
      summaryAnalysisData: [],
      labelData: [],
      showModal: false,
      currentYear: currentYear,
      tableGraphData: {},
      isPdfReport: isPdfReport,
    };
  }

  componentDidMount() {
    let labels = [];
    let priceData = [];
    let profitRateData = [];
    let twelveMonthEarningData = [];
    let twelveMonthDividendData = [];
    let priceEarningData = [];
    let priceBookData = [];
    let dividendYieldData = [];
    for (let i = 0; i < this.state.data.summaryAnalysisList.length; i++) {
      labels.push(this.state.data.summaryAnalysisList[i].year);
      priceData.push(this.state.data.summaryAnalysisList[i].price);
      profitRateData.push(this.state.data.summaryAnalysisList[i].profitRate);
      twelveMonthEarningData.push(
        this.state.data.summaryAnalysisList[i].yearEarningperShare
      );
      twelveMonthDividendData.push(
        this.state.data.summaryAnalysisList[i].yearDividendPerShare
      );
      priceEarningData.push(
        this.state.data.summaryAnalysisList[i].yearDividendPerShare
      );
      priceBookData.push(
        this.state.data.summaryAnalysisList[i].yearDividendPerShare
      );
      dividendYieldData.push(
        this.state.data.summaryAnalysisList[i].yearDividendPerShare
      );
    }
    this.setState({
      currency: this.state.data.currency,
      summaryAnalysisData: this.state.data.summaryAnalysisList,
      priceEarningData: priceEarningData,
      priceBookData: priceBookData,
      dividendYieldData: dividendYieldData,
      render: true,
      labelData: labels,
      fiscalEndYear: this.state.data.fis_end,
      priceData: priceData,
      profitRateData: profitRateData,
      twelveMonthEarningData: twelveMonthEarningData,
      twelveMonthDividendData: twelveMonthDividendData,
      TableOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  to: 0,
                  from: -100000000000,
                  color: "#F15B46",
                },
              ],
            },
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "price") {
      let priceData = [];
      for (let i = 0; i < this.state.summaryAnalysisData.length; i++) {
        priceData.push(this.state.summaryAnalysisData[i].price);
      }
      this.setState({
        graphDetails: {
          graphValues: priceData,
          labelData: this.state.labelData,
          yAxisTitle: "Price",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        tableGraphData: {
          graphValues: priceData,
        },
        showModal: true,
        graphType: "Price",
      });
    } else if (graphType === "Value Ratios") {
      let priceEarningData = [];
      let priceBookData = [];
      let dividendYieldData = [];
      for (let i = 0; i < this.state.summaryAnalysisData.length; i++) {
        priceEarningData.push(
          this.state.summaryAnalysisData[i].priceEarningsRatio
        );
        priceBookData.push(this.state.summaryAnalysisData[i].priceBookRatio);
        dividendYieldData.push(this.state.summaryAnalysisData[i].dividendYield);
      }

      this.setState({
        showModal1: true,
        graphType: graphType,
        series: [
          {
            name: "Price/Earnings Ratio",
            data: priceEarningData,
          },
          {
            name: "Price/Book Ratio",
            data: priceBookData,
          },
          {
            name: "Dividend Yield",
            data: dividendYieldData,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          colors: [
            "#e74c3c",
            "#8e44ad",
            "#2e86c1",
            "#1abc9c",
            " #f39c12",
            "#3d3d6b",
            "#00baad",
            "#511849",
            "#900c3f",
          ],
          title: {
            text: "Value Ratios",
            align: "left",
          },
          grid: {
            row: {},
          },
          legend: {
            show: true,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },
          xaxis: {
            categories: this.state.labelData,
          },
        },
      });
    } else if (graphType === "Equity Capital") {
      let earnGrowthData = [];
      let profitRateData = [];
      let bookValueYearData = [];
      for (let i = 0; i < this.state.summaryAnalysisData.length; i++) {
        earnGrowthData.push(this.state.summaryAnalysisData[i].earnedGrowth);
        profitRateData.push(this.state.summaryAnalysisData[i].profitRate);
        bookValueYearData.push(
          this.state.summaryAnalysisData[i].bookValueBeginYr
        );
      }

      this.setState({
        showModal: true,
        graphType: graphType,
        graphDetails: {
          graphValues: profitRateData,
          labelData: this.state.labelData,
          yAxisTitle: "Equity Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "%",
        },

        graphType: graphType,
      });
    } else if (graphType === "Earnings") {
      let twelveMonthEarningData = [];
      let changeData = [];

      for (let i = 0; i < this.state.summaryAnalysisData.length; i++) {
        twelveMonthEarningData.push(
          this.state.summaryAnalysisData[i].yearEarningperShare
        );
        changeData.push(this.state.summaryAnalysisData[i].percentChange);
      }

      this.setState({
        showModal: true,
        graphType: graphType,
        graphDetails: {
          graphValues: twelveMonthEarningData,
          labelData: this.state.labelData,
          yAxisTitle: "Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else {
      let payoutRatioData = [];
      let twelveMonthDividendData = [];

      for (let i = 0; i < this.state.summaryAnalysisData.length; i++) {
        payoutRatioData.push(
          this.state.summaryAnalysisData[i].percentPayoutRatio
        );
        twelveMonthDividendData.push(
          this.state.summaryAnalysisData[i].yearDividendPerShare
        );
      }

      this.setState({
        showModal: true,
        graphType: graphType,
        graphDetails: {
          graphValues: twelveMonthDividendData,
          labelData: this.state.labelData,
          yAxisTitle: "Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toggle1 = () => {
    this.setState({ showModal1: !this.state.showModal1 });
  };
  render() {
    let itemRow = this.state.summaryAnalysisData.map((summaryAnalysis, i) => {
      return (
        <tr
          style={i % 2 ? { background: "#cae1ff" } : { background: "white" }}
          key={i}
        >
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {summaryAnalysis.year !== this.state.currentYear ? (
              summaryAnalysis.year
            ) : (
              <span title="Not Applicable">
                {moment(summaryAnalysis.fyEndDate).format("MM/DD/YYYY")}
              </span>
            )}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {summaryAnalysis.price !== null
              ? summaryAnalysis.price.toFixed(2)
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.priceEarningsRatio !== null ? (
              summaryAnalysis.priceEarningsRatio.toFixed(2)
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.priceBookRatio !== null ? (
              summaryAnalysis.priceBookRatio.toFixed(2)
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {summaryAnalysis.dividendYield !== null ? (
              summaryAnalysis.dividendYield.toFixed(2) + "%"
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {" "}
            {summaryAnalysis.earnedGrowth !== null ? (
              summaryAnalysis.earnedGrowth.toFixed(2) + "%"
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.profitRate !== null ? (
              summaryAnalysis.profitRate.toFixed(2) + "%"
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {summaryAnalysis.bookValueBeginYr !== null ? (
              summaryAnalysis.bookValueBeginYr.toFixed(2)
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.yearEarningperShare !== null ? (
              summaryAnalysis.yearEarningperShare.toFixed(2)
            ) : (
              <span title="Not Meaningful">n/m</span>
            )}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {summaryAnalysis.percentChange !== null ? (
              summaryAnalysis.percentChange.toFixed(2) + "%"
            ) : (
              <span title="Not Meaningful">n/m</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.percentPayoutRatio !== null ? (
              summaryAnalysis.percentPayoutRatio.toFixed(2) + "%"
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {summaryAnalysis.yearDividendPerShare !== null ? (
              summaryAnalysis.yearDividendPerShare.toFixed(2)
            ) : (
              <span title="Not Applicable">n/a</span>
            )}
          </td>
        </tr>
      );
    });
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <span>
                  <span className="section_header">
                    Summary Analysis Per Share
                  </span>
                  <br></br>
                  <span className="unit_str">
                    All Figures in &nbsp;
                    <span>
                      {this.state.currency !== null ? this.state.currency : ""}
                    </span>
                  </span>
                </span>
                <Table
                  className="sum_analysis"
                  style={{
                    border: "1px solid",
                    margin: "0.5em 0em 0em 0em",
                  }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="sum_analysis"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) => this.getDataInGraphicalForm("price")}
                      >
                        Price
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="3"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Value Ratios")
                        }
                      >
                        Value Ratios
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="3"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Equity Capital")
                        }
                      >
                        Equity Capital
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                      >
                        Earnings
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Dividends")
                        }
                      >
                        Dividends
                      </th>
                    </tr>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        {" "}
                        Fiscal Yr Ends: {this.state.fiscalEndYear}
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="The Closing Price of the stock for the respective period."
                      >
                        Market Price Last
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Market Price at Period End divided by Earnings Per Share at Close of respective period."
                      >
                        Price/ Earnings Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Market price at Period End divided by Book Value Per Share at Beginning of Period."
                      >
                        Price/ Book Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Dividend for the Period divided by the Price at the Close of the respective Period."
                      >
                        Dividend Yield
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents ((Earnings for the Year - Dividends for the Year) / Book Value at the beginning of the year) * 100. Based on Calendar year period for U.S. companies and Fiscal year period for international companies."
                      >
                        % Earned Growth
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="(Earnings Per Share for the year divided by Beginning of Year Book Value Per Share) * 100"
                      >
                        % Profit Rate (ROE)
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Book Value Per Share represents the Book Value (proportioned common equity divided by outstanding shares) at the company?s Fiscal Year end for non-U.S. corporations and Calendar Year end for U.S. corporations. For U.S. corporations, common and common equivalent and fully diluted book values are shown, when available.For companies with more than one type of common/ordinary share, the book value is based on combined shares adjusted for the par value of the share type."
                      >
                        {" "}
                        Book Value Begin Yr
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months. For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                      >
                        12 Month Earnings Per Share
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents [(Earnings Per Share Latest 12 Months / Earnings Per Share Prior 12 Month period) ? 1] * 100. "
                      >
                        % Change
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents (12 month Dividends Per Share / 12 month Earnings Per Share) * 100"
                      >
                        % Payout Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Dividends Per Share Paid out over Last 12 months. U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis. "
                      >
                        12 Month Dividends Per Share
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <span>
                  <span className="section_header">
                    {" "}
                    Summary Analysis Per Share
                  </span>
                  <br></br>
                  <span className="unit_str">
                    All Figures in&nbsp;
                    <span>
                      {this.state.currency !== null ? this.state.currency : ""}
                    </span>
                  </span>
                </span>
                <Table
                  responsive
                  className="sum_analysis"
                  style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="sum_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        Price
                        <GraphIconButton
                          onClick={(e) => this.getDataInGraphicalForm("price")}
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="3"
                        style={{ borderRight: "1px solid" }}
                      >
                        Value Ratios
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Value Ratios")
                          }
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="3"
                        style={{ borderRight: "1px solid" }}
                      >
                        Equity Capital
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Equity Capital")
                          }
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                      >
                        Earnings
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Earnings")
                          }
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                      >
                        Dividends
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Dividends")
                          }
                        ></GraphIconButton>
                      </th>
                    </tr>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        {" "}
                        Fiscal Yr Ends: {this.state.fiscalEndYear}
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="The Closing Price of the stock for the respective period."
                      >
                        Market Price Last
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Market Price at Period End divided by Earnings Per Share at Close of respective period."
                      >
                        Price/ Earnings Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Market price at Period End divided by Book Value Per Share at Beginning of Period."
                      >
                        Price/ Book Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Dividend for the Period divided by the Price at the Close of the respective Period."
                      >
                        Dividend Yield
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents ((Earnings for the Year - Dividends for the Year) / Book Value at the beginning of the year) * 100. Based on Calendar year period for U.S. companies and Fiscal year period for international companies."
                      >
                        % Earned Growth
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="(Earnings Per Share for the year divided by Beginning of Year Book Value Per Share) * 100"
                      >
                        % Profit Rate (ROE)
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Book Value Per Share represents the Book Value (proportioned common equity divided by outstanding shares) at the company?s Fiscal Year end for non-U.S. corporations and Calendar Year end for U.S. corporations. For U.S. corporations, common and common equivalent and fully diluted book values are shown, when available.For companies with more than one type of common/ordinary share, the book value is based on combined shares adjusted for the par value of the share type."
                      >
                        {" "}
                        Book Value Begin Yr
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months. For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                      >
                        12 Month Earnings Per Share
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents [(Earnings Per Share Latest 12 Months / Earnings Per Share Prior 12 Month period) ? 1] * 100. "
                      >
                        % Change
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents (12 month Dividends Per Share / 12 month Earnings Per Share) * 100"
                      >
                        % Payout Ratio
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Dividends Per Share Paid out over Last 12 months. U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis. "
                      >
                        12 Month Dividends Per Share
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={9}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.graphType}
                    </Label>
                  </Col>
                  <Col md={3}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <Card style={{ marginBottom: "1em" }}>
                      <SingleAxisGenericGraph
                        graphData={this.state.graphDetails}
                      ></SingleAxisGenericGraph>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.showModal1}
              toggle={this.toggle1}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={9}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Summary Analysis Per Share
                    </Label>
                  </Col>
                  <Col md={3}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle1}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <Card style={{ marginBottom: "1em" }}>
                      <ReactApexCharts
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        height={350}
                        width={450}
                      />
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
