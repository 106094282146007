import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { Row } from "reactstrap";
import "./TopHundredFilterMethodology.css";
export default class TopHundredFilterMethodology extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row style={{ marginTop: "5em" }}>
          <Container
            maxWidth="lg"
            style={{ marginTop: "3em" }}
            className="top100_rationale"
          >
            <h4>Rationale</h4>
            <p>
              The Top/Bottom 100 Lists are presented as a tool to assist in the
              selection of companies based on specific criteria (i.e.
              Price/Earnings Ratio, Market Capitalization, etc.). By their very
              nature, lists of this type include extreme values that on occasion
              may appear exaggerated. Quite often these values have very little
              relevance within a prudent research or investment selection
              process. An example of this phenomenon is a company that increases
              total net income from $1 US to $10 US. On a percentage basis the
              company has experienced a growth of 1,000% but with only $10 in
              total net income the company merits very little attention (at
              least on the basis of its profitability).
            </p>

            <p>
              Top/Bottom Lists can also serve as a tool to conduct quality
              checks of the data. Extreme values on a percentage basis may
              result from incremental changes in the data as explained above or
              they may in fact reflect data errors. We are very proud of the
              consistently high quality of the data contained within
              CorporateInformation. However, if you discover what you believe to
              be a data error please bring it to our attention. We will check it
              out and report back with our findings.
            </p>

            <h5>
              Top/Bottom 100 Lists – Conditions and Exclusions that are applied
              in developing the lists.
            </h5>
            <ol>
              <li>
                <span>Size of Lists.</span>&nbsp;The Top/Bottom 100 research
                results lists are limited to a maximum of 100 companies.{" "}
              </li>
              <li>
                <span>Currency of Price Data.</span>&nbsp;Companies with a
                “recent” security price date older than 60 days are excluded..
              </li>
              <li>
                <span>Market Capitalization.</span>&nbsp;Companies with a
                current Market Capitalization of less than one million US
                dollars are excluded.
              </li>
              <li>
                <span>Wright Quality Ratings – Individual Criterion.</span>
                &nbsp;If the resultant rating for a specific screening variable
                (i.e. Financial Strength) is “N” (not rated) the company will
                not be shown on the research results list for that specific
                criterion.
              </li>
              <li>
                <span>Wright Quality Ratings – Composite Score.</span>&nbsp; For
                research efficiency, companies with a composite score of “0”
                will be excluded.{" "}
              </li>
              <li>
                <span>Dividend Payout.</span>&nbsp;If the company’s declared
                dividends are equal to or greater than reported earnings a
                dividend payout value of 100% is assigned.
              </li>
              <li>
                <span>Excluded Values </span>&nbsp;- Companies with negative or
                zero values for specific data variables are excluded from the
                following Top/Bottom lists: Debt/Equity Ratio, Dividend Yield,
                Dividend Payout, Earnings per Share Growth – One Year,
                Price/Book Value, Price/Earnings Ratio, Sales, and Return on
                Equity.
              </li>
            </ol>

            <p>
              <span>Definitions of Financial Terms.</span>&nbsp; Definitions of
              the data items used in the Top/Bottom values can be found in the
              &nbsp;
              <span style={{ textDecoration: "underline", fontWeight: "500" }}>
                Definitions of Financial Terms
              </span>{" "}
              section of the website.{" "}
            </p>
            <p>
              The information contained within the Top/Bottom lists should be
              used for reference purposes only. The information is believed
              reliable, but accuracy and completeness are not guaranteed.
            </p>
          </Container>
        </Row>
      </>
    );
  }
}
