import Container from "@material-ui/core/Container";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import "../../../node_modules/rc-tabs/assets/index.css";
import Advertisement from "../ciadvertisement/Advertisement";
import GenericSearch from "../search/GenericSearch";
import TopHundredItem from "./TopHundredItem";
import "./TopHundredList.css";
export default class TopHundredList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      data: [],
      type: "Market Capitalization",
      render: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleSelect(key) {
    if (key === 1) {
    } else {
    }
  }
  handleChange = (event, value) => {
    this.setState({
      value: value,
    });
  };
  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container marquee">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>Top 100 Lists</h4>
                  </div>
                  <div className="col-lg-6">
                    <GenericSearch history={this.props.history} color="white" />
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <div style={{ minHeight: "100em" }}></div>
        </>
      );
    } else {
      return (
        <>
          <Container maxWidth="lg" style={{ marginTop: "5em" }}>
            <Row></Row>
            <Row className="pts_mobile_view">
              <Col md={10} className="top_hundred">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange.bind(this)}
                  indicatorColor="primary"
                  textColor="primary"
                  className={this.state.tabTop}
                  scrollButtons="auto"
                  style={{ marginTop: "1em" }}
                >
                  <Tab label="Market Capitalization" wrapped />
                  <Tab label="Net Sales" wrapped />
                  <Tab label="Operating Margin" wrapped />
                  <Tab label="52-Week Price Change" wrapped />
                  <Tab label="3-Year Sales Growth" wrapped />
                </Tabs>

                {this.state.value === 0 && (
                  <>
                    <Row>
                      <Col md={12} className="topHunTable">
                        <h6
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        ></h6>
                        <TopHundredItem
                          history={this.props.history}
                          type="marketCapitalization"
                        ></TopHundredItem>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.value === 1 && (
                  <>
                    <Row>
                      <Col md={12} className="topHunTable">
                        <h6
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        ></h6>
                        <TopHundredItem
                          history={this.props.history}
                          type="fiscalYearSale"
                        ></TopHundredItem>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.value === 2 && (
                  <>
                    <Row>
                      <Col md={12} className="topHunTable">
                        <h6
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        ></h6>
                        <TopHundredItem
                          history={this.props.history}
                          type="operatingMargin"
                        ></TopHundredItem>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.value === 3 && (
                  <>
                    <Row>
                      <Col md={12} className="topHunTable">
                        <h6
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        ></h6>
                        <TopHundredItem
                          history={this.props.history}
                          type="52weekPriceChange"
                        ></TopHundredItem>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.value === 4 && (
                  <>
                    <Row>
                      <Col md={12} className="topHunTable">
                        <h6
                          style={{ fontSize: "1.2em", fontWeight: "bold" }}
                        ></h6>
                        <TopHundredItem
                          history={this.props.history}
                          type="3yearSalesGrowth"
                        ></TopHundredItem>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col md={2}>
                <Advertisement advertisementType="verticalAddUnit" />
                <br></br>
                <Advertisement advertisementType="verticalAddUnit2" />
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}
