import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import Advertisement from "../ciadvertisement/Advertisement";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./IndustryAverageReport.css";
export default class IndustryAverageReport extends Component {
  cartData = store.getState().cartData.cart;
  price = store.getState().price.price;
  userData = store.getState().userData.userData;
  dataList = [{ label: "Region" }, { label: "Country" }];
  constructor(props) {
    super(props);
    let price = [];
    if (this.price) {
      price = this.price;
    }
    this.state = {
      price: price[1].price,
      sector: "",
      regionCountry: "",
      sectorList: [],
      sectorIndustryReports: [],
      sectorNameList: [],
      selectedSector: {},
      selectedRegion: { label: "Region" },
      reportList: [],
      render: false,
      showModal: false,
      pdfUrlRpt: "",
    };
  }
  isReportAlreadyExists() {}
  addToCart(selectedCompanyReport) {
    let selectedCompanyReportObj = {
      ticker: selectedCompanyReport.regionCountry,
      id: selectedCompanyReport.id,
      regionCountry: selectedCompanyReport.regionCountry,
      publishDate: selectedCompanyReport.publishDate,
      pdf: selectedCompanyReport.pdf,
      sector: selectedCompanyReport.sector,
      isRegion: selectedCompanyReport.isRegion,
      price: this.state.price,
      planId: "4",
      companyName:
        selectedCompanyReport.sector +
        "-" +
        selectedCompanyReport.regionCountry,
      type: "Industry",
    };
    this.cartData = store.getState().cartData.cart;

    let reports = this.cartData;
    for (let i = 0; i < this.cartData.length; i++) {
      let id = this.cartData[i].id;
      if (
        Utils.equalsIgnoreCase("Industry", selectedCompanyReportObj.type) &&
        Utils.equalsIgnoreCase(id, selectedCompanyReportObj.id)
      ) {
        PtsAlert.error(
          selectedCompanyReportObj.type +
            " " +
            "Report " +
            selectedCompanyReportObj.companyName +
            " Already exists in your Cart"
        );
        return;
      }
    }
    reports.push(selectedCompanyReportObj);
    store.dispatch(cartAction.setCart(reports));
    this.props.history.push({
      pathname: "/cart",
      state: {
        previousPage: "/industryreport",
      },
    });
  }
  handleSectorChange(event, values, reason) {
    if (values) {
      let isRegion = true;
      if (this.state.selectedRegion.label === "Region") {
        isRegion = true;
      } else {
        isRegion = false;
      }

      let reportList = [];
      for (let i = 0; i < this.state.sectorIndustryReports.length; i++) {
        if (
          Utils.equalsIgnoreCase(
            values.code,
            this.state.sectorIndustryReports[i].sectorCode
          ) &&
          this.state.sectorIndustryReports[i].isRegion === isRegion
        ) {
          reportList.push(this.state.sectorIndustryReports[i]);
        }
      }

      this.setState({
        selectedSector: values,
        reportList: reportList,
      });
    } else {
      this.setState({
        selectedSector: "",
      });
    }
  }

  handleCountryRegionChange(event, values, reason) {
    this.state.selectedRegion = values;

    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
    let isRegion = true;
    if (values) {
      if (values.label === "Region") {
        isRegion = true;
      } else {
        isRegion = false;
      }

      let reportList = [];
      for (let i = 0; i < this.state.sectorIndustryReports.length; i++) {
        if (
          Utils.equalsIgnoreCase(
            this.state.selectedSector.code,
            this.state.sectorIndustryReports[i].sectorCode
          ) &&
          this.state.sectorIndustryReports[i].isRegion === isRegion
        ) {
          reportList.push(this.state.sectorIndustryReports[i]);
        }
      }
      this.setState({
        selectedRegion: values,
        reportList: reportList,
      });
    } else {
      this.setState({
        selectedRegion: {},
      });
    }
  }
  toReportDetails = (reportData, value, value1) => {
    this.props.history.push({
      pathname: "/indreportdetail",
      state: { data: reportData },
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const postObject = {};
    FetchServerData.callPostService(
      "industryCountryService/sectorIndustryList",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let sectorNameList = data.sectorNameList;
          let sectorIndustryReports = data.sectorIndustryReports;
          let sectorList = data.sectorList;
          let reportList = [];
          for (let i = 0; i < sectorIndustryReports.length; i++) {
            if (
              Utils.equalsIgnoreCase(
                sectorList[0].code,
                sectorIndustryReports[i].sectorCode
              ) &&
              sectorIndustryReports[i].isRegion === true
            ) {
              reportList.push(sectorIndustryReports[i]);
            }
          }

          this.setState({
            sectorList: sectorList,
            selectedSector: sectorList[0],
            sectorNameList: sectorNameList,
            sectorIndustryReports: sectorIndustryReports,
            reportList: reportList,
            render: true,
          });
        } else {
        }
        this.setState({ render: true });
      })
      .catch((error) => {
        this.setState({ render: true });
      });
  }
  viewPdfReport(selectedCompanyReport) {
    this.setState({
      pdfUrlRpt: selectedCompanyReport.pdf,
      showModal: true,
      sector: selectedCompanyReport.sector,
      regionCountry: selectedCompanyReport.regionCountry,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  getButton(report) {
    var lengthObj = Object.keys(this.userData).length;

    if (lengthObj > 0) {
      if (this.userData.activeUser === false) {
        return (
          <Button
            style={{
              background: "#f58d51",

              float: "right",
              webkitBorderRadius: "10px",
              fontSize: "0.8em",
              float: "right",
              margin: "auto",
              marginRight: "5px",
              borderBottom: "2px solid #636262",
            }}
            onClick={(e) => this.addToCart(report)}
          >
            <ShoppingCartIcon style={{ fontSize: "1.3em" }} />
            Add to cart
          </Button>
        );
      } else {
        return (
          <Button
            style={{
              background: "#f58d51",

              float: "right",
              webkitBorderRadius: "10px",
              fontSize: "0.8em",
              float: "right",
              margin: "auto",
              marginRight: "5px",
              borderBottom: "2px solid #636262",
            }}
            onClick={(e) => this.viewPdfReport(report)}
          >
            <VisibilityIcon style={{ fontSize: "1.3em" }} /> View Report
          </Button>
        );
      }
    } else {
      return (
        <Button
          style={{
            background: "#f58d51",

            float: "right",
            webkitBorderRadius: "10px",
            fontSize: "0.8em",
            float: "right",
            margin: "auto",
            marginRight: "5px",
            borderBottom: "2px solid #636262",
          }}
          onClick={(e) => this.addToCart(report)}
        >
          <ShoppingCartIcon style={{ fontSize: "1.3em" }} />
          Add to cart
        </Button>
      );
    }
  }
  getPrice() {
    var lengthObj = Object.keys(this.userData).length;

    if (lengthObj > 0) {
      if (this.userData.activeUser === false) {
        return (
          <>
            <span style={{ fontWeight: "bold", color: "#f26d21" }}>
              Price: ${this.state.price}
            </span>{" "}
            <br></br>
            <a
              href="/indpdf/sample/IndustryAveragesSample.pdf"
              target="_blank"
              className="company_report_sample"
            >
              View Sample
            </a>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <>
          <span style={{ fontWeight: "bold", color: "#f26d21" }}>
            Price: ${this.state.price}
          </span>{" "}
          <br></br>
          <a
            href="/indpdf/sample/IndustryAveragesSample.pdf"
            target="_blank"
            className="company_report_sample"
          >
            View Sample
          </a>
        </>
      );
    }
  }
  render() {
    let reportList = [];

    this.state.reportList.forEach((report, index) => {
      reportList.push(
        <Row className="pts_list_row" key={index}>
          <Col md={9}>
            <span
              className="report_list"
              style={{ cursor: "pointer" }}
              onClick={this.toReportDetails.bind(this, report)}
            >
              <span>{report.sector}</span>-
              <span style={{ color: " #c0392b" }}>{report.regionCountry}</span>
            </span>
          </Col>
          <Col md={3}>{this.getButton(report)}</Col>
        </Row>
      );
    });
    return (
      <>
        <Card
          style={{
            marginTop: "5em",
            marginBottom: "2em",
            background: "transparent",
          }}
        >
          <Row
            style={{ borderBottom: "1px solid #ABB2B9" }}
            className="pts_mobile_view"
          >
            <Col md={1}>
              <img
                src="/home/assets/img/Industry_image.gif"
                alt="Company Report"
                height="70%"
                style={{ marginTop: "0.5em" }}
              ></img>
            </Col>
            <Col md={11} style={{ margin: "1em 0em 0em -1em" }}>
              <span className="bold_text">Wright</span>
              <span className="bold_text2"> Industry Averages Report</span>

              <Row>
                <Col md={12} className="Cmp_reports">
                  <p>
                    Wright Industry Averages Reports are prepared on 22
                    industries with a total of 869 permutations (i.e. global,
                    region and country). The reports are compiled from data
                    reported by the corporations which comprise the
                    CorporateInformation company universe.
                  </p>

                  <p>
                    The seven geographic regions for which Wright Industry
                    Averages are compiled are: Africa, Asia, Australasia,
                    Europe, Latin America, Middle East and North America.
                    Industry Averages Reports are also prepared on a country
                    basis whenever there are a minimum of five companies within
                    the industry.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Advertisement advertisementType="verticalAddUnit" />
            </Col>

            <Col md={8}>
              {this.state.render === true ? (
                <Container maxWidth="xl">
                  <Row
                    style={{
                      marginTop: "1em",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col md={5}>
                      <span
                        style={{
                          fontSize: "small",
                          fontWeight: "bold",
                          float: "left",
                        }}
                      >
                        Select Industry:
                      </span>
                      <Autocomplete
                        type="text"
                        id="tags-outlined"
                        options={this.state.sectorList}
                        getOptionLabel={(option) => option.name}
                        value={
                          this.state.selectedSector
                            ? this.state.selectedSector
                            : ""
                        }
                        filterSelectedOptions
                        onChange={this.handleSectorChange.bind(this)}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            variant="outlined"
                            placeholder="Select Industry"
                          />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <span
                        style={{
                          fontSize: "small",
                          fontWeight: "bold",
                          float: "left",
                        }}
                      >
                        Select Country/Region:
                      </span>
                      <Autocomplete
                        type="text"
                        id="tags-outlined"
                        options={this.dataList}
                        getOptionLabel={(option) => option.label}
                        value={this.state.selectedRegion}
                        filterSelectedOptions
                        onChange={this.handleCountryRegionChange.bind(this)}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            variant="outlined"
                            placeholder="Select Option"
                          />
                        )}
                      />
                    </Col>
                    <Col md={3} style={{ margin: "32px 0px 0px 0px" }}>
                      {this.getPrice()}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="ind_report">
                      <Card
                        style={{
                          boxShadow:
                            " 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1)",
                          backgroundColor: "#ffff",
                        }}
                      >
                        <CardHeader
                          style={{
                            padding: "5px !important",
                            background: "#2b7d6d",
                          }}
                        >
                          {this.state.reportList.length} Reports Found
                        </CardHeader>
                        <CardBody>{reportList}</CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "2em" }}>
                    <Col md={12} className="IV_reports">
                      <p>
                        To purchase a Wright Industry Averages Report(s), select
                        the industry (e.g. Chemicals) from the list above, click
                        on the desired geographic region (i.e. Chemicals
                        (Europe)) and click on the ”Add to Cart” button. You
                        will be taken to “check out” where you will be asked to
                        enter buyer information and credit card details.
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Col md={12}>
                      <span class="bodyTxtBold_iv_rpt">
                        Industry Averages Reports - Content
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Card className="ind_content_report">
                        <table className="report_content">
                          <tbody>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Summary Analysis
                              </td>
                              <td style={{ width: "800px" }}>
                                Key Ratios: Market Price, Earnings, Book Value,
                                Dividends
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Income Statement (4)
                              </td>
                              <td style={{ width: "800px" }}>
                                Actual Values, Common Size, Year-to-Year Percent
                                Change and Five (5) Year Averages
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Balance Sheet (4)
                              </td>
                              <td style={{ width: "800px" }}>
                                Actual Values, Common Size, Year-to-Year Percent
                                Change and Five (5) Year Averages
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Sources of Capital
                              </td>
                              <td style={{ width: "800px" }}>
                                Actual Values, Common Size, Year-to-Year Net
                                Changes, Year-to-Year Percent Changes, Cash Flow
                                (Operating, Financing and Investing Activities)
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>Sales Analysis</td>
                              <td style={{ width: "800px" }}>
                                Sales, Cost of Goods Sold, EBITDA, Net Income
                                Available to Common Shareholders, Employee
                                Efficiency
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Financial Ratio Reports (8)
                              </td>
                              <td style={{ width: "800px" }}>
                                Accounting Ratios, Asset Utilization, Employee
                                Efficiency, Fixed Charges Coverage, Leverage
                                Analysis, Liquidity Analysis, Per Share Data,
                                Profitability Analysis
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Industry Leaders
                              </td>
                              <td style={{ width: "800px" }}>
                                Leading global companies based on: Net Sales,
                                Total Assets and Market Capitalization
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>
                                Company Listing
                              </td>
                              <td style={{ width: "800px" }}>
                                Listing of companies and an indication of which
                                years the companies’ information is represented
                                in the Industry Averages.
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>Glossary</td>
                              <td style={{ width: "800px" }}>
                                Definition of terms used in the Industry
                                Averages reports.
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "300px" }}>&nbsp;</td>
                              <td style={{ width: "800px" }}>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              ) : (
                <div className="pageHeight"></div>
              )}
            </Col>

            <Col md={2}>
              <Advertisement advertisementType="verticalAddUnit2" />
            </Col>
          </Row>
        </Card>

        <Modal
          isOpen={this.state.showModal}
          size="xl"
          className="modalContain"
          style={{ overflowY: "hidden" }}
        >
          <ModalHeader style={{ background: "#286894", color: "#fff" }}>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    float: "left",
                  }}
                >
                  <span>{this.state.sector}</span>-&nbsp;
                  <span>{this.state.regionCountry}</span>
                </Label>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody>
            <iframe
              src={"/indpdf/" + this.state.pdfUrlRpt}
              style={{ width: "100%", height: "500px" }}
              frameborder="0"
              title="indpdf"
            ></iframe>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
