import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import "./AssetUtilization.css";
import BankAssetUtilization from "./BankAssetUtilization";
import FinancialAssetUtilization from "./FinancialAssetUtilization";
import IndAssetUtilization from "./IndAssetUtilization";
import InsuranceAssetUtilization from "./InsuranceAssetUtilization";
export default class AssetUtilization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indType: props.data.ind_TYPE,
      assetUtilizationData: props.data,
      isPdfReport: props.isPdfReport,
      render: true,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <BankAssetUtilization
                  data={this.state.assetUtilizationData}
                ></BankAssetUtilization>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InsuranceAssetUtilization
                  data={this.state.assetUtilizationData}
                ></InsuranceAssetUtilization>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <FinancialAssetUtilization
                  data={this.state.assetUtilizationData}
                ></FinancialAssetUtilization>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IndAssetUtilization
                  data={this.state.assetUtilizationData}
                  isPdfReport={this.state.isPdfReport}
                ></IndAssetUtilization>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
