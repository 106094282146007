import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./FinancialBalanceSheet.css";

export default class FinancialBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      balanceSheetData: [],
      render: false,
      isCommonSize: false,
      noYrToYr: true,
      isYrToYr: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash & Short Term Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashAndShortTermInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash & Short Term Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.Cash,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Investment in Sales and Direct Financing Leases"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentinSalesandDirectFinancingLeases,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment in Sales and Direct Financing Leases",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Short Term Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ShortTermInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivables (Net)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReceivablesNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables (Net)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Securities Inventory") {
      this.setState({
        graphDetails: {
          graphValues: this.state.SecuritiesInventory,
          labelData: this.state.LabelList,
          yAxisTitle: "Securities Inventory",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Custody Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CustodySecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Custody Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentsTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Investments - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Loans - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LoansTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Loans - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Reserve for Loan Losses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReserveForLoanLosses,
          labelData: this.state.LabelList,
          yAxisTitle: "Reserve for Loan Losses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Loans - Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LoansNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Loans - Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Real Estate Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.RealEstateAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Real Estate Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Investment in Sales and Direct Financing Leases"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentInSalesAndDirectFinancingLeases,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment in Sales and Direct Financing Leases",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments in Associated Companies") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentsInAssociatedCompanies,
          labelData: this.state.LabelList,
          yAxisTitle: "Investments in Associated Companies",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property Plant and Equipment - Gross") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PropertyPlantAndEquipmentGross,
          labelData: this.state.LabelList,
          yAxisTitle: "Property Plant and Equipment - Gross",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Accumulated Depreciation") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AccumulatedDepreciation,
          labelData: this.state.LabelList,
          yAxisTitle: "Accumulated Depreciation",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property, Plant and Equipment – Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PropertyPlantandEquipmentNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Property, Plant and Equipment – Ne",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Tangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Tangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Intangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IntangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Intangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Inventories - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InventoriesTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventories - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities & Shareholders' Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilitiesAndShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities & Shareholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deposits - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DepositsTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Deposits - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Demand Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DemandDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Demand Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Short Term Debt & Current Portion of Long Term De"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.ShortTermDebtAndCurrentPortionOfLongTermDe,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt & Current Portion of Long Term De",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt Excluding Capitalized Leases") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebtExcludingCapitalizedLeases,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt Excluding Capitalized Leases",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capitalized Lease Obligations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CapitalizedLeaseObligations,
          labelData: this.state.LabelList,
          yAxisTitle: "Capitalized Lease Obligations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Provision for Risks and Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ProvisionForRisksAndCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Provision for Risks and Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Tax Liability in Untaxed Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxLiabilityinUntaxedReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Tax Liability in Untaxed Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Equity Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NonEquityReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Equity Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MinorityInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock Issued for ESOP") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStockIssuedforESOP,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock Issued for ESOP",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "ESOP Guarantees - Preferred Issued") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ESOPGuaranteesPreferredIssued,
          labelData: this.state.LabelList,
          yAxisTitle: "ESOP Guarantees - Preferred Issued",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.state.balanceSheetData = [];
      let balanceSheetData = this.state.data.oFinBalanceSheetList;

      let Cash = [];
      let ShortTermInvestments = [];
      let ReceivablesNet = [];
      let SecuritiesInventory = [];
      let CustodySecurities = [];
      let LoansTotal = [];
      let ReserveForLoanLosses = [];
      let LoansNet = [];
      let RealEstateAssets = [];
      let OtherInvestments = [];
      let InvestmentsInAssociatedCompanies = [];
      let investmentinSalesandDirectFinancingLeases = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let InventoriesTotal = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let totalAssets = [];
      let CashAndShortTermInvestments = [];
      let InvestmentsTotal = [];
      let letInvestmentInSalesAndDirectFinancingLeases = [];

      for (let i = 0; i < balanceSheetData.length; i++) {
        Cash.push(balanceSheetData[i].cash);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        letInvestmentInSalesAndDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        ShortTermInvestments.push(balanceSheetData[i].shortTermInvestments);
        ReceivablesNet.push(balanceSheetData[i].receivablesNet);
        SecuritiesInventory.push(balanceSheetData[i].securitiesInventory);
        CustodySecurities.push(balanceSheetData[i].custodySecurities);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        ReserveForLoanLosses.push(balanceSheetData[i].reserveforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        investmentinSalesandDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        InventoriesTotal.push(balanceSheetData[i].inventoriesTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        LabelList.push(balanceSheetData[i].fiscalYear);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].securitiesInventory = this.convertToMillionaire(
          balanceSheetData[i].securitiesInventory
        );
        balanceSheetData[i].custodySecurities = this.convertToMillionaire(
          balanceSheetData[i].custodySecurities
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].reserveforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reserveforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].investmentinSalesandDirectFinancingLeases =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinSalesandDirectFinancingLeases
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        totalAssets: totalAssets,
        CashAndShortTermInvestments,
        InvestmentsTotal: InvestmentsTotal,
        Cash: Cash,
        ShortTermInvestments: ShortTermInvestments,
        ReceivablesNet: ReceivablesNet,
        SecuritiesInventory: SecuritiesInventory,
        CustodySecurities: CustodySecurities,
        LoansTotal: LoansTotal,
        ReserveForLoanLosses: ReserveForLoanLosses,
        LoansNet: LoansNet,
        RealEstateAssets: RealEstateAssets,
        OtherInvestments: OtherInvestments,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        InventoriesTotal: InventoriesTotal,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      let balanceSheetData = this.state.data.oFinBalanceSheetPercentChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }

      let Cash = [];
      let ShortTermInvestments = [];
      let ReceivablesNet = [];
      let SecuritiesInventory = [];
      let CustodySecurities = [];
      let LoansTotal = [];
      let ReserveForLoanLosses = [];
      let LoansNet = [];
      let RealEstateAssets = [];
      let OtherInvestments = [];
      let InvestmentsInAssociatedCompanies = [];
      let investmentinSalesandDirectFinancingLeases = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let InventoriesTotal = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let totalAssets = [];
      let CashAndShortTermInvestments = [];
      let InvestmentsTotal = [];
      let letInvestmentInSalesAndDirectFinancingLeases = [];

      for (let i = 0; i < balanceSheetData.length; i++) {
        Cash.push(balanceSheetData[i].cash);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        letInvestmentInSalesAndDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        ShortTermInvestments.push(balanceSheetData[i].shortTermInvestments);
        ReceivablesNet.push(balanceSheetData[i].receivablesNet);
        SecuritiesInventory.push(balanceSheetData[i].securitiesInventory);
        CustodySecurities.push(balanceSheetData[i].custodySecurities);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        ReserveForLoanLosses.push(balanceSheetData[i].reserveforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        investmentinSalesandDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        InventoriesTotal.push(balanceSheetData[i].inventoriesTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        LabelList.push(balanceSheetData[i].fiscalYear);
        // balanceSheetData[i].totalAssets = this.convertToMillionaire1(
        //   balanceSheetData[i].totalAssets
        // );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire1(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire1(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire1(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].securitiesInventory = this.convertToMillionaire1(
          balanceSheetData[i].securitiesInventory
        );
        balanceSheetData[i].custodySecurities = this.convertToMillionaire1(
          balanceSheetData[i].custodySecurities
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire1(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].reserveforLoanLosses = this.convertToMillionaire1(
          balanceSheetData[i].reserveforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire1(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].investmentinSalesandDirectFinancingLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinSalesandDirectFinancingLeases
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire1(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire1(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: true,
        noYrToYr: false,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Common Size",
        totalAssets: totalAssets,
        CashAndShortTermInvestments,
        InvestmentsTotal: InvestmentsTotal,
        Cash: Cash,
        ShortTermInvestments: ShortTermInvestments,
        ReceivablesNet: ReceivablesNet,
        SecuritiesInventory: SecuritiesInventory,
        CustodySecurities: CustodySecurities,
        LoansTotal: LoansTotal,
        ReserveForLoanLosses: ReserveForLoanLosses,
        LoansNet: LoansNet,
        RealEstateAssets: RealEstateAssets,
        OtherInvestments: OtherInvestments,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        InventoriesTotal: InventoriesTotal,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      let balanceSheetData = this.state.data.oFinBalanceSheetYoYChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }

      let Cash = [];
      let ShortTermInvestments = [];
      let ReceivablesNet = [];
      let SecuritiesInventory = [];
      let CustodySecurities = [];
      let LoansTotal = [];
      let ReserveForLoanLosses = [];
      let LoansNet = [];
      let RealEstateAssets = [];
      let OtherInvestments = [];
      let InvestmentsInAssociatedCompanies = [];
      let investmentinSalesandDirectFinancingLeases = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let InventoriesTotal = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let totalAssets = [];
      let CashAndShortTermInvestments = [];
      let InvestmentsTotal = [];
      let letInvestmentInSalesAndDirectFinancingLeases = [];

      for (let i = 0; i < balanceSheetData.length; i++) {
        Cash.push(balanceSheetData[i].cash);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        letInvestmentInSalesAndDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        ShortTermInvestments.push(balanceSheetData[i].shortTermInvestments);
        ReceivablesNet.push(balanceSheetData[i].receivablesNet);
        SecuritiesInventory.push(balanceSheetData[i].securitiesInventory);
        CustodySecurities.push(balanceSheetData[i].custodySecurities);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        ReserveForLoanLosses.push(balanceSheetData[i].reserveforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        investmentinSalesandDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        InventoriesTotal.push(balanceSheetData[i].inventoriesTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        LabelList.push(balanceSheetData[i].fiscalYear);
        balanceSheetData[i].totalAssets = this.convertToMillionaire1(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire1(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire1(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire1(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].securitiesInventory = this.convertToMillionaire1(
          balanceSheetData[i].securitiesInventory
        );
        balanceSheetData[i].custodySecurities = this.convertToMillionaire1(
          balanceSheetData[i].custodySecurities
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire1(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].reserveforLoanLosses = this.convertToMillionaire1(
          balanceSheetData[i].reserveforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire1(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].investmentinSalesandDirectFinancingLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinSalesandDirectFinancingLeases
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire1(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire1(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: false,
        isYrToYr: true,
        modalHeader: " Balance Sheet -Yr-yr % change",
        totalAssets: totalAssets,
        CashAndShortTermInvestments,
        InvestmentsTotal: InvestmentsTotal,
        Cash: Cash,
        ShortTermInvestments: ShortTermInvestments,
        ReceivablesNet: ReceivablesNet,
        SecuritiesInventory: SecuritiesInventory,
        CustodySecurities: CustodySecurities,
        LoansTotal: LoansTotal,
        ReserveForLoanLosses: ReserveForLoanLosses,
        LoansNet: LoansNet,
        RealEstateAssets: RealEstateAssets,
        OtherInvestments: OtherInvestments,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        InventoriesTotal: InventoriesTotal,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      let balanceSheetData = this.state.data.oFinBalanceSheet3YearAverageList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }

      let Cash = [];
      let ShortTermInvestments = [];
      let ReceivablesNet = [];
      let SecuritiesInventory = [];
      let CustodySecurities = [];
      let LoansTotal = [];
      let ReserveForLoanLosses = [];
      let LoansNet = [];
      let RealEstateAssets = [];
      let OtherInvestments = [];
      let InvestmentsInAssociatedCompanies = [];
      let investmentinSalesandDirectFinancingLeases = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let InventoriesTotal = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let totalAssets = [];
      let CashAndShortTermInvestments = [];
      let InvestmentsTotal = [];
      let letInvestmentInSalesAndDirectFinancingLeases = [];

      for (let i = 0; i < balanceSheetData.length; i++) {
        Cash.push(balanceSheetData[i].cash);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        letInvestmentInSalesAndDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        ShortTermInvestments.push(balanceSheetData[i].shortTermInvestments);
        ReceivablesNet.push(balanceSheetData[i].receivablesNet);
        SecuritiesInventory.push(balanceSheetData[i].securitiesInventory);
        CustodySecurities.push(balanceSheetData[i].custodySecurities);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        ReserveForLoanLosses.push(balanceSheetData[i].reserveforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        investmentinSalesandDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        InventoriesTotal.push(balanceSheetData[i].inventoriesTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        LabelList.push(balanceSheetData[i].fiscalYear);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].securitiesInventory = this.convertToMillionaire(
          balanceSheetData[i].securitiesInventory
        );
        balanceSheetData[i].custodySecurities = this.convertToMillionaire(
          balanceSheetData[i].custodySecurities
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].reserveforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reserveforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].investmentinSalesandDirectFinancingLeases =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinSalesandDirectFinancingLeases
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: "Balance Sheet -3 yr Averages",
        totalAssets: totalAssets,
        CashAndShortTermInvestments,
        InvestmentsTotal: InvestmentsTotal,
        Cash: Cash,
        ShortTermInvestments: ShortTermInvestments,
        ReceivablesNet: ReceivablesNet,
        SecuritiesInventory: SecuritiesInventory,
        CustodySecurities: CustodySecurities,
        LoansTotal: LoansTotal,
        ReserveForLoanLosses: ReserveForLoanLosses,
        LoansNet: LoansNet,
        RealEstateAssets: RealEstateAssets,
        OtherInvestments: OtherInvestments,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        InventoriesTotal: InventoriesTotal,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        LabelList: LabelList,
      });
    } else {
      this.state.balanceSheetData = [];
      let balanceSheetData = this.state.data.oFinBalanceSheetList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let Cash = [];
      let ShortTermInvestments = [];
      let ReceivablesNet = [];
      let SecuritiesInventory = [];
      let CustodySecurities = [];
      let LoansTotal = [];
      let ReserveForLoanLosses = [];
      let LoansNet = [];
      let RealEstateAssets = [];
      let OtherInvestments = [];
      let InvestmentsInAssociatedCompanies = [];
      let investmentinSalesandDirectFinancingLeases = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let InventoriesTotal = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let totalAssets = [];
      let CashAndShortTermInvestments = [];
      let InvestmentsTotal = [];
      let letInvestmentInSalesAndDirectFinancingLeases = [];

      for (let i = 0; i < balanceSheetData.length; i++) {
        Cash.push(balanceSheetData[i].cash);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        letInvestmentInSalesAndDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        ShortTermInvestments.push(balanceSheetData[i].shortTermInvestments);
        ReceivablesNet.push(balanceSheetData[i].receivablesNet);
        SecuritiesInventory.push(balanceSheetData[i].securitiesInventory);
        CustodySecurities.push(balanceSheetData[i].custodySecurities);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        ReserveForLoanLosses.push(balanceSheetData[i].reserveforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        investmentinSalesandDirectFinancingLeases.push(
          balanceSheetData[i].investmentinSalesandDirectFinancingLeases
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        InventoriesTotal.push(balanceSheetData[i].inventoriesTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        LabelList.push(balanceSheetData[i].fiscalYear);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].securitiesInventory = this.convertToMillionaire(
          balanceSheetData[i].securitiesInventory
        );
        balanceSheetData[i].custodySecurities = this.convertToMillionaire(
          balanceSheetData[i].custodySecurities
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].reserveforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reserveforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].investmentinSalesandDirectFinancingLeases =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinSalesandDirectFinancingLeases
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        totalAssets: totalAssets,
        CashAndShortTermInvestments,
        InvestmentsTotal: InvestmentsTotal,
        Cash: Cash,
        ShortTermInvestments: ShortTermInvestments,
        ReceivablesNet: ReceivablesNet,
        SecuritiesInventory: SecuritiesInventory,
        CustodySecurities: CustodySecurities,
        LoansTotal: LoansTotal,
        ReserveForLoanLosses: ReserveForLoanLosses,
        LoansNet: LoansNet,
        RealEstateAssets: RealEstateAssets,
        OtherInvestments: OtherInvestments,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        InventoriesTotal: InventoriesTotal,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        LabelList: LabelList,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Cash & Short Term Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash & Short Term Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashShortTermInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Cash
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash", this.state.type)
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.shortTermInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Receivables (Net)
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Receivables (Net)",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Securities Inventory
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Securities Inventory",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.securitiesInventory}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Custody Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Custody Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.custodySecurities}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      {" "}
                      Investments - Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments - Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans - Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Loans - Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Reserve for Loan Losses
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Reserve for Loan Losses",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reserveforLoanLosses}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Loans - Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Loans - Net",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Real Estate Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Real Estate Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Investment in Sales and Direct Financing Leases
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investment in Sales and Direct Financing Leases",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.investmentinSalesandDirectFinancingLeases}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Other Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Investments in Associated Companies
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments in Associated Companies",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Property Plant and Equipment - Gross
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property Plant and Equipment - Gross",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Accumulated Depreciation
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Accumulated Depreciation",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property, Plant and Equipment – Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property, Plant and Equipment – Net",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Deferred Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Tangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Intangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      {" "}
                      Inventories - Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Inventories - Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.inventoriesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <th>
                      Total Liabilities & Shareholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deposits - Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deposits - Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.depositsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term Debit
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term Debit",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Excluding Capitalized Leases",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Capitalized Lease Obligations
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Capitalized Lease Obligations",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Provision for Risks and Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Provision for Risks and Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Income
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Income",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Taxes
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Tax Liability in Untaxed Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Tax Liability in Untaxed Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Non-Equity Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Non-Equity Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Minority Interest
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Minority Interest",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Preferred Stock
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock Issued for ESOP",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "ESOP Guarantees - Preferred Issued",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Cash & Short Term Investments
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash & Short Term Investments"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashShortTermInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Cash</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Investments
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.shortTermInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Receivables (Net)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Receivables (Net)")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Securities Inventory</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.securitiesInventory}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Custody Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.custodySecurities}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      {" "}
                      Investments - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investments - Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Loans - Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Reserve for Loan Losses
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reserveforLoanLosses}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Loans - Net</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Investment in Sales and Direct Financing Leases</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.investmentinSalesandDirectFinancingLeases}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Investments in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Property Plant and Equipment - Gross</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property, Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Inventories - Total</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.inventoriesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <th>
                      Total Liabilities & Shareholders' Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deposits - Total</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.depositsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term Debit
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term Debit"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long-Term Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Excluding Capitalized Leases"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capitalized Lease Obligations</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>

                {/* {itemRow}
                </Table>
              </TableContainer> */}
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
