// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
// import { countries } from "country-data";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Tab1 from "react-bootstrap/Tab";
import Tabs1 from "react-bootstrap/Tabs";
import { Radar } from "react-chartjs-2";
import ScrollspyNav from "react-scrollspy-nav";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import BalanceSheet from "../balanceSheet/BalanceSheet";
import Advertisement from "../ciadvertisement/Advertisement";
import CompanyFinancial from "../companyFinancial/CompanyFinancial";
import ComparativeBusinessAnalysis from "../comparativeBusinessAnalysis/ComparativeBusinessAnalysis";
import EarningAndDividends from "../earningAndDividends/EarningAndDividends";
import AccountingRatios from "../financialRatios/accountingRatios/AccountingRatios";
import AssetUtilization from "../financialRatios/assetUtilization/AssetUtilization";
import EmployeeEfficiency from "../financialRatios/EmployeeEfficiency";
import FixedChargesCoverage from "../financialRatios/FixedChargesCoverage";
import LeverageAnalysis from "../financialRatios/LeverageAnalysis";
import LiquidityAnalysis from "../financialRatios/liquidityAnalysis/LiquidityAnalysis";
import PerShareData from "../financialRatios/PerShareData";
import ProfitabilityAnalysis from "../financialRatios/ProfitabilityAnalysis";
import CashBasis from "../flowOfFunds/CashBasis";
import FlowOfFunds from "../flowOfFunds/FlowOfFunds";
import PerFasb from "../flowOfFunds/PerFasb";
import Ratios from "../flowOfFunds/Ratios";
import CompanyDetailLayoutHeader from "../header/CompanyDetailLayoutHeader";
import InterimBalanceSheet from "../interimBalanceSheet/InterimBalanceSheet";
import InterimIncomeStatement from "../interimIncomeStatement/InterimIncomeStatement";
import Login from "../loginComponent/Login";
import PriceAnalysis from "../priceAnalysis/PriceAnalysis";
import PtsAlert from "../ptsAlert/PtsAlert";
import SalesAnalysis from "../salesAnalysis/SalesAnalysis";
import GenericSearch from "../search/GenericSearch";
import SearchWithResult from "../search/SearchWithResult";
import SourceOfCapital from "../sourceOfCapital/SourceOfCapital";
import SummaryAnalysisPerShare from "../summaryAnalysisPerShare/SummaryAnalysisPerShare";
import CorporateGrowth from "../wrightQualityAnalyses/CorporateGrowth";
import FinancialStrength from "../wrightQualityAnalyses/FinancialStrength";
import InvestmentAcceptance from "../wrightQualityAnalyses/InvestmentAcceptance";
import ProfitabilityStability from "../wrightQualityAnalyses/ProfitabilityStability";
import "./CompanyCorporateInfo.css";
export default class CompanyCorporateInfo extends Component {
  userData = store.getState().userData.userData;
  price = store.getState().price.price;
  investmentAcceptance = {};
  cbaRef = React.createRef();

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let price = "";
    if (this.price) {
      price = this.price[0].price;
    }
    let pageId = "";
    let cusip = "";
    let selectedCompany = {};
    let isUserActive = true;
    let isPaidUser = false;
    if (this.userData) {
      if (this.userData.activeUser === false) {
        isUserActive = false;
        isPaidUser = true;
      }
    }

    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      cusip = props.history.location.state.cusip;
      pageId = props.history.location.state.pageId;
      selectedCompany = props.history.location.state.data;
    } else {
      let str = window.location.href;
      let urlArr = str.split("?");
      cusip = urlArr[1];
    }
    let countries = Utils.getCountryData();
    if (pageId === "company_snap_cba") {
      this.state = {
        windowSize: window.innerWidth,
        isUserActive: isUserActive,
        selectedCompany: selectedCompany,
        countryList: countries,
        cusip: cusip,
        companyDetails: {},
        snapshot: "",
        epsDpsData: [],
        priceData: [],
        companyName: "",
        ticker: "",
        render: false,
        datasets: {},
        showModal1: false,
        series: [],
        cpClassName: "active",
        smClassName: "",
        saClassName: "",
        edClassName: "",
        pClassName: "",
        sopClassName: "",
        cfClassName: "",
        bsClassName: "",
        waClassName: "",
        cmpSnapshot: "company_Info",
        isReadMoreBtn: true,
        currentClassName: "active",
        dropdown: "dropdownClose",
        className: "default_select",
        sidebar: "sidebar",
        showModal: false,
        company_name: "company_Name",
        caretDown: true,
        caretUp: false,
        balanceSheetActualData: [],
        sourceOfCapitalData: {},
        countryName: "",
        value: 2,
        tabTop: "",
        left: false,
        isPaidUser: isPaidUser,
        price: price,
      };
    } else {
      this.state = {
        windowSize: window.innerWidth,
        isUserActive: isUserActive,
        selectedCompany: selectedCompany,
        countryList: countries,
        cusip: cusip,
        companyDetails: {},
        snapshot: "",
        epsDpsData: [],
        priceData: [],
        companyName: "",
        ticker: "",
        render: false,
        datasets: {},
        showModal1: false,
        series: [],
        cpClassName: "active",
        smClassName: "",
        saClassName: "",
        edClassName: "",
        pClassName: "",
        sopClassName: "",
        cfClassName: "",
        bsClassName: "",
        waClassName: "",
        cmpSnapshot: "company_Info",
        isReadMoreBtn: true,
        currentClassName: "active",
        dropdown: "dropdownClose",
        className: "default_select",
        sidebar: "sidebar",
        showModal: false,
        company_name: "company_Name",
        caretDown: true,
        caretUp: false,
        balanceSheetActualData: [],
        sourceOfCapitalData: {},
        countryName: "",
        value: 0,
        tabTop: "",
        left: false,
        isPaidUser: isPaidUser,
        price: price,
      };
    }
    this.loginRefList = React.createRef();
    this.headerLoginRef = React.createRef();
  }
  getModalPopup(e) {
    this.setState({ showModal1: !this.state.showModal1 });
    if (this.loginRefList && this.loginRefList.current) {
      this.loginRefList.current.state.showModal = true;
    }
  }

 convertMarketCapitalisation(mcap) {
  let formatedMcap = mcap;
  let num1 = 13;
  let mcapNum = mcap;
  if(mcap > 1000000000 ) {
    mcapNum = mcap / 1000000000;
    mcapNum = mcapNum.toFixed(2);
    formatedMcap = mcapNum.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " Billions"
  }
  else if(mcap > 1000000) {
    mcapNum = mcap / 1000000;
    mcapNum = mcapNum.toFixed(2);
    formatedMcap = mcapNum + " Millions"
  }
  return formatedMcap;
 }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getSnapShotDetails();
    this.getFinancialStatementReportData();
    this.getFinancialRatioReportData();
  }
  toCompanyDetails = (cusip, e) => {
    this.state.cusip = cusip;
    this.state.value = 2;
    this.setState({
      cusip: cusip,
      value: 2,
      render: false,
    });

    this.getSnapShotDetails();
    this.getFinancialStatementReportData();
    this.getFinancialRatioReportData();
  };
  toTop() {
    window.scrollTo(0, 0);
  }

  handleSelectIncomeStatement = (key) => {
    this.setState({ key });
  };

  handleScroll = () => {
    if (this.state.isUserActive === false) {
      if (window.pageYOffset > 0) {
        if (this.state.className === "default_select") {
          this.setState({
            className: "default_unselect ",
            tabTop: "tab_to_top",
          });
        }
      } else {
        if (this.state.className !== "default_select") {
          this.setState({
            className: "default_select",
            tabTop: "",
          });
        }
      }
    } else {
      if (window.pageYOffset > 0) {
        if (this.state.className === "default_select") {
          this.setState({
            className: "default_unselect ",
            tabTop: "tab_to_top",
          });
        }
      } else {
        if (this.state.className !== "default_select") {
          this.setState({
            className: "default_select",
            tabTop: "",
          });
        }
      }
    }
  };

  getSnapShotDetails() {
    const postObject = {
      cusip: this.state.cusip,
    };
    FetchServerData.callPostService("company/fundamentalReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let summaryAnalysis = {};
          let salesAnalysis = {};
          let priceAnalysis = {};
          let earningsAndDividend = {};
          let grRatingPts = 0;
          let psRatingPts = 0;
          let fsRatingPts = 0;
          let iaRatingPts = 0;
          let backgroundColor = "";
          let borderColor = "";
          let wrightRatings = "";
          if (output.data.companySnashot.wrightRatings !== null) {
            wrightRatings =
              output.data.companySnashot.wrightRatings.compositeRating;
            if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Not Rated"
            ) {
              backgroundColor = "rgba(  243, 25, 25, 0.8)";
              borderColor = "#ff0000";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Poor"
            ) {
              backgroundColor = "rgba(235, 152, 78  , 0.9)";
              borderColor = "#af601a";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Fair"
            ) {
              backgroundColor = "rgba(  242, 242, 0 , 0.8)";
              borderColor = "#ffff00";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Good"
            ) {
              backgroundColor = "rgba(  77, 171, 99 , 0.8)";
              borderColor = "#58D68D";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Excellent"
            ) {
              backgroundColor = "rgba(  44, 92, 202,0.8)";
              borderColor = " #5dade2";
            }
            grRatingPts = output.data.companySnashot.wrightRatings.grRatingPts;
            psRatingPts = output.data.companySnashot.wrightRatings.psRatingPts;
            fsRatingPts = output.data.companySnashot.wrightRatings.fsRatingPts;
            iaRatingPts = output.data.companySnashot.wrightRatings.iaRatingPts;
          }
          if (output.data) {
            summaryAnalysis = output.data.summaryAnalysis;
            salesAnalysis = output.data.salesAnalysis;
            priceAnalysis = output.data.priceAnalysis;
            earningsAndDividend = output.data.earningsAndDividend;
          }

          let quarterlyEarningList =
            output.data.companySnashot.quarterlyEarningList;

          let quarterlyDividendList =
            output.data.companySnashot.quarterlyDividendList;

          let prices = output.data.companySnashot.priceData;
          let quarterlyEarningValues = [];
          let quarterlyDividendValues = [];
          let priceValues = [];
          let earningsDividendLabel = [];
          let quarterlyEarning = "";
          let quarterlyDividend = "n.a.";
          let earningsDividendLabelYearMap = [];
          if (quarterlyEarningList.length !== 0) {
            for (let i = 0; i < quarterlyEarningList.length; i++) {
              quarterlyEarningValues.push(
                parseFloat(quarterlyEarningList[i].eps)
              );

              quarterlyEarning = quarterlyEarningList[quarterlyEarningList.length - 1].eps;
              let year = moment(quarterlyEarningList[i].date).format("YYYY");
              if (earningsDividendLabelYearMap[year] !== true) {    
                  earningsDividendLabel.push(
                  moment(quarterlyEarningList[i].date).format("YYYY")
                );
                earningsDividendLabelYearMap[year] = true;                
              } else {
                earningsDividendLabel.push("");
              }
            }
          }

          if (quarterlyDividendList.length !== 0) {
            quarterlyDividend = quarterlyDividendList[quarterlyDividendList.length - 1].dps;
            for (let i = 0; i < quarterlyDividendList.length; i++) {
              quarterlyDividendValues.push(
                parseFloat(quarterlyDividendList[i].dps)
              );
            }
          }

          for (let i = 0; i < prices.length; i++) {
            let obj1 = {
              x: prices[i].year + " JAN",
              y: [
                prices[i].highJan,
                prices[i].highJan,
                prices[i].lowJan,
                prices[i].lowJan,
              ],
            };
            let obj2 = {
              x: prices[i].year + " FEB",
              y: [
                prices[i].highFeb,
                prices[i].highFeb,
                prices[i].lowFeb,
                prices[i].lowFeb,
              ],
            };
            let obj3 = {
              x: prices[i].year + " MAR",
              y: [
                prices[i].highMar,
                prices[i].highMar,
                prices[i].lowMar,
                prices[i].lowMar,
              ],
            };
            let obj4 = {
              x: prices[i].year + " APR",
              y: [
                prices[i].highApr,
                prices[i].highApr,
                prices[i].lowApr,
                prices[i].lowApr,
              ],
            };
            let obj5 = {
              x: prices[i].year + " MAY",
              y: [
                prices[i].highMay,
                prices[i].highMay,
                prices[i].lowMay,
                prices[i].lowMay,
              ],
            };
            let obj6 = {
              x: prices[i].year + " JUN",
              y: [
                prices[i].highJun,
                prices[i].highJun,
                prices[i].lowJun,
                prices[i].lowJun,
              ],
            };
            let obj7 = {
              x: prices[i].year + " JUL",
              y: [
                prices[i].highJul,
                prices[i].highJul,
                prices[i].lowJul,
                prices[i].lowJul,
              ],
            };
            let obj8 = {
              x: prices[i].year + " AUG",
              y: [
                prices[i].highAug,
                prices[i].highAug,
                prices[i].lowAug,
                prices[i].lowAug,
              ],
            };
            let obj9 = {
              x: prices[i].year + " SEP",
              y: [
                prices[i].highSep,
                prices[i].highSep,
                prices[i].lowSep,
                prices[i].lowSep,
              ],
            };
            let obj10 = {
              x: prices[i].year + " OCT",
              y: [
                prices[i].highOct,
                prices[i].highOct,
                prices[i].lowOct,
                prices[i].lowOct,
              ],
            };
            let obj11 = {
              x: prices[i].year + " NOV",
              y: [
                prices[i].highNov,
                prices[i].highNov,
                prices[i].lowNov,
                prices[i].lowNov,
              ],
            };
            let obj12 = {
              x: prices[i].year + " DEC",
              y: [
                prices[i].highDec,
                prices[i].highDec,
                prices[i].lowDec,
                prices[i].lowDec,
              ],
            };
            priceValues.push(obj1);
            priceValues.push(obj2);
            priceValues.push(obj3);
            priceValues.push(obj4);
            priceValues.push(obj5);
            priceValues.push(obj6);
            priceValues.push(obj7);
            priceValues.push(obj8);
            priceValues.push(obj9);
            priceValues.push(obj10);
            priceValues.push(obj11);
            priceValues.push(obj12);
          }

          let priceAnalysisArr = output.data.monthlyPriceAnalysis;
          // var items = priceAnalysisArr.splice(0, 4);
          let graphPriceData = [];
          let graphPriceList = [];
          for (let i = 0; i < priceAnalysisArr.length; i++) {
            for (
              let j = 0;
              j < priceAnalysisArr[i].monthlyPriceList.length;
              j++
            ) {
              if (
                priceAnalysisArr[i].monthlyPriceList[j].highPrice !== null ||
                priceAnalysisArr[i].monthlyPriceList[j].lowPrice !== null
              ) {
                graphPriceData.push(priceAnalysisArr[i].monthlyPriceList[j]);
              }
            }
          }
          var minValue = graphPriceData.reduce(function (res, obj) {
            if (res.lowPrice !== null || obj.lowPrice !== null) {
              return obj.lowPrice < res.lowPrice ? obj : res;
            }
          });

          var maxValue = graphPriceData.reduce(function (res, obj) {
            return obj.highPrice > res.highPrice ? obj : res;
          });

          let minRangeValue = minValue.highPrice;
          let maxRangeValue = maxValue.highPrice;

          for (let i = 0; i < graphPriceData.length; i++) {
            let priceObj = {
              x: new Date(graphPriceData[i].date),
              y: [
                graphPriceData[i].closingPrice,
                graphPriceData[i].highPrice,
                graphPriceData[i].lowPrice,
                graphPriceData[i].closingPrice,
              ],
            };
            graphPriceList.push(priceObj);
          }
          let countryCode = "";
          let countryName = "";

          for (let i = 0; i < this.state.countryList.length; i++) {
            if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              this.state.countryList[i].name.toUpperCase()
            ) {
              countryCode = this.state.countryList[i].alpha2;
              countryName = this.state.countryList[i].name;
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "KOREA (SOUTH)"
            ) {
              countryCode = "410";
              countryName = "KOREA (SOUTH)";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "VIETNAM"
            ) {
              countryName = "VIETNAM";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "COTE D'IVOIRE"
            ) {
              countryName = "COTE D'IVOIRE";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "PALESTINE"
            ) {
              countryName = "PALESTINE";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "MACEDONIA"
            ) {
              countryName = "MACEDONIA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "TANZANIA"
            ) {
              countryName = "TANZANIA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "VENEZUELA"
            ) {
              countryName = "VENEZUELA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "BOLIVIA"
            ) {
              countryName = "BOLIVIA";
            }
          }
          let companyInfo = output.data.companySnashot.companyInfo;
          this.setState({
            wqr: wrightRatings,
            companyDetails: companyInfo,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            summaryAnalysis: summaryAnalysis,
            salesAnalysis: salesAnalysis,
            priceAnalysis: priceAnalysis,
            earningsAndDividend: earningsAndDividend,
            ratioAnalysisData: output.data ? output.data.companySnashot : {},
            quarterlyEarning: quarterlyEarning,
            quarterlyDividend: quarterlyDividend,
            snapshot: output.data ? output.data.companySnashot.snapshot : {},
            priceData: output.data ? output.data.companySnashot.priceData : {},
            countryName: countryName,
            countryCode: countryCode,
            render: true,
            radarData: [iaRatingPts, fsRatingPts, psRatingPts, grRatingPts],
            series: [
              {
                name: "EPS",
                type: "bar",
                data: quarterlyEarningValues,
              },
              {
                name: "DPS",
                type: "bar",
                data: quarterlyDividendValues,
              },
            ],

            options: {
              chart: {
                height: 350,
                type: "line",
                stacked: false,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },

              grid: {
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              title: {
                text: output.data.companySnashot.companyInfo.name,
                align: "left",
              },
              stroke: {
                width: [0, 2, 5],
                curve: "smooth",
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        to: 0,
                        from: -100000000000,
                        color: "#F15B46",
                      },
                    ],
                  },
                  columnWidth: "50%",
                },
              },
              colors: ["#ff7f0e", "#1f77b4"],

              legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "top",
                horizontalAlign: "center",
                floating: false,
                fontSize: "14px",
                fontFamily: "Helvetica, Arial",
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                // offsetX: 0,
                // offsetY: 0,
                labels: {
                  colors: ["#7d6608", "#0b5345"],
                  useSeriesColors: true,
                },
              },

              labels: earningsDividendLabel,
              // markers: {
              //   size: 0,
              // },
              xaxis: {
                title: {
                  text: "Fiscal Year",
                  offsetY:2,
                  style: {
                    colors: "#808b96",
                  },
                  labels: {
                    style: {
                      colors: "#808b96 ",
                    },
                  },
                },

                axisBorder: {
                  show: true,
                  color: "#808b96",
                  height: 1,
                  width: "100%",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
              // yaxis: [
              //   {
              //     axisTicks: {
              //       show: true,
              //     },

              //     axisBorder: {
              //       show: true,
              //       color: "#247BA0",
              //     },
              //     labels: {
              //       formatter: function (val, index) {
              //         if (val) {
              //           return val;
              //         }
              //       },
              //       style: {
              //         colors: "#247BA0",
              //       },
              //     },
              //     title: {
              //       text: "",
              //       style: {
              //         color: "#247BA0",
              //       },
              //     },
              //   },
              // ],
              yaxis: [
                {
                  axisTicks: {
                    show: true,
                  },
                  forceNiceScale: false,
                  axisBorder: {
                    show: true,
                    color: "#000",
                  },
                  labels: {
                    style: {
                      colors: "#000",
                    },
                  },
                  title: {
                    text: "EPS & DPS",
                    style: {
                      // color: '#7d6608',
                      color: "#000",
                    },
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                // {
                //   seriesName: 'DPS',
                //   opposite: true,

                //   axisTicks: {
                //     show: true,
                //   },
                //   axisBorder: {
                //     show: true,
                //     color: '#000'
                //   },
                //   labels: {
                //     style: {
                //       colors: '#000',
                //     }
                //   },
                //   title: {
                //     text: "DPS",
                //     style: {
                //       color: '#0b5345',
                //     }
                //   },
                // },
              ],
              // yaxis: {
              //   title: {
              //     text: "values",
              //   },
              //   // min: 0,
              // },
              tooltip: {
                shared: true,
                intersect: false,
              },
            },
          });
        }
      })
      .catch((error) => {});
  }
  onUpdate() {
    this.headerLoginRef.current.onUpdate();
    this.getButton();
  }
  getFinancialStatementReportData(value) {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("financial/getAllActualReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let actualData = data.actualIncomeStatement;
          actualData = {
            cusip: actualData.cusip,
            unit: actualData.unit,
            country: actualData.country,
            currency: actualData.currency,
            incomeStmtList: actualData.incomeStmtList.reverse(),
            ind_TYPE: actualData.ind_TYPE,
            bankIncomeStmtList: actualData.bankIncomeStmtList.reverse(),
            insIncomeStmtList: actualData.insIncomeStmtList.reverse(),
            oFinIncomeStmtList: actualData.oFinIncomeStmtList.reverse(),
          };

          let balanceSheetActualData = data.actualBalancesheet;

          balanceSheetActualData = {
            cusip: balanceSheetActualData.cusip,
            unit: balanceSheetActualData.unit,
            country: balanceSheetActualData.country,
            currency: balanceSheetActualData.currency,
            ind_type: balanceSheetActualData.ind_type,
            indBalanceSheetList:
              balanceSheetActualData.indBalanceSheetList.reverse(),
            bankBalanceSheetList:
              balanceSheetActualData.bankBalanceSheetList.reverse(),
            insBalanceSheetList:
              balanceSheetActualData.insBalanceSheetList.reverse(),
            oFinBalanceSheetList:
              balanceSheetActualData.oFinBalanceSheetList.reverse(),
          };
          let sourceOfCapitalData = data.sourceOfCapital;

          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            actualData: actualData,
            balanceSheetActualData: balanceSheetActualData,
            sourceOfCapitalData: sourceOfCapitalData,
            // value: value,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  showCompanySnapshot() {
    this.setState({
      cmpSnapshot: "company_InfoAll",
      isReadMoreBtn: false,
    });
  }
  getFinancialRatioReportData() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("financialRatio/getAllReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let accountingRatio = {};
          let assetUtilizationRatio = {};
          let employeeEfficiencyRatio = {};
          let fixChargeCoverageRatio = {};
          let leverageAnalysisRatio = {};
          let perShareProfitRatio = {};
          let profitabilityAnalysisRatio = {};
          let liquidityAnalysisRatio = {};
          if (data) {
            accountingRatio = data.accountingRatio;
            assetUtilizationRatio = data.assetUtilizationRatio;
            employeeEfficiencyRatio = data.employeeEfficiencyRatio;
            fixChargeCoverageRatio = data.fixChargeCoverageRatio;
            leverageAnalysisRatio = data.leaverageAnalysisRatio;
            perShareProfitRatio = data.perShareProfitRatio;
            profitabilityAnalysisRatio = data.profitabilityAnalysisRatio;
            liquidityAnalysisRatio = data.liquidityAnalysisRatio;
          }

          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            accountingRatio: accountingRatio,
            assetUtilizationRatio: assetUtilizationRatio,
            employeeEfficiencyRatio: employeeEfficiencyRatio,
            fixChargeCoverageRatio: fixChargeCoverageRatio,
            leverageAnalysisRatio: leverageAnalysisRatio,
            perShareProfitRatio: perShareProfitRatio,
            profitabilityAnalysisRatio: profitabilityAnalysisRatio,
            liquidityAnalysisRatio: liquidityAnalysisRatio,
          });
          this.getFlowOfFundReportData();
        } else {
        }
      })
      .catch((error) => {});
  }
  getFlowOfFundReportData() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("flowOfFunds/getAllReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let actualSourceAndUses = {};
          let actualCashBasis = {};
          let actualFasb95 = {};
          let ratios = {};
          if (data) {
            actualSourceAndUses = data.actualSourceAndUses;
            actualSourceAndUses = {
              country: actualSourceAndUses.country,
              currency: actualSourceAndUses.currency,
              fOfSandUActualList:
                actualSourceAndUses.fOfSandUActualList.reverse(),
            };
            actualCashBasis = data.actualCashBasis;
            actualCashBasis = {
              country: actualCashBasis.country,
              currency: actualCashBasis.currency,
              fofCashActualList: actualCashBasis.fofCashActualList.reverse(),
            };
            actualFasb95 = data.actualFasb95;
            actualFasb95 = {
              country: actualFasb95.country,
              currency: actualFasb95.currency,
              flowOfFundFASBActualList:
                actualFasb95.flowOfFundFASBActualList.reverse(),
            };
            ratios = data.ratios;
          }

          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            actualSourceAndUses: actualSourceAndUses,
            actualCashBasis: actualCashBasis,
            actualFasb95: actualFasb95,
            ratios: ratios,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleChange = (event, value) => {
    if (window.pageYOffset > 100) {
      window.scrollTo(0, -0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
    }

    let userData = store.getState().userData.userData;

    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      if (userData === undefined) {
        this.setState({ showModal1: true });
      } else if (userData.activeUser === false) {
        this.setState({ showModal1: true });
      } else {
        this.getButton();
        this.setState({
          value: value,
        });
      }
    } else {
      this.setState({ showModal1: true });
    }
  };
  toBuyPlan() {
    this.props.history.push({
      pathname: "/plans",
    });
  }

  toBuyReport() {
    let selectedCompanyReportObj = {
      cusip: this.state.companyDetails.cusip,
      tableName: this.state.selectedCompany.tableName,
      companyName: this.state.companyDetails.name,
      ticker: this.state.companyDetails.ticker,
      country: this.state.companyDetails.country,
      countryCode: this.state.companyDetails.countryCode,
      region: this.state.companyDetails.region,
      dssTicker: this.state.selectedCompany.dssTicker,
      shouldAppearInSearch: this.state.selectedCompany.shouldAppearInSearch,
      corpInfoIndustry: this.state.selectedCompany.corpInfoIndustry,
      searchDescription: this.state.companyDetails.name,
      fullPDF: this.state.selectedCompany.fullPDF,
      codeCountry: this.state.selectedCompany.codeCountry,
      planId: "3",
      price: this.state.price,
      type: "Company",
    };

    this.cartData = store.getState().cartData.cart;

    let reports = this.cartData;
    for (let i = 0; i < this.cartData.length; i++) {
      let cusip = this.cartData[i].cusip;
      if (
        Utils.equalsIgnoreCase("Company", selectedCompanyReportObj.type) &&
        Utils.equalsIgnoreCase(cusip, selectedCompanyReportObj.cusip)
      ) {
        PtsAlert.error(
          selectedCompanyReportObj.type +
            " " +
            "Report " +
            selectedCompanyReportObj.companyName +
            " Already exists in your Cart"
        );
        return;
      }
    }

    reports.push(selectedCompanyReportObj);
    store.dispatch(cartAction.setCart(reports));
    this.props.history.push({
      pathname: "/cart",
      state: {
        previousPage: "/",
      },
    });
  }
  // toBuyReport() {
  //   let selectedCompanyReportObj = {
  //     cusip: this.state.selectedCompany.cusip,
  //     tableName: this.state.selectedCompany.tableName,
  //     companyName: this.state.selectedCompany.companyName,
  //     ticker: this.state.selectedCompany.ticker,
  //     country: this.state.selectedCompany.country,
  //     countryCode: this.state.selectedCompany.countryCode,
  //     region: this.state.selectedCompany.region,
  //     dssTicker: this.state.selectedCompany.dssTicker,
  //     shouldAppearInSearch: this.state.selectedCompany.shouldAppearInSearch,
  //     corpInfoIndustry: this.state.selectedCompany.corpInfoIndustry,
  //     searchDescription: this.state.selectedCompany.searchDescription,
  //     fullPDF: this.state.selectedCompany.fullPDF,
  //     codeCountry: this.state.selectedCompany.codeCountry,
  //     planId: "3",
  //     price: this.state.price,
  //     type: "Company",
  //   };

  //   this.cartData = store.getState().cartData.cart;

  //   let reports = this.cartData;
  //   for (let i = 0; i < this.cartData.length; i++) {
  //     let cusip = this.cartData[i].cusip;
  //     if (
  //       Utils.equalsIgnoreCase("Company", selectedCompanyReportObj.type) &&
  //       Utils.equalsIgnoreCase(cusip, selectedCompanyReportObj.cusip)
  //     ) {
  //       PtsAlert.error(
  //         selectedCompanyReportObj.type +
  //           " " +
  //           "Report " +
  //           selectedCompanyReportObj.companyName +
  //           " Already exists in your Cart"
  //       );
  //       return;
  //     }
  //   }

  //   reports.push(selectedCompanyReportObj);
  //   store.dispatch(cartAction.setCart(reports));
  //   this.props.history.push({
  //     pathname: "/cart",
  //     state: {
  //       previousPage: "/",
  //     },
  //   });
  // }

  toggle1 = () => {
    this.setState({ showModal1: !this.state.showModal1 });
  };
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  getButton() {
    let userData = store.getState().userData.userData;
    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      if (userData) {
        if (userData.activeUser === false) {
          return (
            <Button
              style={{
                background: "#f58d51",
                fontSize: "0.7em",
                float: "right",
                webkitBorderRadius: "10px",
                height: "2.8em",
                borderBottom: "2px solid #636262",
                width: "7.4em",
                padding: "0px",
                fontWeight: "500",
              }}
              onClick={this.toBuyReport.bind(this)}
            >
              <CreditCardIcon style={{ fontSize: "1.2em" }} /> Buy Report
            </Button>
          );
        } else {
          return <></>;
        }
      } else {
        return (
          <Button
            style={{
              background: "#f58d51",
              fontSize: "0.7em",
              float: "right",
              webkitBorderRadius: "10px",
              height: "2.8em",
              borderBottom: "2px solid #636262",
              width: "7.4em",
              padding: "0px",
              fontWeight: "500",
            }}
            onClick={this.toBuyReport.bind(this)}
          >
            <CreditCardIcon style={{ fontSize: "1.2em" }} /> Buy Report
          </Button>
        );
      }
    } else {
      return (
        <Button
          style={{
            background: "#f58d51",
            fontSize: "0.7em",
            float: "right",
            webkitBorderRadius: "10px",
            height: "2.8em",
            borderBottom: "2px solid #636262",
            width: "7.4em",
            padding: "0px",
            fontWeight: "500",
          }}
          onClick={this.toBuyReport.bind(this)}
        >
          <CreditCardIcon style={{ fontSize: "1.2em" }} /> Buy Report
        </Button>
      );
    }
  }
  render() {
    if (this.state.render === false) {
      return (
        <>
          <CompanyDetailLayoutHeader
            history={this.props.history}
            {...this.props}
            ref={this.headerLoginRef}
          ></CompanyDetailLayoutHeader>
          <Row className="breadcrumbs_fix_top_blank">
            <section class="breadcrumbs">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    <h4>Company Reports</h4>
                  </div>
                  <div class="col-lg-6">
                    <GenericSearch history={this.props.history} color="white" />
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <div style={{ minHeight: "100em" }}></div>
        </>
      );
    } else {
      return (
        <>
          <CompanyDetailLayoutHeader
            history={this.props.history}
            {...this.props}
            ref={this.headerLoginRef}
          ></CompanyDetailLayoutHeader>
          <Row className="breadcrumbs_fix_top">
            <section class="breadcrumbs">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    {this.state.companyDetails.name === null ||
                    this.state.companyDetails.name === undefined ? (
                      <h4>Company Reports</h4>
                    ) : (
                      <h4>{this.state.companyDetails.name}</h4>
                    )}
                  </div>
                  <div class="col-lg-6">
                    <SearchWithResult
                      history={this.props.history}
                      color="white"
                      pageId="NotHome"
                    ></SearchWithResult>
                  </div>
                </div>
              </div>
            </section>
          </Row>

          <Container maxWidth="xl" className="company_snap_tabs">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              onClick={(e) => this.toTop(e)}
              indicatorColor="primary"
              textColor="primary"
              className={this.state.tabTop}
              scrollButtons="auto"
              style={{ marginTop: "7em" }}
            >
              <Tab
                label="Fundamental Reports"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              <Tab
                label="Wright Quality Analysis"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              <Tab
                label="Comparative Business Analysis"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              <Tab
                label="Financial Statement Reports"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              <Tab
                label="Financial Ratio Reports"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              <Tab
                label="Flow of Funds Reports"
                wrapped
                onClick={(e) => this.toTop(e)}
              />
              {/* <Tab> */}
              {this.getButton()}

              {/* </Tab> */}
            </Tabs>

            {this.state.value === 0 && (
              <>
                <Row style={{ marginTop: "-0.8em" }}>
                  <Col
                    md={3}
                    style={{ background: "rgb(23, 32, 42)", padding: "0px" }}
                  >
                    <div
                      style={{ position: "fixed" }}
                      className="sidebar"
                      id="sidebar"
                    >
                      <ScrollspyNav
                        scrollTargetIds={[
                          "company_profile",
                          "summary_analysis",
                          "sales_analysis",
                          "price_analysis",
                          "ed_analysis",
                        ]}
                        offset={50}
                        activeNavClass="is-active"
                        headerBackground="true"
                        scrollDuration="100"
                      >
                        <ul
                          className="menu_List"
                          id="fundamentalReport"
                          style={{ listStyle: "none" }}
                        >
                          <li
                            className="report_Li_list"
                            // className={this.state.className}
                          >
                            <a href="#company_profile">Company Profile</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#summary_analysis">Summary Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#sales_analysis">Sales Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#price_analysis">Price Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#ed_analysis">
                              Earnings & Dividends Analysis
                            </a>
                          </li>
                        </ul>
                      </ScrollspyNav>

                      {this.state.windowSize > 768 ? (
                        <Advertisement advertisementType="SquareAddUnit" />
                      ) : (
                        <></>
                      )}
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </Col>
                  <Col
                    md={9}
                    style={{ padding: "0px" }}
                    className="tab_to_scroll_top"
                  >
                    <Drawer
                      open={this.state.left}
                      onClose={this.toggleDrawer("left", false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer("left", false)}
                        onKeyDown={this.toggleDrawer("left", false)}
                      >
                        <ul
                          className="menu_List"
                          id="fundamentalReport"
                          style={{ listStyle: "none" }}
                        >
                          <li className="report_Li_list">
                            <a
                              href="#company_profile"
                              className={this.state.className}
                            >
                              Company Profile
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#summary_analysis">Summary Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#sales_analysis">Sales Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#price_analysis">Price Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#ed_analysis">
                              Earnings & Dividends Analysis
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                    <div id="company_profile">
                      <div
                        style={{
                          height: "auto",
                          paddingLeft: "12px",
                          background:
                            "linear-gradient(to left, #141e30, #243b55)",
                        }}
                        className="cmp_data"
                      >
                        <div>
                          <Row>
                            <Col
                              md={10}
                              style={{
                                textAlign: "center",
                              }}
                              className="pts_mobile_view"
                            >
                              <span
                                style={{ float: "left" }}
                                class="drawer_float"
                              >
                                <img
                                  className="my-float"
                                  src="home/assets/img/drawer_menu.png"
                                  alt="drawer_menu"
                                  onClick={this.toggleDrawer("left", true)}
                                />
                              </span>

                              <h3
                                style={{
                                  color: "rgb(245, 176, 65)",
                                  fontWeight: "bold",
                                  textDecoration: "underline",
                                }}
                                class="font-weight-bold mb-0"
                              >
                                {this.state.companyDetails.name}&nbsp;
                                <b>({this.state.companyDetails.ticker})</b>
                              </h3>
                              <Row>
                                <Col md={5} style={{ textAlign: "left" }}></Col>
                                <Col md={6} style={{ textAlign: "left" }}>
                                  <small
                                    class="text-secondary highlight_text"
                                    style={{ display: "block" }}
                                  >
                                    &nbsp;
                                    <span>
                                      {Utils.getCountryFlag(
                                        this.state.countryName
                                      )}
                                    </span>{" "}
                                    <span
                                      className=""
                                      style={{ color: "rgb(245, 176, 65)" }}
                                    >
                                      {this.state.companyDetails.country}{" "}
                                    </span>
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <span className="cmp_unit_str">
                              All Figures in&nbsp;
                              <span>
                                {(this.state.companyDetails.unit !== null) !==
                                null
                                  ? this.state.companyDetails.unit
                                  : ""}
                              </span>
                            </span>
                            <Col md={6}>
                              <Row className="company_info">
                                <Col md={6}>
                                  <label>Ticker:</label>

                                  <h6>{this.state.companyDetails.ticker}</h6>
                                </Col>
                                <Col md={6}>
                                  <label>
                                    {this.state.companyDetails.salesYear} Sales
                                  </label>
                                  <h6>
                                    {this.state.companyDetails.sales === "0" ||
                                    this.state.companyDetails.sales === null ||
                                    this.state.companyDetails.sales ===
                                      undefined ? (
                                      <h6 title="Not Available"> n/a</h6>
                                    ) : (
                                      <h6>
                                        {this.state.companyDetails.sales
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </h6>
                                    )}
                                    {/* {this.state.companyDetails.sales
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                                  </h6>
                                </Col>
                              </Row>
                              <Row className="company_info">
                                <Col md={6}>
                                  <label>Major Industry:</label>
                                  <h6>
                                    {this.state.companyDetails.majIndustryName}
                                  </h6>
                                </Col>
                                <Col md={6}>
                                  <label>Sub Industry:</label>
                                  <h6>
                                    {this.state.companyDetails.miniIndustryName}
                                  </h6>
                                </Col>
                              </Row>
                              <Row className="company_info">
                                <Col md={6}>
                                  <label>Country:</label>
                                  <h6>{this.state.companyDetails.country}</h6>
                                </Col>
                                <Col md={6}>
                                  <label>Currency</label>
                                  <h6>{this.state.companyDetails.unit}</h6>
                                </Col>
                              </Row>
                              <Row className="company_info">
                                <Col md={6}>
                                  <label>Fiscal Year Ends:</label>
                                  <h6>{this.state.companyDetails.fis_end}</h6>
                                </Col>
                                <Col md={6}>
                                  <label>Employees:</label>
                                  <div>
                                    {" "}
                                    {this.state.companyDetails.employees.toString() ===
                                      "0" ||
                                    this.state.companyDetails.employees ===
                                      undefined ||
                                    this.state.companyDetails.employees ===
                                      null ? (
                                      <h6 title="Not Available"> n/a</h6>
                                    ) : (
                                      <h6>
                                        {this.state.companyDetails.employees
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </h6>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <Col md={6} style={{ margin: "1em -0.5em" }}>
                              <Radar
                                data={{
                                  labels: [
                                    "Investment Acceptance",
                                    "Financial Strength",
                                    " Profitability & Stability",
                                    " Growth",
                                  ],
                                  datasets: [
                                    {
                                      label: this.state.wqr,
                                      backgroundColor:
                                        this.state.backgroundColor,
                                      borderColor: this.state.borderColor,
                                      borderJoinStyle: "round",
                                      borderWidth: "10px",
                                      borderDashOffset: "5",
                                      lineTension: "0.5",
                                      fontColor: "#fff",

                                      fill: true,
                                      chart: {
                                        height: 350,
                                        type: "radar",
                                      },
                                      scaleLineWidth: 16,
                                      scaleLineColor: "#000",
                                      data: this.state.radarData,
                                      borderWidth: 3,
                                    },
                                  ],
                                }}
                                style={{ margin: "3em 0 0 0" }}
                                options={{
                                  scale: {
                                    gridLines: {
                                      circular: true,
                                      color: "#7B7D7D ",
                                      backdropColor: "#000",
                                    },
                                    pointLabels: {
                                      fontColor: "#ccd1d1",
                                    },
                                    ticks: {
                                      beginAtZero: true,
                                      backdropColor: "transparent",
                                      fontColor: "#000",
                                      display: false,
                                    },
                                    chartArea: {
                                      backgroundColor: "rgba (247, 247, 246)",
                                    },
                                  },

                                  scaleLineWidth: 16,

                                  stroke: {
                                    show: true,
                                    width: 6,
                                    colors: [],
                                    dashArray: 0,
                                  },
                                  pointStyle: "circle",
                                  legend: {
                                    display: true,
                                    position: "bottom",
                                    align: "middle",
                                    labels: {
                                      fontColor: "#d0d3d4 ",
                                      fontSize: 16,
                                      fontStyle: "bold",
                                    },
                                  },
                                }}
                              />
                            </Col>
                          </Row>

                          <Row className="company_info">
                            <Col md={3}>
                              <label>Exchanges:</label>
                              <h6>{this.state.companyDetails.exchanges}</h6>
                            </Col>
                            <Col md={3}>
                              <label>Share Type:</label>
                              <h6>{this.state.companyDetails.shr_type}</h6>
                            </Col>
                            <Col md={3}>
                              <label>Market Capitalization:</label>
                              {this.state.companyDetails.cur_mcap === "0" ||
                              this.state.companyDetails.cur_mcap ===
                                undefined ||
                              this.state.companyDetails.cur_mcap === null ? (
                                <h6 title="Not Available"> n/a</h6>
                              ) : (
                                <h6>
                                  {this.convertMarketCapitalisation(this.state.companyDetails.cur_mcap)}
                                </h6>
                              )}
                            </Col>
                            <Col md={3}>
                              <label>Total Shares Outstanding:</label>

                              <h6>
                                {this.state.companyDetails.com_shr
                                  ? this.state.companyDetails.com_shr
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""}
                              </h6>
                            </Col>
                          </Row>
                          <Row className="company_info">
                            <Col md={3}>
                              <label>Closely Held Shares:</label>
                              <h6>
                                {this.state.companyDetails.cls_hld
                                  ? this.state.companyDetails.cls_hld
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : ""}
                              </h6>
                            </Col>
                            <Col md={3}>
                              <label>Current Stock Price:</label>
                              <h6>
                                <b>
                                  {moment(
                                    this.state.companyDetails.cur_priceDate
                                  ).format("MM/DD/YYYY") +
                                    ": " +
                                    this.state.companyDetails.cur_prc}
                                </b>
                              </h6>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <Row className="company_info">
                                <Col md={4}>
                                  <b
                                    style={{
                                      color: "#f5b041",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Recent stock performance
                                  </b>
                                  <h6 style={{ margin: "0.8em 0em 0.5em 0em" }}>
                                    {"1 Week:  " +
                                      this.state.companyDetails.trend1week +
                                      "%"}
                                  </h6>
                                  <h6>
                                    {"4 Week:  " +
                                      this.state.companyDetails.trend4week +
                                      "%"}
                                  </h6>
                                  <h6>
                                    {"13 Week:  " +
                                      this.state.companyDetails.trend13week +
                                      "%"}
                                  </h6>
                                  <h6>
                                    {"52 Week:  " +
                                      this.state.companyDetails.trend52week +
                                      "%"}
                                  </h6>
                                </Col>
                                <Col md={3}>
                                  <b
                                    style={{
                                      padding: "15px",
                                      color: "#f5b041",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Ratio Analysis
                                  </b>
                                  {/* <Row>
                                  <Col md={6}> */}
                                  <h6 style={{ margin: "0.7em 0em 0.5em 0em" }}>
                                    {this.state.ratioAnalysisData
                                      ? "Price / Earnings Ratio:  " +
                                        this.state.ratioAnalysisData.peRatio +
                                        "x"
                                      : ""}
                                  </h6>
                                  <h6>
                                    {this.state.ratioAnalysisData
                                      ? "Price / Sales Ratio:  " +
                                        this.state.ratioAnalysisData
                                          .priceSalesRatio +
                                        "x"
                                      : ""}
                                  </h6>
                                  <h6>
                                    {this.state.ratioAnalysisData
                                      ? "Price / Book Ratio:  " +
                                        this.state.ratioAnalysisData
                                          .priceBookRatio +
                                        "x"
                                      : ""}
                                  </h6>
                                  <h6>
                                    {this.state.ratioAnalysisData
                                      ? "Dividend Yield:  " +
                                        this.state.ratioAnalysisData
                                          .dividendYield +
                                        "%"
                                      : ""}
                                  </h6>
                                  <h6>
                                    {this.state.ratioAnalysisData
                                      ? "Payout Ratio:  " +
                                        this.state.ratioAnalysisData
                                          .payoutRatio +
                                        "%"
                                      : ""}
                                  </h6>
                                  <h6>
                                    {this.state.ratioAnalysisData
                                      ? "% Held by Insiders:  " +
                                        this.state.ratioAnalysisData
                                          .heldByInsider +
                                        "%"
                                      : ""}
                                  </h6>
                                </Col>
                                <Col md={5}>
                                  <b
                                    style={{
                                      color: "#f5b041",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Earnings / Dividends
                                  </b>
                                  <div className="earn_dividend">
                                    <table
                                      width="90%"
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="1"
                                      className="earn_dividend_table"
                                    >
                                      <tbody>
                                        <tr>
                                          <th></th>
                                          <th>Earnings</th>
                                          <th>Dividends</th>
                                        </tr>
                                        <tr>
                                          <td>Most Recent Qtr</td>
                                          <td align="left">
                                            {this.state.quarterlyEarning !==
                                            null
                                              ? this.state.quarterlyEarning.toFixed(
                                                  2
                                                )
                                              : ""}
                                          </td>
                                          <td align="left">
                                            {this.state.quarterlyDividend !==
                                            null
                                              ? this.state.quarterlyDividend.toFixed(
                                                  2
                                                )
                                              : ""}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Last 12 Months</td>
                                          <td align="left">
                                            {this.state.companyDetails
                                              ._2me_cr !== null
                                              ? this.state.companyDetails._2me_cr.toFixed(
                                                  2
                                                )
                                              : ""}
                                          </td>
                                          <td align="left">
                                            {this.state.companyDetails
                                              ._2mdv_cr !== null
                                              ? this.state.companyDetails._2mdv_cr.toFixed(
                                                  2
                                                )
                                              : ""}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Row className="company_info">
                                <Col md={12}></Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <b
                                style={{
                                  padding: "15px",
                                  color: "#f5b041",
                                  textDecoration: "underline",
                                }}
                              >
                                Officers
                              </b>
                              <Row className="company_info">
                                <Col md={6}>
                                  <label>
                                    {this.state.companyDetails.off_1a}
                                  </label>
                                  <h6>{this.state.companyDetails.off_1}</h6>
                                </Col>
                                <Col md={6}>
                                  <label>
                                    {this.state.companyDetails.off_2a}
                                  </label>
                                  <h6>{this.state.companyDetails.off_2}</h6>
                                </Col>
                              </Row>
                              <Row className="company_info">
                                <Col md={6}>
                                  {this.state.companyDetails.off_3a === null ? (
                                    <label style={{ display: "none" }}> </label>
                                  ) : (
                                    <label>
                                      {this.state.companyDetails.off_3a}
                                    </label>
                                  )}
                                  {this.state.companyDetails.off_3 === null ? (
                                    <h6 style={{ display: "none" }}> </h6>
                                  ) : (
                                    <h6>{this.state.companyDetails.off_3}</h6>
                                  )}
                                </Col>
                                <Col md={6}>
                                  {this.state.companyDetails.off_4a === null ? (
                                    <label style={{ display: "none" }}> </label>
                                  ) : (
                                    <label>
                                      {this.state.companyDetails.off_4a}
                                    </label>
                                  )}
                                  {this.state.companyDetails.off_4 === null ? (
                                    <h6 style={{ display: "none" }}> </h6>
                                  ) : (
                                    <h6>{this.state.companyDetails.off_4}</h6>
                                  )}
                                </Col>
                              </Row>
                              <Row className="company_info">
                                <Col md={6}>
                                  {this.state.companyDetails.off_5a === null ? (
                                    <label style={{ display: "none" }}> </label>
                                  ) : (
                                    <label>
                                      {this.state.companyDetails.off_5a}
                                    </label>
                                  )}

                                  {this.state.companyDetails.off_5 === null ? (
                                    <h6 style={{ display: "none" }}> </h6>
                                  ) : (
                                    <h6>{this.state.companyDetails.off_5}</h6>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="bg_addr ">
                            <Col md={6}>
                              <b
                                style={{
                                  padding: "15px",
                                  color: "#f5b041",
                                  textDecoration: "underline",
                                }}
                              >
                                Address
                              </b>
                              <h6 style={{ paddingLeft: "13px" }}>
                                {this.state.companyDetails.addr_1}
                              </h6>
                              <h6 style={{ paddingLeft: "13px" }}>
                                {this.state.companyDetails.addr_2 === "" ||
                                this.state.companyDetails.addr_2 === null ||
                                this.state.companyDetails.addr_2 === undefined
                                  ? ""
                                  : this.state.companyDetails.addr_2 + ","}
                                {" " + this.state.companyDetails.addr_3}
                              </h6>

                              <h6 style={{ paddingLeft: "13px" }}>
                                {this.state.companyDetails.newCountry
                                  ? this.state.companyDetails.newCountry
                                  : ""}
                              </h6>
                              <h6 style={{ paddingLeft: "13px" }}>
                                {this.state.companyDetails.addr_4 === "" ||
                                this.state.companyDetails.addr_4 === null ||
                                this.state.companyDetails.addr_4 === undefined
                                  ? ""
                                  : this.state.companyDetails.addr_4 + "."}
                              </h6>
                            </Col>
                            <Col md={6}>
                              <label>Phone </label>
                              <h6>{this.state.companyDetails.phone}</h6>
                              <label>Home Page</label>
                              <br></br>
                              <b>
                                <a
                                  href={this.state.companyDetails.webAddress}
                                  target="_blank"
                                  className="cmp_website"
                                >
                                  {this.state.companyDetails.webAddress}
                                </a>
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              md={12}
                              style={{ margin: "22px 0px 0px -4px" }}
                            >
                              <b
                                style={{
                                  padding: "15px",
                                  color: "#f5b041",
                                  textDecoration: "underline",
                                }}
                              >
                                Company Snapshot
                              </b>
                              <a
                                href="/company_rating#/Wrightqualityanalysis"
                                className="unit_str1"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                target="_self"
                              >
                                Wright Quality Rating:{" "}
                                <span>
                                  <b>{this.state.companyDetails.q_rat}</b>
                                </span>
                              </a>
                              <p
                                className={this.state.cmpSnapshot}
                                style={{ color: "#dee2e6" }}
                              >
                                {this.state.snapshot}
                              </p>
                              {this.state.isReadMoreBtn && (
                                <span style={{ padding: "10px" }}>
                                  <a
                                    className="read_more"
                                    onClick={(e) => this.showCompanySnapshot()}
                                  >
                                    Read More
                                  </a>
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>

                        <Row className="cmpny_info_section">
                          <Col md={12}>
                            <Row
                              style={{
                                padding: "10px",
                                borderBottom: "1px solid #EAEDED",
                              }}
                            >
                              <Col md={12}>
                                <h5 className="section_header">
                                  {" "}
                                  &nbsp;Earnings & Dividends
                                </h5>
                                <span className="unit_str">
                                  {"Values in " +
                                    this.state.companyDetails.unit}
                                </span>
                              </Col>
                            </Row>
                            <div id="chart" style={{ padding: "10px" }}>
                              <ReactApexCharts
                                options={this.state.options}
                                series={this.state.series}
                                type="line"
                                height={350}
                                // width={1000}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div
                      id="summary_analysis"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <span
                          style={{
                            borderBottom: "1px solid #abb2b9",
                            padding: "5px",
                          }}
                        >
                          {/* <span className="header_count">1</span> */}
                          <span className="section_main_header">
                            Fundamental Reports
                          </span>
                        </span>

                        <Col md={12}>
                          <Row
                            style={{
                              padding: "10px",
                            }}
                          >
                            <Col md={12}></Col>
                          </Row>
                          <CardBody>
                            <SummaryAnalysisPerShare
                              data={this.state.summaryAnalysis}
                            ></SummaryAnalysisPerShare>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="sales_analysis"
                      style={{ height: "auto", padding: "15px" }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <SalesAnalysis
                              data={this.state.salesAnalysis}
                            ></SalesAnalysis>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="price_analysis"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <PriceAnalysis
                              data={this.state.priceAnalysis}
                            ></PriceAnalysis>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="ed_analysis"
                      style={{ height: "auto", padding: "15px" }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <EarningAndDividends
                              data={this.state.earningsAndDividend}
                            ></EarningAndDividends>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {this.state.value === 1 && (
              <>
                <Row className="pts_company_snap_mobile_view">
                  <Col
                    md={3}
                    style={{ background: "rgb(23, 32, 42)", padding: "0px" }}
                  >
                    <div
                      style={{
                        position: "fixed",
                      }}
                      className="sidebar"
                      id="sidebar"
                    >
                      <ScrollspyNav
                        scrollTargetIds={[
                          "investment_acceptance",
                          "financial_strength",
                          "profitability_stability",
                          "corporate_growth",
                        ]}
                        offset={10}
                        activeNavClass="is-active"
                        headerBackground="true"
                        scrollDuration="100"
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a
                              href="#investment_acceptance"
                              className={this.state.className}
                            >
                              Investment Acceptance
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#financial_strength">Financial Strength</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#profitability_stability">
                              Profitability & Stability
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#corporate_growth">Corporate Growth</a>
                          </li>
                        </ul>
                      </ScrollspyNav>
                    </div>
                  </Col>
                  <Col md={9} style={{ padding: "0px", minHeight: "40em" }}>
                    <span style={{ float: "left" }} class="drawer_float">
                      <img
                        className="my-float"
                        src="home/assets/img/drawer_menu.png"
                        alt="drawer_menu"
                        onClick={this.toggleDrawer("left", true)}
                      />
                    </span>
                    <Drawer
                      open={this.state.left}
                      onClose={this.toggleDrawer("left", false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer("left", false)}
                        onKeyDown={this.toggleDrawer("left", false)}
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li
                            className="report_Li_list"
                            // className={this.state.className}
                          >
                            <a href="#investment_acceptance">
                              Investment Acceptance
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#financial_strength">Financial Strength</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#profitability_stability">
                              Profitability & Stability
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#corporate_growth">Corporate Growth</a>
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                    <div
                      id="investment_acceptance"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <InvestmentAcceptance
                              data={this.state.cusip}
                            ></InvestmentAcceptance>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="financial_strength"
                      style={{ height: "auto", padding: "15px" }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <FinancialStrength
                              data={this.state.cusip}
                            ></FinancialStrength>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="profitability_stability"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <ProfitabilityStability
                              data={this.state.cusip}
                            ></ProfitabilityStability>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="corporate_growth"
                      style={{ height: "auto", padding: "15px" }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <CorporateGrowth
                              data={this.state.cusip}
                            ></CorporateGrowth>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {this.state.value === 2 && (
              <>
                <Row
                  style={{
                    background:
                      "rgba(22, 160, 133, 0.1) none repeat scroll 0% 0%",
                    marginTop: "0.2em",
                    minHeight: "30em",
                  }}
                >
                  <Col md={12} className="cba_mobile_view">
                    <span>
                      {/* <span className="header_count">3</span> */}
                      <span
                        className="section_main_header_cba"
                        style={{ marginLeft: "0.7em" }}
                      >
                        {" "}
                        Comparative Business Analysis
                      </span>
                    </span>
                    <CardBody>
                      <ComparativeBusinessAnalysis
                        ref={this.cbaRef}
                        data={this.state.cusip}
                        history={this.props.history}
                        // onClick={this.toCompanyDetails.bind(this)}
                        onClick={this.toCompanyDetails.bind(this)}
                        // onClick={(comp) => this.toCompanyDetails(comp)}
                      ></ComparativeBusinessAnalysis>
                    </CardBody>
                  </Col>
                </Row>
              </>
            )}

            {this.state.value === 3 && (
              <>
                <Row className="pts_company_snap_mobile_view">
                  <Col
                    md={3}
                    style={{ background: "rgb(23, 32, 42)", padding: "0px" }}
                  >
                    <div
                      style={{
                        position: "fixed",
                      }}
                      className="sidebar"
                      id="sidebar"
                    >
                      <ScrollspyNav
                        scrollTargetIds={[
                          "company_Financial",
                          "Balance_Sheet",
                          "source_capital",
                        ]}
                        offset={0}
                        activeNavClass="is-active"
                        headerBackground="true"
                        scrollDuration="100"
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a
                              href="#company_Financial"
                              className={this.state.className}
                            >
                              Income Statement
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#Balance_Sheet">Balance Sheet</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#source_capital">Sources of Capital</a>
                          </li>
                        </ul>
                      </ScrollspyNav>
                    </div>
                  </Col>
                  <Col md={9} style={{ padding: "0px" }}>
                    <span style={{ float: "left" }} class="drawer_float">
                      <img
                        className="my-float"
                        src="home/assets/img/drawer_menu.png"
                        alt="drawer_menu"
                        onClick={this.toggleDrawer("left", true)}
                      />
                    </span>
                    <Drawer
                      open={this.state.left}
                      onClose={this.toggleDrawer("left", false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer("left", false)}
                        onKeyDown={this.toggleDrawer("left", false)}
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a
                              href="#company_Financial"
                              className={this.state.className}
                            >
                              Income Statement
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#Balance_Sheet">Balance Sheet</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#source_capital">Sources of Capital</a>
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                    <div
                      id="company_Financial"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            <span style={{}}>
                              {/* <span className="header_count">4</span> */}
                              <span className="section_main_header">
                                {" "}
                                {/*<i class="bi bi-table section_icon"></i>*/}
                                {/* Financial Statement Reports */}
                              </span>
                            </span>
                            <span style={{ padding: "20px 0px 0px 10px" }}>
                              {/* <span className="section_header_count">4.1</span> */}
                              <span className="section_header">
                                {" "}
                                Income Statement
                              </span>
                            </span>
                            <CardBody>
                              <Tabs1
                                defaultActiveKey="Income Statement Actual"
                                id="uncontrolled-tab-example"
                                unmountOnExit="false"
                                onSelect={this.handleSelectIncomeStatement}
                              >
                                <Tab1
                                  eventKey="Income Statement Actual"
                                  title=" Actual"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CompanyFinancial
                                        type="Income Statement Actual"
                                        data={this.state.actualData}
                                      ></CompanyFinancial>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Common Size"
                                  title="Common Size"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CompanyFinancial
                                        type="Income Statement Common Size"
                                        data={this.state.cusip}
                                      ></CompanyFinancial>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Year-Year"
                                  title="Year-Year % Change"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CompanyFinancial
                                        type="Income Statement Year-Year"
                                        data={this.state.cusip}
                                      ></CompanyFinancial>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Three Year Average"
                                  title="Three Year Averages"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CompanyFinancial
                                        type="Income Statement Three Year Average"
                                        data={this.state.cusip}
                                      ></CompanyFinancial>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Interim Income Statement Actual"
                                  title=" Interim"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <InterimIncomeStatement
                                        type="Interim Income Statement Actual"
                                        data={this.state.cusip}
                                      ></InterimIncomeStatement>
                                    </Col>
                                  </Row>
                                </Tab1>
                              </Tabs1>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                    <div
                      id="Balance_Sheet"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            <span>
                              {/* <span className="section_header_count">4.2</span> */}
                              <span className="section_header">
                                {" "}
                                Balance Sheet
                              </span>
                            </span>
                            <CardBody>
                              <Tabs1
                                defaultActiveKey="Income Statement Actual"
                                id="uncontrolled-tab-example"
                                unmountOnExit="false"
                              >
                                <Tab1
                                  eventKey="Income Statement Actual"
                                  title=" Actual"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <BalanceSheet
                                        type="Income Statement Actual"
                                        data={this.state.balanceSheetActualData}
                                      ></BalanceSheet>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Common Size"
                                  title="Common Size"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <BalanceSheet
                                        type="Income Statement Common Size"
                                        data={this.state.cusip}
                                      ></BalanceSheet>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Year-Year"
                                  title="Year-Year % Change"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <BalanceSheet
                                        type="Income Statement Year-Year"
                                        data={this.state.cusip}
                                      ></BalanceSheet>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Income Statement Three Year Average"
                                  title="Three Year Averages"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <BalanceSheet
                                        type="Income Statement Three Year Average"
                                        data={this.state.cusip}
                                      ></BalanceSheet>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Interim Balance Sheet Actual"
                                  title="Interim"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <InterimBalanceSheet
                                        type="Interim Balance Sheet Actual"
                                        data={this.state.cusip}
                                      ></InterimBalanceSheet>
                                    </Col>
                                  </Row>
                                </Tab1>
                              </Tabs1>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="source_capital"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <SourceOfCapital
                              data={this.state.sourceOfCapitalData}
                            ></SourceOfCapital>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            {this.state.value === 4 && (
              <>
                <Row className="pts_company_snap_mobile_view">
                  <Col
                    md={3}
                    style={{ background: "rgb(23, 32, 42)", padding: "0px" }}
                  >
                    <div
                      style={{
                        position: "fixed",
                      }}
                      className="sidebar"
                      id="sidebar"
                    >
                      <ScrollspyNav
                        scrollTargetIds={[
                          "accounting_ratios",
                          "assetUtilization_ratios",
                          "employee_efficiency",
                          "fixedCharge_coverage",
                          "leverage_analysis",
                          "liquidity_analysis",
                          "perShare_data",
                          "profitability_analysis",
                        ]}
                        offset={220}
                        activeNavClass="is-active"
                        headerBackground="true"
                        scrollDuration="100"
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a
                              href="#accounting_ratios"
                              className={this.state.className}
                            >
                              Accounting Ratios
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#assetUtilization_ratios">
                              Asset Utilization
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#employee_efficiency">
                              Employee Efficiency
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fixedCharge_coverage">
                              Fixed Charges Coverage
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#leverage_analysis">Leverage Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#liquidity_analysis">Liquidity Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#perShare_data">Per Share Data</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#profitability_analysis">
                              Profitability Analysis
                            </a>
                          </li>
                        </ul>
                      </ScrollspyNav>
                    </div>
                  </Col>
                  <Col md={9} style={{ padding: "0px" }}>
                    <span style={{ float: "left" }} class="drawer_float">
                      <img
                        className="my-float"
                        src="home/assets/img/drawer_menu.png"
                        alt="drawer_menu"
                        onClick={this.toggleDrawer("left", true)}
                      />
                    </span>
                    <Drawer
                      open={this.state.left}
                      onClose={this.toggleDrawer("left", false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer("left", false)}
                        onKeyDown={this.toggleDrawer("left", false)}
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a
                              href="#accounting_ratios"
                              className={this.state.className}
                            >
                              Accounting Ratios
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#assetUtilization_ratios">
                              Asset Utilization
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#employee_efficiency">
                              Employee Efficiency
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fixedCharge_coverage">
                              Fixed Charges Coverage
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#leverage_analysis">Leverage Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#liquidity_analysis">Liquidity Analysis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#perShare_data">Per Share Data</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#profitability_analysis">
                              Profitability Analysis
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                    <div
                      id="accounting_ratios"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            {/* <span
                              style={{
                                borderBottom: "1px solid #abb2b9",
                                padding: "5px",
                              }}
                            >
                              <span className="header_count">5</span>
                              <span className="section_main_header">
                                {" "}
                                Financial Ratios
                              </span>
                            </span> */}
                            <CardBody>
                              <AccountingRatios
                                data={this.state.accountingRatio}
                              ></AccountingRatios>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="assetUtilization_ratios"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <AssetUtilization
                              data={this.state.assetUtilizationRatio}
                            ></AssetUtilization>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="employee_efficiency"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <EmployeeEfficiency
                              data={this.state.employeeEfficiencyRatio}
                            ></EmployeeEfficiency>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="fixedCharge_coverage"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <FixedChargesCoverage
                              data={this.state.fixChargeCoverageRatio}
                            ></FixedChargesCoverage>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="leverage_analysis"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <LeverageAnalysis
                              data={this.state.leverageAnalysisRatio}
                            ></LeverageAnalysis>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="liquidity_analysis"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <LiquidityAnalysis
                              data={this.state.liquidityAnalysisRatio}
                            ></LiquidityAnalysis>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="perShare_data"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <PerShareData
                              data={this.state.perShareProfitRatio}
                            ></PerShareData>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="profitability_analysis"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <ProfitabilityAnalysis
                              data={this.state.profitabilityAnalysisRatio}
                            ></ProfitabilityAnalysis>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {this.state.value === 5 && (
              <>
                <Row className="pts_company_snap_mobile_view">
                  <Col
                    md={3}
                    style={{ background: "rgb(23, 32, 42)", padding: "0px" }}
                  >
                    <div
                      style={{
                        position: "fixed",
                      }}
                      className="sidebar"
                      id="sidebar"
                    >
                      <ScrollspyNav
                        scrollTargetIds={[
                          "fof_su",
                          "fof_cb",
                          "fof_fasb",
                          "fof_ratios",
                        ]}
                        offset={50}
                        activeNavClass="is-active"
                        headerBackground="true"
                        scrollDuration="100"
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a href="#fof_su" className={this.state.className}>
                              Total Sources and Uses
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_cb">Cash Basis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_fasb">FASB 95</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_ratios">Ratios Analysis</a>
                          </li>
                        </ul>
                      </ScrollspyNav>
                    </div>
                  </Col>
                  <Col md={9} style={{ padding: "0px" }}>
                    <span style={{ float: "left" }} class="drawer_float">
                      <img
                        className="my-float"
                        src="home/assets/img/drawer_menu.png"
                        alt="drawer_menu"
                        onClick={this.toggleDrawer("left", true)}
                      />
                    </span>
                    <Drawer
                      open={this.state.left}
                      onClose={this.toggleDrawer("left", false)}
                    >
                      <div
                        tabIndex={0}
                        role="button"
                        onClick={this.toggleDrawer("left", false)}
                        onKeyDown={this.toggleDrawer("left", false)}
                      >
                        <ul className="menu_List" style={{ listStyle: "none" }}>
                          <li className="report_Li_list">
                            <a href="#fof_su" className={this.state.className}>
                              Total Sources and Uses
                            </a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_cb">Cash Basis</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_fasb">FASB 95</a>
                          </li>
                          <li className="report_Li_list">
                            <a href="#fof_ratios">Ratios Analysis</a>
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                    <div
                      id="fof_su"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            {/* <span
                              style={{
                                borderBottom: "1px solid #abb2b9",
                                padding: "5px",
                              }}
                            >
                              <span className="header_count">6</span>
                              <span className="section_main_header">
                                {" "}
                                Flow Of Funds Reports
                              </span>
                            </span> */}

                            <span style={{ padding: "20px 0px 0px 12px" }}>
                              {/* <span className="section_header_count">6.1</span> */}
                              <span className="section_header">
                                {" "}
                                Total Sources and Uses
                              </span>
                            </span>
                            <CardBody>
                              <Tabs1
                                defaultActiveKey="Total Sources and Uses Actual"
                                id="uncontrolled-tab-example"
                                unmountOnExit="false"
                                style={{ marginLeft: "0.75em" }}
                              >
                                <Tab1
                                  eventKey="Total Sources and Uses Actual"
                                  title="Actual"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <FlowOfFunds
                                        type="Total Sources and Uses Actual"
                                        data={this.state.actualSourceAndUses}
                                      ></FlowOfFunds>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Total Sources and Uses Common Size"
                                  title="Common Size"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <FlowOfFunds
                                        type="Total Sources and Uses Common Size"
                                        data={this.state.cusip}
                                      ></FlowOfFunds>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Total Sources and Uses Year-Year"
                                  title="Year-Year % Change"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <FlowOfFunds
                                        type="Total Sources and Uses Year-Year"
                                        data={this.state.cusip}
                                      ></FlowOfFunds>
                                      {/* <BalanceSheet
              type="Income Statement Year-Year"
              data={this.state.cusip}
            ></BalanceSheet> */}
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Total Sources and Uses 3-Year"
                                  title="Three Year Averages"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <FlowOfFunds
                                        type="Total Sources and Uses 3-Year"
                                        data={this.state.cusip}
                                      ></FlowOfFunds>
                                      {/* <BalanceSheet
              type="Income Statement Three Year Average"
              data={this.state.cusip}
            ></BalanceSheet> */}
                                    </Col>
                                  </Row>
                                </Tab1>
                              </Tabs1>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                    <div
                      id="fof_cb"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            <span style={{ padding: "20px 0px 0px 14px" }}>
                              {/* <span className="section_header_count">6.2</span> */}
                              <span className="section_header">
                                {" "}
                                Cash Basis
                              </span>
                            </span>
                            <CardBody>
                              <Tabs1
                                defaultActiveKey="Cash Basis Actual"
                                id="uncontrolled-tab-example"
                                unmountOnExit="false"
                                style={{ marginLeft: "0.75em" }}
                              >
                                <Tab1
                                  eventKey="Cash Basis Actual"
                                  title="Actual"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CashBasis
                                        type="Cash Basis Actual"
                                        data={this.state.actualCashBasis}
                                      ></CashBasis>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Cash Basis Common"
                                  title="Common Size"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CashBasis
                                        type="Cash Basis Common"
                                        data={this.state.cusip}
                                      ></CashBasis>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Cash Basis Year-Year"
                                  title="Year-Year % Change"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CashBasis
                                        type="Cash Basis Year-Year"
                                        data={this.state.cusip}
                                      ></CashBasis>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Cash Basis 3-Year"
                                  title="Three Year Averages"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <CashBasis
                                        type="Cash Basis 3-Year"
                                        data={this.state.cusip}
                                      ></CashBasis>
                                    </Col>
                                  </Row>
                                </Tab1>
                              </Tabs1>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                    <div
                      id="fof_fasb"
                      style={{
                        height: "auto",
                        padding: "15px",
                        background: "rgba(22, 160, 133, 0.1)",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <Card
                            className="section_card"
                            style={{ background: "transparent" }}
                          >
                            <span style={{ padding: "20px 0px 0px 14px" }}>
                              {/* <span className="section_header_count">6.3</span> */}
                              <span className="section_header"> FASB 95</span>
                            </span>
                            <CardBody>
                              <Tabs1
                                defaultActiveKey="Per FASB Actual"
                                id="uncontrolled-tab-example"
                                unmountOnExit="false"
                                style={{ marginLeft: "0.75em" }}
                              >
                                <Tab1 eventKey="Per FASB Actual" title="Actual">
                                  <Row>
                                    <Col md={12}>
                                      <PerFasb
                                        type="Per FASB Actual"
                                        data={this.state.actualFasb95}
                                      ></PerFasb>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Per FASB Common"
                                  title="Common Size"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <PerFasb
                                        type="Per FASB Common"
                                        data={this.state.cusip}
                                      ></PerFasb>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Per FASB Year-Year"
                                  title="Year-Year % Change"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <PerFasb
                                        type="Per FASB Year-Year"
                                        data={this.state.cusip}
                                      ></PerFasb>
                                    </Col>
                                  </Row>
                                </Tab1>
                                <Tab1
                                  eventKey="Per FASB 3-Year"
                                  title="Three Year Averages"
                                >
                                  <Row>
                                    <Col md={12}>
                                      <PerFasb
                                        type="Per FASB 3-Year"
                                        data={this.state.cusip}
                                      ></PerFasb>
                                    </Col>
                                  </Row>
                                </Tab1>
                              </Tabs1>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>

                    <div
                      id="fof_ratios"
                      style={{
                        height: "auto",
                        padding: "15px",
                      }}
                    >
                      <Row>
                        <Col md={12}>
                          <CardBody>
                            <Ratios data={this.state.ratios}></Ratios>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Container>

          <Modal
            isOpen={this.state.showModal1}
            toggle={this.toggle1}
            size="md"
            className="modalContain"
          >
            <ModalHeader>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  ></Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.toggle1}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody style={{ minHeight: "11em" }}>
              <Row>
                <Col md={12}>
                  <h4 style={{ textAlign: "center", color: "#0060a3" }}>
                    To continue to view the full report
                  </h4>
                </Col>
              </Row>
              <Row style={{ margin: "1.5em" }} className="ci_reportSnap_modal">
                <Col md={6} style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      background: "#f58d51",
                      fontSize: "0.8em",
                      float: "right",
                      webkitBorderRadius: "10px",
                      fontSize: "0.8em",
                      margin: "auto",
                      marginRight: "5px",
                      borderBottom: "2px solid #636262",
                      width: "10.1em",
                    }}
                    onClick={this.toBuyReport.bind(this)}
                  >
                    <CreditCardIcon /> Buy Report
                  </Button>
                </Col>
                <Col md={6} style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      background: "#f58d51",
                      fontSize: "0.8em",
                      float: "left",
                      webkitBorderRadius: "10px",

                      margin: "auto",
                      marginRight: "5px",
                      borderBottom: "2px solid #636262",
                    }}
                    onClick={this.toBuyPlan.bind(this)}
                  >
                    <CreditCardIcon /> Subscribe Plans
                  </Button>
                </Col>
              </Row>
              <Row style={{ textAlign: "center", marginTop: "1em" }}>
                <Col md={12}>
                  <h4 className="isMember">
                    If already member?{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "rgb(242, 109, 33)",
                      }}
                      onClick={(e) => this.getModalPopup(e)}
                    >
                      Login
                    </span>
                  </h4>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col md={3}></Col>

                <Col md={3}></Col>
              </Row>
            </ModalBody>
          </Modal>

          <Login
            ref={this.loginRefList}
            data={this.state.selectedPlan}
            onSuccess={this.onUpdate.bind(this)}
            pageId="snapShot"
            history={this.props.history}
            isLogin={true}
            modalShow={this.state.modalShow}
            showPage="Login"
          ></Login>
        </>
      );
    }
  }
}
