import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import $ from "jquery";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-slideshow-image/dist/styles.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import advanceFilterAction from "../../redux/advanceFilter/advanceFilterAction";
import filterSectorAction from "../../redux/filterSector/filterSectorAction";
import store from "../../redux/store";
import userSelectedIndustriesAction from "../../redux/userSelectedIndustries/userSelectedIndustriesAction";
import userSelectedRegionAction from "../../redux/userSelectedRegion/userSelectedRegionAction";
import Advertisement from "../ciadvertisement/Advertisement";
import SearchWithResult from "../search/SearchWithResult";
import "./CorporateInformationHomeTemplate.css";
import NewsSlider from "./NewsSlider";
export default class CorporateInformationHomeTemplate extends Component {
  newsRef = null;
  constructor(props) {
    super(props);
    store.dispatch(advanceFilterAction.setAdvanceFilter({}));
    store.dispatch(userSelectedRegionAction.setRegionFilter([]));
    store.dispatch(filterSectorAction.setSectorFilter([]));
    store.dispatch(userSelectedIndustriesAction.setIndustryFilter([]));
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let previousYear = currentDate.getFullYear() - 1;
    this.state = {
      isOpen: false,
      searchCompany: "",
      currentYear: currentYear,
      previousYear: previousYear,
      searchResult: [
        {
          id: 0,
          name: "Cobol",
        },
        {
          id: 1,
          name: "JavaScript",
        },
        {
          id: 2,
          name: "Basic",
        },
        {
          id: 3,
          name: "PHP",
        },
        {
          id: 4,
          name: "Java",
        },
      ],
      slides: [
        {
          image: "/home/assets/img/slider/CurrencyExchange.png",
          caption: "Currency Exchange Rates",
        },
        {
          image: "/home/assets/img/slider/AdvanceFilter.png",
          caption: "Advanced Screening",
        },
        {
          image: "/home/assets/img/slider/Def And Ext.png",
          caption: " Definitions And Extensions",
        },
        {
          image: "/home/assets/img/slider/Top 100 List.png",
          caption: "Top 100 lists",
        },
      ],
      items: [
        {
          name: "Currency Exchange Rates",
          image: "/home/assets/img/slider/slider1.jpg",
          description: "Probably the most random thing you have ever seen!",
        },
        {
          name: "Advanced Screening",
          image: "/home/assets/img/slider/slider1.jpg",
          description: "Probably the most random thing you have ever seen!",
        },
        {
          name: " Definitions And Extensions",
          image: "/home/assets/img/slider/slider1.jpg",
          description: "Probably the most random thing you have ever seen!",
        },
        {
          name: "Top 100 lists",
          image: "/home/assets/img/slider/slider1.jpg",
          description: "Probably the most random thing you have ever seen!",
        },
      ],
      newsList: [],
    };
    window.scrollTo(0, 0);
  }
  toBuyPlan() {
    this.setState({ showModal: false });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  getNewsData() {
    const postObject = {};
    FetchServerData.callPostService("news/get", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          this.setState({
            newsList: data,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  searchCompany = (e) => {
    this.props.history.push({
      pathname: "/search",
      state: {
        searchCompany: this.state.searchCompany,
        searchType: "simple search",
      },
    });
  };
  toAdvanceSearch = (event, value) => {
    this.props.history.push({
      pathname: "/search",
      state: { searchType: "advance search" },
    });
  };

  toMobileAppDownload = (e) => {
    this.props.history.push({
      pathname: "/download_mobile_app",
    });
  };
  handleCompanyChange(e) {
    this.setState({ searchCompany: e.target.value });
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.searchCompany();
    }
  };
  performAdvanceSearch() {
    this.props.history.push({
      pathname: "/search",
      state: {
        searchText: this.state.searchText,
        searchType: "advance search",
      },
    });
  }
  toCurrencyExchange() {
    this.props.history.push({
      pathname: "currency_exchange",
    });
  }

  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    if (
      rowData.country.trim().toUpperCase() === "KOREA (SOUTH)" ||
      rowData.countryCode === "410"
    ) {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt
              src={`https://www.countryflags.io/KR/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VENEZUELA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/VE/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "TANZANIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/TZ/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "PALESTINE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/PS/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "MACEDONIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/MK/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "BOLIVIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/BO/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "UNITED KINGDOM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/GB/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "COTE D'IVOIRE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/CI/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VIETNAM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/VN/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/${rowData.codeCountry}/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    }
    return getCountryWithFlag;
  }
  componentDidMount() {
    // this.getNewsData();
    // var testim = document.getElementById("testim"),
    //   testimDots = Array.prototype.slice.call(
    //     document.getElementById("testim-dots").children
    //   ),
    //   testimContent = Array.prototype.slice.call(
    //     document.getElementById("testim-content").children
    //   ),
    //   testimLeftArrow = document.getElementById("left-arrow"),
    //   testimRightArrow = document.getElementById("right-arrow"),
    //   testimSpeed = 4500,
    //   currentSlide = 0,
    //   currentActive = 0,
    //   testimTimer,
    //   touchStartPos,
    //   touchEndPos,
    //   touchPosDiff,
    //   ignoreTouch = 30;
    // window.onload = function () {
    //   // Testim Script
    //   function playSlide(slide) {
    //     for (var k = 0; k < testimDots.length; k++) {
    //       testimContent[k].classList.remove("active");
    //       testimContent[k].classList.remove("inactive");
    //       testimDots[k].classList.remove("active");
    //     }

    //     if (slide < 0) {
    //       slide = currentSlide = testimContent.length - 1;
    //     }

    //     if (slide > testimContent.length - 1) {
    //       slide = currentSlide = 0;
    //     }

    //     if (currentActive !== currentSlide) {
    //       testimContent[currentActive].classList.add("inactive");
    //     }
    //     testimContent[slide].classList.add("active");
    //     testimDots[slide].classList.add("active");

    //     currentActive = currentSlide;

    //     clearTimeout(testimTimer);
    //     testimTimer = setTimeout(function () {
    //       playSlide((currentSlide += 1));
    //     }, testimSpeed);
    //   }

    //   testimLeftArrow.addEventListener("click", function () {
    //     playSlide((currentSlide -= 1));
    //   });

    //   testimRightArrow.addEventListener("click", function () {
    //     playSlide((currentSlide += 1));
    //   });

    //   for (var l = 0; l < testimDots.length; l++) {
    //     testimDots[l].addEventListener("click", function () {
    //       playSlide((currentSlide = testimDots.indexOf(this)));
    //     });
    //   }

    //   playSlide(currentSlide);

    //   // keyboard shortcuts
    //   document.addEventListener("keyup", function (e) {
    //     switch (e.keyCode) {
    //       case 37:
    //         testimLeftArrow.click();
    //         break;

    //       case 39:
    //         testimRightArrow.click();
    //         break;

    //       // case 39:
    //       //   testimRightArrow.click();
    //       //   break;

    //       default:
    //         break;
    //     }
    //   });

    //   testim.addEventListener("touchstart", function (e) {
    //     touchStartPos = e.changedTouches[0].clientX;
    //   });

    //   testim.addEventListener("touchend", function (e) {
    //     touchEndPos = e.changedTouches[0].clientX;

    //     touchPosDiff = touchStartPos - touchEndPos;

    //     if (touchPosDiff > 0 + ignoreTouch) {
    //       testimLeftArrow.click();
    //     } else if (touchPosDiff < 0 - ignoreTouch) {
    //       testimRightArrow.click();
    //     } else {
    //       return;
    //     }
    //   });
    // };
    $(document).on("click", ".naccs .menu div", function () {
      var numberIndex = $(this).index();

      if (!$(this).is("active")) {
        $(".naccs .menu div").removeClass("active");
        $(".naccs ul li").removeClass("active");

        $(this).addClass("active");
        $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .addClass("active");

        var listItemHeight = $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .innerHeight();
        $(".naccs ul").height(listItemHeight + "px");
      }
    });
  }

  toSelectedPage(menu, click) {
    if (menu === "Top 100 lists") {
      this.props.history.push({
        pathname: "tophundred",
      });
    } else if (menu === "Currency Exchange Icons") {
      this.props.history.push({
        pathname: "currency",
      });
    } else if (menu === "Comparative Business Analysis") {
      this.props.history.push({
        pathname: "cbasample",
      });
    } else if (menu === "Company Reports") {
      this.props.history.push({
        pathname: "companyreport",
      });
    } else if (menu === "Industry Averages Reports") {
      this.props.history.push({
        pathname: "industryreport",
      });
    } else if (menu === "Wright Quality Analysis") {
      this.props.history.push({
        pathname: "Wrightqualityanalysis",
      });
    } else if (menu === "Definitions Of Financial Terms") {
      this.props.history.push({
        pathname: "financialterms",
      });
    } else {
      let userData = store.getState().userData.userData;
      var lengthObj = Object.keys(userData).length;

      if (lengthObj > 0) {
        if (userData === undefined) {
          this.setState({ showModal: true });
        } else if (userData.activeUser === false) {
          this.setState({ showModal: true });
        } else {
          this.props.history.push({
            pathname: "advancescreening",
          });
        }
      } else {
        this.setState({ showModal: true });
      }
    }
  }
  Item(item) {
    return (
      <div
        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay={200}
        style={{ cursor: "pointer" }}
        onClick={this.toSelectedPage.bind(this, "Top 100 Lists")}
      >
        <div className="icon-box discover">
          <div className="icon">
            <img
              src="/home/assets/img/top_100_list.png"
              alt="report_cba"
              style={{ height: "100px" }}
            ></img>
            {/* <i className="ri-stack-line" /> */}
          </div>
          <h4 className="title">
            <a href className="discover_menu">
              {item.name}
            </a>
          </h4>
          <p className="description">{item.description}</p>
        </div>
      </div>
    );
  }

  handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    this.userEnterSearch(string);
  };
  userEnterSearch = (userEnterText) => {
    const postObject = {
      searchText: userEnterText,
    };
    FetchServerData.callPostService("searchService/search", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let searchResult = [];
          searchResult = output.data.result;
          this.setState({
            searchResult: searchResult,
            render: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  handleOnHover = (result) => {
    // the item hovered
  };

  handleOnSelect = (item) => {
    // the item selected
  };

  handleOnFocus = () => {};

  formatResult = (item) => {
    // return item;
    return (
      <p dangerouslySetInnerHTML={{ __html: "<h5>" + item + "</h5>" }}></p>
    ); //To format result as html
  };

  intelsenseText(searchResult) {
    return (
      <ReactSearchAutocomplete
        items={searchResult}
        onSearch={this.handleOnSearch}
        onHover={this.handleOnHover}
        onSelect={this.handleOnSelect}
        onFocus={this.handleOnFocus}
        autoFocus
        formatResult={this.formatResult}
      />
    );
  }
  displayNews(newsList) {
    return <NewsSlider type="slider1" newsData={newsList} />;
  }

  getMainMenuBox(
    ptsBackgroundColor,
    titleColor,
    title,
    detailColor,
    details,
    iconUrl,
    pageToNavigate
  ) {
    return (
      <div className="service-block-one">
        <div
          className="inner-box"
          style={{ background: ptsBackgroundColor }}
          onClick={this.toSelectedPage.bind(this, pageToNavigate)}
        >
          <div className="icon-box1">
            <img
              src={iconUrl}
              alt={pageToNavigate}
              style={{ height: "60px", width: "60px" }}
            ></img>
          </div>
          <h3 style={{ color: titleColor + " !important" }}>
            <a href style={{ color: titleColor }}>
              {title}
            </a>
          </h3>
          <div style={{ height: "5.2em", color: detailColor + " !important" }}>
            <p
              style={{
                fontWeight: "normal",
                color: detailColor,
              }}
            >
              {details}
            </p>
          </div>
        </div>
      </div>
    );
  }
  render() {
    this.newsRef = React.createRef();
    let newsList = [];
    this.state.newsList.forEach((requestData, index) => {
      newsList.push(<div>{requestData.name}</div>);
    });

    return (
      <>
        <div>
          <section id="hero" className="hero_temp5 d-flex align-items-center">
            <div
              className="container position-relative aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={100}
            >
              <Row>
                <Col md={2}>
                  <Advertisement advertisementType="verticalAddUnit" />
                </Col>
                <Col md={10}>
                  <Row style={{ marginLeft: "-0.3em" }}>
                    <Col md={12}>
                      <p style={{ textAlign: "left" }}>
                        Delivering the <b></b>
                        <span style={{ color: "#d35400" }}>
                          <b>Financial</b>
                        </span>{" "}
                        World to you
                      </p>
                    </Col>

                    <div className="tag_line_home">
                      <div className="row">
                        <span
                          style={{
                            color: "#4154f1",
                            fontWeight: "bold",
                            fontSize: "1.6em",
                          }}
                        >
                          Up-to-Date Financial Information on Over 46,000
                          companies worldwide
                        </span>
                      </div>
                    </div>
                    <br></br>
                  </Row>
                  <Row
                    style={{
                      marginLeft: "-0.2em",
                      marginTop: "0.5em",
                      marginBottom: "0.5em",
                    }}
                  >
                    <Col md={6}>
                      <SearchWithResult
                        history={this.props.history}
                        color="rgb(242, 109, 33)"
                        pageId="/home"
                      ></SearchWithResult>
                    </Col>
                    <Col md={6} style={{ float: "left" }}>
                      <Row className="app_src_btn">
                        {/* <Col md={1}></Col>
                        <Col md={4} style={{}}> */}
                        <a
                          href="https://apps.apple.com/us/app/corporate-information/id1582306551"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            alt="appleStore"
                            src="/home/assets/img/appleStore.png"
                            style={{ cursor: "pointer", height: "41px" }}
                          />
                        </a>
                        {/* </Col>
                        <Col md={2}></Col>
                        <Col md={4} style={{}}> */}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.wici_mobile"
                          target="_blank"
                        >
                          <img
                            src="/home/assets/img/googlePlayStore.png"
                            style={{
                              marginTop: "-0.2em",
                              cursor: "pointer",
                              height: "48px",
                            }}
                          />
                        </a>
                        {/* </Col>
                        <Col md={1}></Col> */}
                      </Row>
                      {/* <div>
                        <a
                          onClick={this.toMobileAppDownload.bind(this)}
                          style={{
                            background: "rgba(135, 160, 190, 1)",
                            color: "#ffffff",
                            padding: "6px",
                            cursor: "pointer",
                          }}
                        >
                          Download our App InvestWISeR
                        </a>
                      </div> */}
                    </Col>
                  </Row>
                  <div>
                    <div className="container" style={{ marginTop: "-1.5em" }}>
                      <Row style={{ marginLeft: "-0.8em", marginTop: "0.5em" }}>
                        <Col md={6} style={{}} className="home_news_feeds">
                          <Card
                            style={{
                              boxShadow:
                                "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                            }}
                          >
                            <CardHeader className="market_commentary_header">
                              Market Commentary
                            </CardHeader>
                            <CardBody>
                              <NewsSlider type="slider1" newsData={newsList} />
                            </CardBody>
                          </Card>
                          {/* <NewsSlider newsData={this.state.newsList} /> */}
                        </Col>
                        <Col md={6} className="home_news_feeds">
                          {/* <div className="srch">
                    <div className="searchTicker">
                      <div> */}
                          <div>
                            <Card
                              style={{
                                boxShadow:
                                  "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
                              }}
                            >
                              <CardHeader className="market_commentary_header">
                                Market Commentary
                              </CardHeader>
                              <CardBody>
                                <NewsSlider
                                  type="slider2"
                                  newsData={newsList}
                                />
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <br></br>

                  <div>
                    <div className="container" style={{ marginTop: "-2em" }}>
                      <div className="row">
                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#ff5757",
                            "#fff",
                            "Currency Exchange Rates",
                            "#fff",
                            "Currency Exchange rates between all major global currencies",
                            "/home/assets/img/exchange.png",
                            "Currency Exchange Icons"
                          )}
                        </div>
                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#38b6ff",
                            "#000",
                            "Top 100 List",
                            "#000",
                            "Top 100 Companies ranked by Current Market Cap, Net Sales, Operating Margin, Price change, Sales Growth",
                            "/home/assets/img/top_100_2.png",
                            "Top 100 lists"
                          )}
                        </div>

                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#8c52ff",
                            "#fff",
                            "Advanced Screening",
                            "#fff",
                            "Presents robust filtering and ranking of companies",
                            "/home/assets/img/filtering.png",
                            "Advanced Screening"
                          )}
                        </div>

                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#c9e265",
                            "#000",
                            "Comparative Business Analysis",
                            "#000",
                            "A Special Report created by analyzing performance of the company",
                            "/home/assets/img/cba_logo1.png",
                            "Comparative Business Analysis"
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#E67E22",
                            "#fff",
                            "Company Reports",
                            "#fff",
                            "Company research reports on over 46,000 companies from 85 countries",
                            "/home/assets/img/blueBook.png",
                            "Company Reports"
                          )}
                        </div>

                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#ffde59",
                            "#000",
                            "Industry Averages Reports",
                            "#000",
                            "Prepared on 22 industries with a total of 869 permutations (Global, Region and Country)",
                            "/home/assets/img/Industry_image.png",
                            "Industry Averages Reports"
                          )}
                        </div>

                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#0b8c5b",
                            "#fff",
                            "Wright Quality Analysis",
                            "#fff",
                            "The Wright Quality Ratings, measure the overall investment quality of a company",
                            "/home/assets/img/research1.png",
                            "Wright Quality Analysis"
                          )}
                        </div>

                        <div className="col-md-3 service-block">
                          {this.getMainMenuBox(
                            "#e3e5e8",
                            "#000",
                            "Definitions Of Financial Terms",
                            "#000",
                            "Be familiar with Financial Terms",
                            "/home/assets/img/terms.png",
                            "Definitions Of Financial Terms"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          <>
            <main id="main">
              <section className="portfolio_section">
                <Advertisement advertisementType="horizontalAddUnit" />
              </section>
              <section
                className="portfolio_section"
                style={{ background: "black" }}
              >
                {/* <Advertisement advertisementType="horizontalAddUnit" /> */}
                <div
                  className="container"
                  // data-aos="zoom-in"
                  data-aos-delay="{1500}"
                >
                  <h2>
                    <strong>Visual,</strong> <strong>Graphical</strong> and{" "}
                    <strong>Convenient </strong> Financial Reports <br></br>{" "}
                    over <strong> 46,000 Companies </strong>
                  </h2>

                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-up"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Company Snapshot
                          </h4>
                          <img
                            alt="reportApple"
                            style={{ padding: "5px" }}
                            src="/home/assets/img/reportApple.PNG"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-left"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Wright Quality Analysis
                          </h4>
                          <img
                            style={{ padding: "5px" }}
                            alt="reportWQA"
                            src="/home/assets/img/reportWQA.PNG"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-up"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Comparative Business Analysis
                          </h4>
                          <img
                            style={{ padding: "5px" }}
                            src="/home/assets/img/cbaSnap.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-left"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Earnings &amp; Dividends
                          </h4>
                          <img
                            style={{ padding: "5px" }}
                            alt="edSnap"
                            src="/home/assets/img/edSnap.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-left"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Flow of Funds
                          </h4>
                          <img
                            style={{ padding: "5px" }}
                            alt="fofSnap"
                            src="/home/assets/img/fofSnap.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-ms-12 col-xs-12 "
                      data-aos="fade-down-right"
                    >
                      <div
                        className="card text-white bg-success mb-4"
                        data-aos="fade-left"
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center">
                            Top 100 Companies
                          </h4>
                          <img
                            alt="top100Snap"
                            src="/home/assets/img/top100Snap.png"
                            style={{ height: "19.7em", padding: "5px" }}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <section id="graphics" className="py-5"> */}
                {/* <div className="container graphics py-4">

                  </div> */}
                {/* </section> */}
              </section>

              <section id="values" className="values">
                <div className="container" data-aos="fade-up">
                  <header className="section-header">
                    <p style={{ color: "black" }}>Our Offering</p>
                  </header>
                  <div className="row">
                    <div className="col-lg-3">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={200}
                        style={{
                          background: "#2db6fa",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="/home/assets/img/u1.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3> Global Research and Analysis</h3>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={400}
                        style={{
                          background: "#ff9933",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="/home/assets/img/u2.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3>In-Depth Financial Reports on Companies</h3>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={600}
                        style={{
                          background: "#b50edf",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="/home/assets/img/u3.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3>
                          Comparative Business Analysis on over 46,000 Companies
                          Worldwide
                        </h3>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={800}
                        style={{
                          background: "#08da4e",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="/home/assets/img/u4.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3> Over 800 Reports on Industries Worldwide</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section
                id="about"
                className="about"
                style={{ background: "darkslategrey", marginTop: "-0.25em" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="row gx-0">
                    <div
                      className="col-lg-12 d-flex flex-column justify-content-center"
                      data-aos="fade-up"
                      data-aos-delay={800}
                    >
                      <div
                        //className="content"
                        style={{
                          margin: "10px",
                          padding: "10px",
                          // background: "white",
                          borderRadius: "10px",
                          marginRight: "0px",
                        }}
                      >
                        <h3 style={{ color: "#ffff" }}>About US</h3>
                        <p>
                          The company research reports contained in CorporateInformation.com 
                          are produced on over 46,000 companies from 85 countries. 
                          Wright Investors’ Service has researched and analyzed company 
                          information since the inception of the firm in 1960. 
                        </p>
                        <p>
                          In the mid 1980’s, in support of its international investment 
                          initiatives, Wright Investors’ developed the Worldscope® Database, 
                          one of the financial industry’s premier sources of detailed financial 
                          statement data for public companies. This Database contained extensive 
                          data obtained from primary source documents, including corporate annual and interim reports.
                        </p>
                        <p>
                          Wright’s analysis tools, developed over several decades and using 
                          extensive corporate fundamental data, include the proprietary 
                          Wright Quality Rating® that is applied to each company contained 
                          in CorporateInformation. The CorporateInformation.com service is 
                          one of the most comprehensive sources for financial data on publicly 
                          traded companies worldwide.
                        </p>

                        <p>
                          To learn more about Wright Investors’ Service visit us
                          at &nbsp;
                          <a
                            href="https://www.wrightinvestorsservice.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>www.wrightinvestorsservice.com.</span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ======= Services ======= */}
              <section id="services" className="services">
                <div className="container" data-aos="fade-up">
                  <header className="section-header">
                    <p style={{ color: "black" }}>Who We Are</p>
                  </header>
                  <div>
                    <p
                      style={{
                        lineHeight: "normal",
                        fontWeight: 300,
                        color: "black",
                      }}
                    >
                      <a
                        href="https://www.wrightinvestorsservice.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#0060a3",
                          margin: "10px",
                          borderRadius: "20px",
                        }}
                      >
                        Wright Investors' Service
                      </a>
                      &nbsp;is an internationally recognized investment
                      management and financial advisory firm headquartered in
                      Shelton, Connecticut, USA. For more than 50 years, Wright
                      has used well-defined and sophisticated investment
                      strategies to help institutions, plan sponsors, bank trust
                      departments, trust companies and individual investors
                      achieve their financial objectives through a sensible
                      approach to managing assets that balances risk and return.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                  <Row
                    style={{
                      background: "#2db6fa",
                      margin: "10px",
                      borderRadius: "20px",
                    }}
                    data-aos="fade-up"
                    data-aos-delay={200}
                  >
                    <Col md={3}>
                      <img
                        alt="funds"
                        src="/home/assets/img/funds.png"
                        style={{
                          height: "180px",
                          width: "180px",
                          paddingBottom: "1em",
                        }}
                      />
                    </Col>
                    <Col md={9}>
                      <h5
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        Our Process: The Difference is Discipline
                      </h5>

                      <p style={{ fontSize: "0.8em", textAlign: "left" }}>
                        At the center of Wright's investment process is the
                        Wright Investment Committee. It consists of a select
                        group of senior investment professionals who are
                        supported by a highly experienced staff. This staff
                        provides multilevel analyses of the economic and
                        investment environments, including actual and projected
                        corporate earnings, interest rates, and the effect of
                        economic forecasts on market sectors, individual
                        securities and client portfolios. Only those companies
                        that meet the demanding standards of Wright Quality
                        Ratings® are selected for The Approved Wright Investment
                        List.
                      </p>
                    </Col>
                  </Row>
                  <Row
                    data-aos="fade-up"
                    data-aos-delay={300}
                    style={{
                      background: "#f68c09",
                      margin: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <Col md={9}>
                      <h5
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        A Global Research and Analysis Capability
                      </h5>
                      <p style={{ fontSize: "0.8em", textAlign: "left" }}>
                        To meet its equity research needs, Wright uses
                        Worldscope® which is recognized by the global investment
                        community as one of the world's most comprehensive and
                        accurate corporate financial databases. Wright developed
                        Worldscope® more than 30 years ago and continues to
                        provide the database with the editorial oversight that
                        only a seasoned investment firm with a global
                        perspective can deliver. The research reports delivered
                        through CorporateInformation.com rely heavily on the
                        data contained in the Worldscope Database. It is not
                        only Wright's clients, but also the financial community
                        in general that benefits from Wright's research,
                        investment advisory and analytical services. For over
                        five decades, Wright Investors' Service has managed an
                        in-house research capability that provides institutional
                        and private investors with the analyses they need.
                      </p>
                    </Col>
                    <Col md={3}>
                      <img
                        src="/home/assets/img/qualitative-research.png"
                        alt="qualitative-research"
                        style={{
                          height: "180px",
                          width: "180px",
                          paddingBottom: "1em",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row
                    data-aos="fade-up"
                    data-aos-delay={400}
                    style={{
                      background: "#08da4e",
                      margin: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <Col md={3}>
                      <img
                        alt="report"
                        src="/home/assets/img/report.png"
                        style={{
                          height: "180px",
                          width: "180px",
                          padding: "10px",
                        }}
                      />
                    </Col>
                    <Col md={9}>
                      <h5
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        High Level Service and Reporting
                      </h5>
                      <p
                        style={{
                          fontSize: "0.8em",

                          textAlign: "left",
                        }}
                      >
                        Wright is committed to a disciplined approach to
                        investing that is backed by a high level of client
                        service and reporting. Each client is assigned one or
                        more Client Service Officers whose mission is to act as
                        the client's representative within Wright. Client
                        Service Officers are seasoned professionals with
                        extensive experience handling the investment and service
                        needs of the clients to whom they are assigned. In
                        addition, Wright's sophisticated reporting system
                        delivers customized reports to meet specific client
                        needs.
                      </p>
                    </Col>
                  </Row>
                </div>
              </section>
              {/* TESTIMONIALS */}

              {/* <div className="container"> */}

              {/* <section
                id="testim"
                className="testim"
                style={{ background: "cadetblue" }}
              >
                <header className="section-header">
                  <p style={{ color: "black" }}>Testimonials</p>
                </header>
                <div className="testim-cover">
                  <div className="wrap">
                    <span
                      id="right-arrow"
                      className="arrow right fa fa-chevron-right"
                    />
                    <span
                      id="left-arrow"
                      className="arrow left fa fa-chevron-left "
                    />
                    <ul id="testim-dots" className="dots">
                      <li className="dot active" />

                      <li className="dot" />

                      <li className="dot" />

                      <li className="dot" />

                      <li className="dot" />
                    </ul>
                    <div id="testim-content" className="cont">
                      <div className="active">
                        <div className="img">
                          <img src="/home/assets/img/testimonial5.jpg" alt="" />
                        </div>
                        <h2>Jacob</h2>
                        <p style={{ color: "#fff" }}>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial6.jpg" alt="" />
                        </div>
                        <h2>Stephen</h2>
                        <p style={{ color: "#fff" }}>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial7.jpg" alt="" />
                        </div>
                        <h2>Kethi</h2>
                        <p style={{ color: "#fff" }}>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial3.png" alt="" />
                        </div>
                        <h2>Sophia</h2>
                        <p style={{ color: "#fff" }}>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial4.png" alt="" />
                        </div>
                        <h2>Scarlett</h2>
                        <p style={{ color: "#fff" }}>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
              {/* </div> */}
            </main>
            <footer
              id="footer"
              className="footer"
              // style={{ background: "cadetblue" }}
            >
              <div
                className="footer-top footerImage footerFont"
                style={{ borderTop: "1px solid transparent" }}
              >
                {/* <div className="container"> */}
                <Container maxWidth="lg">
                  <div className="row gy-4">
                    <div className="col-lg-4 col-md-12 footer-info">
                      {/* <a
                      href="index.html"
                      className="logo d-flex align-items-center"
                    >
                      <img src="assets/img/logo5.png" alt="" />
                    </a> */}
                      <a href="/" className="logo d-flex align-items-center">
                        <h4 className="footerh4">
                          <span>Corporate</span>Information
                          <span>
                            <sup>®</sup>
                          </span>
                        </h4>
                      </a>
                      <p>
                        We are one of the world’s leading sites for value-added
                        corporate and related industry information appropriate
                        for investing, competitive analysis and research
                        information on over 46,000 of the leading public
                        companies in over 86 countries.
                      </p>
                      <p>
                        Wright Investors’ Service has researched and analyzed
                        company and industry information since the inception of
                        the firm in 1960. Wright's proprietary analysis tools,
                        developed over several decades, include a Quality Rating
                        system that is applied (when there is sufficient
                        information available) to each company contained in
                        CorporateInformation.
                      </p>
                      <div className="social-links mt-3">
                        <a
                          href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fwww.corporateinformation.com"
                          target="_blank"
                          rel="noreferrer"
                          className="twitter"
                        >
                          <TwitterIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                        <a
                          href="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dwww.corporateinformation.com&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB"
                          target="_blank"
                          rel="noreferrer"
                          className="facebook"
                        >
                          <FacebookIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                        {/* <a
                          href="https://plus.google.com/up/?continue=https://plus.google.com/share?url%3Dwww.corporateinformation.com"
                          target="_blank"
                          className="instagram"
                        >
                          <img
                            src="/home/assets/img/google_link.png"
                            style={{ height: "1.2em", cursor: "pointer" }}
                          ></img>
                        </a> */}
                        <a
                          href="https://www.linkedin.com/sharing/share-offsite/?url=http%3A%2F%2Fwww.corporateinformation.com"
                          target="_blank"
                          rel="noreferrer"
                          className="linkedin"
                        >
                          <LinkedInIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12 ptsfooter-links">
                      <h4>Useful Links</h4>
                      <ul className="useful_links">
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/feedback"
                            className="nav-link scrollto active"
                            style={{ color: "#fff" }}
                          >
                            Feedback
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/tandc"
                            className="nav-link scrollto active"
                            style={{ color: "#fff" }}
                          >
                            Terms and Conditions
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/privacypolicy"
                            className="nav-link scrollto active"
                            style={{ color: "#fff" }}
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />
                          <NavLink
                            to="/financialterms"
                            className="nav-link scrollto active"
                            style={{ color: "#fff" }}
                          >
                            Definitions of Financial Terms
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />
                          <NavLink
                            to="/sitemap"
                            className="nav-link scrollto active"
                            style={{ color: "#fff" }}
                          >
                            Site Map
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start">
                      <h3
                        style={{
                          color: "#fff !important",
                          textTransform: "uppercase",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Contact Us
                      </h3>
                      <p>
                        CorporateInformation.com Customer Support
                        <br />
                        Wright Investors' Service Inc
                        <br />
                        2 Corporate Drive Suite 770
                        <br />
                        Shelton, CT 06484
                        <br />
                        USA
                        {/* <br />
                        <strong>Telephone:</strong> +1-203-783-4343
                        <br />
                        <strong>Fax:</strong> +1-203-783-4401 */}
                        <br />
                      </p>
                    </div>
                  </div>
                  {/* </div> */}
                </Container>
              </div>
              {/* <div className="container"> */}
              <div className="fooCopyR">
                <div className="copyright">
                  {/* &copy; Copyright <strong><span>FlexStart</span></strong
          >. */}
                  <div className="copyrightContent">
                    CorporateInformation® website and selected data Copyright ©
                    2000-{this.state.currentYear} by The Winthrop Corporation. .
                  </div>
                  <div className="copyrightContent">
                    {" "}
                    Distributed by Wright Investors' Service, Inc. All Rights
                    Reserved. Selected data ©{this.state.currentYear}{" "}
                    "Worldscope/Disclosure, L.L.C., a Primark affiliate". All
                    Rights Reserved.
                  </div>
                </div>
                <span style={{ fontSize: "0.8em" }}>
                  THE ANALYSES AND DATA ARE PROVIDED "AS IS" WITHOUT WARRANTY OF
                  ANY KIND EXPRESS OR IMPLIED. THE WINTHROP CORPORATION, WRIGHT
                  INVESTORS' SERVICE, INC. AND OTHER DATA PROVIDERS SPECIFICALLY
                  EXCLUDE THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE AND DO NOT WARRANT, GUARANTEE OR MAKE ANY
                  REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF THE USE,
                  OF THE DATA IN TERMS OF ITS ACCURACY, RELIABILITY OR
                  CURRENTNESS. THE WINTHROP CORPORATION, WRIGHT INVESTORS'
                  SERVICE, INC. AND OTHER DATA PROVIDERS SHALL NOT BE LIABLE FOR
                  ANY DIRECT, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES
                  (INCLUDING LOST PROFITS) ARISING OUT OF THE SUBSCRIBER'S USE
                  OF OR INABILITY TO USE THE ANALYSES AND DATA. THE USER ASSUMES
                  THE ENTIRE RISK AS TO THE RESULTS AND PERFORMANCE OF THE
                  ANALYSES AND DATA, WHICH ARE PROVIDED FOR INTERNAL USE ONLY.
                  NO REDISTRIBUTION OF THE ANALYSES AND DATA OR ANY PART THEREOF
                  IS PERMITTED. WRIGHT QUALITY RATINGS ARE NOT MEANT TO
                  CONSTITUTE INVESTMENT ADVICE OR AN ENDORSEMENT OF ANY ISSUER.
                </span>
                <div className="credits">
                  {/* All the links in the footer should remain intact. */}
                  {/* You can delete the links only if you purchased the pro version. */}
                  {/* Licensing information: https://bootstrapmade.com/license/ */}
                  {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ */}
                  {/* Designed by */}
                </div>
              </div>
              {/* </div> */}
            </footer>
            <a
              href="#"
              className="back-to-top d-flex align-items-center justify-content-center"
            >
              <i className="bi bi-arrow-up-short"></i>
            </a>
          </>
        </div>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          size="md"
          className="modalContain"
        >
          <ModalHeader>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    float: "left",
                  }}
                ></Label>
                <br></br>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody style={{ minHeight: "12em" }}>
            <Row>
              <Col md={12}>
                <h4 style={{ textAlign: "center", color: "#0060a3" }}>
                  To View Advanced Screening please subscribe
                </h4>
              </Col>
            </Row>

            <br></br>
            <Row>
              <Col md={2}></Col>

              <Col md={6} style={{ textAlign: "center" }}>
                <NavLink to="/plans" onClick={this.toBuyPlan.bind(this)}>
                  <Button
                    style={{
                      background: "#f58d51",
                      fontSize: "0.8em",
                      float: "right",
                      webkitBorderRadius: "10px",

                      float: "right",
                      margin: "auto",
                      marginRight: "5px",
                      borderBottom: "2px solid #636262",
                    }}
                  >
                    <CreditCardIcon style={{ fontSize: "1.5em" }} /> Subscribe
                    Plans
                  </Button>
                </NavLink>
              </Col>
              <Col md={3}></Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
