import Container from '@material-ui/core/Container';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import moment from 'moment';
import React, { Component } from 'react';
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import 'react-tabs/style/react-tabs.css';
import { Button, Card, CardBody, CardHeader, Col, Row ,Label,
  Modal,
  ModalBody,
  ModalHeader,} from 'reactstrap';
import cartAction from '../../redux/cart/cartAction';
import store from '../../redux/store';
import Advertisement from '../ciadvertisement/Advertisement';
import PtsAlert from '../ptsAlert/PtsAlert';
import GenericSearch from '../search/GenericSearch';
import './ReportDetails.css';
import VisibilityIcon from "@material-ui/icons/Visibility";
export default class ReportDetails extends Component {
  cartData = store.getState().cartData.cart;
  price = store.getState().price.price;
  userData = store.getState().userData.userData;
  constructor(props) {
    super(props);
    let reportDetails = '';

    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      reportDetails = props.history.location.state.data;
    }
    this.state = {
      reportDetails: reportDetails,
      price: this.price[1].price,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  viewPdfReport(selectedCompanyReport) {
    this.setState({
      pdfUrlRpt: selectedCompanyReport.pdf,
      showModal: true,
      sector: selectedCompanyReport.sector,
      regionCountry: selectedCompanyReport.regionCountry,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  getButton(report) {
    if (this.userData) {
      if (this.userData.activeUser === false) {
        return (
          <Button
            style={{
              background: "#f58d51",

           
              webkitBorderRadius: "10px",
              fontSize: "0.8em",
           
              margin: "auto",
              marginRight: "5px",
              borderBottom: "2px solid #636262",
            }}
            onClick={(e) => this.addToCart(this.state.reportDetails)}
          >
            <ShoppingCartIcon style={{ fontSize: "1.3em" }} />
            Add to cart
          </Button>
        );
      } else {
        return (
          <Button
            style={{
              background: "#f58d51",

             
              webkitBorderRadius: "10px",
              fontSize: "0.8em",
            
              margin: "auto",
              marginRight: "5px",
              borderBottom: "2px solid #636262",
            }}
            onClick={(e) => this.viewPdfReport(this.state.reportDetails)}
          >
            <VisibilityIcon style={{ fontSize: "1.3em" }} /> View Report
          </Button>
        );
      }
    } else {
      return (
        <Button
          style={{
            background: "#f58d51",

          
            webkitBorderRadius: "10px",
            fontSize: "0.8em",
          
            margin: "auto",
            marginRight: "5px",
            borderBottom: "2px solid #636262",
          }}
          onClick={(e) => this.addToCart(this.state.reportDetails)}
        >
          <ShoppingCartIcon style={{ fontSize: "1.3em" }} />
          Add to cart
        </Button>
      );
    }
  }
  addToCart(selectedCompanyReport) {
    let selectedCompanyReportObj = {
      price: this.price[1].price,
      ticker: selectedCompanyReport.regionCountry,
      id: selectedCompanyReport.id,
      regionCountry: selectedCompanyReport.regionCountry,
      publishDate: selectedCompanyReport.publishDate,
      pdf: selectedCompanyReport.pdf,
      sector: selectedCompanyReport.sector,
      isRegion: selectedCompanyReport.isRegion,
    
      planId: '4',
      companyName:
        selectedCompanyReport.sector +
        '-' +
        selectedCompanyReport.regionCountry,
    };
    let reports = this.cartData;
    reports.push(selectedCompanyReportObj);
    store.dispatch(cartAction.setCart(reports));
    PtsAlert.success(
      selectedCompanyReport.sector + ' report added to cart successfully'
    );
    this.props.history.push({
      pathname: '/cart',
      state: {
        previousPage: '/indreportdetail',
      },
    });
  }
  render() {
    return (
      <>
        <Row className="breadcrumbs_fix_top_all_rd">
          <section className="breadcrumbs">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4>Reports Details</h4>
                </div>
                <div className="col-lg-6">
                  <GenericSearch history={this.props.history} color="white" />
                </div>
              </div>
            </div>
          </section>
        </Row>
        <Row>
          <Row
            style={{
              borderBottom: '1px solid #ABB2B9',
              marginTop: '7em',
              padding: '10px',
            }}
          >
            <Col md={1} style={{ marginTop: '-1em' }}></Col>
            <Col md={1} style={{ marginTop: '-1em' }}>
              <img
                src="/home/assets/img/Industry_image.gif"
                alt="Company Report"
                height="70%"
                style={{ marginTop: '0.5em' }}
              ></img>
            </Col>
            <Col md={7} style={{}}>
              <span className="bold_text2">
                Inside Industry Averages Report
              </span>
              &nbsp;
            </Col>
            <Col md={3} style={{}}>
              {/* <Button
                style={{
                  background: '#f58d51',

                  webkitBorderRadius: '10px',
                  fontSize: '0.8em',
                  margin: 'auto',
                  marginRight: '5px',
                  borderBottom: '2px solid #636262',
                }}
                onClick={(e) => this.addToCart(this.state.reportDetails)}
              >
                <ShoppingCartIcon style={{ fontSize: '1.3em' }} /> Add to cart
              </Button> */}
              {this.getButton()}
              <br></br>
              <span style={{ fontWeight: 'bold', color: '#f26d21' }}>
              Price: ${this.state.price}
              </span>{' '}
              <a
                href="/indpdf/sample/IndustryAveragesSample.pdf"
                target="_blank"
                className="company_report_sample"
              >
                View Sample
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
            <Advertisement advertisementType="verticalAddUnit" />
            </Col>
            <Col md={10}>
              <Container maxWidth="lg" style={{ marginTop: '1em' }}>
                <Row>
                  <Col md={12}>
                    <Card
                      style={{
                        background: 'transparent',
                      }}
                    >
                      <CardBody>
                        <Row
                          style={{
                            background: 'transparent',

                            textAlign: 'left',
                            marginBottom: '1em',
                          }}
                        >
                          <b style={{ paddingLeft: '10px' }}>
                            {this.state.reportDetails.sector}{' '}
                            {this.state.reportDetails.regionCountry}
                          </b>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <table className="report_content">
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      width: '300px',
                                      color: '#003B6D',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Publisher
                                  </td>
                                  <td style={{ width: '800px' }}>
                                    Wright Investors' Service
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: '300px',
                                      color: '#003B6D',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Industry
                                  </td>
                                  <td style={{ width: '800px' }}>
                                    {this.state.reportDetails.sector}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                          <Col md={6}>
                            <table className="report_content">
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      width: '300px',
                                      color: '#003B6D',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Region
                                  </td>
                                  <td style={{ width: '800px' }}>
                                    {this.state.reportDetails.regionCountry}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      width: '300px',
                                      color: '#003B6D',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Published
                                  </td>
                                  <td style={{ width: '800px' }}>
                                    {moment(
                                      this.state.reportDetails.publishDate
                                    ).format('MM/DD/YYYY')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row style={{ margin: '15px' }}>
                  <span className="bodyTxtBold1">Standard Report Content:</span>
                </Row>

                <Row>
                  <Col md={12}>
                    <Row
                      style={{
                        position: 'relative',
                        display: 'flex',
                      }}
                    >
                      <Col
                        md={6}
                        style={{ position: 'relative', padding: '1em' }}
                      >
                        <Card
                          style={{
                            height: '100%',
                            boxShadow:
                              'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                          }}
                          data-aos="fade-up"
                          data-aos-delay={200}
                        >
                          <CardHeader
                            style={{
                              background: '#2b7d6d',
                              color: '#fff',
                              height: '2em',
                            }}
                          >
                            <b style={{ paddingLeft: '10px' }}>1: Overview</b>
                          </CardHeader>
                          <CardBody>
                            <ul className="report_details_list">
                              <li style={{ padding: '2px' }}>
                                Wright Industry Averages - Overview
                              </li>
                              <li style={{ padding: '2px' }}>
                                Summary Analysis
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col
                        md={6}
                        style={{ position: 'relative', padding: '1em' }}
                      >
                        <Card
                          style={{
                            height: '100%',
                            boxShadow:
                              'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                          }}
                          data-aos="fade-up"
                          data-aos-delay={200}
                        >
                          <CardHeader
                            style={{
                              background: '#2b7d6d',
                              color: '#fff',
                              height: '2em',
                            }}
                          >
                            <b style={{ paddingLeft: '10px' }}>
                              {' '}
                              2: Financial Statement Analyses
                            </b>
                          </CardHeader>
                          <CardBody>
                            <ul className="report_details_list">
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Income Statement - (Actual Values)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Income Statement - (Common Size)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Income Statement - (Year-Year % Change)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Income Statement - (Five-Year Averages)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Balance Sheet - (Actual Values)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Balance Sheet - (Common Size)
                              </li>
                              <li style={{ padding: '2px' }}>
                                Balance Sheet - (Year-Year % Change)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Balance Sheet - (Five-Year Averages)
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Sources of Capital
                              </li>
                              <li style={{ padding: '2px' }}>Sales Analysis</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        position: 'relative',

                        marginBottom: '1em',
                      }}
                    >
                      <Col
                        md={6}
                        style={{
                          position: 'relative',
                          padding: '1em',
                          height: '16em',
                        }}
                      >
                        <Card
                          style={{
                            height: '100%',
                            boxShadow:
                              'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                          }}
                          data-aos="fade-up"
                          data-aos-delay={200}
                        >
                          <CardHeader
                            style={{
                              background: '#2b7d6d',
                              color: '#fff',
                              height: '2em',
                            }}
                          >
                            <b style={{ paddingLeft: '10px' }}>
                              {' '}
                              3: Financial Ratio Analyses
                            </b>
                          </CardHeader>
                          <CardBody>
                            <ul className="report_details_list">
                              <li style={{ padding: '2px' }}>
                                Accounting Ratios
                              </li>
                              <li style={{ padding: '2px' }}>
                                Asset Utilization
                              </li>
                              <li style={{ padding: '2px' }}>
                                Employee Efficiency
                              </li>
                              <li style={{ padding: '2px' }}>
                                Fixed Charges Coverage
                              </li>
                              <li style={{ padding: '2px' }}>
                                Leverage Analysis
                              </li>
                              <li style={{ padding: '2px' }}>
                                Liquidity Analysis
                              </li>
                              <li style={{ padding: '2px' }}>
                                Per-Share Ratios
                              </li>
                              <li style={{ padding: '2px' }}>
                                Profitability Analysis
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col
                        md={6}
                        style={{
                          position: 'relative',
                          padding: '1em',
                        }}
                      >
                        <Card
                          style={{
                            height: '100%',
                            boxShadow:
                              'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                          }}
                          data-aos="fade-up"
                          data-aos-delay={200}
                        >
                          <CardHeader
                            style={{
                              background: '#2b7d6d',
                              color: '#fff',
                              height: '2em',
                            }}
                          >
                            <b style={{ paddingLeft: '10px' }}>
                              {' '}
                              4: Company Listings
                            </b>
                          </CardHeader>
                          <CardBody>
                            <ul className="report_details_list">
                              <li style={{ padding: '2px' }}>
                                Alphabetical Listing of Companies
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Leading Companies - Fiscal Year Sales
                              </li>
                              <li style={{ padding: '2px' }}>
                                Leading Companies - Total Assets
                              </li>
                              <li style={{ padding: '2px' }}>
                                {' '}
                                Leading Companies - Market Capitalization
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        position: 'relative',

                        marginBottom: '1em',
                      }}
                    >
                      <Col
                        md={6}
                        style={{
                          position: 'relative',
                          padding: '1em',
                        }}
                      >
                        <Card
                          style={{
                            height: '100%',
                            boxShadow:
                              'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                          }}
                          data-aos="fade-up"
                          data-aos-delay={200}
                        >
                          <CardHeader
                            style={{
                              background: '#2b7d6d',
                              color: '#fff',
                              height: '2em',
                            }}
                          >
                            <b style={{ paddingLeft: '10px' }}> 5: Glossary</b>
                          </CardHeader>
                          <CardBody>
                            <ul className="report_details_list">
                              <li style={{ padding: '2px' }}>
                                Definition of Terms
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col
                        md={6}
                        style={{
                          position: 'relative',
                          padding: '1em',
                        }}
                      ></Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Row>
        <Modal
          isOpen={this.state.showModal}
          size="xl"
          className="modalContain"
          style={{ overflowY: "hidden" }}
        >
          <ModalHeader style={{ background: "#286894", color: "#fff" }}>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    float: "left",
                  }}
                >
                  <span>{this.state.sector}</span>-&nbsp;
                  <span>{this.state.regionCountry}</span>
                </Label>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody>
            <iframe
              src={"/indpdf/" + this.state.pdfUrlRpt}
              style={{ width: "100%", height: "500px" }}
              frameborder="0"
              title="indpdf"
            ></iframe>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
