import Container from "@material-ui/core/Container";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
export default class Canceled extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {};
  }

  BackToCart = () => {
    this.props.history.push({
      pathname: "/cart",
    });
  };
  render() {
    return (
      <>
        <Container
          maxWidth="lg"
          className="contcss"
          style={{ marginTop: "5em", minHeight: "40em" }}
        >
          <Row style={{ marginTop: "5em" }}>
            <Col md={12} style={{ marginTop: "4em", textAlign: "center" }}>
              <h1>Your payment was cancelled</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ textAlign: "center" }}>
              <Button
                style={{
                  background: "#f58d51",
                  webkitBorderRadius: "10px",
                  fontSize: "0.8em",
                  margin: "auto",
                  marginRight: "5px",
                  borderBottom: "2px solid #636262",
                }}
                onClick={this.BackToCart.bind(this)}
              >
                <ShoppingCartIcon style={{ fontSize: "1.3em" }} /> Back To Cart
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
