import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./LeverageAnalysis.css";
export default class LeverageAnalysis extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }

    let type = props.type;
    this.state = {
      isPdfReport: isPdfReport,
      type: type,
      leaverageAnalysisListData: [],
      render: false,
      data: props.data,
    };
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Long Term Debt % of EBIT") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebtEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt % of EBIT",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt % of EBITDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebtOfEBITDA,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt % of EBITDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebtOfTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebtOfTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt % of Com Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebtOfComEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt % of Com Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of EBIT") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of EBIT",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of EBITDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfEBITDA,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of EBITDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Total Debt % of Total Capital & Short Term Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfTotalCapitalAndShortTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of Total Capital & Short Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of Common Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfCommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquityOfTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquityOfTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapitalOfTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capital Expenditure % of Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.capitalExpenditureOfSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Capital Expenditure % of Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Fixed Assets % of Common Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.fixedAssetsOfCommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Fixed Assets % of Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Dividend Payout") {
      this.setState({
        graphDetails: {
          graphValues: this.state.dividendPayout,
          labelData: this.state.LabelList,
          yAxisTitle: "Dividend Payout",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest % of EBIT") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterestPercentofEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest % of EBIT",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest % of EBITDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterestPercentofEBITDA,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest % of EBITDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterestPercentofTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest % of Total Capita") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterestPercentofTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest % of Total Capita",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest % of Com Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterestPercentofComEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest % of Com Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock % of EBIT") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStockPercentofEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock % of EBIT",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock % of EDITDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStockPercentofEDITDA,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock % of EDITDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStockPercentofTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStockPercentofTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock % of Total Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStockPercentofTotalEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock % of Total Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Working Capital % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.workingCapitalPercentofTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Working Capital % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues: this.state.leaverageAnalysisData,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % of Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  componentDidMount() {
    let leaverageAnalysisList = this.state.data.leaverageAnalysisList.slice(
      0,
      5
    );

    if (leaverageAnalysisList.length > 0) {
      leaverageAnalysisList.reverse();
    }
    let indType = this.state.data.ind_TYPE;
    this.currency = this.state.data.currency;
    this.unit = this.state.data.unit;
    let leaverageAnalysisData = [];
    let LabelList = [];

    let longTermDebtEBIT = [];
    let longTermDebtOfEBITDA = [];
    let longTermDebtOfTotalAssets = [];
    let longTermDebtOfTotalCapital = [];
    let longTermDebtOfComEquity = [];
    let totalDebtOfEBIT = [];
    let totalDebtOfEBITDA = [];
    let totalDebtOfTotalAssets = [];
    let totalDebtOfTotalCapital = [];
    let totalDebtOfTotalCapitalAndShortTermDebt = [];
    let totalDebtOfCommonEquity = [];
    let commonEquityOfTotalAssets = [];
    let commonEquityOfTotalCapital = [];
    let totalCapitalOfTotalAssets = [];
    let capitalExpenditureOfSales = [];
    let fixedAssetsOfCommonEquity = [];
    let dividendPayout = [];

    let minorityInterestPercentofEBIT = [];
    let minorityInterestPercentofEBITDA = [];
    let minorityInterestPercentofTotalAssets = [];
    let minorityInterestPercentofTotalCapital = [];
    let minorityInterestPercentofComEquity = [];
    let preferredStockPercentofEBIT = [];
    let preferredStockPercentofEDITDA = [];
    let preferredStockPercentofTotalAssets = [];
    let preferredStockPercentofTotalCapital = [];
    let preferredStockPercentofTotalEquity = [];
    let workingCapitalPercentofTotalCapital = [];
    for (let i = 0; i < leaverageAnalysisList.length; i++) {
      minorityInterestPercentofEBIT.push(
        leaverageAnalysisList[i].minorityInterestPercentofEBIT
      );
      minorityInterestPercentofEBITDA.push(
        leaverageAnalysisList[i].minorityInterestPercentofEBITDA
      );
      minorityInterestPercentofTotalAssets.push(
        leaverageAnalysisList[i].minorityInterestPercentofTotalAssets
      );
      minorityInterestPercentofTotalCapital.push(
        leaverageAnalysisList[i].minorityInterestPercentofTotalCapital
      );
      minorityInterestPercentofComEquity.push(
        leaverageAnalysisList[i].minorityInterestPercentofComEquity
      );
      preferredStockPercentofEBIT.push(
        leaverageAnalysisList[i].preferredStockPercentofEBIT
      );
      preferredStockPercentofEDITDA.push(
        leaverageAnalysisList[i].preferredStockPercentofEDITDA
      );
      preferredStockPercentofTotalAssets.push(
        leaverageAnalysisList[i].preferredStockPercentofTotalAssets
      );
      preferredStockPercentofTotalCapital.push(
        leaverageAnalysisList[i].preferredStockPercentofTotalCapital
      );
      preferredStockPercentofTotalEquity.push(
        leaverageAnalysisList[i].preferredStockPercentofTotalEquity
      );
      workingCapitalPercentofTotalCapital.push(
        leaverageAnalysisList[i].workingCapitalPercentofTotalCapital
      );
      leaverageAnalysisData.push(
        leaverageAnalysisList[i].fundsFromOperationsPercentofTotalDebt
      );
      LabelList.push(leaverageAnalysisList[i].fiscalYear);

      longTermDebtEBIT.push(leaverageAnalysisList[i].longTermDebtPercentofEBIT);
      longTermDebtOfEBITDA.push(
        leaverageAnalysisList[i].longTermDebtPercentofEBITDA
      );
      longTermDebtOfTotalAssets.push(
        leaverageAnalysisList[i].longTermDebtPercentofTotalAssets
      );
      longTermDebtOfTotalCapital.push(
        leaverageAnalysisList[i].longTermDebtPercentofTotalCapital
      );
      longTermDebtOfComEquity.push(
        leaverageAnalysisList[i].longTermDebtPercentofComEquity
      );
      totalDebtOfEBIT.push(leaverageAnalysisList[i].totalDebtPercentofEBIT);
      totalDebtOfEBITDA.push(leaverageAnalysisList[i].totalDebtPercentofEBITDA);
      totalDebtOfTotalAssets.push(
        leaverageAnalysisList[i].totalDebtPercentofTotalAssets
      );
      totalDebtOfTotalCapital.push(
        leaverageAnalysisList[i].totalDebtPercentofTotalCapital
      );
      totalDebtOfTotalCapitalAndShortTermDebt.push(
        leaverageAnalysisList[i].totalDebtPercentofTotalCapitalAndShortTermDebt
      );
      totalDebtOfCommonEquity.push(
        leaverageAnalysisList[i].totalDebtPercentofCommonEquity
      );
      commonEquityOfTotalAssets.push(
        leaverageAnalysisList[i].commonEquityPercentofTotalAssets
      );
      commonEquityOfTotalCapital.push(
        leaverageAnalysisList[i].commonEquityPercentofTotalCapital
      );
      totalCapitalOfTotalAssets.push(
        leaverageAnalysisList[i].totalCapitalPercentofTotalAssets
      );
      capitalExpenditureOfSales.push(
        leaverageAnalysisList[i].capitalExpenditurePercentofSales
      );
      fixedAssetsOfCommonEquity.push(
        leaverageAnalysisList[i].fixedAssetsPercentofCommonEquity
      );
      dividendPayout.push(leaverageAnalysisList[i].dividendPayout);
    }
    this.setState({
      leaverageAnalysisListData: leaverageAnalysisList,
      minorityInterestPercentofEBIT: minorityInterestPercentofEBIT,
      minorityInterestPercentofEBITDA: minorityInterestPercentofEBITDA,
      minorityInterestPercentofTotalAssets:
        minorityInterestPercentofTotalAssets,
      minorityInterestPercentofTotalCapital:
        minorityInterestPercentofTotalCapital,
      minorityInterestPercentofComEquity: minorityInterestPercentofComEquity,
      preferredStockPercentofEBIT: preferredStockPercentofEBIT,
      preferredStockPercentofEDITDA: preferredStockPercentofEDITDA,
      preferredStockPercentofTotalAssets: preferredStockPercentofTotalAssets,
      preferredStockPercentofTotalCapital: preferredStockPercentofTotalCapital,
      preferredStockPercentofTotalEquity: preferredStockPercentofTotalEquity,
      workingCapitalPercentofTotalCapital: workingCapitalPercentofTotalCapital,
      indType: indType,
      leaverageAnalysisData: leaverageAnalysisData,
      LabelList: LabelList,
      longTermDebtEBIT: longTermDebtEBIT,
      longTermDebtOfEBITDA: longTermDebtOfEBITDA,
      longTermDebtOfTotalAssets: longTermDebtOfTotalAssets,
      longTermDebtOfTotalCapital: longTermDebtOfTotalCapital,
      longTermDebtOfComEquity: longTermDebtOfComEquity,
      totalDebtOfEBIT: totalDebtOfEBIT,
      totalDebtOfEBITDA: totalDebtOfEBITDA,
      totalDebtOfTotalAssets: totalDebtOfTotalAssets,
      totalDebtOfTotalCapital: totalDebtOfTotalCapital,
      totalDebtOfTotalCapitalAndShortTermDebt:
        totalDebtOfTotalCapitalAndShortTermDebt,
      totalDebtOfCommonEquity: totalDebtOfCommonEquity,
      commonEquityOfTotalAssets: commonEquityOfTotalAssets,
      commonEquityOfTotalCapital: commonEquityOfTotalCapital,
      totalCapitalOfTotalAssets: totalCapitalOfTotalAssets,
      capitalExpenditureOfSales: capitalExpenditureOfSales,
      fixedAssetsOfCommonEquity: fixedAssetsOfCommonEquity,
      dividendPayout: dividendPayout,
      series: [
        {
          name: "Funds From Operations % of Total Debt",
          type: "bar",
          data: leaverageAnalysisData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },

        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          text: "",
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        colors: ["#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: LabelList,
        xaxis: {
          title: {
            text: "Year",
            style: {
              colors: "#808b96",
            },
          },

          axisBorder: {
            show: true,
            color: "#808b96",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "Funds From Operations % of Total Debt",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#808b96 ",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;
            },
          },
        },
      },
      tableGraphSeries: [
        {
          data: leaverageAnalysisData,
        },
      ],
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      render: true,
    });
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
              <Row style={{ padding: "0px 0px 7px 0px" }}>
                <Col md={8}>
                  <span>
                    <span className="section_header"> Leverage Analysis</span>
                  </span>{" "}
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="leverage_analysis">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Long Term Debt % of EBIT</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.longTermDebtPercentofEBIT !== null
                          ? Utils.valueFormater(
                              data.longTermDebtPercentofEBIT
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Long Term Debt % of EBITDA</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.longTermDebtPercentofEBITDA !== null
                          ? Utils.valueFormater(
                              data.longTermDebtPercentofEBITDA
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Long Term Debt % of Total Assets</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.longTermDebtPercentofTotalAssets !== null
                          ? Utils.valueFormater(
                              data.longTermDebtPercentofTotalAssets
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Long Term Debt % of Total Capital</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.longTermDebtPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.longTermDebtPercentofTotalCapital
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Long Term Debt % of Com Equity</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.longTermDebtPercentofComEquity !== null
                          ? Utils.valueFormater(
                              data.longTermDebtPercentofComEquity
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of EBIT</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofEBIT !== null
                          ? Utils.valueFormater(data.totalDebtPercentofEBIT) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of EBITDA</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofEBITDA !== null
                          ? Utils.valueFormater(data.totalDebtPercentofEBITDA) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of Total Assets</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofTotalAssets !== null
                          ? Utils.valueFormater(
                              data.totalDebtPercentofTotalAssets
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of Total Capital</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.totalDebtPercentofTotalCapital
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Total Debt % of Total Capital & Short Term Debt
                    </span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofTotalCapitalAndShortTermDebt !==
                        null
                          ? Utils.valueFormater(
                              data.totalDebtPercentofTotalCapitalAndShortTermDebt
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of Common Equity</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofCommonEquity !== null
                          ? Utils.valueFormater(
                              data.totalDebtPercentofCommonEquity
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest % of EBIT</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.minorityInterestPercentofEBIT !== null
                          ? data.minorityInterestPercentofEBIT.toFixed(2) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest % of EBITDA</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.minorityInterestPercentofEBITDA !== null
                          ? data.minorityInterestPercentofEBITDA.toFixed(2) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest % of Total Assets</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.minorityInterestPercentofTotalAssets !== null
                          ? data.minorityInterestPercentofTotalAssets.toFixed(
                              2
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest % of Total Capita</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.minorityInterestPercentofTotalCapital !== null
                          ? data.minorityInterestPercentofTotalCapital.toFixed(
                              2
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest % of Com Equity</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.minorityInterestPercentofComEquity !== null
                          ? data.minorityInterestPercentofComEquity.toFixed(2) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock % of EBIT</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.preferredStockPercentofEBIT !== null
                          ? data.preferredStockPercentofEBIT.toFixed(2) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock % of EDITDA</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.preferredStockPercentofEDITDA !== null
                          ? data.preferredStockPercentofEDITDA.toFixed(2) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock % of Total Assets</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.preferredStockPercentofTotalAssets !== null
                          ? data.preferredStockPercentofTotalAssets.toFixed(2) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock % of Total Capital</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.preferredStockPercentofTotalCapital !== null
                          ? data.preferredStockPercentofTotalCapital.toFixed(
                              2
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock % of Total Equity</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.preferredStockPercentofTotalEquity !== null
                          ? data.preferredStockPercentofTotalEquity.toFixed(2) +
                            "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Common Equity % of Total Assets</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.commonEquityPercentofTotalAssets !== null
                          ? Utils.valueFormater(
                              data.commonEquityPercentofTotalAssets
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Common Equity % of Total Capital</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.commonEquityPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.commonEquityPercentofTotalCapital
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Capital % of Total Assets</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.totalCapitalPercentofTotalAssets !== null
                          ? Utils.valueFormater(
                              data.totalCapitalPercentofTotalAssets
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Capital Expenditure % of Sales</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.capitalExpenditurePercentofSales !== null
                          ? Utils.valueFormater(
                              data.capitalExpenditurePercentofSales
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Fixed Assets % of Common Equity</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.fixedAssetsPercentofCommonEquity !== null
                          ? Utils.valueFormater(
                              data.fixedAssetsPercentofCommonEquity
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Working Capital % of Total Capital</th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.workingCapitalPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.workingCapitalPercentofTotalCapital
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Dividend Payout</span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.dividendPayout !== null
                          ? Utils.valueFormater(data.dividendPayout) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      {" "}
                      Funds From Operations % of Total Debt
                    </span>
                  </th>
                  {this.state.leaverageAnalysisListData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentofTotalDebt !== null
                          ? Utils.valueFormater(
                              data.fundsFromOperationsPercentofTotalDebt
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row style={{ padding: "0px 0px 7px 0px" }}>
                  <Col md={8}>
                    <span>
                      <span className="section_header"> Leverage Analysis</span>
                    </span>{" "}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="leverage_analysis">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Long Term Debt % of EBIT</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long Term Debt % of EBIT"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.longTermDebtPercentofEBIT !== null
                            ? Utils.valueFormater(
                                data.longTermDebtPercentofEBIT
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Long Term Debt % of EBITDA</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long Term Debt % of EBITDA"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.longTermDebtPercentofEBITDA !== null
                            ? Utils.valueFormater(
                                data.longTermDebtPercentofEBITDA
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Long Term Debt % of Total Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long Term Debt % of Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.longTermDebtPercentofTotalAssets !== null
                            ? Utils.valueFormater(
                                data.longTermDebtPercentofTotalAssets
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        Long Term Debt % of Total Capital
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long Term Debt % of Total Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.longTermDebtPercentofTotalCapital !== null
                            ? Utils.valueFormater(
                                data.longTermDebtPercentofTotalCapital
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Long Term Debt % of Com Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long Term Debt % of Com Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.longTermDebtPercentofComEquity !== null
                            ? Utils.valueFormater(
                                data.longTermDebtPercentofComEquity
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Debt % of EBIT</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Debt % of EBIT")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofEBIT !== null
                            ? Utils.valueFormater(data.totalDebtPercentofEBIT) +
                              "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Debt % of EBITDA</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Debt % of EBITDA")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofEBITDA !== null
                            ? Utils.valueFormater(
                                data.totalDebtPercentofEBITDA
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Debt % of Total Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt % of Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofTotalAssets !== null
                            ? Utils.valueFormater(
                                data.totalDebtPercentofTotalAssets
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Debt % of Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt % of Total Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofTotalCapital !== null
                            ? Utils.valueFormater(
                                data.totalDebtPercentofTotalCapital
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        Total Debt % of Total Capital & Short Term Debt
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt % of Total Capital & Short Term Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofTotalCapitalAndShortTermDebt !==
                          null
                            ? Utils.valueFormater(
                                data.totalDebtPercentofTotalCapitalAndShortTermDebt
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Debt % of Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt % of Common Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalDebtPercentofCommonEquity !== null
                            ? Utils.valueFormater(
                                data.totalDebtPercentofCommonEquity
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest % of EBIT</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.minorityInterestPercentofEBIT !== null
                            ? data.minorityInterestPercentofEBIT.toFixed(2) +
                              "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest % of EBITDA</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.minorityInterestPercentofEBITDA !== null
                            ? data.minorityInterestPercentofEBITDA.toFixed(2) +
                              "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest % of Total Assets</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.minorityInterestPercentofTotalAssets !== null
                            ? data.minorityInterestPercentofTotalAssets.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest % of Total Capita</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.minorityInterestPercentofTotalCapital !== null
                            ? data.minorityInterestPercentofTotalCapital.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest % of Com Equity</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.minorityInterestPercentofComEquity !== null
                            ? data.minorityInterestPercentofComEquity.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock % of EBIT</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.preferredStockPercentofEBIT !== null
                            ? data.preferredStockPercentofEBIT.toFixed(2) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock % of EDITDA</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.preferredStockPercentofEDITDA !== null
                            ? data.preferredStockPercentofEDITDA.toFixed(2) +
                              "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock % of Total Assets</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.preferredStockPercentofTotalAssets !== null
                            ? data.preferredStockPercentofTotalAssets.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock % of Total Capital</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.preferredStockPercentofTotalCapital !== null
                            ? data.preferredStockPercentofTotalCapital.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock % of Total Equity</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.preferredStockPercentofTotalEquity !== null
                            ? data.preferredStockPercentofTotalEquity.toFixed(
                                2
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Common Equity % of Total Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity % of Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.commonEquityPercentofTotalAssets !== null
                            ? Utils.valueFormater(
                                data.commonEquityPercentofTotalAssets
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Common Equity % of Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity % of Total Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.commonEquityPercentofTotalCapital !== null
                            ? Utils.valueFormater(
                                data.commonEquityPercentofTotalCapital
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Total Capital % of Total Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Capital % of Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.totalCapitalPercentofTotalAssets !== null
                            ? Utils.valueFormater(
                                data.totalCapitalPercentofTotalAssets
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Capital Expenditure % of Sales</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Capital Expenditure % of Sales"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.capitalExpenditurePercentofSales !== null
                            ? Utils.valueFormater(
                                data.capitalExpenditurePercentofSales
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Fixed Assets % of Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Fixed Assets % of Common Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.fixedAssetsPercentofCommonEquity !== null
                            ? Utils.valueFormater(
                                data.fixedAssetsPercentofCommonEquity
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Working Capital % of Total Capital</th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.workingCapitalPercentofTotalCapital !== null
                            ? Utils.valueFormater(
                                data.workingCapitalPercentofTotalCapital
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Dividend Payout</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Dividend Payout")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.dividendPayout !== null
                            ? Utils.valueFormater(data.dividendPayout) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        {" "}
                        Funds From Operations % of Total Debt
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations % of Total Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.leaverageAnalysisListData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentofTotalDebt !== null
                            ? Utils.valueFormater(
                                data.fundsFromOperationsPercentofTotalDebt
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Leverage Analysis
                    </Label>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
