import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React from "react";
import { Button, CardBody, Col, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./CompanyShortDetails.css";

export class CompanyShortDetails extends React.Component {
  cartData = store.getState().cartData.cart;
  price = store.getState().price.price;
  userData = store.getState().userData.userData;
  constructor(props) {
    super(props);
    let price = "";
    if (this.price) {
      price = this.price[0].price;
    }
    this.state = {
      company: props.data,
      onClick: props.onClick,
      price: price,
    };
  }

  goToCompanySnapshot() {
    if (this.state.onClick) {
      this.state.onClick(this.state.company);
    }
  }
  addToCart(selectedCompanyReport) {
    let selectedCompanyReportObj = {
      cusip: selectedCompanyReport.cusip,
      tableName: selectedCompanyReport.tableName,
      companyName: selectedCompanyReport.companyName,
      ticker: selectedCompanyReport.ticker,
      country: selectedCompanyReport.country,
      countryCode: selectedCompanyReport.countryCode,
      region: selectedCompanyReport.region,
      dssTicker: selectedCompanyReport.dssTicker,
      shouldAppearInSearch: selectedCompanyReport.shouldAppearInSearch,
      corpInfoIndustry: selectedCompanyReport.corpInfoIndustry,
      searchDescription: selectedCompanyReport.searchDescription,
      fullPDF: selectedCompanyReport.fullPDF,
      codeCountry: selectedCompanyReport.codeCountry,
      planId: "3",
      price: this.state.price,
      type: "Company",
    };

    this.cartData = store.getState().cartData.cart;

    let reports = this.cartData;
    for (let i = 0; i < this.cartData.length; i++) {
      let cusip = this.cartData[i].cusip;
      if (
        Utils.equalsIgnoreCase("Company", selectedCompanyReportObj.type) &&
        Utils.equalsIgnoreCase(cusip, selectedCompanyReportObj.cusip)
      ) {
        PtsAlert.error(
          selectedCompanyReportObj.type +
            " " +
            "Report " +
            selectedCompanyReportObj.companyName +
            " Already exists in your Cart"
        );
        return;
      }
    }

    reports.push(selectedCompanyReportObj);
    store.dispatch(cartAction.setCart(reports));

    this.props.history.push({
      pathname: "/cart",
      state: {
        previousPage: "/search",
      },
    });
  }

  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    if (
      rowData.country.trim().toUpperCase() === "KOREA (SOUTH)" ||
      rowData.countryCode === "410"
    ) {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/KR/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VENEZUELA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/VE/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "TANZANIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/TZ/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "PALESTINE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/PS/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "MACEDONIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/MK/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "BOLIVIA") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/BO/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "UNITED KINGDOM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/GB/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "COTE D'IVOIRE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/CI/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else if (rowData.country.trim().toUpperCase() === "VIETNAM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/VN/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    } else {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/${rowData.codeCountry}/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp;
        </span>
      );
    }
    return getCountryWithFlag;
  }
  getButton(report) {
    var lengthObj = Object.keys(this.userData).length;

    if (lengthObj > 0) {
      if (this.userData.activeUser === false) {
        if (this.state.company.fullPDF && this.state.company.fullPDF === true) {
          return (
            <Button
              className="cart_button"
              style={{
                background: "rgb(245, 141, 81)",
                fontSize: "0.8em",
                float: "right",
                borderRadius: "10px",
                margin: "auto 5px auto auto",
                borderBottom: "2px solid rgb(99, 98, 98)",
              }}
              onClick={(e) => this.addToCart(this.state.company)}
            >
              <ShoppingCartIcon style={{ fontSize: "1.3em" }} />

              <span className="isAddToCart">Add to cart</span>
            </Button>
          );
        }

        return "";
      } else {
        return "";
      }
    } else {
      if (this.state.company.fullPDF && this.state.company.fullPDF === true) {
        return (
          <Button
            className="cart_button"
            style={{
              background: "rgb(245, 141, 81)",
              fontSize: "0.8em",
              float: "right",
              borderRadius: "10px",
              margin: "auto 5px auto auto",
              borderBottom: "2px solid rgb(99, 98, 98)",
            }}
            onClick={(e) => this.addToCart(this.state.company)}
          >
            <ShoppingCartIcon style={{ fontSize: "1.3em" }} />

            <span className="isAddToCart">Add to cart</span>
          </Button>
        );
      }

      return "";
    }
  }
  // getButton() {
  //   if (this.state.company.fullPDF && this.state.company.fullPDF === true) {
  //     return (
  //       <Button
  //         className="cart_button"
  //         style={{
  //           background: "rgb(245, 141, 81)",
  //           fontSize: "0.8em",
  //           float: "right",
  //           borderRadius: "10px",
  //           margin: "auto 5px auto auto",
  //           borderBottom: "2px solid rgb(99, 98, 98)",
  //         }}
  //         onClick={(e) => this.addToCart(this.state.company)}
  //       >
  //         <ShoppingCartIcon style={{ fontSize: "1.3em" }} />

  //         <span className="isAddToCart">Add to cart</span>
  //       </Button>
  //     );
  //   }

  //   return "";
  // }
  render() {
    return (
      <CardBody
        style={{
          boxShadow: "0 4px 6px -6px #e6e1e1 inset",
          padding: "0.5em",
          width: "100%",
          background: "#f9fcff",
          borderBottom: "3px solid #fff",
          borderRadius: "5px",
          margin: "auto",
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col class="buy" style={{ fontSize: "small", alignItems: "center" }}>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={this.goToCompanySnapshot.bind(this)}
            >
              {this.state.company.companyName}
            </span>{" "}
            &nbsp; (
            <span
              style={{
                fontWeight: "bold",
                color: "brown",
                cursor: "pointer",
              }}
              onClick={this.goToCompanySnapshot.bind(this)}
            >
              {this.state.company.ticker}
            </span>
            )
          </Col>
          <Col
            style={{
              fontSize: "small",
              alignItems: "center",
              display: "flex",
            }}
          >
            {Utils.getCountryFlag(this.state.company.country)}
            &nbsp;&nbsp;
            <span>
              <i>
                {this.state.company.region},{this.state.company.country}
              </i>
            </span>
            {this.getButton()}
          </Col>
        </Row>
      </CardBody>
    );
  }
}

export default CompanyShortDetails;
