import {
  GET_INDUSTRY_FILTER,
  SET_INDUSTRY_FILTER,
} from "./userSelectedIndustriesTypes";

const initialState = {
  selectedIndustries: [],
};

const userSelectedIndustriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDUSTRY_FILTER:
      return {
        ...state,
      };
    case SET_INDUSTRY_FILTER:
      return {
        ...state,
        selectedIndustries: action.payload,
      };
    default:
      return state;
  }
};

export default userSelectedIndustriesReducer;
