import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./FlowOfFunds.css";
export default class FlowOfFunds extends Component {
  country = "";
  currency = "";
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      type: props.type,
      country: "",
      currency: "",
      data: props.data,
      flowOfFundsData: [],
      render: false,
      modalHeader: "",
      actual: false,
      common: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }

  componentDidMount() {
    this.currency = this.state.data.currency;
    this.country = this.state.data.country;
    if (this.state.type === "Total Sources and Uses Actual") {
      let LabelList = [];
      let totalSources = [];
      let OtherUses = [];
      let FundsFromForOtherOperatingActivities = [];
      let DecreaseIncreaseinReceivables = [];
      let IncreaseDecreaseInAccountsPayable = [];
      let DecreaseIncreaseinOtherAssetsLiabilities = [];
      let LongTermBorrowings = [];
      let NetProceedsFromSaleorIssuanceofCommonAndPreferred = [];
      let ProceedsFromStockOptions = [];
      let OtherProceedsFromSaleorIssuanceofStock = [];
      let DisposalofFixedAssets = [];
      let DecreaseinInvestments = [];
      let IncreaseDecreaseinShortTermBorrowings = [];
      let OtherSourcesFinancing = [];
      let CashDividendsPaidTotal = [];
      let IncreaseinInvestments = [];
      let ReductioninLongTermDebt = [];
      let CapitalExpendituresAdditionstoFixedAssets = [];
      let NetAssetsfromAcquisitions = [];
      let ComPfdPurchasedRetiredConvertedAndRedeemed = [];
      let AdditionstoOtherAssets = [];
      let OtherUsesInvesting = [];
      let OtherUsesFinancing = [];
      let EffectofExchangeRateonCash = [];
      let IncreaseDecreaseinCash = [];
      let ExtraordinaryItems = [];
      let commonDividends = [];
      let PreferredDividends = [];
      let FundsFromOperations = [];
      let totalUses = [];
      let flowOfFundsData = this.state.data.fOfSandUActualList;

      for (let i = 0; i < flowOfFundsData.length; i++) {
        totalSources.push(flowOfFundsData[i].totalSources);
        totalUses.push(flowOfFundsData[i].totalUses);
        LabelList.push(flowOfFundsData[i].year);
        OtherUses.push(flowOfFundsData[i].otherUses);
        FundsFromForOtherOperatingActivities.push(
          flowOfFundsData[i].fundsfromforOtherOperatingActivities
        );
        DecreaseIncreaseinReceivables.push(
          flowOfFundsData[i].decreaseIncreaseinReceivables
        );
        IncreaseDecreaseInAccountsPayable.push(
          flowOfFundsData[i].increaseDecreaseinAccountsPayable
        );
        DecreaseIncreaseinOtherAssetsLiabilities.push(
          flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
        );
        LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
        NetProceedsFromSaleorIssuanceofCommonAndPreferred.push(
          flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred
        );
        ProceedsFromStockOptions.push(
          flowOfFundsData[i].proceedsfromStockOptions
        );
        OtherProceedsFromSaleorIssuanceofStock.push(
          flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
        );
        DisposalofFixedAssets.push(flowOfFundsData[i].disposalofFixedAssets);
        DecreaseinInvestments.push(flowOfFundsData[i].decreaseinInvestments);
        IncreaseDecreaseinShortTermBorrowings.push(
          flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
        );
        OtherSourcesFinancing.push(flowOfFundsData[i].otherSourcesFinancing);
        CashDividendsPaidTotal.push(flowOfFundsData[i].cashDividendsPaidTotal);
        IncreaseinInvestments.push(flowOfFundsData[i].increaseinInvestments);
        ReductioninLongTermDebt.push(
          flowOfFundsData[i].reductioninLongTermDebt
        );
        CapitalExpendituresAdditionstoFixedAssets.push(
          flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
        );
        NetAssetsfromAcquisitions.push(
          flowOfFundsData[i].netAssetsfromAcquisitions
        );
        ComPfdPurchasedRetiredConvertedAndRedeemed.push(
          flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
        );
        AdditionstoOtherAssets.push(flowOfFundsData[i].additionstoOtherAssets);
        OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
        OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
        EffectofExchangeRateonCash.push(
          flowOfFundsData[i].effectofExchangeRateonCash
        );
        IncreaseDecreaseinCash.push(flowOfFundsData[i].increaseDecreaseinCash);
        ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
        commonDividends.push(flowOfFundsData[i].commonDividends);
        PreferredDividends.push(flowOfFundsData[i].preferredDividends);
        FundsFromOperations.push(flowOfFundsData[i].fundsfromOperations);

        flowOfFundsData[i].totalSources = this.convertToMillionaire(
          flowOfFundsData[i].totalSources
        );

        flowOfFundsData[i].otherUses = this.convertToMillionaire(
          flowOfFundsData[i].otherUses
        );
        flowOfFundsData[i].totalUses = this.convertToMillionaire(
          flowOfFundsData[i].totalUses
        );
        flowOfFundsData[i].fundsfromforOtherOperatingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsfromforOtherOperatingActivities
          );
        flowOfFundsData[i].decreaseIncreaseinReceivables =
          this.convertToMillionaire(
            flowOfFundsData[i].decreaseIncreaseinReceivables
          );
        flowOfFundsData[i].decreaseIncreaseinInventories =
          this.convertToMillionaire(
            flowOfFundsData[i].decreaseIncreaseinInventories
          );
        flowOfFundsData[i].increaseDecreaseinAccountsPayable =
          this.convertToMillionaire(
            flowOfFundsData[i].increaseDecreaseinAccountsPayable
          );
        flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
          );
        flowOfFundsData[i].longTermBorrowings = this.convertToMillionaire(
          flowOfFundsData[i].longTermBorrowings
        );
        flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred =
          this.convertToMillionaire(
            flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred
          );
        flowOfFundsData[i].proceedsfromStockOptions = this.convertToMillionaire(
          flowOfFundsData[i].proceedsfromStockOptions
        );
        flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
          this.convertToMillionaire(
            flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
          );
        flowOfFundsData[i].disposalofFixedAssets = this.convertToMillionaire(
          flowOfFundsData[i].disposalofFixedAssets
        );
        flowOfFundsData[i].decreaseinInvestments = this.convertToMillionaire(
          flowOfFundsData[i].decreaseinInvestments
        );
        flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
          this.convertToMillionaire(
            flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
          );
        flowOfFundsData[i].otherSourcesFinancing = this.convertToMillionaire(
          flowOfFundsData[i].otherSourcesFinancing
        );
        flowOfFundsData[i].otherSourcesInvesting = this.convertToMillionaire(
          flowOfFundsData[i].otherSourcesInvesting
        );
        flowOfFundsData[i].cashDividendsPaidTotal = this.convertToMillionaire(
          flowOfFundsData[i].cashDividendsPaidTotal
        );
        flowOfFundsData[i].increaseinInvestments = this.convertToMillionaire(
          flowOfFundsData[i].increaseinInvestments
        );
        flowOfFundsData[i].reductioninLongTermDebt = this.convertToMillionaire(
          flowOfFundsData[i].reductioninLongTermDebt
        );
        flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
          );
        flowOfFundsData[i].netAssetsfromAcquisitions =
          this.convertToMillionaire(
            flowOfFundsData[i].netAssetsfromAcquisitions
          );
        flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
          this.convertToMillionaire(
            flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
          );
        flowOfFundsData[i].additionstoOtherAssets = this.convertToMillionaire(
          flowOfFundsData[i].additionstoOtherAssets
        );
        flowOfFundsData[i].otherUsesInvesting = this.convertToMillionaire(
          flowOfFundsData[i].otherUsesInvesting
        );
        flowOfFundsData[i].otherUsesFinancing = this.convertToMillionaire(
          flowOfFundsData[i].otherUsesFinancing
        );
        flowOfFundsData[i].effectofExchangeRateonCash =
          this.convertToMillionaire(
            flowOfFundsData[i].effectofExchangeRateonCash
          );
        flowOfFundsData[i].increaseDecreaseinCash = this.convertToMillionaire(
          flowOfFundsData[i].increaseDecreaseinCash
        );
        flowOfFundsData[i].extraordinaryItems = this.convertToMillionaire(
          flowOfFundsData[i].extraordinaryItems
        );
        flowOfFundsData[i].commonDividends = this.convertToMillionaire(
          flowOfFundsData[i].commonDividends
        );
        flowOfFundsData[i].preferredDividends = this.convertToMillionaire(
          flowOfFundsData[i].preferredDividends
        );
        flowOfFundsData[i].fundsfromOperations = this.convertToMillionaire(
          flowOfFundsData[i].fundsfromOperations
        );
      }

      this.setState({
        actual: true,
        common: false,
        flowOfFundsData: flowOfFundsData,
        OtherUses: OtherUses,
        totalSources: totalSources,
        LabelList: LabelList,
        totalUses: totalUses,
        FundsFromForOtherOperatingActivities:
          FundsFromForOtherOperatingActivities,
        DecreaseIncreaseinReceivables: DecreaseIncreaseinReceivables,
        IncreaseDecreaseInAccountsPayable: IncreaseDecreaseInAccountsPayable,
        DecreaseIncreaseinOtherAssetsLiabilities:
          DecreaseIncreaseinOtherAssetsLiabilities,
        LongTermBorrowings: LongTermBorrowings,
        NetProceedsFromSaleorIssuanceofCommonAndPreferred:
          NetProceedsFromSaleorIssuanceofCommonAndPreferred,
        ProceedsFromStockOptions: ProceedsFromStockOptions,
        OtherProceedsFromSaleorIssuanceofStock:
          OtherProceedsFromSaleorIssuanceofStock,
        DisposalofFixedAssets: DisposalofFixedAssets,
        DecreaseinInvestments: DecreaseinInvestments,
        IncreaseDecreaseinShortTermBorrowings:
          IncreaseDecreaseinShortTermBorrowings,
        OtherSourcesFinancing: OtherSourcesFinancing,
        CashDividendsPaidTotal: CashDividendsPaidTotal,
        IncreaseinInvestments: IncreaseinInvestments,
        ReductioninLongTermDebt: ReductioninLongTermDebt,
        CapitalExpendituresAdditionstoFixedAssets:
          CapitalExpendituresAdditionstoFixedAssets,
        NetAssetsfromAcquisitions: NetAssetsfromAcquisitions,
        ComPfdPurchasedRetiredConvertedAndRedeemed:
          ComPfdPurchasedRetiredConvertedAndRedeemed,
        AdditionstoOtherAssets: AdditionstoOtherAssets,
        OtherUsesInvesting: OtherUsesInvesting,
        OtherUsesFinancing: OtherUsesFinancing,
        EffectofExchangeRateonCash: EffectofExchangeRateonCash,
        IncreaseDecreaseinCash: IncreaseDecreaseinCash,
        ExtraordinaryItems: ExtraordinaryItems,
        commonDividends: commonDividends,
        PreferredDividends: PreferredDividends,
        FundsFromOperations: FundsFromOperations,
        render: true,
        modalHeader: " Total Sources and Uses -Actual ",
        series: [
          {
            name: "Total Uses",
            type: "bar",
            data: totalUses,
          },
          {
            name: "Total Sources",
            type: "bar",
            data: totalSources,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "Shortterm and longterm debit",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "30%",
            },
          },
          colors: ["#ff7f0e", "#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
      });
    } else if (this.state.type === "Total Sources and Uses Common Size") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService(
        "flowOfFunds/SourceAndUsageCommon",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.fOfSandUCommonList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];
              let totalSources = [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseinReceivables = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsFromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleorIssuanceofStock = [];
              let DisposalofFixedAssets = [];
              let DecreaseinInvestments = [];
              let IncreaseDecreaseinShortTermBorrowings = [];
              let OtherSourcesFinancing = [];
              let CashDividendsPaidTotal = [];
              let IncreaseinInvestments = [];
              let ReductioninLongTermDebt = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let NetAssetsfromAcquisitions = [];
              let ComPfdPurchasedRetiredConvertedAndRedeemed = [];
              let AdditionstoOtherAssets = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let EffectofExchangeRateonCash = [];
              let IncreaseDecreaseinCash = [];
              let ExtraordinaryItems = [];
              let commonDividends = [];
              let PreferredDividends = [];
              let FundsFromOperations = [];
              let totalUses = [];
              let OtherUses = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                totalSources.push(flowOfFundsData[i].totalSources);
                totalUses.push(flowOfFundsData[i].totalUses);
                LabelList.push(flowOfFundsData[i].year);
                OtherUses.push(flowOfFundsData[i].otherUses);
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseinReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsFromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleorIssuanceofStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalofFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseinInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseinShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                IncreaseinInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                ReductioninLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                NetAssetsfromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                ComPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                AdditionstoOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                EffectofExchangeRateonCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseinCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                commonDividends.push(flowOfFundsData[i].commonDividends);
                PreferredDividends.push(flowOfFundsData[i].preferredDividends);
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );

                flowOfFundsData[i].totalSources = this.convertToMillionaire(
                  flowOfFundsData[i].totalSources
                );

                flowOfFundsData[i].otherUses = this.convertToMillionaire(
                  flowOfFundsData[i].otherUses
                );
                flowOfFundsData[i].totalUses = this.convertToMillionaire(
                  flowOfFundsData[i].totalUses
                );
                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].decreaseIncreaseinInventories =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinInventories
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].additionstoOtherAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].additionstoOtherAssets
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].commonDividends = this.convertToMillionaire(
                  flowOfFundsData[i].commonDividends
                );
                flowOfFundsData[i].preferredDividends =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividends
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
              }
              this.setState({
                actual: false,
                common: true,
                flowOfFundsData: flowOfFundsData,
                OtherUses: OtherUses,
                totalSources: totalSources,
                totalUses: totalUses,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseinReceivables: DecreaseIncreaseinReceivables,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsFromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsFromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleorIssuanceofStock:
                  OtherProceedsFromSaleorIssuanceofStock,
                DisposalofFixedAssets: DisposalofFixedAssets,
                DecreaseinInvestments: DecreaseinInvestments,
                IncreaseDecreaseinShortTermBorrowings:
                  IncreaseDecreaseinShortTermBorrowings,
                OtherSourcesFinancing: OtherSourcesFinancing,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                IncreaseinInvestments: IncreaseinInvestments,
                ReductioninLongTermDebt: ReductioninLongTermDebt,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                NetAssetsfromAcquisitions: NetAssetsfromAcquisitions,
                ComPfdPurchasedRetiredConvertedAndRedeemed:
                  ComPfdPurchasedRetiredConvertedAndRedeemed,
                AdditionstoOtherAssets: AdditionstoOtherAssets,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                EffectofExchangeRateonCash: EffectofExchangeRateonCash,
                IncreaseDecreaseinCash: IncreaseDecreaseinCash,
                ExtraordinaryItems: ExtraordinaryItems,
                commonDividends: commonDividends,
                PreferredDividends: PreferredDividends,
                FundsFromOperations: FundsFromOperations,
                render: true,
                modalHeader: " Total Sources and Uses -Common Size",
                totalSources: totalSources,
                totalUses: totalUses,
                LabelList: LabelList,
                series: [
                  {
                    name: "Total Sources",
                    type: "bar",
                    data: totalSources,
                  },
                  {
                    name: "Total Uses",
                    type: "bar",
                    data: totalUses,
                  },
                ],
                options: {
                  chart: {
                    height: 350,
                    type: "bar",
                    stacked: false,
                  },

                  grid: {
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                  },
                  title: {
                    text: "Shortterm and longterm debit",
                    align: "left",
                  },
                  stroke: {
                    width: [0, 2, 5],
                    curve: "smooth",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "30%",
                    },
                  },
                  colors: ["#ff7f0e", "#1f77b4"],
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                      colors: ["#7d6608", "#0b5345"],
                      useSeriesColors: true,
                    },
                  },

                  labels: LabelList,
                  markers: {
                    size: 0,
                  },
                  xaxis: {
                    type: "year",
                  },
                  yaxis: {
                    title: {
                      text: "values",
                    },
                    min: 0,
                  },

                  tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return "$ " + y;
                        }
                        return y;
                      },
                    },
                  },
                },
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Total Sources and Uses Year-Year") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService(
        "flowOfFunds/SourceAndUsageYoY",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.fOfSandUYrtoYrList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];
              let totalSources = [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseinReceivables = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsFromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleorIssuanceofStock = [];
              let DisposalofFixedAssets = [];
              let DecreaseinInvestments = [];
              let IncreaseDecreaseinShortTermBorrowings = [];
              let OtherSourcesFinancing = [];
              let CashDividendsPaidTotal = [];
              let IncreaseinInvestments = [];
              let ReductioninLongTermDebt = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let NetAssetsfromAcquisitions = [];
              let ComPfdPurchasedRetiredConvertedAndRedeemed = [];
              let AdditionstoOtherAssets = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let EffectofExchangeRateonCash = [];
              let IncreaseDecreaseinCash = [];
              let ExtraordinaryItems = [];
              let commonDividends = [];
              let PreferredDividends = [];
              let FundsFromOperations = [];
              let totalUses = [];
              let OtherUses = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                totalSources.push(flowOfFundsData[i].totalSources);
                totalUses.push(flowOfFundsData[i].totalUses);
                LabelList.push(flowOfFundsData[i].year);
                OtherUses.push(flowOfFundsData[i].otherUses);
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseinReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsFromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleorIssuanceofStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalofFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseinInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseinShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                IncreaseinInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                ReductioninLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                NetAssetsfromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                ComPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                AdditionstoOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                EffectofExchangeRateonCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseinCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                commonDividends.push(flowOfFundsData[i].commonDividends);
                PreferredDividends.push(flowOfFundsData[i].preferredDividends);
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );

                flowOfFundsData[i].totalSources = this.convertToMillionaire(
                  flowOfFundsData[i].totalSources
                );

                flowOfFundsData[i].otherUses = this.convertToMillionaire(
                  flowOfFundsData[i].otherUses
                );
                flowOfFundsData[i].totalUses = this.convertToMillionaire(
                  flowOfFundsData[i].totalUses
                );
                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].decreaseIncreaseinInventories =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinInventories
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].additionstoOtherAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].additionstoOtherAssets
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].commonDividends = this.convertToMillionaire(
                  flowOfFundsData[i].commonDividends
                );
                flowOfFundsData[i].preferredDividends =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividends
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
              }
              this.setState({
                actual: true,
                common: false,
                flowOfFundsData: flowOfFundsData,
                OtherUses: OtherUses,
                totalSources: totalSources,
                LabelList: LabelList,
                totalUses: totalUses,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseinReceivables: DecreaseIncreaseinReceivables,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsFromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsFromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleorIssuanceofStock:
                  OtherProceedsFromSaleorIssuanceofStock,
                DisposalofFixedAssets: DisposalofFixedAssets,
                DecreaseinInvestments: DecreaseinInvestments,
                IncreaseDecreaseinShortTermBorrowings:
                  IncreaseDecreaseinShortTermBorrowings,
                OtherSourcesFinancing: OtherSourcesFinancing,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                IncreaseinInvestments: IncreaseinInvestments,
                ReductioninLongTermDebt: ReductioninLongTermDebt,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                NetAssetsfromAcquisitions: NetAssetsfromAcquisitions,
                ComPfdPurchasedRetiredConvertedAndRedeemed:
                  ComPfdPurchasedRetiredConvertedAndRedeemed,
                AdditionstoOtherAssets: AdditionstoOtherAssets,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                EffectofExchangeRateonCash: EffectofExchangeRateonCash,
                IncreaseDecreaseinCash: IncreaseDecreaseinCash,
                ExtraordinaryItems: ExtraordinaryItems,
                commonDividends: commonDividends,
                PreferredDividends: PreferredDividends,
                FundsFromOperations: FundsFromOperations,
                render: true,
                modalHeader: " Total Sources and Uses -Yr-yr % change",
                series: [
                  {
                    name: "Total Sources",
                    type: "bar",
                    data: totalSources,
                  },
                  {
                    name: "Total Uses",
                    type: "bar",
                    data: totalUses,
                  },
                ],
                options: {
                  chart: {
                    height: 350,
                    type: "bar",
                    stacked: false,
                  },

                  grid: {
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                  },
                  title: {
                    text: "Shortterm and longterm debit",
                    align: "left",
                  },
                  stroke: {
                    width: [0, 2, 5],
                    curve: "smooth",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "30%",
                    },
                  },
                  colors: ["#ff7f0e", "#1f77b4"],

                  legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                      colors: ["#7d6608", "#0b5345"],
                      useSeriesColors: true,
                    },
                  },

                  labels: LabelList,
                  markers: {
                    size: 0,
                  },
                  xaxis: {
                    type: "year",
                  },
                  yaxis: {
                    title: {
                      text: "values",
                    },
                    min: 0,
                  },

                  tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return "$ " + y;
                        }
                        return y;
                      },
                    },
                  },
                },
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Total Sources and Uses 3-Year") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService(
        "flowOfFunds/SourceAndUsage3YearAverage",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.fOfSandU3YearAvgList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];
              let totalSources = [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseinReceivables = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsFromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleorIssuanceofStock = [];
              let DisposalofFixedAssets = [];
              let DecreaseinInvestments = [];
              let IncreaseDecreaseinShortTermBorrowings = [];
              let OtherSourcesFinancing = [];
              let CashDividendsPaidTotal = [];
              let IncreaseinInvestments = [];
              let ReductioninLongTermDebt = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let NetAssetsfromAcquisitions = [];
              let ComPfdPurchasedRetiredConvertedAndRedeemed = [];
              let AdditionstoOtherAssets = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let EffectofExchangeRateonCash = [];
              let IncreaseDecreaseinCash = [];
              let ExtraordinaryItems = [];
              let commonDividends = [];
              let PreferredDividends = [];
              let FundsFromOperations = [];
              let totalUses = [];
              let OtherUses = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                totalSources.push(flowOfFundsData[i].totalSources);
                totalUses.push(flowOfFundsData[i].totalUses);
                LabelList.push(flowOfFundsData[i].year);
                OtherUses.push(flowOfFundsData[i].otherUses);
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseinReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsFromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleorIssuanceofStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalofFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseinInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseinShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                IncreaseinInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                ReductioninLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                NetAssetsfromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                ComPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                AdditionstoOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                EffectofExchangeRateonCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseinCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                commonDividends.push(flowOfFundsData[i].commonDividends);
                PreferredDividends.push(flowOfFundsData[i].preferredDividends);
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );

                flowOfFundsData[i].totalSources = this.convertToMillionaire(
                  flowOfFundsData[i].totalSources
                );

                flowOfFundsData[i].otherUses = this.convertToMillionaire(
                  flowOfFundsData[i].otherUses
                );
                flowOfFundsData[i].totalUses = this.convertToMillionaire(
                  flowOfFundsData[i].totalUses
                );
                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].decreaseIncreaseinInventories =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinInventories
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].additionstoOtherAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].additionstoOtherAssets
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].commonDividends = this.convertToMillionaire(
                  flowOfFundsData[i].commonDividends
                );
                flowOfFundsData[i].preferredDividends =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividends
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
              }
              this.setState({
                actual: true,
                common: false,
                flowOfFundsData: flowOfFundsData,
                OtherUses: OtherUses,
                totalSources: totalSources,
                LabelList: LabelList,
                totalUses: totalUses,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseinReceivables: DecreaseIncreaseinReceivables,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsFromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsFromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleorIssuanceofStock:
                  OtherProceedsFromSaleorIssuanceofStock,
                DisposalofFixedAssets: DisposalofFixedAssets,
                DecreaseinInvestments: DecreaseinInvestments,
                IncreaseDecreaseinShortTermBorrowings:
                  IncreaseDecreaseinShortTermBorrowings,
                OtherSourcesFinancing: OtherSourcesFinancing,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                IncreaseinInvestments: IncreaseinInvestments,
                ReductioninLongTermDebt: ReductioninLongTermDebt,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                NetAssetsfromAcquisitions: NetAssetsfromAcquisitions,
                ComPfdPurchasedRetiredConvertedAndRedeemed:
                  ComPfdPurchasedRetiredConvertedAndRedeemed,
                AdditionstoOtherAssets: AdditionstoOtherAssets,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                EffectofExchangeRateonCash: EffectofExchangeRateonCash,
                IncreaseDecreaseinCash: IncreaseDecreaseinCash,
                ExtraordinaryItems: ExtraordinaryItems,
                commonDividends: commonDividends,
                PreferredDividends: PreferredDividends,
                FundsFromOperations: FundsFromOperations,
                render: true,
                modalHeader: "Total Sources and Uses -3 yr Averages",
                series: [
                  {
                    name: "Total Sources",
                    type: "bar",
                    data: totalSources,
                  },
                  {
                    name: "Total Uses",
                    type: "bar",
                    data: totalUses,
                  },
                ],
                options: {
                  chart: {
                    height: 350,
                    type: "bar",
                    stacked: false,
                  },

                  grid: {
                    yaxis: {
                      lines: {
                        show: false,
                      },
                    },
                  },
                  title: {
                    text: "Shortterm and longterm debit",
                    align: "left",
                  },
                  stroke: {
                    width: [0, 2, 5],
                    curve: "smooth",
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "30%",
                    },
                  },
                  colors: ["#ff7f0e", "#1f77b4"],

                  legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    fontSize: "14px",
                    fontFamily: "Helvetica, Arial",
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                      colors: ["#7d6608", "#0b5345"],
                      useSeriesColors: true,
                    },
                  },

                  labels: LabelList,
                  markers: {
                    size: 0,
                  },
                  xaxis: {
                    type: "year",
                  },
                  yaxis: {
                    title: {
                      text: "values",
                    },
                    min: 0,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return "$ " + y;
                        }
                        return y;
                      },
                    },
                  },
                },
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService(
        "flowOfFunds/SourceAndUsageActual",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            this.currency = data.currency;
            this.country = data.country;
            let LabelList = [];
            let totalSources = [];
            let OtherUses = [];
            let FundsFromForOtherOperatingActivities = [];
            let DecreaseIncreaseinReceivables = [];
            let IncreaseDecreaseInAccountsPayable = [];
            let DecreaseIncreaseinOtherAssetsLiabilities = [];
            let LongTermBorrowings = [];
            let NetProceedsFromSaleorIssuanceofCommonAndPreferred = [];
            let ProceedsFromStockOptions = [];
            let OtherProceedsFromSaleorIssuanceofStock = [];
            let DisposalofFixedAssets = [];
            let DecreaseinInvestments = [];
            let IncreaseDecreaseinShortTermBorrowings = [];
            let OtherSourcesFinancing = [];
            let CashDividendsPaidTotal = [];
            let IncreaseinInvestments = [];
            let ReductioninLongTermDebt = [];
            let CapitalExpendituresAdditionstoFixedAssets = [];
            let NetAssetsfromAcquisitions = [];
            let ComPfdPurchasedRetiredConvertedAndRedeemed = [];
            let AdditionstoOtherAssets = [];
            let OtherUsesInvesting = [];
            let OtherUsesFinancing = [];
            let EffectofExchangeRateonCash = [];
            let IncreaseDecreaseinCash = [];
            let ExtraordinaryItems = [];
            let commonDividends = [];
            let PreferredDividends = [];
            let FundsFromOperations = [];
            let totalUses = [];
            let flowOfFundsData = data.fOfSandUActualList;
            if (flowOfFundsData.length > 0) {
              flowOfFundsData.reverse();
            }
            for (let i = 0; i < flowOfFundsData.length; i++) {
              totalSources.push(flowOfFundsData[i].totalSources);
              totalUses.push(flowOfFundsData[i].totalUses);
              LabelList.push(flowOfFundsData[i].year);
              OtherUses.push(flowOfFundsData[i].otherUses);
              FundsFromForOtherOperatingActivities.push(
                flowOfFundsData[i].fundsfromforOtherOperatingActivities
              );
              DecreaseIncreaseinReceivables.push(
                flowOfFundsData[i].decreaseIncreaseinReceivables
              );
              IncreaseDecreaseInAccountsPayable.push(
                flowOfFundsData[i].increaseDecreaseinAccountsPayable
              );
              DecreaseIncreaseinOtherAssetsLiabilities.push(
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
              );
              LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
              NetProceedsFromSaleorIssuanceofCommonAndPreferred.push(
                flowOfFundsData[i]
                  .netProceedsfromSaleorIssuanceofCommonAndPreferred
              );
              ProceedsFromStockOptions.push(
                flowOfFundsData[i].proceedsfromStockOptions
              );
              OtherProceedsFromSaleorIssuanceofStock.push(
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
              );
              DisposalofFixedAssets.push(
                flowOfFundsData[i].disposalofFixedAssets
              );
              DecreaseinInvestments.push(
                flowOfFundsData[i].decreaseinInvestments
              );
              IncreaseDecreaseinShortTermBorrowings.push(
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
              );
              OtherSourcesFinancing.push(
                flowOfFundsData[i].otherSourcesFinancing
              );
              CashDividendsPaidTotal.push(
                flowOfFundsData[i].cashDividendsPaidTotal
              );
              IncreaseinInvestments.push(
                flowOfFundsData[i].increaseinInvestments
              );
              ReductioninLongTermDebt.push(
                flowOfFundsData[i].reductioninLongTermDebt
              );
              CapitalExpendituresAdditionstoFixedAssets.push(
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
              );
              NetAssetsfromAcquisitions.push(
                flowOfFundsData[i].netAssetsfromAcquisitions
              );
              ComPfdPurchasedRetiredConvertedAndRedeemed.push(
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
              );
              AdditionstoOtherAssets.push(
                flowOfFundsData[i].additionstoOtherAssets
              );
              OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
              OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
              EffectofExchangeRateonCash.push(
                flowOfFundsData[i].effectofExchangeRateonCash
              );
              IncreaseDecreaseinCash.push(
                flowOfFundsData[i].increaseDecreaseinCash
              );
              ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
              commonDividends.push(flowOfFundsData[i].commonDividends);
              PreferredDividends.push(flowOfFundsData[i].preferredDividends);
              FundsFromOperations.push(flowOfFundsData[i].fundsfromOperations);

              flowOfFundsData[i].totalSources = this.convertToMillionaire(
                flowOfFundsData[i].totalSources
              );

              flowOfFundsData[i].otherUses = this.convertToMillionaire(
                flowOfFundsData[i].otherUses
              );
              flowOfFundsData[i].totalUses = this.convertToMillionaire(
                flowOfFundsData[i].totalUses
              );
              flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
              flowOfFundsData[i].decreaseIncreaseinReceivables =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
              flowOfFundsData[i].decreaseIncreaseinInventories =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseIncreaseinInventories
                );
              flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
              flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
              flowOfFundsData[i].longTermBorrowings = this.convertToMillionaire(
                flowOfFundsData[i].longTermBorrowings
              );
              flowOfFundsData[
                i
              ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                flowOfFundsData[i]
                  .netProceedsfromSaleorIssuanceofCommonAndPreferred
              );
              flowOfFundsData[i].proceedsfromStockOptions =
                this.convertToMillionaire(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
              flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
              flowOfFundsData[i].disposalofFixedAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].disposalofFixedAssets
                );
              flowOfFundsData[i].decreaseinInvestments =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseinInvestments
                );
              flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
              flowOfFundsData[i].otherSourcesFinancing =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherSourcesFinancing
                );
              flowOfFundsData[i].otherSourcesInvesting =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherSourcesInvesting
                );
              flowOfFundsData[i].cashDividendsPaidTotal =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
              flowOfFundsData[i].increaseinInvestments =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseinInvestments
                );
              flowOfFundsData[i].reductioninLongTermDebt =
                this.convertToMillionaire(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
              flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
              flowOfFundsData[i].netAssetsfromAcquisitions =
                this.convertToMillionaire(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
              flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                this.convertToMillionaire(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
              flowOfFundsData[i].additionstoOtherAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].additionstoOtherAssets
                );
              flowOfFundsData[i].otherUsesInvesting = this.convertToMillionaire(
                flowOfFundsData[i].otherUsesInvesting
              );
              flowOfFundsData[i].otherUsesFinancing = this.convertToMillionaire(
                flowOfFundsData[i].otherUsesFinancing
              );
              flowOfFundsData[i].effectofExchangeRateonCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
              flowOfFundsData[i].increaseDecreaseinCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
              flowOfFundsData[i].extraordinaryItems = this.convertToMillionaire(
                flowOfFundsData[i].extraordinaryItems
              );
              flowOfFundsData[i].commonDividends = this.convertToMillionaire(
                flowOfFundsData[i].commonDividends
              );
              flowOfFundsData[i].preferredDividends = this.convertToMillionaire(
                flowOfFundsData[i].preferredDividends
              );
              flowOfFundsData[i].fundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromOperations
                );
            }

            this.setState({
              actual: true,
              common: false,
              flowOfFundsData: flowOfFundsData,
              OtherUses: OtherUses,
              totalSources: totalSources,
              LabelList: LabelList,
              totalUses: totalUses,
              FundsFromForOtherOperatingActivities:
                FundsFromForOtherOperatingActivities,
              DecreaseIncreaseinReceivables: DecreaseIncreaseinReceivables,
              IncreaseDecreaseInAccountsPayable:
                IncreaseDecreaseInAccountsPayable,
              DecreaseIncreaseinOtherAssetsLiabilities:
                DecreaseIncreaseinOtherAssetsLiabilities,
              LongTermBorrowings: LongTermBorrowings,
              NetProceedsFromSaleorIssuanceofCommonAndPreferred:
                NetProceedsFromSaleorIssuanceofCommonAndPreferred,
              ProceedsFromStockOptions: ProceedsFromStockOptions,
              OtherProceedsFromSaleorIssuanceofStock:
                OtherProceedsFromSaleorIssuanceofStock,
              DisposalofFixedAssets: DisposalofFixedAssets,
              DecreaseinInvestments: DecreaseinInvestments,
              IncreaseDecreaseinShortTermBorrowings:
                IncreaseDecreaseinShortTermBorrowings,
              OtherSourcesFinancing: OtherSourcesFinancing,
              CashDividendsPaidTotal: CashDividendsPaidTotal,
              IncreaseinInvestments: IncreaseinInvestments,
              ReductioninLongTermDebt: ReductioninLongTermDebt,
              CapitalExpendituresAdditionstoFixedAssets:
                CapitalExpendituresAdditionstoFixedAssets,
              NetAssetsfromAcquisitions: NetAssetsfromAcquisitions,
              ComPfdPurchasedRetiredConvertedAndRedeemed:
                ComPfdPurchasedRetiredConvertedAndRedeemed,
              AdditionstoOtherAssets: AdditionstoOtherAssets,
              OtherUsesInvesting: OtherUsesInvesting,
              OtherUsesFinancing: OtherUsesFinancing,
              EffectofExchangeRateonCash: EffectofExchangeRateonCash,
              IncreaseDecreaseinCash: IncreaseDecreaseinCash,
              ExtraordinaryItems: ExtraordinaryItems,
              commonDividends: commonDividends,
              PreferredDividends: PreferredDividends,
              FundsFromOperations: FundsFromOperations,
              render: true,
              series: [
                {
                  name: "Total Uses",
                  type: "bar",
                  data: totalUses,
                },
                {
                  name: "Total Sources",
                  type: "bar",
                  data: totalSources,
                },
              ],
              options: {
                chart: {
                  height: 350,
                  type: "bar",
                  stacked: false,
                },
                dataLabels: {
                  enabled: false,
                },
                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                title: {
                  text: "Shortterm and longterm debit",
                  align: "left",
                },
                stroke: {
                  width: [0, 2, 5],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    columnWidth: "30%",
                  },
                },
                colors: ["#ff7f0e", "#1f77b4"],

                legend: {
                  show: true,
                  showForSingleSeries: false,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "bottom",
                  horizontalAlign: "center",
                  floating: false,
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial",
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  offsetX: 0,
                  offsetY: 0,
                  labels: {
                    colors: ["#7d6608", "#0b5345"],
                    useSeriesColors: true,
                  },
                },

                labels: LabelList,
                markers: {
                  size: 0,
                },
                xaxis: {
                  type: "year",
                },
                yaxis: {
                  title: {
                    text: "values",
                  },
                  min: 0,
                },

                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return "$ " + y;
                      }
                      return y;
                    },
                  },
                },
              },
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Sources") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalSources,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Sources",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Uses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalUses,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Uses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUses,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Decrease/(Increase) in Receivables") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseIncreaseinReceivables,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease/(Increase) in Receivables",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/(Decrease) In Accounts Payable") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInAccountsPayable,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/(Decrease) In Accounts Payable",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Decrease/(Increase) in Other Assets Liabilities"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseIncreaseinOtherAssetsLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease/(Increase) in Other Assets Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Borrowings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermBorrowings,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Borrowings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Proceeds From Sale or Issuance of Common And Preferred"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.NetProceedsFromSaleorIssuanceofCommonAndPreferred,
          labelData: this.state.LabelList,
          yAxisTitle:
            "TNet Proceeds From Sale or Issuance of Common And Preferred",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Proceeds From Stock Options") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ProceedsFromStockOptions,
          labelData: this.state.LabelList,
          yAxisTitle: "Proceeds From Stock Options",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Proceeds From Sale or Issuance of Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherProceedsFromSaleorIssuanceofStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Proceeds From Sale or Issuance of Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Disposal of Fixed Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DisposalofFixedAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Disposal of Fixed Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Decrease in Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseinInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease in Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/(Decrease) in Short Term Borrowings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseinShortTermBorrowings,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/(Decrease) in Short Term Borrowings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Sources Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherSourcesFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Sources Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Dividends Paid Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashDividendsPaidTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Dividends Paid Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase in Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseinInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase in Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Reduction in Long Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReductioninLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Reduction in Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capital Expenditures Additions to Fixed Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CapitalExpendituresAdditionstoFixedAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Capital Expenditures Additions to Fixed Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Assets from Acquisitions") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetAssetsfromAcquisitions,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Assets from Acquisitions",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "ComPfd Purchased Retired Converted And Redeemed"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.ComPfdPurchasedRetiredConvertedAndRedeemed,
          labelData: this.state.LabelList,
          yAxisTitle: "ComPfd Purchased Retired Converted And Redeemed",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Additionsto Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AdditionstoOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Additionsto Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses Investing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUsesInvesting,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses Investing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUsesFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Effect of Exchange Rate on Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EffectofExchangeRateonCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Effect of Exchange Rate on Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/Decrease in Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseinCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/Decrease in Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Items") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ExtraordinaryItems,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Items",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Dividends") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonDividends,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Dividends") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredDividends,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from/for Other Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromForOtherOperatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from/for Other Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-3em" }}>
            <Col md={12} style={{ padding: "0em 0em 0.3em 2.9em" }}>
              <Row>
                <Col md={8} style={{ padding: "8px 10px" }}>
                  {this.state.actual && (
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  )}

                  {this.state.common && (
                    <>
                      <span className="unit_str">
                        {" "}
                        Figures are expressed as Percent of Total Source
                      </span>
                      <br></br>
                    </>
                  )}
                </Col>

                <Col md={4}></Col>
              </Row>
              <Table responsive className="balance_sheet">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td style={{ fontWeight: "bold" }}>{data.year}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEnd}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Total Sources</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.totalSources}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.fundsfromOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.extraordinaryItems}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Funds from/For Other Activities{" "}
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fundsfromforOtherOperatingActivities}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Total Uses</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.totalUses}</td>;
                  })}
                </tr>

                <tr>
                  <th>Other Uses </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherUses}</td>;
                  })}
                </tr>

                <tr>
                  <th>Decrease/Increasein Receivables </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseIncreaseinReceivables}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease/(Increase) in Inventories </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseIncreaseinInventories}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Income Taxes Payable</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinIncomeTaxesPayable}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Other Accruals</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinOtherAccruals}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) In Accounts Payable </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinAccountsPayable}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease/Increase in Other Assets Liabilities </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.decreaseIncreaseinOtherAssetsLiabilities}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Borrowings </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.longTermBorrowings}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Proceeds From Sale or Issuance of Common And Preferred
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.netProceedsfromSaleorIssuanceofCommonAndPreferred}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Proceeds From Stock Options </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.proceedsfromStockOptions}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Proceeds From Sale or Issuance of Stock </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.otherProceedsfromSaleorIssuanceofStock}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Disposal of Fixed Assets </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.disposalofFixedAssets}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease in Investments </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseinInvestments}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Short Term Borrowings </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.increaseDecreaseinShortTermBorrowings}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Effect of Exchange Rate on Cash </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.effectofExchangeRateonCash}</td>;
                  })}
                </tr>
                <tr>
                  <th>Changes in Cash and/or Liquid Items </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.changesinCashandorLiquidItems}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Cash</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinCash}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources - Financing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSourcesFinancing}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources - Investing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSourcesInvesting}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSources}</td>;
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px 10px" }}>
                    {this.state.actual && (
                      <span className="unit_str">
                        All figures in {this.currency}
                      </span>
                    )}

                    {this.state.common && (
                      <>
                        <span className="unit_str">
                          {" "}
                          Figures are expressed as Percent of Total Source
                        </span>
                        <br></br>
                      </>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.year}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEnd}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Sources
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Sources")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalSources}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Funds From Operations")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.fundsfromOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Extraordinary Items{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Extraordinary Items")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.extraordinaryItems}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Funds from/for Other Activities{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds from/for Other Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fundsfromforOtherOperatingActivities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Uses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Uses")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.totalUses}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>
                      Other Uses{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Uses")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherUses}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>
                      Decrease/(Increase) in Receivables{" "}
                      {/* Decrease / Increase in Receivables{" "} */}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Decrease/(Increase) in Receivables"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseIncreaseinReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Decrease/(Increase) in Inventories </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseIncreaseinInventories}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Increase/(Decrease) in Income Taxes Payable</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.increaseDecreaseinIncomeTaxesPayable}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Increase/(Decrease) in Other Accruals</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinOtherAccruals}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Increase/(Decrease) In Accounts Payable{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Increase/(Decrease) In Accounts Payable"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinAccountsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease/(Increase) in Other Assets Liabilities{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Decrease/(Increase) in Other Assets Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.decreaseIncreaseinOtherAssetsLiabilities}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Borrowings </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.longTermBorrowings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Proceeds From Sale or Issuance of Common And Preferred
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {
                            data.netProceedsfromSaleorIssuanceofCommonAndPreferred
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Proceeds From Stock Options </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.proceedsfromStockOptions}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Proceeds From Sale or Issuance of Stock </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.otherProceedsfromSaleorIssuanceofStock}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Disposal of Fixed Assets </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.disposalofFixedAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease in Investments{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Decrease in Investments")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseinInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Increase/(Decrease) in Short Term Borrowings{" "}
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Increase/(Decrease) in Short Term Borrowings"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.increaseDecreaseinShortTermBorrowings}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Effect of Exchange Rate on Cash </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.effectofExchangeRateonCash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Changes in Cash and/or Liquid Items </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.changesinCashandorLiquidItems}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Increase/(Decrease) in Cash</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinCash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources - Financing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSourcesFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources - Investing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSourcesInvesting}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSources}</td>;
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                      type="flowOfFunds"
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
