import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./IndBalanceSheet.css";
export default class IndBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      heading: props.heading,
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      balanceSheetData: [],
      render: false,
      isShortTerm: false,
      isLongTerm: false,
      showModal: false,
      modalHeader: "",
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      totalAssets: [],
      cashAndShortTermInvestments: [],
      Receivables: [],
      Inventories: [],
      currentAssets: [],
      TotalLiabilitiesAndShareholdersEquity: [],
      totalLiabilities: [],
      commonEquity: [],
      noYrToYr: true,
      isYrToYr: false,
      isCommonSize: false,
    };
 
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  getDataInGraphicalForm(graphType, tab) {
    if (tab === "Income Statement Actual") {
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < this.state.balanceSheetData.length; i++) {
        longTermDebt.push(this.state.balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          this.state.balanceSheetData[i]
            .shortTermDebtCurrentPortionofLongTermDebt
        );

        LabelList.push(this.state.balanceSheetData[i].fiscalYear);
        totalAssets.push(this.state.balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          this.state.balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(this.state.balanceSheetData[i].receivablesNet);
        Inventories.push(this.state.balanceSheetData[i].inventoriesTotal);
        currentAssets.push(this.state.balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          this.state.balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(
          this.state.balanceSheetData[i].totalLiabilities
        );
        commonEquity.push(this.state.balanceSheetData[i].commonEquity);
      }
      if (graphType === "Total Assets") {
        this.setState({
          graphDetails: {
            graphValues: totalAssets,
            labelData: LabelList,
            yAxisTitle: "Total Assets",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Receivables") {
        this.setState({
          graphDetails: {
            graphValues: Receivables,
            labelData: LabelList,
            yAxisTitle: "Receivables (Net)",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cash & Short Term Investments") {
        this.setState({
          graphDetails: {
            graphValues: cashAndShortTermInvestments,
            labelData: LabelList,
            yAxisTitle: "Cash & Short Term Investments",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Inventories") {
        this.setState({
          graphDetails: {
            graphValues: Inventories,
            labelData: LabelList,
            yAxisTitle: "Inventories -Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Current Assets") {
        this.setState({
          graphDetails: {
            graphValues: currentAssets,
            labelData: LabelList,
            yAxisTitle: "Current Assets - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities & Shareholders' Equity") {
        this.setState({
          graphDetails: {
            graphValues: TotalLiabilitiesAndShareholdersEquity,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities & Shareholders' Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "STD") {
        this.setState({
          graphDetails: {
            graphValues: shortTermDebt,
            labelData: LabelList,
            yAxisTitle: "Short Term Debt & Current Portion of Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "LTD") {
        this.setState({
          graphDetails: {
            graphValues: longTermDebt,
            labelData: LabelList,
            yAxisTitle: "Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities") {
        this.setState({
          graphDetails: {
            graphValues: totalLiabilities,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Common Equity") {
        this.setState({
          graphDetails: {
            graphValues: commonEquity,
            labelData: LabelList,
            yAxisTitle: "Common Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else if (tab === "Income Statement Common Size") {
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < this.state.balanceSheetData.length; i++) {
        longTermDebt.push(this.state.balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          this.state.balanceSheetData[i]
            .shortTermDebtCurrentPortionofLongTermDebt
        );

        LabelList.push(this.state.balanceSheetData[i].fiscalYear);
        totalAssets.push(this.state.balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          this.state.balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(this.state.balanceSheetData[i].receivablesNet);
        Inventories.push(this.state.balanceSheetData[i].inventoriesTotal);
        currentAssets.push(this.state.balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          this.state.balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(
          this.state.balanceSheetData[i].totalLiabilitiesPercent
        );
        commonEquity.push(this.state.balanceSheetData[i].commonEquity);
      }
      if (graphType === "Total Assets") {
        this.setState({
          graphDetails: {
            graphValues: totalAssets,
            labelData: LabelList,
            yAxisTitle: "Total Assets",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Receivables") {
        this.setState({
          graphDetails: {
            graphValues: Receivables,
            labelData: LabelList,
            yAxisTitle: "Receivables (Net)",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cash & Short Term Investments") {
        this.setState({
          graphDetails: {
            graphValues: cashAndShortTermInvestments,
            labelData: LabelList,
            yAxisTitle: "Cash & Short Term Investments",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Inventories") {
        this.setState({
          graphDetails: {
            graphValues: Inventories,
            labelData: LabelList,
            yAxisTitle: "Inventories -Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Current Assets") {
        this.setState({
          graphDetails: {
            graphValues: currentAssets,
            labelData: LabelList,
            yAxisTitle: "Current Assets - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities & Shareholders' Equity") {
        this.setState({
          graphDetails: {
            graphValues: TotalLiabilitiesAndShareholdersEquity,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities & Shareholders' Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "STD") {
        this.setState({
          graphDetails: {
            graphValues: shortTermDebt,
            labelData: LabelList,
            yAxisTitle: "Short Term Debt & Current Portion of Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "LTD") {
        this.setState({
          graphDetails: {
            graphValues: longTermDebt,
            labelData: LabelList,
            yAxisTitle: "Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities") {
        this.setState({
          graphDetails: {
            graphValues: totalLiabilities,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Common Equity") {
        this.setState({
          graphDetails: {
            graphValues: commonEquity,
            labelData: LabelList,
            yAxisTitle: "Common Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else if (tab === "Income Statement Year-Year") {
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < this.state.balanceSheetData.length; i++) {
        longTermDebt.push(this.state.balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          this.state.balanceSheetData[i]
            .shortTermDebtCurrentPortionofLongTermDebt
        );

        LabelList.push(this.state.balanceSheetData[i].fiscalYear);
        totalAssets.push(this.state.balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          this.state.balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(this.state.balanceSheetData[i].receivablesNet);
        Inventories.push(this.state.balanceSheetData[i].inventoriesTotal);
        currentAssets.push(this.state.balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          this.state.balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(
          this.state.balanceSheetData[i].totalLiabilitiesPercent
        );
        commonEquity.push(this.state.balanceSheetData[i].commonEquity);
      }
      if (graphType === "Total Assets") {
        this.setState({
          graphDetails: {
            graphValues: totalAssets,
            labelData: LabelList,
            yAxisTitle: "Total Assets",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Receivables") {
        this.setState({
          graphDetails: {
            graphValues: Receivables,
            labelData: LabelList,
            yAxisTitle: "Receivables (Net)",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cash & Short Term Investments") {
        this.setState({
          graphDetails: {
            graphValues: cashAndShortTermInvestments,
            labelData: LabelList,
            yAxisTitle: "Cash & Short Term Investments",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Inventories") {
        this.setState({
          graphDetails: {
            graphValues: Inventories,
            labelData: LabelList,
            yAxisTitle: "Inventories -Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Current Assets") {
        this.setState({
          graphDetails: {
            graphValues: currentAssets,
            labelData: LabelList,
            yAxisTitle: "Current Assets - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities & Shareholders' Equity") {
        this.setState({
          graphDetails: {
            graphValues: TotalLiabilitiesAndShareholdersEquity,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities & Shareholders' Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "STD") {
        this.setState({
          graphDetails: {
            graphValues: shortTermDebt,
            labelData: LabelList,
            yAxisTitle: "Short Term Debt & Current Portion of Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "LTD") {
        this.setState({
          graphDetails: {
            graphValues: longTermDebt,
            labelData: LabelList,
            yAxisTitle: "Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities") {
        this.setState({
          graphDetails: {
            graphValues: totalLiabilities,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Common Equity") {
        this.setState({
          graphDetails: {
            graphValues: commonEquity,
            labelData: LabelList,
            yAxisTitle: "Common Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else {
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < this.state.balanceSheetData.length; i++) {
        longTermDebt.push(this.state.balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          this.state.balanceSheetData[i]
            .shortTermDebtCurrentPortionofLongTermDebt
        );

        LabelList.push(this.state.balanceSheetData[i].fiscalYear);
        totalAssets.push(this.state.balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          this.state.balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(this.state.balanceSheetData[i].receivablesNet);
        Inventories.push(this.state.balanceSheetData[i].inventoriesTotal);
        currentAssets.push(this.state.balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          this.state.balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(
          this.state.balanceSheetData[i].totalLiabilitiesPercent
        );
        commonEquity.push(this.state.balanceSheetData[i].commonEquity);
      }
      if (graphType === "Total Assets") {
        this.setState({
          graphDetails: {
            graphValues: totalAssets,
            labelData: LabelList,
            yAxisTitle: "Total Assets",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Receivables") {
        this.setState({
          graphDetails: {
            graphValues: Receivables,
            labelData: LabelList,
            yAxisTitle: "Receivables (Net)",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cash & Short Term Investments") {
        this.setState({
          graphDetails: {
            graphValues: cashAndShortTermInvestments,
            labelData: LabelList,
            yAxisTitle: "Cash & Short Term Investments",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Inventories") {
        this.setState({
          graphDetails: {
            graphValues: Inventories,
            labelData: LabelList,
            yAxisTitle: "Inventories -Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Current Assets") {
        this.setState({
          graphDetails: {
            graphValues: currentAssets,
            labelData: LabelList,
            yAxisTitle: "Current Assets - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities & Shareholders' Equity") {
        this.setState({
          graphDetails: {
            graphValues: TotalLiabilitiesAndShareholdersEquity,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities & Shareholders' Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "STD") {
        this.setState({
          graphDetails: {
            graphValues: shortTermDebt,
            labelData: LabelList,
            yAxisTitle: "Short Term Debt & Current Portion of Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "LTD") {
        this.setState({
          graphDetails: {
            graphValues: longTermDebt,
            labelData: LabelList,
            yAxisTitle: "Long Term Debt",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Total Liabilities") {
        this.setState({
          graphDetails: {
            graphValues: totalLiabilities,
            labelData: LabelList,
            yAxisTitle: "Total Liabilities",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Common Equity") {
        this.setState({
          graphDetails: {
            graphValues: commonEquity,
            labelData: LabelList,
            yAxisTitle: "Common Equity",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    }
  }

  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.data = [];
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      let balanceSheetData = this.state.data.indBalanceSheetList;

      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].year);
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        totalAssets.push(balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(balanceSheetData[i].receivablesNet);
        Inventories.push(balanceSheetData[i].inventoriesTotal);
        currentAssets.push(balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(balanceSheetData[i].totalLiabilitiesPercent);
        commonEquity.push(balanceSheetData[i].commonEquity);

        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].rawMaterials = this.convertToMillionaire(
          balanceSheetData[i].rawMaterials
        );
        balanceSheetData[i].workinProcess = this.convertToMillionaire(
          balanceSheetData[i].workinProcess
        );
        balanceSheetData[i].finishedGoods = this.convertToMillionaire(
          balanceSheetData[i].finishedGoods
        );
        balanceSheetData[i].progressPaymentsOther = this.convertToMillionaire(
          balanceSheetData[i].progressPaymentsOther
        );
        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire(
          balanceSheetData[i].prepaidExpenses
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].currentAssetsTotal = this.convertToMillionaire(
          balanceSheetData[i].currentAssetsTotal
        );
        balanceSheetData[i].longTermReceivables = this.convertToMillionaire(
          balanceSheetData[i].longTermReceivables
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
        balanceSheetData[i].accountsPayable = this.convertToMillionaire(
          balanceSheetData[i].accountsPayable
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].accruedPayroll = this.convertToMillionaire(
          balanceSheetData[i].accruedPayroll
        );
        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire(
          balanceSheetData[i].incomeTaxesPayable
        );
        balanceSheetData[i].dividendsPayable = this.convertToMillionaire(
          balanceSheetData[i].dividendsPayable
        );
        balanceSheetData[i].otherCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentLiabilities
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilitiesPercent = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilitiesPercent
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        shortTermDebt: shortTermDebt,
        totalAssets: totalAssets,
        cashAndShortTermInvestments: cashAndShortTermInvestments,
        Receivables: Receivables,
        Inventories: Inventories,
        currentAssets: currentAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        totalLiabilities: totalLiabilities,
        commonEquity: commonEquity,
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        tableGraphSeries1: [
          {
            data: shortTermDebt,
          },
        ],
        tableGraphOptions1: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
      });
    } else if (this.state.type === "Income Statement Common Size") {
      // let balanceSheetData = this.state.data.indBalanceSheetYoYChangeList;
      let balanceSheetData = this.state.data.indBalanceSheetPercentChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(balanceSheetData[i].receivablesNet);
        Inventories.push(balanceSheetData[i].inventoriesTotal);
        currentAssets.push(balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(balanceSheetData[i].totalLiabilitiesPercent);
        commonEquity.push(balanceSheetData[i].commonEquity);
        this.convertToMillionaire1(
          (balanceSheetData[i].totalAssets = balanceSheetData[i].totalAssets)
        );
        balanceSheetData[i].totalAsset = "100%";
        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire1(
            balanceSheetData[i].cashShortTermInvestments
          );

        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );

        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire1(
          balanceSheetData[i].shortTermInvestments
        );

        balanceSheetData[i].receivablesNet = this.convertToMillionaire1(
          balanceSheetData[i].receivablesNet
        );

        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire1(
          balanceSheetData[i].inventoriesTotal
        );

        balanceSheetData[i].rawMaterials = this.convertToMillionaire1(
          balanceSheetData[i].rawMaterials
        );

        balanceSheetData[i].workinProcess = this.convertToMillionaire1(
          balanceSheetData[i].workinProcess
        );

        balanceSheetData[i].finishedGoods = this.convertToMillionaire1(
          balanceSheetData[i].finishedGoods
        );

        balanceSheetData[i].progressPaymentsOther = this.convertToMillionaire1(
          balanceSheetData[i].progressPaymentsOther
        );

        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire1(
          balanceSheetData[i].prepaidExpenses
        );

        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherCurrentAssets
        );

        balanceSheetData[i].currentAssetsTotal = this.convertToMillionaire1(
          balanceSheetData[i].currentAssetsTotal
        );

        balanceSheetData[i].longTermReceivables = this.convertToMillionaire1(
          balanceSheetData[i].longTermReceivables
        );

        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinAssociatedCompanies
          );

        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );

        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );

        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );

        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );

        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );

        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );

        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          balanceSheetData[i].totalLiabilitiesShareholdersEquity;

        balanceSheetData[i].accountsPayable = this.convertToMillionaire1(
          balanceSheetData[i].accountsPayable
        );

        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );

        balanceSheetData[i].accruedPayroll = this.convertToMillionaire1(
          balanceSheetData[i].accruedPayroll
        );

        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire1(
          balanceSheetData[i].incomeTaxesPayable
        );

        balanceSheetData[i].dividendsPayable = this.convertToMillionaire1(
          balanceSheetData[i].dividendsPayable
        );

        balanceSheetData[i].otherCurrentLiabilities =
          this.convertToMillionaire1(
            balanceSheetData[i].otherCurrentLiabilities
          );

        balanceSheetData[i].currentLiabilitiesTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].currentLiabilitiesTotal
          );

        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );

        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );

        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );

        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );

        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );

        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );

        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );

        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );

        balanceSheetData[i].totalLiabilitiesPercent =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesPercent
          );

        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );

        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );

        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );

        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );

        balanceSheetData[i].esopguaranteesPreferredIssued =
          balanceSheetData[i].esopguaranteesPreferredIssued;

        balanceSheetData[i].commonEquity = balanceSheetData[i].commonEquity;

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          balanceSheetData[i].totalLiabilitiesShareholdersEquity;
      }
      // this.data = balanceSheetData;
      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        shortTermDebt: shortTermDebt,
        totalAssets: totalAssets,
        cashAndShortTermInvestments: cashAndShortTermInvestments,
        Receivables: Receivables,
        Inventories: Inventories,
        currentAssets: currentAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        totalLiabilities: totalLiabilities,
        commonEquity: commonEquity,
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        tableGraphSeries1: [
          {
            data: shortTermDebt,
          },
        ],
        tableGraphOptions1: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
        isCommonSize: true,
        noYrToYr: false,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Common Size",
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      // let balanceSheetData = this.state.data.indBalanceSheetPercentChangeList;
      let balanceSheetData = this.state.data.indBalanceSheetYoYChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(balanceSheetData[i].receivablesNet);
        Inventories.push(balanceSheetData[i].inventoriesTotal);
        currentAssets.push(balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(balanceSheetData[i].totalLiabilitiesPercent);
        commonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire1(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire1(
            balanceSheetData[i].cashShortTermInvestments
          );

        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );

        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire1(
          balanceSheetData[i].shortTermInvestments
        );

        balanceSheetData[i].receivablesNet = this.convertToMillionaire1(
          balanceSheetData[i].receivablesNet
        );


        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire1(
          balanceSheetData[i].inventoriesTotal
        );

        balanceSheetData[i].rawMaterials = this.convertToMillionaire1(
          balanceSheetData[i].rawMaterials
        );

        balanceSheetData[i].workinProcess = this.convertToMillionaire1(
          balanceSheetData[i].workinProcess
        );

        balanceSheetData[i].finishedGoods = this.convertToMillionaire1(
          balanceSheetData[i].finishedGoods
        );

        balanceSheetData[i].progressPaymentsOther = this.convertToMillionaire1(
          balanceSheetData[i].progressPaymentsOther
        );

        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire1(
          balanceSheetData[i].prepaidExpenses
        );

        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherCurrentAssets
        );

        balanceSheetData[i].currentAssetsTotal = this.convertToMillionaire1(
          balanceSheetData[i].currentAssetsTotal
        );

        balanceSheetData[i].longTermReceivables = this.convertToMillionaire1(
          balanceSheetData[i].longTermReceivables
        );

        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinAssociatedCompanies
          );

        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );

        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );

        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );

        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );

        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );

        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );

        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].accountsPayable = this.convertToMillionaire1(
          balanceSheetData[i].accountsPayable
        );

        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );

        balanceSheetData[i].accruedPayroll = this.convertToMillionaire1(
          balanceSheetData[i].accruedPayroll
        );

        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire1(
          balanceSheetData[i].incomeTaxesPayable
        );

        balanceSheetData[i].dividendsPayable = this.convertToMillionaire1(
          balanceSheetData[i].dividendsPayable
        );

        balanceSheetData[i].otherCurrentLiabilities =
          this.convertToMillionaire1(
            balanceSheetData[i].otherCurrentLiabilities
          );

        balanceSheetData[i].currentLiabilitiesTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].currentLiabilitiesTotal
          );

        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );

        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );

        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );

        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );

        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );

        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );

        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );

        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );

        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );

        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );

        balanceSheetData[i].totalLiabilitiesPercent =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesPercent
          );

        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );

        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );

        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );

        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );

        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        shortTermDebt: shortTermDebt,
        totalAssets: totalAssets,
        cashAndShortTermInvestments: cashAndShortTermInvestments,
        Receivables: Receivables,
        Inventories: Inventories,
        currentAssets: currentAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        totalLiabilities: totalLiabilities,
        commonEquity: commonEquity,
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        tableGraphSeries1: [
          {
            data: shortTermDebt,
          },
        ],
        tableGraphOptions1: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
        noYrToYr: false,
        isCommonSize: false,
        isYrToYr: true,
        modalHeader: " Balance Sheet -Yr-yr % change",
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      let balanceSheetData = this.state.data.indBalanceSheet3YearAverageList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );

        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(balanceSheetData[i].receivablesNet);
        Inventories.push(balanceSheetData[i].inventoriesTotal);
        currentAssets.push(balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(balanceSheetData[i].totalLiabilitiesPercent);
        commonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].rawMaterials = this.convertToMillionaire(
          balanceSheetData[i].rawMaterials
        );
        balanceSheetData[i].workinProcess = this.convertToMillionaire(
          balanceSheetData[i].workinProcess
        );
        balanceSheetData[i].finishedGoods = this.convertToMillionaire(
          balanceSheetData[i].finishedGoods
        );
        balanceSheetData[i].progressPaymentsOther = this.convertToMillionaire(
          balanceSheetData[i].progressPaymentsOther
        );

        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire(
          balanceSheetData[i].prepaidExpenses
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].currentAssetsTotal = this.convertToMillionaire(
          balanceSheetData[i].currentAssetsTotal
        );
        balanceSheetData[i].longTermReceivables = this.convertToMillionaire(
          balanceSheetData[i].longTermReceivables
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
        balanceSheetData[i].accountsPayable = this.convertToMillionaire(
          balanceSheetData[i].accountsPayable
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].accruedPayroll = this.convertToMillionaire(
          balanceSheetData[i].accruedPayroll
        );
        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire(
          balanceSheetData[i].incomeTaxesPayable
        );
        balanceSheetData[i].dividendsPayable = this.convertToMillionaire(
          balanceSheetData[i].dividendsPayable
        );
        balanceSheetData[i].otherCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentLiabilities
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilitiesPercent = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilitiesPercent
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      // this.data = balanceSheetData;
      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        shortTermDebt: shortTermDebt,
        totalAssets: totalAssets,
        cashAndShortTermInvestments: cashAndShortTermInvestments,
        Receivables: Receivables,
        Inventories: Inventories,
        currentAssets: currentAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        totalLiabilities: totalLiabilities,
        commonEquity: commonEquity,
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        tableGraphSeries1: [
          {
            data: shortTermDebt,
          },
        ],
        tableGraphOptions1: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: "Balance Sheet -3 yr Averages",
      });
    } else {
      this.data = [];
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let totalAssets = [];
      let cashAndShortTermInvestments = [];
      let Receivables = [];
      let Inventories = [];
      let currentAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let totalLiabilities = [];
      let commonEquity = [];
      let balanceSheetData = this.state.data.indBalanceSheetList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].year);
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        shortTermDebt.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        totalAssets.push(balanceSheetData[i].totalAssets);
        cashAndShortTermInvestments.push(
          balanceSheetData[i].cashShortTermInvestments
        );
        Receivables.push(balanceSheetData[i].receivablesNet);
        Inventories.push(balanceSheetData[i].inventoriesTotal);
        currentAssets.push(balanceSheetData[i].currentAssetsTotal);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        totalLiabilities.push(balanceSheetData[i].totalLiabilitiesPercent);
        commonEquity.push(balanceSheetData[i].commonEquity);

        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].cashShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashShortTermInvestments
          );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].shortTermInvestments = this.convertToMillionaire(
          balanceSheetData[i].shortTermInvestments
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].inventoriesTotal = this.convertToMillionaire(
          balanceSheetData[i].inventoriesTotal
        );
        balanceSheetData[i].rawMaterials = this.convertToMillionaire(
          balanceSheetData[i].rawMaterials
        );
        balanceSheetData[i].workinProcess = this.convertToMillionaire(
          balanceSheetData[i].workinProcess
        );
        balanceSheetData[i].finishedGoods = this.convertToMillionaire(
          balanceSheetData[i].finishedGoods
        );
        balanceSheetData[i].progressPaymentsOther = this.convertToMillionaire(
          balanceSheetData[i].progressPaymentsOther
        );
        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire(
          balanceSheetData[i].prepaidExpenses
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].currentAssetsTotal = this.convertToMillionaire(
          balanceSheetData[i].currentAssetsTotal
        );
        balanceSheetData[i].longTermReceivables = this.convertToMillionaire(
          balanceSheetData[i].longTermReceivables
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
        balanceSheetData[i].accountsPayable = this.convertToMillionaire(
          balanceSheetData[i].accountsPayable
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].accruedPayroll = this.convertToMillionaire(
          balanceSheetData[i].accruedPayroll
        );

        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire(
          balanceSheetData[i].incomeTaxesPayable
        );
        balanceSheetData[i].dividendsPayable = this.convertToMillionaire(
          balanceSheetData[i].dividendsPayable
        );
        balanceSheetData[i].otherCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentLiabilities
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilitiesPercent = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilitiesPercent
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        shortTermDebt: shortTermDebt,
        totalAssets: totalAssets,
        cashAndShortTermInvestments: cashAndShortTermInvestments,
        Receivables: Receivables,
        Inventories: Inventories,
        currentAssets: currentAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        totalLiabilities: totalLiabilities,
        commonEquity: commonEquity,
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        tableGraphSeries1: [
          {
            data: shortTermDebt,
          },
        ],
        tableGraphOptions1: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
      });
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}>
                    {this.state.noYrToYr && (
                      <>
                        <span className="unit_str">
                          All figures in{" "}
                          <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                          {this.state.currency}
                        </span>
                      </>
                    )}
                    {this.state.isYrToYr && (
                      <>
                        <span className="unit_str">
                          Figures are the Percent Changes from the Prior Year.
                        </span>
                      </>
                    )}
                    {this.state.isCommonSize && (
                      <>
                        <span className="unit_str">
                          Figures are expressed as Percent of Total Assets.
                        </span>
                        <br></br>
                        <span className="unit_str">
                          All figures in{" "}
                          <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                          {this.state.currency}
                        </span>
                      </>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Assets</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Cash & Short Term Investments</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash & Short Term Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashShortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Cash</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Short Term Investments
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.shortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Receivables (Net)</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Receivables",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Inventories -Total</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Inventories",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.inventoriesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Raw Materials</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.rawMaterials}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Work in Process</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.workinProcess}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Finished Goods</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.finishedGoods}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Progress Payments & Other
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.progressPaymentsOther}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Prepaid Expenses </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.prepaidExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Current Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Current Assets - Total</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Current Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.currentAssetsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Receivables</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Gross</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Property Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Assets </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{"100%"}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{data.totalAssets}</td>;
                        })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        {" "}
                        Total Liabilities & Shareholders' Equity
                      </span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Accounts Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accountsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        {" "}
                        Short Term Debt & Current Portion of Long Term Debt
                      </span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("STD", this.state.type)
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>Accrued Payroll</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accruedPayroll}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.incomeTaxesPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Dividends Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.dividendsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Current Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Current Liabilities - Total</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.currentLiabilitiesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className=""> Long Term Debt</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("LTD", this.state.type)
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Long Term Debt Excluding Capitalized Leases
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className=""> Total Liabilities</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td>{data.totalLiabilitiesPercent}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td>{data.totalLiabilities}</td>;
                        })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className=""> Common Equity</span>
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>{"100%"}</td>
                          );
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.totalLiabilitiesShareholdersEquity}
                            </td>
                          );
                        })}
                  </tr>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}>
                    {this.state.noYrToYr && (
                      <>
                        <span className="unit_str">
                          All figures in{" "}
                          <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                          {this.state.currency}
                        </span>
                      </>
                    )}
                    {this.state.isYrToYr && (
                      <>
                        <span className="unit_str">
                          Figures are the Percent Changes from the Prior Year.
                        </span>
                      </>
                    )}
                    {this.state.isCommonSize && (
                      <>
                        <span className="unit_str">
                          Figures are the Percent Changes from the Prior Year.
                        </span>
                        <br></br>
                        <span className="unit_str">
                          All figures in{" "}
                          <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                          {this.state.currency}
                        </span>
                      </>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">Total Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">Cash & Short Term Investments</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash & Short Term Investments"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashShortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Cash</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Short Term Investments
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.shortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">Receivables (Net)</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Receivables")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">Inventories -Total</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Inventories")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.inventoriesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Raw Materials</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.rawMaterials}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Work in Process</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.workinProcess}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Finished Goods</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.finishedGoods}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Progress Payments & Other
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.progressPaymentsOther}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Prepaid Expenses </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.prepaidExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Current Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">Current Assets - Total</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Current Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.currentAssetsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Receivables</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Gross</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Property Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Assets </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      Total Assets
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{"100%"}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{data.totalAssets}</td>;
                        })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">
                        {" "}
                        Total Liabilities & Shareholders' Equity
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Accounts Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accountsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className="">
                        {" "}
                        Short Term Debt & Current Portion of Long Term Debt
                      </span>

                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("STD")}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  {/* <tr>
                    <th
                      style={{ fontWeight: "bold"}}
                      onClick={(e) => this.getDataInGraphicalForm()}
                    >

                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.shortTermDebtCurrentPortionofLongTermDebt}</td>
                      );
                    })}
                  </tr> */}
                  <tr>
                    <th>Accrued Payroll</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accruedPayroll}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.incomeTaxesPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Dividends Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.dividendsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Current Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Current Liabilities - Total</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.currentLiabilitiesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className=""> Long Term Debt</span>

                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("LTD")}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Long Term Debt Excluding Capitalized Leases
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className=""> Total Liabilities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities",  this.state.type)
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td>{data.totalLiabilitiesPercent}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td>{data.totalLiabilities}</td>;
                        })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className=""> Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold" }}
                      // onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      Total Liabilities & Shareholders' Equity
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>{"100%"}</td>
                          );
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.totalLiabilitiesShareholdersEquity}
                            </td>
                          );
                        })}
                  </tr>
                </Table>

                {/* {itemRow}
                  </Table>
                </TableContainer> */}
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <>
                      <SingleAxisGenericGraph
                        graphData={this.state.graphDetails}
                      />
                    </>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
