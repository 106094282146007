import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Advertisement from "../ciadvertisement/Advertisement";
import GenericSearch from "../search/GenericSearch";
import "./DownloadMobileApp.css";

export default class DownloadMobileApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extensionList: [],
      identifierList: [],
      render: true,
      value: 0,
      columns: [
        {
          title: "Ext.",
          data: "extension",

          width: "10",
        },

        { title: "Country", data: "country", width: "20" },
        { title: "Description", data: "description", width: "60" },
      ],
    };
  }

  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section class="breadcrumbs">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    <h4>Company Extensions and Security Identifiers</h4>
                  </div>
                  <div class="col-lg-6">
                    <GenericSearch history={this.props.history} color="white" />
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <div style={{ minHeight: "100em" }}></div>
        </>
      );
    } else {
      return (
        <>
          <Row style={{ marginTop: "5em" }}>
            <Col md={2} style={{ marginTop: "1em" }}>
              <Advertisement advertisementType="CurrencyPageLeft" />
            </Col>
            <Col
              md={8}
              style={{
                padding: "1em",

                zIndex: "0 !important",
              }}
              className="def_ext pts_mobile_view"
            >
              <Row>
                <Col md={1}></Col>
                <Col md={4} style={{ padding: "82px" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wici_mobile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="appleStore"
                      src="/home/assets/img/appleStore.PNG"
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </Col>
                <Col md={2}></Col>
                <Col md={4} style={{ padding: "66px" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wici_mobile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/home/assets/img/googlePlayStore.PNG"
                      alt="googlePlayStore"
                      style={{ marginTop: "0.8em", cursor: "pointer" }}
                    />
                  </a>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Col>
            <Col md={2} style={{ marginTop: "1em" }}>
              <Advertisement advertisementType="CurrencyPageLeft" />
            </Col>
          </Row>
        </>
      );
    }
  }
}
