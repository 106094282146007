import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import "./InterimBankBalanceSheet.css";
export default class InterimBankBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      balanceSheetData: [],
      render: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }

  componentDidMount() {
    if (this.state.type === "Interim Balance Sheet Actual") {
      this.data = [];
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let balanceSheetData = this.state.data.bankInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);

        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].totalCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentAssets
        );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities = this.convertToMillionaire(
          balanceSheetData[i].federalAgencySecurities
        );
        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire(
          balanceSheetData[i].interbankLoans
        );
        balanceSheetData[i].leaseFinancing = this.convertToMillionaire(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].realEstateMortgageLoans = this.convertToMillionaire(
          balanceSheetData[i].realEstateMortgageLoans
        );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].cashAndDuefromBanks = this.convertToMillionaire(
          balanceSheetData[i].cashAndDuefromBanks
        );
        balanceSheetData[i].totalInvestments = this.convertToMillionaire(
          balanceSheetData[i].totalInvestments
        );
        balanceSheetData[i].totalLoans = this.convertToMillionaire(
          balanceSheetData[i].totalLoans
        );
        balanceSheetData[i].commercialAndIndustrialLoans =
          this.convertToMillionaire(
            balanceSheetData[i].commercialAndIndustrialLoans
          );
        balanceSheetData[i].consumerAndInstalmentLoans =
          this.convertToMillionaire(
            balanceSheetData[i].consumerAndInstalmentLoans
          );
        balanceSheetData[i].brokerAndFinancialInstitutionLoans =
          this.convertToMillionaire(
            balanceSheetData[i].brokerAndFinancialInstitutionLoans
          );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].totalDeposits = this.convertToMillionaire(
          balanceSheetData[i].totalDeposits
        );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        series: [
          {
            name: "Long Term Debit",
            type: "bar",
            data: longTermDebt,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "Shortterm and longterm debit",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Interim Balance Sheet Common Size") {
      let balanceSheetData = this.state.data.bankInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);

        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );

        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].totalCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentAssets
        );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities = this.convertToMillionaire(
          balanceSheetData[i].federalAgencySecurities
        );
        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire(
          balanceSheetData[i].interbankLoans
        );
        balanceSheetData[i].leaseFinancing = this.convertToMillionaire(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].realEstateMortgageLoans = this.convertToMillionaire(
          balanceSheetData[i].realEstateMortgageLoans
        );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].cashAndDuefromBanks = this.convertToMillionaire(
          balanceSheetData[i].cashAndDuefromBanks
        );
        balanceSheetData[i].totalInvestments = this.convertToMillionaire(
          balanceSheetData[i].totalInvestments
        );
        balanceSheetData[i].totalLoans = this.convertToMillionaire(
          balanceSheetData[i].totalLoans
        );
        balanceSheetData[i].commercialAndIndustrialLoans =
          this.convertToMillionaire(
            balanceSheetData[i].commercialAndIndustrialLoans
          );
        balanceSheetData[i].consumerAndInstalmentLoans =
          this.convertToMillionaire(
            balanceSheetData[i].consumerAndInstalmentLoans
          );
        balanceSheetData[i].brokerAndFinancialInstitutionLoans =
          this.convertToMillionaire(
            balanceSheetData[i].brokerAndFinancialInstitutionLoans
          );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].totalDeposits = this.convertToMillionaire(
          balanceSheetData[i].totalDeposits
        );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
      }
      // this.data = balanceSheetData;
      this.setState({
        balanceSheetData: balanceSheetData,
        series: [
          {
            name: "Long Term Debit",
            type: "bar",
            data: longTermDebt,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "Shortterm and longterm debit",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }

  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Accumulated Depreciation</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Treasury Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.treasurySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Federal Agency Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalAgencySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>State and Municipal Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.stateandMunicipalSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Trading Account Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tradingAccountSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Securities Purchased under Resale Agreements
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.securitiesPurchasedunderResaleAgreements}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage Backed Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgageBackedSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Federal Funds</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalFunds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interbank Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interbankLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Lease Financing</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.leaseFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Real Estate Mortgage Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateMortgageLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Foreign Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unspecified/Other Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedOtherLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unearned Income </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Reserves for Loan Losses
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reservesforLoanLosses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Loans - Net</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Customer Liabilities on Acceptances (Assets)
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.customerLiabilitiesonAcceptancesAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property Plant and Equipment - Gross
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Property Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.propertyPlantandEquipmentNet}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Interest Receivable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.interestReceivable}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Tangible Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Intangible Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Demand Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.demandDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Savings/Other Time Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.savingsOtherTimeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Foreign Office Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignOfficeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unspecified Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Total Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold", cursor: "pointer" }}>
                      <span className=""> Long Term Debt</span>
                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm()}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leasess
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.capitalizedLeaseObligations}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Provision for Risks and Charges
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.provisionforRisksandCharges}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      cash And Due from Banks
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashAndDuefromBanks}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInvestments}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLoans}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Commercial & Industrial Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commercialAndIndustrialLoans}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Consumer & Instalment Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.consumerAndInstalmentLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Broker & Financial Institution Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.brokerAndFinancialInstitutionLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Liabilities And Shareholders Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.liabilitiesAndShareholdersEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Total Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Short Term Debt & Current Portion of Long Term Debt
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Interim Balance Sheet({" "}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <ReactApexCharts
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={350}
                      width={450}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td style={{ fontWeight: "bold" }}></td>;
                    })}
                  </tr>
                  <tr>
                    <th>Cash And Due from Banks</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashAndDuefromBanks}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInvestments}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Treasury Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.treasurySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Federal Agency Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalAgencySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      State and Municipal Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.stateandMunicipalSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Trading Account Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tradingAccountSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Securities Purchased under Resale Agreements
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.securitiesPurchasedunderResaleAgreements}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage Backed Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgageBackedSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Federal Funds</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalFunds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLoans}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Interbank Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interbankLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Lease Financing</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.leaseFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Commercial & Industrial Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commercialAndIndustrialLoans}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Consumer & Instalment Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.consumerAndInstalmentLoans}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Real Estate Mortgage Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateMortgageLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Foreign Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Broker & Financial Institution Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.brokerAndFinancialInstitutionLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Unspecified/Other Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedOtherLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unearned Income </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves for Loan Losses</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reservesforLoanLosses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Loans - Net</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Customer Liabilities on Acceptances (Assets)</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.customerLiabilitiesonAcceptancesAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Gross</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Accumulated Depreciation</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Property Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.propertyPlantandEquipmentNet}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Interest Receivable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interestReceivable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalDeposits}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Demand Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.demandDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Savings/Other Time Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.savingsOtherTimeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Foreign Office Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignOfficeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Unspecified Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Total Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.totalDebt}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Short Term Debt & Current Portion of Long Term Debt
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      <span className=""> Long-Term Debt</span>
                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm()}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leasess
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capitalized Lease Obligations</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities And Shareholders Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesAndShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Interim Balance Sheet({" "}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <ReactApexCharts
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={350}
                      width={450}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
