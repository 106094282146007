import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "./InterimFinancialIncomeStatement.css";
export default class InterimFinancialIncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      interimIncomeStatementData: [],
      showModal: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  componentDidMount() {
    if (this.state.type === "Interim Income Statement Actual") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.oFinIncomeStatement;

      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMMYYYY")
        );

        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].investmentIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].investmentIncome
          );
        interimIncomeStatementData[i].foreignExchangeIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].foreignExchangeIncome
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].tradingAccountIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].tradingAccountIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].provisionforLoanLosses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].provisionforLoanLosses
          );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].totalInterestExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestExpense
          );
        interimIncomeStatementData[i].totalInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestIncome
          );
        interimIncomeStatementData[i].netRevenues = this.convertToMillionaire(
          interimIncomeStatementData[i].netRevenues
        );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
      });
    } else if (this.state.type === "Interim Income Statement Common Size") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.oFinIncomeStatement;
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].investmentIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].investmentIncome
          );
        interimIncomeStatementData[i].foreignExchangeIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].foreignExchangeIncome
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].tradingAccountIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].tradingAccountIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].provisionforLoanLosses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].provisionforLoanLosses
          );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].totalInterestExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestExpense
          );
        interimIncomeStatementData[i].totalInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestIncome
          );
        interimIncomeStatementData[i].netRevenues = this.convertToMillionaire(
          interimIncomeStatementData[i].netRevenues
        );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization +
              "%"
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
      });
    } else if (this.state.type === "Interim Income Statement Year-Year") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.oFinIncomeStatement;
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMMYYYY")
        );
        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].investmentIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].investmentIncome
          );
        interimIncomeStatementData[i].foreignExchangeIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].foreignExchangeIncome
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].tradingAccountIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].tradingAccountIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].provisionforLoanLosses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].provisionforLoanLosses
          );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].totalInterestExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestExpense
          );
        interimIncomeStatementData[i].totalInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestIncome
          );
        interimIncomeStatementData[i].netRevenues = this.convertToMillionaire(
          interimIncomeStatementData[i].netRevenues
        );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization +
              "%"
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
      });
    } else if (
      this.state.type === "Interim Income Statement Three Year Average"
    ) {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.oFinIncomeStatement;
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMMYYYY")
        );
        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].investmentIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].investmentIncome
          );
        interimIncomeStatementData[i].foreignExchangeIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].foreignExchangeIncome
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );

        interimIncomeStatementData[i].tradingAccountIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].tradingAccountIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].provisionforLoanLosses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].provisionforLoanLosses
          );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );

        interimIncomeStatementData[i].totalInterestExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestExpense
          );
        interimIncomeStatementData[i].totalInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalInterestIncome
          );
        interimIncomeStatementData[i].netRevenues = this.convertToMillionaire(
          interimIncomeStatementData[i].netRevenues
        );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "20%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
      });
    }
  }
  render() {
    if (this.state.isPdfReport === true) {
      return (
        <Row style={{ padding: "15px", marginTop: "-3em" }}>
          <Col md={12} style={{ marginLeft: "1.5em" }}>
            <Row>
              <Col md={8} style={{ padding: "9px" }}></Col>
              <Col md={4}></Col>
            </Row>

            <Table responsive className="financial_analysis1">
              <tr>
                <th style={{ fontWeight: "bold" }}>Quarter </th>

                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {moment(data.year).format("MM/DD/YYYY")}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>Net Revenues</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.totalInterestIncome}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Total Interest Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.totalInterestIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Trading Account Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.tradingAccountIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Foreign Exchange Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.foreignExchangeIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Investment Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.investmentIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Cost of Goods Sold</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.costofGoodsSold}</td>;
                })}
              </tr>
              <tr>
                <th>Depreciation, Depletion & Amortization</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.depreciationDepletionAndAmortization}</td>;
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>Gross Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>{data.grossIncome}</td>
                  );
                })}
              </tr>
              <tr>
                <th>Selling, General & Administrative Expenses</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td>{data.sellingGeneralAndAdministrativeExpenses}</td>
                  );
                })}
              </tr>
              <tr>
                <th>Total Interest Expense</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.totalInterestExpense}</td>;
                })}
              </tr>
              <tr>
                <th>Provision For Loan Losses</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.provisionforLoanLosses}</td>;
                })}
              </tr>
              <tr>
                <th>Other Operating Expenses</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.otherOperatingExpenses}</td>;
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>
                  Total Operating Expenses{" "}
                </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.totalOperatingExpenses}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>Operating Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.operatingIncome}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Non-Operating Interest Income </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.nonOperatingInterestIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Pretax Equity in Earnings </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.pretaxEquityinEarnings}</td>;
                })}
              </tr>
              <tr>
                <th>Extraordinary Credit - Pretax</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.extraordinaryCreditPretax}</td>;
                })}
              </tr>
              <tr>
                <th>Extraordinary Charge - Pretax</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.extraordinaryChargePretax}</td>;
                })}
              </tr>
              <tr>
                <th>Other Income/Expense - Net</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.otherIncomeExpenseNet}</td>;
                })}
              </tr>

              <tr>
                <th>Reserves - Increase/Decrease</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.reservesIncreaseDecrease}</td>;
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>
                  Earnings before Interest, Taxes, Depreciation & Amortization
                  (EBITDA)
                </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {
                        data.earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
                      }
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>
                  Earnings before Interest & Taxes (EBIT){" "}
                </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.earningsbeforeInterestAndTaxesEBIT}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Pretax Income</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.pretaxIncome}</td>;
                })}
              </tr>
              <tr>
                <th>Income Taxes </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.incomeTaxes}</td>;
                })}
              </tr>

              <tr>
                <th>Minority Interest</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.minorityInterest}</td>;
                })}
              </tr>
              <tr>
                <th>Equity in Earnings </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.equityinEarnings}</td>;
                })}
              </tr>
              <tr>
                <th>After Tax Other Income/Expense</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.afterTaxOtherIncomeExpense}</td>;
                })}
              </tr>
              <tr>
                <th>Discontinued Operations</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.discontinuedOperations}</td>;
                })}
              </tr>

              <tr>
                <th style={{ fontWeight: "bold" }}>
                  Net Income before Extraordinary Items and Preferred Dividends{" "}
                </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {
                        data.netIncomebeforeExtraordinaryItemsandPreferredDividends
                      }
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td>{data.extraordinaryItemsAndGainLossSaleofAssets}</td>
                  );
                })}
              </tr>
              <tr>
                <th>Preferred Dividend Requirements</th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return <td>{data.preferredDividendRequirements}</td>;
                })}
              </tr>

              <tr>
                <th style={{ fontWeight: "bold" }}>
                  Net Income after Preferred Dividends - Available to Common
                  Shares
                </th>
                {this.state.interimIncomeStatementData.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {
                        data.netIncomeafterPreferredDividendsAvailabletoCommonShares
                      }
                    </td>
                  );
                })}
              </tr>
            </Table>

            {/* {itemRow}
                </Table>
              </TableContainer> */}
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <Row style={{ padding: "15px", marginTop: "-3em" }}>
            <Col md={12}>
              <Row>
                <Col md={8} style={{ padding: "9px" }}></Col>
                <Col md={4}></Col>
              </Row>

              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Quarter </th>

                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {moment(data.year).format("MM/DD/YYYY")}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Net Revenues</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.totalInterestIncome}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Total Interest Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.totalInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trading Account Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.tradingAccountIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Foreign Exchange Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.foreignExchangeIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Investment Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.investmentIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Cost of Goods Sold</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.costofGoodsSold}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation, Depletion & Amortization</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.depreciationDepletionAndAmortization}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Gross Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.grossIncome}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Selling, General & Administrative Expenses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td>{data.sellingGeneralAndAdministrativeExpenses}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Total Interest Expense</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.totalInterestExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Provision For Loan Losses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.provisionforLoanLosses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Total Operating Expenses{" "}
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.totalOperatingExpenses}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Operating Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.operatingIncome}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>

                <tr>
                  <th>Reserves - Increase/Decrease</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Earnings before Interest, Taxes, Depreciation & Amortization
                    (EBITDA)
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Earnings before Interest & Taxes (EBIT){" "}
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.earningsbeforeInterestAndTaxesEBIT}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Pretax Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.pretaxIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Income Taxes </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>

                <tr>
                  <th>Minority Interest</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>

                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Net Income before Extraordinary Items and Preferred
                    Dividends{" "}
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.netIncomebeforeExtraordinaryItemsandPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td>{data.extraordinaryItemsAndGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>

                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Net Income after Preferred Dividends - Available to Common
                    Shares
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.netIncomeafterPreferredDividendsAvailabletoCommonShares
                        }
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.showModal}
            toggle={this.toggle}
            size="xl"
            className="modalContain"
          >
            <ModalHeader>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    Interim Balance Sheet({" "}
                  </Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.toggle}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <ReactApexCharts
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={350}
                  />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}
