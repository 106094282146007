import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./SalesAnalysis.css";
export default class SalesAnalysis extends Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var yesterday = d.setDate(d.getDate() - 1);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      render: false,
      showModal: false,
      graph1: false,
      graph2: false,
      isPdfReport: isPdfReport,
      data: props.data,
      salesAnalysisData: [],
      TableOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  to: 0,
                  from: -100000000000,
                  color: "#F15B46",
                },
              ],
            },
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }

  componentDidMount() {
    let salesAIT = [];
    let salesYtyGrowth = [];
    let labels = [];
    let cgsAIT = [];
    let cgsPercentOfSale = [];
    let ebitdaAIT = [];
    let ebitdaPercentOfSale = [];
    let atibecAIT = [];
    let atibecPercentOfSale = [];
    let salesPerEmployee = [];
    for (let i = 0; i < this.state.data.salesAnalysisList.length; i++) {
      labels.push(this.state.data.salesAnalysisList[i].year);
      salesAIT.push(this.state.data.salesAnalysisList[i].salesAmt);
      salesYtyGrowth.push(this.state.data.salesAnalysisList[i].salesYoYGrowth);

      cgsAIT.push(this.state.data.salesAnalysisList[i].costOfGoodsSold);
      cgsPercentOfSale.push(
        this.state.data.salesAnalysisList[i].costOfGoodsSoldPercentOfSales
      );
      ebitdaAIT.push(this.state.data.salesAnalysisList[i].ebitda);
      ebitdaPercentOfSale.push(
        this.state.data.salesAnalysisList[i].ebitdaPercentOfSales
      );
      atibecAIT.push(this.state.data.salesAnalysisList[i].afterTaxBeforeECCAmt);
      atibecPercentOfSale.push(
        this.state.data.salesAnalysisList[i].afterTaxBeforeECCPercentOfSales
      );
      salesPerEmployee.push(
        this.state.data.salesAnalysisList[i].salesPerEmployee
      );
    }

    this.setState({
      render: true,
      unit: this.state.data.unit,
      currency: this.state.data.currency,
      salesAnalysisData: this.state.data.salesAnalysisList,
      salesAIT: salesAIT,
      cgsAIT: cgsAIT,
      ebitdaAIT: ebitdaAIT,
      atibecAIT: atibecAIT,
      salesPerEmployee: salesPerEmployee,
      labels: labels,
    });
  }
  getDataInTabularForm(graphType) {
    if (graphType === "Sales") {
      this.setState({
        showModal: true,
        graph2: true,
        graph1: false,
        graphDetails: {
          graphValues: this.state.salesAIT,
          labelData: this.state.labels,
          yAxisTitle: "Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    } else if (graphType === "Cost of Goods Sold") {
      this.setState({
        showModal: true,
        graph2: false,
        graph1: true,
        series: [
          {
            name: "Sales",
            data: this.state.salesAIT,
          },
          {
            name: "Cost of Goods Sold",
            data: this.state.cgsAIT,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          labels: this.state.labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {},
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        },
      });
    } else if (graphType === "EBITDA") {
      this.setState({
        showModal: true,
        graph2: false,
        graph1: true,
        series: [
          {
            name: "Sales",
            data: this.state.salesAIT,
          },
          {
            name: "EBITDA",
            data: this.state.ebitdaAIT,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          labels: this.state.labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {},
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        },
      });
    } else if (graphType === "ATIBECC") {
      this.setState({
        showModal: true,
        graph2: false,
        graph1: true,
        series: [
          {
            name: "Sales",
            data: this.state.salesAIT,
          },
          {
            name: "ATIBECC",
            data: this.state.atibecAIT,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          labels: this.state.labels,
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {},
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        },
      });
    } else {
      this.setState({
        showModal: true,
        graph2: true,
        graph1: false,
        graphDetails: {
          graphValues: this.state.salesPerEmployee,
          labelData: this.state.labels,
          yAxisTitle: "Employees",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
      });
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    let itemRow = this.state.salesAnalysisData.map((salesAnalysis, i) => {
      return (
        <tr
          style={i % 2 ? { background: "#cae1ff" } : { background: "white" }}
          key={i}
        >
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.year}
          </td>
          <td>
            {salesAnalysis.salesAmt !== null
              ? Utils.valueFormat(salesAnalysis.salesAmt)
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.salesYoYGrowth !== null
              ? salesAnalysis.salesYoYGrowth.toFixed(2) + "%"
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {salesAnalysis.costOfGoodsSold !== null
              ? Utils.valueFormat(salesAnalysis.costOfGoodsSold.toFixed(2))
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.costOfGoodsSoldPercentOfSales !== null
              ? salesAnalysis.costOfGoodsSoldPercentOfSales + "%"
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {salesAnalysis.ebitda !== null
              ? Utils.valueFormat(salesAnalysis.ebitda)
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.ebitdaPercentOfSales !== 0
              ? salesAnalysis.ebitdaPercentOfSales + "%"
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {salesAnalysis.afterTaxBeforeECCAmt !== null
              ? Utils.valueFormat(salesAnalysis.afterTaxBeforeECCAmt.toFixed(2))
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.afterTaxBeforeECCPercentOfSales !== null
              ? salesAnalysis.afterTaxBeforeECCPercentOfSales + "%"
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {salesAnalysis.employeeNumber !== null
              ? Utils.valueFormat(salesAnalysis.employeeNumber)
              : "n/a"}
          </td>
          <td style={{ borderRight: "1px solid", textAlign: "right" }}>
            {salesAnalysis.salesPerEmployee !== 0
              ? Utils.valueFormat(salesAnalysis.salesPerEmployee)
              : "n/a"}
          </td>
          <td style={{ textAlign: "right" }}>
            {salesAnalysis.afterTaxIncomePerEmployee !== 0
              ? Utils.valueFormat(
                  salesAnalysis.afterTaxIncomePerEmployee.toFixed(2)
                )
              : "n/a"}
          </td>
        </tr>
      );
    });
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1em" }}>
              <Col md={12}>
                <span>
                  <span className="section_header">Sales Analysis</span>
                </span>
                <br></br>
                <span className="unit_str">
                  All Figures in{" "}
                  <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                  {this.state.currency}
                </span>
                <Table
                  style={{ border: "1px solid ", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        rowSpan="2"
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents gross sales and other operating revenue less discounts, returns and allowances."
                      >
                        Sales
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                      >
                        Cost of Goods Sold
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represent the earnings of a company before interest expense, income taxes and depreciation. It is calculated by taking the pretax income and adding back interest expense on debt and depreciation, depletion and amortization and subtracting interest capitalized."
                      >
                        EBITDA
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="ATIBECC(After Tax Income before Extraordinary Charges and Credits) - Represents income before extraordinary items and preferred and common dividends, but after operating and non-operating income and expense, reserves, income taxes, minority interest and equity in earnings."
                      >
                        ATIBECC
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="3"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the number of both full and part time employees of the company. It excludes seasonal and emergency employees."
                      >
                        Employees
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className="sales_analysis"
                        title="Represents gross sales and other operating revenue less discounts, returns and allowances."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents ((Current Year?s Net Sales or Revenues / Last Year?s Total Net Sales or Revenues) - 1) * 100"
                      >
                        Year-to-year Growth
                      </th>
                      <th className="sales_analysis">
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represent the earnings of a company before interest expense, income taxes and depreciation. It is calculated by taking the pretax income and adding back interest expense on debt and depreciation, depletion and amortization and subtracting interest capitalized."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Earnings before Interest, Taxes, Depreciation, and Amortization divided by Sales"
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents income before extraordinary items and preferred and common dividends, but after operating and non-operating income and expense, reserves, income taxes, minority interest and equity in earnings."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Income Before Extraordinary Items and Preferred Dividends divided by Sales."
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the number of both full and part time employees of the company. It excludes seasonal and emergency employees."
                      >
                        Number
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Net Sales or Revenues divided by the number of Employees."
                      >
                        Sales Per Employee
                      </th>
                      <th className="sales_analysis">
                        After Tax Income Per Employee
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1em" }}>
              <Col md={12}>
                <span>
                  <span className="section_header"> Sales Analysis</span>
                </span>
                <br></br>
                <span className="unit_str">
                  All Figures in{" "}
                  <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                  {this.state.currency}
                </span>
                <Table
                  responsive
                  style={{ border: "1px solid ", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        rowSpan="2"
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents gross sales and other operating revenue less discounts, returns and allowances."
                      >
                        Sales
                        <GraphIconButton
                          onClick={(e) => this.getDataInTabularForm("Sales")}
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid", width: "20%" }}
                      >
                        Cost of Goods Sold
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInTabularForm("Cost of Goods Sold")
                          }
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represent the earnings of a company before interest expense, income taxes and depreciation. It is calculated by taking the pretax income and adding back interest expense on debt and depreciation, depletion and amortization and subtracting interest capitalized."
                      >
                        EBITDA
                        <GraphIconButton
                          onClick={(e) => this.getDataInTabularForm("EBITDA")}
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="2"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="ATIBECC(After Tax Income before Extraordinary Charges and Credits) - Represents income before extraordinary items and preferred and common dividends, but after operating and non-operating income and expense, reserves, income taxes, minority interest and equity in earnings."
                        onClick={(e) => this.getDataInTabularForm("ATIBECC")}
                      >
                        ATIBECC
                        <GraphIconButton
                          onClick={(e) => this.getDataInTabularForm("ATIBECC")}
                        ></GraphIconButton>
                      </th>
                      <th
                        className="sales_analysis"
                        colSpan="3"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the number of both full and part time employees of the company. It excludes seasonal and emergency employees."
                      >
                        Employees
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInTabularForm("Dividends")
                          }
                        ></GraphIconButton>
                      </th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className="sales_analysis"
                        title="Represents gross sales and other operating revenue less discounts, returns and allowances."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents ((Current Year?s Net Sales or Revenues / Last Year?s Total Net Sales or Revenues) - 1) * 100"
                      >
                        Year-to-year Growth
                      </th>
                      <th className="sales_analysis">
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represent the earnings of a company before interest expense, income taxes and depreciation. It is calculated by taking the pretax income and adding back interest expense on debt and depreciation, depletion and amortization and subtracting interest capitalized."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Earnings before Interest, Taxes, Depreciation, and Amortization divided by Sales"
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents income before extraordinary items and preferred and common dividends, but after operating and non-operating income and expense, reserves, income taxes, minority interest and equity in earnings."
                      >
                        Amount in {this.toTitleCase(this.state.unit)}
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Income Before Extraordinary Items and Preferred Dividends divided by Sales."
                      >
                        % of Sales
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the number of both full and part time employees of the company. It excludes seasonal and emergency employees."
                      >
                        Number
                      </th>
                      <th
                        className="sales_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the Net Sales or Revenues divided by the number of Employees."
                      >
                        Sales Per Employee
                      </th>
                      <th className="sales_analysis">
                        After Tax Income Per Employee
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Sales Analysis
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <>
                      {this.state.graph1 && (
                        <ReactApexCharts
                          options={this.state.options}
                          series={this.state.series}
                          type="bar"
                          height={350}
                        />
                      )}

                      {this.state.graph2 && (
                        <SingleAxisGenericGraph
                          graphData={this.state.graphDetails}
                        />
                      )}
                    </>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
