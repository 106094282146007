import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import MaterialTable from "material-table";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import UiUtils from "../../provider/UiUtils";
import Advertisement from "../ciadvertisement/Advertisement";
import "./DefinationExtension.css";

export default class DefinitionExtension extends Component {
  constructor(props) {
    super(props);
    this.tableIcons = UiUtils.getMaterialTableIcons();
    this.state = {
      extensionList: [],
      identifierList: [],
      render: false,
      value: 0,
      columns: [
        {
          title: "Ext.",
          data: "extension",

          width: "10",
        },

        { title: "Country", data: "country", width: "20" },
        { title: "Description", data: "description", width: "60" },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const postObject = {};
    FetchServerData.callPostService("definitionExt/getAll", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          var identifierList = output.data.identifierList.filter(function (el) {
            return el != null;
          });

          this.setState({
            extensionList: output.data.extensionList,
            identifierList: identifierList,
            render: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleChange = (event, value) => {
    this.setState({
      value: value,
    });
  };
  render() {
    return (
      <>
        <Row style={{ marginTop: "5em" }}>
          <Col md={2} style={{ marginTop: "1em" }}></Col>
          <Col
            md={8}
            style={{
              padding: "1em",

              zIndex: "0 !important",
            }}
            className="def_ext pts_mobile_view"
          >
            {this.state.render === true ? (
              <>
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange.bind(this)}
                  indicatorColor="primary"
                  textColor="primary"
                  className={this.state.tabTop}
                  scrollButtons="auto"
                  style={{ marginTop: "1em" }}
                >
                  <Tab label="Definitions and Extensions" wrapped />
                  <Tab label="Security Identifiers" wrapped />
                </Tabs>

                {this.state.value === 0 && (
                  <>
                    <Row style={{ margin: "1em 0em 0em -0.8em" }}>
                      <Col md={12}>
                        <p>
                          This page provides definitions of company extensions
                          and security identifiers. While U.S. companies are
                          usually followed by "Inc.", many foreign companies
                          have different endings. This section tells what these
                          terms mean, and where they are used. If you don't know
                          what country a company is based in, this list of
                          identifers might help narrow your search.
                        </p>
                      </Col>
                    </Row>
                    <Row
                      style={{ margin: "1em 0em 0em -0.8em" }}
                      className="defExt_Table"
                    >
                      <Col md={12}>
                        <MaterialTable
                          icons={this.tableIcons}
                          title="Company Extensions"
                          columns={[
                            {
                              title: "Extension",
                              field: "extension",
                              align: "left",
                              width: "20%",
                              cellStyle: {
                                width: "20%",
                                maxWidth: "20%",
                              },
                              headerStyle: {
                                width: "20%",
                                maxWidth: "20%",
                              },
                            },
                            {
                              title: "Country",
                              field: "country",
                              align: "left",
                              width: "20%",
                              cellStyle: {
                                width: "20%",
                                maxWidth: "20%",
                              },
                              headerStyle: {
                                width: "20%",
                                maxWidth: "20%",
                              },
                            },
                            {
                              title: "Description",
                              field: "description",
                              align: "left",
                              width: "60%",
                              font: "small",
                              filtering: false,
                              cellStyle: {
                                width: "60%",
                                maxWidth: "60%",
                                fontSize: "small",
                              },
                              headerStyle: {
                                width: "60%",
                                maxWidth: "60%",
                                fontSize: "small",
                              },
                            },
                          ]}
                          data={this.state.extensionList}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            headerStyle: {
                              backgroundColor: "#2b7d6d",
                              fontWeight: "bold",
                              color: "#fff",
                              align: "center",
                            },
                            rowStyle: (rowData) => ({
                              background:
                                rowData.tableData.id % 2
                                  ? "#FFF"
                                  : "rgba(22, 160, 133, 0.1)",
                              padding: "0",
                            }),
                            filtering: true,
                            toolbar: false,
                          }}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #808b96",
                            boxShadow:
                              " 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1)",
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.value === 1 && (
                  <>
                    <Row style={{ margin: "1em 0em 0em -0.7em" }}>
                      <Col md={12}>
                        <p>
                          Bonds and Stocks usually have one or more identifier
                          codes, issued by various clearing houses or other
                          agencies. The purpose of these identifiers is to
                          prevent confusion when discussing a particular
                          security, particularly a bond. While a company will
                          usually only have one class of stock, it can have many
                          different bond issues. The following is a list of
                          various security identifiers along with information
                          about their structure and issuers.
                        </p>
                      </Col>

                      <Col md={12} className="defExt_Table">
                        <MaterialTable
                          icons={this.tableIcons}
                          title="Security Identifiers"
                          columns={[
                            {
                              title: "ID",
                              field: "id",
                              align: "left",
                              width: "20%",
                              maxWidth: "20%",
                              cellStyle: {
                                width: "20%",
                                maxWidth: "20%",
                              },
                              headerStyle: {
                                width: "20%",
                                maxWidth: "20%",
                                backgroundColor: "#2b7d6d",
                              },
                            },
                            {
                              title: "Description",
                              field: "description",
                              align: "left",
                              width: "80% !important",
                              maxWidth: "80% !important",
                              filtering: false,
                              cellStyle: {
                                width: "80%",
                                maxWidth: "80%",
                                fontSize: "small",
                              },
                              headerStyle: {
                                width: "80%",
                                maxWidth: "80%",
                                backgroundColor: "#2b7d6d",
                              },
                            },
                          ]}
                          data={this.state.identifierList}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            headerStyle: {
                              backgroundColor: "#2980b9",
                              fontWeight: "bold",
                              color: "#fff",
                              align: "center",
                            },

                            filtering: true,
                            toolbar: false,
                            rowStyle: (rowData) => ({
                              background:
                                rowData.tableData.id % 2
                                  ? "#FFF"
                                  : "rgba(22, 160, 133, 0.1)",
                              padding: "0",
                            }),
                          }}
                          style={{
                            fontSize: "14px",
                            border: "1px solid #808b96",
                            boxShadow:
                              " 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1)",
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <div className="pageHeight"></div>
            )}
          </Col>
          <Col md={2} style={{ marginTop: "1em" }}>
            <Advertisement advertisementType="verticalAddUnit1" />
          </Col>
        </Row>
      </>
    );
    // }
  }
}
