import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import BankLiquidityAnalysis from "./BankLiquidityAnalysis";
import FinancialLiquidityAnalysis from "./FinancialLiquidityAnalysis";
import IndLiquidityAnalysis from "./IndLiquidityAnalysis";
import InsuranceLiquidityAnalysis from "./InsuranceLiquidityAnalysis";
import "./LiquidityAnalysis.css";
export default class LiquidityAnalysis extends Component {
  constructor(props) {
    super(props);

    let columns = [];
    let type = props.type;
    this.state = {
      type: type,
      financialRatioData: props.data,
      isPdfReport: props.isPdfReport,
      render: true,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <BankLiquidityAnalysis
                  data={this.state.financialRatioData}
                ></BankLiquidityAnalysis>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "Ins") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InsuranceLiquidityAnalysis
                  data={this.state.financialRatioData}
                ></InsuranceLiquidityAnalysis>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFin") {
        return (
          <>
            <Row>
              <Col md={12}>
                <FinancialLiquidityAnalysis
                  data={this.state.financialRatioData}
                ></FinancialLiquidityAnalysis>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IndLiquidityAnalysis
                  data={this.state.financialRatioData}
                  isPdfReport={this.state.isPdfReport}
                ></IndLiquidityAnalysis>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
