import React, { Component } from "react";

class Advertisement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      advertisementType: props.advertisementType,
    };
  }
  componentDidMount() {
    const installGoogleAds = () => {
      const elem = document.createElement("script");
      elem.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    if (this.state.advertisementType === "horizontalAddUnit") {
      return this.horizontalAddUnit();
    } else if (this.state.advertisementType === "verticalAddUnit") {
      return this.verticalAddUnit();
    } else if (this.state.advertisementType === "verticalAddUnit1") {
      return this.verticalAddUnit1();
    } else if (this.state.advertisementType === "verticalAddUnit2") {
      return this.verticalAddUnit2();
    } else if (this.state.advertisementType === "SquareAddUnit") {
      return this.SquareAddUnit();
    } else if (this.state.advertisementType === "SearchPageHorizontalAddUnit") {
      return this.SearchPageHorizontalAddUnit();
    } else {
      return this.fixHorizontalAddUnit();
    }
  }

  horizontalAddUnit() {
    return (
      <ins
        key="horizontalAddUnit"
        className="adsbygoogle"
        style={{ display: "inline-block", width: "760px", height: "172px" }}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="5137561517"
        data-ad-format="auto"
        data-full-width-responsive="true"
        target="_blank"
      ></ins>
    );
  }

  verticalAddUnit() {
    return (
      <ins
        key="verticalAddUnit"
        className="adsbygoogle"
        style={{ display: "inline-block", width: "172px", height: "760px"}}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="5425691342"
        data-ad-format="auto"
        data-full-width-responsive="true"
        target="_blank"
      ></ins>
    );
  }
  verticalAddUnit1() {
    return (
      <ins
        key="horizontalAddUnit1"
        className="adsbygoogle"
        style={{ display: "inline-block", width: "172px", height: "760px" }}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="9104726686"
        data-ad-format="auto"
        data-full-width-responsive="true"
        target="_blank"
      ></ins>
    );
  }
  verticalAddUnit2() {
    return (
      <ins
        key="horizontalAddUnit2"
        className="adsbygoogle"
        style={{ display: "inline-block", width: "172px", height: "760px" }}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="3946530046"
        data-ad-format="auto"
        data-full-width-responsive="true"
        target="_blank"
      ></ins>
    );
  }
  SquareAddUnit() {
    return (
      <ins
        key="squarAddUnit"
        style={{ display: "block",overflow: 'scroll !important' }}
        className="adsbygoogle"
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="8191455486"
        data-ad-format="auto"
        data-full-width-responsive="true"
        target="_blank"
      ></ins>
    );
  }
  fixHorizontalAddUnit() {
    return (
      <ins
        key="fixHorizontalAddUnit"
        className="adsbygoogle"
        style={{ display: "inline-block", width: "760px", height: "172px" }}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="5137561517"
        target="_blank"
      ></ins>
    );
  }
  SearchPageHorizontalAddUnit() {
    return (
      <ins
        key="fixHorizontalAddUnit"
        className="adsbygoogle"
        style={{
          display: "block",
          // background: "#d5d8dc",
          width: "485px",
          height: "90px",
        }}
        data-ad-client="ca-pub-5265260100094582"
        data-ad-slot="9418722306"
        target="_blank"
      ></ins>
    );
  }
}

export default Advertisement;
