import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { Radar } from "react-chartjs-2";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import "./CorporateGrowth.css";
export default class CorporateGrowth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      cusip: props.data,
      corporateGrowthData: [],
      corporateGrowth: false,
    };
  }

  componentDidMount() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService(
      "qualityAnalysis/corporateGrowth",
      postObject
    )
    .then((output) => {
      if (output.status === "SUCCESS") {
        let data = output.data;
        if (data) {
            this.setState({
              corporateGrowthData: data.corporateGrowth,
              currency: output.data.currency,
              corporateGrowth: true,
            });
          }
        }
       else {
      }
    })
      .catch((error) => {
        "";
      });
  }
  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
      radarData: ["200.0", "150.0", "160.0", "100.0"],
    });
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toQualityRating(qualityRating) {
    let wrightQualityRating = qualityRating.split(
      ''
    );
    let  wrightQualityRatingStr = (
        <>
          <span style={{ fontWeight: "bold", padding: "0px" }}>
            {wrightQualityRating[0]}
            {wrightQualityRating[1]}
            {wrightQualityRating[2]}
          </span>

          <span
            style={{
              fontSize: "2em",
              color: "green",
              fontWeight: "bold",
              padding: "0px",
            }}
          >
            {wrightQualityRating[3]}
            {wrightQualityRating[4]}
          </span>
        </>
      );
    return wrightQualityRatingStr;
  }
  
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      return (
        <>
          <Row style={{ padding: "15px", marginTop: "-1em" }}>
            <Col md={12}>
              <span className="section_header"> Corporate Growth</span>
              <br></br>
              <span className="unit_str">
                Figures are expressed on a Per Share Basis.
              </span>
            </Col>
          </Row>
          {this.state.corporateGrowth && (
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                <Table className="corporate_growth" style={{ width: "103%" }}>
                {this.state.corporateGrowthData.map((dataObj, index) => {
                    if (index === 0) {
                      return (
                        <tr>
                          <th
                            onClick={(e) =>
                              this.getDataInGraphicalForm("price")
                            }
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {dataObj.header}
                          </th>
                          <td style={{ textAlign: "center" }}>
                            {this.toQualityRating(dataObj.formattedValue)}
                          </td>
                        </tr>
                      );
                    }
                    else if (index === 1) {
                      return (
                        <tr>
                          <th
                            style={{
                              fontWeight: "bold",
                              paddingLeft: "2em",
                            }}
                          >
                            {dataObj.header}
                          </th>

                          <td
                            style={{
                              fontSize: "1.5em",
                              textAlign: "center",
                              color: "green",
                              fontWeight: "bold",
                            }}
                          >
                            {dataObj.formattedValue}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <th>
                            {dataObj.header}
                          </th>
                          <td style={{ textAlign: "center" }}>
                            {dataObj.formattedValue}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </Table>
              </Col>
            </Row>
          )}
          <Modal
            isOpen={this.state.showModal}
            toggle={this.toggle}
            size="xl"
            className="modalContain"
          >
            <ModalHeader>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      verticalAlign: "middle",
                      float: "left",
                    }}
                  >
                    Wright Quality Ratings
                  </Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.toggle}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody className="wqa_modal">
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Ratings, in use for over 40 years,
                    measure the overall investment quality of a company. This
                    proprietary rating is based on numerous individual measures
                    of quality, grouped into four principal components: (1)
                    Investment Acceptance (i.e. stock liquidity), (2) Financial
                    Strength, (3) Profitability & Stability, and (4) Growth.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Rating is expressed as three letters and
                    a number. Each letter reflects a composite qualitative
                    measurement of numerous individual standards which may be
                    summarized as “A”; Outstanding, “B”; Excellent, “C”; Good,
                    “D” Fair, “L”; Limited and “N”; Not Rated The number
                    component of the Quality Rating is also a composite of
                    numerous individual standards that measure Corporate Growth
                    (i.e. sales, earnings, assets, etc.). It ranges from a low
                    of 0 to high of 20. (See sample Quality Rating below.)
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The highest quality rating assigned by Wright is AAA20. This
                    rating would be assigned to a company that has a large and
                    broad base of shareholders, and has an outstanding balance
                    sheet and profitability. The company also has experienced
                    superior growth over the past several years.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Rating assigned to a company also takes
                    into consideration country and industry variations. If there
                    is not sufficient information available, the quality rating
                    will not be assigned or an “N” (not-rated) will be applied
                    for that particular quality criteria.
                  </p>
                </Col>
              </Row>
              <Row>
                {/* <Col md={3}></Col> */}
                <Col md={4}>
                  <h5 className="qualityRatingHeading">
                    Sample Quality Rating
                  </h5>
                  <img
                    src="/home/assets/img/qalityRatings.png"
                    alt="qalityRatings"
                  ></img>
                </Col>
                <Col md={4} style={{ padding: "2em" }}>
                  <Radar
                    data={{
                      labels: [
                        "Investment Acceptance",
                        "Financial Strength",
                        " Profitability & Stability",
                        " Growth",
                      ],
                      datasets: [
                        {
                          backgroundColor: "rgba(  44, 92, 202,0.8)",
                          borderColor: "#5dade2",
                          borderJoinStyle: "round",
                          borderDashOffset: "5",
                          lineTension: "0.5",
                          fontColor: "#fff",
                          fill: true,
                          chart: {
                            height: 350,
                            type: "radar",
                          },
                          scaleLineWidth: 16,
                          scaleLineColor: "#000",
                          data: this.state.radarData,
                          borderWidth: 3,
                        },
                      ],
                    }}
                    style={{ margin: "3em 0 0 0" }}
                    options={{
                      scale: {
                        gridLines: {
                          circular: true,
                          color: "#7B7D7D ",
                          backdropColor: "#000",
                        },
                        pointLabels: {
                          fontColor: "#2c3e50",
                        },
                        ticks: {
                          beginAtZero: true,
                          backdropColor: "transparent",
                          fontColor: "#000",
                          display: false,
                        },
                        chartArea: {
                          backgroundColor: "rgba (247, 247, 246)",
                        },
                      },

                      scaleLineWidth: 16,

                      stroke: {
                        show: true,
                        width: 6,
                        colors: [],
                        dashArray: 0,
                      },
                      pointStyle: "circle",
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </Col>
                <Col md={4} style={{ marginTop: "2em" }}>
                  <img
                    src="/home/assets/img/snowflake_Rating.png"
                    alt="snowflake_Rating"
                  ></img>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}
