import store from "../redux/store";
import FetchServerData from "./FetchServerData";
export const getAmountInWords = (amt, currency) => {
  // return new Promise(function (accept, reject) {

  let currName = "Rupees";
  let currSubunit = "Paisa";
  if (undefined !== currency && null !== currency) {
    currName = currency.unit;
    currSubunit = currency.subunit;
  }
  let a = [
    "",
    "One ",
    "Two ",
    "Three ",
    "Four ",
    "Five ",
    "Six ",
    "Seven ",
    "Eight ",
    "Nine ",
    "Ten ",
    "Eleven ",
    "Twelve ",
    "Thirteen ",
    "Fourteen ",
    "Fifteen ",
    "Sixteen ",
    "Seventeen ",
    "Eighteen ",
    "Nineteen ",
  ];
  let b = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  let number = parseFloat(amt).toFixed(2).split(".");

  let num = parseInt(number[0], 10);
  let digit = parseInt(number[1], 10);
  if (num.toString().length > 11) return "overflow";
  let n = ("000000000" + num)
    .substr(-11)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  let d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
  if (!n) return;
  let str = "";
  str +=
    n[1] !== 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Arab "
      : "";
  str +=
    n[2] !== 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Crore "
      : "";
  str +=
    n[3] !== 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Lakh "
      : "";
  str +=
    n[4] !== 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Thousand "
      : "";
  str +=
    n[5] !== 0
      ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Hundred "
      : "";
  if (num === 1) {
    str +=
      n[6] !== 0
        ? (a[Number(n[6])] || b[n[6][0]] + " " + a[n[6][1]]) + currName + " "
        : "";
  } else {
    str +=
      n[6] !== 0
        ? (a[Number(n[6])] || b[n[6][0]] + " " + a[n[6][1]]) + currName + "s "
        : "" + currName + "s ";
  }
  str +=
    d[1] !== 0
      ? (str !== "" ? "and " : "") +
        (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]]) +
        currSubunit +
        " "
      : "Only!";

  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
  // });
};

export const hasPrivilege = (privilegeName, privilegeList) => {
  if (isNull(privilegeList) || isNull(privilegeName)) {
    return false;
  }
  for (let i = 0; i < privilegeList.length; i++) {
    if (equalsIgnoreCase(privilegeName, privilegeList[i])) {
      return true;
    }
  }

  return false;
};

export const isSupportedCurrency = (input) => {
  let supportedArray = [
    "CAD",
    "HKD",
    "ISK",
    "PHP",
    "DKK",
    "HUF",
    "CZK",
    "GBP",
    "RON",
    "SEK",
    "IDR",
    "INR",
    "BRL",
    "RUB",
    "HRK",
    "JPY",
    "THB",
    "CHF",
    "EUR",
    "MYR",
    "BGN",
    "TRY",
    "CNY",
    "NOK",
    "NZD",
    "ZAR",
    "USD",
    "MXN",
    "SGD",
    "AUD",
    "ILS",
    "KRW",
    "PLN",
  ];
  for (let i = 0; i < supportedArray.length; i++) {
    if (input === supportedArray[i]) {
      return true;
    }
  }
  return false;
};

export const equalsIgnoreCase = (string1, string2) => {
  if (
    undefined === string1 ||
    null === string1 ||
    undefined === string2 ||
    null === string2
  ) {
    return false;
  }
  if (
    string1.toString().trim().toUpperCase() ===
    string2.toString().trim().toUpperCase()
  ) {
    return true;
  }

  return false;
};

export const isNull = (input) => {
  if (undefined === input || null === input) {
    return true;
  }
  return false;
};
export const isNullOrEmpty = (input) => {
  if (undefined === input || null === input || input === "") {
    return true;
  }
  return false;
};

export const isNotNull = (input) => {
  if (undefined === input || null === input) {
    return false;
  }
  return true;
};

export const isNotNullAndEmpty = (input) => {
  if (undefined === input || null === input || input.toString().trim() === "") {
    return false;
  }
  return true;
};

export const getFloatValue = (input) => {
  if (undefined === input || null === input || "" === input) {
    return 0.0;
  }
  return parseFloat(input).toFixed(2);
};

export const getMonth = (monthInNumber) => {
  monthInNumber = Number(monthInNumber);
  let months = {
    1: "JAN",
    2: "FEB",
    3: "MAR",
    4: "APR",
    5: "MAY",
    6: "JUN",
    7: "JUL",
    8: "AUG",
    9: "SEP",
    10: "OCT",
    11: "NOV",
    12: "DEC",
  };
  return months[monthInNumber];
};

export const getMonthNumber = (monthInNumber) => {
  // monthInNumber = Number(monthInNumber);
  let months = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };
  return months[monthInNumber];
};

export const getMonthList = () => {
  return [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
};

export const quarterList = () => {
  return ["APR - JUN", "JUL-SEP", "OCT-DEC", "JAN-MAR"];
};

export const getQuarterList = () => {
  let arr = [];
  arr.push({ month: "APR", quarter: "APR-JUN" });
  arr.push({ month: "JUL", quarter: "JUL-SEP" });
  arr.push({ month: "OCT", quarter: "OCT-DEC" });
  arr.push({ month: "JAN", quarter: "JAN-MAR" });
  return arr;
};

export const getQuarterForMonth = (month) => {
  if (
    equalsIgnoreCase("APR", month) ||
    equalsIgnoreCase("MAY", month) ||
    equalsIgnoreCase("JUN", month)
  ) {
    return { month: "APR", quarter: "APR-JUN" };
  }
  if (
    equalsIgnoreCase("JUL", month) ||
    equalsIgnoreCase("AUG", month) ||
    equalsIgnoreCase("SEP", month)
  ) {
    return { month: "JUL", quarter: "JUL-SEP" };
  }
  if (
    equalsIgnoreCase("OCT", month) ||
    equalsIgnoreCase("NOV", month) ||
    equalsIgnoreCase("DEC", month)
  ) {
    return { month: "OCT", quarter: "OCT-DEC" };
  }
  if (
    equalsIgnoreCase("JAN", month) ||
    equalsIgnoreCase("FEB", month) ||
    equalsIgnoreCase("MAR", month)
  ) {
    return { month: "JAN", quarter: "JAN-MAR" };
  }
};

export const getLogo = () => {
  let imageData = "";
  if (store.getState().appData.logoData) {
    imageData = imageData + store.getState().appData.logoData;
  }
  return imageData;
};

export const getAddressAsText = (address) => {
  if (isNull(address)) {
    return "";
  }
  let finalAddress = "";
  let addressLine1 = address.line1;
  if (addressLine1 && addressLine1.length > 0) {
    finalAddress = addressLine1.toString().trim() + ", ";
  }
  let addressLine2 = address.line2;
  if (addressLine2 && addressLine2.length > 0) {
    finalAddress = finalAddress + addressLine2.toString().trim() + ", ";
  }

  let addressLine = address.line;
  if (addressLine && addressLine.length > 0) {
    finalAddress = finalAddress + addressLine.toString().trim() + ", ";
  }

  let city = address.city;
  if (city && city.length > 0) {
    finalAddress = finalAddress + city.toString().trim() + ", ";
  }

  let state = address.statevo;
  if (state && state.name && state.name.toString().length > 0) {
    finalAddress = finalAddress + state.name.toString().trim() + ", ";
    if (
      state.countryvo &&
      state.countryvo.name &&
      state.countryvo.name.toString().length > 0
    ) {
      finalAddress =
        finalAddress + state.countryvo.name.toString().trim() + ", ";
    }
  } else {
    state = address.state;
    if (state && state.name && state.name.toString().length > 0) {
      finalAddress = finalAddress + state.name.toString().trim() + ", ";
      if (
        state.countryvo &&
        state.countryvo.name &&
        state.countryvo.name.toString().length > 0
      ) {
        finalAddress =
          finalAddress + state.countryvo.name.toString().trim() + ", ";
      }
      if (
        state.country &&
        state.country.name &&
        state.country.name.toString().length > 0
      ) {
        finalAddress =
          finalAddress + state.country.name.toString().trim() + ", ";
      }
    }
  }
  let country = address.country;
  if (country && country.name) {
    finalAddress = finalAddress + country.name.toString().trim() + ", ";
  }
  let pin = address.pin;
  if (pin && pin.length > 0) {
    finalAddress = finalAddress + pin.toString().trim();
  } else if (address.pincode && address.pincode > 0) {
    finalAddress = finalAddress + address.pincode.toString().trim();
  }

  let zip = address.zipCode;
  if (zip && zip.length > 0) {
    finalAddress = finalAddress + zip.toString().trim();
  }

  zip = address.zicode;
  if (zip && zip.length > 0) {
    finalAddress = finalAddress + zip.toString().trim();
  }

  return finalAddress;
};

export const getPercentAmount = (percent, amount) => {
  if (percent && amount && percent > 0 && amount > 0) {
    let val = parseFloat(percent) * parseFloat(amount);
    val = parseFloat(val) / 100;
    return parseFloat(val);
  } else {
    return 0.0;
  }
};
export const valueFormat = (value) => {
  // let values = value.toFixed()
  // var digits = new Number(values).toString().length;
  if (value) {
    var values = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // values = Math.round(values / 10000000)
  //   .toString()
  //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return values;
};
export const valueFormater = (value) => {
  let values = "";
  if (value) {
    values = value.toFixed(2);
    values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    values = "";
  }

  return values;
};

/**
 * @param {string} input - The string
 */
export const toBase64 = (input) => {
  let keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  var output = [];
  var chr1,
    chr2,
    chr3 = "";
  var enc1,
    enc2,
    enc3,
    enc4 = "";
  var i = 0;

  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output.push(
      keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4)
    );
    chr1 = chr2 = chr3 = "";
    enc1 = enc2 = enc3 = enc4 = "";
  } while (i < input.length);

  return output.join("");
};

export const setFCMTokenForUser = (inputHeader) => {
  let header = store.getState().header.header;
  if (!header) {
    header = inputHeader;
  }
  const fcmToken = store.getState().appUserReducer.fcmToken;
  if (!header || !header.appuserId) {
    return;
  }

  if (!fcmToken || fcmToken.length <= 0) {
    /*Fcm Token Not Exists, Create Fcm Token*/

    return;
  }

  /* Call Service to Set Token */
  let requestObject = {
    header: header,
    appuserVo: {
      fcmToken: fcmToken,
    },
  };
  FetchServerData.callPostService("userms/updateFcmToken/", requestObject)
    .then((output) => {
      if (output.status === "SUCCESS") {
      }
    })
    .catch((error) => {});
};

export const getCountryFlag = (companyCountry) => {
  let countries = [
    {
      country: "Algeria",
      flagSrc: "/home/assets/img/country_Flags/Algeria.png",
      countryCode: "",
    },
    {
      country: "Anguilla",
      flagSrc: "/home/assets/img/country_Flags/Anguilla.png",
      countryCode: "",
    },
    {
      country: "Antigua & Barbuda",
      flagSrc: "/home/assets/img/country_Flags/Antigua-and-Barbuda.png",
      countryCode: "",
    },
    {
      country: "Argentina",
      flagSrc: "/home/assets/img/country_Flags/Argentina.png",
      countryCode: "",
    },
    {
      country: "Australia",
      flagSrc: "/home/assets/img/country_Flags/Australia.png",
      countryCode: "",
    },
    {
      country: "Austria",
      flagSrc: "/home/assets/img/country_Flags/Austria.png",
      countryCode: "",
    },
    {
      country: "Azerbaijan",
      flagSrc: "/home/assets/img/country_Flags/Azerbaijan.png",
      countryCode: "",
    },
    {
      country: "Bahamas",
      flagSrc: "/home/assets/img/country_Flags/Bahamas.png",
      countryCode: "",
    },
    {
      country: "Bahrain",
      flagSrc: "/home/assets/img/country_Flags/Bahrain.png",
      countryCode: "",
    },
    {
      country: "Bangladesh",
      flagSrc: "/home/assets/img/country_Flags/Bangladesh.png",
      countryCode: "",
    },
    {
      country: "Barbados",
      flagSrc: "/home/assets/img/country_Flags/Barbados.png",
      countryCode: "",
    },

    {
      country: "Belgium",
      flagSrc: "/home/assets/img/country_Flags/Belgium.png",
      countryCode: "",
    },
    {
      country: "Belize",
      flagSrc: "/home/assets/img/country_Flags/Belize.png",
      countryCode: "",
    },
    {
      country: "Benin",
      flagSrc: "/home/assets/img/country_Flags/Benin.png",
      countryCode: "",
    },
    {
      country: "Bermuda",
      flagSrc: "/home/assets/img/country_Flags/Bermuda.png",
      countryCode: "",
    },
    {
      country: "Bolivia",
      flagSrc: "/home/assets/img/country_Flags/Bolivia.png",
      countryCode: "",
    },
    {
      country: "Bosnia and Herzogovina",
      flagSrc: "/home/assets/img/country_Flags/Bosnia-and-Herzegovina.png",
      countryCode: "",
    },
    {
      country: "Bosnia & Herzegovina",
      flagSrc: "/home/assets/img/country_Flags/Bosnia-and-Herzegovina.png",
      countryCode: "",
    },
    {
      country: "Botswana",
      flagSrc: "/home/assets/img/country_Flags/Botswana.png",
      countryCode: "",
    },
    {
      country: "Brazil",
      flagSrc: "/home/assets/img/country_Flags/Brazil.png",
      countryCode: "",
    },
    {
      country: "Bulgaria",
      flagSrc: "/home/assets/img/country_Flags/Bulgaria.png",
      countryCode: "",
    },
    {
      country: "Burkina Faso",
      flagSrc: "/home/assets/img/country_Flags/Burkina-Faso.png",
      countryCode: "",
    },
    {
      country: "Cambodia",
      flagSrc: "/home/assets/img/country_Flags/Cambodia.png",
      countryCode: "",
    },
    {
      country: "Cameroon",
      flagSrc: "/home/assets/img/country_Flags/Cameroon.png",
      countryCode: "",
    },
    {
      country: "Canada",
      flagSrc: "/home/assets/img/country_Flags/Canada.png",
      countryCode: "",
    },
    {
      country: "Cayman Islands",
      flagSrc: "/home/assets/img/country_Flags/Cayman-Islands.png",
      countryCode: "",
    },
    {
      country: "Chile",
      flagSrc: "/home/assets/img/country_Flags/Chile.png",
      countryCode: "",
    },
    {
      country: "China",
      flagSrc: "/home/assets/img/country_Flags/China.png",
      countryCode: "",
    },
    {
      country: "Colombia",
      flagSrc: "/home/assets/img/country_Flags/Colombia.png",
      countryCode: "",
    },
    {
      country: "Cook Islands",
      flagSrc: "/home/assets/img/country_Flags/Cook-Islands.png",
      countryCode: "",
    },
    {
      country: "Costa Rica",
      flagSrc: "/home/assets/img/country_Flags/Costa-Rica.png",
      countryCode: "",
    },
    {
      country: "Cote D'ivoire",
      flagSrc: "/home/assets/img/country_Flags/Cote-dIvoire.png",
      countryCode: "",
    },

    {
      country: "Croatia",
      flagSrc: "/home/assets/img/country_Flags/Croatia.png",
      countryCode: "",
    },
    {
      country: "Curacao",
      flagSrc: "/home/assets/img/country_Flags/Curacao.png",
      countryCode: "",
    },
    {
      country: "Cyprus",
      flagSrc: "/home/assets/img/country_Flags/Cyprus.png",
      countryCode: "",
    },
    {
      country: "Czech Republic",
      flagSrc: "/home/assets/img/country_Flags/Czech-Republic.png",
      countryCode: "",
    },
    {
      country: "Denmark",
      flagSrc: "/home/assets/img/country_Flags/Denmark.png",
      countryCode: "",
    },
    {
      country: "Dominican Republic",
      flagSrc: "/home/assets/img/country_Flags/Dominican-Republic.png",
      countryCode: "",
    },
    {
      country: "Ecuador",
      flagSrc: "/home/assets/img/country_Flags/Ecuador.png",
      countryCode: "",
    },
    {
      country: "Egypt",
      flagSrc: "/home/assets/img/country_Flags/Egypt.png",
      countryCode: "",
    },
    {
      country: "Estonia",
      flagSrc: "/home/assets/img/country_Flags/Estonia.png",
      countryCode: "",
    },
    {
      country: "Falkland Islands (Malvinas)",
      flagSrc: "/home/assets/img/country_Flags/Falkland-Islands.png",
      countryCode: "",
    },
    {
      country: "Faroe Islands",
      flagSrc: "/home/assets/img/country_Flags/Faroes.png",
      countryCode: "",
    },
    {
      country: "Fiji",
      flagSrc: "/home/assets/img/country_Flags/Fiji.png",
      countryCode: "",
    },
    {
      country: "Finland",
      flagSrc: "/home/assets/img/country_Flags/Finland.png",
      countryCode: "",
    },
    {
      country: "France",
      flagSrc: "/home/assets/img/country_Flags/France.png",
      countryCode: "",
    },
    {
      country: "French Guiana",
      flagSrc: "/home/assets/img/country_Flags/French Guiana.png",
      countryCode: "",
    },
    {
      country: "Gabon",
      flagSrc: "/home/assets/img/country_Flags/Gabon.png",
      countryCode: "",
    },
    {
      country: "Georgia",
      flagSrc: "/home/assets/img/country_Flags/Georgia.png",
      countryCode: "",
    },
    {
      country: "Germany",
      flagSrc: "/home/assets/img/country_Flags/Germany.png",
      countryCode: "",
    },
    {
      country: "Ghana",
      flagSrc: "/home/assets/img/country_Flags/Ghana.png",
      countryCode: "",
    },
    {
      country: "Gibraltar",
      flagSrc: "/home/assets/img/country_Flags/Gibraltar.png",
      countryCode: "",
    },
    {
      country: "Greece",
      flagSrc: "/home/assets/img/country_Flags/Greece.png",
      countryCode: "",
    },

    {
      country: "Greenland",
      flagSrc: "/home/assets/img/country_Flags/Greenland.png",
      countryCode: "",
    },
    {
      country: "Guam",
      flagSrc: "/home/assets/img/country_Flags/Guam.png",
      countryCode: "",
    },
    {
      country: "Guatemala",
      flagSrc: "/home/assets/img/country_Flags/Guatemala.png",
      countryCode: "",
    },
    {
      country: "Guernsey",
      flagSrc: "/home/assets/img/country_Flags/Guernsey.png",
      countryCode: "",
    },
    {
      country: "Hong Kong",
      flagSrc: "/home/assets/img/country_Flags/Hong-Kong.png",
      countryCode: "",
    },
    {
      country: "Honduras",
      flagSrc: "/home/assets/img/country_Flags/Honduras.png",
      countryCode: "",
    },
    {
      country: "Hungary",
      flagSrc: "/home/assets/img/country_Flags/Hungary.png",
      countryCode: "",
    },
    {
      country: "Iceland",
      flagSrc: "/home/assets/img/country_Flags/Iceland.png",
      countryCode: "",
    },
    {
      country: "India",
      flagSrc: "/home/assets/img/country_Flags/India.png",
      countryCode: "",
    },
    {
      country: "Indonesia",
      flagSrc: "/home/assets/img/country_Flags/Indonesia.png",
      countryCode: "",
    },
    {
      country: "Iraq",
      flagSrc: "/home/assets/img/country_Flags/Iraq.png",
      countryCode: "",
    },
    {
      country: "Ireland",
      flagSrc: "/home/assets/img/country_Flags/Ireland.png",
      countryCode: "",
    },
    {
      country: "Isle Of Man",
      flagSrc: "/home/assets/img/country_Flags/Isle-of-Man.png",
      countryCode: "",
    },
    {
      country: "Israel",
      flagSrc: "/home/assets/img/country_Flags/Israel.png",
      countryCode: "",
    },
    {
      country: "Italy",
      flagSrc: "/home/assets/img/country_Flags/Italy.png",
      countryCode: "",
    },
    {
      country: "Jamaica",
      flagSrc: "/home/assets/img/country_Flags/Jamaica.png",
      countryCode: "",
    },
    {
      country: "Japan",
      flagSrc: "/home/assets/img/country_Flags/Japan.png",
      countryCode: "",
    },
    {
      country: "Jersey",
      flagSrc: "/home/assets/img/country_Flags/Jersey.png",
      countryCode: "",
    },
    {
      country: "Jordan",
      flagSrc: "/home/assets/img/country_Flags/Jordan.png",
      countryCode: "",
    },
    {
      country: "Kazakhstan",
      flagSrc: "/home/assets/img/country_Flags/Kazakhstan.png",
      countryCode: "",
    },
    {
      country: "Kenya",
      flagSrc: "/home/assets/img/country_Flags/Kenya.png",
      countryCode: "",
    },
    {
      country: "Korea (South)",
      flagSrc: "/home/assets/img/country_Flags/South-Korea.png",
      countryCode: "",
    },

    {
      country: "Kuwait",
      flagSrc: "/home/assets/img/country_Flags/Kuwait.png",
      countryCode: "",
    },
    {
      country: "Latvia",
      flagSrc: "/home/assets/img/country_Flags/Latvia.png",
      countryCode: "",
    },
    {
      country: "Lebanon",
      flagSrc: "/home/assets/img/country_Flags/Lebanon.png",
      countryCode: "",
    },
    {
      country: "Liechtenstein",
      flagSrc: "/home/assets/img/country_Flags/Liechtenstein.png",
      countryCode: "",
    },
    {
      country: "Lithuania",
      flagSrc: "/home/assets/img/country_Flags/Lithuania.png",
      countryCode: "",
    },
    {
      country: "Luxembourg",
      flagSrc: "/home/assets/img/country_Flags/Luxembourg.png",
      countryCode: "",
    },
    {
      country: "Macau",
      flagSrc: "/home/assets/img/country_Flags/Macau.png",
      countryCode: "",
    },
    {
      country: "Macedonia",
      flagSrc: "/home/assets/img/country_Flags/Macedonia.png",
      countryCode: "",
    },
    {
      country: "Madagascar",
      flagSrc: "/home/assets/img/country_Flags/Madagascar.png",
      countryCode: "",
    },
    {
      country: "Malawi",
      flagSrc: "/home/assets/img/country_Flags/Malawi.png",
      countryCode: "",
    },
    {
      country: "Malaysia",
      flagSrc: "/home/assets/img/country_Flags/Malaysia.png",
      countryCode: "",
    },
    {
      country: "Malta",
      flagSrc: "/home/assets/img/country_Flags/Malta.png",
      countryCode: "",
    },
    {
      country: "Martinique",
      flagSrc: "/home/assets/img/country_Flags/Martinique.png",
      countryCode: "",
    },
    {
      country: "Mauritius",
      flagSrc: "/home/assets/img/country_Flags/Mauritius.png",
      countryCode: "",
    },
    {
      country: "Mexico",
      flagSrc: "/home/assets/img/country_Flags/Mexico.png",
      countryCode: "",
    },
    {
      country: "Moldovia",
      flagSrc: "/home/assets/img/country_Flags/Moldova.png",
      countryCode: "",
    },
    {
      country: "Monaco",
      flagSrc: "/home/assets/img/country_Flags/Monaco.png",
      countryCode: "",
    },
    {
      country: "Mongolia",
      flagSrc: "/home/assets/img/country_Flags/Mongolia.png",
      countryCode: "",
    },
    {
      country: "Montenegro",
      flagSrc: "/home/assets/img/country_Flags/Montenegro.png",
      countryCode: "",
    },
    {
      country: "Morocco",
      flagSrc: "/home/assets/img/country_Flags/Morocco.png",
      countryCode: "",
    },

    {
      country: "Myanmar",
      flagSrc: "/home/assets/img/country_Flags/Myanmar.png",
      countryCode: "",
    },
    {
      country: "Namibia",
      flagSrc: "/home/assets/img/country_Flags/Namibia.png",
      countryCode: "",
    },
    {
      country: "Netherlands",
      flagSrc: "/home/assets/img/country_Flags/Netherlands.png",
      countryCode: "",
    },
    {
      country: "New Zealand",
      flagSrc: "/home/assets/img/country_Flags/New-Zealand.png",
      countryCode: "",
    },
    {
      country: "Niger",
      flagSrc: "/home/assets/img/country_Flags/Niger.png",
      countryCode: "",
    },
    {
      country: "Nigeria",
      flagSrc: "/home/assets/img/country_Flags/Nigeria.png",
      countryCode: "",
    },
    {
      country: "Norway",
      flagSrc: "/home/assets/img/country_Flags/Norway.png",
      countryCode: "",
    },
    {
      country: "Oman",
      flagSrc: "/home/assets/img/country_Flags/Oman.png",
      countryCode: "",
    },
    {
      country: "Pakistan",
      flagSrc: "/home/assets/img/country_Flags/Pakistan.png",
      countryCode: "",
    },
    {
      country: "Palestine",
      flagSrc: "/home/assets/img/country_Flags/Palestine.png",
      countryCode: "",
    },
    {
      country: "Panama",
      flagSrc: "/home/assets/img/country_Flags/Panama.png",
      countryCode: "",
    },
    {
      country: "Papua New Guinea",
      flagSrc: "/home/assets/img/country_Flags/Papua-New-Guinea.png",
      countryCode: "",
    },
    {
      country: "Peru",
      flagSrc: "/home/assets/img/country_Flags/Peru.png",
      countryCode: "",
    },
    {
      country: "Philippines",
      flagSrc: "/home/assets/img/country_Flags/Philippines.png",
      countryCode: "",
    },
    {
      country: "Poland",
      flagSrc: "/home/assets/img/country_Flags/Poland.png",
      countryCode: "",
    },
    {
      country: "Portugal",
      flagSrc: "/home/assets/img/country_Flags/Portugal.png",
      countryCode: "",
    },
    {
      country: "Puerto Rico",
      flagSrc: "/home/assets/img/country_Flags/Puerto-Rico.png",
      countryCode: "",
    },
    {
      country: "Qatar",
      flagSrc: "/home/assets/img/country_Flags/Qatar.png",
      countryCode: "",
    },
    {
      country: "Romania",
      flagSrc: "/home/assets/img/country_Flags/Romania.png",
      countryCode: "",
    },
    {
      country: "Russian Federation",
      flagSrc: "/home/assets/img/country_Flags/Russia.png",
      countryCode: "",
    },

    {
      country: "Rwanda",
      flagSrc: "/home/assets/img/country_Flags/Rwanda.png",
      countryCode: "",
    },
    {
      country: "Saint Lucia",
      flagSrc: "/home/assets/img/country_Flags/Saint-Lucia.png",
      countryCode: "",
    },
    {
      country: "Saudi Arabia",
      flagSrc: "/home/assets/img/country_Flags/Saudi-Arabia.png",
      countryCode: "",
    },
    {
      country: "Senegal",
      flagSrc: "/home/assets/img/country_Flags/Senegal.png",
      countryCode: "",
    },
    // {
    //   country: "Jordan",
    //   flagSrc: "/home/assets/img/country_Flags/Jordan.jpg",
    //   countryCode: "",
    // },
    {
      country: "Serbia",
      flagSrc: "/home/assets/img/country_Flags/Serbia.png",
      countryCode: "",
    },
    {
      country: "Sierra Leone",
      flagSrc: "/home/assets/img/country_Flags/Sierra-Leone.png",
      countryCode: "",
    },
    {
      country: "Singapore",
      flagSrc: "/home/assets/img/country_Flags/Singapore.png",
      countryCode: "",
    },
    {
      country: "Slovakia",
      flagSrc: "/home/assets/img/country_Flags/Slovakia.png",
      countryCode: "",
    },
    {
      country: "Slovenia",
      flagSrc: "/home/assets/img/country_Flags/Slovenia.png",
      countryCode: "",
    },
    {
      country: "South Africa",
      flagSrc: "/home/assets/img/country_Flags/South-Africa.png",
      countryCode: "",
    },
    {
      country: "South Korea",
      flagSrc: "/home/assets/img/country_Flags/South-Korea.png",
      countryCode: "",
    },
    {
      country: "Spain",
      flagSrc: "/home/assets/img/country_Flags/Spain.png",
      countryCode: "",
    },
    {
      country: "Sri Lanka",
      flagSrc: "/home/assets/img/country_Flags/Sri-Lanka.png",
      countryCode: "",
    },
    {
      country: "Sudan",
      flagSrc: "/home/assets/img/country_Flags/Sudan.png",
      countryCode: "",
    },
    {
      country: "Sweden",
      flagSrc: "/home/assets/img/country_Flags/Sweden.png",
      countryCode: "",
    },
    {
      country: "Switzerland",
      flagSrc: "/home/assets/img/country_Flags/Switzerland.png",
      countryCode: "",
    },
    {
      country: "Syria",
      flagSrc: "/home/assets/img/country_Flags/Syria.png",
      countryCode: "",
    },
    {
      country: "Taiwan",
      flagSrc: "/home/assets/img/country_Flags/Taiwan.png",
      countryCode: "",
    },
    {
      country: "Tanzania",
      flagSrc: "/home/assets/img/country_Flags/Tanzania.png",
      countryCode: "",
    },
    {
      country: "Thailand",
      flagSrc: "/home/assets/img/country_Flags/Thailand.png",
      countryCode: "",
    },

    {
      country: "Togo",
      flagSrc: "/home/assets/img/country_Flags/Togo.png",
      countryCode: "",
    },
    {
      country: "Trinidad & Tobago",
      flagSrc: "/home/assets/img/country_Flags/Trinidad-and-Tobago.png",
      countryCode: "",
    },
    {
      country: "Tunisia",
      flagSrc: "/home/assets/img/country_Flags/Tunisia.png",
      countryCode: "",
    },
    {
      country: "Turkey",
      flagSrc: "/home/assets/img/country_Flags/Turkey.png",
      countryCode: "",
    },
    {
      country: "Turks & Caicos Islands",
      flagSrc: "/home/assets/img/country_Flags/Turks-and-Caicos-Islands.png",
      countryCode: "",
    },
    {
      country: "Uganda",
      flagSrc: "/home/assets/img/country_Flags/Uganda.png",
      countryCode: "",
    },
    {
      country: "Ukraine",
      flagSrc: "/home/assets/img/country_Flags/Ukraine.png",
      countryCode: "",
    },
    {
      country: "United Arab Emirates",
      flagSrc: "/home/assets/img/country_Flags/United-Arab-Emirates.png",
      countryCode: "",
    },
    {
      country: "United Kingdom",
      flagSrc: "/home/assets/img/country_Flags/United-Kingdom.png",
      countryCode: "",
    },
    {
      country: "United States",
      flagSrc: "/home/assets/img/country_Flags/United-States.png",
      countryCode: "",
    },
    {
      country: "United States Virgin Islands",
      flagSrc: "/home/assets/img/country_Flags/US-Virgin-Islands.png",
      countryCode: "",
    },
    {
      country: "Uruguay",
      flagSrc: "/home/assets/img/country_Flags/Uruguay.png",
      countryCode: "",
    },
    {
      country: "Venezuela",
      flagSrc: "/home/assets/img/country_Flags/Venezuela.png",
      countryCode: "",
    },
    {
      country: "Vietnam",
      flagSrc: "/home/assets/img/country_Flags/Vietnam.png",
      countryCode: "",
    },
    {
      country: "Virgin Islands (Brit)",
      flagSrc: "/home/assets/img/country_Flags/British-Virgin-Islands.png",
      countryCode: "",
    },
    {
      country: "Zambia",
      flagSrc: "/home/assets/img/country_Flags/Zambia.png",
      countryCode: "",
    },
    {
      country: "Zimbabwe",
      flagSrc: "/home/assets/img/country_Flags/Zimbabwe.png",
      countryCode: "",
    },
    {
      country: "Paraguay",
      flagSrc: "/home/assets/img/country_Flags/Paraguay.png",
      countryCode: "",
    },
    {
      country: "Europe",
      flagSrc: "/home/assets/img/country_Flags/Europe.png",
      countryCode: "",
    },
  ];
  let countryFlagSrc = "";

  for (let i = 0; i < countries.length; i++) {
    if (
      companyCountry.toUpperCase().trim() === countries[i].country.toUpperCase()
    ) {
      return (
        <img
          src={countries[i].flagSrc}
          alt="flag"
          style={{
            height: "2em",
            marginLeft: "0.3em",
          }}
        ></img>
      );
    }
  }
  return countryFlagSrc;
};

export const getCountryData = () => {
  let countries = [
    {
      alpha2: "AC",
      alpha3: "",
      countryCallingCodes: ["+247"],
      currencies: ["USD"],
      emoji: "",
      ioc: "SHP",
      languages: ["eng"],
      name: "Ascension Island",
      status: "reserved",
    },
    {
      alpha2: "AD",
      alpha3: "AND",
      countryCallingCodes: ["+376"],
      currencies: ["EUR"],
      emoji: "🇦🇩",
      ioc: "AND",
      languages: ["cat"],
      name: "Andorra",
      status: "assigned",
    },
    {
      alpha2: "AE",
      alpha3: "ARE",
      countryCallingCodes: ["+971"],
      currencies: ["AED"],
      emoji: "🇦🇪",
      ioc: "UAE",
      languages: ["ara"],
      name: "United Arab Emirates",
      status: "assigned",
    },
    {
      alpha2: "AF",
      alpha3: "AFG",
      countryCallingCodes: ["+93"],
      currencies: ["AFN"],
      emoji: "🇦🇫",
      ioc: "AFG",
      languages: ["pus"],
      name: "Afghanistan",
      status: "assigned",
    },
    {
      alpha2: "AG",
      alpha3: "ATG",
      countryCallingCodes: ["+1 268"],
      currencies: ["XCD"],
      emoji: "🇦🇬",
      ioc: "ANT",
      languages: ["eng"],
      name: "Antigua And Barbuda",
      status: "assigned",
    },
    {
      alpha2: "AI",
      alpha3: "AIA",
      countryCallingCodes: ["+1 264"],
      currencies: ["XCD"],
      emoji: "🇦🇮",
      ioc: "",
      languages: ["eng"],
      name: "Anguilla",
      status: "assigned",
    },
    {
      alpha2: "AI",
      alpha3: "AFI",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "French Afar and Issas",
      status: "deleted",
    },
    {
      alpha2: "AL",
      alpha3: "ALB",
      countryCallingCodes: ["+355"],
      currencies: ["ALL"],
      emoji: "🇦🇱",
      ioc: "ALB",
      languages: ["sqi"],
      name: "Albania",
      status: "assigned",
    },
    {
      alpha2: "AM",
      alpha3: "ARM",
      countryCallingCodes: ["+374"],
      currencies: ["AMD"],
      emoji: "🇦🇲",
      ioc: "ARM",
      languages: ["hye", "rus"],
      name: "Armenia",
      status: "assigned",
    },
    {
      alpha2: "AN",
      alpha3: "ANT",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Netherlands Antilles",
      status: "deleted",
    },
    {
      alpha2: "AO",
      alpha3: "AGO",
      countryCallingCodes: ["+244"],
      currencies: ["AOA"],
      emoji: "🇦🇴",
      ioc: "ANG",
      languages: ["por"],
      name: "Angola",
      status: "assigned",
    },
    {
      alpha2: "AQ",
      alpha3: "ATA",
      countryCallingCodes: ["+672"],
      currencies: [],
      emoji: "🇦🇶",
      ioc: "",
      languages: [],
      name: "Antarctica",
      status: "assigned",
    },
    {
      alpha2: "AR",
      alpha3: "ARG",
      countryCallingCodes: ["+54"],
      currencies: ["ARS"],
      emoji: "🇦🇷",
      ioc: "ARG",
      languages: ["spa"],
      name: "Argentina",
      status: "assigned",
    },
    {
      alpha2: "AS",
      alpha3: "ASM",
      countryCallingCodes: ["+1 684"],
      currencies: ["USD"],
      emoji: "🇦🇸",
      ioc: "ASA",
      languages: ["eng", "smo"],
      name: "American Samoa",
      status: "assigned",
    },
    {
      alpha2: "AT",
      alpha3: "AUT",
      countryCallingCodes: ["+43"],
      currencies: ["EUR"],
      emoji: "🇦🇹",
      ioc: "AUT",
      languages: ["deu"],
      name: "Austria",
      status: "assigned",
    },
    {
      alpha2: "AU",
      alpha3: "AUS",
      countryCallingCodes: ["+61"],
      currencies: ["AUD"],
      emoji: "🇦🇺",
      ioc: "AUS",
      languages: ["eng"],
      name: "Australia",
      status: "assigned",
    },
    {
      alpha2: "AW",
      alpha3: "ABW",
      countryCallingCodes: ["+297"],
      currencies: ["AWG"],
      emoji: "🇦🇼",
      ioc: "ARU",
      languages: ["nld"],
      name: "Aruba",
      status: "assigned",
    },
    {
      alpha2: "AX",
      alpha3: "ALA",
      countryCallingCodes: ["+358"],
      currencies: ["EUR"],
      emoji: "🇦🇽",
      ioc: "",
      languages: ["swe"],
      name: "Åland Islands",
      status: "assigned",
    },
    {
      alpha2: "AZ",
      alpha3: "AZE",
      countryCallingCodes: ["+994"],
      currencies: ["AZN"],
      emoji: "🇦🇿",
      ioc: "AZE",
      languages: ["aze"],
      name: "Azerbaijan",
      status: "assigned",
    },
    {
      alpha2: "BA",
      alpha3: "BIH",
      countryCallingCodes: ["+387"],
      currencies: ["BAM"],
      emoji: "🇧🇦",
      ioc: "BIH",
      languages: ["bos", "cre", "srp"],
      name: "Bosnia & Herzegovina",
      status: "assigned",
    },
    {
      alpha2: "BB",
      alpha3: "BRB",
      countryCallingCodes: ["+1 246"],
      currencies: ["BBD"],
      emoji: "🇧🇧",
      ioc: "BAR",
      languages: ["eng"],
      name: "Barbados",
      status: "assigned",
    },
    {
      alpha2: "BD",
      alpha3: "BGD",
      countryCallingCodes: ["+880"],
      currencies: ["BDT"],
      emoji: "🇧🇩",
      ioc: "BAN",
      languages: ["ben"],
      name: "Bangladesh",
      status: "assigned",
    },
    {
      alpha2: "BE",
      alpha3: "BEL",
      countryCallingCodes: ["+32"],
      currencies: ["EUR"],
      emoji: "🇧🇪",
      ioc: "BEL",
      languages: ["nld", "fra", "deu"],
      name: "Belgium",
      status: "assigned",
    },
    {
      alpha2: "BF",
      alpha3: "BFA",
      countryCallingCodes: ["+226"],
      currencies: ["XOF"],
      emoji: "🇧🇫",
      ioc: "BUR",
      languages: ["fra"],
      name: "Burkina Faso",
      status: "assigned",
    },
    {
      alpha2: "BG",
      alpha3: "BGR",
      countryCallingCodes: ["+359"],
      currencies: ["BGN"],
      emoji: "🇧🇬",
      ioc: "BUL",
      languages: ["bul"],
      name: "Bulgaria",
      status: "assigned",
    },
    {
      alpha2: "BH",
      alpha3: "BHR",
      countryCallingCodes: ["+973"],
      currencies: ["BHD"],
      emoji: "🇧🇭",
      ioc: "BRN",
      languages: ["ara"],
      name: "Bahrain",
      status: "assigned",
    },
    {
      alpha2: "BI",
      alpha3: "BDI",
      countryCallingCodes: ["+257"],
      currencies: ["BIF"],
      emoji: "🇧🇮",
      ioc: "BDI",
      languages: ["fra"],
      name: "Burundi",
      status: "assigned",
    },
    {
      alpha2: "BJ",
      alpha3: "BEN",
      countryCallingCodes: ["+229"],
      currencies: ["XOF"],
      emoji: "🇧🇯",
      ioc: "BEN",
      languages: ["fra"],
      name: "Benin",
      status: "assigned",
    },
    {
      alpha2: "BL",
      alpha3: "BLM",
      countryCallingCodes: ["+590"],
      currencies: ["EUR"],
      emoji: "🇧🇱",
      ioc: "",
      languages: ["fra"],
      name: "Saint Barthélemy",
      status: "assigned",
    },
    {
      alpha2: "BM",
      alpha3: "BMU",
      countryCallingCodes: ["+1 441"],
      currencies: ["BMD"],
      emoji: "🇧🇲",
      ioc: "BER",
      languages: ["eng"],
      name: "Bermuda",
      status: "assigned",
    },
    {
      alpha2: "BN",
      alpha3: "BRN",
      countryCallingCodes: ["+673"],
      currencies: ["BND"],
      emoji: "🇧🇳",
      ioc: "BRU",
      languages: ["msa", "eng"],
      name: "Brunei Darussalam",
      status: "assigned",
    },
    {
      alpha2: "BO",
      alpha3: "BOL",
      countryCallingCodes: ["+591"],
      currencies: ["BOB", "BOV"],
      emoji: "🇧🇴",
      ioc: "BOL",
      languages: ["spa", "aym", "que"],
      name: "Bolivia, Plurinational State Of",
      status: "assigned",
    },
    {
      alpha2: "BQ",
      alpha3: "BES",
      countryCallingCodes: ["+599"],
      currencies: ["USD"],
      emoji: "🇧🇶",
      ioc: "",
      languages: ["nld"],
      name: "Bonaire, Saint Eustatius And Saba",
      status: "assigned",
    },
    {
      alpha2: "BQ",
      alpha3: "ATB",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "British Antarctic Territory",
      status: "deleted",
    },
    {
      alpha2: "BR",
      alpha3: "BRA",
      countryCallingCodes: ["+55"],
      currencies: ["BRL"],
      emoji: "🇧🇷",
      ioc: "BRA",
      languages: ["por"],
      name: "Brazil",
      status: "assigned",
    },
    {
      alpha2: "BS",
      alpha3: "BHS",
      countryCallingCodes: ["+1 242"],
      currencies: ["BSD"],
      emoji: "🇧🇸",
      ioc: "BAH",
      languages: ["eng"],
      name: "Bahamas",
      status: "assigned",
    },
    {
      alpha2: "BT",
      alpha3: "BTN",
      countryCallingCodes: ["+975"],
      currencies: ["INR", "BTN"],
      emoji: "🇧🇹",
      ioc: "BHU",
      languages: ["dzo"],
      name: "Bhutan",
      status: "assigned",
    },
    {
      alpha2: "BU",
      alpha3: "BUR",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Burma",
      status: "deleted",
    },
    {
      alpha2: "BV",
      alpha3: "BVT",
      countryCallingCodes: [],
      currencies: ["NOK"],
      emoji: "🇧🇻",
      ioc: "",
      languages: [],
      name: "Bouvet Island",
      status: "assigned",
    },
    {
      alpha2: "BW",
      alpha3: "BWA",
      countryCallingCodes: ["+267"],
      currencies: ["BWP"],
      emoji: "🇧🇼",
      ioc: "BOT",
      languages: ["eng", "tsn"],
      name: "Botswana",
      status: "assigned",
    },
    {
      alpha2: "BY",
      alpha3: "BLR",
      countryCallingCodes: ["+375"],
      currencies: ["BYR"],
      emoji: "🇧🇾",
      ioc: "BLR",
      languages: ["bel", "rus"],
      name: "Belarus",
      status: "assigned",
    },
    {
      alpha2: "BY",
      alpha3: "BYS",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Byelorussian SSR",
      status: "deleted",
    },
    {
      alpha2: "BZ",
      alpha3: "BLZ",
      countryCallingCodes: ["+501"],
      currencies: ["BZD"],
      emoji: "🇧🇿",
      ioc: "BIZ",
      languages: ["eng"],
      name: "Belize",
      status: "assigned",
    },
    {
      alpha2: "CA",
      alpha3: "CAN",
      countryCallingCodes: ["+1"],
      currencies: ["CAD"],
      emoji: "🇨🇦",
      ioc: "CAN",
      languages: ["eng", "fra"],
      name: "Canada",
      status: "assigned",
    },
    {
      alpha2: "CC",
      alpha3: "CCK",
      countryCallingCodes: ["+61"],
      currencies: ["AUD"],
      emoji: "🇨🇨",
      ioc: "",
      languages: ["eng"],
      name: "Cocos (Keeling) Islands",
      status: "assigned",
    },
    {
      alpha2: "CD",
      alpha3: "COD",
      countryCallingCodes: ["+243"],
      currencies: ["CDF"],
      emoji: "🇨🇩",
      ioc: "COD",
      languages: ["fra", "lin", "kon", "swa"],
      name: "Democratic Republic Of Congo",
      status: "assigned",
    },
    {
      alpha2: "CF",
      alpha3: "CAF",
      countryCallingCodes: ["+236"],
      currencies: ["XAF"],
      emoji: "🇨🇫",
      ioc: "CAF",
      languages: ["fra", "sag"],
      name: "Central African Republic",
      status: "assigned",
    },
    {
      alpha2: "CG",
      alpha3: "COG",
      countryCallingCodes: ["+242"],
      currencies: ["XAF"],
      emoji: "🇨🇬",
      ioc: "CGO",
      languages: ["fra", "lin"],
      name: "Republic Of Congo",
      status: "assigned",
    },
    {
      alpha2: "CH",
      alpha3: "CHE",
      countryCallingCodes: ["+41"],
      currencies: ["CHF", "CHE", "CHW"],
      emoji: "🇨🇭",
      ioc: "SUI",
      languages: ["deu", "fra", "ita", "roh"],
      name: "Switzerland",
      status: "assigned",
    },
    {
      alpha2: "CI",
      alpha3: "CIV",
      countryCallingCodes: ["+225"],
      currencies: ["XOF"],
      emoji: "🇨🇮",
      ioc: "CIV",
      languages: ["fra"],
      name: "Côte d'Ivoire",
      status: "assigned",
    },
    {
      alpha2: "CK",
      alpha3: "COK",
      countryCallingCodes: ["+682"],
      currencies: ["NZD"],
      emoji: "🇨🇰",
      ioc: "COK",
      languages: ["eng", "mri"],
      name: "Cook Islands",
      status: "assigned",
    },
    {
      alpha2: "CL",
      alpha3: "CHL",
      countryCallingCodes: ["+56"],
      currencies: ["CLP", "CLF"],
      emoji: "🇨🇱",
      ioc: "CHI",
      languages: ["spa"],
      name: "Chile",
      status: "assigned",
    },
    {
      alpha2: "CM",
      alpha3: "CMR",
      countryCallingCodes: ["+237"],
      currencies: ["XAF"],
      emoji: "🇨🇲",
      ioc: "CMR",
      languages: ["eng", "fra"],
      name: "Cameroon",
      status: "assigned",
    },
    {
      alpha2: "CN",
      alpha3: "CHN",
      countryCallingCodes: ["+86"],
      currencies: ["CNY"],
      emoji: "🇨🇳",
      ioc: "CHN",
      languages: ["zho"],
      name: "China",
      status: "assigned",
    },
    {
      alpha2: "CO",
      alpha3: "COL",
      countryCallingCodes: ["+57"],
      currencies: ["COP", "COU"],
      emoji: "🇨🇴",
      ioc: "COL",
      languages: ["spa"],
      name: "Colombia",
      status: "assigned",
    },
    {
      alpha2: "CP",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["EUR"],
      emoji: "",
      ioc: "",
      languages: [],
      name: "Clipperton Island",
      status: "reserved",
    },
    {
      alpha2: "CR",
      alpha3: "CRI",
      countryCallingCodes: ["+506"],
      currencies: ["CRC"],
      emoji: "🇨🇷",
      ioc: "CRC",
      languages: ["spa"],
      name: "Costa Rica",
      status: "assigned",
    },
    {
      alpha2: "CS",
      alpha3: "CSK",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Czechoslovakia",
      status: "deleted",
    },
    {
      alpha2: "CS",
      alpha3: "SCG",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Serbia and Montenegro",
      status: "deleted",
    },
    {
      alpha2: "CT",
      alpha3: "CTE",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Canton and Enderbury Islands",
      status: "deleted",
    },
    {
      alpha2: "CU",
      alpha3: "CUB",
      countryCallingCodes: ["+53"],
      currencies: ["CUP", "CUC"],
      emoji: "🇨🇺",
      ioc: "CUB",
      languages: ["spa"],
      name: "Cuba",
      status: "assigned",
    },
    {
      alpha2: "CV",
      alpha3: "CPV",
      countryCallingCodes: ["+238"],
      currencies: ["CVE"],
      emoji: "🇨🇻",
      ioc: "CPV",
      languages: ["por"],
      name: "Cabo Verde",
      status: "assigned",
    },
    {
      alpha2: "CW",
      alpha3: "CUW",
      countryCallingCodes: ["+599"],
      currencies: ["ANG"],
      emoji: "🇨🇼",
      ioc: "",
      languages: ["nld"],
      name: "Curacao",
      status: "assigned",
    },
    {
      alpha2: "CX",
      alpha3: "CXR",
      countryCallingCodes: ["+61"],
      currencies: ["AUD"],
      emoji: "🇨🇽",
      ioc: "",
      languages: ["eng"],
      name: "Christmas Island",
      status: "assigned",
    },
    {
      alpha2: "CY",
      alpha3: "CYP",
      countryCallingCodes: ["+357"],
      currencies: ["EUR"],
      emoji: "🇨🇾",
      ioc: "CYP",
      languages: ["ell", "tur"],
      name: "Cyprus",
      status: "assigned",
    },
    {
      alpha2: "CZ",
      alpha3: "CZE",
      countryCallingCodes: ["+420"],
      currencies: ["CZK"],
      emoji: "🇨🇿",
      ioc: "CZE",
      languages: ["ces"],
      name: "Czech Republic",
      status: "assigned",
    },
    {
      alpha2: "DD",
      alpha3: "DDR",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "German Democratic Republic",
      status: "deleted",
    },
    {
      alpha2: "DE",
      alpha3: "DEU",
      countryCallingCodes: ["+49"],
      currencies: ["EUR"],
      emoji: "🇩🇪",
      ioc: "GER",
      languages: ["deu"],
      name: "Germany",
      status: "assigned",
    },
    {
      alpha2: "DG",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["USD"],
      emoji: "",
      ioc: "",
      languages: [],
      name: "Diego Garcia",
      status: "reserved",
    },
    {
      alpha2: "DJ",
      alpha3: "DJI",
      countryCallingCodes: ["+253"],
      currencies: ["DJF"],
      emoji: "🇩🇯",
      ioc: "DJI",
      languages: ["ara", "fra"],
      name: "Djibouti",
      status: "assigned",
    },
    {
      alpha2: "DK",
      alpha3: "DNK",
      countryCallingCodes: ["+45"],
      currencies: ["DKK"],
      emoji: "🇩🇰",
      ioc: "DEN",
      languages: ["dan"],
      name: "Denmark",
      status: "assigned",
    },
    {
      alpha2: "DM",
      alpha3: "DMA",
      countryCallingCodes: ["+1 767"],
      currencies: ["XCD"],
      emoji: "🇩🇲",
      ioc: "DMA",
      languages: ["eng"],
      name: "Dominica",
      status: "assigned",
    },
    {
      alpha2: "DO",
      alpha3: "DOM",
      countryCallingCodes: ["+1 809", "+1 829", "+1 849"],
      currencies: ["DOP"],
      emoji: "🇩🇴",
      ioc: "DOM",
      languages: ["spa"],
      name: "Dominican Republic",
      status: "assigned",
    },
    {
      alpha2: "DY",
      alpha3: "DHY",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Dahomey",
      status: "deleted",
    },
    {
      alpha2: "DZ",
      alpha3: "DZA",
      countryCallingCodes: ["+213"],
      currencies: ["DZD"],
      emoji: "🇩🇿",
      ioc: "ALG",
      languages: ["ara"],
      name: "Algeria",
      status: "assigned",
    },
    {
      alpha2: "EA",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["EUR"],
      emoji: "",
      ioc: "",
      languages: [],
      name: "Ceuta, Mulilla",
      status: "reserved",
    },
    {
      alpha2: "EC",
      alpha3: "ECU",
      countryCallingCodes: ["+593"],
      currencies: ["USD"],
      emoji: "🇪🇨",
      ioc: "ECU",
      languages: ["spa", "que"],
      name: "Ecuador",
      status: "assigned",
    },
    {
      alpha2: "EE",
      alpha3: "EST",
      countryCallingCodes: ["+372"],
      currencies: ["EUR"],
      emoji: "🇪🇪",
      ioc: "EST",
      languages: ["est"],
      name: "Estonia",
      status: "assigned",
    },
    {
      alpha2: "EG",
      alpha3: "EGY",
      countryCallingCodes: ["+20"],
      currencies: ["EGP"],
      emoji: "🇪🇬",
      ioc: "EGY",
      languages: ["ara"],
      name: "Egypt",
      status: "assigned",
    },
    {
      alpha2: "EH",
      alpha3: "ESH",
      countryCallingCodes: ["+212"],
      currencies: ["MAD"],
      emoji: "🇪🇭",
      ioc: "",
      languages: [],
      name: "Western Sahara",
      status: "assigned",
    },
    {
      alpha2: "ER",
      alpha3: "ERI",
      countryCallingCodes: ["+291"],
      currencies: ["ERN"],
      emoji: "🇪🇷",
      ioc: "ERI",
      languages: ["eng", "ara", "tir"],
      name: "Eritrea",
      status: "assigned",
    },
    {
      alpha2: "ES",
      alpha3: "ESP",
      countryCallingCodes: ["+34"],
      currencies: ["EUR"],
      emoji: "🇪🇸",
      ioc: "ESP",
      languages: ["spa"],
      name: "Spain",
      status: "assigned",
    },
    {
      alpha2: "ET",
      alpha3: "ETH",
      countryCallingCodes: ["+251"],
      currencies: ["ETB"],
      emoji: "🇪🇹",
      ioc: "ETH",
      languages: ["amh"],
      name: "Ethiopia",
      status: "assigned",
    },
    {
      alpha2: "EU",
      alpha3: "",
      countryCallingCodes: ["+388"],
      currencies: ["EUR"],
      emoji: "🇪🇺",
      ioc: "",
      languages: [],
      name: "European Union",
      status: "reserved",
    },
    {
      alpha2: "FI",
      alpha3: "FIN",
      countryCallingCodes: ["+358"],
      currencies: ["EUR"],
      emoji: "🇫🇮",
      ioc: "FIN",
      languages: ["fin", "swe"],
      name: "Finland",
      status: "assigned",
    },
    {
      alpha2: "FJ",
      alpha3: "FJI",
      countryCallingCodes: ["+679"],
      currencies: ["FJD"],
      emoji: "🇫🇯",
      ioc: "FIJ",
      languages: ["eng", "fij"],
      name: "Fiji",
      status: "assigned",
    },
    {
      alpha2: "FK",
      alpha3: "FLK",
      countryCallingCodes: ["+500"],
      currencies: ["FKP"],
      emoji: "🇫🇰",
      ioc: "",
      languages: ["eng"],
      name: "Falkland Islands",
      status: "assigned",
    },
    {
      alpha2: "FM",
      alpha3: "FSM",
      countryCallingCodes: ["+691"],
      currencies: ["USD"],
      emoji: "🇫🇲",
      ioc: "FSM",
      languages: ["eng"],
      name: "Micronesia, Federated States Of",
      status: "assigned",
    },
    {
      alpha2: "FO",
      alpha3: "FRO",
      countryCallingCodes: ["+298"],
      currencies: ["DKK"],
      emoji: "🇫🇴",
      ioc: "FAI",
      languages: ["fao", "dan"],
      name: "Faroe Islands",
      status: "assigned",
    },
    {
      alpha2: "FQ",
      alpha3: "ATF",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "French Southern and Antarctic Territories",
      status: "deleted",
    },
    {
      alpha2: "FR",
      alpha3: "FRA",
      countryCallingCodes: ["+33"],
      currencies: ["EUR"],
      emoji: "🇫🇷",
      ioc: "FRA",
      languages: ["fra"],
      name: "France",
      status: "assigned",
    },
    {
      alpha2: "FX",
      alpha3: "",
      countryCallingCodes: ["+241"],
      currencies: ["EUR"],
      emoji: "",
      ioc: "",
      languages: ["fra"],
      name: "France, Metropolitan",
      status: "reserved",
    },
    {
      alpha2: "GA",
      alpha3: "GAB",
      countryCallingCodes: ["+241"],
      currencies: ["XAF"],
      emoji: "🇬🇦",
      ioc: "GAB",
      languages: ["fra"],
      name: "Gabon",
      status: "assigned",
    },
    {
      alpha2: "GB",
      alpha3: "GBR",
      countryCallingCodes: ["+44"],
      currencies: ["GBP"],
      emoji: "🇬🇧",
      ioc: "GBR",
      languages: ["eng", "cor", "gle", "gla", "cym"],
      name: "United Kingdom",
      status: "assigned",
    },
    {
      alpha2: "GD",
      alpha3: "GRD",
      countryCallingCodes: ["+473"],
      currencies: ["XCD"],
      emoji: "🇬🇩",
      ioc: "GRN",
      languages: ["eng"],
      name: "Grenada",
      status: "assigned",
    },
    {
      alpha2: "GE",
      alpha3: "GEO",
      countryCallingCodes: ["+995"],
      currencies: ["GEL"],
      emoji: "🇬🇪",
      ioc: "GEO",
      languages: ["kat"],
      name: "Georgia",
      status: "assigned",
    },
    {
      alpha2: "GE",
      alpha3: "GEL",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Gilbert and Ellice Islands",
      status: "deleted",
    },
    {
      alpha2: "GF",
      alpha3: "GUF",
      countryCallingCodes: ["+594"],
      currencies: ["EUR"],
      emoji: "🇬🇫",
      ioc: "",
      languages: ["fra"],
      name: "French Guiana",
      status: "assigned",
    },
    {
      alpha2: "GG",
      alpha3: "GGY",
      countryCallingCodes: ["+44"],
      currencies: ["GBP"],
      emoji: "🇬🇬",
      ioc: "GCI",
      languages: ["fra"],
      name: "Guernsey",
      status: "assigned",
    },
    {
      alpha2: "GH",
      alpha3: "GHA",
      countryCallingCodes: ["+233"],
      currencies: ["GHS"],
      emoji: "🇬🇭",
      ioc: "GHA",
      languages: ["eng"],
      name: "Ghana",
      status: "assigned",
    },
    {
      alpha2: "GI",
      alpha3: "GIB",
      countryCallingCodes: ["+350"],
      currencies: ["GIP"],
      emoji: "🇬🇮",
      ioc: "",
      languages: ["eng"],
      name: "Gibraltar",
      status: "assigned",
    },
    {
      alpha2: "GL",
      alpha3: "GRL",
      countryCallingCodes: ["+299"],
      currencies: ["DKK"],
      emoji: "🇬🇱",
      ioc: "",
      languages: ["kal"],
      name: "Greenland",
      status: "assigned",
    },
    {
      alpha2: "GM",
      alpha3: "GMB",
      countryCallingCodes: ["+220"],
      currencies: ["GMD"],
      emoji: "🇬🇲",
      ioc: "GAM",
      languages: ["eng"],
      name: "Gambia",
      status: "assigned",
    },
    {
      alpha2: "GN",
      alpha3: "GIN",
      countryCallingCodes: ["+224"],
      currencies: ["GNF"],
      emoji: "🇬🇳",
      ioc: "GUI",
      languages: ["fra"],
      name: "Guinea",
      status: "assigned",
    },
    {
      alpha2: "GP",
      alpha3: "GLP",
      countryCallingCodes: ["+590"],
      currencies: ["EUR"],
      emoji: "🇬🇵",
      ioc: "",
      languages: ["fra"],
      name: "Guadeloupe",
      status: "assigned",
    },
    {
      alpha2: "GQ",
      alpha3: "GNQ",
      countryCallingCodes: ["+240"],
      currencies: ["XAF"],
      emoji: "🇬🇶",
      ioc: "GEQ",
      languages: ["spa", "fra", "por"],
      name: "Equatorial Guinea",
      status: "assigned",
    },
    {
      alpha2: "GR",
      alpha3: "GRC",
      countryCallingCodes: ["+30"],
      currencies: ["EUR"],
      emoji: "🇬🇷",
      ioc: "GRE",
      languages: ["ell"],
      name: "Greece",
      status: "assigned",
    },
    {
      alpha2: "GS",
      alpha3: "SGS",
      countryCallingCodes: [],
      currencies: ["GBP"],
      emoji: "🇬🇸",
      ioc: "",
      languages: ["eng"],
      name: "South Georgia And The South Sandwich Islands",
      status: "assigned",
    },
    {
      alpha2: "GT",
      alpha3: "GTM",
      countryCallingCodes: ["+502"],
      currencies: ["GTQ"],
      emoji: "🇬🇹",
      ioc: "GUA",
      languages: ["spa"],
      name: "Guatemala",
      status: "assigned",
    },
    {
      alpha2: "GU",
      alpha3: "GUM",
      countryCallingCodes: ["+1 671"],
      currencies: ["USD"],
      emoji: "🇬🇺",
      ioc: "GUM",
      languages: ["eng"],
      name: "Guam",
      status: "assigned",
    },
    {
      alpha2: "GW",
      alpha3: "GNB",
      countryCallingCodes: ["+245"],
      currencies: ["XOF"],
      emoji: "🇬🇼",
      ioc: "GBS",
      languages: ["por"],
      name: "Guinea-bissau",
      status: "assigned",
    },
    {
      alpha2: "GY",
      alpha3: "GUY",
      countryCallingCodes: ["+592"],
      currencies: ["GYD"],
      emoji: "🇬🇾",
      ioc: "GUY",
      languages: ["eng"],
      name: "Guyana",
      status: "assigned",
    },
    {
      alpha2: "HK",
      alpha3: "HKG",
      countryCallingCodes: ["+852"],
      currencies: ["HKD"],
      emoji: "🇭🇰",
      ioc: "HKG",
      languages: ["zho", "eng"],
      name: "Hong Kong",
      status: "assigned",
    },
    {
      alpha2: "HM",
      alpha3: "HMD",
      countryCallingCodes: [],
      currencies: ["AUD"],
      emoji: "🇭🇲",
      ioc: "",
      languages: [],
      name: "Heard Island And McDonald Islands",
      status: "assigned",
    },
    {
      alpha2: "HN",
      alpha3: "HND",
      countryCallingCodes: ["+504"],
      currencies: ["HNL"],
      emoji: "🇭🇳",
      ioc: "HON",
      languages: ["spa"],
      name: "Honduras",
      status: "assigned",
    },
    {
      alpha2: "HR",
      alpha3: "HRV",
      countryCallingCodes: ["+385"],
      currencies: ["HRK"],
      emoji: "🇭🇷",
      ioc: "CRO",
      languages: ["hrv"],
      name: "Croatia",
      status: "assigned",
    },
    {
      alpha2: "HT",
      alpha3: "HTI",
      countryCallingCodes: ["+509"],
      currencies: ["HTG", "USD"],
      emoji: "🇭🇹",
      ioc: "HAI",
      languages: ["fra", "hat"],
      name: "Haiti",
      status: "assigned",
    },
    {
      alpha2: "HU",
      alpha3: "HUN",
      countryCallingCodes: ["+36"],
      currencies: ["HUF"],
      emoji: "🇭🇺",
      ioc: "HUN",
      languages: ["hun"],
      name: "Hungary",
      status: "assigned",
    },
    {
      alpha2: "HV",
      alpha3: "HVO",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Upper Volta",
      status: "deleted",
    },
    {
      alpha2: "IC",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["EUR"],
      emoji: "",
      ioc: "",
      languages: [],
      name: "Canary Islands",
      status: "reserved",
    },
    {
      alpha2: "ID",
      alpha3: "IDN",
      countryCallingCodes: ["+62"],
      currencies: ["IDR"],
      emoji: "🇮🇩",
      ioc: "INA",
      languages: ["ind"],
      name: "Indonesia",
      status: "assigned",
    },
    {
      alpha2: "IE",
      alpha3: "IRL",
      countryCallingCodes: ["+353"],
      currencies: ["EUR"],
      emoji: "🇮🇪",
      ioc: "IRL",
      languages: ["eng", "gle"],
      name: "Ireland",
      status: "assigned",
    },
    {
      alpha2: "IL",
      alpha3: "ISR",
      countryCallingCodes: ["+972"],
      currencies: ["ILS"],
      emoji: "🇮🇱",
      ioc: "ISR",
      languages: ["heb", "ara", "eng"],
      name: "Israel",
      status: "assigned",
    },
    {
      alpha2: "IM",
      alpha3: "IMN",
      countryCallingCodes: ["+44"],
      currencies: ["GBP"],
      emoji: "🇮🇲",
      ioc: "",
      languages: ["eng", "glv"],
      name: "Isle Of Man",
      status: "assigned",
    },
    {
      alpha2: "IN",
      alpha3: "IND",
      countryCallingCodes: ["+91"],
      currencies: ["INR"],
      emoji: "🇮🇳",
      ioc: "IND",
      languages: ["eng", "hin"],
      name: "India",
      status: "assigned",
    },
    {
      alpha2: "IO",
      alpha3: "IOT",
      countryCallingCodes: ["+246"],
      currencies: ["USD"],
      emoji: "🇮🇴",
      ioc: "",
      languages: ["eng"],
      name: "British Indian Ocean Territory",
      status: "assigned",
    },
    {
      alpha2: "IQ",
      alpha3: "IRQ",
      countryCallingCodes: ["+964"],
      currencies: ["IQD"],
      emoji: "🇮🇶",
      ioc: "IRQ",
      languages: ["ara", "kur"],
      name: "Iraq",
      status: "assigned",
    },
    {
      alpha2: "IR",
      alpha3: "IRN",
      countryCallingCodes: ["+98"],
      currencies: ["IRR"],
      emoji: "🇮🇷",
      ioc: "IRI",
      languages: ["fas"],
      name: "Iran, Islamic Republic Of",
      status: "assigned",
    },
    {
      alpha2: "IS",
      alpha3: "ISL",
      countryCallingCodes: ["+354"],
      currencies: ["ISK"],
      emoji: "🇮🇸",
      ioc: "ISL",
      languages: ["isl"],
      name: "Iceland",
      status: "assigned",
    },
    {
      alpha2: "IT",
      alpha3: "ITA",
      countryCallingCodes: ["+39"],
      currencies: ["EUR"],
      emoji: "🇮🇹",
      ioc: "ITA",
      languages: ["ita"],
      name: "Italy",
      status: "assigned",
    },
    {
      alpha2: "JE",
      alpha3: "JEY",
      countryCallingCodes: ["+44"],
      currencies: ["GBP"],
      emoji: "🇯🇪",
      ioc: "JCI",
      languages: ["eng", "fra"],
      name: "Jersey",
      status: "assigned",
    },
    {
      alpha2: "JM",
      alpha3: "JAM",
      countryCallingCodes: ["+1 876"],
      currencies: ["JMD"],
      emoji: "🇯🇲",
      ioc: "JAM",
      languages: ["eng"],
      name: "Jamaica",
      status: "assigned",
    },
    {
      alpha2: "JO",
      alpha3: "JOR",
      countryCallingCodes: ["+962"],
      currencies: ["JOD"],
      emoji: "🇯🇴",
      ioc: "JOR",
      languages: ["ara"],
      name: "Jordan",
      status: "assigned",
    },
    {
      alpha2: "JP",
      alpha3: "JPN",
      countryCallingCodes: ["+81"],
      currencies: ["JPY"],
      emoji: "🇯🇵",
      ioc: "JPN",
      languages: ["jpn"],
      name: "Japan",
      status: "assigned",
    },
    {
      alpha2: "JT",
      alpha3: "JTN",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Johnston Island",
      status: "deleted",
    },
    {
      alpha2: "KE",
      alpha3: "KEN",
      countryCallingCodes: ["+254"],
      currencies: ["KES"],
      emoji: "🇰🇪",
      ioc: "KEN",
      languages: ["eng", "swa"],
      name: "Kenya",
      status: "assigned",
    },
    {
      alpha2: "KG",
      alpha3: "KGZ",
      countryCallingCodes: ["+996"],
      currencies: ["KGS"],
      emoji: "🇰🇬",
      ioc: "KGZ",
      languages: ["rus"],
      name: "Kyrgyzstan",
      status: "assigned",
    },
    {
      alpha2: "KH",
      alpha3: "KHM",
      countryCallingCodes: ["+855"],
      currencies: ["KHR"],
      emoji: "🇰🇭",
      ioc: "CAM",
      languages: ["khm"],
      name: "Cambodia",
      status: "assigned",
    },
    {
      alpha2: "KI",
      alpha3: "KIR",
      countryCallingCodes: ["+686"],
      currencies: ["AUD"],
      emoji: "🇰🇮",
      ioc: "KIR",
      languages: ["eng"],
      name: "Kiribati",
      status: "assigned",
    },
    {
      alpha2: "KM",
      alpha3: "COM",
      countryCallingCodes: ["+269"],
      currencies: ["KMF"],
      emoji: "🇰🇲",
      ioc: "COM",
      languages: ["ara", "fra"],
      name: "Comoros",
      status: "assigned",
    },
    {
      alpha2: "KN",
      alpha3: "KNA",
      countryCallingCodes: ["+1 869"],
      currencies: ["XCD"],
      emoji: "🇰🇳",
      ioc: "SKN",
      languages: ["eng"],
      name: "Saint Kitts And Nevis",
      status: "assigned",
    },
    {
      alpha2: "KP",
      alpha3: "PRK",
      countryCallingCodes: ["+850"],
      currencies: ["KPW"],
      emoji: "🇰🇵",
      ioc: "PRK",
      languages: ["kor"],
      name: "Korea, Democratic People's Republic Of",
      status: "assigned",
    },
    {
      alpha2: "KR",
      alpha3: "KOR",
      countryCallingCodes: ["+82"],
      currencies: ["KRW"],
      emoji: "🇰🇷",
      ioc: "KOR",
      languages: ["kor"],
      name: "Korea, Republic Of",
      status: "assigned",
    },
    {
      alpha2: "KW",
      alpha3: "KWT",
      countryCallingCodes: ["+965"],
      currencies: ["KWD"],
      emoji: "🇰🇼",
      ioc: "KUW",
      languages: ["ara"],
      name: "Kuwait",
      status: "assigned",
    },
    {
      alpha2: "KY",
      alpha3: "CYM",
      countryCallingCodes: ["+1 345"],
      currencies: ["KYD"],
      emoji: "🇰🇾",
      ioc: "CAY",
      languages: ["eng"],
      name: "Cayman Islands",
      status: "assigned",
    },
    {
      alpha2: "KZ",
      alpha3: "KAZ",
      countryCallingCodes: ["+7", "+7 6", "+7 7"],
      currencies: ["KZT"],
      emoji: "🇰🇿",
      ioc: "KAZ",
      languages: ["kaz", "rus"],
      name: "Kazakhstan",
      status: "assigned",
    },
    {
      alpha2: "LA",
      alpha3: "LAO",
      countryCallingCodes: ["+856"],
      currencies: ["LAK"],
      emoji: "🇱🇦",
      ioc: "LAO",
      languages: ["lao"],
      name: "Lao People's Democratic Republic",
      status: "assigned",
    },
    {
      alpha2: "LB",
      alpha3: "LBN",
      countryCallingCodes: ["+961"],
      currencies: ["LBP"],
      emoji: "🇱🇧",
      ioc: "LIB",
      languages: ["ara", "hye"],
      name: "Lebanon",
      status: "assigned",
    },
    {
      alpha2: "LC",
      alpha3: "LCA",
      countryCallingCodes: ["+1 758"],
      currencies: ["XCD"],
      emoji: "🇱🇨",
      ioc: "LCA",
      languages: ["eng"],
      name: "Saint Lucia",
      status: "assigned",
    },
    {
      alpha2: "LI",
      alpha3: "LIE",
      countryCallingCodes: ["+423"],
      currencies: ["CHF"],
      emoji: "🇱🇮",
      ioc: "LIE",
      languages: ["deu"],
      name: "Liechtenstein",
      status: "assigned",
    },
    {
      alpha2: "LK",
      alpha3: "LKA",
      countryCallingCodes: ["+94"],
      currencies: ["LKR"],
      emoji: "🇱🇰",
      ioc: "SRI",
      languages: ["sin", "tam"],
      name: "Sri Lanka",
      status: "assigned",
    },
    {
      alpha2: "LR",
      alpha3: "LBR",
      countryCallingCodes: ["+231"],
      currencies: ["LRD"],
      emoji: "🇱🇷",
      ioc: "LBR",
      languages: ["eng"],
      name: "Liberia",
      status: "assigned",
    },
    {
      alpha2: "LS",
      alpha3: "LSO",
      countryCallingCodes: ["+266"],
      currencies: ["LSL", "ZAR"],
      emoji: "🇱🇸",
      ioc: "LES",
      languages: ["eng", "sot"],
      name: "Lesotho",
      status: "assigned",
    },
    {
      alpha2: "LT",
      alpha3: "LTU",
      countryCallingCodes: ["+370"],
      currencies: ["EUR"],
      emoji: "🇱🇹",
      ioc: "LTU",
      languages: ["lit"],
      name: "Lithuania",
      status: "assigned",
    },
    {
      alpha2: "LU",
      alpha3: "LUX",
      countryCallingCodes: ["+352"],
      currencies: ["EUR"],
      emoji: "🇱🇺",
      ioc: "LUX",
      languages: ["fra", "deu", "ltz"],
      name: "Luxembourg",
      status: "assigned",
    },
    {
      alpha2: "LV",
      alpha3: "LVA",
      countryCallingCodes: ["+371"],
      currencies: ["EUR"],
      emoji: "🇱🇻",
      ioc: "LAT",
      languages: ["lav"],
      name: "Latvia",
      status: "assigned",
    },
    {
      alpha2: "LY",
      alpha3: "LBY",
      countryCallingCodes: ["+218"],
      currencies: ["LYD"],
      emoji: "🇱🇾",
      ioc: "LBA",
      languages: ["ara"],
      name: "Libya",
      status: "assigned",
    },
    {
      alpha2: "MA",
      alpha3: "MAR",
      countryCallingCodes: ["+212"],
      currencies: ["MAD"],
      emoji: "🇲🇦",
      ioc: "MAR",
      languages: ["ara"],
      name: "Morocco",
      status: "assigned",
    },
    {
      alpha2: "MC",
      alpha3: "MCO",
      countryCallingCodes: ["+377"],
      currencies: ["EUR"],
      emoji: "🇲🇨",
      ioc: "MON",
      languages: ["fra"],
      name: "Monaco",
      status: "assigned",
    },
    {
      alpha2: "MD",
      alpha3: "MDA",
      countryCallingCodes: ["+373"],
      currencies: ["MDL"],
      emoji: "🇲🇩",
      ioc: "MDA",
      languages: ["ron"],
      name: "Moldova",
      status: "assigned",
    },
    {
      alpha2: "ME",
      alpha3: "MNE",
      countryCallingCodes: ["+382"],
      currencies: ["EUR"],
      emoji: "🇲🇪",
      ioc: "MNE",
      languages: ["mot"],
      name: "Montenegro",
      status: "assigned",
    },
    {
      alpha2: "MF",
      alpha3: "MAF",
      countryCallingCodes: ["+590"],
      currencies: ["EUR"],
      emoji: "🇲🇫",
      ioc: "",
      languages: ["fra"],
      name: "Saint Martin",
      status: "assigned",
    },
    {
      alpha2: "MG",
      alpha3: "MDG",
      countryCallingCodes: ["+261"],
      currencies: ["MGA"],
      emoji: "🇲🇬",
      ioc: "MAD",
      languages: ["fra", "mlg"],
      name: "Madagascar",
      status: "assigned",
    },
    {
      alpha2: "MH",
      alpha3: "MHL",
      countryCallingCodes: ["+692"],
      currencies: ["USD"],
      emoji: "🇲🇭",
      ioc: "MHL",
      languages: ["eng", "mah"],
      name: "Marshall Islands",
      status: "assigned",
    },
    {
      alpha2: "MI",
      alpha3: "MID",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Midway Islands",
      status: "deleted",
    },
    {
      alpha2: "MK",
      alpha3: "MKD",
      countryCallingCodes: ["+389"],
      currencies: ["MKD"],
      emoji: "🇲🇰",
      ioc: "MKD",
      languages: ["mkd"],
      name: "Macedonia, The Former Yugoslav Republic Of",
      status: "assigned",
    },
    {
      alpha2: "ML",
      alpha3: "MLI",
      countryCallingCodes: ["+223"],
      currencies: ["XOF"],
      emoji: "🇲🇱",
      ioc: "MLI",
      languages: ["fra"],
      name: "Mali",
      status: "assigned",
    },
    {
      alpha2: "MM",
      alpha3: "MMR",
      countryCallingCodes: ["+95"],
      currencies: ["MMK"],
      emoji: "🇲🇲",
      ioc: "MYA",
      languages: ["mya"],
      name: "Myanmar",
      status: "assigned",
    },
    {
      alpha2: "MN",
      alpha3: "MNG",
      countryCallingCodes: ["+976"],
      currencies: ["MNT"],
      emoji: "🇲🇳",
      ioc: "MGL",
      languages: ["mon"],
      name: "Mongolia",
      status: "assigned",
    },
    {
      alpha2: "MO",
      alpha3: "MAC",
      countryCallingCodes: ["+853"],
      currencies: ["MOP"],
      emoji: "🇲🇴",
      ioc: "MAC",
      languages: ["zho", "por"],
      name: "Macao",
      status: "assigned",
    },
    {
      alpha2: "MP",
      alpha3: "MNP",
      countryCallingCodes: ["+1 670"],
      currencies: ["USD"],
      emoji: "🇲🇵",
      ioc: "",
      languages: ["eng"],
      name: "Northern Mariana Islands",
      status: "assigned",
    },
    {
      alpha2: "MQ",
      alpha3: "MTQ",
      countryCallingCodes: ["+596"],
      currencies: ["EUR"],
      emoji: "🇲🇶",
      ioc: "",
      languages: [],
      name: "Martinique",
      status: "assigned",
    },
    {
      alpha2: "MR",
      alpha3: "MRT",
      countryCallingCodes: ["+222"],
      currencies: ["MRO"],
      emoji: "🇲🇷",
      ioc: "MTN",
      languages: ["ara", "fra"],
      name: "Mauritania",
      status: "assigned",
    },
    {
      alpha2: "MS",
      alpha3: "MSR",
      countryCallingCodes: ["+1 664"],
      currencies: ["XCD"],
      emoji: "🇲🇸",
      ioc: "",
      languages: [],
      name: "Montserrat",
      status: "assigned",
    },
    {
      alpha2: "MT",
      alpha3: "MLT",
      countryCallingCodes: ["+356"],
      currencies: ["EUR"],
      emoji: "🇲🇹",
      ioc: "MLT",
      languages: ["mlt", "eng"],
      name: "Malta",
      status: "assigned",
    },
    {
      alpha2: "MU",
      alpha3: "MUS",
      countryCallingCodes: ["+230"],
      currencies: ["MUR"],
      emoji: "🇲🇺",
      ioc: "MRI",
      languages: ["eng", "fra"],
      name: "Mauritius",
      status: "assigned",
    },
    {
      alpha2: "MV",
      alpha3: "MDV",
      countryCallingCodes: ["+960"],
      currencies: ["MVR"],
      emoji: "🇲🇻",
      ioc: "MDV",
      languages: ["div"],
      name: "Maldives",
      status: "assigned",
    },
    {
      alpha2: "MW",
      alpha3: "MWI",
      countryCallingCodes: ["+265"],
      currencies: ["MWK"],
      emoji: "🇲🇼",
      ioc: "MAW",
      languages: ["eng", "nya"],
      name: "Malawi",
      status: "assigned",
    },
    {
      alpha2: "MX",
      alpha3: "MEX",
      countryCallingCodes: ["+52"],
      currencies: ["MXN", "MXV"],
      emoji: "🇲🇽",
      ioc: "MEX",
      languages: ["spa"],
      name: "Mexico",
      status: "assigned",
    },
    {
      alpha2: "MY",
      alpha3: "MYS",
      countryCallingCodes: ["+60"],
      currencies: ["MYR"],
      emoji: "🇲🇾",
      ioc: "MAS",
      languages: ["msa", "eng"],
      name: "Malaysia",
      status: "assigned",
    },
    {
      alpha2: "MZ",
      alpha3: "MOZ",
      countryCallingCodes: ["+258"],
      currencies: ["MZN"],
      emoji: "🇲🇿",
      ioc: "MOZ",
      languages: ["por"],
      name: "Mozambique",
      status: "assigned",
    },
    {
      alpha2: "NA",
      alpha3: "NAM",
      countryCallingCodes: ["+264"],
      currencies: ["NAD", "ZAR"],
      emoji: "🇳🇦",
      ioc: "NAM",
      languages: ["eng"],
      name: "Namibia",
      status: "assigned",
    },
    {
      alpha2: "NC",
      alpha3: "NCL",
      countryCallingCodes: ["+687"],
      currencies: ["XPF"],
      emoji: "🇳🇨",
      ioc: "",
      languages: ["fra"],
      name: "New Caledonia",
      status: "assigned",
    },
    {
      alpha2: "NE",
      alpha3: "NER",
      countryCallingCodes: ["+227"],
      currencies: ["XOF"],
      emoji: "🇳🇪",
      ioc: "NIG",
      languages: ["fra"],
      name: "Niger",
      status: "assigned",
    },
    {
      alpha2: "NF",
      alpha3: "NFK",
      countryCallingCodes: ["+672"],
      currencies: ["AUD"],
      emoji: "🇳🇫",
      ioc: "",
      languages: ["eng"],
      name: "Norfolk Island",
      status: "assigned",
    },
    {
      alpha2: "NG",
      alpha3: "NGA",
      countryCallingCodes: ["+234"],
      currencies: ["NGN"],
      emoji: "🇳🇬",
      ioc: "NGR",
      languages: ["eng"],
      name: "Nigeria",
      status: "assigned",
    },
    {
      alpha2: "NH",
      alpha3: "NHB",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "New Hebrides",
      status: "deleted",
    },
    {
      alpha2: "NI",
      alpha3: "NIC",
      countryCallingCodes: ["+505"],
      currencies: ["NIO"],
      emoji: "🇳🇮",
      ioc: "NCA",
      languages: ["spa"],
      name: "Nicaragua",
      status: "assigned",
    },
    {
      alpha2: "NL",
      alpha3: "NLD",
      countryCallingCodes: ["+31"],
      currencies: ["EUR"],
      emoji: "🇳🇱",
      ioc: "NED",
      languages: ["nld"],
      name: "Netherlands",
      status: "assigned",
    },
    {
      alpha2: "NO",
      alpha3: "NOR",
      countryCallingCodes: ["+47"],
      currencies: ["NOK"],
      emoji: "🇳🇴",
      ioc: "NOR",
      languages: ["nor"],
      name: "Norway",
      status: "assigned",
    },
    {
      alpha2: "NP",
      alpha3: "NPL",
      countryCallingCodes: ["+977"],
      currencies: ["NPR"],
      emoji: "🇳🇵",
      ioc: "NEP",
      languages: ["nep"],
      name: "Nepal",
      status: "assigned",
    },
    {
      alpha2: "NQ",
      alpha3: "ATN",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Dronning Maud Land",
      status: "deleted",
    },
    {
      alpha2: "NR",
      alpha3: "NRU",
      countryCallingCodes: ["+674"],
      currencies: ["AUD"],
      emoji: "🇳🇷",
      ioc: "NRU",
      languages: ["eng", "nau"],
      name: "Nauru",
      status: "assigned",
    },
    {
      alpha2: "NT",
      alpha3: "NTZ",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Neutral Zone",
      status: "deleted",
    },
    {
      alpha2: "NU",
      alpha3: "NIU",
      countryCallingCodes: ["+683"],
      currencies: ["NZD"],
      emoji: "🇳🇺",
      ioc: "",
      languages: ["eng"],
      name: "Niue",
      status: "assigned",
    },
    {
      alpha2: "NZ",
      alpha3: "NZL",
      countryCallingCodes: ["+64"],
      currencies: ["NZD"],
      emoji: "🇳🇿",
      ioc: "NZL",
      languages: ["eng"],
      name: "New Zealand",
      status: "assigned",
    },
    {
      alpha2: "OM",
      alpha3: "OMN",
      countryCallingCodes: ["+968"],
      currencies: ["OMR"],
      emoji: "🇴🇲",
      ioc: "OMA",
      languages: ["ara"],
      name: "Oman",
      status: "assigned",
    },
    {
      alpha2: "PA",
      alpha3: "PAN",
      countryCallingCodes: ["+507"],
      currencies: ["PAB", "USD"],
      emoji: "🇵🇦",
      ioc: "PAN",
      languages: ["spa"],
      name: "Panama",
      status: "assigned",
    },
    {
      alpha2: "PC",
      alpha3: "PCI",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Pacific Islands, Trust Territory of the",
      status: "deleted",
    },
    {
      alpha2: "PE",
      alpha3: "PER",
      countryCallingCodes: ["+51"],
      currencies: ["PEN"],
      emoji: "🇵🇪",
      ioc: "PER",
      languages: ["spa", "aym", "que"],
      name: "Peru",
      status: "assigned",
    },
    {
      alpha2: "PF",
      alpha3: "PYF",
      countryCallingCodes: ["+689"],
      currencies: ["XPF"],
      emoji: "🇵🇫",
      ioc: "",
      languages: ["fra"],
      name: "French Polynesia",
      status: "assigned",
    },
    {
      alpha2: "PG",
      alpha3: "PNG",
      countryCallingCodes: ["+675"],
      currencies: ["PGK"],
      emoji: "🇵🇬",
      ioc: "PNG",
      languages: ["eng"],
      name: "Papua New Guinea",
      status: "assigned",
    },
    {
      alpha2: "PH",
      alpha3: "PHL",
      countryCallingCodes: ["+63"],
      currencies: ["PHP"],
      emoji: "🇵🇭",
      ioc: "PHI",
      languages: ["eng"],
      name: "Philippines",
      status: "assigned",
    },
    {
      alpha2: "PK",
      alpha3: "PAK",
      countryCallingCodes: ["+92"],
      currencies: ["PKR"],
      emoji: "🇵🇰",
      ioc: "PAK",
      languages: ["urd", "eng"],
      name: "Pakistan",
      status: "assigned",
    },
    {
      alpha2: "PL",
      alpha3: "POL",
      countryCallingCodes: ["+48"],
      currencies: ["PLN"],
      emoji: "🇵🇱",
      ioc: "POL",
      languages: ["pol"],
      name: "Poland",
      status: "assigned",
    },
    {
      alpha2: "PM",
      alpha3: "SPM",
      countryCallingCodes: ["+508"],
      currencies: ["EUR"],
      emoji: "🇵🇲",
      ioc: "",
      languages: ["eng"],
      name: "Saint Pierre And Miquelon",
      status: "assigned",
    },
    {
      alpha2: "PN",
      alpha3: "PCN",
      countryCallingCodes: ["+872"],
      currencies: ["NZD"],
      emoji: "🇵🇳",
      ioc: "",
      languages: ["eng"],
      name: "Pitcairn",
      status: "assigned",
    },
    {
      alpha2: "PR",
      alpha3: "PRI",
      countryCallingCodes: ["+1 787", "+1 939"],
      currencies: ["USD"],
      emoji: "🇵🇷",
      ioc: "PUR",
      languages: ["spa", "eng"],
      name: "Puerto Rico",
      status: "assigned",
    },
    {
      alpha2: "PS",
      alpha3: "PSE",
      countryCallingCodes: ["+970"],
      currencies: ["JOD", "EGP", "ILS"],
      emoji: "🇵🇸",
      ioc: "PLE",
      languages: ["ara"],
      name: "Palestinian Territory, Occupied",
      status: "assigned",
    },
    {
      alpha2: "PT",
      alpha3: "PRT",
      countryCallingCodes: ["+351"],
      currencies: ["EUR"],
      emoji: "🇵🇹",
      ioc: "POR",
      languages: ["por"],
      name: "Portugal",
      status: "assigned",
    },
    {
      alpha2: "PU",
      alpha3: "PUS",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "U.S. Miscellaneous Pacific Islands",
      status: "deleted",
    },
    {
      alpha2: "PW",
      alpha3: "PLW",
      countryCallingCodes: ["+680"],
      currencies: ["USD"],
      emoji: "🇵🇼",
      ioc: "PLW",
      languages: ["eng"],
      name: "Palau",
      status: "assigned",
    },
    {
      alpha2: "PY",
      alpha3: "PRY",
      countryCallingCodes: ["+595"],
      currencies: ["PYG"],
      emoji: "🇵🇾",
      ioc: "PAR",
      languages: ["spa"],
      name: "Paraguay",
      status: "assigned",
    },
    {
      alpha2: "PZ",
      alpha3: "PCZ",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Panama Canal Zone",
      status: "deleted",
    },
    {
      alpha2: "QA",
      alpha3: "QAT",
      countryCallingCodes: ["+974"],
      currencies: ["QAR"],
      emoji: "🇶🇦",
      ioc: "QAT",
      languages: ["ara"],
      name: "Qatar",
      status: "assigned",
    },
    {
      alpha2: "RE",
      alpha3: "REU",
      countryCallingCodes: ["+262"],
      currencies: ["EUR"],
      emoji: "🇷🇪",
      ioc: "",
      languages: ["fra"],
      name: "Reunion",
      status: "assigned",
    },
    {
      alpha2: "RH",
      alpha3: "RHO",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Southern Rhodesia",
      status: "deleted",
    },
    {
      alpha2: "RO",
      alpha3: "ROU",
      countryCallingCodes: ["+40"],
      currencies: ["RON"],
      emoji: "🇷🇴",
      ioc: "ROU",
      languages: ["ron"],
      name: "Romania",
      status: "assigned",
    },
    {
      alpha2: "RS",
      alpha3: "SRB",
      countryCallingCodes: ["+381"],
      currencies: ["RSD"],
      emoji: "🇷🇸",
      ioc: "SRB",
      languages: ["srp"],
      name: "Serbia",
      status: "assigned",
    },
    {
      alpha2: "RU",
      alpha3: "RUS",
      countryCallingCodes: ["+7", "+7 3", "+7 4", "+7 8"],
      currencies: ["RUB"],
      emoji: "🇷🇺",
      ioc: "RUS",
      languages: ["rus"],
      name: "Russian Federation",
      status: "assigned",
    },
    {
      alpha2: "RW",
      alpha3: "RWA",
      countryCallingCodes: ["+250"],
      currencies: ["RWF"],
      emoji: "🇷🇼",
      ioc: "RWA",
      languages: ["eng", "fra", "kin"],
      name: "Rwanda",
      status: "assigned",
    },
    {
      alpha2: "SA",
      alpha3: "SAU",
      countryCallingCodes: ["+966"],
      currencies: ["SAR"],
      emoji: "🇸🇦",
      ioc: "KSA",
      languages: ["ara"],
      name: "Saudi Arabia",
      status: "assigned",
    },
    {
      alpha2: "SB",
      alpha3: "SLB",
      countryCallingCodes: ["+677"],
      currencies: ["SBD"],
      emoji: "🇸🇧",
      ioc: "SOL",
      languages: ["eng"],
      name: "Solomon Islands",
      status: "assigned",
    },
    {
      alpha2: "SC",
      alpha3: "SYC",
      countryCallingCodes: ["+248"],
      currencies: ["SCR"],
      emoji: "🇸🇨",
      ioc: "SEY",
      languages: ["eng", "fra"],
      name: "Seychelles",
      status: "assigned",
    },
    {
      alpha2: "SD",
      alpha3: "SDN",
      countryCallingCodes: ["+249"],
      currencies: ["SDG"],
      emoji: "🇸🇩",
      ioc: "SUD",
      languages: ["ara", "eng"],
      name: "Sudan",
      status: "assigned",
    },
    {
      alpha2: "SE",
      alpha3: "SWE",
      countryCallingCodes: ["+46"],
      currencies: ["SEK"],
      emoji: "🇸🇪",
      ioc: "SWE",
      languages: ["swe"],
      name: "Sweden",
      status: "assigned",
    },
    {
      alpha2: "SG",
      alpha3: "SGP",
      countryCallingCodes: ["+65"],
      currencies: ["SGD"],
      emoji: "🇸🇬",
      ioc: "SIN",
      languages: ["eng", "zho", "msa", "tam"],
      name: "Singapore",
      status: "assigned",
    },
    {
      alpha2: "SH",
      alpha3: "SHN",
      countryCallingCodes: ["+290"],
      currencies: ["SHP"],
      emoji: "🇸🇭",
      ioc: "",
      languages: ["eng"],
      name: "Saint Helena, Ascension And Tristan Da Cunha",
      status: "assigned",
    },
    {
      alpha2: "SI",
      alpha3: "SVN",
      countryCallingCodes: ["+386"],
      currencies: ["EUR"],
      emoji: "🇸🇮",
      ioc: "SLO",
      languages: ["slv"],
      name: "Slovenia",
      status: "assigned",
    },
    {
      alpha2: "SJ",
      alpha3: "SJM",
      countryCallingCodes: ["+47"],
      currencies: ["NOK"],
      emoji: "🇸🇯",
      ioc: "",
      languages: [],
      name: "Svalbard And Jan Mayen",
      status: "assigned",
    },
    {
      alpha2: "SK",
      alpha3: "SVK",
      countryCallingCodes: ["+421"],
      currencies: ["EUR"],
      emoji: "🇸🇰",
      ioc: "SVK",
      languages: ["slk"],
      name: "Slovakia",
      status: "assigned",
    },
    {
      alpha2: "SK",
      alpha3: "SKM",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Sikkim",
      status: "deleted",
    },
    {
      alpha2: "SL",
      alpha3: "SLE",
      countryCallingCodes: ["+232"],
      currencies: ["SLL"],
      emoji: "🇸🇱",
      ioc: "SLE",
      languages: ["eng"],
      name: "Sierra Leone",
      status: "assigned",
    },
    {
      alpha2: "SM",
      alpha3: "SMR",
      countryCallingCodes: ["+378"],
      currencies: ["EUR"],
      emoji: "🇸🇲",
      ioc: "SMR",
      languages: ["ita"],
      name: "San Marino",
      status: "assigned",
    },
    {
      alpha2: "SN",
      alpha3: "SEN",
      countryCallingCodes: ["+221"],
      currencies: ["XOF"],
      emoji: "🇸🇳",
      ioc: "SEN",
      languages: ["fra"],
      name: "Senegal",
      status: "assigned",
    },
    {
      alpha2: "SO",
      alpha3: "SOM",
      countryCallingCodes: ["+252"],
      currencies: ["SOS"],
      emoji: "🇸🇴",
      ioc: "SOM",
      languages: ["som"],
      name: "Somalia",
      status: "assigned",
    },
    {
      alpha2: "SR",
      alpha3: "SUR",
      countryCallingCodes: ["+597"],
      currencies: ["SRD"],
      emoji: "🇸🇷",
      ioc: "SUR",
      languages: ["nld"],
      name: "Suriname",
      status: "assigned",
    },
    {
      alpha2: "SS",
      alpha3: "SSD",
      countryCallingCodes: ["+211"],
      currencies: ["SSP"],
      emoji: "🇸🇸",
      ioc: "SSD",
      languages: ["eng"],
      name: "South Sudan",
      status: "assigned",
    },
    {
      alpha2: "ST",
      alpha3: "STP",
      countryCallingCodes: ["+239"],
      currencies: ["STD"],
      emoji: "🇸🇹",
      ioc: "STP",
      languages: ["por"],
      name: "Sao Tome and Principe",
      status: "assigned",
    },
    {
      alpha2: "SU",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["RUB"],
      emoji: "",
      ioc: "",
      languages: ["rus"],
      name: "USSR",
      status: "reserved",
    },
    {
      alpha2: "SV",
      alpha3: "SLV",
      countryCallingCodes: ["+503"],
      currencies: ["USD"],
      emoji: "🇸🇻",
      ioc: "ESA",
      languages: ["spa"],
      name: "El Salvador",
      status: "assigned",
    },
    {
      alpha2: "SX",
      alpha3: "SXM",
      countryCallingCodes: ["+1 721"],
      currencies: ["ANG"],
      emoji: "🇸🇽",
      ioc: "",
      languages: ["nld"],
      name: "Sint Maarten",
      status: "assigned",
    },
    {
      alpha2: "SY",
      alpha3: "SYR",
      countryCallingCodes: ["+963"],
      currencies: ["SYP"],
      emoji: "🇸🇾",
      ioc: "SYR",
      languages: ["ara"],
      name: "Syrian Arab Republic",
      status: "assigned",
    },
    {
      alpha2: "SZ",
      alpha3: "SWZ",
      countryCallingCodes: ["+268"],
      currencies: ["SZL"],
      emoji: "🇸🇿",
      ioc: "SWZ",
      languages: ["eng", "ssw"],
      name: "Swaziland",
      status: "assigned",
    },
    {
      alpha2: "TA",
      alpha3: "",
      countryCallingCodes: ["+290"],
      currencies: ["GBP"],
      emoji: "",
      ioc: "",
      languages: [],
      name: "Tristan de Cunha",
      status: "reserved",
    },
    {
      alpha2: "TC",
      alpha3: "TCA",
      countryCallingCodes: ["+1 649"],
      currencies: ["USD"],
      emoji: "🇹🇨",
      ioc: "",
      languages: ["eng"],
      name: "Turks And Caicos Islands",
      status: "assigned",
    },
    {
      alpha2: "TD",
      alpha3: "TCD",
      countryCallingCodes: ["+235"],
      currencies: ["XAF"],
      emoji: "🇹🇩",
      ioc: "CHA",
      languages: ["ara", "fra"],
      name: "Chad",
      status: "assigned",
    },
    {
      alpha2: "TF",
      alpha3: "ATF",
      countryCallingCodes: [],
      currencies: ["EUR"],
      emoji: "🇹🇫",
      ioc: "",
      languages: ["fra"],
      name: "French Southern Territories",
      status: "assigned",
    },
    {
      alpha2: "TG",
      alpha3: "TGO",
      countryCallingCodes: ["+228"],
      currencies: ["XOF"],
      emoji: "🇹🇬",
      ioc: "TOG",
      languages: ["fra"],
      name: "Togo",
      status: "assigned",
    },
    {
      alpha2: "TH",
      alpha3: "THA",
      countryCallingCodes: ["+66"],
      currencies: ["THB"],
      emoji: "🇹🇭",
      ioc: "THA",
      languages: ["tha"],
      name: "Thailand",
      status: "assigned",
    },
    {
      alpha2: "TJ",
      alpha3: "TJK",
      countryCallingCodes: ["+992"],
      currencies: ["TJS"],
      emoji: "🇹🇯",
      ioc: "TJK",
      languages: ["tgk", "rus"],
      name: "Tajikistan",
      status: "assigned",
    },
    {
      alpha2: "TK",
      alpha3: "TKL",
      countryCallingCodes: ["+690"],
      currencies: ["NZD"],
      emoji: "🇹🇰",
      ioc: "",
      languages: ["eng"],
      name: "Tokelau",
      status: "assigned",
    },
    {
      alpha2: "TL",
      alpha3: "TLS",
      countryCallingCodes: ["+670"],
      currencies: ["USD"],
      emoji: "🇹🇱",
      ioc: "TLS",
      languages: ["por"],
      name: "Timor-Leste, Democratic Republic of",
      status: "assigned",
    },
    {
      alpha2: "TM",
      alpha3: "TKM",
      countryCallingCodes: ["+993"],
      currencies: ["TMT"],
      emoji: "🇹🇲",
      ioc: "TKM",
      languages: ["tuk", "rus"],
      name: "Turkmenistan",
      status: "assigned",
    },
    {
      alpha2: "TN",
      alpha3: "TUN",
      countryCallingCodes: ["+216"],
      currencies: ["TND"],
      emoji: "🇹🇳",
      ioc: "TUN",
      languages: ["ara"],
      name: "Tunisia",
      status: "assigned",
    },
    {
      alpha2: "TO",
      alpha3: "TON",
      countryCallingCodes: ["+676"],
      currencies: ["TOP"],
      emoji: "🇹🇴",
      ioc: "TGA",
      languages: ["eng"],
      name: "Tonga",
      status: "assigned",
    },
    {
      alpha2: "TP",
      alpha3: "TMP",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "East Timor",
      status: "deleted",
    },
    {
      alpha2: "TR",
      alpha3: "TUR",
      countryCallingCodes: ["+90"],
      currencies: ["TRY"],
      emoji: "🇹🇷",
      ioc: "TUR",
      languages: ["tur"],
      name: "Turkey",
      status: "assigned",
    },
    {
      alpha2: "TT",
      alpha3: "TTO",
      countryCallingCodes: ["+1 868"],
      currencies: ["TTD"],
      emoji: "🇹🇹",
      ioc: "TTO",
      languages: ["eng"],
      name: "Trinidad And Tobago",
      status: "assigned",
    },
    {
      alpha2: "TV",
      alpha3: "TUV",
      countryCallingCodes: ["+688"],
      currencies: ["AUD"],
      emoji: "🇹🇻",
      ioc: "TUV",
      languages: ["eng"],
      name: "Tuvalu",
      status: "assigned",
    },
    {
      alpha2: "TW",
      alpha3: "TWN",
      countryCallingCodes: ["+886"],
      currencies: ["TWD"],
      emoji: "🇹🇼",
      ioc: "TPE",
      languages: ["zho"],
      name: "Taiwan",
      status: "assigned",
    },
    {
      alpha2: "TZ",
      alpha3: "TZA",
      countryCallingCodes: ["+255"],
      currencies: ["TZS"],
      emoji: "🇹🇿",
      ioc: "TAN",
      languages: ["swa", "eng"],
      name: "Tanzania, United Republic Of",
      status: "assigned",
    },
    {
      alpha2: "UA",
      alpha3: "UKR",
      countryCallingCodes: ["+380"],
      currencies: ["UAH"],
      emoji: "🇺🇦",
      ioc: "UKR",
      languages: ["ukr", "rus"],
      name: "Ukraine",
      status: "assigned",
    },
    {
      alpha2: "UG",
      alpha3: "UGA",
      countryCallingCodes: ["+256"],
      currencies: ["UGX"],
      emoji: "🇺🇬",
      ioc: "UGA",
      languages: ["eng", "swa"],
      name: "Uganda",
      status: "assigned",
    },
    {
      alpha2: "UK",
      alpha3: "",
      countryCallingCodes: [],
      currencies: ["GBP"],
      emoji: "",
      ioc: "",
      languages: ["eng", "cor", "gle", "gla", "cym"],
      name: "United Kingdom",
      status: "reserved",
    },
    {
      alpha2: "UM",
      alpha3: "UMI",
      countryCallingCodes: ["+1"],
      currencies: ["USD"],
      emoji: "🇺🇲",
      ioc: "",
      languages: ["eng"],
      name: "United States Minor Outlying Islands",
      status: "assigned",
    },
    {
      alpha2: "US",
      alpha3: "USA",
      countryCallingCodes: ["+1"],
      currencies: ["USD"],
      emoji: "🇺🇸",
      ioc: "USA",
      languages: ["eng"],
      name: "United States",
      status: "assigned",
    },
    {
      alpha2: "UY",
      alpha3: "URY",
      countryCallingCodes: ["+598"],
      currencies: ["UYU", "UYI"],
      emoji: "🇺🇾",
      ioc: "URU",
      languages: ["spa"],
      name: "Uruguay",
      status: "assigned",
    },
    {
      alpha2: "UZ",
      alpha3: "UZB",
      countryCallingCodes: ["+998"],
      currencies: ["UZS"],
      emoji: "🇺🇿",
      ioc: "UZB",
      languages: ["uzb", "rus"],
      name: "Uzbekistan",
      status: "assigned",
    },
    {
      alpha2: "VA",
      alpha3: "VAT",
      countryCallingCodes: ["+379", "+39"],
      currencies: ["EUR"],
      emoji: "🇻🇦",
      ioc: "",
      languages: ["ita"],
      name: "Vatican City State",
      status: "assigned",
    },
    {
      alpha2: "VC",
      alpha3: "VCT",
      countryCallingCodes: ["+1 784"],
      currencies: ["XCD"],
      emoji: "🇻🇨",
      ioc: "VIN",
      languages: ["eng"],
      name: "Saint Vincent And The Grenadines",
      status: "assigned",
    },
    {
      alpha2: "VD",
      alpha3: "VDR",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Viet-Nam, Democratic Republic of",
      status: "deleted",
    },
    {
      alpha2: "VE",
      alpha3: "VEN",
      countryCallingCodes: ["+58"],
      currencies: ["VEF"],
      emoji: "🇻🇪",
      ioc: "VEN",
      languages: ["spa"],
      name: "Venezuela, Bolivarian Republic Of",
      status: "assigned",
    },
    {
      alpha2: "VG",
      alpha3: "VGB",
      countryCallingCodes: ["+1 284"],
      currencies: ["USD"],
      emoji: "🇻🇬",
      ioc: "IVB",
      languages: ["eng"],
      name: "Virgin Islands (British)",
      status: "assigned",
    },
    {
      alpha2: "VI",
      alpha3: "VIR",
      countryCallingCodes: ["+1 340"],
      currencies: ["USD"],
      emoji: "🇻🇮",
      ioc: "ISV",
      languages: ["eng"],
      name: "Virgin Islands (US)",
      status: "assigned",
    },
    {
      alpha2: "VN",
      alpha3: "VNM",
      countryCallingCodes: ["+84"],
      currencies: ["VND"],
      emoji: "🇻🇳",
      ioc: "VIE",
      languages: ["vie"],
      name: "Viet Nam",
      status: "assigned",
    },
    {
      alpha2: "VU",
      alpha3: "VUT",
      countryCallingCodes: ["+678"],
      currencies: ["VUV"],
      emoji: "🇻🇺",
      ioc: "VAN",
      languages: ["bis", "eng", "fra"],
      name: "Vanuatu",
      status: "assigned",
    },
    {
      alpha2: "WF",
      alpha3: "WLF",
      countryCallingCodes: ["+681"],
      currencies: ["XPF"],
      emoji: "🇼🇫",
      ioc: "",
      languages: ["fra"],
      name: "Wallis And Futuna",
      status: "assigned",
    },
    {
      alpha2: "WK",
      alpha3: "WAK",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Wake Island",
      status: "deleted",
    },
    {
      alpha2: "WS",
      alpha3: "WSM",
      countryCallingCodes: ["+685"],
      currencies: ["WST"],
      emoji: "🇼🇸",
      ioc: "SAM",
      languages: ["eng", "smo"],
      name: "Samoa",
      status: "assigned",
    },
    {
      alpha2: "XK",
      alpha3: "",
      countryCallingCodes: ["+383"],
      currencies: ["EUR"],
      emoji: "",
      ioc: "KOS",
      languages: ["sqi", "srp", "bos", "tur", "rom"],
      name: "Kosovo",
      status: "user assigned",
    },
    {
      alpha2: "YD",
      alpha3: "YMD",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Yemen, Democratic",
      status: "deleted",
    },
    {
      alpha2: "YE",
      alpha3: "YEM",
      countryCallingCodes: ["+967"],
      currencies: ["YER"],
      emoji: "🇾🇪",
      ioc: "YEM",
      languages: ["ara"],
      name: "Yemen",
      status: "assigned",
    },
    {
      alpha2: "YT",
      alpha3: "MYT",
      countryCallingCodes: ["+262"],
      currencies: ["EUR"],
      emoji: "🇾🇹",
      ioc: "",
      languages: ["fra"],
      name: "Mayotte",
      status: "assigned",
    },
    {
      alpha2: "YU",
      alpha3: "YUG",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Yugoslavia",
      status: "deleted",
    },
    {
      alpha2: "ZA",
      alpha3: "ZAF",
      countryCallingCodes: ["+27"],
      currencies: ["ZAR"],
      emoji: "🇿🇦",
      ioc: "RSA",
      languages: ["afr", "eng", "nbl", "som", "tso", "ven", "xho", "zul"],
      name: "South Africa",
      status: "assigned",
    },
    {
      alpha2: "ZM",
      alpha3: "ZMB",
      countryCallingCodes: ["+260"],
      currencies: ["ZMW"],
      emoji: "🇿🇲",
      ioc: "ZAM",
      languages: ["eng"],
      name: "Zambia",
      status: "assigned",
    },
    {
      alpha2: "ZR",
      alpha3: "ZAR",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "Zaire",
      status: "deleted",
    },
    {
      alpha2: "ZW",
      alpha3: "ZWE",
      countryCallingCodes: ["+263"],
      currencies: ["USD", "ZAR", "BWP", "GBP", "EUR"],
      emoji: "🇿🇼",
      ioc: "ZIM",
      languages: ["eng", "sna", "nde"],
      name: "Zimbabwe",
      status: "assigned",
    },
  ];
  return countries;
};
export default {
  setFCMTokenForUser,
  getPercentAmount,
  getAddressAsText,
  getLogo,
  getFloatValue,
  getAmountInWords,
  hasPrivilege,
  isSupportedCurrency,
  equalsIgnoreCase,
  isNull,
  isNullOrEmpty,
  isNotNull,
  isNotNullAndEmpty,
  getMonth,
  getMonthNumber,
  getMonthList,
  getQuarterList,
  quarterList,
  getQuarterForMonth,
  valueFormat,
  valueFormater,
  getCountryFlag,
  toBase64,
  getCountryData,
};
