import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./ProfitabilityAnalysis.css";
export default class ProfitabilityAnalysis extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }

    let type = props.type;
    this.state = {
      isPdfReport: isPdfReport,
      type: type,
      profitabilityAnalysisList: [],
      render: false,
      data: props.data,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  componentDidMount() {
    let indType = this.state.data.ind_TYPE;
    this.currency = this.state.data.currency;
    this.unit = this.state.data.unit;
    let profitabilityAnalysisList =
      this.state.data.profitabilityAnalysisList.slice(0, 5);
    if (profitabilityAnalysisList.length > 0) {
      profitabilityAnalysisList.reverse();
    }
    let cashFlowData = [];
    let LabelList = [];

    let grossIncomeMargin = [];
    let operatingIncomeMargin = [];
    let pretaxIncomeMargin = [];
    let eBITMargin = [];
    let netIncomeMargin = [];
    let returnOnEquityTotal = [];
    let returnOnInvestedCapital = [];
    let returnOnAssets = [];
    let assetTurnover = [];
    let financialLeverage = [];
    let interestExpenseOnDebt = [];
    let effectiveTaxRate = [];
    let sellingGeneralAndAdministrativeExpensesOfSales = [];
    let researchAndDevelopmentExpense = [];
    let operatingIncomeReturnOnTotalCapital = [];
    for (let i = 0; i < profitabilityAnalysisList.length; i++) {
      cashFlowData.push(profitabilityAnalysisList[i].cashFlowPercentSales);
      LabelList.push(profitabilityAnalysisList[i].fiscalYear);

      grossIncomeMargin.push(profitabilityAnalysisList[i].grossIncomeMargin);
      operatingIncomeMargin.push(
        profitabilityAnalysisList[i].operatingIncomeMargin
      );
      pretaxIncomeMargin.push(profitabilityAnalysisList[i].pretaxIncomeMargin);
      eBITMargin.push(profitabilityAnalysisList[i].eBITMargin);
      netIncomeMargin.push(profitabilityAnalysisList[i].netIncomeMargin);
      returnOnEquityTotal.push(
        profitabilityAnalysisList[i].returnonEquityTotal
      );
      returnOnInvestedCapital.push(
        profitabilityAnalysisList[i].returnonInvestedCapital
      );
      returnOnAssets.push(profitabilityAnalysisList[i].returnonAssets);
      assetTurnover.push(profitabilityAnalysisList[i].assetTurnover);
      financialLeverage.push(profitabilityAnalysisList[i].financialLeverage);
      interestExpenseOnDebt.push(
        profitabilityAnalysisList[i].interestExpenseonDebt
      );
      effectiveTaxRate.push(profitabilityAnalysisList[i].effectiveTaxRate);
      sellingGeneralAndAdministrativeExpensesOfSales.push(
        profitabilityAnalysisList[i]
          .sellingGeneralAndAdministrativeExpensesPercentofSales
      );
      researchAndDevelopmentExpense.push(
        profitabilityAnalysisList[i].researchAndDevelopmentExpense
      );
      operatingIncomeReturnOnTotalCapital.push(
        profitabilityAnalysisList[i].operatingIncomeReturnOnTotalCapital
      );
    }
    this.setState({
      profitabilityAnalysisList: profitabilityAnalysisList,
      indType: indType,
      cashFlowData: cashFlowData,
      LabelList: LabelList,
      grossIncomeMargin: grossIncomeMargin,
      operatingIncomeMargin: operatingIncomeMargin,
      pretaxIncomeMargin: pretaxIncomeMargin,
      eBITMargin: eBITMargin,
      netIncomeMargin: netIncomeMargin,
      returnOnEquityTotal: returnOnEquityTotal,
      returnOnInvestedCapital: returnOnInvestedCapital,
      returnOnAssets: returnOnAssets,
      assetTurnover: assetTurnover,
      financialLeverage: financialLeverage,
      interestExpenseOnDebt: interestExpenseOnDebt,
      effectiveTaxRate: effectiveTaxRate,
      sellingGeneralAndAdministrativeExpensesOfSales:
        sellingGeneralAndAdministrativeExpensesOfSales,
      researchAndDevelopmentExpense: researchAndDevelopmentExpense,
      operatingIncomeReturnOnTotalCapital: operatingIncomeReturnOnTotalCapital,
      series: [
        {
          name: "Cash Flow % Sales",
          type: "bar",
          data: cashFlowData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          text: "",
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        colors: ["#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: LabelList,
        xaxis: {
          title: {
            text: "Year",
            style: {
              colors: "#808b96",
            },
          },

          axisBorder: {
            show: true,
            color: "#808b96",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "Cash Flow % Sales",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#808b96 ",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;
            },
          },
        },
      },
      tableGraphSeries: [
        {
          data: cashFlowData,
        },
      ],
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      render: true,
    });
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Gross Income Margin") {
      this.setState({
        graphDetails: {
          graphValues: this.state.grossIncomeMargin,
          labelData: this.state.LabelList,
          yAxisTitle: "Gross Income Margin",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income Margin") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncomeMargin,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income Margin",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Income Margin") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncomeMargin,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Income Margin",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT Margin") {
      this.setState({
        graphDetails: {
          graphValues: this.state.eBITMargin,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT Margin",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income Margin") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncomeMargin,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income Margin",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Return on Equity - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.returnOnEquityTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Return on Equity - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Return on Invested Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.returnOnInvestedCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Return on Invested Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Return on Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.returnOnAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Return on Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Asset Turnover") {
      this.setState({
        graphDetails: {
          graphValues: this.state.assetTurnover,
          labelData: this.state.LabelList,
          yAxisTitle: "Asset Turnover",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Financial Leverage") {
      this.setState({
        graphDetails: {
          graphValues: this.state.financialLeverage,
          labelData: this.state.LabelList,
          yAxisTitle: "Financial Leverage",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Effective Tax Rate") {
      this.setState({
        graphDetails: {
          graphValues: this.state.effectiveTaxRate,
          labelData: this.state.LabelList,
          yAxisTitle: "Effective Tax Rate",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Flow % Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashFlowData,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Flow % Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "SellingGeneralAndAdministrativeExpensesOfSales") {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.sellingGeneralAndAdministrativeExpensesOfSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Selling, General & Administrative Expenses % of Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Research & Development Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.researchAndDevelopmentExpense,
          labelData: this.state.LabelList,
          yAxisTitle: "Research & Development Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income Return On Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncomeReturnOnTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income Return On Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
              <Row>
                <Col md={8}>
                  <span>
                    <span className="section_header">
                      {" "}
                      Profitability Analysis
                    </span>
                  </span>
                  <br></br>
                  <span className="unit_str" style={{ marginLeft: "-2px" }}>
                    All Figures in&nbsp; {this.currency}
                  </span>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="profitability_analysis">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Gross Income Margin</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.grossIncomeMargin !== null
                          ? Utils.valueFormater(data.grossIncomeMargin) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th>
                    <span className="">Operating Income Margin</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.operatingIncomeMargin !== null
                          ? Utils.valueFormater(data.operatingIncomeMargin) +
                            "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Pretax Income Margin</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.pretaxIncomeMargin !== null
                          ? Utils.valueFormater(data.pretaxIncomeMargin) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">EBIT Margin</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITMargin !== null
                          ? Utils.valueFormater(data.eBITMargin) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Net Income Margin</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.netIncomeMargin !== null
                          ? Utils.valueFormater(data.netIncomeMargin) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Return on Equity - Total</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.returnonEquityTotal !== null
                          ? Utils.valueFormater(data.returnonEquityTotal) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Return on Invested Capital</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.returnonInvestedCapital !== null
                          ? Utils.valueFormater(data.returnonInvestedCapital) +
                            "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Return on Assets</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.returnonAssets !== null
                          ? Utils.valueFormater(data.returnonAssets) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Asset Turnover</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.assetTurnover !== null
                          ? Utils.valueFormater(data.assetTurnover)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Financial Leverage</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.financialLeverage !== null
                          ? Utils.valueFormater(data.financialLeverage) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Effective Tax Rate</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.effectiveTaxRate !== null
                          ? Utils.valueFormater(data.effectiveTaxRate) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className=""> Cash Flow % Sales</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.cashFlowPercentSales !== null
                          ? Utils.valueFormater(data.cashFlowPercentSales) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th>
                    <span className="">
                      Selling, General & Administrative Expenses % of Sales
                    </span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.sellingGeneralAndAdministrativeExpensesPercentofSales !==
                        null
                          ? Utils.valueFormater(
                              data.sellingGeneralAndAdministrativeExpensesPercentofSales
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Research & Development Expense</span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.researchAndDevelopmentExpense !== null
                          ? Utils.valueFormater(
                              data.researchAndDevelopmentExpense
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Operating Income Return On Total Capital
                    </span>
                  </th>
                  {this.state.profitabilityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.operatingIncomeReturnOnTotalCapital !== null
                          ? Utils.valueFormater(
                              data.operatingIncomeReturnOnTotalCapital
                            ) + "%"
                          : "0%"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        Profitability Analysis
                      </span>
                    </span>
                    <br></br>
                    <span className="unit_str" style={{ marginLeft: "-2px" }}>
                      All Figures in&nbsp; {this.currency}
                    </span>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="profitability_analysis">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Gross Income Margin</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Gross Income Margin")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.grossIncomeMargin !== null
                            ? Utils.valueFormater(data.grossIncomeMargin) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>
                      <span className="">Operating Income Margin</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Income Margin")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.operatingIncomeMargin !== null
                            ? Utils.valueFormater(data.operatingIncomeMargin) +
                              "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Pretax Income Margin</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pretax Income Margin")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.pretaxIncomeMargin !== null
                            ? Utils.valueFormater(data.pretaxIncomeMargin) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">EBIT Margin</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EBIT Margin")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITMargin !== null
                            ? Utils.valueFormater(data.eBITMargin) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Net Income Margin</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Income Margin")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.netIncomeMargin !== null
                            ? Utils.valueFormater(data.netIncomeMargin) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Return on Equity - Total</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Return on Equity - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.returnonEquityTotal !== null
                            ? Utils.valueFormater(data.returnonEquityTotal) +
                              "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Return on Invested Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Return on Invested Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.returnonInvestedCapital !== null
                            ? Utils.valueFormater(
                                data.returnonInvestedCapital
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Return on Assets</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Return on Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.returnonAssets !== null
                            ? Utils.valueFormater(data.returnonAssets) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Asset Turnover</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Asset Turnover")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.assetTurnover !== null
                            ? Utils.valueFormater(data.assetTurnover)
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Financial Leverage</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Financial Leverage")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.financialLeverage !== null
                            ? Utils.valueFormater(data.financialLeverage) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Effective Tax Rate</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Effective Tax Rate")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.effectiveTaxRate !== null
                            ? Utils.valueFormater(data.effectiveTaxRate) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className=""> Cash Flow % Sales</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash Flow % Sales")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.cashFlowPercentSales !== null
                            ? Utils.valueFormater(data.cashFlowPercentSales) +
                              "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>
                      <span className="">
                        Selling, General & Administrative Expenses % of Sales
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "SellingGeneralAndAdministrativeExpensesOfSales"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.sellingGeneralAndAdministrativeExpensesPercentofSales !==
                          null
                            ? Utils.valueFormater(
                                data.sellingGeneralAndAdministrativeExpensesPercentofSales
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">Research & Development Expense</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Research & Development Expense"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.researchAndDevelopmentExpense !== null
                            ? Utils.valueFormater(
                                data.researchAndDevelopmentExpense
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className="">
                        Operating Income Return On Total Capital
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Operating Income Return On Total Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.profitabilityAnalysisList.map((data) => {
                      return (
                        <td>
                          {data.operatingIncomeReturnOnTotalCapital !== null
                            ? Utils.valueFormater(
                                data.operatingIncomeReturnOnTotalCapital
                              ) + "%"
                            : "0%"}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Profitability Analysis
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp; {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
