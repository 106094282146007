import {
  GET_REGION_FILTER,
  SET_REGION_FILTER,
} from "./userSelectedRegionTypes";

const initialState = {
  selectedRegions: [],
};

const userSelectedRegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGION_FILTER:
      return {
        ...state,
      };
    case SET_REGION_FILTER:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    default:
      return state;
  }
};

export default userSelectedRegionReducer;
