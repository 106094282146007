import TextField from "@material-ui/core/TextField";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { default as VisibilityIcon } from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { countries } from "country-data";
import MaterialTable from "material-table";
import Moment from "moment";
import React, { Component, forwardRef } from "react";
import Table from "react-bootstrap/Table";
import { Slide } from "react-slideshow-image";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "../../../node_modules/rc-tabs/assets/index.css";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import Advertisement from "../ciadvertisement/Advertisement";
import "./CurrencyExchangeRate.css";
export default class CurrencyExchangeRate extends Component {
  defaultCurrency = {
    code: "978",
    country: "Europe",
    currency: "Euro",
    adjective: "European",
    currencyName: "European Euro",
  };
  constructor(props) {
    super(props);

    this.tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Visibility: forwardRef((props, ref) => (
        <VisibilityIcon {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
    };
    let countries = Utils.getCountryData();

    this.state = {
      value: 0,
      currencyList: [],
      countryList: countries,
      selectedCurrency: this.defaultCurrency,
      priceDate: new Date(),
      usaCurrency: {},
      currencyRateList: [],
      render: false,
      defaultValueCurrency: 1,
      defaultValueCurrency1: 1,
    };
    this.loadCurrency.bind(this);
  }

  loadCurrency(currency) {
    let currencySelected = {
      code: currency.code,
      country: currency.country,
      currency: currency.currency,
      adjective: currency.adjective,
      currencyName: currency.currencyName,
    };

    this.setState({ selectedCurrency: currencySelected });
    this.getSelectedCurrencyData(currencySelected);
  }
  getCountryWithFlag(currency) {
    let getCountryWithFlag = "";

    getCountryWithFlag = (
      <span>
        {Utils.getCountryFlag(currency.country)}&nbsp; {currency.currencyName}{" "}
      </span>
    );

    return getCountryWithFlag;
  }

  componentDidMount() {
    const postObject = {};

    FetchServerData.callPostService("currencyRate/getList", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          this.setState({
            currencyList: data,
          });
          this.getSelectedCurrencyData(this.state.selectedCurrency);
        } else {
        }
      })
      .catch((error) => {});
  }

  handleCurrencyClick(currency) {
    if (!currency) {
      return;
    }

    this.setState({
      selectedCurrency: currency,
    });
    this.getSelectedCurrencyData(currency);
  }

  handleCurrencyChange(e, values) {
    if (!values) {
      return;
    }

    this.setState({
      selectedCurrency: values,
    });
    this.getSelectedCurrencyData(values);
  }

  handleDefaultCurrencyValueChange(e) {
    this.setState({
      defaultValueCurrency: +e.target.value,
    });
  }

  handleDefaultCurrencyValueChange1(e) {
    this.setState({
      defaultValueCurrency1: +e.target.value,
    });
  }
  MaterialTableTitle() {
    return (
      <>
        <Row className="currency_web_view1">
          <Col md={4} style={{ paddingLeft: "7em" }}>
            <b>Amount</b>
          </Col>
          <Col md={3}>
            <b>From</b>
          </Col>
          <Col md={4} style={{ paddingLeft: "2em" }}>
            <b>To</b>
          </Col>
        </Row>
        <Row
          md={12}
          style={{
            marginBottom: "0.2em",
            textAlign: "center",
            fontSize: "small",
            width: "100%",
          }}
          className="currency_web_view"
        >
          <Col md={5} style={{ textAlign: "right" }}>
            <Row>
              <Col md={6}>
                {" "}
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  <input
                    type="number"
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      textAlign: "center",
                    }}
                    required
                    value={this.state.defaultValueCurrency}
                    onChange={this.handleDefaultCurrencyValueChange.bind(this)}
                  />
                  {/* <TextField
                name="lastName"
                variant="outlined"
        
                inputProps={{
                  style: { textTransform: "capitalize" },
                }}
                fullWidth
                id="name"
                label="Name"
                autoComplete="off"
              /> */}
                </span>
              </Col>

              <Col md={6}>
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.usaCurrency
                    ? this.state.usaCurrency.currencyName
                    : "U.S Dollar"}
                </span>
              </Col>
            </Row>
            {/* &nbsp;{" "} */}
          </Col>
          <Col md={2}>
            <ArrowForwardIcon />
          </Col>
          <Col md={5} style={{ textAlign: "left" }}>
            <span
              style={{
                color: "#ff4500",
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {this.state.usaCurrency
                ? this.addCommasToValue(
                    this.state.usaCurrency.crossRate *
                      this.state.defaultValueCurrency.toFixed(3)
                  )
                : "1"}
            </span>
            <span
              style={{
                color: "#003b6d",
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              &nbsp; {this.state.selectedCurrency.currencyName}
            </span>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "0.2em",
            textAlign: "center",
            fontSize: "small",
            width: "100%",
          }}
          className="currency_web_view"
        >
          <Col md={5} style={{ textAlign: "right" }}>
            <Row>
              <Col md={6}>
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  <input
                    type="number"
                    style={{
                      width: "60%",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      border: "collapse",

                      textAlign: "center",
                    }}
                    required
                    value={this.state.defaultValueCurrency1}
                    onChange={this.handleDefaultCurrencyValueChange1.bind(this)}
                  />
                  {/* {this.state.selectedCurrency
                ? this.state.selectedCurrency.rate
                : "1"} */}
                </span>
              </Col>

              <Col md={6}>
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.selectedCurrency.currencyName}
                </span>
              </Col>
            </Row>
            {/* &nbsp;{" "} */}
          </Col>
          <Col md={2}>
            <ArrowForwardIcon />
          </Col>
          <Col md={5} style={{ textAlign: "left" }}>
            <span
              style={{
                color: "#ff4500",
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {this.state.usaCurrency
                ? this.addCommasToValue(
                    this.state.usaCurrency.rate *
                      this.state.defaultValueCurrency1.toFixed(3)
                  )
                : "1"}
            </span>{" "}
            <span
              style={{
                color: "#003b6d",
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {this.state.usaCurrency
                ? this.state.usaCurrency.currencyName
                : "U.S Dollar"}
            </span>
          </Col>
        </Row>
        <Row className="currency_mobile_view_table">
          {/* <Col md={4} style={{ paddingLeft: "7em" }}></Col>
          <Col md={3}></Col>
          <Col md={4} style={{ paddingLeft: "2em" }}></Col> */}
          <Table>
            <tr>
              <td>
                <b>Amount</b>&nbsp;
                <b>From</b>
              </td>
              <td></td>
              <td>
                <b>To</b>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      width: "25%",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      textAlign: "center",
                    }}
                    required
                    value={this.state.defaultValueCurrency}
                    onChange={this.handleDefaultCurrencyValueChange.bind(this)}
                  />
                </span>
                &nbsp;{" "}
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.usaCurrency
                    ? this.state.usaCurrency.currencyName
                    : "U.S Dollar"}
                </span>
              </td>
              <td>
                {" "}
                <ArrowForwardIcon style={{ fontSize: "3em" }} />
              </td>
              <td>
                {" "}
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.usaCurrency
                    ? this.state.usaCurrency.crossRate
                    : "1"}
                </span>
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  &nbsp; {this.state.selectedCurrency.currencyName}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                {" "}
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {/* {this.state.selectedCurrency
                    ? this.state.selectedCurrency.rate
                    : "1"} */}
                  <input
                    tyoe="text"
                    style={{
                      width: "25%",
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      border: "collapse",
                      border: "1px transparent",
                      textAlign: "center",
                    }}
                    required
                    value={this.state.defaultValueCurrency}
                    disabled
                  />
                </span>
                &nbsp;{" "}
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.selectedCurrency.currencyName}
                </span>
              </td>
              <td>
                <ArrowForwardIcon style={{ fontSize: "3em" }} />
              </td>
              <td>
                <span
                  style={{
                    color: "#ff4500",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.usaCurrency ? this.state.usaCurrency.rate : "1"}
                </span>{" "}
                <span
                  style={{
                    color: "#003b6d",
                    fontSize: "1em",
                    fontWeight: "bold",
                  }}
                >
                  {this.state.usaCurrency
                    ? this.state.usaCurrency.currencyName
                    : "U.S Dollar"}
                </span>
              </td>
            </tr>
          </Table>
        </Row>

        <Row
          style={{
            marginBottom: "0.5em",
            textAlign: "center",
            fontSize: "small",
          }}
        >
          <Col md={12} style={{ textAlign: "center" }}>
            <span style={{ fontSize: "0.9em" }}>Exchange Rate of the </span>
            <span
              style={{
                fontSize: "0.9em",
                color: "#f26d21",
                fontWeight: "bold",
              }}
            >
              {this.state.selectedCurrency.currencyName}
            </span>
            <span> versus other key currencies</span>
          </Col>
        </Row>
      </>
    );
  }
  getSelectedCurrencyData(values) {
    const postObject = values;
    FetchServerData.callPostService("currencyRate/getFor", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let currencyRateList = data.currencies;
          for (let i = 0; i < this.state.countryList.length; i++) {
            for (let j = 0; j < currencyRateList.length; j++) {
              if (
                this.state.countryList[i].name === currencyRateList[j].country
              ) {
                currencyRateList[j].codeCountry =
                  this.state.countryList[i].alpha2;
              }
            }
          }
          this.setState({
            selectedCurrency: data.baseCurrency,
            usaCurrency: data.usCurrency,
            priceDate: Moment(data.priceDate).format("MM/DD/YYYY"),
            currencyRateList: currencyRateList,
            render: true,
          });
          document.getElementById("select_currency_dropdown").focus();
        } else {
        }
      })
      .catch((error) => {});
  }

  getClickedRegionData(values) {
    const postObject = values;
    FetchServerData.callPostService("currencyRate/getFor", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let currencyRateList = data.currencies;
          let date = "";

          if (data.priceDate) {
            date = data.priceDate;
          }
          for (let i = 0; i < currencyRateList.length; i++) {
            if (currencyRateList[i].code === values.code) {
            }
          }
          let usCurrencyRate = "";
          let usCurrencyCrossRate = "";
          for (let i = 0; i < currencyRateList.length; i++) {
            if (currencyRateList[i].code === "840") {
              usCurrencyRate = currencyRateList[i].rate;
              usCurrencyCrossRate = currencyRateList[i].crossRate;
            }
          }

          for (let i = 0; i < this.state.countryList.length; i++) {
            for (let j = 0; j < currencyRateList.length; j++) {
              if (
                this.state.countryList[i].name === currencyRateList[j].country
              ) {
                currencyRateList[j].codeCountry =
                  this.state.countryList[i].alpha2;
              }
            }
          }
          this.setState({
            baseCurrencyName: data.baseCurrency.currencyName,
            selectedCurrency: values,
            selectedCurrencyName: values.currencyName,
            currencyRateList: currencyRateList,
            date: Moment(date).format("MM/DD/YYYY"),
            priceDate: Moment(data.priceDate).format("MM/DD/YYYY"),
            rate: data.baseCurrency.rate,
            crossRate: data.baseCurrency.crossRate,
            usCurrencyRate: usCurrencyRate,
            usCurrencyCrossRate: usCurrencyCrossRate,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  addCommasToValue(value) {
    if (value) {
      if (value !== "-") {
        let array = value.toString().split(".");
        if (array.length > 1) {
          let beforeDecimal = array[0];
          let afterDecimal = array[1];
          if (afterDecimal === undefined || afterDecimal === null) {
            afterDecimal = "0000";
          }
          beforeDecimal = beforeDecimal
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          value = beforeDecimal + "." + afterDecimal;
        } else {
          value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          value += ".0000";
        }
        return value;
      } else {
        return "0.0000";
      }
    } else {
      value = "0.0000";
      return value;
    }
  }
  render() {
    const style = {};
    let locationItems = [];
    this.state.currencyRateList.forEach((currency, index) => {
      locationItems.push(
        <div style={style} key={index}>
          <div
            className="col-md-6"
            style={{ cursor: "pointer", width: "90%" }}
            onClick={() => this.getSelectedCurrencyData(currency)}
          >
            <div className=" ">
              <Row>
                <Col md={3}>{Utils.getCountryFlag(currency.country)}</Col>
                <Col md={9}>
                  <span
                    style={{
                      color: "rgb(242, 109, 33)",
                      fontSize: "small",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {currency.currencyName}
                  </span>
                  <p
                    className="description"
                    style={{
                      color: "#305388",
                      fontSize: "small",
                    }}
                  >
                    {currency.rate}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    });

    const webProperties = {
      duration: 2000,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      indicators: false,
      arrows: false,
    };
    const phoneProperties = {
      duration: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      indicators: false,
      arrows: false,
    };
    return (
      <div style={{ marginBottom: "2em", minHeight: "35em !important" }}>
        <Row style={{ marginTop: "5em" }}>
          <Col
            md={12}
            className="page_header_slider"
            style={{ marginTop: "1em" }}
          >
            <Slide {...webProperties} className="slider_web">
              {locationItems}
            </Slide>
            <Slide {...phoneProperties} className="slider_mobile">
              {locationItems}
            </Slide>
          </Col>
        </Row>
        <Row style={{ marginTop: "0em" }}>
          <Col md={2}>
            <Advertisement advertisementType="verticalAddUnit" />
            <br></br>
          </Col>

          <Col
            md={8}
            style={{ marginBottom: "2em", minHeight: "35em !important" }}
            className="pts_mobile_view currency_slider"
          >
            {this.state.render === true ? (
              <Card className="cardOver" style={{ marginTop: "-1.8em" }}>
                <CardHeader className="exchange_card_header">
                  <Row md={12} className="currency_row ">
                    <Col md={4} className="currency_column">
                      <span className="exchange_h4" style={{ float: "right" }}>
                        Exchange Rate For :
                      </span>
                    </Col>
                    <Col
                      md={4}
                      className="currency_column"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      <Autocomplete
                        type="text"
                        id="select_currency_dropdown"
                        options={this.state.currencyList}
                        getOptionLabel={(option) => option.currencyName}
                        value={this.state.selectedCurrency}
                        filterSelectedOptions
                        onChange={this.handleCurrencyChange.bind(this)}
                        renderInput={(params) => (
                          <TextField
                            margin="normal"
                            {...params}
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                    <Col md={4} style={{ lineHeight: "3.8em" }}>
                      <span className="exchange_h4" style={{ float: "left" }}>
                        On {this.state.priceDate}
                      </span>
                    </Col>
                  </Row>
                  {this.MaterialTableTitle()}
                </CardHeader>
                <CardBody>
                  <div className="outerdiv">
                    <MaterialTable
                      icons={this.tableIcons}
                      columns={[
                        {
                          title: "Currency",
                          render: (rowData) => this.getCountryWithFlag(rowData),

                          align: "left",
                          width: "40%",
                          sorting: true,
                          customSort: (a, b) => {
                            return a.currencyName
                              .toString()
                              .localeCompare(b.currencyName.toString());
                          },
                        },
                        {
                          title:
                            "Per " + this.state.selectedCurrency.currencyName,
                          render: (rowData) =>
                            this.addCommasToValue(rowData.rate),
                          align: "left",
                          width: "30%",
                          type: "text",
                          sorting: true,
                          customSort: (a, b) => {
                            let aval = a.rate;
                            aval = aval
                              ? aval.toString().replace(",", "")
                              : "0";
                            let bval = b.rate;
                            bval = bval
                              ? bval.toString().replace(",", "")
                              : "0";

                            return parseFloat(aval) > parseFloat(bval) ? 1 : -1;
                          },
                        },
                        {
                          title:
                            "To " + this.state.selectedCurrency.currencyName,
                          render: (rowData) =>
                            this.addCommasToValue(rowData.crossRate),
                          align: "left",
                          width: "30%",
                          type: "text",
                          sorting: true,
                          customSort: (a, b) => {
                            let aval = a.crossRate;
                            aval = aval
                              ? aval.toString().replace(",", "")
                              : "0";
                            let bval = b.crossRate;
                            bval = bval
                              ? bval.toString().replace(",", "")
                              : "0";

                            return parseFloat(aval) > parseFloat(bval) ? 1 : -1;
                          },
                        },
                      ]}
                      data={this.state.currencyRateList}
                      options={{
                        search: false,
                        sorting: true,
                        toolbar: false,
                        pageSizeOptions: [10, 25, 50, 75, 100],
                        actionsColumnIndex: -1,
                        pageSize: 100,
                        paging: false,
                        headerStyle: {
                          backgroundColor: "#2b7d6d",
                          fontWeight: "bold",
                          color: "#fff",
                          align: "center",
                          fontSize: "1.2em",
                        },

                        rowStyle: (rowData) => ({
                          background:
                            rowData.tableData.id % 2 ? "#9dd6fb1a" : "#FFF",
                          padding: "0",
                        }),
                        filtering: false,
                      }}
                      style={{
                        fontSize: "14px",
                        boxShadow:
                          " 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1)",
                      }}
                      onRowClick={(event, rowData) => {
                        this.getSelectedCurrencyData(rowData);
                        event.stopPropagation();
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            ) : (
              <div className="pageHeight"></div>
            )}
          </Col>

          <Col md={2}>
            <Advertisement advertisementType="verticalAddUnit2" />
            <br></br>
          </Col>
        </Row>
      </div>
    );
    // }
  }
}
