import React from "react";
import "./PageContent.css";
export default function PageContent({ children }) {
  window.scrollTo(0, 0);
  return (
    <main tabIndex="-1" role="main" style={{ height: "40em !important" }}>
      {children}
    </main>
  );
}
