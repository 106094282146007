import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DeleteIcon from "@material-ui/icons/Delete";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import GenericButton from "../buttons/GenericButton";
import Advertisement from "../ciadvertisement/Advertisement";
import Login from "../loginComponent/Login";
import "./Cart.css";
export default class Cart extends Component {
  cartData = store.getState().cartData.cart;
  userData = store.getState().userData.userData;

  loginRefList = [];
  constructor(props) {
    super(props);

    let previousPage = "";
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      previousPage = props.history.location.state.previousPage;
    }
    if (previousPage === "") {
      previousPage = "/ci";
    } else if (previousPage === "/indreportdetail") {
      previousPage = "/industryreport";
    }
    let isMemberLogin = true;
    if (this.userData) {
      isMemberLogin = false;
    }

    let total = parseFloat(0);
    for (let i = 0; i < this.cartData.length; i++) {
      total += parseFloat(this.cartData[i].price);
    }
    let isReportEmpty = false;
    let isReportNotEmpty = true;
    let isPayNow = true;
    if (this.cartData.length === 0) {
      isReportEmpty = true;
      isReportNotEmpty = false;
      isPayNow = false;
    }

    this.state = {
      cartReports: this.cartData,
      isReportNotEmpty: isReportNotEmpty,
      total: total,
      isMemberLogin: isMemberLogin,
      isReportEmpty: isReportEmpty,
      userData: this.userData,
      totalItem: this.cartData.length,
      previousPage: previousPage,
      firstName: "",
      lastName: "",
      userName: "",
      isPayNow: isPayNow,
    };

    this.loginRefList = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  getModalPopup(e) {
    if (this.loginRefList && this.loginRefList.current) {
      this.loginRefList.current.state.showModal = true;
    }
    this.setState({
      modalShow: true,
      showModal: false,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  removeItemFromCart(ticker, companyName) {
    let total = parseFloat(0);
    for (let i = 0; i < this.cartData.length; i++) {
      if (this.cartData[i].ticker === ticker) {
        this.cartData.splice(i, 1);
      }
    }

    for (let i = 0; i < this.cartData.length; i++) {
      total += parseFloat(this.cartData[i].price);
    }
    let isReportEmpty = false;
    let isReportNotEmpty = true;
    let isPayNow = true;
    if (this.cartData.length === 0) {
      isReportEmpty = true;
      isReportNotEmpty = false;
      isPayNow = false;
    }
    store.dispatch(cartAction.setCart(this.cartData));
    this.setState({
      cartReports: this.cartData,
      total: total,
      isPayNow: isPayNow,
      isReportEmpty: isReportEmpty,
      isReportNotEmpty: isReportNotEmpty,
      totalItem: this.cartData.length,
    });
    this.props.history.push({
      pathname: "/cart",
    });
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }
  backToShopping() {
    this.props.history.push({
      pathname: this.state.previousPage,
    });
  }
  toCheckout(selectedPlan) {
    let userData = store.getState().userData.userData;
    let isMemberLogin = false;
    if (!userData) {
      isMemberLogin = true;
    }
    if (userData) {
      this.setState({
        firstName: userData.firstName,
        lastName: userData.lastName,
        userName: userData.email,
        selectedPlan: selectedPlan,
        showModal: true,
        isMemberLogin: isMemberLogin,
      });
    } else {
      this.setState({
        selectedPlan: selectedPlan,
        showModal: true,
        isMemberLogin: isMemberLogin,
      });
    }
  }
  toBuyReport() {
    this.setState({
      showModal: false,
    });
    this.props.history.push({
      pathname: "/reportcheckout",
      state: {
        planDetails: this.state.selectedPlan,
        totalPrice: this.state.total,
        userDetails: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.userName,
        },
      },
    });
  }

  render() {
    this.cartData = store.getState().cartData.cart;
    let itemRow = this.state.cartReports.map((report, i) => {
      return (
        <tr style={{ background: "white" }}>
          <td style={{ borderRight: "1px solid", textAlign: "left" }}>
            {report.planId === "3" ? (
              <img
                src="home/assets/img/CartReportImage.gif"
                alt="Company Report"
                height="20%"
              ></img>
            ) : (
              <img
                src="home/assets/img/ind_cart_rpt.gif"
                alt="Company Report"
                height="20%"
              ></img>
            )}
            {report.type !== null ? report.type : ""}
            {" Report - "}
            {report.planId !== null ? report.companyName : "n/a"}
          </td>
          <td style={{ textAlign: "left", borderRight: "1px solid" }}>
            {"$" + report.price}
          </td>
          <td
            style={{
              borderRight: "1px solid",
              textAlign: "center",
            }}
          >
            <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
              <DeleteIcon
                style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
                onClick={(e) =>
                  this.removeItemFromCart(report.ticker, report.companyName)
                }
              ></DeleteIcon>
            </span>
          </td>
        </tr>
      );
    });
    return (
      <>
        <Row style={{ marginTop: "5em" }}>
          <Col md={2} style={{ marginTop: "1em" }}></Col>
          <Col md={8} style={{ marginTop: "2em" }} className="pageHeight">
            <Container maxWidth="xl">
              <Row style={{ margin: "0em -1.6em" }}>
                <Row>
                  <Col md={12}>
                    <span style={{ fontWeight: "600" }}>
                      Total purchased items: <span>{this.state.totalItem}</span>
                    </span>
                    <br></br>
                    <span
                      style={{ fontWeight: "600", color: "rgb(242, 109, 33)" }}
                    >
                      The Purchased report will be available for 7 days.
                    </span>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col
                  md={12}
                  style={{
                    margin: 0,
                    padding: 0,
                    boxShadow:
                      " 0px 3px rgba(128,128,128,1), 0px 4px rgba(118,118,118,1), 0px 5px rgba(108,108,108,1), 0px 6px rgba(98,98,98,1), 0px 7px rgba(88,88,88,1), 0px 8px rgba(78,78,78,1), 0px 14px 6px -1px rgba(128,128,128,1)",
                  }}
                >
                  <Table
                    responsive
                    class="sum_analysis"
                    style={{
                      border: "1px solid",
                      margin: "0.5em 0em 0em 0em",
                    }}
                  >
                    <thead
                      style={{
                        fontSize: "0.8em",
                        background: "#2b7d6d",
                        color: "#fff",
                      }}
                    >
                      <tr style={{ textAlign: "center" }}>
                        <th
                          class="sum_analysis"
                          style={{
                            borderRight: "1px solid",
                            textDecoration: "underline",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                        >
                          Reports:
                        </th>
                        <th
                          class="sum_analysis"
                          style={{
                            borderRight: "1px solid",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                        >
                          Price
                        </th>
                        <th
                          class="sum_analysis"
                          colSpan="3"
                          style={{
                            borderRight: "1px solid",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                        >
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemRow}
                      <tr style={{ textAlign: "center" }}>
                        <td
                          class="sum_analysis"
                          style={{
                            textDecoration: "underline",
                            textAlign: "left",
                          }}
                        >
                          {this.state.isReportEmpty && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "0.7em",
                              }}
                            >
                              There are No Reports in the Shopping Cart
                            </span>
                          )}
                          {this.state.isReportNotEmpty && "Total"}
                        </td>
                        <td class="sum_analysis" style={{ textAlign: "left" }}>
                          {this.state.isReportNotEmpty && this.state.total
                            ? "$" + this.state.total.toFixed(2)
                            : ""}
                        </td>
                        <td
                          class="sum_analysis"
                          colSpan="3"
                          style={{
                            borderRight: "1px solid",
                            cursor: "pointer",
                          }}
                        ></td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row style={{ margin: "1em " }}>
                <Col md={6} className="cart_btn_space">
                  <GenericButton
                    style={{ float: "right" }}
                    icon={<ShoppingCartIcon />}
                    name="Continue Shopping"
                    buttonType="cart"
                    onClick={this.backToShopping.bind(this)}
                  />
                </Col>
                <Col md={6} className="cart_btn_space">
                  {this.state.isPayNow && (
                    <GenericButton
                      style={{ float: "left" }}
                      icon={<CreditCardIcon />}
                      name="Pay Now"
                      buttonType="cart"
                      onClick={this.toCheckout.bind(
                        this,
                        this.state.cartReports
                      )}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={2} style={{ marginTop: "1em" }}>
            <Advertisement advertisementType="verticalAddUnit" />
          </Col>
        </Row>

        <Modal isOpen={this.state.showModal} size="md" className="modalContain">
          <ModalHeader style={{ background: "#286894", color: "#fff" }}>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    float: "left",
                  }}
                >
                  User Details
                </Label>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.toBuyReport.bind(this, this.state.cartReports)}
                >
                  <Row style={{ margin: "1em" }}>
                    <Col md={12}>
                      <TextField
                        name="firstName"
                        variant="outlined"
                        required
                        value={this.state.firstName}
                        onChange={this.handleFirstNameChange.bind(this)}
                        inputProps={{
                          style: { textTransform: "capitalize" },
                        }}
                        fullWidth
                        id="name"
                        label="First Name"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ margin: "1em", marginTop: "1.5em" }}>
                    <Col md={12}>
                      <TextField
                        name="lastName"
                        variant="outlined"
                        required
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange.bind(this)}
                        inputProps={{
                          style: { textTransform: "capitalize" },
                        }}
                        fullWidth
                        id="name"
                        label="Last Name"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ margin: "1em", marginTop: "1.5em" }}>
                    <Col md={12}>
                      <TextValidator
                        name="email"
                        variant="outlined"
                        value={this.state.userName}
                        onChange={this.handleUserNameChange.bind(this)}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                        fullWidth
                        id="email"
                        label="Email"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          background: "#f26d21",
                          color: "#fff",
                          height: "100%",
                          borderBottom: "3px solid #636262",
                          borderRadius: "5px",
                          margin: 0,
                        }}
                        type="submit"
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>
                  {this.state.isMemberLogin && (
                    <Row style={{ textAlign: "center", marginTop: "1em" }}>
                      <Col md={12}>
                        <h4 className="isMember">
                          If already member?{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              color: "rgb(242, 109, 33)",
                            }}
                            onClick={(e) => this.getModalPopup(e)}
                          >
                            Login
                          </span>
                        </h4>
                      </Col>
                    </Row>
                  )}
                </ValidatorForm>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Login
          ref={this.loginRefList}
          data={this.state.selectedPlan}
          pageId="plans"
          history={this.props.history}
          isLogin={true}
          modalShow={this.state.modalShow}
          showPage="Login"
        ></Login>
      </>
    );
  }
}
