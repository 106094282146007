import Container from "@material-ui/core/Container";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import $ from "jquery";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Row } from "reactstrap";
import GenericSearch from "../search/GenericSearch";
import "./CorporateInformationHome.css";
export default class CorporateInformationHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      searchCompany: "",
    };
    window.scrollTo(0, 0);
  }
  searchCompany = (e) => {
    this.props.history.push({
      pathname: "/search",
      state: {
        searchCompany: this.state.searchCompany,
        searchType: "simple search",
      },
    });
  };
  toAdvanceSearch = (event, value) => {
    this.props.history.push({
      pathname: "/search",
      state: { searchType: "advance search" },
    });
  };
  handleCompanyChange(e) {
    this.setState({ searchCompany: e.target.value });
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.searchCompany();
    }
  };
  // formValidation() {
  //   let searchCompany = this.state.searchCompany;
  //   if (null === searchCompany || searchCompany.length <= 0) {
  //     PtsAlert.error("Enter the company name or ticker");
  //     return false;
  //   } else {
  //     this.searchCompany();
  //   }
  // }
  performAdvanceSearch() {
    this.props.history.push({
      pathname: "/search",
      state: {
        searchText: this.state.searchText,
        searchType: "advance search",
      },
    });
  }
  toCurrencyExchange() {
    this.props.history.push({
      pathname: "currency_exchange",
    });
  }
  componentDidMount() {
    var testim = document.getElementById("testim"),
      testimDots = Array.prototype.slice.call(
        document.getElementById("testim-dots").children
      ),
      testimContent = Array.prototype.slice.call(
        document.getElementById("testim-content").children
      ),
      testimLeftArrow = document.getElementById("left-arrow"),
      testimRightArrow = document.getElementById("right-arrow"),
      testimSpeed = 4500,
      currentSlide = 0,
      currentActive = 0,
      testimTimer,
      touchStartPos,
      touchEndPos,
      touchPosDiff,
      ignoreTouch = 30;
    window.onload = function () {
      // Testim Script
      function playSlide(slide) {
        for (var k = 0; k < testimDots.length; k++) {
          testimContent[k].classList.remove("active");
          testimContent[k].classList.remove("inactive");
          testimDots[k].classList.remove("active");
        }

        if (slide < 0) {
          slide = currentSlide = testimContent.length - 1;
        }

        if (slide > testimContent.length - 1) {
          slide = currentSlide = 0;
        }

        if (currentActive !== currentSlide) {
          testimContent[currentActive].classList.add("inactive");
        }
        testimContent[slide].classList.add("active");
        testimDots[slide].classList.add("active");

        currentActive = currentSlide;

        clearTimeout(testimTimer);
        testimTimer = setTimeout(function () {
          playSlide((currentSlide += 1));
        }, testimSpeed);
      }

      testimLeftArrow.addEventListener("click", function () {
        playSlide((currentSlide -= 1));
      });

      testimRightArrow.addEventListener("click", function () {
        playSlide((currentSlide += 1));
      });

      for (var l = 0; l < testimDots.length; l++) {
        testimDots[l].addEventListener("click", function () {
          playSlide((currentSlide = testimDots.indexOf(this)));
        });
      }

      playSlide(currentSlide);

      // keyboard shortcuts
      document.addEventListener("keyup", function (e) {
        switch (e.keyCode) {
          case 37:
            testimLeftArrow.click();
            break;

          case 39:
            testimRightArrow.click();
            break;

          // case 39:
          //   testimRightArrow.click();
          //   break;

          default:
            break;
        }
      });

      testim.addEventListener("touchstart", function (e) {
        touchStartPos = e.changedTouches[0].clientX;
      });

      testim.addEventListener("touchend", function (e) {
        touchEndPos = e.changedTouches[0].clientX;

        touchPosDiff = touchStartPos - touchEndPos;

        if (touchPosDiff > 0 + ignoreTouch) {
          testimLeftArrow.click();
        } else if (touchPosDiff < 0 - ignoreTouch) {
          testimRightArrow.click();
        } else {
          return;
        }
      });
    };
    $(document).on("click", ".naccs .menu div", function () {
      var numberIndex = $(this).index();

      if (!$(this).is("active")) {
        $(".naccs .menu div").removeClass("active");
        $(".naccs ul li").removeClass("active");

        $(this).addClass("active");
        $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .addClass("active");

        var listItemHeight = $(".naccs ul")
          .find("li:eq(" + numberIndex + ")")
          .innerHeight();
        $(".naccs ul").height(listItemHeight + "px");
      }
    });
    // let sortBtn = document.querySelector(".filter-menu").children;
    // let sortItem = document.querySelector(".filter-item").children;

    // for (let i = 0; i < sortBtn.length; i++) {
    //   sortBtn[i].addEventListener("click", function () {
    //     for (let j = 0; j < sortBtn.length; j++) {
    //       sortBtn[j].classList.remove("current");
    //     }

    //     this.classList.add("current");

    //     let targetData = this.getAttribute("data-target");

    //     for (let k = 0; k < sortItem.length; k++) {
    //       sortItem[k].classList.remove("active");
    //       sortItem[k].classList.add("delete");

    //       if (
    //         sortItem[k].getAttribute("data-item") == targetData ||
    //         targetData == "Wright Quality Analysis"
    //       ) {
    //         sortItem[k].classList.remove("delete");
    //         sortItem[k].classList.add("active");
    //       }
    //     }
    //   });
    // }
  }
  render() {
    return (
      <>
        {/* <HomeHeader></HomeHeader> */}

        <div>
          <section id="hero" className="hero d-flex align-items-center">
            <Container maxWidth="lg">
              {/* <div className="container"> */}
              <div className="row">
                <div className="col-lg-7 d-flex flex-column justify-content-center">
                  {/* <p data-aos="fade-up"> */}
                  <p>
                    Delivering the <b></b>
                    <span style={{ color: "#d35400" }}>
                      <b>Financial</b>
                    </span>{" "}
                    World to you
                  </p>
                  <h2
                    // data-aos="fade-up"
                    // data-aos-delay={1500}
                    style={{ fontFamily: "Nunito" }}
                  >
                    Up-to-Date Financial Information on the Leading<br></br>
                    Publicly-Traded Companies Worldwide
                  </h2>
                  <div
                    className="srch"
                    // data-aos="fade-up"
                    // data-aos-delay={1500}
                  >
                    <div className="searchTicker">
                      <div>
                        <Row>
                          <GenericSearch
                            history={this.props.history}
                            pageId="ciHome"
                          />
                        </Row>
                        <Row>
                          <div
                            className="row"
                            data-aos="fade-up"
                            data-aos-delay={1500}
                          >
                            <a
                              href="#"
                              style={{
                                cursor: "pointer",
                                fontSize: "large",
                                color: "#F26D21",
                                float: "left",
                                outline: "none",
                                lineHeight: "3em",
                              }}
                              onClick={this.toCurrencyExchange.bind(this)}
                            >
                              <strong>
                                <u>Currency Exchange Rates</u>
                              </strong>
                            </a>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {/* <Row className="advertisement">
                  <Col md={12}>
                    <Advertisement advertisementType="CurrencyPageLeft" />
                  </Col>
                </Row> */}
                  <div style={{ marginTop: "" }}>
                    <div
                      className="row"
                      data-aos="fade-up"
                      data-aos-delay={1500}
                    >
                      <span
                        style={{
                          color: "#4154f1",
                          fontWeight: "bold",
                          fontSize: "1.5em",
                        }}
                      >
                        Financial Information on Over 46,000 companies worldwide
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5 hero-img"
                  // data-aos="zoom-out"
                  // data-aos-delay={800}
                  style={{ marginTop: "1.5em" }}
                >
                  <img
                    src="/home/assets/img/img1.png"
                    className="img-fluid"
                    alt=""
                    style={{ maxWidth: "130%", height: "34em" }}
                  />
                </div>

                {/*  </div> */}
              </div>
              {/* </div> */}
            </Container>
          </section>
          <>
            <main id="main">
              <section id="values" className="values">
                <div className="container" data-aos="fade-up">
                  <header className="section-header">
                    <p style={{ color: "black" }}>Our Offering</p>
                  </header>
                  <div className="row">
                    <div className="col-lg-3">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={200}
                        style={{
                          background: "#2db6fa",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="home/assets/img/u1.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3> Global Research and Analysis</h3>
                        {/* <p>
                        Eum ad dolor et. Autem aut fugiat debitis voluptatem
                        consequuntur sit. Et veritatis id.
                      </p> */}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={400}
                        style={{
                          background: "#ff9933",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="home/assets/img/u2.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3>In-Depth Financial Reports on Companies</h3>
                        {/* <p>
                        Repudiandae amet nihil natus in distinctio suscipit id.
                        Doloremque ducimus ea sit non.
                      </p> */}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={600}
                        style={{
                          background: "#b50edf",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="home/assets/img/u3.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3>
                          Comparative Business Analysis on over 46,000 Companies
                          Worldwide
                        </h3>
                        {/* <p>
                        Repudiandae amet nihil natus in distinctio suscipit id.
                        Doloremque ducimus ea sit non.
                      </p> */}
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 mt-lg-0">
                      <div
                        className="box"
                        data-aos="fade-up"
                        data-aos-delay={800}
                        style={{
                          background: "#08da4e",
                          boxShadow: "grey 1px 1px 5px inset",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src="home/assets/img/u4.png"
                          className="img-fluid"
                          alt=""
                        />
                        <h3> Over 800 Reports on Industries Worldwide</h3>
                        {/* <p>
                        Quam rem vitae est autem molestias explicabo debitis
                        sint. Vero aliquid quidem commodi.
                      </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* End Values Section */}
              {/* <section
              id="services"
              className="services cardservice"
              style={{ background: "#f2f5fc" }}
            >
              <Container maxWidth="lg">
                <div data-aos="fade-up" data-aos-delay="{1200}">
                  <header className="section-header">
                    <p style={{ color: "black" }}>Our Offering</p>
                  </header>
                  <p>&nbsp;</p>
                  <div className="row">
                    <div
                      className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="{1200}"
                    >
                      <div
                        className="service-box blue"
                        style={{
                          background: "#2db6fa",
                          height: "100%",
                          boxShadow: "1px 1px 5px grey inset",
                        }}
                      >
                        <h4 style={{ color: "#fff" }}>
                          Global Research and Analysis
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="{1200}"
                    >
                      <div
                        className="service-box orange"
                        style={{
                          background: "#ff9933",
                          height: "100%",
                          boxShadow: "1px 1px 5px grey inset",
                        }}
                      >
                        <h4 style={{ color: "#fff" }}>
                          In-Depth Financial Reports on Companies
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="{1000}"
                    >
                      <div
                        className="service-box purple"
                        style={{
                          background: "#b50edf",
                          height: "100%",
                          boxShadow: "1px 1px 5px grey inset",
                        }}
                      >
                        <h4 style={{ color: "#fff" }}>
                          Comparative Business Analysis on over 46,000 Companies
                          Worldwide
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay="{1500}"
                    >
                      <div
                        className="service-box green"
                        style={{
                          background: "#08da4e",
                          height: "100%",
                          boxShadow: "1px 1px 5px grey inset",
                        }}
                      >
                        <h4 style={{ color: "#fff" }}>
                          Over 800 Reports on Industries Worldwide
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </section> */}

              <section
                className="portfolio_section"
                style={{ background: "black" }}
              >
                <Container maxWidth="lg">
                  <div
                    class="portfolio_section"
                    data-aos="fade-up"
                    data-aos-delay="{1500}"
                  >
                    <h2>
                      <strong>Visual,</strong> <strong>Graphical</strong> and{" "}
                      <strong>Convenient </strong> Financial Reports <br></br>{" "}
                      over <strong> 46,000 Companies </strong>
                    </h2>
                  </div>
                  <div
                    className="naccs"
                    data-aos="fade-up"
                    data-aos-delay="{1800}"
                  >
                    <div className="grid">
                      <div className="gc gc--1-of-3">
                        <div className="menu">
                          <div className="active">
                            <span className="light" />
                            <span>Company Snapshot</span>
                          </div>
                          <div>
                            <span className="light" />
                            <span>Wright Quality Analysis</span>
                          </div>
                          <div>
                            <span className="light" />
                            <span>Comparative Business Analysis</span>
                          </div>
                          <div>
                            <span className="light" />
                            <span>Earnings & Dividends</span>
                          </div>
                          <div>
                            <span className="light" />
                            <span>Flow of Funds</span>
                          </div>
                          <div>
                            <span className="light" />
                            <span>Top 100 Companies</span>
                          </div>
                        </div>
                      </div>
                      <div className="gc gc--2-of-3">
                        <ul className="nacc" style={{ height: "437px" }}>
                          <li className="active">
                            <div>
                              <img
                                src="/home/assets/img/reportApple.PNG"
                                alt="alt text"
                              />
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src="/home/assets/img/reportWQA.PNG"
                                alt="alt text"
                              />
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src="/home/assets/img/cbaSnap.png"
                                alt="alt text"
                              />
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src="/home/assets/img/edSnap.png"
                                alt="alt text"
                              />
                            </div>
                          </li>
                          <li>
                            <div>
                              <img
                                src="/home/assets/img/fofSnap.png"
                                alt="alt text"
                              />
                            </div>
                          </li>
                          {/* <li>
                          <div>
                            <img
                              src="/home/assets/img/top100Snap.PNG"
                              alt="alt text"
                            />
                          </div>
                        </li> */}
                          <li>
                            <div>
                              <img
                                src="/home/assets/img/top100Snap.png"
                                alt="alt text"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="container">
                <div class="portfolio_section">
                  <h2>
                    <strong>Visual,</strong> <strong>Graphical</strong> and{" "}
                    <strong>Convenient </strong> Financial Reports <br></br>{" "}
                    over <strong> 46000 Companies </strong>
                  </h2>
                </div>
                <ul className="filter-menu">
                  <li data-target="all">All</li>
                  <li data-target="Wright Quality Analysis">
                    Wright Quality Analysis
                  </li>
                  <li data-target="Company Snapshot">Company Snapshot</li>
                  <li data-target="Comparative Business Analysis">
                    Comparative Business Analysis{" "}
                  </li>
                  <li data-target="Earnings & Dividends">
                    Earnings & Dividends
                  </li>
                  <li data-target="Sales">Sales</li>
                  <li data-target="Flow of Funds">Flow of Funds</li>
                  <li data-target="Top 100 Companies">Top 100 Companies</li>
                </ul>
                <ul className="filter-item">
                  <li data-item="Wright Quality Analysis">
                    <img src="/home/assets/img/reportWQA.PNG" alt="" />
                  </li>
                  <li data-item="Company Snapshot">
                    <img src="/home/assets/img/reportApple.PNG" alt="" />
                  </li>
                  <li data-item="Comparative Business Analysis ">
                    <img src="/home/assets/img/cba.png" alt="" />
                  </li>
                  <li data-item="Earnings & Dividends">
                    <img src="/home/assets/img/reportMicrosoft.PNG" alt="" />
                  </li>
                  <li data-item="Flow of Funds">
                    <img src="/home/assets/img/reportMicrosoft.PNG" alt="" />
                  </li>
                  <li data-item="Top 100 Companies">
                    <img src="/home/assets/img/reportTophundred.PNG" alt="" />
                  </li>
                </ul>
            </div>*/}
                </Container>
              </section>
              <section
                id="about"
                className="about"
                style={{ background: "darkslategrey", marginTop: "-1.25em" }}
              >
                <div className="container" data-aos="fade-up">
                  <div className="row gx-0">
                    <div
                      className="col-lg-12 d-flex flex-column justify-content-center"
                      data-aos="fade-up"
                      data-aos-delay={800}
                    >
                      <div
                        //className="content"
                        style={{
                          margin: "10px",
                          padding: "10px",
                          // background: "white",
                          borderRadius: "10px",
                          marginRight: "0px",
                        }}
                      >
                        <h3 style={{ color: "#ffff" }}>About US</h3>
                        <p>
                          The company research reports contained in CorporateInformation.com 
                          are produced on over 46,000 companies from 85 countries. 
                          Wright Investors’ Service has researched and analyzed company 
                          information since the inception of the firm in 1960. 
                        </p>
                        <p>
                          In the mid 1980’s, in support of its international investment 
                          initiatives, Wright Investors’ developed the Worldscope® Database, 
                          one of the financial industry’s premier sources of detailed financial 
                          statement data for public companies. This Database contained extensive 
                          data obtained from primary source documents, including corporate annual and interim reports.
                        </p>
                        <p>
                          Wright’s analysis tools, developed over several decades and using 
                          extensive corporate fundamental data, include the proprietary 
                          Wright Quality Rating® that is applied to each company contained 
                          in CorporateInformation. The CorporateInformation.com service is 
                          one of the most comprehensive sources for financial data on publicly 
                          traded companies worldwide.
                        </p>
                        <p>
                          To learn more about Wright Investors’ Service visit us
                          at &nbsp;
                          <a
                            href="https://www.wrightinvestorsservice.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>www.wrightinvestorsservice.com.</span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* ======= Services ======= */}
              <section id="services" className="services">
                <div className="container" data-aos="fade-up">
                  <header className="section-header">
                    <p style={{ color: "black" }}>Who We Are</p>
                  </header>
                  <div>
                    <p
                      style={{
                        lineHeight: "normal",
                        fontWeight: 300,
                        color: "black",
                      }}
                    >
                      <a
                        href="https://www.wrightinvestorsservice.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#0060a3" }}
                      >
                        Wright Investors' Service
                      </a>
                      &nbsp;is an internationally recognized investment
                      management and financial advisory firm headquartered in
                      Shelton, Connecticut, USA. For more than 50 years, Wright
                      has used well-defined and sophisticated investment
                      strategies to help institutions, plan sponsors, bank trust
                      departments, trust companies and individual investors
                      achieve their financial objectives through a sensible
                      approach to managing assets that balances risk and return.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                  <div className="row gy-4">
                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div
                        className="service-box blue"
                        style={{ height: "100%", background: "#2db6fa" }}
                      >
                        {/* <i class="ri-discuss-line icon"><img  src="/assets/img/pts/mobile.png"/></i> */}
                        <img
                          src="/home/assets/img/funds.png"
                          alt="funds"
                          style={{
                            height: "180px",
                            width: "180px",
                            paddingBottom: "1em",
                          }}
                        />
                        <h5 style={{ fontWeight: "bold" }}>
                          Our Process: The Difference is Discipline
                        </h5>
                        <p style={{ font: "menu" }}>
                          At the center of Wright's investment process is the
                          Wright Investment Committee. It consists of a select
                          group of senior investment professionals who are
                          supported by a highly experienced staff. This staff
                          provides multilevel analyses of the economic and
                          investment environments, including actual and
                          projected corporate earnings, interest rates, and the
                          effect of economic forecasts on market sectors,
                          individual securities and client portfolios. Only
                          those companies that meet the demanding standards of
                          Wright Quality Ratings® are selected for The Approved
                          Wright Investment List.
                        </p>
                        {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div
                        className="service-box orange"
                        style={{ height: "100%", background: "#f68c09" }}
                      >
                        <img
                          src="/home/assets/img/qualitative-research.png"
                          alt="qualitative-research"
                          style={{
                            height: "180px",
                            width: "180px",
                            paddingBottom: "1em",
                          }}
                        />
                        <h5 style={{ fontWeight: "bold" }}>
                          A Global Research and Analysis Capability
                        </h5>
                        <p style={{ font: "menu" }}>
                          To meet its equity research needs, Wright uses
                          Worldscope® which is recognized by the global
                          investment community as one of the world's most
                          comprehensive and accurate corporate financial
                          databases. Wright developed Worldscope® more than 30
                          years ago and continues to provide the database with
                          the editorial oversight that only a seasoned
                          investment firm with a global perspective can deliver.
                          The research reports delivered through
                          CorporateInformation.com rely heavily on the data
                          contained in the Worldscope Database. It is not only
                          Wright's clients, but also the financial community in
                          general that benefits from Wright's research,
                          investment advisory and analytical services. For over
                          five decades, Wright Investors' Service has managed an
                          in-house research capability that provides
                          institutional and private investors with the analyses
                          they need.
                        </p>
                        {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6"
                      data-aos="fade-up"
                      data-aos-delay={400}
                    >
                      <div
                        className="service-box green"
                        style={{ height: "100%", background: "#08da4e" }}
                      >
                        <img
                          src="/home/assets/img/report.png"
                          alt="report"
                          style={{
                            height: "180px",
                            width: "180px",
                            paddingBottom: "1em",
                          }}
                        />
                        <h5 style={{ fontWeight: "bold" }}>
                          High Level Service and Reporting
                        </h5>
                        <p style={{ font: "menu" }}>
                          Wright is committed to a disciplined approach to
                          investing that is backed by a high level of client
                          service and reporting. Each client is assigned one or
                          more Client Service Officers whose mission is to act
                          as the client's representative within Wright. Client
                          Service Officers are seasoned professionals with
                          extensive experience handling the investment and
                          service needs of the clients to whom they are
                          assigned. In addition, Wright's sophisticated
                          reporting system delivers customized reports to meet
                          specific client needs.
                        </p>
                        {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* TESTIMONIALS */}

              {/* <div className="container"> */}

              <section id="testim" className="testim">
                <header class="section-header">
                  <p style={{ color: "black" }}>Testimonials</p>
                </header>
                <div className="testim-cover">
                  <div className="wrap">
                    <span
                      id="right-arrow"
                      className="arrow right fa fa-chevron-right"
                    />
                    <span
                      id="left-arrow"
                      className="arrow left fa fa-chevron-left "
                    />
                    <ul id="testim-dots" className="dots">
                      <li className="dot active" />
                      {/*
                       */}
                      <li className="dot" />
                      {/*
                       */}
                      <li className="dot" />
                      {/*
                       */}
                      <li className="dot" />
                      {/*
                       */}
                      <li className="dot" />
                    </ul>
                    <div id="testim-content" className="cont">
                      <div className="active">
                        <div className="img">
                          <img src="/home/assets/img/testimonial5.jpg" alt="" />
                        </div>
                        <h2>Jacob</h2>
                        <p>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial6.jpg" alt="" />
                        </div>
                        <h2>Stephen</h2>
                        <p>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial7.jpg" alt="" />
                        </div>
                        <h2>Kethi</h2>
                        <p>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial3.png" alt="" />
                        </div>
                        <h2>Lorem De Ipsum</h2>
                        <p>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                      <div>
                        <div className="img">
                          <img src="/home/assets/img/testimonial4.png" alt="" />
                        </div>
                        <h2>Ms. Lorem R. Ipsum</h2>
                        <p>
                          This website offers lots of financial information
                          about companies. It provides graphical representation
                          which is easier to understand.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* </div> */}
            </main>
            <footer id="footer" className="footer ">
              <div className="footer-top footerImage footerFont">
                {/* <div className="container"> */}
                <Container maxWidth="lg">
                  <div className="row gy-4">
                    <div className="col-lg-4 col-md-12 footer-info">
                      {/* <a
                      href="index.html"
                      className="logo d-flex align-items-center"
                    >
                      <img src="assets/img/logo5.png" alt="" />
                    </a> */}
                      <a href="/" class="logo d-flex align-items-center">
                        <h4 class="footerh4">
                          <span>Corporate</span>Information
                          <span>
                            <sup>®</sup>
                          </span>
                        </h4>
                      </a>
                      <p>
                        We are one of the world’s leading sites for value-added
                        corporate and related industry information appropriate
                        for investing, competitive analysis and research
                        information on over 46,000 of the leading public
                        companies in over 86 countries.
                      </p>
                      <p>
                        Wright Investors’ Service has researched and analyzed
                        company and industry information since the inception of
                        the firm in 1960. Wright's proprietary analysis tools,
                        developed over several decades, include a Quality Rating
                        system that is applied (when there is sufficient
                        information available) to each company contained in
                        CorporateInformation.
                      </p>
                      <div className="social-links mt-3">
                        <a
                          href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fwww.corporateinformation.com"
                          target="_blank"
                          rel="noreferrer"
                          className="twitter"
                        >
                          <TwitterIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                        <a
                          href="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dwww.corporateinformation.com&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB"
                          target="_blank"
                          className="facebook"
                          rel="noreferrer"
                        >
                          <FacebookIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                        {/* <a
                          href="https://plus.google.com/up/?continue=https://plus.google.com/share?url%3Dwww.corporateinformation.com"
                          target="_blank"
                          className="instagram"
                        >
                          <img
                            src="/home/assets/img/google_link.png"
                            style={{ height: "1.2em", cursor: "pointer" }}
                          ></img>
                        </a> */}
                        <a
                          href="https://www.linkedin.com/sharing/share-offsite/?url=http%3A%2F%2Fwww.corporateinformation.com"
                          target="_blank"
                          className="linkedin"
                          rel="noreferrer"
                        >
                          <LinkedInIcon
                            style={{ color: "#fff", cursor: "pointer" }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6 ptsfooter-links">
                      <h4>Useful Links</h4>
                      <ul>
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/feedback"
                            className="nav-link scrollto active"
                          >
                            Feedback
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/tandc"
                            className="nav-link scrollto active"
                          >
                            Terms and Conditions
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />{" "}
                          <NavLink
                            to="/privacypolicy"
                            className="nav-link scrollto active"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                        <li>
                          <ChevronRightIcon />
                          <NavLink
                            to="/financialterms"
                            className="nav-link scrollto active"
                          >
                            Definitions of Financial Terms
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-12 footer-contact text-center text-md-start">
                      <h4>Contact Us</h4>
                      <p>
                        CorporateInformation.com Customer Support
                        <br />
                        Wright Investors' Service Inc
                        <br />
                        2 Corporate Drive Suite 770
                        <br />
                        Shelton, CT 06484
                        <br />
                        USA
                        {/* <br />
                        <strong>Telephone:</strong> +1-203-783-4343
                        <br />
                        <strong>Fax:</strong> +1-203-783-4401 */}
                        <br />
                      </p>
                    </div>
                  </div>
                  {/* </div> */}
                </Container>
              </div>
              {/* <div className="container"> */}
              <div className="fooCopyR">
                <div className="copyright">
                  {/* &copy; Copyright <strong><span>FlexStart</span></strong
          >. */}
                  <div className="copyrightContent">
                    CorporateInformation® website and selected data Copyright ©
                    2000 - 2021 by The Winthrop Corporation. .
                  </div>
                  <div className="copyrightContent">
                    {" "}
                    Distributed by Wright Investors' Service, Inc. All Rights
                    Reserved. Selected data © 2021 "Worldscope/Disclosure,
                    L.L.C., a Primark affiliate". All Rights Reserved.
                  </div>
                </div>
                <span style={{ fontSize: "0.8em" }}>
                  THE ANALYSES AND DATA ARE PROVIDED "AS IS" WITHOUT WARRANTY OF
                  ANY KIND EXPRESS OR IMPLIED. THE WINTHROP CORPORATION, WRIGHT
                  INVESTORS' SERVICE, INC. AND OTHER DATA PROVIDERS SPECIFICALLY
                  EXCLUDE THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                  PARTICULAR PURPOSE AND DO NOT WARRANT, GUARANTEE OR MAKE ANY
                  REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF THE USE,
                  OF THE DATA IN TERMS OF ITS ACCURACY, RELIABILITY OR
                  CURRENTNESS. THE WINTHROP CORPORATION, WRIGHT INVESTORS'
                  SERVICE, INC. AND OTHER DATA PROVIDERS SHALL NOT BE LIABLE FOR
                  ANY DIRECT, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES
                  (INCLUDING LOST PROFITS) ARISING OUT OF THE SUBSCRIBER'S USE
                  OF OR INABILITY TO USE THE ANALYSES AND DATA. THE USER ASSUMES
                  THE ENTIRE RISK AS TO THE RESULTS AND PERFORMANCE OF THE
                  ANALYSES AND DATA, WHICH ARE PROVIDED FOR INTERNAL USE ONLY.
                  NO REDISTRIBUTION OF THE ANALYSES AND DATA OR ANY PART THEREOF
                  IS PERMITTED. WRIGHT QUALITY RATINGS ARE NOT MEANT TO
                  CONSTITUTE INVESTMENT ADVICE OR AN ENDORSEMENT OF ANY ISSUER.
                </span>
                <div className="credits">
                  {/* All the links in the footer should remain intact. */}
                  {/* You can delete the links only if you purchased the pro version. */}
                  {/* Licensing information: https://bootstrapmade.com/license/ */}
                  {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ */}
                  {/* Designed by */}
                </div>
              </div>
              {/* </div> */}
            </footer>
            <a
              href="#"
              class="back-to-top d-flex align-items-center justify-content-center"
            >
              <i class="bi bi-arrow-up-short"></i>
            </a>
          </>
        </div>
      </>
    );
  }
}
