import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import BankIncomeStatement from "./BankIncomeStatement";
import "./CompanyFinancial.css";
import FinancialIncomeStatement from "./FinancialIncomeStatement";
import IncomeStatement from "./IncomeStatement";
import InsuranceIncomeStatement from "./InsuranceIncomeStatement";

export default class CompanyFinancial extends Component {
  constructor(props) {
    super(props);
    let type = props.type;

    if (
      type === "Income Statement Common Size" ||
      type === "Income Statement Year-Year" ||
      type === "Income Statement Three Year Average" ||
      type === "Income Statement Actual Pdf"
    ) {
      this.state = {
        type: type,
        actualData: [],
        cusip: props.data,
        isPdfReport: props.isPdfReport,
        render: false,
        indType: "",
        unit: "",
        currency: "",
      };
    } else {
      this.state = {
        type: type,
        actualData: props.data,
        render: true,
        indType: props.data.ind_TYPE,
        unit: "",
        currency: "",
      };
    }
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  getFinancialStatementReportData() {
    if (this.state.type === "Income Statement Common Size") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/incomeStmtPercentChange",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let actualData = data;
            let indType = data.ind_TYPE;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              actualData: actualData,
              indType: indType,
              render: true,
            });
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Income Statement Year-Year") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/incomeStmtYoYChange",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let actualData = data;
            let indType = data.ind_TYPE;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              actualData: actualData,
              indType: indType,
              render: true,
            });
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Income Statement Three Year Average") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/incomeStmt3YearAverage",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let actualData = data;
            let indType = data.ind_TYPE;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              actualData: actualData,
              indType: indType,
              render: true,
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService("financial/incomeStmtActual", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let actualData = data;
            let indType = data.ind_TYPE;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              actualData: actualData,
              indType: indType,
              render: true,
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  }
  componentDidMount() {
    if (this.state.type !== "Income Statement Actual") {
      this.getFinancialStatementReportData();
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <BankIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></BankIncomeStatement>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InsuranceIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InsuranceIncomeStatement>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <FinancialIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></FinancialIncomeStatement>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></IncomeStatement>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
