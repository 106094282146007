import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import "../corporateInformationHome/assets/css/style.css";
import "../corporateInformationHome/assets/vendor/aos/aos.css";
import "../corporateInformationHome/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../corporateInformationHome/assets/vendor/bootstrap/css/bootstrap.css";
import "../corporateInformationHome/assets/vendor/glightbox/css/glightbox.min.css";
import "../corporateInformationHome/assets/vendor/remixicon/remixicon.css";
import "../corporateInformationHome/assets/vendor/swiper/swiper-bundle.min.css";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let previousYear = currentDate.getFullYear() - 1;
    this.state = {
      isOpen: false,
      currentYear: currentYear,
      previousYear: previousYear,
    };
  }

  render() {
    if (
      window.location.pathname === "/ci_home" ||
      window.location.pathname === "corporateInformation"
    )
      return null;
    return (
      <footer
        id="footer"
        className="footer"
        style={{ background: "#444141", color: "#fff", textAlign: "center" }}
      >
        <Container maxWidth="lg">
          <div className="copyright">
            <div className="copyrightContent">
              CorporateInformation® website and selected data Copyright © 2000-
              {this.state.currentYear} by The Winthrop Corporation.
            </div>
            <div className="copyrightContent">
              {" "}
              Distributed by Wright Investors' Service, Inc. All Rights
              Reserved.
            </div>
          </div>
          <span style={{ fontSize: "0.8em" }}>
            Except for quotations by established news media, no pages on this
            site may be reproduced, stored in a retrieval system, or transmitted
            for commercial purposes, in any form or by any means, electronic,
            mechanical, photocopying, recording, or otherwise without prior
            written permission. Information is believed reliable, but accuracy,
            completeness and opinions are not guaranteed.
          </span>
          <div className="credits"></div>
        </Container>
      </footer>
    );
  }
}
