import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import "./InterimInsuranceBalanceSheet.css";
export default class InterimInsuranceBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      balanceSheetData: [],
      render: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }

  componentDidMount() {
    if (this.state.type === "Interim Balance Sheet Actual") {
      this.data = [];
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      let balanceSheetData = this.state.data.insInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);

        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].bonds = this.convertToMillionaire(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].totalInvestments = this.convertToMillionaire(
          balanceSheetData[i].totalInvestments
        );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );

        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].totalFixedIncomeSecuritiesInvestment =
          this.convertToMillionaire(
            balanceSheetData[i].totalFixedIncomeSecuritiesInvestment
          );
        balanceSheetData[i].totalEquitySecuritiesInvestment =
          this.convertToMillionaire(
            balanceSheetData[i].totalEquitySecuritiesInvestment
          );
        balanceSheetData[i].netPropertyPlantandEquipment =
          this.convertToMillionaire(
            balanceSheetData[i].netPropertyPlantandEquipment
          );
        balanceSheetData[i].separateAndVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateAndVariableAccountAssets
          );
        balanceSheetData[i].totalInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].totalInsuranceReserves
        );
        balanceSheetData[i].benefitAndLossReserves = this.convertToMillionaire(
          balanceSheetData[i].benefitAndLossReserves
        );
        balanceSheetData[i].policyAndContractClaims = this.convertToMillionaire(
          balanceSheetData[i].policyAndContractClaims
        );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        series: [
          {
            name: "Long Term Debit",
            type: "bar",
            data: longTermDebt,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "Shortterm and longterm debit",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Interim Balance Sheet Common Size") {
      let balanceSheetData = this.state.data.insInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);

        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].bonds = this.convertToMillionaire(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].totalInvestments = this.convertToMillionaire(
          balanceSheetData[i].totalInvestments
        );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );

        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].totalFixedIncomeSecuritiesInvestment =
          this.convertToMillionaire(
            balanceSheetData[i].totalFixedIncomeSecuritiesInvestment
          );
        balanceSheetData[i].totalEquitySecuritiesInvestment =
          this.convertToMillionaire(
            balanceSheetData[i].totalEquitySecuritiesInvestment
          );
        balanceSheetData[i].netPropertyPlantandEquipment =
          this.convertToMillionaire(
            balanceSheetData[i].netPropertyPlantandEquipment
          );
        balanceSheetData[i].separateAndVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateAndVariableAccountAssets
          );
        balanceSheetData[i].totalInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].totalInsuranceReserves
        );
        balanceSheetData[i].benefitAndLossReserves = this.convertToMillionaire(
          balanceSheetData[i].benefitAndLossReserves
        );
        balanceSheetData[i].policyAndContractClaims = this.convertToMillionaire(
          balanceSheetData[i].policyAndContractClaims
        );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        series: [
          {
            name: "Long Term Debit",
            type: "bar",
            data: longTermDebt,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "Shortterm and longterm debit",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }

  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Cash</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.cash}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInvestments}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Total Fixed Income Securities Investment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.totalFixedIncomeSecuritiesInvestment}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Bonds</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.bonds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Redeemable Preferred Stock{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.redeemablePreferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Total Equity Securities Investment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalEquitySecuritiesInvestment}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Stocks </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonStocks}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Non-Redeemable Preferred Stock{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonRedeemablePreferredStock}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage, Policy & Other Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgagePolicyAndOtherLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Investments in Associated Companies
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Premium Balance Receivables
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.premiumBalanceReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Net Property, Plant and Equipment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.netPropertyPlantandEquipment}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Separate & Variable Account Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.separateAndVariableAccountAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders'Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Insurance Reserves
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInsuranceReserves}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>Benefit & Loss Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.benefitAndLossReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unearned Premiums</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedPremiums}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Policy & Contract Claims</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyAndContractClaims}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Insurance Reserves </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInsuranceReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.totalDebt}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short-Term Debt & Current Portion of Long-Term Debt{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Long-Term Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.deferredTaxes}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities And Shareholders Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesAndShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Cash</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.cash}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInvestments}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Total Fixed Income Securities Investment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.totalFixedIncomeSecuritiesInvestment}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Bonds</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.bonds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Redeemable Preferred Stock{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.redeemablePreferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Total Equity Securities Investment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalEquitySecuritiesInvestment}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Stocks </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonStocks}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Non-Redeemable Preferred Stock{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonRedeemablePreferredStock}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage, Policy & Other Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgagePolicyAndOtherLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Investments in Associated Companies
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Premium Balance Receivables
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.premiumBalanceReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Net Property, Plant and Equipment
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.netPropertyPlantandEquipment}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}> Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Separate & Variable Account Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.separateAndVariableAccountAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders'Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Insurance Reserves
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInsuranceReserves}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>Benefit & Loss Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.benefitAndLossReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Unearned Premiums</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedPremiums}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Policy & Contract Claims</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyAndContractClaims}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Insurance Reserves </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInsuranceReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.totalDebt}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short-Term Debt & Current Portion of Long-Term Debt{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      <span className=""> Long-Term Debt</span>

                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm()}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Risks and Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.deferredTaxes}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities And Shareholders Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesAndShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Interim Balance Sheet({" "}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <ReactApexCharts
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={350}
                      width={450}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
