import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./InsuranceIncomeStatement.css";
export default class InsuranceIncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      render: false,
      modalHeader: "",
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.actualData = [];
      let netSalesOrRevenues = [];
      let premiumsEarned = [];
      let investmentIncome = [];
      let claimLossExpenseTotal = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let LabelList = [];
      let actualData = this.state.data.insIncomeStmtList;

      for (let i = 0; i < actualData.length; i++) {
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        premiumsEarned.push(actualData[i].premiumsEarned);
        investmentIncome.push(actualData[i].investmentIncome);
        claimLossExpenseTotal.push(actualData[i].claimLossExpenseTotal);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        LabelList.push(actualData[i].fiscalYear);
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].premiumsEarned = this.convertToMillionaire(
          actualData[i].premiumsEarned
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].claimLossExpenseTotal = this.convertToMillionaire(
          actualData[i].claimLossExpenseTotal
        );
        actualData[i].longTermInsuranceReserves = this.convertToMillionaire(
          actualData[i].longTermInsuranceReserves
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].realizedInvestmentGainLoss = this.convertToMillionaire(
          actualData[i].realizedInvestmentGainLoss
        );
        actualData[i].policyholdersSurplus = this.convertToMillionaire(
          actualData[i].policyholdersSurplus
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        netSalesOrRevenues: netSalesOrRevenues,
        premiumsEarned: premiumsEarned,
        investmentIncome: investmentIncome,
        claimLossExpenseTotal: claimLossExpenseTotal,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      this.actualData = [];
      this.actualData = [];
      let netSalesOrRevenues = [];
      let premiumsEarned = [];
      let investmentIncome = [];
      let claimLossExpenseTotal = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let LabelList = [];
      let actualData = this.state.data.insIncomeStmtPercentChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].premiumsEarned);
        LabelList.push(actualData[i].investmentIncome);
        LabelList.push(actualData[i].claimLossExpenseTotal);
        LabelList.push(actualData[i].operatingExpensesTotal);
        LabelList.push(actualData[i].operatingIncome);
        LabelList.push(actualData[i].pretaxIncome);
        LabelList.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        LabelList.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        LabelList.push(actualData[i].fiscalYear);
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].premiumsEarned = this.convertToMillionaire1(
          actualData[i].premiumsEarned
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire1(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].claimLossExpenseTotal = this.convertToMillionaire1(
          actualData[i].claimLossExpenseTotal
        );
        actualData[i].longTermInsuranceReserves = this.convertToMillionaire1(
          actualData[i].longTermInsuranceReserves
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].realizedInvestmentGainLoss = this.convertToMillionaire1(
          actualData[i].realizedInvestmentGainLoss
        );
        actualData[i].policyholdersSurplus = this.convertToMillionaire1(
          actualData[i].policyholdersSurplus
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Common Size",
        actualData: actualData,
        netSalesOrRevenues: netSalesOrRevenues,
        premiumsEarned: premiumsEarned,
        investmentIncome: investmentIncome,
        claimLossExpenseTotal: claimLossExpenseTotal,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      this.actualData = [];
      this.actualData = [];
      let netSalesOrRevenues = [];
      let premiumsEarned = [];
      let investmentIncome = [];
      let claimLossExpenseTotal = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let LabelList = [];
      let actualData = this.state.data.insIncomeStmtYoYChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].premiumsEarned);
        LabelList.push(actualData[i].investmentIncome);
        LabelList.push(actualData[i].claimLossExpenseTotal);
        LabelList.push(actualData[i].operatingExpensesTotal);
        LabelList.push(actualData[i].operatingIncome);
        LabelList.push(actualData[i].pretaxIncome);
        LabelList.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        LabelList.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        LabelList.push(actualData[i].fiscalYear);
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].premiumsEarned = this.convertToMillionaire1(
          actualData[i].premiumsEarned
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire1(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].claimLossExpenseTotal = this.convertToMillionaire1(
          actualData[i].claimLossExpenseTotal
        );
        actualData[i].longTermInsuranceReserves = this.convertToMillionaire1(
          actualData[i].longTermInsuranceReserves
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].realizedInvestmentGainLoss = this.convertToMillionaire1(
          actualData[i].realizedInvestmentGainLoss
        );
        actualData[i].policyholdersSurplus = this.convertToMillionaire1(
          actualData[i].policyholdersSurplus
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Yr-yr % change",
        actualData: actualData,
        netSalesOrRevenues: netSalesOrRevenues,
        premiumsEarned: premiumsEarned,
        investmentIncome: investmentIncome,
        claimLossExpenseTotal: claimLossExpenseTotal,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      this.actualData = [];
      this.actualData = [];
      let netSalesOrRevenues = [];
      let premiumsEarned = [];
      let investmentIncome = [];
      let claimLossExpenseTotal = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let LabelList = [];
      let actualData = this.state.data.insIncomeStmt3YearAverageList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].premiumsEarned);
        LabelList.push(actualData[i].investmentIncome);
        LabelList.push(actualData[i].claimLossExpenseTotal);
        LabelList.push(actualData[i].operatingExpensesTotal);
        LabelList.push(actualData[i].operatingIncome);
        LabelList.push(actualData[i].pretaxIncome);
        LabelList.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        LabelList.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        LabelList.push(actualData[i].fiscalYear);
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].premiumsEarned = this.convertToMillionaire(
          actualData[i].premiumsEarned
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].claimLossExpenseTotal = this.convertToMillionaire(
          actualData[i].claimLossExpenseTotal
        );
        actualData[i].longTermInsuranceReserves = this.convertToMillionaire(
          actualData[i].longTermInsuranceReserves
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].realizedInvestmentGainLoss = this.convertToMillionaire(
          actualData[i].realizedInvestmentGainLoss
        );
        actualData[i].policyholdersSurplus = this.convertToMillionaire(
          actualData[i].policyholdersSurplus
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: "Income Statement -3 yr Averages",
        actualData: actualData,
        netSalesOrRevenues: netSalesOrRevenues,
        premiumsEarned: premiumsEarned,
        investmentIncome: investmentIncome,
        claimLossExpenseTotal: claimLossExpenseTotal,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
        LabelList: LabelList,
      });
    } else {
      this.actualData = [];
      let netSalesOrRevenues = [];
      let premiumsEarned = [];
      let investmentIncome = [];
      let claimLossExpenseTotal = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let LabelList = [];
      let actualData = this.state.data.insIncomeStmtList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        premiumsEarned.push(actualData[i].premiumsEarned);
        investmentIncome.push(actualData[i].investmentIncome);
        claimLossExpenseTotal.push(actualData[i].claimLossExpenseTotal);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        LabelList.push(actualData[i].fiscalYear);
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].premiumsEarned = this.convertToMillionaire(
          actualData[i].premiumsEarned
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].claimLossExpenseTotal = this.convertToMillionaire(
          actualData[i].claimLossExpenseTotal
        );
        actualData[i].longTermInsuranceReserves = this.convertToMillionaire(
          actualData[i].longTermInsuranceReserves
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].realizedInvestmentGainLoss = this.convertToMillionaire(
          actualData[i].realizedInvestmentGainLoss
        );
        actualData[i].policyholdersSurplus = this.convertToMillionaire(
          actualData[i].policyholdersSurplus
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        netSalesOrRevenues: netSalesOrRevenues,
        premiumsEarned: premiumsEarned,
        investmentIncome: investmentIncome,
        claimLossExpenseTotal: claimLossExpenseTotal,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
        LabelList: LabelList,
      });
    }
  }

  getDataInGraphicalForm(graphType) {
    // if (tab === "Interim Income Statement Actual") {
    if (graphType === "Net Sales or Revenues") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netSalesOrRevenues,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales or Revenues ",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Premiums Earned") {
      this.setState({
        graphDetails: {
          graphValues: this.state.premiumsEarned,
          labelData: this.state.LabelList,
          yAxisTitle: "Premiums Earned",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investment Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Claim & Loss Expense - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.claimLossExpenseTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Claim & Loss Expense - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Expenses - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingExpensesTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Expenses - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Income before Extraordinary Items/Preferred Dividends"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomebeforeExtraordinaryItemsPreferredDividends,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income before Extraordinary Items/Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Income after Preferred Dividends - available to Common"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomeafterPreferredDividendsavailabletoCommon,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income after Preferred Dividends - available to Common",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-2em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <span className="unit_str">
                All figures in <span>{this.toTitleCase(this.state.unit)}</span>{" "}
                of {this.state.currency}
              </span>
              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Net Sales or Revenues</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netSalesorRevenues}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th>Premiums Earned</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.premiumsEarned}</td>;
                  })}
                </tr>
                <tr>
                  <th>Investment Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.investmentIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Gains/Losses on Sale of Securities - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.gainsLossesonSaleofSecuritiesPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Claim & Loss Expense - Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.claimLossExpenseTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Long Term Insurance Reserves</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.longTermInsuranceReserves}</td>;
                  })}
                </tr>
                <tr>
                  <th>Selling, General & Administrative Expenses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.sellingGeneralAdministrativeExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Expenses - Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingExpensesTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Debt</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseonDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Capitalized</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestCapitalized}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Reserves - Increase/Decrease</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Income Taxes</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>Realized Investment Gain/Loss</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.realizedInvestmentGainLoss}</td>;
                  })}
                </tr>
                <tr>
                  <th>Policyholders' Surplus</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.policyholdersSurplus}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income before Extraordinary Items/Preferred Dividends
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {
                          data.netIncomebeforeExtraordinaryItemsPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income after Preferred Dividends - available to Common
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {data.netIncomeafterPreferredDividendsavailabletoCommon}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                <span className="unit_str">
                  All figures in{" "}
                  <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                  {this.state.currency}
                </span>
                <Table responsive className="financial_analysis1">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Sales or Revenues
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales or Revenues")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSalesorRevenues}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>
                      Premiums Earned
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Premiums Earned")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.premiumsEarned}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investment Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investment Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.investmentIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Gains/Losses on Sale of Securities - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.gainsLossesonSaleofSecuritiesPretax}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Operating Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Claim & Loss Expense - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Claim & Loss Expense - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.claimLossExpenseTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Insurance Reserves</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.longTermInsuranceReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Selling, General & Administrative Expenses</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.sellingGeneralAdministrativeExpenses}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Operating Expenses</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Operating Expenses - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Operating Expenses - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingExpensesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Debt</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseonDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Capitalized</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestCapitalized}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Operating Interest Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.nonOperatingInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Credit - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryCreditPretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Charge - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryChargePretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Income/Expense - Net</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherIncomeExpenseNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Pretax Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxEquityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves - Increase/Decrease</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.reservesIncreaseDecrease}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Pretax Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pretax Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.incomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.equityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>After Tax Other Income/Expense </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.afterTaxOtherIncomeExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Discontinued Operations</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.discontinuedOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Realized Investment Gain/Loss</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.realizedInvestmentGainLoss}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Policyholders' Surplus</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.policyholdersSurplus}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income before Extraordinary Items/Preferred Dividends
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income before Extraordinary Items/Preferred Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomebeforeExtraordinaryItemsPreferredDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividend Requirements</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.preferredDividendRequirements}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income after Preferred Dividends - available to Common
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income after Preferred Dividends - available to Common"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomeafterPreferredDividendsavailabletoCommon
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
