import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Advertisement from "../ciadvertisement/Advertisement";
import "./SiteMap.css";
export default class SiteMap extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Row style={{ marginTop: "5em" }}>
          <Col md={2} style={{ marginTop: "1em" }}></Col>
          <Col md={8} style={{ marginTop: "2em" }} className="pageHeight ">
            <Container maxWidth="xl">
              <Row
                style={{ margin: "0em -1.6em" }}
                className=" pts_list_content"
              >
                <ul>
                  <li>
                    <NavLink to="/" className="nav-link scrollto">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/currency" className="nav-link scrollto">
                      Currency Exchange Rates
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tophundred" className="nav-link scrollto">
                      Top 100 Lists
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/companyreport" className="nav-link scrollto">
                      Company Reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/industryreport" className="nav-link scrollto">
                      Industry Averages Reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/plans" className="nav-link scrollto">
                      Plans
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/definitions" className="nav-link scrollto">
                      Definitions &amp; Extensions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/financialterms" className="nav-link scrollto">
                      Definitions of Financial Terms
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/feedback" className="nav-link scrollto">
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/cart" className="nav-link scrollto">
                      Cart
                    </NavLink>
                  </li>
                </ul>
              </Row>
            </Container>
          </Col>
          <Col md={2} style={{ marginTop: "1em" }}>
            <Advertisement advertisementType="verticalAddUnit" />
          </Col>
        </Row>
      </>
    );
  }
}
