import Cart from "../cart/Cart";
import CompanyQualityRating from "../companyQualityRating/CompanyQualityRating";
import CompanyReport from "../companyReport/CompanyReport";
import CompanyCorporateInfo from "../companySnapshot/CompanyCorporateInfo";
import CbaSampleReport from "../comparativeBusinessAnalysis/CbaSampleReport";
import CorporateInformationHome from "../corporateInformationHome/CorporateInformationHome";
import CorporateInformationHomeTemplate from "../corporateInformationHome/CorporateInformationHomeTemplate";
import WrightQualityRatingInfo from "../corporateInformationHome/WrightQualityRatingInfo";
import CurrencyExchangeRate from "../currencyExchangeRate/CurrencyExchangeRate";
import DefinitionExtension from "../definitionAndExtensions/DefinitionExtension";
import DefinitionsOfFinancialTerms from "../definitionsOfFinancialTerms/DefinitionsOfFinancialTerms";
import DownloadMobileApp from "../downloadMobileApp/DownloadMobileApp";
import Feedback from "../feedback/Feedback";
import IndustryAverageReport from "../industryAverageReport/IndustryAverageReport";
import Login from "../loginComponent/Login";
import Registration from "../loginComponent/Registration";
import Payment from "../payment/Payment";
import Cancel from "../paymentGateway/Canceled";
import Checkout from "../paymentGateway/Checkout";
import Checkout1 from "../paymentGateway/Checkout1";
import IndividualReportCheckout from "../paymentGateway/IndividualReportCheckout";
import Success from "../paymentGateway/Success";
import Plans from "../plans/Plans";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import ReportDetails from "../reportDetails/ReportDetails";
import ReportSell from "../reportSell/ReportSell";
import ResetPassword from "../resetPassword/ResetPassword";
import CompanySearch from "../search/CompanySearch";
import SiteMap from "../siteMap/SiteMap";
import TermsAndCondition from "../termsAndCondition/TermsAndCondition";
import TopHundredFilter from "../topHundredFilter/TopHundredFilter";
import TopHundredFilterMethodology from "../topHundredFilter/TopHundredFilterMethodology";
import TopHundredList from "../topHundredList/TopHundredList";
import DefaultCard from "../userProfile/DefaultCard";
import UserProfile from "../userProfile/UserProfile";

const pageList = [
  {
    name: "Home",
    path: "/home",
    component: CorporateInformationHome,
  },
  {
    name: "CorporateInformationHome",
    path: "/ci_home",
    component: CorporateInformationHome,
  },
  {
    name: "CorporateInformationHome",
    path: "/ci",
    component: CorporateInformationHomeTemplate,
  },
  {
    name: "Basic Search",
    path: "/search",
    component: CompanySearch,
  },
  {
    name: "Company Extensions and Security Identifiers",
    path: "/definitions",
    component: DefinitionExtension,
  },

  {
    name: "Feedback",
    path: "/feedback",
    component: Feedback,
  },
  {
    name: "Terms And Conditions",
    path: "/tandc",
    component: TermsAndCondition,
  },
  {
    name: "Privacy Policy",
    path: "/privacypolicy",
    component: PrivacyPolicy,
  },
  {
    name: "Top 100 Lists",
    path: "/tophundred",
    component: TopHundredList,
  },
  {
    name: "Currency Rates (Updated Daily)",
    path: "/currency",
    component: CurrencyExchangeRate,
  },
  {
    name: "Company Reports",
    path: "/companyreport",
    component: CompanyReport,
  },
  {
    name: "Industry Averages Reports",
    path: "/industryreport",
    component: IndustryAverageReport,
  },
  {
    name: "Reports Details",
    path: "/indreportdetail",
    component: ReportDetails,
  },
  {
    name: "Report Details",
    path: "/company",
    component: CompanyCorporateInfo,
  },
  {
    name: "Report Details",
    path: "/companysnapdetails",
    component: CompanyCorporateInfo,
  },
  {
    name: "Report Details",
    path: "/companysnapreport",
    component: CompanyCorporateInfo,
  },
  // {
  //   name: "Top Hundred Advance List",
  //   path: "/top_hundred_advance",
  //   component: TopHundredItem,
  // },
  {
    name: "Top Hundred Advance List",
    path: "/login",
    component: Login,
  },
  {
    name: "ResetPassword",
    path: "/reset_password",
    component: ResetPassword,
  },
  {
    name: "CBA Sample Report",
    path: "/cbasample",
    component: CbaSampleReport,
  },
  {
    name: "Wright Quality Analysis",
    path: "/Wrightqualityanalysis",
    component: WrightQualityRatingInfo,
  },
  {
    name: "SignUp",
    path: "/signup",
    component: Registration,
  },
  {
    name: "Plans",
    path: "/plans",
    component: Plans,
  },
  {
    name: "Payment",
    path: "/payment",
    component: Payment,
  },
  {
    name: "Reset Password",
    path: "/reset_Pass",
    component: ResetPassword,
  },
  {
    name: "Company Quality Rating",
    path: "/company_rating",
    component: CompanyQualityRating,
  },
  {
    name: "Report Sell",
    path: "/report_sell",
    component: ReportSell,
  },
  {
    name: "Shopping Cart",
    path: "/cart",
    component: Cart,
  },
  {
    name: "Advanced Screening",
    path: "/advancescreening",
    component: TopHundredFilter,
  },
  {
    name: "Checkout",
    path: "/Checkout",
    component: Checkout,
  },
  {
    name: "Checkout",
    path: "/Checkout1",
    component: Checkout1,
  },
  {
    name: "Cancel",
    path: "/canceled",
    component: Cancel,
  },
  {
    name: "Success",
    path: "/success",
    component: Success,
  },
  {
    name: "Checkout",
    path: "/reportcheckout",
    component: IndividualReportCheckout,
  },
  {
    name: "User Profile",
    path: "/user",
    component: UserProfile,
  },
  {
    name: "Rank Methodology",
    path: "/topHundredFilterMethodology",
    component: TopHundredFilterMethodology,
  },

  {
    name: "Definitions Of Financial Terms",
    path: "/financialterms",
    component: DefinitionsOfFinancialTerms,
  },
  {
    name: "Download our App",
    path: "/download_mobile_app",
    component: DownloadMobileApp,
  },
  {
    name: "Site Map",
    path: "/sitemap",
    component: SiteMap,
  },
  {
    name: "Card Payment",
    path: "/payment_method",
    component: DefaultCard,
  },
];

export default pageList;
