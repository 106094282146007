import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import historyAction from "../../redux/history/historyAction";
import store from "../../redux/store";
import Advertisement from "../ciadvertisement/Advertisement";
import Login from "../loginComponent/Login";
import SearchWithResult from "../search/SearchWithResult";
import "./Plans.css";

export default class Plans extends Component {
  userData = store.getState().userData.userData;
  loginRefList = [];
  constructor(props) {
    window.scrollTo(0, 0);

    super(props);
    let isUserLogin = false;
    let user = "";
    store.dispatch(historyAction.setHistory(this.props.history));
    if (this.userData) {
      isUserLogin = true;
      user = this.userData.firstName + " " + this.userData.lastName;
    }
    this.state = {
      isOpen: false,
      showModal: false,
      selectedPlan: "",
      modalShow: false,
      isUserLogin: isUserLogin,
      userData: user,
      planList: [],
      features: [],
      anchorEl: undefined,
      open: false,
      render: false,
    };
    this.loginRefList = React.createRef();
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };
  componentDidMount() {
    const postObject = {};
    FetchServerData.callPostService("planms/getAllSubPlans", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let plan = [];

          let obj = {};
          for (let i = 0; i < output.data.length; i++) {
            if (output.data[i].planType === "sub") {
              obj = {
                id: output.data[1].id,
                description: output.data[1].description,
                name: output.data[1].name,
                price: output.data[1].price,
                currency: output.data[1].currency,
                stripePriceId: output.data[1].stripePriceId,
                discount: output.data[1].discount,
                displaySequence: output.data[1].displaySequence,
                planType: output.data[1].planType,
                annualId: output.data[2].id,
                annualDescription: output.data[2].description,
                annualName: output.data[2].name,
                annualPrice: output.data[2].price,
                annualCurrency: output.data[2].currency,
                annualStripePriceId: output.data[2].stripePriceId,
                annualDiscount: output.data[2].discount,
                annualDisplaySequence: output.data[2].displaySequence,
                annualPlanType: output.data[2].planType,
                planItems: output.data[1].planItems,
              };
              plan.push(obj);
            } else {
              plan.push(output.data[i]);
            }
          }
          plan.splice(1, 1);
          // plan.length = plan.length - 1;
          for (let i = 0; i < plan.length; i++) {
            plan[0].monthlyPlanClass =
              "plan standard aos-init aos-animate plan_card plan_card_bottom";
            plan[0].monthlyPlanSampleLink =
              "/indpdf/sample/GlobalBasicSample.pdf";
            plan[0].monthlyPlanBtnClass = "btn_plan1";
            plan[2].monthlyPlanClass =
              "plan ultimite aos-init aos-animate plan_card plan_card_bottom";
            plan[1].monthlyPlanBtnClass = "btn_plan";
            plan[1].monthlyPlanSampleLink =
              "/indpdf/sample/GlobalProfessionalSample.pdf";
            plan[1].monthlyPlanClass =
              "plan plan-inner aos-init aos-animate plan_card plan_card_bottom";
            plan[2].monthlyPlanSampleLink =
              "/indpdf/sample/GlobalProfessionalSample.pdf";
            plan[2].planDesc =
              "Full Company reports & Industry Averages reports are available for purchase";
          }
          plan.sort((a, b) => a.displaySequence - b.displaySequence);

          this.setState({
            planList: plan,
            render: true,
          });
        }
      })
      .catch((error) => {});
  }

  // toBuyReport() {
  //   let selectedCompanyReportObj = {
  //     cusip: this.state.selectedCompany.cusip,
  //     tableName: this.state.selectedCompany.tableName,
  //     companyName: this.state.selectedCompany.companyName,
  //     ticker: this.state.selectedCompany.ticker,
  //     country: this.state.selectedCompany.country,
  //     countryCode: this.state.selectedCompany.countryCode,
  //     region: this.state.selectedCompany.region,
  //     dssTicker: this.state.selectedCompany.dssTicker,
  //     shouldAppearInSearch: this.state.selectedCompany.shouldAppearInSearch,
  //     corpInfoIndustry: this.state.selectedCompany.corpInfoIndustry,
  //     searchDescription: this.state.selectedCompany.searchDescription,
  //     fullPDF: this.state.selectedCompany.fullPDF,
  //     codeCountry: this.state.selectedCompany.codeCountry,
  //     planId: "3",
  //     price: this.state.price,
  //     type: "Company",
  //   };

  //   this.cartData = store.getState().cartData.cart;

  //   let reports = this.cartData;
  //   for (let i = 0; i < this.cartData.length; i++) {
  //     let cusip = this.cartData[i].cusip;
  //     if (
  //       Utils.equalsIgnoreCase("Company", selectedCompanyReportObj.type) &&
  //       Utils.equalsIgnoreCase(cusip, selectedCompanyReportObj.cusip)
  //     ) {
  //       PtsAlert.error(
  //         selectedCompanyReportObj.type +
  //           " " +
  //           "Report " +
  //           selectedCompanyReportObj.companyName +
  //           " Already exists in your Cart"
  //       );
  //       return;
  //     }
  //   }

  //   reports.push(selectedCompanyReportObj);
  //   store.dispatch(cartAction.setCart(reports));
  //   this.props.history.push({
  //     pathname: "/cart",
  //     state: {
  //       previousPage: "/",
  //     },

  //   });
  // }
  toBuyCompanyReport() {
    this.props.history.push({
      pathname: "/companyreport",
    });
  }
  toBuyIndustryAverageReport() {
    this.props.history.push({
      pathname: "/industryreport",
    });
  }
  toBuyReport(selectedPlan, type) {
    let userData = store.getState().userData.userData;
    let userSelectedPlan = {};
    if (type === "Monthly") {
      userSelectedPlan = selectedPlan;
    } else {
      userSelectedPlan = {
        id: selectedPlan.annualId,
        description: selectedPlan.annualDescription,
        name: selectedPlan.annualName,
        price: selectedPlan.annualPrice,
        currency: selectedPlan.annualCurrency,
        stripePriceId: selectedPlan.annualStripePriceId,
        discount: selectedPlan.annualDiscount,
        displaySequence: selectedPlan.annualDisplaySequence,
        planType: selectedPlan.annualPlanType,
        planItems: selectedPlan.planItems,
      };
    }
    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      if (userData) {
        if (userData.activeUser === false) {
          this.props.history.push({
            pathname: "/checkout",
            state: {
              planDetails: userSelectedPlan,
            },
          });
        } else {
          this.props.history.push({
            pathname: "/user",
          });
        }
      } else {
        this.props.history.push({
          pathname: "/checkout",
          state: {
            planDetails: userSelectedPlan,
          },
        });
      }
    } else {
      this.props.history.push({
        pathname: "/checkout",
        state: {
          planDetails: userSelectedPlan,
        },
      });
    }
  }

  planCard() {
    let plan = [];
    for (let i = 0; i < this.state.planList.length; i++) {
      let feature = [];
      if (this.state.planList[i].planType === "free") {
        plan.push(
          <Col md={3} key={i}>
            <div
              className={this.state.planList[i].monthlyPlanClass}
              data-aos="zoom-in"
              data-aos-delay={300}
              style={{
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <div className="plan-inner plan_card plan_card_bottom">
                <div className="entry-title plan_card">
                  <h3 className="plan_card" style={{ padding: "7px" }}>
                    {this.state.planList[i].name}
                  </h3>

                  {this.state.planList[i].planType === "purchase" ? (
                    <></>
                  ) : (
                    <>
                      {this.state.planList[i].price !== 0 ? (
                        <div className="price">
                          <span style={{ left: "8px" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                          </span>
                        </div>
                      ) : (
                        <div className="price">
                          <span style={{ left: "1.1em" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="entry-content">
                  <ul style={{ fontSize: "small" }}>
                    {this.state.planList[i].discount !== 0 ? (
                      <>
                        <li style={{}}>
                          {" "}
                          <b>
                            {"Discount : " +
                              this.state.planList[i].discount +
                              "%"}
                          </b>{" "}
                        </li>
                      </>
                    ) : (
                      <li style={{ marginTop: "2em" }}> </li>
                    )}
                    {feature}
                  </ul>
                </div>
                &nbsp;
                {this.state.planList[i].price !== 0 && (
                  <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                      <div
                        className={this.state.planList[i].monthlyPlanBtnClass}
                      >
                        <a
                          onClick={this.toBuyReport.bind(
                            this,
                            this.state.planList[i]
                          )}
                          style={{ borderRadius: "10px" }}
                        >
                          Buy Now
                        </a>
                      </div>
                    </Col>
                  </Row>
                )}
                {this.state.planList[i].planType !== "free" ? <></> : <></>}
              </div>
            </div>
          </Col>
        );
      } else if (this.state.planList[i].planType === "purchase") {
        plan.push(
          <Col md={3} key={i}>
            <div
              className={this.state.planList[i].monthlyPlanClass}
              data-aos="zoom-in"
              data-aos-delay={300}
              style={{
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <div className="plan-inner plan_card plan_card_bottom">
                <div className="entry-title plan_card">
                  <h3 className="plan_card" style={{ padding: "7px" }}>
                    {this.state.planList[i].name}
                  </h3>

                  {this.state.planList[i].planType === "purchase" ? (
                    <></>
                  ) : (
                    <>
                      {this.state.planList[i].price !== 0 ? (
                        <div className="price">
                          <span style={{ left: "8px" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                          </span>
                        </div>
                      ) : (
                        <div className="price">
                          <span style={{ left: "1.1em" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="entry-content">
                  <ul style={{ fontSize: "small" }}>
                    {this.state.planList[i].discount !== 0 ? (
                      <>
                        <li style={{}}>
                          {" "}
                          <b>
                            {"Discount : " +
                              this.state.planList[i].discount +
                              "%"}
                          </b>{" "}
                        </li>
                      </>
                    ) : (
                      <li style={{ marginTop: "2em" }}> </li>
                    )}
                    {feature}
                    <br></br>
                    <br></br>
                    <div
                      className="btn_plan"
                      style={{ marginLeft: "1.6em", marginBottom: "1em" }}
                    >
                      <a
                        onClick={this.toBuyCompanyReport.bind(this)}
                        style={{ borderRadius: "10px", padding: "0.5em 3em" }}
                      >
                        Buy Company Report
                      </a>
                    </div>

                    <div className="btn_plan" style={{ marginLeft: "1.6em" }}>
                      <a
                        onClick={this.toBuyIndustryAverageReport.bind(this)}
                        style={{ borderRadius: "10px" }}
                      >
                        Buy Industry Average Report
                      </a>
                    </div>
                  </ul>
                </div>
                &nbsp;
                {this.state.planList[i].price !== 0 && (
                  <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                      <div
                        className={this.state.planList[i].monthlyPlanBtnClass}
                      >
                        <a
                          onClick={this.toBuyReport.bind(
                            this,
                            this.state.planList[i]
                          )}
                          style={{ borderRadius: "10px" }}
                        >
                          Buy Now
                        </a>
                      </div>
                    </Col>
                  </Row>
                )}
                {this.state.planList[i].planType !== "free" ? <></> : <></>}
              </div>
            </div>
          </Col>
        );
      } else {
        plan.push(
          <Col md={3} key={i}>
            <div
              className={this.state.planList[i].monthlyPlanClass}
              data-aos="zoom-in"
              data-aos-delay={300}
              style={{
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <div className="plan-inner plan_card plan_card_bottom">
                <div className="entry-title plan_card">
                  <h3 className="plan_card" style={{ padding: "7px" }}>
                    {"Unlimited"}
                  </h3>
                  <Row>
                    <Col md={6}>
                      <div className="price1">
                        {this.state.planList[i].price !== 0 ? (
                          <span style={{ left: "8px", fontSize: "0.7em" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                            <h6 style={{ marginTop: "-4.5em" }}>Monthly</h6>
                          </span>
                        ) : (
                          <span style={{ left: "1.1em", fontSize: "0.7em" }}>
                            {" "}
                            {"$" + this.state.planList[i].price}
                            <h6 style={{ marginTop: "-4.5em" }}>Monthly</h6>
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="price2 ">
                        {this.state.planList[i].price !== 0 ? (
                          <span style={{ left: "8px", fontSize: "0.7em" }}>
                            {" "}
                            {"$" + this.state.planList[i].annualPrice}
                            <h6 style={{ marginTop: "-4.5em" }}>Annual</h6>
                          </span>
                        ) : (
                          <span style={{ left: "1.1em", fontSize: "0.7em" }}>
                            {" "}
                            {"$" + this.state.planList[i].annualPrice}
                            <h6 style={{ marginTop: "-4.5em" }}>Annual</h6>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row style={{ marginTop: "2.5em" }}>
                  <Col md={6}>
                    {this.state.planList[i].price !== 0 && (
                      <Row>
                        <Col md={12} className="buy_now_btn1">
                          <div
                            className="btn_plan1"
                            style={{ marginLeft: "1.6em" }}
                          >
                            <a
                              onClick={this.toBuyReport.bind(
                                this,
                                this.state.planList[i],
                                "Monthly"
                              )}
                              style={{ borderRadius: "10px" }}
                            >
                              Buy Now
                            </a>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md={6}>
                    {this.state.planList[i].price !== 0 && (
                      <Row>
                        <Col md={12} className="buy_now_btn2">
                          <div
                            className="btn_plan1"
                            style={{ marginLeft: "-1.5em" }}
                          >
                            <a
                              onClick={this.toBuyReport.bind(
                                this,
                                this.state.planList[i],
                                "Annual"
                              )}
                              style={{ borderRadius: "10px" }}
                            >
                              Buy Now
                            </a>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <div className="entry-content">
                  <ul style={{ fontSize: "small" }}>
                    {this.state.planList[i].discount !== 0 ? (
                      <>
                        <li style={{}}>
                          {" "}
                          <b>
                            {"Discount : " +
                              this.state.planList[i].discount +
                              "%"}
                          </b>{" "}
                        </li>
                      </>
                    ) : (
                      <li style={{}}> </li>
                    )}
                    {feature}
                  </ul>
                </div>
                &nbsp;
                {this.state.planList[i].price !== 0 ? (
                  <Row style={{ marginTop: "-2em" }}>
                    <Col
                      md={12}
                      style={{ textAlign: "center", padding: "10px" }}
                    >
                      <a
                        href={this.state.planList[i].monthlyPlanSampleLink}
                        target="_blank"
                        rel="noreferrer"
                        className="company_report_sample"
                      >
                        View Sample
                      </a>
                    </Col>
                  </Row>
                ) : (
                  <h6 style={{ textAlign: "center", fontSize: "0.8em" }}>
                    <i>{this.state.planList[i].planDesc}</i>
                  </h6>
                )}
              </div>
            </div>
          </Col>
        );
      }

      for (let j = 0; j < this.state.planList[i].planItems.length; j++) {
        feature.push(<li>{this.state.planList[i].planItems[j].name}</li>);
      }
    }
    return plan;
  }
  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>Plans</h4>
                  </div>
                  <div className="col-lg-6">
                    <SearchWithResult
                      history={this.props.history}
                      color="white"
                      pageId="NotHome"
                    ></SearchWithResult>
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <section>
            <Row style={{ margin: "4em 0 0 0" }}>
              <Col md={1}></Col>
              <Col md={1} className="pts_mobile_view1">
                <div style={{ float: "left" }}>
                  <img
                    src="/home/assets/img/planTop.png"
                    className="img-fluid"
                    alt=""
                    align="left"
                    style={{ width: "140px" }}
                  />
                </div>
              </Col>
              <Col md={10}>
                <>
                  <Row className="">
                    <h6 style={{ color: "#003B6D", fontWeight: "revert" }}>
                      Wright Reports are available in two subscription levels
                      allowing you to tailor the service to fit your corporate
                      research needs.
                    </h6>
                  </Row>
                  <Row>
                    <p
                      style={{
                        fontSize: "0.9em",
                        marginTop: "-7px",
                        textAlign: "justify",
                        marginBottom: "45px",
                      }}
                    >
                      Monthly and annual subscribers obtain a secure login to
                      access Wright Reports’ content, 24 hours a day, seven days
                      a week. Service features include: screening capability on
                      25 criteria, customized peer comparative analysis reports.
                      The site also contains up-to-date currency conversion
                      tables for over 50 currencies.
                    </p>
                  </Row>
                </>
              </Col>
            </Row>
          </section>

          <Container
            maxWidth="lg"
            className="contcss"
            style={{ minHeight: "33em" }}
          >
            <Row>
              <Col md={3}></Col>
              <Col md={3}></Col>
              <Col md={3}></Col>
              <Col md={3}>
                <Advertisement advertisementType="verticalAddUnit1" />
              </Col>
            </Row>
          </Container>

          <Row>
            <Col md={1}></Col>
            <Col md={10}></Col>
            <Col md={1}></Col>
          </Row>
          <Login
            ref={this.loginRefList}
            data={this.state.selectedPlan}
            pageId="plans"
            history={this.props.history}
            isLogin={true}
            modalShow={this.state.modalShow}
            showPage="Login"
          ></Login>
        </>
      );
    } else {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>Plans</h4>
                  </div>
                  <div className="col-lg-6">
                    <SearchWithResult
                      history={this.props.history}
                      color="white"
                      pageId="NotHome"
                    ></SearchWithResult>
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <section>
            <Row style={{ margin: "4em 0 0 0" }}>
              <Col md={1}></Col>
              <Col md={1} className="pts_mobile_view1">
                <div style={{ float: "left" }}>
                  <img
                    src="/home/assets/img/planTop.png"
                    className="img-fluid"
                    alt=""
                    align="left"
                    style={{ width: "140px" }}
                  />
                </div>
              </Col>
              <Col md={10}>
                <>
                  <Row className="">
                    <h6 style={{ color: "#003B6D", fontWeight: "revert" }}>
                      Wright Reports are available in two subscription levels
                      allowing you to tailor the service to fit your corporate
                      research needs.
                    </h6>
                  </Row>
                  <Row>
                    <p
                      style={{
                        fontSize: "0.9em",
                        marginTop: "-7px",
                        textAlign: "justify",
                        marginBottom: "45px",
                      }}
                    >
                      Monthly and annual subscribers obtain a secure login to
                      access Wright Reports’ content, 24 hours a day, seven days
                      a week. Service features include: screening capability on
                      25 criteria, customized peer comparative analysis reports.
                      The site also contains up-to-date currency conversion
                      tables for over 50 currencies.
                    </p>
                  </Row>
                </>
              </Col>
            </Row>
          </section>

          <Container
            maxWidth="lg"
            className="contcss"
            style={{ minHeight: "33em" }}
          >
            <Row>
              {this.planCard()}
              <Col md={3}>
                <Advertisement advertisementType="verticalAddUnit1" />
              </Col>
            </Row>
          </Container>

          <Row>
            <Col md={1}></Col>
            <Col md={10}></Col>
            <Col md={1}></Col>
          </Row>
          <Login
            ref={this.loginRefList}
            data={this.state.selectedPlan}
            pageId="plans"
            history={this.props.history}
            isLogin={true}
            modalShow={this.state.modalShow}
            showPage="Login"
          ></Login>
        </>
      );
    }
  }
}
