import React, { Component } from "react";
import { matchPath } from "react-router-dom";
import { Row } from "reactstrap";
import store from "../../redux/store";
import "../corporateInformationHome/assets/css/style.css";
import "../corporateInformationHome/assets/vendor/aos/aos.css";
import "../corporateInformationHome/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../corporateInformationHome/assets/vendor/bootstrap/css/bootstrap.css";
import "../corporateInformationHome/assets/vendor/glightbox/css/glightbox.min.css";
import "../corporateInformationHome/assets/vendor/remixicon/remixicon.css";
import "../corporateInformationHome/assets/vendor/swiper/swiper-bundle.min.css";
import SearchWithResult from "../search/SearchWithResult";
import "./PageHeader.css";
export default class PageHeader extends Component {
  userData = store.getState().userData.userData;
  cartData = store.getState().cartData.cart;

  loginRefList = [];
  constructor(props) {
    super(props);
    let pageUrl = window.location.href;
    let isUserLogin = false;
    let user = "";
    let isLoginMenu = true;
    let isUserProfile = false;
    let isPaidLoginUser = false;
    let className = "breadcrumbs";
    let className1 = "breadcrumbs_fix_top_all";
    if (props.header === "companySnap") {
      className = "companySnapBreadcrumbs";
      className1 = "companySnap_breadcrumbs_fix_top_all";
    }
    if (this.userData) {
    if (this.userData) {
      isUserLogin = true;
      user = this.userData.firstName + " " + this.userData.lastName;
      isLoginMenu = false;
      isUserProfile = true;
    }
    if (this.userData === undefined) {
      isPaidLoginUser = true;
    } else if (this.userData.activeUser === false) {
      isPaidLoginUser = true;
    } else {
      isPaidLoginUser = false;
    }
  }
    this.state = {
      isOpen: false,
      className: className,
      className1: className1,
      showModal: false,
      password: "",
      userName: "",
      isLogin: true,
      isResetPass: false,
      isForgotPass: false,
      isPaidLoginUser: isPaidLoginUser,
      location: this.props.location,
      modalShow: false,
      user: user,
      isUserLogin: isUserLogin,
      isLoginMenu: isLoginMenu,
      isUserProfile: isUserProfile,
      totalCartItems: this.cartData.length,
      pageUrl: pageUrl,
    };
    this.loginRefList = React.createRef();
  }

  getPageTitle = () => {
    let name;
    this.props.routes.map((prop) => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false,
        })
      ) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };
  render() {
    return (
      <>
        {/* <Container maxWidth="xl"> */}
        <Row className={this.state.className1}>
          <section className={this.state.className}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4>{this.getPageTitle()}</h4>
                </div>
                <div className="col-lg-6">
                  <SearchWithResult
                    history={this.props.history}
                    color="white"
                    pageId="NotHome"
                    pageUrl={this.state.pageUrl}
                  ></SearchWithResult>
                  {/* <GenericSearch history={this.props.history} color="white" /> */}
                </div>
              </div>
            </div>
          </section>
        </Row>
        {/* </Container> */}
      </>
    );
  }
}
