import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import Advertisement from "../ciadvertisement/Advertisement";
import PtsAlert from "../ptsAlert/PtsAlert";
import SearchWithResult from "../search/SearchWithResult";
import "./DefaultCard.css";
class DefaultCard extends Component {
  userData = store.getState().userData.userData;
  constructor(props) {
    super(props);

    let userEmail = "";
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      userEmail = props.history.location.state.userEmail;
    }
    this.state = {
      cards: [],
      userEmail: userEmail ? userEmail : this.userData.email,
      render: false,
      selectedPlan: true,
      showModal: false,
      isToAnnualtBtn: false,
      monthList: [
        // <option value=''>Month</option>
        { value: "01", month: "January" },
        { value: "02", month: "February" },
        { value: "03", month: "March" },
        { value: "04", month: "April" },
        { value: "05", month: "May" },
        { value: "06", month: "June" },
        { value: "07", month: "July" },
        { value: "08", month: "August" },
        { value: "09", month: "September" },
        { value: "10", month: "October" },
        { value: "11", month: "November" },
        { value: "12", month: "December" },
      ],
    };
  }

  componentDidMount() {
    this.getCardList();
  }
  getCardList() {
    let obj = { email: this.state.userEmail };
    FetchServerData.callPostService("cardms/getlist", obj)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          this.getAllSubscription();
          this.setState({
            cards: data,
          });
        }
      })
      .catch((error) => {});
  }
  getAllSubscription() {
    const postObject = {};
    FetchServerData.callPostService("planms/getAllSubPlans", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let plan = output.data;
          this.setState({
            planList: plan,
            planItems: plan[2].planItems,
            render: true,
          });
        }
      })
      .catch((error) => {});
  }
  addCardToBackend() {
    // let PBKDF2 = Crypto.PBKDF2(
    //   "CorporateInformation@2022",
    //   "123456789"
    // ).toString();
    // let encryptedCVC = Crypto.HmacSHA256(this.state.cvv, PBKDF2).toString();
    // let encryptedCVC = Crypto.AES.encrypt(
    //   this.state.cvv,
    //   "CorporateInformation@2022"
    // ).toString();

    let base64Cvc = Utils.toBase64(this.state.cvv);
    const postObject = {
      email: this.state.userEmail,
      card: {
        number: String(this.state.cardNumber).replace(/-/g, ""),
        exp_month: this.state.expMonth,
        exp_year: this.state.expYear,
        country: "US",
        cvc: base64Cvc,
        name: this.state.name,
      },
    };
    FetchServerData.callPostService("cardms/createCard", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
          this.getCardList();
          this.setState({
            showModal: false,
          });
        }
      })
      .catch((error) => {});
  }
  planCard() {
    let plan = [];

    let feature = [];

    plan.push(
      <Col md={3}>
        <div
          className="plan ultimite aos-init aos-animate plan_card plan_card_bottom"
          style={{
            boxShadow:
              "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
          }}
        >
          <div className="plan-inner1 plan_card plan_card_bottom">
            <div className="entry-title plan_card">
              <h3 className="plan_card" style={{ padding: "7px" }}>
                {this.state.planList[2].name}
              </h3>

              <div className="price">
                <span style={{ left: "7px" }}>
                  {" "}
                  {"$" + this.state.planList[2].price}
                </span>
              </div>
            </div>
            <div className="entry-content">
              <ul>{feature}</ul>
            </div>

            <Row>
              <Col
                md={12}
                style={{
                  textAlign: "center",
                  color: "#0d6efd",
                  textDecoration: "underline",
                }}
              ></Col>
            </Row>
          </div>
        </div>
        ;
      </Col>
    );

    for (let j = 0; j < this.state.planItems.length; j++) {
      feature.push(<li>{this.state.planItems[j].name}</li>);
    }

    return plan;
  }

  renderPlan() {
    this.setState({
      selectedPlan: true,
    });
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  addNewcard() {
    this.setState({
      showModal: true,
    });
  }
  cancel() {
    this.props.history.push({
      pathname: "/user",
    });
  }
  handleCardNumberChange(e) {
    let cardNumber = "";
    if (String(e.target.value).match(/\d{4}(?=\d{2,3})|\d+/g)) {
      cardNumber = String(e.target.value)
        .match(/\d{4}(?=\d{1,3})|\d+/g)
        .join("-");
      this.setState({ cardNumber: cardNumber });
    } else {
      cardNumber = "";
    }
    this.setState({ cardNumber: cardNumber });
    // this.setState({
    //   cardNumber: e.target.value,
    // });
  }

  handleExpiaryMonthChange(selectedMonth) {
    //     if (undefined !== this.state.onChange && null !== this.state.onChange) {
    //       this.state.onChange(this.state);
    //     }
    // this.setState({
    //   // expMonth: e.target.value,
    //   expMonth: selectedMonth.value,
    // });

    this.state.expMonth = selectedMonth;
    this.setState({
      expMonth: selectedMonth.value,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  handleYearMonthsChange(e) {
    this.setState({
      expYear: e.target.value,
    });
  }
  handleCvvChange(e) {
    this.setState({
      cvv: e.target.value,
    });
  }
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  //   handleNameChange(e) {
  //     this.setState({
  //       name: e.target.value,
  //     });
  //   }
  cardHandleChange(card) {
    this.setState({
      isToAnnualtBtn: true,
      selectedCard: card,
    });
  }
  upgradeMonthlyToAnnual() {
    const postObject = {
      email: this.state.userEmail,
      subscriptionId: "",
      paymentMethodId: this.state.selectedCard.paymentMethodId,
    };

    FetchServerData.callPostService("pg/upgradeToAnnual", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.props.history.push({
            pathname: "/user",
          });
        }
      })
      .catch((error) => {});
  }
  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>Card Payment</h4>
                  </div>
                  <div className="col-lg-6">
                    <SearchWithResult
                      history={this.props.history}
                      color="white"
                      pageId="NotHome"
                    ></SearchWithResult>
                  </div>
                </div>
              </div>
            </section>
          </Row>
        </>
      );
    } else {
      let userCards = this.state.cards.map((card, i) => {
        return (
          <>
            <Row>
              <Col md={2} style={{ margin: "auto" }}>
                <span>
                  <input
                    type="radio"
                    name="card"
                    style={{ height: "25px", width: "25px" }}
                    value={card.paymentMethodId}
                    onChange={this.cardHandleChange.bind(this, card)}
                  ></input>
                </span>
              </Col>
              <Col md={10}>
                <div className="CI-Card">
                  <div className="card">
                    <div
                      className="card__front card__part"
                      style={
                        i % 2
                          ? {
                              background:
                                "linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)",
                            }
                          : { background: "#2980B9" }
                      }
                    >
                      <div class="chips svg"></div>
                      <div className="paywave svg"></div>
                      <p className="card_numer">
                        <span> XXXX XXXX XXXX {card.number}</span>
                      </p>
                      <div className="card__space-75">
                        <span className="card__label">Card holder</span>
                        <p className="card__info">
                          {" "}
                          {card.name ? card.name : "XXXX XXXX"}
                        </p>
                      </div>
                      <div className="card__space-25">
                        <span className="card__label">Expires</span>
                        <p className="card__info">
                          {card.exp_month +
                            "/" +
                            moment(card.exp_year, "yyyy").format("YY")}
                        </p>
                      </div>
                    </div>
                    {/* <div className="card__back card__part">
                      <div className="card__black-line" />
                      <div className="card__back-content">
                        <div className="card__secret">
                          <p className="card__secret--last">xxx</p>
                        </div>
                        <br></br>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            style={{
                              background: "#fff",
                              textAlign: "center",
                              webkitBorderRadius: "10px",

                              margin: "auto",
                              color: "#fd696b",
                              borderBottom: "2px solid #636262",
                            }}
                            onClick={this.upgradeMonthlyToAnnual.bind(this)}
                          >
                            Pay from this card
                          </Button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <br></br>
          </>
        );
      });
      return (
        <>
          <div className="defaultCard">
            <Container maxWidth="lg" style={{ marginTop: "5em" }}>
              <Row style={{ marginTop: "1em" }}>
                <Row
                  style={{
                    borderBottom: "1px solid #ABB2B9",
                    marginTop: "4em",
                  }}
                >
                  <div>
                    <Button
                      style={{
                        background: "#f58d51",

                        float: "left",
                        webkitBorderRadius: "10px",
                        fontSize: "0.8em",
                        float: "left",
                        margin: "auto 5px 0.5em auto",
                        marginRight: "5px",
                        borderBottom: "2px solid #636262",
                      }}
                      onClick={(e) => this.cancel()}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "#f58d51",

                        float: "right",
                        webkitBorderRadius: "10px",
                        fontSize: "0.8em",
                        float: "right",
                        margin: "auto 5px 0.5em auto",
                        marginRight: "5px",
                        borderBottom: "2px solid #636262",
                      }}
                      onClick={(e) => this.addNewcard()}
                    >
                      Add New Card
                    </Button>
                  </div>
                </Row>
                <Row>
                  <Col md={12} className="Cmp_reports"></Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <Advertisement advertisementType="verticalAddUnit" />
                  </Col>
                  <Col md={5}>{this.planCard()}</Col>
                  <Col md={5} style={{ marginTop: "2em" }}>
                    {userCards}
                    <br></br>
                    <div style={{ textAlign: "center" }}>
                      {this.state.isToAnnualtBtn === true && (
                        <Button
                          style={{
                            background: "#f58d51",

                            webkitBorderRadius: "10px",
                            fontSize: "0.8em",

                            margin: "auto 5px 0.5em auto",
                            marginRight: "5px",
                            borderBottom: "2px solid #636262",
                          }}
                          onClick={this.upgradeMonthlyToAnnual.bind(this)}
                        >
                          Pay With Card
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Row>
            </Container>
          </div>

          <Modal
            isOpen={this.state.showModal}
            toggle={this.toggle}
            size="md"
            className="modalContain"
          >
            <ModalHeader>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    Add New Card
                  </Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.toggle}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <form action="#">
                <div className="" style={{ marginLeft: "0px !important" }}>
                  <div className="row pull-center">
                    <div className="col-md-12">
                      <div className="well">
                        <div className="row card"></div>
                        <br />
                        <Row>
                          <Col md={12}>
                            <TextField
                              name="Credit Card Number"
                              variant="outlined"
                              value={this.state.cardNumber}
                              onChange={this.handleCardNumberChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                              fullWidth
                              id="name"
                              label="Credit Card Number"
                              autoComplete="off"
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md={6}>
                            {/* <TextField
                              name="Expiary Month"
                              variant="outlined"
                              required
                              value={this.state.expMonth}
                              onChange={this.handleExpiaryMonthChange.bind(
                                this
                              )}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              fullWidth
                              id="name"
                              label="Expiration Month"
                              autoComplete="off"
                            /> */}

                            <Autocomplete
                              id="state"
                              options={this.state.monthList}
                              getOptionLabel={(option) => option.month}
                              defaultValue={this.state.expMonth}
                              value={this.state.expMonth}
                              onChange={(event, value) => {
                                this.handleExpiaryMonthChange(value);
                              }}
                              inputProps={{
                                style: {
                                  textTransform: "capitalize",
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Month"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Col>

                          <Col md={6}>
                            <TextField
                              name="Expiary Year"
                              variant="outlined"
                              required
                              value={this.state.expYear}
                              onChange={this.handleYearMonthsChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              fullWidth
                              id="name"
                              label="Expiration Year"
                              autoComplete="off"
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md={6}>
                            <TextField
                              name="CVV"
                              variant="outlined"
                              required
                              value={this.state.cvv}
                              onChange={this.handleCvvChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              fullWidth
                              id="name"
                              label="CVC"
                              autoComplete="off"
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <Row>
                          <Col md={12}>
                            <TextField
                              name="Name"
                              variant="outlined"
                              value={this.state.name}
                              onChange={this.handleNameChange.bind(this)}
                              inputProps={{
                                style: { textTransform: "capitalize" },
                              }}
                              required
                              fullWidth
                              id="name"
                              label="Name"
                              autoComplete="off"
                            />
                          </Col>
                        </Row>
                        <br></br>
                        {/* <Row>
                          <Col md={12}>
                            <TextField
                              name="Email"
                              variant="outlined"
                              required
                              value={this.state.email}
                              onChange={this.handleEmailChange.bind(this)}
                              fullWidth
                              id="name"
                              label="Email"
                              autoComplete="off"
                            />
                          </Col>
                        </Row> */}

                        <div className="row ">
                          <div className="col-md-12 text-right">
                            <Button
                              style={{
                                background: "#f58d51",

                                webkitBorderRadius: "10px",

                                margin: "auto",
                                marginRight: "5px",
                                borderBottom: "2px solid #636262",
                              }}
                              onClick={(e) => this.addCardToBackend()}
                            >
                              Add
                            </Button>
                            {/* <button type="button" className="btn btn-success">
                              Submit
                            </button>
                            <button type="button" className="btn btn-info">
                              Clear
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}

export default DefaultCard;
