import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import IndInterimIncomeStatement from "./IndInterimIncomeStatement";
import InterimBankStatement from "./InterimBankStatement";
import InterimFinancialIncomeStatement from "./InterimFinancialIncomeStatement";
import "./InterimIncomeStatement.css";
import InterimInsuranceIncomeStatement from "./InterimInsuranceIncomeStatement";

export default class InterimIncomeStatement extends Component {
  constructor(props) {
    super(props);

    let columns = [];
    let type = props.type;

    this.state = {
      type: type,
      actualData: [],
      render: false,
      cusip: props.data,
      isPdfReport: props.isPdfReport,
      unit: "",
      currency: "",
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }
  componentDidMount() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService(
      "financial/incomeStatementInterim",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let actualData = data.indIncomeStatement;
          let indType = data.ind_TYPE;
          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            actualData: data,
            render: true,
            indType: indType,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimBankStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimBankStatement>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimInsuranceIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimInsuranceIncomeStatement>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimFinancialIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimFinancialIncomeStatement>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IndInterimIncomeStatement
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></IndInterimIncomeStatement>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
