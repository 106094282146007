import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import "./InterimBalanceSheet.css";
import InterimBankBalanceSheet from "./InterimBankBalanceSheet";
import InterimFinanceBalanceSheet from "./InterimFinanceBalanceSheet";
import InterimIndBalanceSheet from "./InterimIndBalanceSheet";
import InterimInsuranceBalanceSheet from "./InterimInsuranceBalanceSheet";

export default class InterimBalanceSheet extends Component {
  constructor(props) {
    super(props);

    let columns = [];
    let type = props.type;

    this.state = {
      type: type,
      actualData: [],
      render: false,
      cusip: props.data,
      unit: "",
      currency: "",
      isPdfReport: props.isPdfReport,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }
  componentDidMount() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("financial/balanceSheetInterim", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let actualData = data.indIncomeStatement;
          let indType = data.ind_TYPE;
          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            actualData: data,
            render: true,
            indType: indType,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimBankBalanceSheet
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimBankBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimInsuranceBalanceSheet
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimInsuranceBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimFinanceBalanceSheet
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimFinanceBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <InterimIndBalanceSheet
                  data={this.state.actualData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InterimIndBalanceSheet>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
