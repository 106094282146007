import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import "./InsuranceAssetUtilization.css";
export default class InsuranceAssetUtilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      accountingRatioIndData: [],
      note: props.data.note,
      noteHeading: props.data.noteHeading,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    let assetUtilizationRatioInsList =
      this.state.data.assetUtilizationRatioInsList;
    if (assetUtilizationRatioInsList.length > 0) {
      //assetUtilizationRatioInsList.reverse();
    }
    return (
      <>
        <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
          <Col md={12}>
            <Row>
              <Col md={8}>
                <span>
                  <span className="section_header">
                    {" "}
                    Financial Asset Utilization
                  </span>
                </span>{" "}
                <br></br>
                <span className="unit_str" style={{ marginLeft: "-2px" }}>
                  Figures are expressed as the ratio of Net Sales.
                </span>
                <br></br>
                <span className="unit_str" style={{ marginLeft: "-2px" }}>
                  Net Sales are in{" "}
                  <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                  {this.state.currency}
                </span>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Table responsive className="financial_analysis">
           
           {Object.keys(assetUtilizationRatioInsList).map((key, index) => { 
             return (
             <tr>
                   <th style={{ fontWeight: "bold" }}>{key}</th>
                 
                  
             {assetUtilizationRatioInsList[key].map((data, index) => { 
             return (
            
                   <td style={{ fontWeight: "bold" }}>{data}</td>
                
             
             );
           })
           }
           </tr>
             );
           })
           }
       
           </Table>

          
            
          </Col>
        </Row>
      </>
    );
  }
}
