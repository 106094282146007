import { combineReducers } from "redux";
import advanceFilterReducer from "./advanceFilter/advanceFilterReducer";
import cartReducer from "./cart/cartReducer";
import companySnapshotReducer from "./companyDetails/companySnapshotReducer";
import filterSectorReducer from "./filterSector/filterSectorReducer";
import headerReducer from "./header/headerReducer";
import historyReducer from "./history/historyReducer";
import reportPriceReducer from "./reportPrice/reportPriceReducer";
import staticDataReducer from "./staticData/staticDataReducer";
import userDataReducer from "./userData/userDataReducer";
import userSelectedIndustriesReducer from "./userSelectedIndustries/userSelectedIndustriesReducer";
import userSelectedRegionReducer from "./userSelectedRegion/userSelectedRegionReducer";

const appReducer = combineReducers({
  header: headerReducer,
  staticData: staticDataReducer,
  userData: userDataReducer,
  cartData: cartReducer,
  price: reportPriceReducer,
  companyName: companySnapshotReducer,
  advanceFilter: advanceFilterReducer,
  regionFilterData: userSelectedRegionReducer,
  industryFilterData: userSelectedIndustriesReducer,
  sectorFilterData: filterSectorReducer,
  history: historyReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_ACTION") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
