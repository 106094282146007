import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { PureComponent } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { withRouter } from "react-router-dom";
import GenericButton from "../buttons/GenericButton";
import "./GenericSearch.css";
class GenericSearch extends PureComponent {
  constructor(props) {
    super(props);
    let pageId = "";
    if (props.pageId === "ciHome") {
      pageId = props.pageId;
    }
    this.state = {
      pageId: pageId,
      searchText: props.searchText,
      searchType: "simple",
      color: props.color,
    };
  }

  handleSearchTextChange(e) {
    if (e.charCode === 13) {
      /*Enter key pressed */
      if (this.state.searchText && this.state.searchText.length > 0) {
        this.props.history.push({
          pathname: "/search",
          state: {
            searchText: this.state.searchText,
            searchType: "simple search",
          },
        });
      }
    }
    this.setState({ searchText: e.target.value });
  }
  performSearch(e) {
    if (this.state.searchText && this.state.searchText.length > 0) {
      this.props.history.push({
        pathname: "/search",
        state: {
          searchText: this.state.searchText,
          searchType: "simple search",
        },
      });
    }
  }

  performAdvanceSearch() {
    if (this.props.history) {
      this.props.history.push({
        pathname: "/search",
        state: {
          searchText: this.state.searchText,
          searchType: "advance search",
        },
      });
    }
  }

  getAdvanceSearch() {
    if (this.state.color === "white") {
      return (
        <a
          style={{
            cursor: "pointer",
            fontSize: "small",
            color: "#fff",

            textAlign: "center",
            outline: "none",
            width: "100%",
            margin: "auto",
          }}
          onClick={this.performAdvanceSearch.bind(this)}
        >
          {" "}
          &nbsp;&nbsp;
          <strong>
            <i>
              <u>Advanced Search</u>
            </i>
          </strong>
        </a>
      );
    } else {
      return (
        <a
          style={{
            cursor: "pointer",
            fontSize: "small",
            color: "#F26D21",

            textAlign: "right",
            outline: "none",
            width: "100%",
            marginTop: "1em",
          }}
          onClick={this.performAdvanceSearch.bind(this)}
        >
          {" "}
          &nbsp;&nbsp;
          <strong>
            <i>
              <u>Advanced Search</u>
            </i>
          </strong>
        </a>
      );
    }
  }
  render() {
    if (this.state.pageId === "ciHome") {
      return (
        <>
          <Grid container>
            <Grid item className="generic_search_box">
              <input
                type="text"
                id="searchText"
                name="searchText"
                autocomplete="off"
                placeholder="Company Name or Ticker"
                style={{
                  border: "1px solid #566573",
                  width: "100%",
                  height: "140%",
                  borderRadius: "0",
                  fontSize: "small",
                  outline: "none",
                }}
                required
                onChange={this.handleSearchTextChange.bind(this)}
                onKeyPress={this.handleSearchTextChange.bind(this)}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "10%" }}
            >
              <GenericButton
                tooltip="Search"
                onClick={this.performSearch.bind(this)}
                pageId={this.state.pageId}
                icon={<SearchIcon />}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "30%" }}
            >
              {this.getAdvanceSearch()}
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container>
            <Grid item className="generic_search_box">
              <input
                type="text"
                id="searchText"
                name="searchText"
                autocomplete="off"
                placeholder="Company Name or Ticker"
                style={{
                  border: "1px solid white",
                  width: "100%",
                  height: "100%",
                  borderRadius: "0",
                  fontSize: "small",
                  outline: "none",
                  borderColor: "#d0c8c8 #d0c8c8 #636262",
                  borderBottom: "3px solid #636262",
                  cursor: "pointer",
                }}
                required
                onChange={this.handleSearchTextChange.bind(this)}
                onKeyPress={this.handleSearchTextChange.bind(this)}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "10%" }}
            >
              <GenericButton
                tooltip="Search"
                onClick={this.performSearch.bind(this)}
                icon={<SearchIcon />}
              />
            </Grid>
            <Grid
              item
              alignItems="stretch"
              style={{ display: "flex", width: "30%" }}
            >
              {this.getAdvanceSearch()}
            </Grid>
          </Grid>
        </>
      );
    }
  }
}
export default withRouter(GenericSearch);
