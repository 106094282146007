import {
  GET_STATIC_DATA,
  SET_COUNTRY_FLAG_DATA,
  SET_INDUSTRY_SUBINDUSTRY_DATA,
  SET_REGION_COUNTRY_DATA,
  SET_STATIC_DATA,
} from "./staticDataTypes";

export const getStaticData = () => {
  return {
    type: GET_STATIC_DATA,
  };
};
export const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};

export const setCountryCodeForFlag = (data) => {
  return {
    type: SET_COUNTRY_FLAG_DATA,
    payload: data,
  };
};

export const setRegionCountryData = (data) => {
  return {
    type: SET_REGION_COUNTRY_DATA,
    payload: data,
  };
};

export const setIndustrySubIndustryData = (data) => {
  return {
    type: SET_INDUSTRY_SUBINDUSTRY_DATA,
    payload: data,
  };
};

export default {
  getStaticData,
  setStaticData,
  setCountryCodeForFlag,
  setRegionCountryData,
  setIndustrySubIndustryData,
};
