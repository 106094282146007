import Axios from "axios";
import PtsAlert from "../ptsComponentes/ptsAlert/PtsAlert";
import store from "../redux/store";
import { Buffer } from 'buffer';
export const serVerUrl = "/CiServices/";

const username = 'UIApp'
const password = 'wright@1234'

const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

const showLoader = () =>
  document.getElementById("iAmUniqueLoaderFCC")
    ? (document.getElementById("iAmUniqueLoaderFCC").className = "ptsloader")
    : "";

const hideLoader = () =>
  document.getElementById("iAmUniqueLoaderFCC")
    ? (document.getElementById("iAmUniqueLoaderFCC").className =
        "ptsloaderHide")
    : "";

  const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${token}`
  }

export const callPostServiceForPDFData = (url, data) => {
  hideLoader();
  showLoader();
  return new Promise(function (accept, reject) {
    Axios.post(url, data, {
      headers: headers
    }).then((response) => {
      hideLoader();

      const responseData = response.data;
      if (undefined !== responseData && responseData.length > 0) {
        //PtsAlert.success(responseData.message);
        accept(responseData);
      } else {
        let errorMessage = "No Data From Server";
        PtsAlert.error("No Data Received from Server");
        reject(errorMessage);
      }
    });
  });
};

export const callPostFileDownload = (url, data) => {
  url = serVerUrl + url;
  hideLoader();
  showLoader();

  return new Promise(function (accept, reject) {
    Axios.post(url, data, {
      headers: headers
    })
      .then((response) => {
        hideLoader();

        accept(response);
      })
      .catch((error) => {
        hideLoader();

        if (error.name !== null && error.name === "TimeoutError") {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = "Unknown Error occurred";
          switch (error.status) {
            /*
                        Unauthorized
                         */
            case 401:
              errorMessage = "You are not authenticate to call this service";
              break;
            /*
                        Forbidden
                       */
            case 403:
              errorMessage =
                "You are not authorized to perform this operation or the resource is unavailable for some reason";
              break;
            /*
                        internal server error
                         */
            case 500:
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            /*
                        Request Timeout
                        */
            case 408:
              /*
                          gatewat timeout
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 504:
              /*
                         Network connect timeout error
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
                         */
            case 503:
              errorMessage =
                "Service you are trying is currently unavailable , please try again later.";
              break;
            default:
              errorMessage = "You are not authenticate to call this service";
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};
export const callPostFileUpload = (url, data) => {
  hideLoader();
  showLoader();

  url = serVerUrl + url;
  return new Promise(function (accept, reject) {
    Axios.post(url, data, {
      headers: headers
    })
      .then((response) => {
        hideLoader();

        const responseData = response.data;
        if (responseData.status === "SUCCESS") {
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message =
            null === responseData.message
              ? "Null Response"
              : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === "TID_1000") {
            try {
              localStorage.clear();
              store.clear();
            } catch (err) {}

            window.location = "/login";
            reject("");
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        hideLoader();
        if (error.name !== null && error.name === "TimeoutError") {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = "Unknown Error occurred";
          switch (error.status) {
            /*
                        Unauthorized
                         */
            case 401:
              errorMessage = "You are not authenticate to call this service";
              break;
            /*
                        Forbidden
                       */
            case 403:
              errorMessage =
                "You are not authorized to perform this operation or the resource is unavailable for some reason";
              break;
            /*
                        internal server error
                         */
            case 500:
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            /*
                        Request Timeout
                        */
            case 408:
              /*
                          gatewat timeout
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 504:
              /*
                         Network connect timeout error
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
                         */
            case 503:
              errorMessage =
                "Service you are trying is currently unavailable , please try again later.";
              break;
            default:
              errorMessage = "You are not authenticate to call this service";
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};

export const callPostService = (url, data) => {
  hideLoader();
  showLoader();
  url = serVerUrl + url;
  return new Promise(function (accept, reject) {
    Axios.post(url, data, {
      headers: headers
    })
      .then((response) => {
        hideLoader();

        const responseData = response.data;
        if (responseData.status === "SUCCESS") {
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message =
            null === responseData.message
              ? "Null Response"
              : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === "TID_1000") {
            try {
              localStorage.clear();
              store.clear();
            } catch (err) {}

            window.location = "/login";
            reject("");
          } else {
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        hideLoader();

        if (error.name !== null && error.name === "TimeoutError") {
          PtsAlert.error("Can't reach to server,please try again later.");
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage =
            undefined !== error.message && null !== error.message
              ? error.message
              : "Unknown Error occurred";

          switch (error.status) {
            /*
                        Unauthorized
                         */
            case 401:
              errorMessage = "You are not authenticate to call this service";
              break;
            /*
                        Forbidden
                       */
            case 403:
              errorMessage =
                "You are not authorized to perform this operation or the resource is unavailable for some reason";
              break;
            /*
                        internal server error
                         */
            case 500:
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            /*
                        Request Timeout
                        */
            case 408:
              /*
                          gatewat timeout
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 504:
              /*
                         Network connect timeout error
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
                         */
            case 503:
              errorMessage =
                "Service you are trying is currently unavailable , please try again later.";
              break;
            default:
              errorMessage = "You are not authenticate to call this service";
              break;
          }
          PtsAlert.error(errorMessage);
          reject(errorMessage);
        } else {
          let errorMessage =
            undefined !== error.message && null !== error.message
              ? error.message
              : "Unknown Error occurred";
          PtsAlert.error(errorMessage);
          reject(error);
        }
      });
  });
};

export const callPostServiceWithoutHeader = (url, data) => {
  hideLoader();
  showLoader();

  url = serVerUrl + url;
  return new Promise(function (accept, reject) {
    Axios.post(url, data, {
      headers: headers
    })
      .then((response) => {
        hideLoader();

        const responseData = response.data;
        if (responseData.status === "SUCCESS") {
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message =
            null === responseData.message
              ? "Null Response"
              : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === "TID_1000") {
            try {
              localStorage.clear();
              store.clear();
            } catch (err) {}

            window.location = "/login";
            reject("");
          } else {
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        hideLoader();

        if (error.name !== null && error.name === "TimeoutError") {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = "Unknown Error occurred";
          switch (error.status) {
            /*
                        Unauthorized
                         */
            case 401:
              errorMessage = "You are not authenticate to call this service";
              break;
            /*
                        Forbidden
                       */
            case 403:
              errorMessage =
                "You are not authorized to perform this operation or the resource is unavailable for some reason";
              break;
            /*
                        internal server error
                         */
            case 500:
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            /*
                        Request Timeout
                        */
            case 408:
              /*
                          gatewat timeout
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 504:
              /*
                         Network connect timeout error
                         */
              errorMessage =
                "Internal Server Error occurred , please try again later";
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
                         */
            case 503:
              errorMessage =
                "Service you are trying is currently unavailable , please try again later.";
              break;
            default:
              errorMessage = "You are not authenticate to call this service";
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};
//}
export default {
  callPostFileDownload,
  callPostFileUpload,
  callPostService,
  callPostServiceForPDFData,
  callPostServiceWithoutHeader,
  hideLoader,
};
