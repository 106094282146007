import Container from "@material-ui/core/Container";
import Pagination from "@material-ui/lab/Pagination";
// import { countries } from "country-data";
import React, { Component } from "react";
import Select from "react-select";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import advanceFilterAction from "../../redux/advanceFilter/advanceFilterAction";
import staticDataAction from "../../redux/staticData/staticDataAction";
import store from "../../redux/store";
import AdvanceSearch from "./AdvanceSearch";
import "./CompanySearch.css";
import CompanyShortDetails from "./CompanyShortDetails";
import SimpleSearch from "./SimpleSearch";

export default class CompanySearch extends Component {
  advanceSearchRef = React.createRef();
  simpleSearchRef = React.createRef();
  currentPageRef = React.createRef();
  advanceFilterData = store.getState().advanceFilter.advanceFilter;
  countryList = store.getState().staticData
    ? store.getState().staticData.countryCodesForFlag
    : [];
  selectedCountries = [];
  selectedIndustries = [];
  dropDownOptions = [
    { value: "10", label: "10" },
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let countries = Utils.getCountryData();
    if ((!this.countryList || this.countryList.length <= 0) && countries) {
      store.dispatch(staticDataAction.setCountryCodeForFlag(countries));
    }
    let advanceFilterData = {};
    if (this.advanceFilterData) {
      advanceFilterData = Object.getOwnPropertyNames(this.advanceFilterData);
    }

    let searchText = "";
    let searchType = "";
    let isAdvanceSearch = false;
    let isSimpleSearch = false;
    let str = "";
    if (advanceFilterData.length > 0) {
      let pageNo = 1;

      this.performSearchByStoreData(
        this.advanceFilterData.searchTextPar,
        this.advanceFilterData.regionPar,
        this.advanceFilterData.countryPar,
        this.advanceFilterData.countryCodesPar,
        this.advanceFilterData.sectorPar,
        this.advanceFilterData.industryPar,
        this.advanceFilterData.isAdvanceSearchPar,
        this.advanceFilterData.industryCodes,
        this.advanceFilterData.industryList,
        this.advanceFilterData.resetPage
      );
      isAdvanceSearch = true;

      this.state = {
        filterData: this.advanceFilterData,
        searchText: this.advanceFilterData.searchTextPar,
        tickerList: [],
        keywordList: [],
        companyList: [],
        selectedCountryCodeList: [],
        tickerCount: "",
        keywordCount: "",
        companyCount: "",
        pageNo: 1,
        pageSize: 10,
        totalPages: 0,
        rowsPerPage: 10,
        isAdvanceSearch: isAdvanceSearch,
        isSimpleSearch: isSimpleSearch,
        advanceSearch: this.advanceFilterData,
        pageParameterUrl: str,
        totalRecords: "",
        AlphabeticalList: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "[0-9]",
        ],
        alphabetSearchData: [],
        advanceDataList: [],
        isAlphabetSearch: false,
        selectedAlphabet: "",
        abPageNo: 1,
        abPageSize: 10,
        abTotalPages: 0,
        selectedOption: { value: "10", label: "10" },
        selectedABOption: { value: "10", label: "10" },
      };
      this.getFilterData();
    } else {
      if (
        props.history &&
        props.history.location &&
        props.history.location.state
      ) {
        searchText = props.history.location.state.searchText;
        searchType = props.history.location.state.searchType;
      } else {
        str = window.location.href;
        if (str && str.length > 0 && str.indexOf("?") >= 0) {
          let urlArr = str.split("?");
          let url = urlArr[1].split("=");
          if (url[1] === "advanceSearch") {
            searchText = "";
            searchType = "advance search";
          } else {
            searchText = url[1];
          }
        }
      }
      if (searchType && searchType === "advance search") {
        searchText = "";
        isAdvanceSearch = true;
        isSimpleSearch = false;
      } else {
        searchText = searchText.split("+").join(" ");
        isAdvanceSearch = false;
        isSimpleSearch = true;
      }

      this.state = {
        filterData: {},
        searchText: searchText,
        tickerList: [],
        keywordList: [],
        companyList: [],
        selectedCountryCodeList: [],
        tickerCount: "",
        keywordCount: "",
        companyCount: "",
        pageNo: 1,
        pageSize: 10,
        totalPages: 0,
        rowsPerPage: 10,
        isAdvanceSearch: isAdvanceSearch,
        isSimpleSearch: isSimpleSearch,
        advanceSearch: {},
        pageParameterUrl: str,
        totalRecords: "",
        AlphabeticalList: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "[0-9]",
        ],
        alphabetSearchData: [],
        advanceDataList: [],
        isAlphabetSearch: false,
        selectedAlphabet: "",
        abPageNo: 1,
        abPageSize: 10,
        abTotalPages: 0,
        selectedOption: { value: "10", label: "10" },
        selectedABOption: { value: "10", label: "10" },
      };
    }

    if (searchText && searchText.length > 0) {
      this.performSimpleSearch();
    }
  }
  getFilterData() {
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.simpleSearchRef.current.setState({
        regions: this.advanceFilterData.regionPar
          ? this.advanceFilterData.regionPar
          : "",
        countries: this.advanceFilterData.countryPar
          ? this.advanceFilterData.countryPar
          : "",
        countryCodes: this.advanceFilterData.countryCodesPar
          ? this.advanceFilterData.countryCodesPar
          : "",
      });
    }
  }
  performSimpleSearch = (e) => {
    let pageNo = this.state.pageNo - 1;

    const postObject = {
      searchText: this.state.searchText,
      page: { pageNo: pageNo, pageSize: this.state.rowsPerPage },
    };
    FetchServerData.callPostService("searchService/simpleSearch", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let tickerList = output.data.tickerList;
          for (let i = 0; i < this.countryList.length; i++) {
            for (let j = 0; j < tickerList.length; j++) {
              if (
                Utils.equalsIgnoreCase(
                  this.countryList[i].name,
                  tickerList[j].country
                )
              ) {
                tickerList[j].codeCountry = this.countryList[i].alpha2;
              }
            }
          }
          let keywordList = output.data.keywordList;
          for (let i = 0; i < this.countryList.length; i++) {
            for (let j = 0; j < keywordList.length; j++) {
              if (
                Utils.equalsIgnoreCase(
                  this.countryList[i].name,
                  keywordList[j].country
                )
              ) {
                keywordList[j].codeCountry = this.countryList[i].alpha2;
              }
            }
          }
          let companyList = output.data.companyList;
          for (let i = 0; i < this.countryList.length; i++) {
            for (let j = 0; j < companyList.length; j++) {
              if (
                Utils.equalsIgnoreCase(
                  this.countryList[i].name,
                  companyList[j].country
                )
              ) {
                companyList[j].codeCountry = this.countryList[i].alpha2;
              }
            }
          }
          this.setState({
            companyList: companyList,
            tickerList: tickerList,
            keywordList: keywordList,
            tickerCount: output.data.tickerCount,
            keywordCount: output.data.keywordCount,
            companyCount: output.data.companyCount,
            paginationType: "simpleSearch",
            totalRecords: output.data.totalRecords,
            isAlphabetSearch: false,
            isPagination: false,
            isSimpleSearchListShow: true,
            isAdvanceSearchListShow: false,
            isSimpleSearch: true,
            abPageSize: 10,
            selectedOption: { value: "10", label: "10" },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  handleChangeABPageSize = (selectedOption) => {
    this.state.abPageSize = selectedOption.value;
    this.setState({
      abPageSize: selectedOption.value,
      selectedABOption: selectedOption,
    });
    this.getCompanyListByAlphabet(this.state.selectedAlphabet);
  };
  handleChangeAdvancePageSize = (selectedOption) => {
    this.state.pageSize = selectedOption.value;
    this.setState({
      selectedOption: selectedOption,
      pageSize: selectedOption.value,
    });

    this.pageNumberChanged(null, this.state.pageNo);
  };

  toAdvanceSearch = (event, value) => {
    this.setState({
      isSimpleSearch: false,
      isAdvanceSearch: true,
      isAlphabetSearch: false,
    });
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.simpleSearchRef.current.toAdvanceSearch();
      this.scrollToTop();
    }
  };
  toCompanyDetails = (companyData) => {
    window.location.href = "#/company?" + companyData.cusip;
    // this.props.history.push({
    //   pathname: "/company",
    //   state: {
    //     data: companyData,
    //     pageId: "company_Search",
    //     cusip: companyData.cusip,
    //   },
    // });
  };

  cancelAdvanceSearch = () => {
    this.setState({
      isAdvanceSearch: false,
      isSimpleSearch: true,
      isAlphabetSearch: false,
    });
  };
  alphabetPageNumberChanged = (event, page) => {
    this.state.abPageNo = page;
    this.getCompanyListByAlphabet(this.state.selectedAlphabet);
  };
  getCompanyListByAlphabetFromPage1 = (Alphabet, value, value1) => {
    this.setState({ abPageNo: 1 });
    this.state.abPageNo = 1;
    this.getCompanyListByAlphabet(Alphabet, value, value1);
  };
  getCompanyListByAlphabet = (Alphabet, value, value1) => {
    this.setState({
      isSimpleSearch: false,
      isAdvanceSearch: false,
      isAlphabetSearch: true,
      selectedAlphabet: Alphabet,
      alphabetSearchData: [],
    });
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.simpleSearchRef.current.setState({
        isSimpleSearch: false,
        isAdvanceSearch: false,
        isAlphabetSearch: true,
      });
    }
    if (this.advanceSearchRef && this.advanceSearchRef.current) {
      this.advanceSearchRef.current.setState({
        isSimpleSearch: false,
        isAdvanceSearch: false,
        isAlphabetSearch: true,
      });
    }
    let abPageNo = this.state.abPageNo - 1;
    const postObject = {
      searchText: Alphabet,
      page: { pageNo: abPageNo, pageSize: this.state.abPageSize },
    };
    FetchServerData.callPostService(
      "searchService/companyStartWith",
      postObject
    ).then((output) => {
      if (output.status === "SUCCESS") {
        let dataList = output.data.result;
        for (let i = 0; i < this.countryList.length; i++) {
          for (let j = 0; j < dataList.length; j++) {
            if (
              Utils.equalsIgnoreCase(
                this.countryList[i].name,
                dataList[j].country
              )
            ) {
              dataList[j].codeCountry = this.countryList[i].alpha2;
            }
          }
        }
        this.setState({
          alphabetSearchData: dataList,
          isAlphabetSearch: true,
          isAdvanceSearch: false,
          isSimpleSearch: false,
          totalRecords: output.data.totalRecords,
          selectedAlphabet: Alphabet,
          abTotalPages: output.data.page.totalPages,
          abPageSize: output.data.page.pageSize,
          abPageNo: output.data.page.pageNo + 1,
        });
        this.scrollToTop();
      } else {
      }
    });
  };

  pageNumberChanged = (event, page) => {
    this.state.pageNo = page;
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      let searchText = this.simpleSearchRef.current.state.searchText;
      let regions = this.simpleSearchRef.current.state.regions;
      let countries = this.simpleSearchRef.current.state.countries;
      // let countryCodes = this.simpleSearchRef.current.state.countryCodeList;
      let countryCodes = this.simpleSearchRef.current.state.countryCodes;
      let sectors = this.simpleSearchRef.current.state.countryCodeList;
      let industries = this.simpleSearchRef.current.state.industries;
      let isAdvanceSearch = this.simpleSearchRef.current.state.isAdvanceSearch;
      let industryCodes = this.simpleSearchRef.current.state.industryCodes;
      let industryList = this.simpleSearchRef.current.state.industryList;
      this.performSearch(
        searchText,
        regions,
        countries,
        countryCodes,
        sectors,
        industries,
        isAdvanceSearch,
        industryCodes,
        industryList
      );
    }
  };

  applyAdvanceFilter() {
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      let searchText = this.simpleSearchRef.current.state.searchText;
      let regions = this.simpleSearchRef.current.state.regions;
      let countries = this.simpleSearchRef.current.state.countries;
      let countryCodes = this.simpleSearchRef.current.state.countryCodes;
      let sectors = this.simpleSearchRef.current.state.countryCodeList;
      let industries = this.simpleSearchRef.current.state.industries;
      let isAdvanceSearch = this.simpleSearchRef.current.state.isAdvanceSearch;
      let industryCodes = this.simpleSearchRef.current.state.industryCodes;
      let industryList = this.simpleSearchRef.current.state.industryList;
      this.state.pageNo = 1;
      this.setState({ pageNo: 1 });
      this.performSearch(
        searchText,
        regions,
        countries,
        countryCodes,
        sectors,
        industries,
        isAdvanceSearch,
        industryCodes,
        industryList
      );
    }
  }

  getAdvanceSearchData = () => {
    this.selectedCountries =
      this.advanceSearchRef.current.state.checkedRegionCountryList;
    this.selectedIndustries =
      this.advanceSearchRef.current.state.checkedSectorCompanyList;
    if (this.advanceSearchRef.current.state) {
      let selectedRegionList = [];
      let selectedCountryList = [];
      let selectedCategoryList = [];
      let selectedSubCategoryList = [];
      let pageNo = this.state.advancePageNo - 1;
      for (
        let i = 0;
        i < this.advanceSearchRef.current.state.selectedRegionList.length;
        i++
      ) {
        selectedRegionList.push(
          this.advanceSearchRef.current.state.selectedRegionList[i].region
        );
      }
      for (
        let i = 0;
        i < this.advanceSearchRef.current.state.selectedCountryList.length;
        i++
      ) {
        selectedCountryList.push(
          this.advanceSearchRef.current.state.selectedCountryList[i].countryCode
        );
      }
      for (
        let i = 0;
        i < this.advanceSearchRef.current.state.selectedCategoryList.length;
        i++
      ) {
        selectedCategoryList.push(
          this.advanceSearchRef.current.state.selectedCategoryList[i]
            .corpInfoIndustry
        );
      }
      for (
        let i = 0;
        i < this.advanceSearchRef.current.state.selectedSubCategoryList.length;
        i++
      ) {
        selectedSubCategoryList.push(
          this.advanceSearchRef.current.state.selectedSubCategoryList[i]
            .industryCode
        );
      }

      const postObject = {
        regionList: selectedRegionList,
        countryCodeList:
          this.advanceSearchRef.current.state.checkedRegionCountryList,
        majorIndustryCodeList: selectedCategoryList,
        industryCodeList:
          this.advanceSearchRef.current.state.checkedSectorCompanyList,
        searchText: this.state.searchText,
        page: {
          pageSize: this.state.rowsPerPage,
          pageNo: pageNo,
        },
      };
      FetchServerData.callPostService("searchService/advanceSearch", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let advanceDataList = output.data.result;
            for (let i = 0; i < this.countryList.length; i++) {
              for (let j = 0; j < advanceDataList.length; j++) {
                if (
                  Utils.equalsIgnoreCase(
                    this.countryList[i].name,
                    advanceDataList[j].country
                  )
                ) {
                  advanceDataList[j].codeCountry = this.countryList[i].alpha2;
                }
              }
            }

            let selectedIndustries1 = [];
            for (
              let i = 0;
              i <
              this.advanceSearchRef.current.state.checkedRegionCountryList
                .length;
              i++
            ) {
              for (let j = 0; j < advanceDataList.length; j++) {
                if (
                  this.advanceSearchRef.current.state.checkedRegionCountryList[
                    i
                  ] === advanceDataList[j].countryCode
                ) {
                  selectedIndustries1.push(advanceDataList[j].country);
                }
              }
            }
            this.setState({
              advanceDataList: advanceDataList,
              paginationType: "advanceSearch",
              totalRecords: output.data.totalRecords,
              isSimpleSearchListShow: false,
              isAdvanceSearchListShow: true,
              isPagination: true,
              count: output.data.page.totalPages,
              isAdvanceSearch: this.state.isAdvanceSearch,
              isSimpleSearchBtn: false,
              rowsPerPage: output.data.page.pageSize,
              selectedOption: { value: "10", label: "10" },
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  advanceHandleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.getAdvanceSearchData();
    }
  };

  renderSimpleSearch() {
    let tickerSearchText = (
      <Row style={{ margin: "auto" }}>
        <h6 style={{ padding: 0 }}>
          <b>
            Ticker Search Results for&nbsp; "{this.state.searchText}"&nbsp;
            found {this.state.tickerCount} matches.
          </b>
        </h6>
      </Row>
    );
    let tickerTooManySearch = "";
    tickerTooManySearch = this.state.tickerCount > 100 && (
      <Row style={{ margin: "auto", fontSize: "small" }}>
        <span>
          Too many matches found - use &nbsp;
          <a
            style={{ cursor: "pointer", color: "brown" }}
            onClick={this.toAdvanceSearch}
          >
            <strong>
              <u>Advanced Search</u>
            </strong>
          </a>
          &nbsp; to narrow your search
        </span>
      </Row>
    );
    let tickerSearchList = [];
    this.state.tickerList.forEach((company, index) => {
      tickerSearchList.push(
        <CompanyShortDetails
          key={index}
          data={company}
          onClick={this.toCompanyDetails.bind(this)}
          history={this.props.history}
        />
      );
    });

    let companySearchText = (
      <Row style={{ margin: "auto" }}>
        <hr
          style={{
            boxShadow: "1px 1px 2px #1d1d1d",
          }}
        ></hr>
        <h6 style={{ padding: 0 }}>
          <b>
            Company Name search results for&nbsp; "{this.state.searchText}"
            &nbsp;found&nbsp; {this.state.companyCount}&nbsp; matches.
          </b>
        </h6>
      </Row>
    );
    let companyTooManySearch = "";
    companyTooManySearch = this.state.companyCount > 100 && (
      <Row style={{ margin: "auto", fontSize: "small" }}>
        <span>
          Too many matches found - use &nbsp;
          <a
            style={{ cursor: "pointer", color: "brown" }}
            onClick={this.toAdvanceSearch}
          >
            <strong>
              <u>Advanced Search</u>
            </strong>
          </a>
          &nbsp; to narrow your search
        </span>
      </Row>
    );
    let companySearchList = [];
    this.state.companyList.forEach((company, index) => {
      companySearchList.push(
        <CompanyShortDetails
          key={index}
          data={company}
          onClick={this.toCompanyDetails.bind(this)}
          history={this.props.history}
        />
      );
    });

    let keywordSearchText = (
      <Row style={{ margin: "auto" }}>
        <hr
          style={{
            boxShadow: "1px 1px 2px #1d1d1d",
          }}
        ></hr>
        <h6 style={{ padding: 0 }}>
          <b>
            Keyword search results for &nbsp;"{this.state.searchText}"
            &nbsp;found&nbsp;
            {this.state.keywordCount} matches.
          </b>
        </h6>
      </Row>
    );
    let keywordTooManySearch = "";
    keywordTooManySearch = this.state.keywordCount > 100 && (
      <Row style={{ margin: "auto", fontSize: "small" }}>
        <span>
          Too many matches found - use &nbsp;
          <a
            style={{ cursor: "pointer", color: "brown" }}
            onClick={this.toAdvanceSearch}
          >
            <strong>
              <u>Advanced Search</u>
            </strong>
          </a>
          &nbsp; to narrow your search
        </span>
      </Row>
    );
    let keywordSearchList = [];
    this.state.keywordList.forEach((company, index) => {
      keywordSearchList.push(
        <CompanyShortDetails
          key={index}
          data={company}
          onClick={this.toCompanyDetails.bind(this)}
          history={this.props.history}
        />
      );
    });

    return (
      <>
        <Container maxWidth="lg" style={{ marginTop: "0.5em" }}>
          <Row style={{ margin: "auto" }}>
            <h6 style={{ padding: 0 }}>
              <b>Total Results :&nbsp; {this.state.totalRecords}</b>
            </h6>
          </Row>
          {tickerSearchText}
          {tickerTooManySearch}
          {tickerSearchList}

          <Row style={{ height: "1em" }}></Row>
          {companySearchText}
          {companyTooManySearch}
          {companySearchList}

          <Row style={{ height: "1em" }}></Row>
          {keywordSearchText}
          {keywordTooManySearch}
          {keywordSearchList}
        </Container>
      </>
    );
  }
  renderAdvanceSearch() {
    let advanceDataList = [];
    if (!this.state.advanceDataList) {
      this.state.advanceDataList = this.state.tickerList;
    }
    this.state.advanceDataList.forEach((company, index) => {
      advanceDataList.push(
        <CompanyShortDetails
          key={index}
          data={company}
          onClick={this.toCompanyDetails.bind(this)}
          history={this.props.history}
        />
      );
    });
    return (
      <Container maxWidth="lg" style={{ marginTop: "0.5em" }}>
        {this.state.advanceDataList && this.state.advanceDataList.length >= 0 && (
          <Row style={{ margin: "auto" }}>
            <h6 style={{ padding: 0 }}>
              <b>Total Results :&nbsp; {this.state.totalRecords}</b>
            </h6>
          </Row>
        )}

        {advanceDataList}
        {this.state.advanceDataList.length && this.state.totalPages > 1 && (
          <Row>
            <Card style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
              <CardHeader
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                  fontSize: "small",
                  background: "#fbebed",
                }}
              >
                <span>
                  <b style={{ textAlign: "center", alignSelf: "center" }}>
                    Page {this.state.pageNo} of {this.state.totalPages}
                  </b>
                </span>
              </CardHeader>
              <CardFooter
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  background: "#fbebed",
                }}
              >
                <Col md={2}>
                  <Select
                    options={this.dropDownOptions}
                    id="advancePageSize"
                    value={this.state.selectedOption}
                    onChange={this.handleChangeAdvancePageSize}
                  ></Select>
                </Col>
                <Col md={10}>
                  <Pagination
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    boundaryCount={3}
                    count={this.state.totalPages}
                    style={{ float: "right" }}
                    page={this.state.pageNo}
                    onChange={this.pageNumberChanged}
                  />
                </Col>
              </CardFooter>
            </Card>
          </Row>
        )}
      </Container>
    );
  }
  renderAlphabeticalSearch() {
    let alphabetSearchDataList = [];
    this.state.alphabetSearchData.forEach((company, index) => {
      alphabetSearchDataList.push(
        <CompanyShortDetails
          key={index}
          data={company}
          onClick={this.toCompanyDetails.bind(this)}
          history={this.props.history}
        />
      );
    });

    return (
      <Container maxWidth="lg" style={{ marginTop: "0.5em" }}>
        <Row style={{ margin: "auto" }}>
          <h6 style={{ padding: 0 }}>
            <b>Alphabetic search: &nbsp; {this.state.selectedAlphabet}</b>
          </h6>
          <h6 style={{ padding: 0 }}>
            <b>Total Results :&nbsp; {this.state.totalRecords}</b>
          </h6>
          <hr
            style={{
              boxShadow: "1px 1px 2px #1d1d1d",
            }}
          ></hr>
        </Row>

        <Row>
          <Col md={12}>{alphabetSearchDataList}</Col>
        </Row>

        {this.state.totalRecords > this.state.pageSize && (
          <Row>
            <Card style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
              <CardHeader
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                  fontSize: "small",
                  background: "#fbebed",
                }}
              >
                <span>
                  <b style={{ textAlign: "center", alignSelf: "center" }}>
                    Page {this.state.abPageNo} of {this.state.abTotalPages}
                  </b>
                </span>
              </CardHeader>
              <CardFooter
                style={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  background: "#fbebed",
                }}
              >
                <Col md={2}>
                  <Select
                    options={this.dropDownOptions}
                    id="advancePageSize"
                    value={this.state.selectedABOption}
                    onChange={this.handleChangeABPageSize}
                  ></Select>
                </Col>
                <Col md={10}>
                  <Pagination
                    color="primary"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    boundaryCount={3}
                    count={this.state.abTotalPages}
                    style={{ float: "right" }}
                    page={this.state.abPageNo}
                    onChange={this.alphabetPageNumberChanged}
                  />
                </Col>
              </CardFooter>
            </Card>
          </Row>
        )}
      </Container>
    );
  }

  onSearchOptionChange(input) {
    if (input === "advance") {
      if (this.advanceSearchRef && this.advanceSearchRef.current) {
        this.advanceSearchRef.current.setState({
          isSimpleSearch: false,
          isAdvanceSearch: true,
          isAlphabetSearch: false,
        });
      }
      if (this.simpleSearchRef && this.simpleSearchRef.current) {
        this.simpleSearchRef.current.setState({
          isSimpleSearch: false,
          isAdvanceSearch: true,
          isAlphabetSearch: false,
        });
      }
      this.setState({
        isSimpleSearch: false,
        isAdvanceSearch: true,
        isAlphabetSearch: false,
      });
    }
    if (input === "simple") {
      if (this.advanceSearchRef && this.advanceSearchRef.current) {
        this.advanceSearchRef.current.setState({
          isSimpleSearch: true,
          isAdvanceSearch: false,
          isAlphabetSearch: false,
        });
      }
      if (this.simpleSearchRef && this.simpleSearchRef.current) {
        this.simpleSearchRef.current.setState({
          isSimpleSearch: true,
          isAdvanceSearch: false,
          isAlphabetSearch: false,
        });
      }

      this.setState({
        isSimpleSearch: true,
        isAdvanceSearch: false,
        isAlphabetSearch: false,
      });
    }
  }

  onRegionSelection(selectedCountries, selectedRegions, selectedCountryCodes) {
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.simpleSearchRef.current.setState({
        regions: selectedRegions ? selectedRegions.join(", ") : "",
        countries: selectedCountries ? selectedCountries.join(", ") : "",
        countryCodes: selectedCountryCodes
          ? selectedCountryCodes.join(", ")
          : "",
      });
    }
  }
  onSectorSelection(selectedIndustries, selectedSectors, majorIndustryCodes) {
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.simpleSearchRef.current.setState({
        sectors: selectedSectors ? selectedSectors.join(", ") : "",
        industries: selectedIndustries ? selectedIndustries.join(", ") : "",
        industryCodes: majorIndustryCodes,
        industryList: selectedIndustries,
      });
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  performSearchByStoreData(
    searchTextPar,
    regionPar,
    countryPar,
    countryCodesPar,
    sectorPar,
    industryPar,
    isAdvanceSearchPar,
    industryCodes,
    industryList,
    resetPage
  ) {
    this.setState({
      isSimpleSearch: false,
      isAdvanceSearch: true,
      isAlphabetSearch: false,
    });
    let regions = regionPar ? regionPar.split(",") : [];
    let countries = countryPar ? countryPar.split(",") : [];
    let datatype = typeof countryCodesPar;
    if (datatype === "object") {
      countryCodesPar = countryCodesPar.toString();

      // countryCodesPar = countryCodesPar.split(",");
    }
    let countryCodes =
      countryCodesPar && countryCodesPar.length > 0
        ? countryCodesPar.split(",")
        : [];
    // let pageNo = resetPage && resetPage === true ? 0 : this.state.pageNo - 1;
    const postObject = {
      searchText: searchTextPar,
      regionList: regions,
      countryCodeList: countryCodes,
      majorIndustryCodeList: industryCodes,
      industryCodeList: industryList,
      page: { pageNo: 0, pageSize: 10 },
    };
    FetchServerData.callPostService("searchService/advanceSearch", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            companyList: [],
            selectedCountryCodeList: countryCodes,
            tickerList: [],
            keywordList: [],
            advanceDataList: [],
            totalRecords: output.data.totalRecords,
            searchText: searchTextPar,
          });
          let advanceDataList = output.data.result;
          for (let i = 0; i < this.countryList.length; i++) {
            for (let j = 0; j < advanceDataList.length; j++) {
              if (
                Utils.equalsIgnoreCase(
                  this.countryList[i].name,
                  advanceDataList[j].country
                )
              ) {
                advanceDataList[j].codeCountry = this.countryList[i].alpha2;
              }
            }
          }

          let selectedIndustries1 = [];
          for (
            let i = 0;
            i <
            this.advanceSearchRef.current.state.checkedRegionCountryList.length;
            i++
          ) {
            for (let j = 0; j < advanceDataList.length; j++) {
              if (
                this.advanceSearchRef.current.state.checkedRegionCountryList[
                  i
                ] === advanceDataList[j].countryCode
              ) {
                selectedIndustries1.push(advanceDataList[j].country);
              }
            }
          }
          this.state.advanceDataList = advanceDataList;
          this.setState({
            advanceDataList: advanceDataList,
            searchText: searchTextPar,
            totalRecords: output.data.totalRecords,
            isSimpleSearchListShow: false,
            isAdvanceSearchListShow: true,
            isPagination: true,
            isSimpleSearchBtn: false,
            totalPages: output.data.page.totalPages,
            pageSize: output.data.page.pageSize,
            pageNo: output.data.page.pageNo + 1,
          });
          this.scrollToTop();
        } else {
        }
      })
      .catch((error) => {});
  }
  performSearch(
    searchTextPar,
    regionPar,
    countryPar,
    countryCodesPar,
    sectorPar,
    industryPar,
    isAdvanceSearchPar,
    industryCodes,
    industryList,
    resetPage
  ) {
    let advanceFilterUserData = {
      searchTextPar: searchTextPar,
      regionPar: regionPar,
      countryPar: countryPar,
      countryCodesPar: countryCodesPar,
      sectorPar: sectorPar,
      industryPar: industryPar,
      isAdvanceSearchPar: isAdvanceSearchPar,
      industryCodes: industryCodes,
      industryList: industryList,
      resetPage: resetPage,
    };
    store.dispatch(advanceFilterAction.setAdvanceFilter(advanceFilterUserData));

    if (isAdvanceSearchPar && isAdvanceSearchPar === true) {
      this.setState({
        isSimpleSearch: false,
        isAdvanceSearch: true,
        isAlphabetSearch: false,
      });
      let regions = regionPar ? regionPar.split(",") : [];
      let countries = countryPar ? countryPar.split(",") : [];
      let datatype = typeof countryCodesPar;
      if (datatype === "object") {
        countryCodesPar = countryCodesPar.toString();

        // countryCodesPar = countryCodesPar.split(",");
      }
      let countryCodes =
        countryCodesPar && countryCodesPar.length > 0
          ? countryCodesPar.split(",")
          : [];
      let pageNo = resetPage && resetPage === true ? 0 : this.state.pageNo - 1;
      const postObject = {
        searchText: searchTextPar,
        regionList: regions,
        countryCodeList: countryCodes,
        majorIndustryCodeList: industryCodes,
        industryCodeList: industryList,
        page: { pageNo: pageNo, pageSize: this.state.pageSize },
      };
      FetchServerData.callPostService("searchService/advanceSearch", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.setState({
              companyList: [],
              selectedCountryCodeList: countryCodes,
              tickerList: [],
              keywordList: [],
              advanceDataList: [],
              totalRecords: output.data.totalRecords,
              searchText: searchTextPar,
            });
            let advanceDataList = output.data.result;
            for (let i = 0; i < this.countryList.length; i++) {
              for (let j = 0; j < advanceDataList.length; j++) {
                if (
                  Utils.equalsIgnoreCase(
                    this.countryList[i].name,
                    advanceDataList[j].country
                  )
                ) {
                  advanceDataList[j].codeCountry = this.countryList[i].alpha2;
                }
              }
            }

            let selectedIndustries1 = [];
            for (
              let i = 0;
              i <
              this.advanceSearchRef.current.state.checkedRegionCountryList
                .length;
              i++
            ) {
              for (let j = 0; j < advanceDataList.length; j++) {
                if (
                  this.advanceSearchRef.current.state.checkedRegionCountryList[
                    i
                  ] === advanceDataList[j].countryCode
                ) {
                  selectedIndustries1.push(advanceDataList[j].country);
                }
              }
            }
            this.state.advanceDataList = advanceDataList;
            this.setState({
              advanceDataList: advanceDataList,
              searchText: searchTextPar,
              totalRecords: output.data.totalRecords,
              isSimpleSearchListShow: false,
              isAdvanceSearchListShow: true,
              isPagination: true,
              isSimpleSearchBtn: false,
              totalPages: output.data.page.totalPages,
              pageSize: output.data.page.pageSize,
              pageNo: output.data.page.pageNo + 1,
            });
            this.scrollToTop();
          } else {
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        isSimpleSearch: true,
        isAdvanceSearch: false,
        isAlphabetSearch: false,
      });
      /*Simple Search */
      const postObject = {
        searchText: searchTextPar,
      };
      FetchServerData.callPostService("searchService/simpleSearch", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.state.tickerList = [];
            this.state.companyList = [];
            this.state.keywordList = [];
            this.setState({
              companyList: [],
              tickerList: [],
              keywordList: [],
              tickerCount: output.data.tickerCount,
              keywordCount: output.data.keywordCount,
              companyCount: output.data.companyCount,
              totalRecords: output.data.totalRecords,
              searchText: searchTextPar,
            });
            let tickerList = output.data.tickerList;
            for (let i = 0; i < this.countryList.length; i++) {
              for (let j = 0; j < tickerList.length; j++) {
                if (
                  Utils.equalsIgnoreCase(
                    this.countryList[i].name,
                    tickerList[j].country
                  )
                ) {
                  tickerList[j].codeCountry = this.countryList[i].alpha2;
                }
              }
            }

            let keywordList = output.data.keywordList;
            for (let i = 0; i < this.countryList.length; i++) {
              for (let j = 0; j < keywordList.length; j++) {
                if (
                  Utils.equalsIgnoreCase(
                    this.countryList[i].name,
                    keywordList[j].country
                  )
                ) {
                  keywordList[j].codeCountry = this.countryList[i].alpha2;
                }
              }
            }

            let companyList = output.data.companyList;
            for (let i = 0; i < this.countryList.length; i++) {
              for (let j = 0; j < companyList.length; j++) {
                if (
                  Utils.equalsIgnoreCase(
                    this.countryList[i].name,
                    companyList[j].country
                  )
                ) {
                  companyList[j].codeCountry = this.countryList[i].alpha2;
                }
              }
            }
            this.setState({
              companyList: companyList,
              tickerList: tickerList,
              keywordList: keywordList,
              abPageSize: 10,
              selectedOption: { value: "10", label: "10" },
            });
            this.props.history.push({
              pathname: "/search",
              state: {
                searchText: this.state.searchText,
                searchType: "simple search",
              },
            });
            this.scrollToTop();

            var href = new URL(this.state.pageParameterUrl);
            href.searchParams.set("searchText", this.state.searchText);
          } else {
          }
        })
        .catch((error) => {});
    }
  }
  onResetAdvanceSearch() {
    if (this.simpleSearchRef && this.simpleSearchRef.current) {
      this.setState({
        advanceDataList: [],
        totalRecords: "",
      });
      this.simpleSearchRef.current.resetAdvanceSearch();
    }
  }
  render() {
    let advanceComponent = (
      <AdvanceSearch
        ref={this.advanceSearchRef}
        isAdvanceSearch={this.state.isAdvanceSearch}
        isSimpleSearch={this.state.isSimpleSearch}
        isAlphabetSearch={this.state.isAlphabetSearch}
        data={this.state.advanceSearch}
        onCancel={this.cancelAdvanceSearch.bind(this)}
        onAdd={this.getAdvanceSearchData.bind(this)}
        onOptionChange={this.onSearchOptionChange.bind(this)}
        onRegionSelection={this.onRegionSelection.bind(this)}
        onSectorSelection={this.onSectorSelection.bind(this)}
        onResetSearch={this.onResetAdvanceSearch.bind(this)}
        onApplyFilter={this.applyAdvanceFilter.bind(this)}
      />
    );

    let alphabeticalList = [];
    this.state.AlphabeticalList.forEach((alphabet, index) => {
      alphabeticalList.push(
        <>
          <a
            onClick={this.getCompanyListByAlphabetFromPage1.bind(
              this,
              alphabet
            )}
            className="alphabetList"
          >
            {alphabet}
          </a>
          &nbsp;|&nbsp;
        </>
      );
    });
    return (
      <>
        <Row className="breadcrumbs_fix_top_all">
          <section className="breadcrumbs">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>Search Results </h4>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
          </section>
        </Row>
        <Row style={{ marginTop: "5em", minHeight: "100vh" }}>
          {/* <Row className="pts_mobile_view"> */}
          <Row style={{ paddingRight: "0" }}>
            <Card style={{ paddingRight: "0" }}>
              <CardHeader style={{ marginTop: "0.5em" }}>
                <SimpleSearch
                  ref={this.simpleSearchRef}
                  filterData={this.state.filterData}
                  searchText={this.state.searchText}
                  isAdvanceSearch={this.state.isAdvanceSearch}
                  isSimpleSearch={this.state.isSimpleSearch}
                  isAlphabetSearch={this.state.isAlphabetSearch}
                  onOptionChange={this.onSearchOptionChange.bind(this)}
                  onSearch={this.performSearch.bind(this)}
                />
              </CardHeader>
              <CardBody>
                {this.state.isSimpleSearch &&
                  this.state.isSimpleSearch === true && (
                    <Row md={12} style={{ display: "flex" }}>
                      <Col>{this.renderSimpleSearch()}</Col>
                    </Row>
                  )}
                {this.state.isAdvanceSearch &&
                  this.state.isAdvanceSearch === true && (
                    <Row md={12} style={{ display: "flex" }}>
                      {advanceComponent}
                      <Col>{this.renderAdvanceSearch()}</Col>
                    </Row>
                  )}
                {this.state.isAlphabetSearch &&
                  this.state.isAlphabetSearch === true && (
                    <Row md={12} style={{ display: "flex" }}>
                      <Col>{this.renderAlphabeticalSearch()}</Col>
                    </Row>
                  )}
              </CardBody>
              <CardFooter>
                <Row style={{ padding: "0.5em" }}>
                  <span style={{ textAlign: "center" }}>
                    <b>Individual Company SnapShots Listed Alphabetically</b>
                  </span>
                </Row>
                <Row style={{ padding: "0.5em" }}>
                  <span style={{ textAlign: "center" }}>
                    {" "}
                    {alphabeticalList}
                  </span>
                </Row>
              </CardFooter>
            </Card>
          </Row>
        </Row>
      </>
    );
  }
}
