import PrintIcon from "@material-ui/icons/Print";
// import { countries } from "country-data";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import { Radar } from "react-chartjs-2";
import "react-tabs/style/react-tabs.css";
import { Button, Card, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import BalanceSheet from "../balanceSheet/BalanceSheet";
import CompanyFinancial from "../companyFinancial/CompanyFinancial";
import ComparativeBusinessAnalysis from "../comparativeBusinessAnalysis/ComparativeBusinessAnalysis";
import EarningAndDividends from "../earningAndDividends/EarningAndDividends";
import AccountingRatios from "../financialRatios/accountingRatios/AccountingRatios";
import AssetUtilization from "../financialRatios/assetUtilization/AssetUtilization";
import EmployeeEfficiency from "../financialRatios/EmployeeEfficiency";
import FixedChargesCoverage from "../financialRatios/FixedChargesCoverage";
import LeverageAnalysis from "../financialRatios/LeverageAnalysis";
import LiquidityAnalysis from "../financialRatios/liquidityAnalysis/LiquidityAnalysis";
import PerShareData from "../financialRatios/PerShareData";
import ProfitabilityAnalysis from "../financialRatios/ProfitabilityAnalysis";
import CashBasis from "../flowOfFunds/CashBasis";
import FlowOfFunds from "../flowOfFunds/FlowOfFunds";
import PerFasb from "../flowOfFunds/PerFasb";
import Ratios from "../flowOfFunds/Ratios";
import InterimBalanceSheet from "../interimBalanceSheet/InterimBalanceSheet";
import InterimIncomeStatement from "../interimIncomeStatement/InterimIncomeStatement";
import PriceAnalysis from "../priceAnalysis/PriceAnalysis";
import SalesAnalysis from "../salesAnalysis/SalesAnalysis";
import SourceOfCapital from "../sourceOfCapital/SourceOfCapital";
import SummaryAnalysisPerShare from "../summaryAnalysisPerShare/SummaryAnalysisPerShare";
import CorporateGrowth from "../wrightQualityAnalyses/CorporateGrowth";
import FinancialStrength from "../wrightQualityAnalyses/FinancialStrength";
import InvestmentAcceptance from "../wrightQualityAnalyses/InvestmentAcceptance";
import ProfitabilityStability from "../wrightQualityAnalyses/ProfitabilityStability";
import "./PdfReport.css";
export default class PdfReport extends Component {
  snowFlexChartRef = React.createRef();
  constructor(props) {
    super(props);
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let previousYear = currentDate.getFullYear() - 1;
    let isUserActive = true;
    if (this.userData) {
      if (this.userData.activeUser === false) {
        isUserActive = false;
      }
    }
    let countries = Utils.getCountryData();
    this.state = {
      isUserActive: isUserActive,
      countryList: countries,
      cusip: props.cusip,
      currentYear: currentYear,
      previousYear: previousYear,
      companyDetails: {},
      snapshot: "",
      epsDpsData: [],
      priceData: [],
      companyName: "",
      ticker: "",
      render: false,
      datasets: {},
      showModal1: false,
      series: [],
      cpClassName: "active",
      smClassName: "",
      saClassName: "",
      edClassName: "",
      pClassName: "",
      sopClassName: "",
      cfClassName: "",
      bsClassName: "",
      waClassName: "",
      cmpSnapshot: "company_Info",
      isReadMoreBtn: true,
      currentClassName: "active",
      dropdown: "dropdownClose",
      className: "default_select",
      sidebar: "sidebar",
      showModal: false,
      company_name: "company_Name",
      caretDown: true,
      caretUp: false,
      balanceSheetActualData: [],
      sourceOfCapitalData: {},
      countryName: "",
      currency: "",
    };
    if (this.state.cusip) {
      this.getSnapShotDetails();
    }
  }

  getSnapShotDetails() {
    const postObject = {
      cusip: this.state.cusip,
    };
    FetchServerData.callPostService("company/fundamentalReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let summaryAnalysis = {};
          let salesAnalysis = {};
          let priceAnalysis = {};
          let earningsAndDividend = {};
          let grRatingPts = 0;
          let psRatingPts = 0;
          let fsRatingPts = 0;
          let iaRatingPts = 0;
          let backgroundColor = "";
          let borderColor = "";
          let wrightRatings = "";
          if (output.data.companySnashot.wrightRatings !== null) {
            wrightRatings =
              output.data.companySnashot.wrightRatings.compositeRating;
            if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Not Rated"
            ) {
              backgroundColor = "rgba(  243, 25, 25, 0.8)";
              borderColor = "#ff0000";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Poor"
            ) {
              backgroundColor = "rgba(235, 152, 78  , 0.9)";
              borderColor = "#af601a";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Fair"
            ) {
              backgroundColor = "rgba(  242, 242, 0 , 0.8)";
              borderColor = "#ffff00";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Good"
            ) {
              backgroundColor = "rgba(  77, 171, 99 , 0.8)";
              borderColor = "#58D68D";
            } else if (
              output.data.companySnashot.wrightRatings.compositeRating ===
              "Excellent"
            ) {
              backgroundColor = "rgba(  44, 92, 202,0.8)";
              borderColor = " #5dade2";
            }
            grRatingPts = output.data.companySnashot.wrightRatings.grRatingPts;
            psRatingPts = output.data.companySnashot.wrightRatings.psRatingPts;
            fsRatingPts = output.data.companySnashot.wrightRatings.fsRatingPts;
            iaRatingPts = output.data.companySnashot.wrightRatings.iaRatingPts;
          }
          if (output.data) {
            summaryAnalysis = output.data.summaryAnalysis;
            salesAnalysis = output.data.salesAnalysis;
            priceAnalysis = output.data.priceAnalysis;
            earningsAndDividend = output.data.earningsAndDividend;
          }
          let quarterlyEarningList =
            output.data.companySnashot.quarterlyEarningList;
          let quarterlyDividendList =
            output.data.companySnashot.quarterlyDividendList;
          let prices = output.data.companySnashot.priceData;
          let quarterlyEarningValues = [];
          let quarterlyDividendValues = [];
          let priceValues = [];
          let earningsDividendLabel = [];
          let quarterlyEarning = "";
          let quarterlyDividend = "n.a.";
          if (quarterlyEarningList.length !== 0) {
            for (let i = 0; i < quarterlyEarningList.length; i++) {
              quarterlyEarningValues.push(quarterlyEarningList[i].eps);
              quarterlyEarning = quarterlyEarningList[0].eps;

              earningsDividendLabel.push(
                moment(quarterlyEarningList[i].date).format("YYYY MMM")
              );
            }
          }
          if (quarterlyDividendList.length !== 0) {
            for (let i = 0; i < quarterlyDividendList.length; i++) {
              quarterlyDividend = quarterlyDividendList[0].dps;
              quarterlyDividendValues.push(quarterlyDividendList[i].dps);
            }
          }

          for (let i = 0; i < prices.length; i++) {
            let obj1 = {
              x: prices[i].year + " JAN",
              y: [
                prices[i].highJan,
                prices[i].highJan,
                prices[i].lowJan,
                prices[i].lowJan,
              ],
            };
            let obj2 = {
              x: prices[i].year + " FEB",
              y: [
                prices[i].highFeb,
                prices[i].highFeb,
                prices[i].lowFeb,
                prices[i].lowFeb,
              ],
            };
            let obj3 = {
              x: prices[i].year + " MAR",
              y: [
                prices[i].highMar,
                prices[i].highMar,
                prices[i].lowMar,
                prices[i].lowMar,
              ],
            };
            let obj4 = {
              x: prices[i].year + " APR",
              y: [
                prices[i].highApr,
                prices[i].highApr,
                prices[i].lowApr,
                prices[i].lowApr,
              ],
            };
            let obj5 = {
              x: prices[i].year + " MAY",
              y: [
                prices[i].highMay,
                prices[i].highMay,
                prices[i].lowMay,
                prices[i].lowMay,
              ],
            };
            let obj6 = {
              x: prices[i].year + " JUN",
              y: [
                prices[i].highJun,
                prices[i].highJun,
                prices[i].lowJun,
                prices[i].lowJun,
              ],
            };
            let obj7 = {
              x: prices[i].year + " JUL",
              y: [
                prices[i].highJul,
                prices[i].highJul,
                prices[i].lowJul,
                prices[i].lowJul,
              ],
            };
            let obj8 = {
              x: prices[i].year + " AUG",
              y: [
                prices[i].highAug,
                prices[i].highAug,
                prices[i].lowAug,
                prices[i].lowAug,
              ],
            };
            let obj9 = {
              x: prices[i].year + " SEP",
              y: [
                prices[i].highSep,
                prices[i].highSep,
                prices[i].lowSep,
                prices[i].lowSep,
              ],
            };
            let obj10 = {
              x: prices[i].year + " OCT",
              y: [
                prices[i].highOct,
                prices[i].highOct,
                prices[i].lowOct,
                prices[i].lowOct,
              ],
            };
            let obj11 = {
              x: prices[i].year + " NOV",
              y: [
                prices[i].highNov,
                prices[i].highNov,
                prices[i].lowNov,
                prices[i].lowNov,
              ],
            };
            let obj12 = {
              x: prices[i].year + " DEC",
              y: [
                prices[i].highDec,
                prices[i].highDec,
                prices[i].lowDec,
                prices[i].lowDec,
              ],
            };
            priceValues.push(obj1);
            priceValues.push(obj2);
            priceValues.push(obj3);
            priceValues.push(obj4);
            priceValues.push(obj5);
            priceValues.push(obj6);
            priceValues.push(obj7);
            priceValues.push(obj8);
            priceValues.push(obj9);
            priceValues.push(obj10);
            priceValues.push(obj11);
            priceValues.push(obj12);
          }
          let countryCode = "";
          let countryName = "";

          for (let i = 0; i < this.state.countryList.length; i++) {
            if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              this.state.countryList[i].name.toUpperCase()
            ) {
              countryCode = this.state.countryList[i].alpha2;
              countryName = this.state.countryList[i].name;
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "KOREA (SOUTH)"
            ) {
              countryCode = "410";
              countryName = "KOREA (SOUTH)";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "VIETNAM"
            ) {
              countryName = "VIETNAM";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "COTE D'IVOIRE"
            ) {
              countryName = "COTE D'IVOIRE";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "PALESTINE"
            ) {
              countryName = "PALESTINE";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "MACEDONIA"
            ) {
              countryName = "MACEDONIA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "TANZANIA"
            ) {
              countryName = "TANZANIA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "VENEZUELA"
            ) {
              countryName = "VENEZUELA";
            } else if (
              output.data.companySnashot.companyInfo.country.toUpperCase() ===
              "BOLIVIA"
            ) {
              countryName = "BOLIVIA";
            }
          }
          let companyInfo = output.data.companySnashot.companyInfo;

          this.setState({
            currency: priceAnalysis.currency,
            wqr: wrightRatings,
            companyDetails: companyInfo,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            summaryAnalysis: summaryAnalysis,
            salesAnalysis: salesAnalysis,
            priceAnalysis: priceAnalysis,
            earningsAndDividend: earningsAndDividend,
            ratioAnalysisData: output.data ? output.data.companySnashot : {},
            quarterlyEarning: quarterlyEarning,
            quarterlyDividend: quarterlyDividend,
            snapshot: output.data ? output.data.companySnashot.snapshot : {},
            priceData: output.data ? output.data.companySnashot.priceData : {},
            countryName: countryName,
            countryCode: countryCode,

            radarData: [iaRatingPts, fsRatingPts, psRatingPts, grRatingPts],
            series: [
              {
                name: "Earnings",
                type: "bar",
                data: quarterlyEarningValues,
              },
              {
                name: "Dividends",
                type: "bar",
                data: quarterlyDividendValues,
              },
            ],
            options: {
              chart: {
                height: 350,
                type: "line",
                stacked: false,
                toolbar: {
                  show: false,
                },
              },

              grid: {
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              title: {
                text: output.data.companySnashot.companyInfo.name,
                align: "left",
              },
              stroke: {
                width: [0, 2, 5],
                curve: "smooth",
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [
                      {
                        to: 0,
                        from: -100000000000,
                        color: "#F15B46",
                      },
                    ],
                  },
                  columnWidth: "50%",
                },
              },
              colors: ["#ff7f0e", "#1f77b4"],

              legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "top",
                horizontalAlign: "center",
                floating: false,
                fontSize: "14px",
                fontFamily: "Helvetica, Arial",
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                offsetX: 0,
                offsetY: 0,
                labels: {
                  colors: ["#7d6608", "#0b5345"],
                  useSeriesColors: true,
                },
              },

              labels: earningsDividendLabel,

              xaxis: {
                title: {
                  text: "Year",
                  style: {
                    colors: "#808b96",
                  },
                  labels: {
                    style: {
                      colors: "#808b96 ",
                    },
                  },
                },

                axisBorder: {
                  show: true,
                  color: "#808b96",
                  height: 1,
                  width: "100%",
                  offsetX: 0,
                  offsetY: 0,
                },
              },
              yaxis: [
                {
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: "#247BA0",
                  },
                  labels: {
                    style: {
                      colors: "#247BA0",
                    },
                  },
                  title: {
                    text: "Earnings",
                    style: {
                      color: "#247BA0",
                    },
                  },
                },
              ],

              tooltip: {
                shared: true,
                intersect: false,
              },
            },
          });
          this.getFinancialRatioReportData();
        }
      })
      .catch((error) => {});
  }

  getFinancialRatioReportData() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("financialRatio/getAllReports", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let accountingRatio = {};
          let assetUtilizationRatio = {};
          let employeeEfficiencyRatio = {};
          let fixChargeCoverageRatio = {};
          let leverageAnalysisRatio = {};
          let perShareProfitRatio = {};
          let profitabilityAnalysisRatio = {};
          let liquidityAnalysisRatio = {};
          if (data) {
            accountingRatio = data.accountingRatio;
            assetUtilizationRatio = data.assetUtilizationRatio;
            employeeEfficiencyRatio = data.employeeEfficiencyRatio;
            fixChargeCoverageRatio = data.fixChargeCoverageRatio;
            leverageAnalysisRatio = data.leaverageAnalysisRatio;
            perShareProfitRatio = data.perShareProfitRatio;
            profitabilityAnalysisRatio = data.profitabilityAnalysisRatio;
            liquidityAnalysisRatio = data.liquidityAnalysisRatio;
          }

          this.currency = data.currency;
          this.unit = data.unit;
          this.setState({
            render: true,
            accountingRatio: accountingRatio,
            assetUtilizationRatio: assetUtilizationRatio,
            employeeEfficiencyRatio: employeeEfficiencyRatio,
            fixChargeCoverageRatio: fixChargeCoverageRatio,
            leverageAnalysisRatio: leverageAnalysisRatio,
            perShareProfitRatio: perShareProfitRatio,
            profitabilityAnalysisRatio: profitabilityAnalysisRatio,
            liquidityAnalysisRatio: liquidityAnalysisRatio,
          });
          this.getFlowOfFundReportData();
        } else {
        }
      })
      .catch((error) => {});
  }

  prepareReportContent() {
    this.setState({ isImageSet: true });
  }
  printContent() {
    if (this.state.isImageSet === true) {
      let printContents = document.getElementById("printReport").innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      document.title = this.state.companyDetails.name;
      window.print();

      document.body.innerHTML = originalContents;
      this.setState({ render: true });
      document.location.reload(true);
    }
  }

  getSnowFlexImage() {
    if (
      this.snowFlexChartRef.current &&
      this.state.isImageSet &&
      this.state.isImageSet === true
    ) {
      let data = this.snowFlexChartRef.current.chartInstance.toBase64Image();
      return (
        <img id="snowFlakeImageGraph" src={data} alt="snowFlakeImageGraph" />
      );
    } else {
      return (
        <Radar
          id="snowFlakeGraph"
          ref={this.snowFlexChartRef}
          data={{
            labels: [
              "Investment Acceptance",
              "Financial Strength",
              " Profitability & Stability",
              " Growth",
            ],
            datasets: [
              {
                label: this.state.wqr,
                backgroundColor: this.state.backgroundColor,
                borderColor: this.state.borderColor,
                borderJoinStyle: "round",

                borderDashOffset: "5",
                lineTension: "0.5",
                fontColor: "#fff",

                fill: true,
                chart: {
                  height: 350,
                  type: "radar",
                },
                scaleLineWidth: 16,
                scaleLineColor: "#000",
                data: this.state.radarData,
                borderWidth: 3,
              },
            ],
          }}
          style={{ margin: "0 auto" }}
          options={{
            scale: {
              gridLines: {
                circular: true,
                color: "#7B7D7D ",
                backdropColor: "#000",
              },
              pointLabels: {
                fontColor: "#808b96",
              },
              ticks: {
                beginAtZero: true,
                backdropColor: "transparent",
                fontColor: "#000",
                display: false,
              },
              chartArea: {
                backgroundColor: "rgba (247, 247, 246)",
              },
            },

            scaleLineWidth: 16,

            stroke: {
              show: true,
              width: 6,
              colors: [],
              dashArray: 0,
            },
            pointStyle: "circle",
            legend: {
              display: true,
              position: "bottom",
              align: "middle",
              labels: {
                fontColor: "#808b96",
                fontSize: 16,
                fontStyle: "bold",
              },
            },
          }}
        />
      );
    }
  }

  getButtonOnState() {
    if (this.state.isImageSet && this.state.isImageSet === true) {
      return (
        <Button
          style={{
            background: "#f58d51",
            fontSize: "0.8em",
            float: "right",
            webkitBorderRadius: "10px",

            margin: "auto",
            marginRight: "5px",
            borderBottom: "2px solid #636262",
          }}
          onClick={(e) => this.printContent(e)}
          onclick="document.title=My new title'; window.print(); return false;"
        >
          <PrintIcon style={{ fontSize: "1.3em" }} /> Print
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            background: "#f58d51",
            fontSize: "0.8em",

            webkitBorderRadius: "10px",

            float: "right",
            margin: "auto",
            marginRight: "5px",
            borderBottom: "2px solid #636262",
          }}
          onClick={(e) => this.prepareReportContent(e)}
        >
          <PrintIcon style={{ fontSize: "1.3em" }} /> Prepare Report
        </Button>
      );
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      return (
        <>
          <Row>
            <Col md={12}>{this.getButtonOnState()}</Col>
          </Row>

          <Card id="printReport" className="pdf_print">
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col md={12} style={{ textAlign: "center", marginTop: "1.2em" }}>
                <h3
                  style={{
                    color: "#d35400",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  className="font-weight-bold mb-0"
                >
                  {this.state.companyDetails.name}&nbsp;
                  <b>({this.state.companyDetails.ticker})</b>
                </h3>
                <small
                  className="text-secondary highlight_text"
                  style={{ display: "block" }}
                >
                  <a
                    href="/company_rating"
                    className="unit_str1"
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    target="_blank"
                  >
                    Wright Quality Rating:{" "}
                    <span>
                      <b>{this.state.companyDetails.q_rat}</b>
                    </span>
                  </a>
                </small>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}> </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "50%" }}>
                <Row style={{ width: "100%" }}>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>Ticker:</label>

                    <h6>{this.state.companyDetails.ticker}</h6>
                  </Col>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>
                      {this.state.companyDetails.salesYear} Sales
                    </label>
                    <h6>
                      {this.state.companyDetails.sales === "0" ||
                      this.state.companyDetails.sales === null ||
                      this.state.companyDetails.sales === undefined ? (
                        <h6 title="Not Available"> n/a</h6>
                      ) : (
                        <h6>
                          {this.state.companyDetails.sales
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h6>
                      )}
                    </h6>
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Major Industry:
                    </label>
                    <h6>{this.state.companyDetails.majIndustryName}</h6>
                  </Col>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>Sub Industry:</label>
                    <h6>{this.state.companyDetails.miniIndustryName}</h6>
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>Country:</label>
                    <h6>{this.state.companyDetails.country}</h6>
                  </Col>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>Currency</label>
                    <h6>{this.state.companyDetails.unit}</h6>
                  </Col>
                </Row>
                <Row style={{ width: "100%" }}>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Fiscal Year Ends:
                    </label>
                    <h6>{this.state.companyDetails.fis_end}</h6>
                  </Col>
                  <Col style={{ width: "50%" }}>
                    <label style={{ fontWeight: "bold" }}>Employees:</label>
                    <div>
                      {" "}
                      {this.state.companyDetails.employees === "0" ||
                      this.state.companyDetails.employees === undefined ||
                      this.state.companyDetails.employees === null ? (
                        <h6 title="Not Available"> n/a</h6>
                      ) : (
                        <h6>
                          {this.state.companyDetails.employees
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h6>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ width: "50%", padding: "1em" }}>
                <main> {this.getSnowFlexImage()}</main>
              </Col>
            </Row>

            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>Exchanges:</label>
                <h6>{this.state.companyDetails.exchanges}</h6>
              </Col>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>Share Type:</label>
                <h6>{this.state.companyDetails.shr_type}</h6>
              </Col>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>
                  Market Capitalization:
                </label>
                {this.state.companyDetails.cur_mcap === "0" ||
                this.state.companyDetails.cur_mcap === undefined ||
                this.state.companyDetails.cur_mcap === null ? (
                  <h6 title="Not Available"> n/a</h6>
                ) : (
                  <h6>
                    {this.state.companyDetails.cur_mcap
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h6>
                )}
              </Col>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>
                  Total Shares Outstanding:
                </label>
                <h6>
                  {this.state.companyDetails.com_shr
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h6>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>
                  Closely Held Shares:
                </label>
                <h6>
                  {this.state.companyDetails.cls_hld
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h6>
              </Col>
              <Col style={{ width: "25%" }}>
                <label style={{ fontWeight: "bold" }}>
                  Current Stock Price:
                </label>
                <h6>
                  {moment(this.state.companyDetails.cur_priceDate).format(
                    "MM/DD/YYYY"
                  ) +
                    ": " +
                    this.state.companyDetails.cur_prc}
                </h6>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}> </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "30%" }}>
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Recent stock performance
                </b>
                <h6 style={{ margin: "0.8em 0em 0.5em 0em" }}>
                  {"1 Week:  " + this.state.companyDetails.trend1week + "%"}
                </h6>
                <h6>
                  {"4 Week:  " + this.state.companyDetails.trend4week + "%"}
                </h6>
                <h6>
                  {"13 Week:  " + this.state.companyDetails.trend13week + "%"}
                </h6>
                <h6>
                  {"52 Week:  " + this.state.companyDetails.trend52week + "%"}
                </h6>
              </Col>
              <Col style={{ width: "30%" }}>
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Ratio Analysis
                </b>

                <h6 style={{ margin: "0.7em 0em 0.5em 0em" }}>
                  {"Price / Earnings Ratio:  " +
                    this.state.ratioAnalysisData.peRatio +
                    "x"}
                </h6>
                <h6>
                  {"Price / Sales Ratio:  " +
                    this.state.ratioAnalysisData.priceSalesRatio +
                    "x"}
                </h6>
                <h6>
                  {"Price / Book Ratio:  " +
                    this.state.ratioAnalysisData.priceBookRatio +
                    "x"}
                </h6>
                <h6>
                  {"Dividend Yield:  " +
                    this.state.ratioAnalysisData.dividendYield +
                    "%"}
                </h6>
                <h6>
                  {"Payout Ratio:  " +
                    this.state.ratioAnalysisData.payoutRatio +
                    "%"}
                </h6>
                <h6>
                  {"% Held by Insiders:  " +
                    this.state.ratioAnalysisData.heldByInsider +
                    "%"}
                </h6>
              </Col>
              <Col style={{ width: "40%" }}>
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Earnings / Dividends
                </b>
                <div className="earn_dividend">
                  <table
                    width="90%"
                    cellspacing="0"
                    cellpadding="0"
                    border="1"
                    className="pdf_earn_dividend_table"
                  >
                    <tbody>
                      <tr>
                        <th></th>
                        <th>Earnings</th>
                        <th>Dividends</th>
                      </tr>
                      <tr>
                        <td>Most Recent Qtr</td>
                        <td align="left">{this.state.quarterlyEarning}</td>
                        <td align="left">{this.state.quarterlyDividend}</td>
                      </tr>
                      <tr>
                        <td>Last 12 Months</td>
                        <td align="left">
                          {this.state.companyDetails._2me_cr}
                        </td>
                        <td align="left">
                          {this.state.companyDetails._2mdv_cr}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}> </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "100%" }}>
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Officers
                </b>
              </Col>
            </Row>

            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "50%" }}>
                <label>{this.state.companyDetails.off_1a}</label>
                <h6 style={{ fontWeight: "bold" }}>
                  {this.state.companyDetails.off_1}
                </h6>
              </Col>
              <Col style={{ width: "50%" }}>
                <label>{this.state.companyDetails.off_2a}</label>
                <h6 style={{ fontWeight: "bold" }}>
                  {this.state.companyDetails.off_2}
                </h6>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "50%" }}>
                {this.state.companyDetails.off_3a === null ? (
                  <label style={{ display: "none" }}> </label>
                ) : (
                  <label>{this.state.companyDetails.off_3a}</label>
                )}
                {this.state.companyDetails.off_3 === null ? (
                  <h6 style={{ display: "none" }}> </h6>
                ) : (
                  <h6 style={{ fontWeight: "bold" }}>
                    {this.state.companyDetails.off_3}
                  </h6>
                )}
              </Col>
              <Col style={{ width: "50%" }}>
                {this.state.companyDetails.off_4a === null ? (
                  <label style={{ display: "none" }}> </label>
                ) : (
                  <label>{this.state.companyDetails.off_4a}</label>
                )}
                {this.state.companyDetails.off_4 === null ? (
                  <h6 style={{ display: "none" }}> </h6>
                ) : (
                  <h6 style={{ fontWeight: "bold" }}>
                    {this.state.companyDetails.off_4}
                  </h6>
                )}
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "50%" }}>
                {this.state.companyDetails.off_5a === null ? (
                  <label style={{ display: "none" }}> </label>
                ) : (
                  <label>{this.state.companyDetails.off_5a}</label>
                )}

                {this.state.companyDetails.off_5 === null ? (
                  <h6 style={{ display: "none" }}> </h6>
                ) : (
                  <h6 style={{ fontWeight: "bold" }}>
                    {this.state.companyDetails.off_5}
                  </h6>
                )}
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "100%" }}>
                {" "}
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Address
                </b>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }}>
              <Col style={{ width: "50%" }}>
                <h6>{this.state.companyDetails.addr_1}</h6>
                <h6>
                  {this.state.companyDetails.addr_2},
                  {" " + this.state.companyDetails.addr_3}
                </h6>

                <h6>{this.state.companyDetails.country}</h6>
                <h6>{this.state.companyDetails.addr_4}.</h6>
              </Col>
              <Col style={{ width: "50%" }}>
                <label>Phone </label>
                <h6>{this.state.companyDetails.phone}</h6>
                <label>Home Page</label>
                <br></br>
                <b>
                  <a
                    href={this.state.companyDetails.webAddress}
                    target="_blank"
                    rel="noreferrer"
                    className="cmp_website"
                  >
                    {this.state.companyDetails.webAddress}
                  </a>
                </b>
              </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1em" }} className="ptsPage">
              <Col
                md={12}
                style={{ width: "100%", margin: "22px 0px 0px -4px" }}
              >
                <b
                  style={{
                    color: "#f5b041",
                    textDecoration: "underline",
                  }}
                >
                  Company Snapshot
                </b>
                <a
                  href="/company_rating"
                  className="unit_str1"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  target="_blank"
                ></a>
                <p>{this.state.snapshot}</p>
              </Col>
            </Row>

            <Row style={{ width: "100%", margin: "1em" }}>
              <Col md={12}>
                <Row
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #EAEDED",
                  }}
                >
                  <Col md={12}>
                    <h5 className="section_header">
                      {" "}
                      &nbsp;Earnings & Dividends
                    </h5>
                  </Col>
                </Row>
                <div id="chart">
                  <ReactApexCharts
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={350}
                  />
                </div>
              </Col>
            </Row>

            <div className="ptsPage">
              <span>
                <span className="section_header" style={{ marginLeft: "1em" }}>
                  {" "}
                  Comparative Business Analysis
                </span>
              </span>

              <ComparativeBusinessAnalysis
                data={this.state.cusip}
                competitor="false"
              ></ComparativeBusinessAnalysis>
            </div>
            <br></br>
            <br></br>
            <div className="ptsPage">
              <SummaryAnalysisPerShare
                data={this.state.summaryAnalysis}
                isPdfReport="true"
              ></SummaryAnalysisPerShare>
            </div>

            <div className="ptsPage">
              <SalesAnalysis
                data={this.state.salesAnalysis}
                isPdfReport="true"
              ></SalesAnalysis>
            </div>
            <div className="" style={{ marginTop: "38em" }}>
              <span>
                <span className="section_header">Price Analysis</span>
              </span>
              <PriceAnalysis
                data={this.state.priceAnalysis}
                isPdfReport="true"
              ></PriceAnalysis>
            </div>
            <div className="ptsPage">
              <EarningAndDividends
                data={this.state.earningsAndDividend}
                isPdfReport="true"
              ></EarningAndDividends>
            </div>

            <div style={{ padding: "20px" }} className="ptsPage">
              <span>
                <span className="section_header">
                  Wright Quality Rating <span>&#174;</span>
                </span>
              </span>
              <p>
                Since 1970, Wright Investors' Service has rated all of the
                companies in its database (when there is sufficient information
                available). The Wright Quality Rating measures the overall
                investment quality of a company. Wright Quality Ratings are
                based on numerous individual measures of quality, grouped into
                four principle components: (1) Investment Acceptance (i.e. stock
                liquidity), (2) Financial Strength, (3) Profitablilty &
                Stability, and (4) Growth. The ratings are based on established
                principles using 5-6 years of corporate record and other
                investment data.
              </p>

              <p>
                The ratings consist of three letters and a number. Each letter
                reflects a composite qualitative measurement of numerous
                individual standards which may be summarized as follows:
              </p>
              <p>
                <span>A</span> = Outstanding; <span>B</span> = Excellent;{" "}
                <span>C</span> = Good; <span>D</span> = Fair; <span>L</span> =
                Limited; <span>N</span> = Not Rated
              </p>
              <p>
                The number component of the Quality Rating is also a composite
                measurement of the annual corporate growth, based on earnings
                and modified by growth rates of equity, dividends, and sales per
                common share. The Growth rating may vary from 0 (lowest) to 20
                (highest). (See sample Quality Rating below.)
              </p>
              <Row>
                <Col md={12}>
                  <img
                    src="/home/assets/img/pdf_report_wqr.png"
                    alt="pdf_report_wqr"
                  ></img>
                </Col>
              </Row>
              <p>
                The highest quality rating assigned by Wright is AAA20. This
                rating would be assigned to a company that has a large and broad
                base of shareholders, an outstanding balance sheet, and strong
                and stable profitability. The company would also have
                experienced superior growth over the past several years. The
                Wright Quality Rating assigned to a company also takes into
                consideration country and industry variations. It there is not
                sufficient information available, the quality rating will not be
                assigned or an "N" (not-rated) will be applied for that
                particular quality criteria.
              </p>

              <InvestmentAcceptance
                data={this.state.cusip}
              ></InvestmentAcceptance>
              <Row className="ptsPage">
                <FinancialStrength data={this.state.cusip}></FinancialStrength>
              </Row>

              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <ProfitabilityStability
                data={this.state.cusip}
              ></ProfitabilityStability>

              <CorporateGrowth data={this.state.cusip}></CorporateGrowth>
            </div>

            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Balance Sheet-(Actual Values)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <BalanceSheet
                  type="Income Statement Actual Pdf"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></BalanceSheet>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Balance Sheet-(Common Size)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <BalanceSheet
                  type="Income Statement Common Size"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></BalanceSheet>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Balance Sheet-(Year-to-Year Percent Change)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <BalanceSheet
                  type="Income Statement Year-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></BalanceSheet>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Balance Sheet-(3-Year Averages)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <BalanceSheet
                  type="Income Statement Three Year Average"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></BalanceSheet>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header"> Balance Sheet Interim</span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <InterimBalanceSheet
                  type="Interim Balance Sheet Actual"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></InterimBalanceSheet>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Income Statement-(Actual Values)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CompanyFinancial
                  type="Income Statement Actual Pdf"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CompanyFinancial>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Income Statement-(Common Size)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CompanyFinancial
                  type="Income Statement Common Size"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CompanyFinancial>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Income Statement-(Year-to-Year Percent Change)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CompanyFinancial
                  type="Income Statement Year-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CompanyFinancial>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Income Statement-(3-Year Averages)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CompanyFinancial
                  type="Income Statement Three Year Average"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CompanyFinancial>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Income Statement Interim
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <InterimIncomeStatement
                  type="Interim Income Statement Actual"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></InterimIncomeStatement>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <SourceOfCapital
                  data={this.state.cusip}
                  isPdfReport="true"
                ></SourceOfCapital>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <AccountingRatios
                  data={this.state.accountingRatio}
                  isPdfReport="true"
                ></AccountingRatios>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <AssetUtilization
                  data={this.state.assetUtilizationRatio}
                  isPdfReport="true"
                ></AssetUtilization>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <EmployeeEfficiency
                  data={this.state.employeeEfficiencyRatio}
                  isPdfReport="true"
                ></EmployeeEfficiency>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <FixedChargesCoverage
                  data={this.state.fixChargeCoverageRatio}
                  isPdfReport="true"
                ></FixedChargesCoverage>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <LeverageAnalysis
                  data={this.state.leverageAnalysisRatio}
                  isPdfReport="true"
                ></LeverageAnalysis>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <LiquidityAnalysis
                  data={this.state.liquidityAnalysisRatio}
                  isPdfReport="true"
                ></LiquidityAnalysis>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <PerShareData
                  data={this.state.perShareProfitRatio}
                  isPdfReport="true"
                ></PerShareData>
              </Row>
            </div>
            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <ProfitabilityAnalysis
                  data={this.state.profitabilityAnalysisRatio}
                  isPdfReport="true"
                ></ProfitabilityAnalysis>
              </Row>
            </div>

            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Total Sources and Uses-(Actual Values)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <FlowOfFunds
                  type="Total Sources and Uses Actual Pdf"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></FlowOfFunds>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Total Sources and Uses - (Common Sizes)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <FlowOfFunds
                  type="Total Sources and Uses Common Size"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></FlowOfFunds>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Total Sources and Uses- (Year-To-Year Percent Change)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <FlowOfFunds
                  type="Total Sources and Uses Year-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></FlowOfFunds>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Total Sources and Uses- (3-Year Averages)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <FlowOfFunds
                  type="Total Sources and Uses 3-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></FlowOfFunds>
              </Row>
            </div>

            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Cash Basis-(Actual Values)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CashBasis
                  type="Cash Basis Actual Pdf"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CashBasis>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Cash Basis - (Common Sizes)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CashBasis
                  type="Cash Basis Common"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CashBasis>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Cash Basis- (Year-To-Year Percent Change)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CashBasis
                  type="Cash Basis Year-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CashBasis>
              </Row>
            </div>
            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  Cash Basis- (3-Year Averages)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CashBasis
                  type="Cash Basis 3-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CashBasis>
              </Row>
            </div>

            <div className="ptsPage">
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header"> FASB 95-(Actual Values)</span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <PerFasb
                  type="Per FASB Actual Pdf"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></PerFasb>
              </Row>
            </div>
            <div>
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  FASB 95 - (Common Sizes)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <PerFasb
                  type="Per FASB Common"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></PerFasb>
              </Row>
            </div>
            <div>
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  FASB 95- (Year-To-Year Percent Change)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <PerFasb
                  type="Per FASB Year-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></PerFasb>
              </Row>
            </div>
            <div>
              <span style={{ padding: "0em 2.4em" }}>
                <span className="section_header">
                  {" "}
                  FASB 95- (3-Year Averages)
                </span>
              </span>
              <Row style={{ marginTop: "1em" }}>
                <CashBasis
                  type="Per FASB 3-Year"
                  data={this.state.cusip}
                  isPdfReport="true"
                ></CashBasis>
              </Row>
            </div>

            <div className="ptsPage">
              <Row style={{ marginTop: "1em" }}>
                <Ratios cusip={this.state.cusip} isPdfReport="true"></Ratios>
              </Row>
            </div>

            <div className="ptsPage">
              <Row style={{ padding: "2em 0 0 2.3em", borderTop: "1px solid" }}>
                <p>
                  Copyright ©2000-{this.state.currentYear}. Distributed by
                  Wright Investors' Service, Inc. All Rights Reserved. Except
                  for quotations by established news media, no pages in this
                  report may be reproduced, stored in a retrieval system, or
                  transmitted for commercial purposes, in any form or by any
                  means, electronic, mechanical, photocopying, recording, or
                  otherwise without prior written permission. Wright Quality
                  Rating® is a registered trademark of the Winthrop Corporation.
                </p>{" "}
                <p>
                  Information is believed reliable, but accuracy, completeness
                  and opinions are not guaranteed. This report is provided for
                  general information only, is not to be considered investment
                  advice, and should not be relied upon for investment
                  decisions. This report is provided, as is, without warranty of
                  any kind, express, or implied, including, but not limited to
                  warranties of merchantability, fitness for a particular
                  purpose or non-infringement.
                </p>
              </Row>
            </div>
          </Card>
        </>
      );
    }
  }
}
