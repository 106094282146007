import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';
import 'react-tabs/style/react-tabs.css';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import cartAction from '../../redux/cart/cartAction';
import store from '../../redux/store';
import PtsAlert from '../ptsAlert/PtsAlert';
import GenericSearch from '../search/GenericSearch';
import './ReportSell.css';
export default class ReportSell extends Component {
  cartData = store.getState().cartData.cart;
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let selectedCompanyReport = '';
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      selectedCompanyReport =
        props.history.location.state.selectedCompanyReport;
    }
    this.state = {
      showModal: false,
      selectedCompanyReport: selectedCompanyReport,
      companyName: selectedCompanyReport.companyName,
    };
  }

  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
      radarData: ['200.0', '150.0', '160.0', '100.0'],
    });
  }
  addToCart(selectedCompanyReport) {
    let reports = this.cartData;
    reports.push(selectedCompanyReport);
    store.dispatch(cartAction.setCart(reports));
    PtsAlert.success(selectedCompanyReport.companyName + ' add to cart');
    this.props.history.push({
      pathname: '/cart',
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    return (
      <>
        <section className="breadcrumbs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4>Company Reports</h4>
              </div>
              <div className="col-lg-6">
                <GenericSearch history={this.props.history} />
              </div>
            </div>
          </div>
        </section>
        <Container maxWidth="lg">
          <Row style={{ borderBottom: '1px solid #ABB2B9' }}>
            <Col md={1}>
              <img
                src="/home/assets/img/Reports_03.gif"
                alt="Company Report"
                height="70%"
              ></img>
            </Col>
            <Col md={6} style={{ margin: '30px' }}>
              <span className="bold_text2">{this.state.companyName}</span>
              <br></br>
              <span className="bold_text">Wright</span>{' '}
              <span className="bold_text2">Comprehensive Company Report</span>
              &nbsp;
            </Col>
            <Col
              md={2}
              style={{ margin: '21px 0px 0px 0px', textAlign: 'right' }}
            >
              <Button
                style={{
                  background: 'linear-gradient(#f6c696, #e9893d)',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                <a
                  style={{
                    color: 'white',
                  }}
                  onClick={(e) =>
                    this.addToCart(this.state.selectedCompanyReport)
                  }
                >
                  Add To Cart
                </a>
              </Button>
            </Col>
            <Col md={2} style={{ margin: '21px 0px 0px 0px' }}>
              <Button
                style={{
                  background: 'linear-gradient(#f6c696, #e9893d)',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                <a
                  href="/home/assets/img/CompanySampleReport.pdf"
                  target="_blank"
                  style={{
                    color: 'white',
                  }}
                >
                  View Sample
                </a>
              </Button>
              &nbsp;
              <span>Price Each:</span> <span className="price_tag">$59.00</span>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="Cmp_reports">
              <p>
                We provide detailed comprehensive reports to help you research
                the leading publicly traded companies in the world. The company
                reports are presented in a standardized format that includes
                extensive explanatory footnotes to help you interpret company
                results. Each Wright Report contains a broad range of
                information that includes up to 25 sub-reports.
              </p>
            </Col>
          </Row>
          <Row>
            <span className="bodyTxtBold1">Standard Report Content:</span>
          </Row>

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={6} style={{ position: 'relative', padding: '1em' }}>
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>Fundamental Reports</b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Company Profile</li>

                      <li>Summary Analysis</li>
                      <li>Sales Analysis</li>
                      <li>Price Analysis</li>
                      <li>Earnings & Dividends Analysis</li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
            <Col md={6} style={{ position: 'relative', padding: '1em' }}>
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>
                      {' '}
                      Quality Analysis Reports
                    </b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Investment Acceptance</li>
                      <li>Financial Strength</li>
                      <li>Profitability & Stability</li>
                      <li>Corporate Growth</li>
                      <li
                        className="quality_rating"
                        onClick={(e) => this.getDataInGraphicalForm('price')}
                      >
                        What is Quality Analysis Rating?
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
          </Row>
          <Row
            style={{ position: 'relative', margin: '1em', marginBottom: '1em' }}
          >
            <Col
              md={6}
              style={{ position: 'relative', padding: '1em', height: '25em' }}
            >
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>
                      {' '}
                      Comparative Business Analysis Report
                    </b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Comparative Business Analysis</li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
            <Col
              md={6}
              style={{ position: 'relative', padding: '1em', height: '25em' }}
            >
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>
                      {' '}
                      Financial Statement Reports
                    </b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Balance Sheet- Actual</li>
                      <li>Balance Sheet- Common Size</li>
                      <li>Balance Sheet- Year- Year % Change</li>
                      <li>Balance Sheet- Three-Year Averages</li>
                      <li>Interim Balance Sheet- Actual Values</li>
                      <li>Interim Balance Sheet- Common Size</li>
                      <li>Income Statement- Actual</li>
                      <li>Income Statement- Common Size</li>
                      <li>Income Statement- Year- Year % Change</li>
                      <li>Income Statement- Three-Year Averages</li>
                      <li>Interim Income Statement- Actual Values</li>
                      <li>Interim Income Statement- Common Size</li>
                      <li>Sources of Capital</li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
          </Row>

          <Row
            style={{ position: 'relative', margin: '1em', marginBottom: '1em' }}
          >
            <Col
              md={6}
              style={{ position: 'relative', padding: '1em', height: '25em' }}
            >
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>
                      {' '}
                      Financial Ratio Reports
                    </b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Accounting Ratios</li>
                      <li>Asset Utilization</li>
                      <li>Employee Efficiency</li>
                      <li>Fixed Charges Coverage</li>
                      <li>Leverage Analysis</li>
                      <li>Liquidity Analysis</li>
                      <li>Per-Share Ratios</li>
                      <li>Profitability Analysis</li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
            <Col
              md={6}
              style={{ position: 'relative', padding: '1em', height: '25em' }}
            >
              <Paper
                elevation={3}
                style={{
                  height: '100%',
                }}
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <Card
                  style={{
                    height: '100%',
                  }}
                >
                  <CardHeader
                    style={{
                      background: '#808B96',
                      color: '#fff',
                    }}
                  >
                    <b style={{ paddingLeft: '10px' }}>
                      {' '}
                      Flow of Funds Reports
                    </b>
                  </CardHeader>
                  <CardBody>
                    <ul>
                      <li>Total Sources and Uses - Actual</li>
                      <li>Total Sources and Uses - Common Size</li>
                      <li>Total Sources and Uses - Year-Year % Change</li>
                      <li>Total Sources and Uses – Three-Year averages</li>
                      <li>Flow of funds: Cash Basis - Actual</li>
                      <li>Flow of funds: Cash Basis - Common Size</li>
                      <li>Flow of funds: Cash Basis - Year-Year % Change</li>
                      <li>Flow of funds: Cash Basis – Three-Year averages</li>
                      <li>Flow of funds: FASB 95 - Actual</li>
                      <li>Flow of funds: FASB 95 - Common Size</li>
                      <li>Flow of funds: FASB 95 - Year-Year % Change</li>
                      <li>Flow of funds: FASB 95 – Three-Year averages</li>
                      <li>Flow of funds: Analyses Ratios</li>
                    </ul>
                  </CardBody>
                </Card>
              </Paper>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          size="xl"
          className="modalContain"
        >
          <ModalHeader>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: '600',
                    color: '#fff',
                    textAlign: 'center',
                    float: 'left',
                  }}
                >
                  Wright Quality Ratings
                </Label>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Ratings, in use for over 40 years, measure
                  the overall investment quality of a company. This proprietary
                  rating is based on numerous individual measures of quality,
                  grouped into four principal components: (1) Investment
                  Acceptance (i.e. stock liquidity), (2) Financial Strength, (3)
                  Profitability & Stability, and (4) Growth.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Rating is expressed as three letters and a
                  number. Each letter reflects a composite qualitative
                  measurement of numerous individual standards which may be
                  summarized as “A”; Outstanding, “B”; Excellent, “C”; Good, “D”
                  Fair, “L”; Limited and “N”; Not Rated The number component of
                  the Quality Rating is also a composite of numerous individual
                  standards that measure Corporate Growth (i.e. sales, earnings,
                  assets, etc.). It ranges from a low of 0 to high of 20. (See
                  sample Quality Rating below.)
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The highest quality rating assigned by Wright is AAA20. This
                  rating would be assigned to a company that has a large and
                  broad base of shareholders, and has an outstanding balance
                  sheet and profitability. The company also has experienced
                  superior growth over the past several years.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Rating assigned to a company also takes
                  into consideration country and industry variations. If there
                  is not sufficient information available, the quality rating
                  will not be assigned or an “N” (not-rated) will be applied for
                  that particular quality criteria.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <h5 className="qualityRatingHeading">Sample Quality Rating</h5>
                <img
                  src="/home/assets/img/qalityRatings.png"
                  alt="qalityRatings"
                ></img>
              </Col>
              <Col md={5} style={{ padding: '2em' }}>
                <Radar
                  data={{
                    labels: [
                      'Investment Acceptance',
                      'Financial Strength',
                      ' Profitability & Stability',
                      ' Growth',
                    ],
                    datasets: [
                      {
                        backgroundColor: 'rgba( 230, 126, 34  , 0.6)',

                        borderColor: '#e67e22',
                        borderJoinStyle: 'round',
                        borderWidth: '10px',
                        borderDashOffset: '5',
                        lineTension: '0.5',
                        fontColor: '#fff',

                        fill: true,
                        chart: {
                          height: 350,
                          type: 'radar',
                        },
                        scaleLineWidth: 16,
                        scaleLineColor: '#000',
                        data: this.state.radarData,
                        borderWidth: 3,
                      },
                    ],
                  }}
                  style={{ margin: '3em 0 0 0' }}
                  options={{
                    scale: {
                      gridLines: {
                        circular: true,
                        color: '#7B7D7D ',
                        backdropColor: '#000',
                      },
                      pointLabels: {
                        fontColor: '#2c3e50',
                      },
                      ticks: {
                        beginAtZero: true,
                        backdropColor: 'transparent',
                        fontColor: '#000',
                        display: false,
                      },
                      chartArea: {
                        backgroundColor: 'rgba (247, 247, 246)',
                      },
                    },

                    scaleLineWidth: 16,

                    stroke: {
                      show: true,
                      width: 6,
                      colors: [],
                      dashArray: 0,
                    },
                    pointStyle: 'circle',
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
