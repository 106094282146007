import {
  GET_STATIC_DATA,
  SET_COUNTRY_FLAG_DATA,
  SET_INDUSTRY_SUBINDUSTRY_DATA,
  SET_REGION_COUNTRY_DATA,
  SET_STATIC_DATA,
} from "./staticDataTypes";

const initialState = {
  countryList: [],
  relationRoleList: [],
  relationTypeList: [],
  relationWithMemberList: [],
  taskTypeList: [],
  taskOccuranceList: [],
  reminderIntervalList: [],
  facilityTypeList: [],
  eventTypeListArray: [],
  eventOccuranceList: [],
  specializationList: [],
  accompanyTypeList: [],
  incidentTypeList: [],
  incidentOccuranceList: [],
  countryCodesForFlag: [],
  regionCountryData: [],
  industrySubIndustryData: [],
};

const staticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGION_COUNTRY_DATA:
      return {
        ...state,
        regionCountryData: action.payload,
      };
    case SET_INDUSTRY_SUBINDUSTRY_DATA:
      return {
        ...state,
        industrySubIndustryData: action.payload,
      };
    case SET_COUNTRY_FLAG_DATA:
      return {
        ...state,
        countryCodesForFlag: action.payload,
      };
    case GET_STATIC_DATA:
      return {
        ...state,
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        countryList: action.payload.countryList,
        relationRoleList: action.payload.relationRoleList,
        relationTypeList: action.payload.relationTypeList,
        relationWithMemberList: action.payload.relationWithMemberList,
        contactTypeList: action.payload.contactTypeList,
        appointmentFrequencyList: action.payload.appointmentFrequencyList,
        appointmentTypeList: action.payload.appointmentTypeList,
        appointmentPurposeList: action.payload.appointmentPurposeList,
        taskTypeList: action.payload.taskTypeList,
        taskOccuranceList: action.payload.taskOccuranceList,
        reminderIntervalList: action.payload.intervalList,
        facilityTypeList: action.payload.facilityTypeList,
        eventTypeListArray: action.payload.eventTypeList,
        eventOccuranceList: action.payload.eventOccuranceList,
        specializationList: action.payload.specializationList,
        accompanyTypeList: action.payload.accompanyTypeList,
        incidentTypeList: action.payload.incidentTypeList,
        incidentOccuranceList: action.payload.incidentOccuranceList,
      };

    default:
      return state;
  }
};

export default staticDataReducer;
