import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { Row } from "reactstrap";
import "./PrivacyPolicy.css";
export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Container maxWidth="lg" style={{ marginTop: "5em" }}>
          <main id="main">
            <Row style={{ marginTop: "1em" }}>
              <section id="privacyPolicy" className="termscondition">
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        data-aos="fade-up"
                        data-aos-delay={200}
                        style={{ textAlign: "left", fontFamily: "auto" }}
                      >
                        <ul>
                          <p>
                            CorporateInformation.com respects the privacy of
                            visitors to this site. This Privacy Policy applies
                            only to your use of this site. We have no control
                            over the privacy practices of other sites to which
                            you may link from here.
                          </p>
                        </ul>
                        <ul>
                          <li>Information We Collect</li>
                          <p>
                            CorporateInformation.com collects only the personal
                            information that you voluntarily and knowingly
                            provide to us. In order to use certain of our
                            services, we require your email address and a
                            password. Any other personal information you choose
                            to provide is purely voluntary and will not affect
                            your use of this site.
                          </p>
                          <li>How We Use Personal Information</li>
                          <p>
                            CorporateInformation.com and its affiliates may
                            store and use your personal information for any of
                            the following purposes:
                            <ul
                              style={{
                                listStyleType: "disc",

                                fontWeight: "normal",
                                fontSize: "small",
                                color: "black",
                              }}
                            >
                              <li
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "small",
                                  color: "black",
                                }}
                              >
                                To protect against unauthorized access to our
                                site;
                              </li>
                              <li
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "small",
                                  color: "black",
                                }}
                              >
                                To respond to your questions;
                              </li>
                              <li
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "small",
                                  color: "black",
                                }}
                              >
                                To inform you of other products and services of
                                our affiliates;
                              </li>
                              <li
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "small",
                                  color: "black",
                                }}
                              >
                                To send you occasional information related to
                                the economy or stock markets;
                              </li>
                              <li
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "small",
                                  color: "black",
                                }}
                              >
                                To administer the web site.
                              </li>
                            </ul>
                          </p>
                          <p>
                            CORPORATEINFORMATION.COM WILL NOT SELL OR LEASE YOUR
                            PERSONAL INFORMATION TO ANY THIRD PARTY.
                          </p>
                          <li>Cookies and Statistical Information</li>
                          <p>
                            Like many other web sites, CorporateInformation.com
                            uses “cookies” to facilitate automated activity, to
                            determine how visitors use our web site and to
                            determine which features to add to our web site. If
                            you instruct your browser to disable cookies, you
                            will not be able to access
                            CorporateInformation.com’s extensive database. The
                            data we collect through cookies provides us with
                            important nonpersonal information about how our site
                            is used. This information may be shared with our
                            advertisers, but only in aggregate form without
                            revealing any personal information about you or any
                            of our visitors.
                          </p>
                          <li>Links</li>
                          <p>
                            CorporateInformation.com contains thousands of links
                            to other web sites. Since we have no control over
                            such sites, we are not responsible for information
                            you may provide to them, knowingly or otherwise. Be
                            sure to check the privacy policy of any site asking
                            you for personal information.
                          </p>
                          <li>
                            Compliant with Network Advertising Initiative (NAI)
                          </li>
                          <p>
                            We use third-party advertising companies to serve
                            ads when you visit our Web site. These companies may
                            use aggregated information (not including your name,
                            address, email address or telephone number) about
                            your visits to this and other Web sites in order to
                            provide advertisements about goods and services of
                            interest to you. If you would like more information
                            about this practice and to know your choices about
                            not having this information used by these
                            companies,&nbsp;
                            <a
                              href="http://www.networkadvertising.org/managing/opt_out.asp"
                              className="privacyPolicy_link"
                              rel="noreferrer"
                              target="_blank"
                            >
                              click here
                            </a>
                          </p>
                          <li>Changes</li>
                          <p>
                            From time to time, CorporateInformation.com may
                            change this privacy policy.&nbsp; To keep up-to-date
                            on any changes, please check this site
                            periodically.&nbsp; If at any time you have a
                            question about this privacy policy, please
                            <a href="/feedback" className="privacyPolicy_link">
                              {" "}
                              Contact Us
                            </a>{" "}
                            with your request.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Row>
          </main>
        </Container>
      </>
    );
  }
}
