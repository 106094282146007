import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import "./InsuranceAccountingRatios.css";
export default class InsuranceAccountingRatios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      accountingRatioIndData: [],
      note: props.data.note,
      noteHeading: props.data.noteHeading,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    let accountingRatioInsData = this.state.data.accountingRatioInsList;
    if (accountingRatioInsData.length > 0) {
      //accountingRatioInsData.reverse();
    }
    return (
      <>
        <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
          <Col md={12}>
            <Row>
              <Col md={8}>
                <span>
                  <span className="section_header">
                    {" "}
                    Financial Accounting Ratios
                  </span>
                </span>
                <br></br>
              </Col>
              <Col md={4}></Col>
            </Row>
            <Table responsive className="accounting_ratio">
            {Object.keys(accountingRatioInsData).map((key, index) => { 
              return (
              <tr>
                    <th style={{ fontWeight: "bold" }}>{key}</th>
                  
                   
              {accountingRatioInsData[key].map((data, index) => { 
              return (
             
                    <td style={{ fontWeight: "bold" }}>{data}</td>
                 
              
              );
            })
            }
            </tr>
              );
            })
            }
            </Table>
           
          </Col>
        </Row>
      </>
    );
  }
}
