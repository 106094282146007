import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../../provider/Utils";
import GraphIconButton from "../../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../../genricGraph/SingleAxisGenericGraph";
import "./IndAssetUtilization.css";
export default class IndAssetUtilization extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    let assetUtilizationRatioIndList =
      props.data.assetUtilizationRatioIndList.slice(0, 5);

    if (assetUtilizationRatioIndList.length > 0) {
      assetUtilizationRatioIndList.reverse();
    }
    let totalAsset = [];
    let LabelList = [];
    let netSales = [];
    let accountsReceivable = [];
    let inventories = [];
    let totalCurrentAssets = [];
    let otherAssets = [];
    let propertyPlantAndEquipmentNet = [];
    let accumulatedDepreciation = [];
    let propertyPlantAndEquipmentGross = [];
    let otherInvestments = [];
    let investmentsinAssociatedCompanies = [];
    let longTermReceivables = [];
    let totalLongTermReceivablesAndInvestments = [];
    let shortTermInvestments = [];
    let otherCurrentAssets = [];
    let cashAndCashEquivalents = [];
    for (let i = 0; i < assetUtilizationRatioIndList.length; i++) {
      totalAsset.push(assetUtilizationRatioIndList[i].totalAssetsPercent);
      LabelList.push(assetUtilizationRatioIndList[i].fiscalYear);
      netSales.push(assetUtilizationRatioIndList[i].netSales);
      accountsReceivable.push(
        assetUtilizationRatioIndList[i].accountsReceivablePercent
      );
      inventories.push(assetUtilizationRatioIndList[i].inventoriesPercent);
      totalCurrentAssets.push(
        assetUtilizationRatioIndList[i].totalCurrentAssetsPercent
      );

      otherAssets.push(assetUtilizationRatioIndList[i].otherAssets);
      propertyPlantAndEquipmentNet.push(
        assetUtilizationRatioIndList[i].propertyPlantAndEquipmentNet
      );
      accumulatedDepreciation.push(
        assetUtilizationRatioIndList[i].accumulatedDepreciation
      );
      propertyPlantAndEquipmentGross.push(
        assetUtilizationRatioIndList[i].propertyPlantAndEquipmentGross
      );
      otherInvestments.push(assetUtilizationRatioIndList[i].otherInvestments);
      investmentsinAssociatedCompanies.push(
        assetUtilizationRatioIndList[i].investmentsinAssociatedCompanies
      );
      longTermReceivables.push(
        assetUtilizationRatioIndList[i].longTermReceivables
      );
      totalLongTermReceivablesAndInvestments.push(
        assetUtilizationRatioIndList[i].totalLongTermReceivablesAndInvestments
      );
      shortTermInvestments.push(
        assetUtilizationRatioIndList[i].shortTermInvestments
      );
      otherCurrentAssets.push(
        assetUtilizationRatioIndList[i].otherCurrentAssets
      );
      cashAndCashEquivalents.push(
        assetUtilizationRatioIndList[i].cashAndCashEquivalents
      );
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      assetUtilizationRatioIndData: assetUtilizationRatioIndList,
      totalAsset: totalAsset,
      LabelList: LabelList,
      netSales: netSales,
      accountsReceivable: accountsReceivable,
      inventories: inventories,
      totalCurrentAssets: totalCurrentAssets,
      otherAssets: otherAssets,
      propertyPlantAndEquipmentNet: propertyPlantAndEquipmentNet,
      accumulatedDepreciation: accumulatedDepreciation,
      propertyPlantAndEquipmentGross: propertyPlantAndEquipmentGross,
      otherInvestments: otherInvestments,
      investmentsinAssociatedCompanies: investmentsinAssociatedCompanies,
      longTermReceivables: longTermReceivables,
      totalLongTermReceivablesAndInvestments:
        totalLongTermReceivablesAndInvestments,
      shortTermInvestments: shortTermInvestments,
      otherCurrentAssets: otherCurrentAssets,
      cashAndCashEquivalents: cashAndCashEquivalents,
      series: [
        {
          name: "Total Asset",
          type: "bar",
          data: totalAsset,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },

        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        colors: ["#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: LabelList,
        xaxis: {
          title: {
            text: "Year",
            style: {
              colors: "#808b96",
            },
          },

          axisBorder: {
            show: true,
            color: "#808b96",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "Total Assets",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#808b96 ",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return "$ " + y;
              }
              return y;
            },
          },
        },
      },
      tableGraphSeries: [
        {
          data: totalAsset,
        },
      ],
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      render: true,
    };
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Accounts Receivable") {
      this.setState({
        graphDetails: {
          graphValues: this.state.accountsReceivable,
          labelData: this.state.LabelList,
          yAxisTitle: "Accounts Receivable",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Inventories") {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventories,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventories",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash & Cash Equivalents") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashAndCashEquivalents,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash & Cash Equivalents",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Short-Term Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.shortTermInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Short-Term Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Long Term Receivables & Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLongTermReceivablesAndInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Long Term Receivables & Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Receivables") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermReceivables,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Receivables",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments in Associated Companies") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentsinAssociatedCompanies,
          labelData: this.state.LabelList,
          yAxisTitle: "Investments in Associated Companies",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property, Plant & Equipment - Gross") {
      this.setState({
        graphDetails: {
          graphValues: this.state.propertyPlantAndEquipmentGross,
          labelData: this.state.LabelList,
          yAxisTitle: "Property, Plant & Equipment - Gross",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Accumulated Depreciation") {
      this.setState({
        graphDetails: {
          graphValues: this.state.accumulatedDepreciation,
          labelData: this.state.LabelList,
          yAxisTitle: "Accumulated Depreciation",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property Plant & Equipment - Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.propertyPlantAndEquipmentNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Property Plant & Equipment - Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAsset,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <Row>
                <Col md={8}>
                  <span>
                    <span className="section_header">
                      {" "}
                      Financial Asset Utilization
                    </span>
                  </span>{" "}
                  <br></br>
                  <span className="unit_str" style={{ marginLeft: "-2px" }}>
                    Figures are expressed as the ratio of Net Sales.
                  </span>
                  <br></br>
                  <span className="unit_str" style={{ marginLeft: "-2px" }}>
                    All Figures in&nbsp;{" "}
                    <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                    {this.state.currency}
                  </span>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="asset_utilization">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Net Sales</span>
                  </th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netSales !== null
                          ? Utils.valueFormater(data.netSales)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash & Cash Equivalents</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.cashAndCashEquivalents !== null
                          ? Utils.valueFormater(
                              data.cashAndCashEquivalentsPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Short-Term Investments</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.shortTermInvestments !== null
                          ? Utils.valueFormater(
                              data.shortTermInvestmentsPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Accounts Receivable</span>
                  </th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.accountsReceivable !== null
                          ? Utils.valueFormater(
                              data.accountsReceivablePercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Inventories</span>
                  </th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.inventories !== null
                          ? Utils.valueFormater(data.inventoriesPercent) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Current Assets</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.otherCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.otherCurrentAssetsPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Current Assets</span>
                  </th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.totalCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.totalCurrentAssetsPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Total Long Term Receivables & Investments</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.totalLongTermReceivablesAndInvestmentsPercent !==
                        null
                          ? Utils.valueFormater(
                              data.totalLongTermReceivablesAndInvestmentsPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Receivables</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.longTermReceivablesPercent !== null
                          ? Utils.valueFormater(
                              data.longTermReceivablesPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Investments in Associated Companies</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.investmentsinAssociatedCompaniesPercent !== null
                          ? Utils.valueFormater(
                              data.investmentsinAssociatedCompaniesPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Investments</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.otherInvestmentsPercent !== null
                          ? Utils.valueFormater(data.otherInvestmentsPercent) +
                            "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Property, Plant & Equipment - Gross</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.propertyPlantAndEquipmentGross !== null
                          ? Utils.valueFormater(
                              data.propertyPlantAndEquipmentGrossPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Accumulated Depreciation</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.accumulatedDepreciation !== null
                          ? Utils.valueFormater(
                              data.accumulatedDepreciationPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Property Plant & Equipment - Net</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.propertyPlantAndEquipmentNet !== null
                          ? Utils.valueFormater(
                              data.propertyPlantAndEquipmentNetPercent
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Assets</th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.otherAssets !== null
                          ? Utils.valueFormater(data.otherAssetsPercent) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className=""> Total Assets</span>
                  </th>
                  {this.state.assetUtilizationRatioIndData.map((data) => {
                    return (
                      <td>
                        {data.totalAssets !== null
                          ? Utils.valueFormater(data.totalAssetsPercent) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Financial Asset Utilization
                      </span>
                    </span>{" "}
                    <br></br>
                    <span className="unit_str" style={{ marginLeft: "-2px" }}>
                      Figures are expressed as the ratio of Net Sales.
                    </span>
                    <br></br>
                    <span className="unit_str" style={{ marginLeft: "-2px" }}>
                      All Figures in&nbsp;{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="asset_utilization">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Sales
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSales !== null
                            ? Utils.valueFormater(data.netSales)
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash & Cash Equivalents
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash & Cash Equivalents")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.cashAndCashEquivalents !== null
                            ? Utils.valueFormater(
                                data.cashAndCashEquivalentsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Short-Term Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Short-Term Investments")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.shortTermInvestments !== null
                            ? Utils.valueFormater(
                                data.shortTermInvestmentsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Accounts Receivable
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Accounts Receivable")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.accountsReceivable !== null
                            ? Utils.valueFormater(
                                data.accountsReceivablePercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Inventories
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Inventories")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.inventories !== null
                            ? Utils.valueFormater(data.inventoriesPercent) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Current Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Current Assets")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.otherCurrentAssets !== null
                            ? Utils.valueFormater(
                                data.otherCurrentAssetsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Current Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Current Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.totalCurrentAssets !== null
                            ? Utils.valueFormater(
                                data.totalCurrentAssetsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Long Term Receivables & Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Long Term Receivables & Investments"
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.totalLongTermReceivablesAndInvestments !== null
                            ? Utils.valueFormater(
                                data.totalLongTermReceivablesAndInvestmentsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Long Term Receivables
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long Term Receivables")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.longTermReceivablesPercent !== null
                            ? data.longTermReceivablesPercent.toFixed(2) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investments in Associated Companies
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments in Associated Companies"
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.investmentsinAssociatedCompaniesPercent !== null
                            ? data.investmentsinAssociatedCompaniesPercent.toFixed(
                                2
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Investments")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.otherInvestmentsPercent !== null
                            ? Utils.valueFormater(
                                data.otherInvestmentsPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Property, Plant & Equipment - Gross
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property, Plant & Equipment - Gross"
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.propertyPlantAndEquipmentGrossPercent !== null
                            ? Utils.valueFormater(
                                data.propertyPlantAndEquipmentGrossPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Accumulated Depreciation
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Accumulated Depreciation"
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.accumulatedDepreciationPercent !== null
                            ? Utils.valueFormater(
                                data.accumulatedDepreciationPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Property Plant & Equipment - Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property Plant & Equipment - Net"
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.propertyPlantAndEquipmentNetPercent !== null
                            ? Utils.valueFormater(
                                data.propertyPlantAndEquipmentNetPercent
                              ) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Assets")
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.otherAssetsPercent !== null
                            ? Utils.valueFormater(data.otherAssetsPercent) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.assetUtilizationRatioIndData.map((data) => {
                      return (
                        <td>
                          {data.totalAssetsPercent !== null
                            ? Utils.valueFormater(data.totalAssetsPercent) + "%"
                            : "0"}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Financial Asset Utilization &nbsp;
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp;{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
