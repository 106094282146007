import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import store from "../../redux/store";
import GenericSearch from "../search/GenericSearch";
import "./Checkout1.css";
export default class Checkout1 extends Component {
  userData = store.getState().userData.userData;
  loginRefList = [];
  constructor(props) {
    super(props);
    let selectedPlan = "";
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      selectedPlan = props.history.location.state.planDetails;
    }
    this.state = {};
    this.loginRefList = React.createRef();
  }

  render() {
    return (
      <>
        <section className="breadcrumbs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4>Checkout</h4>
              </div>
              <div className="col-lg-6">
                <GenericSearch history={this.props.history} />
              </div>
            </div>
          </div>
        </section>

        <Container maxWidth="lg" className="contcss"></Container>
      </>
    );
  }
}
