import PublishIcon from "@material-ui/icons/Publish";
import Aos from "aos";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ApplicationLayout } from "./layouts/ApplicationLayout";
import CheckoutPageLayout from "./layouts/CheckoutPageLayout";
import { CompanyDetailLayout } from "./layouts/CompanyDetailLayout";
import { HomePageLayout } from "./layouts/HomePageLayout";
import { SearchPageLayout } from "./layouts/SearchPageLayout";
import FetchServerData from "./provider/FetchServerData";
import Cart from "./ptsComponentes/cart/Cart";
import CompanyQualityRating from "./ptsComponentes/companyQualityRating/CompanyQualityRating";
import CompanyReport from "./ptsComponentes/companyReport/CompanyReport";
import CompanyCorporateInfo from "./ptsComponentes/companySnapshot/CompanyCorporateInfo";
import CbaSampleReport from "./ptsComponentes/comparativeBusinessAnalysis/CbaSampleReport";
import CorporateInformationHomeTemplate from "./ptsComponentes/corporateInformationHome/CorporateInformationHomeTemplate";
import CorporateInformationStartHomeTemplate from "./ptsComponentes/corporateInformationHome/CorporateInformationStartHomeTemplate";
import WrightQualityRatingInfo from "./ptsComponentes/corporateInformationHome/WrightQualityRatingInfo";
import CurrencyExchangeRate from "./ptsComponentes/currencyExchangeRate/CurrencyExchangeRate";
import DefinitionExtension from "./ptsComponentes/definitionAndExtensions/DefinitionExtension";
import DefinitionsOfFinancialTerms from "./ptsComponentes/definitionsOfFinancialTerms/DefinitionsOfFinancialTerms";
import DownloadMobileApp from "./ptsComponentes/downloadMobileApp/DownloadMobileApp";
import Feedback from "./ptsComponentes/feedback/Feedback";
import IndustryAverageReport from "./ptsComponentes/industryAverageReport/IndustryAverageReport";
import Payment from "./ptsComponentes/payment/Payment";
import Cancel from "./ptsComponentes/paymentGateway/Canceled";
import Checkout from "./ptsComponentes/paymentGateway/Checkout";
import Checkout1 from "./ptsComponentes/paymentGateway/Checkout1";
import IndividualReportCheckout from "./ptsComponentes/paymentGateway/IndividualReportCheckout";
import Success from "./ptsComponentes/paymentGateway/Success";
import Plans from "./ptsComponentes/plans/Plans";
import PrivacyPolicy from "./ptsComponentes/privacyPolicy/PrivacyPolicy";
import ReportDetails from "./ptsComponentes/reportDetails/ReportDetails";
import ReportSell from "./ptsComponentes/reportSell/ReportSell";
import ResetPassword from "./ptsComponentes/resetPassword/ResetPassword";
import CompanySearch from "./ptsComponentes/search/CompanySearch";
import SiteMap from "./ptsComponentes/siteMap/SiteMap";
import TermsAndCondition from "./ptsComponentes/termsAndCondition/TermsAndCondition";
import TopHundredItem from "./ptsComponentes/topHundredAdvanceList/TopHundredItem";
import TopHundredFilter from "./ptsComponentes/topHundredFilter/TopHundredFilter";
import TopHundredFilterMethodology from "./ptsComponentes/topHundredFilter/TopHundredFilterMethodology";
import TopHundredList from "./ptsComponentes/topHundredList/TopHundredList";
import DefaultCard from "./ptsComponentes/userProfile/DefaultCard";
import UserProfile from "./ptsComponentes/userProfile/UserProfile";
import store from "./redux/store";
import userDataAction from "./redux/userData/userDataAction";

// import { HashRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  Aos.init();
  FetchServerData.hideLoader();

  return (
    // <div>
    <Provider store={store}>
      <ToastContainer
        style={{
          alignItems: "center",
          fontSize: "small",
          width: "auto",
          maxHeight: "1em",
        }}
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HashRouter>
        <Switch>
          <SearchPageLayout path="/search" component={CompanySearch} />
          <ApplicationLayout
            path="/definitions"
            component={DefinitionExtension}
          />
          <HomePageLayout
            path="/ci"
            component={CorporateInformationHomeTemplate}
          />
          <CompanyDetailLayout
            path="/company"
            component={CompanyCorporateInfo}
          />
          <CompanyDetailLayout
            path="/companysnapdetails"
            component={CompanyCorporateInfo}
          />

          <CompanyDetailLayout
            path="/companysnapreport"
            component={CompanyCorporateInfo}
          />
          <ApplicationLayout path="/feedback" component={Feedback} />
          <ApplicationLayout path="/tandc" component={TermsAndCondition} />
          <ApplicationLayout path="/privacypolicy" component={PrivacyPolicy} />
          <ApplicationLayout path="/tophundred" component={TopHundredList} />
          <ApplicationLayout
            path="/currency"
            component={CurrencyExchangeRate}
          />
          <ApplicationLayout path="/companyreport" component={CompanyReport} />
          <ApplicationLayout
            path="/financialterms"
            component={DefinitionsOfFinancialTerms}
          />
          <ApplicationLayout
            path="/industryreport"
            component={IndustryAverageReport}
          />
          <ApplicationLayout
            path="/Wrightqualityanalysis"
            component={WrightQualityRatingInfo}
          />
          <ApplicationLayout path="/cbasample" component={CbaSampleReport} />
          <ApplicationLayout
            path="/indreportdetail"
            component={ReportDetails}
          />
          <ApplicationLayout
            path="/top_hundred_advance"
            component={TopHundredItem}
          />
          <ApplicationLayout path="/sitemap" component={SiteMap} />
          <ApplicationLayout path="/plans" component={Plans} />
          <ApplicationLayout path="/payment" component={Payment} />
          <ApplicationLayout path="/reset_Pass" component={ResetPassword} />
          <ApplicationLayout
            path="/company_rating"
            component={CompanyQualityRating}
          />
          <ApplicationLayout path="/report_sell" component={ReportSell} />
          <ApplicationLayout
            path="/download_mobile_app"
            component={DownloadMobileApp}
          />
          <ApplicationLayout path="/cart" component={Cart} />
          <ApplicationLayout
            path="/advancescreening"
            component={TopHundredFilter}
          />
          {/* <ApplicationLayout path="/checkout" component={Checkout} /> */}
          <CheckoutPageLayout path="/checkout" component={Checkout} />
          <ApplicationLayout path="/canceled" component={Cancel} />
          <ApplicationLayout path="/success" component={Success} />
          <ApplicationLayout path="/checkout1" component={Checkout1} />
          <ApplicationLayout
            path="/reportcheckout"
            component={IndividualReportCheckout}
          />
          <ApplicationLayout path="/user" component={UserProfile} />
          <ApplicationLayout
            path="/topHundredFilterMethodology"
            component={TopHundredFilterMethodology}
          ></ApplicationLayout>
          <ApplicationLayout path="/payment_method" component={DefaultCard} />
          <Route component={CorporateInformationStartHomeTemplate} />
        </Switch>
      </HashRouter>
      <ScrollToTop
        smooth
        component={
          <PublishIcon
            style={{
              color: "white",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />
        }
        style={{
          background: "rgb(242, 109, 33)",
          // borderRadius: '50%',
          // border: '2px solid #b34608',
          boxShadow: "2px 2px 2px 3px #b34608 inline",
          cursor: "pointer",
        }}
      />
    </Provider>
    // </div>
  );
}

export default App;
