import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import Advertisement from "../ciadvertisement/Advertisement";

class DefinitionsOfFinancialTerms extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container maxWidth="lg" style={{ marginTop: "5em" }}>
        <Row style={{ marginTop: "1em" }}>
          <Col md={10}>
            <div style={{ marginTop: "5em" }}>
              <h3>A</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Accounts Payable
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Short-term debt (payable in 12 months or less) a company
                      owes suppliers of goods and services. This amount is
                      included in current liabilities on the balance sheet.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Accounts Receivable{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds owed the company by customers (less bad debt
                      write-offs) expected to be received within 12 months. This
                      amount is included in current assets on the balance sheet.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Accrued Payroll
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Payroll expenses incurred within the normal operating
                      cycle of the company without being paid.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Accumulated Depreciation
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The cumulative reduction of the carrying amount of
                      tangible balance sheet assets to reflect loss of value due
                      to wear, tear, and usage.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Acid-Test Ratio{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Current Assets less Inventory by
                      Current Liabilities. This ratio measures the ability of
                      liquid assets to quickly meet short-term obligations. It
                      is also known as the Quick Ratio.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      After Tax Other Income/Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Other income and expense not subject to corporate income
                      taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Amortization of Intangible Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A non-cash annual reduction of the carrying amount of
                      intangible balance sheet assets such as copyrights,
                      trademarks, patents etc. recognized over the useful life
                      of the assets.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Asset Turnover
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Net sales divided by total assets or average total assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any item owned by a corporation which can be expected to
                      provide future economic benefit.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Assets Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio computed by dividing assets by the same number of
                      shares used to compute earnings per share.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>B</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Book Value Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Common Shareholders' Equity at book value divided by
                      outstanding shares at the company's fiscal year end for
                      non-U.S. corporations and calendar year end for U.S.
                      corporations. It is equal to assets minus liabilities,
                      preferred stock, and minority interest.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Business
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any activities of a firm that provide services or sell
                      goods in order to earn a profit.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>C</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Capital Expenditure % of Sales
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio indicating the amount of capital expenditures for
                      property, plant and equipment (excluding acquisitions) for
                      every dollar's worth of sales or revenues.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Capitalized Lease Obligations
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A lease having the economic characteristic of asset
                      ownership and classified as an asset on a company's
                      balance sheet.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Currency (paper or coins) or negotiable checks and money
                      orders that can be used to purchase goods and services or
                      reduce debt. Cash is the most liquid company asset.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash % of Current Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio which measures the extent to which Cash is a
                      component of total Current Assets. It is calculated by
                      dividing Cash by total Current Assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash &amp; Short Term Investments
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Made up of Cash (Currency and negotiable checks and money
                      orders) and Investments maturing in one year or less.
                      These assets are more liquid than other components of
                      Current Assets such as Accounts Receivable and Inventory.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash &amp; Short Term Investments % of Current Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio of Cash and Short-term Investments divided by
                      total Current Assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Earnings per Employee{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Cash Earnings (also known as Cash
                      Flow) to the number of full-time employees. In comparison
                      with other companies in the same sector, it is a measure
                      of employee productivity.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Earnings Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Cash Earnings (also known as Cash Flow) divided by the
                      same number of shares used to compute earnings per share.
                      It is a measure of a company's ability to pay dividends to
                      shareholders.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Flow % Sales
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio of a company's Cash Flow to annual sales
                      representing a measure of how much cash is generated by
                      each dollar of sales. The higher the ratio the more
                      profitable the company.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Flow - Financing Activities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds related to the financing activities (stock sales or
                      repurchases, debt issues or repayments, dividend payments)
                      of the company reported on the cash flow statement of the
                      quarterly and annual reports.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Flow - Investing Activities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds related to the investing activities (capital
                      expenditures, investments) of the company reported on the
                      cash flow statement of the quarterly and annual reports.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Flow - Operating Activities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds related directly to the company's income-producing
                      operations (net income, changes in accounts payable,
                      accounts receivable and inventory) reported on the Cash
                      Flow statement of the quarterly and annual reports.
                      Depreciation, depletion, amortization, and deferred taxes
                      are also added back to net income to derive Cash Flow from
                      Operations.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cash Flow Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Cash Flow (also known as Cash Earnings) divided by the
                      same number of shares used to compute earnings per share.
                      It is a measure of a company's ability to pay dividends to
                      shareholders.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Common Dividends Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A potentially taxable payment to its common shareholders
                      declared by a company's board of directors and paid out of
                      the company's cash flow or retained earnings. Dividends
                      are usually distributed as cash, but they can also take
                      the form of stock or other property.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Common Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Common shareholders' investment in a company including
                      paid-in capital and retained earnings.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Common Equity % of Total Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio of Common Equity to Total Assets representing a
                      traditional measure of financial leverage. Generally,
                      companies who finance a greater portion of their assets
                      with equity rather than debt, are considered to be less
                      risky.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Common Equity % of Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio of Common Equity to Total Capital representing a
                      traditional measure of financial leverage. Generally,
                      companies who finance a greater portion of their assets
                      with equity rather than debt, are considered to be less
                      risky.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Company Financials
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Various accounting statements like the income statement,
                      balance sheet and cash flow statement. Financial
                      statements summarize all the transactions that have
                      occurred over a specific period.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Cost of Goods Sold
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An expense that appears on the income statement which
                      includes the direct costs attributable to the production
                      of the goods sold by a company such as the cost of the
                      materials and labor. Indirect expenses such as
                      distribution and sales force costs are excluded.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Country
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The name of the country where the company is domiciled.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Currency
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The currency of data items.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Current Assets - Total
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The value of all assets that are reasonably expected to be
                      converted into cash usually within one year. Current
                      assets include cash, accounts receivable, inventory,
                      marketable securities, prepaid expenses and other
                      liquid assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Current Liabilities - Total
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The value of a company's debts or obligations that are
                      usually due within one year. Current liabilities include
                      short-term debt, accounts payable, accrued liabilities,
                      taxes payable, dividends payable and other short-term
                      obligations.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Current Ratio
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Also known as the Working Capital Ratio, this measure of a
                      company's ability to pay its short-term obligations is
                      calculated by dividing current assets by current
                      liabilities.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>D</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Usually refers to debt maturing in one year or more.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Charges
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Obligations due to creditors and suppliers within a period
                      of time greater than one year. Since related income is
                      received over an extended period, recording deferred
                      charges in this manner ensures that a company is adhering
                      to Generally Accepted Accounting Principles (GAAP) by
                      matching revenues with expenses.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Income a company collects before it actually delivers a
                      product or service. It represents income received but not
                      yet earned.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Income Taxes
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liability recorded on the balance sheet that results
                      from a reconciliation of income already earned and
                      recognized for accounting, but not for tax purposes. In
                      other words, differences between tax laws and accounting
                      methods can result in a temporary inconsistency in the
                      amount of income tax payable by a company. This
                      inconsistency is recorded as deferred income tax.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Income Taxes &amp; Investment Tax Credit
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The increase or decrease in the Deferred Tax Liability and
                      Investment Tax Credit from one year to the next.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Tax Liability in Untaxed Reserves
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The portion of untaxed reserves allocable to deferred
                      taxation.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Taxes
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liability recorded on the balance sheet that results
                      from a reconciliation of income already earned and
                      recognized for accounting, but not for tax purposes. In
                      other words, differences between tax laws and accounting
                      methods can result in a temporary inconsistency in the
                      amount of income tax payable by a company. This
                      inconsistency is recorded as deferred income tax.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Taxes - Credit
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The credit balance of the Deferred Tax account.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Deferred Taxes - Debit
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The debit balance of the Deferred Tax account.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Depreciation &amp; Depletion
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Depreciation is an expense recorded to allocate a tangible
                      asset's cost over its useful life reflecting the loss in
                      value because of wear, tear and usage. Depletion
                      represents the allocation of cost for natural resources
                      such as mineral deposits and oil. Since Depreciation and
                      Depletion are non-cash expenses, they increase free cash
                      flow despite decreasing reported earnings.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Depreciation, Depletion &amp; Amortization
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Depreciation is an expense recorded to allocate a tangible
                      asset's cost over its useful life reflecting loss in value
                      because of wear, tear and usage. Depletion represents the
                      allocation of cost for natural resources such as mineral
                      deposits and oil. Amortization relates to the write off,
                      over time, of the cost for intangible assets such as
                      copyrights, patents and leasehold improvements,
                      trademarks, bookplates, tools and film cost. Since
                      Depreciation, Depletion and Amortization are non-cash
                      expenses, they increase free cash flow despite decreasing
                      reported earnings.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Discontinued Operations
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The earnings (or loss) of a division or business segment
                      that the company plans to discontinue or dispose of in the
                      near future.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Dividend Payout
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The percentage of earnings paid out to shareholders in the
                      form of cash dividends. Earnings not paid out are retained
                      by the company and reinvested in the business.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Dividend Yield{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A portion of the investment return shareholders receive in
                      cash dividends calculated by dividing cash dividends by
                      the market price, expressed as a percentage.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Dividends - Total
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The total amount of dividends declared by a company's
                      board of directors and distributed to its shareowners out
                      of current or retained earnings. Dividends are usually
                      distributed as cash, but they can also take the form of
                      stock or other property.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Dividends Payable
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The dollar amount of dividends declared by the board of
                      directors but not yet paid to shareholders.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Dividends Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A cash payment usually declared quarterly by a company's
                      board of directors and distributed to its shareholders out
                      of current or retained earnings divided by the number of
                      common shares outstanding.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>E</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Earned Growth
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The difference between earnings for the year and dividends
                      for the year, divided by book value at the beginning of
                      the year, expressed as a percentage. Based on calendar
                      year period for U.S. companies and fiscal year period for
                      non-U.S. companies.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Earnings before Interest and Taxes (EBIT)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The earnings of a company, including non-cash items,
                      before deductions for interest expense and income taxes.
                      It is an indicator of profitability without regard to the
                      company's financial structure. EBIT is also referred to as
                      operating earnings, operating profit and operating income.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Earnings before Interest, Taxes, Depreciation &amp;
                      Amortization (EBITDA)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The earnings of a company before interest expense, income
                      taxes, and (non-cash) depreciation and amortization. It is
                      a significant indicator of a company's Cash Flow from
                      operations.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Earnings Per Share - 12 Months
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Sum of any earnings per share during the last twelve
                      months.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Effective Tax Rate
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount of income taxes actually paid by a firm as a
                      percent of pretax income.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Employees
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Number of full-time employees at fiscal year end.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Equity in Earnings
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Earnings or losses of an unconsolidated subsidiary in
                      proportion to the parent company's equity interest..
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Guarantees made by the company for loans incurred by
                      Employee Stock Option Plans (ESOP) for the purchase of the
                      company's common shares by employees. Purchased stock is
                      held as collateral until the loan is repaid. An ESOP is a
                      qualified, defined contribution, employee benefit (ERISA)
                      plan.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Extraordinary Charge - Pretax
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Infrequent and unusual pre-tax charges segregated on the
                      income statement and usually explained in the notes to the
                      financial statements.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Extraordinary Credit - Pretax
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Infrequent and unusual pre-tax credits segregated on the
                      income statement and usually explained in the notes to the
                      financial statements.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Extraordinary Credit - Pretax % of Net Sales or Revenues
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio to Total Sales of infrequent and unusual pre-tax
                      credits segregated on the income statement and usually
                      explained in the notes to the financial statements.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Extraordinary Items &amp; Gain/Loss Sale of Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Gain and loss resulting from infrequent, unusual and
                      nonrecurring events such as the sale of segments of a
                      business or the sale of an entire subsidiary. The
                      disposition could have taken place either in the current
                      accounting period or in past year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Extraordinary Items Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Extraordinary gains and losses divided by the same shares
                      used to calculate per share earnings.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>F</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Financial Leverage
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount of debt in the capital structure of a company
                      used to finance assets. A firm with significantly more
                      debt than equity is considered to be highly leveraged.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Financial Ratios
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Ratios based on the financial data of a company utilized
                      by management, investors and analysts to determine
                      comparative value, profitability, liquidity, leverage, and
                      solvency.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Finished Goods
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Fully-completed products that are considered to be that
                      portion of inventory ready for sale and delivery.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fiscal Year
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any 12-month period a company uses for accounting purposes
                      that may or may not be the same as a calendar year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fiscal Year End Date
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The end date of the completion of a 12-month accounting
                      period that may or may not be the same as end date of the
                      calendar year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Assets % of Common Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Long-term tangible and intangible assets used in the
                      production of goods and services as a percent of common
                      shareholders' equity.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBIT / (Total Interest
                      Expense ) + [(Preferred Dividends) / (1-tax rate)/100]
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its fixed
                      charges calculated by dividing Earnings Before Interest
                      and Taxes by interest expense and preferred dividends
                      adjusted for taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBIT / Dividends on Common
                      Shares
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its common
                      stock dividends calculated by dividing Earnings Before
                      Interest and Taxes by common stock dividends adjusted for
                      taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBIT / Net Interest Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its net
                      interest charges calculated by dividing Earnings Before
                      Interest and Taxes by net interest expense.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBIT / Total Interest
                      Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its total
                      interest charges calculated by dividing Earnings Before
                      Interest and Taxes by total interest expense.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBIT/ (Dividends on Common
                      Shares) + [(Preferred Stock)/ (1-tax rate)/100]
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its total
                      dividends. It is calculated by dividing Earnings Before
                      Interest and Taxes by common and preferred dividends
                      adjusted for taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBITDA / (Dividends on
                      Common Shares + Preferred Stock)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its total
                      dividends. It is calculated by dividing Earnings Before
                      Interest, Taxes, Depreciation and Amortization by common
                      and preferred dividends adjusted for taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBITDA / (Total Interest
                      Expense) + [(Preferred Dividends)/ (1-tax rate)/100]
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its fixed
                      charges. It is calculated by dividing Earnings Before
                      Interest, Taxes, Depreciation and Amortization by interest
                      and preferred dividends adjusted for taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBITDA / Dividends on Common
                      Shares
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its common
                      stock dividends. It is calculated by dividing Earnings
                      Before Interest, Taxes, Depreciation and Amortization by
                      common stock dividends adjusted for taxes.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBITDA / Net Interest
                      Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its net
                      interest expense. It is calculated by dividing Earnings
                      Before Interest, Taxes, Depreciation and Amortization by
                      net interest expense.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fixed Charge Coverage Ratio û EBITDA / Total Interest
                      Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the ability of a company to pay its total
                      interest expense. It is calculated by dividing Earnings
                      Before Interest, Taxes, Depreciation and Amortization by
                      total interest expense.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Free Cash Flow Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Cash that a company generates solely from operations
                      after laying out the money required to maintain or expand
                      its asset base divided by the same shares used to
                      calculate per share earnings. Free cash flow is the amount
                      of cash that a company has left over after it has paid all
                      of its expenses, including investments. It is calculated
                      as operating cash flow (net income plus amortization and
                      depreciation) minus capital expenditures and dividends.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Fully Diluted Earnings Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Earnings per share calculated by dividing net income
                      adjusted to add back preferred dividends of convertible
                      preferred stock and interest expense of convertible bonds
                      by the number of weighted average common shares
                      outstanding. The weighted average number of shares is
                      adjusted by adding shares, assuming all convertible
                      securities such as warrants, options, outstanding
                      convertible preferred shares and convertible bonds, are
                      converted into common.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Funds from Operations % of Current Liabilities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liquidity ratio which is computed as net income plus
                      depreciation, depletion and amortization and other
                      non-cash charges divided by Current Liabilities, expressed
                      as a percentage.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Funds from Operations % of Long Term Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liquidity ratio which is computed as net income plus
                      depreciation, depletion and amortization and other
                      non-cash charges divided by Long-term Debt, expressed as a
                      percentage.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Funds from Operations % of Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liquidity ratio which is computed as net income plus
                      depreciation, depletion and amortization and other
                      non-cash charges divided by Total Capital (Long-term Debt,
                      Minority Interest, Preferred Stock and Common Equity),
                      expressed as a percentage.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Funds from Operations % of Total Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A liquidity ratio which is computed as net income plus
                      depreciation, depletion and amortization and other
                      non-cash charges divided by Total Debt (Short and
                      Long-term Debt), expressed as a percentage.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>G</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Gross Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Pretax revenue or net sales minus cost of goods sold. This
                      measures a company's ability to cover operating expenses.
                      Also called gross margin and gross profit.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Gross Income Margin
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Total sales or revenue minus cost of goods sold divided by
                      total sales or revenue, expressed as a percentage Gross
                      income margin indicates how much a company earns after
                      incurring expenses on the production of its goods and
                      services.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Gross Property, Plant &amp; Equipment Turnover
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio of Net Sales to Gross Property, Plant &amp;
                      Equipment. Gross Property, Plant &amp; Equipment
                      Turnover measures a firm's efficiency in using its assets
                      to generate sales or revenue - the higher the number the
                      better.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>I</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Income Taxes
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An annual tax on any income earned during a fiscal year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Income Taxes Payable
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The sum of income taxes owed by a company and due within
                      one year. Income taxes payable are debts that must be paid
                      off within a given period of time in order to avoid fines
                      and asset confiscation.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Indicated Dividends per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The expected cash dividends per share during the next
                      twelve months. It is usually calculated as four times the
                      most recently reported quarterly dividend per share plus
                      any extra dividend reported during the twelve months
                      period.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Intangible Other Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An asset that is not physical in nature such as goodwill
                      and corporate intellectual property (e.g., brand names,
                      franchise rights, patents, trademarks, copyrights).{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Interest Capitalized
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Interest payable but not yet paid. It is shown on the
                      Income Statement in order to match Net Sales with related
                      expenses.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Interest Expense on Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A fee charged by lenders to a company for borrowing funds
                      which include short-term debt, long-term debt, and
                      capitalized leased obligations.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Inventories % of Current Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio to Current Assets of the value of a company's raw
                      materials, work-in-process goods and finished goods which
                      have not yet been sold. Inventories as a percent of
                      Current Assets is one of the measures of the liquidity and
                      financial strength of a company.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Inventories - Total
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The value of a company's raw materials, work-in-process
                      goods and final goods which have not yet been sold.
                      Inventories are part of a company's current assets since
                      they can be converted into cash easily to pay off
                      short-term debt and other current liabilities.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Inventory to Cash &amp; Equivalents - Number of days
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio computed by dividing the amount of Inventories by
                      average daily Cash and Equivalents. This ratio indicates
                      the structure of Current Assets and is best used in
                      comparative analysis with companies in the same industry
                      sector.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Inventory Turnover
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The number of times the average inventory (stock) is
                      turned over during the year. It is computed by dividing
                      the Cost of Goods Sold by the average of the opening and
                      closing Inventory for the year.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Investment in Associated Companies
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An investment in a company by another larger company which
                      controls more than 50% of the voting stock.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Investment Tax Credits
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An amount by which income tax liability is diminished for
                      companies that make new investments encouraged by the
                      federal government. It is deducted from the tax bill, not
                      from pretax income.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>L</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Liabilities &amp; Shareholders' Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A combination of financial obligations, debt, claims, or
                      potential losses (current and long term) plus common
                      shareholders' investment in a company including paid-in
                      capital and retained earnings.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Long Term Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Financial obligations such as bonds, loans, notes and
                      capitalized obligations, which have maturities greater
                      than one year. It also includes revolving credits and
                      long-term advances from affiliates. Represents the
                      proportion of financial leverage in a company's capital
                      structure.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Long Term Debt Excluding Capitalized Leases
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Long-term financial obligations excluding capitalized
                      lease obligations.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Long Term Receivables
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      All debts, unsettled transactions or other monetary
                      obligations owed to a company due after one year.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>M</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Major Industry
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The major industry sector to which a company belongs.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Market Capitalization
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A measure of the value of the company calculated by
                      multiplying the market price of the common stock by the
                      number of outstanding common shares. Based on this,
                      companies are sometimes classified as large cap, medium
                      cap and small cap.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Market Price - Close
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Closing market price of the company's common stock for a
                      specific ending period like a day, week, quarter or year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Market Price - High
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The high market price of the company's common stock for a
                      day, week, month, quarter or year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Market Price - Low
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The low market price of the company's common stock for a
                      day, week, month, quarter or year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Minority Interest
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount of consolidated net assets that does not belong
                      to the parent company.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Most Recent Quarterly Dividends per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The most recently declared quarterly dividends per share.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Most Recent Quarterly Earnings per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The most recent reported quarterly earnings per share.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>N</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Cash provided by Continuing Operations
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds related directly to the company's income-producing
                      continuing operations (net income, changes in accounts
                      payable, accounts receivable and inventory) reported on
                      the Cash Flow statement of the quarterly and annual
                      reports. Depreciation, depletion, amortization, and
                      deferred taxes are also added back to net income to derive
                      Cash Flow from Continuing Operations.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Change in Liabilities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Year-over-year dollar change in the financial obligations,
                      debt, claims, or potential losses (current and long term)
                      of the company.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Change in Operating Assets and Liabilities Net of
                      Acquisitions &amp; Divestitures
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Net change in operating assets and liabilities excluding
                      recent acquisitions and divestitures.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) by a company after paying all
                      expenses. Net Income = (Total Sales or Revenues - Total
                      Costs and Expenses).
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income (Continuing Operations) Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) by the continuing operations
                      of a company after paying all expenses divided by the
                      weighted average number of common shares outstanding.
                      Earnings Per Share is generally considered to be the
                      single most important variable in determining a company's
                      common share price.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income after Preferred Dividends - available to Common
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) by a company after paying all
                      expenses including preferred dividends.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income Available to Common Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A financial measure usually calculated by dividing a
                      company's. Net Income after Preferred Dividends by
                      weighted average common shares outstanding during the
                      fiscal year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income before Extraordinary Items Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A financial measure usually calculated by dividing a
                      company's. Net Income before Extraordinary Items by
                      weighted average common shares outstanding during the
                      fiscal year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income before Extraordinary Items/Preferred Dividends
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) by a company after paying all
                      expenses but before extraordinary gains or losses and
                      preferred dividends.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income Margin
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Net income divided by net sales or revenues. Measures how
                      much out of each dollar of sales a company keeps as
                      earnings. This ratio is very useful in evaluating the
                      comparative profitability of companies in the same
                      industry.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income per Employee
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Net income in relation to the number of full-time
                      employees. It is calculated by dividing Net Income by the
                      number of full-time employees.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income Per Share - Basic
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) by the company after paying
                      all expenses and preferred dividends divided by the
                      weighted average number of common shares outstanding.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income Per Share - Fully Diluted
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Net Income adjusted for the interest and dividends of
                      convertible securities by the weighted average number of
                      shares outstanding adjusted to reflect the conversion of
                      all warrants, stock options and convertible securities
                      (bonds and preferred stock) if any.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Income Per Share after Extraordinary Items.
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A financial measure usually calculated by dividing a
                      company's Net Income after Extraordinary Items by weighted
                      average common shares outstanding during the fiscal year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Property, Plant and Equipment Turnover
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount of sales generated for every dollar's worth of
                      tangible fixed assets with an expected useful life of over
                      one year after adjusting for depreciation. Net PP&amp;E
                      turnover measures a firm's efficiency of using its assets
                      in generating sales or revenue.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Net Sales or Revenues
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Money received from the sale of goods or services less
                      returns, allowances, discounts and freight out.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Non-Equity Reserves
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Not applicable to U.S. corporations, this item represents
                      reserves such as revaluation reserves, specific reserves,
                      government investment subsidies etc.set aside from
                      shareholders' equity without naming a designated payee.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Non-Operating Interest Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Income generated from the non-operating interest-bearing
                      investments of the company.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>O</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Officers
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A list of the names of key officers of the company with
                      their designation.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Operating Income Margin
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An important measure of profitability calculated as the
                      difference between Net Sales or Revenue of the company and
                      related costs and expenses (cost of goods sold plus
                      operating and administrative expenses) divided by Net
                      Sales or Revenue, expressed as a percentage.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Operating Expenses - Total
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Expenses which arise from the normal operating activities
                      of the business including cost of goods sold plus
                      operating and administrative expenses.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Operating Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The difference between Net Sales or Revenue of the company
                      and related costs and expenses (cost of goods sold plus
                      operating and administrative expenses).
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Operating Income Return on Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A significant measure of comparative profitability. This
                      ratio is calculated by dividing Operating Income by Total
                      Capital.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Accrued Expenses
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Those accrued expenses not included in accrued payroll,
                      interest payable, dividends payable or income taxes
                      payable. These expenses are recognized when incurred even
                      though they are not actually paid in cash.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A residual of assets of the company other than current
                      assets, long-term receivables, investment in
                      unconsolidated subsidiaries, other investments and net
                      property, plant and equipment.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Current Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      All other non-cash current assets besides cash and
                      equivalents, receivables, inventories and prepaid
                      expenses.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Current Liabilities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      All other current liabilities other than accounts payable,
                      short-term debt, accrued payroll, income taxes payable,
                      and dividends payable.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Income/Expense - Net
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Other pretax income net of expenses besides operating
                      income and expense, non-operating interest income and
                      expense, interest expense on short and long term debt,
                      capitalized interest, pretax non-recurring gain or losses
                      and change in reserves.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Investments
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any other long-term investments except those in
                      unconsolidated subsidiaries.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Liabilities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Obligations of the company besides current liabilities,
                      long-term debt, capitalized leased obligations and
                      deferred charges that do not currently require interest
                      payments.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Other Operating Expenses
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      All other operating expenses beside interest expense,
                      salaries and benefits expense, equipment expense and
                      provisions for loan losses.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>P</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Percent (%) Held by Insiders
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent of publicly-held voting shares owned by officers,
                      directors, their families, trusts, foundations and
                      shareholders who individually own 10% or more of the
                      shares.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Dividend Requirements
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount of annual income required to be paid through an
                      actual cash dividend payment to preferred stock owners or
                      the provision for preferred dividends, if in arrears.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company than
                      common shareholders in the event of liquidation. Preferred
                      stock generally has a specified and fixed dividend rate
                      that must be paid out before dividends are paid to common
                      stockholders.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock % of Common Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company in the
                      event of liquidation than common shareholders as a percent
                      of Common Equity.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock % of EBIT
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company in the
                      event of liquidation than common shareholders as a percent
                      of Earnings before Interest and Taxes.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock % of EBITDA
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company in the
                      event of liquidation than common shareholders as a percent
                      of Earnings before Interest, Taxes, Depreciation and
                      Amortization.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock % of Total Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company in the
                      event of liquidation than common shareholders as a percent
                      of Total Assets.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock % of Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A type of ownership (usually non-voting) that has a higher
                      claim on the earnings and assets of the company in the
                      event of liquidation than common shareholders as a percent
                      of Total Capital. Investors can identify the amount of
                      leverage utilized by a specific company and compare it to
                      its industry average or other companies in the same
                      industry to help analyze and evaluate the company's
                      comparative risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Preferred Stock Issued for ESOP
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The total number of preferred shares issued to the
                      Employee Stock Option Plan (ESOP). This includes all the
                      shares that are fully paid for as well as those covered by
                      a pledge made by the company to insure a loan incurred for
                      the purchase of preferred shares.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Prepaid Expenses
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An advance payment, recorded in the current assets on the
                      balance sheet, made by a company for goods or services to
                      be received after the completion of its normal operating
                      cycle.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Prepaid Expenses - % of total assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An advance payment, recorded in the current assets on the
                      balance sheet, made by a company for goods or services to
                      be received after the completion of its normal operating
                      cycle as a percent of Total Assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Pretax Equity in Earnings
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The pretax portion of the earnings (or losses) of a
                      subsidiary whose financial accounts are not consolidated
                      with the parent company's accounts.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Pretax Income
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Income after deducting all tax-deductible expenses.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Pretax Income Margin
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Income after deducting all tax-deductible expenses as a
                      percent of Net Sales or Revenues. This ratio is a key
                      measure of comparative profitability.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Pretax Income Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Income after deducting all tax-deductible expenses divided
                      by the weighted average number of common shares
                      outstanding.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price % Change - 12 Months
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent change in the common stock price per share during
                      the last 12 months.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price % Change - 13 Weeks{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent increase or decrease in common stock price during
                      the past thirteen week period.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price % Change - 4 Weeks
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent increase or decrease in common stock price during
                      the past four week period.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price % Change - 52 Weeks{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent increase or decrease in common stock price during
                      the past fifty-two week period.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price % Change - Week
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Percent increase or decrease in common stock price during
                      the past week.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price/Book Ratio (P/BV)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The relationship of a company's market value to its Common
                      Shareholders' Equity (book value) at the beginning of the
                      year. It is derived by dividing the common stock price by
                      per share book value at the beginning of the period.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price/Earnings Ratio (P/E)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The relationship of the company's common stock price to
                      earnings per share derived by dividing the common stock
                      price by Last 12 Months Earnings. This is the key ratio in
                      the determination of comparative corporate value.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Price/Sales Ratio (P/S)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The relationship of a company's market value to its Net
                      Sales or Revenues at the beginning of the year. It is
                      derived by dividing the common stock price by average per
                      share sales.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Progress Payments &amp; Other
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Periodic payments made to service providers for work on
                      long-term contracts satisfactorily completed to date. Also
                      refers to disbursements by lending firms to contractors
                      under contractual loan arrangements.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Property Plant and Equipment - Gross
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Tangible fixed assets with an expected useful life of over
                      one year before adjusting for depreciation.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Property Plant and Equipment - Net
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Tangible fixed assets with an expected useful life of over
                      one year after adjusting for depreciation.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Provision for Risks and Charges
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Estimated reserves for pension funds, repairs and
                      maintenance, litigation claims, etc.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>Q</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Quarterly Price % Change
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The percent change in a common stock price for a given
                      quarter.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Quarterly Reported Dividends per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Dividends that companies in the U.S. declare quarterly. In
                      some other countries, companies declare semi-annual or
                      annual dividends. Semi-annual dividends are divided by two
                      and annual dividends by four to express such dividends at
                      a quarterly rate.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Quarterly Reported Earnings Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The amount earned (or lost) per share for a quarter after
                      paying all expenses.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Quick Ratio
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Current Assets less Inventory by
                      Current Liabilities. This ratio measures the ability of
                      liquid assets to quickly meet short-term obligations. It
                      is also known as the Acid-Test Ratio.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>R</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Raw Materials
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Natural resources, materials or substances such as oil,
                      iron ore, paper and wood used in the primary production or
                      manufacturing of goods.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Receivables % of Total Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Sales for which money has not yet been collected as a
                      percent of Total Assets. The ratio indicates one of the
                      measures of comparative liquidity and financial strength.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Receivables (Net)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Gross accounts receivable owed to the company less bad
                      debt write-offs.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Receivables - Number of Days
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The average number of days required to collect
                      receivables. It shows the comparative efficiency of the
                      collection policy of the company.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Receivables including Financial Receivables
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Accounts Receivable owed to the company including
                      financial receivables less bad debt write-offs. Companies
                      may use receivables as collateral for short-term loans
                      from bank or factoring entities. The amount a company can
                      borrow depends on the age of the receivables.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Receivables Turnover
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated as Net Sales or Revenues divided by Accounts
                      Receivable, this ratio suggests the number of times the
                      amount of credit sales are collected during the year.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Research &amp; Development Expense
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds expended for Research on improving existing products
                      and the Development of new products, procedures and
                      marketing techniques.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Reserves - Increase/Decrease
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Changes to discretionary Reserves included in net income
                      of European companies.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Retained Earnings
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Earnings not paid out as dividends or allocated to a
                      reserve account, instead kept to reinvest in the company.
                      It is shown as a line item on balance sheet under
                      shareholders equity. It is also known as earned surplus
                      and undistributed earnings or profits.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Retained Earnings Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Earnings not paid out as dividends or allocated to a
                      reserve account, instead kept to reinvest in the company
                      divided by the weighted-average number of common shares
                      outstanding.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Return on Assets (ROA)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Net Income by Total Assets at the
                      beginning of period. This ratio indicates how efficiently
                      management is using a company's Total Assets to generate
                      profits.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Return on Equity (ROE)
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Net Income by Common Shareholders'
                      Equity at the beginning of period. This ratio indicates
                      how efficiently management is using a company's Common
                      Shareholders' Equity to generate profits.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Return on Invested Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Income before interest, taxes and
                      dividends by Total Capital (common shareholders' equity,
                      preferred stock, minority interest and long-term debt
                      including capitalized lease obligations) at the beginning
                      of period. This ratio indicates how efficiently management
                      is using a company's Total Capital to generate profits.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Revenues
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Money received from the sale of goods or services less
                      returns, allowances, discounts and freight out.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>S</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Sales
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Money received from the sale of goods or services less
                      returns, allowances, discounts and freight out.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Sales Per Employee
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Net Sales or Revenues to the
                      number of full-time employees. In comparison with other
                      companies in the same sector, it is a measure of relative
                      employee productivity.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Sales Per Share
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Derived by dividing Net Sales or Revenues over a 12-month
                      period by the weighted-average number of common shares
                      outstanding used to compute Earnings Per Share. The
                      sales-per-share ratio, often in comparison to its common
                      share price, is used to evaluate the success of a
                      company's business activities.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Selling, General &amp; Administrative Expenses
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The sum of all direct (credit, warranty and advertising)
                      and indirect (telephones, interest and postal) selling
                      expenses plus all general and administrative expenses
                      (salaries, commissions, travel for executives and sales
                      persons).{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Share Type
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The name of the security whose prices and dividends are
                      shown with the company's fundamental financial data. The
                      share name is usually shown in the language of the country
                      in which the company is domiciled.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Short Term Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any debt incurred that is due within the accounting cycle
                      usually one year. It is normally shown as a line item in
                      the current liabilities portion of a company's balance
                      sheet or in the notes associated with the financial
                      statements.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Short Term Debt &amp; Current Portion of Long Term Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any debt incurred (both short and long term) that is due
                      within the accounting cycle usually one year. It is
                      normally shown as a line item in the current liabilities
                      portion of a company's balance sheet or in the notes
                      associated with the financial statements.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Short Term Investments
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Investments of excess cash in marketable securities (money
                      market accounts, short-term government bonds, bills, notes
                      and commercial paper, etc.) that can be converted into
                      cash quickly. The liquidity of these financial instruments
                      cause them to be known as cash equivalents.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Stock Exchange Listings
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Stock exchanges on which the company's shares are listed
                      and traded.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Sub Industry
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The classification of each company into a subordinate
                      industry group.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>T</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Tangible Other Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Any asset not included in tangible assets such as assets
                      from discontinued operations, franchises of a specific
                      duration, and advances to suppliers.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Ticker
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      An arrangement of characters (normally alphabetical) which
                      identify a publicly-traded company. When a company is
                      formed and issues securities publicly, it selects a unique
                      ticker symbol which investors and traders use for stock
                      transactions.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      All items owned by a corporation which can be expected to
                      provide future economic benefit including current assets
                      (cash, accounts receivable, inventories, prepaid
                      expenses), long-term receivables, investment in
                      unconsolidated subsidiaries, and net fixed (tangible and
                      intangible) assets.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Assets per Employee{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Total Assets to the number of
                      full-time employees. In comparison with other companies in
                      the same sector, it is a measure of employee efficiency.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Capital % of Total Assets{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Calculated by dividing Total Capital by Total Assets.
                      Investors can identify the amount of leverage utilized by
                      a specific company and compare it to its industry average
                      or other companies in the same industry to help analyze
                      and evaluate the company's comparative risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Capital per Employee{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Total Capital to the number of
                      full-time employees. For a meaningful analysis, however,
                      the ratio should be compared with the industry average and
                      that of other companies in the same industry.
                      Capital-intensive companies would have a higher ratio than
                      labor-intensive companies.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Cash Flow from Operations
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds related directly to the company's income-producing
                      operations (net income, changes in accounts payable,
                      accounts receivable and inventory) reported on the Cash
                      Flow statement of the quarterly and annual reports.
                      Depreciation, depletion, amortization, and deferred taxes
                      are also added back to net income to derive Cash Flow from
                      Operations.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Current Assets % of Net Sales or Revenues
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio to Net Sales or Revenues of the value of all
                      assets that are reasonably expected to be converted into
                      cash usually within one year. Current assets include cash,
                      accounts receivable, inventory, marketable securities,
                      prepaid expenses and other liquid assets. This is a
                      measure of a company's liquidity.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of Common Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A traditional financial leverage ratio determined by
                      dividing short and long-term debt by Common Shareholders'
                      Equity. Investors can identify the amount of leverage
                      utilized by a specific company and compare it to its
                      industry average or other companies in the same industry
                      to help analyze and evaluate the company's risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of EBIT
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A traditional financial leverage ratio determined by
                      dividing short and long-term debt by Earnings before
                      Interest and Taxes (EBIT). Investors can identify the
                      amount of leverage utilized by a specific company and
                      compare it to its industry average or other companies in
                      the same industry to help analyze and evaluate the
                      company's risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of EBITDA
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A traditional financial leverage ratio determined by
                      dividing short and long-term debt by Earnings before
                      Interest, Taxes, Depreciation and Amortization (EBITDA).
                      Investors can identify the amount of leverage utilized by
                      a specific company and compare it to its industry average
                      or other companies in the same industry to help analyze
                      and evaluate the company's risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of Total Assets
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio indicating how much of the company's assets have
                      been financed by debt, calculated by dividing Total Debt
                      by Total Assets. Investors can identify the comparative
                      amount of leverage utilized by a specific company and
                      compare it to its industry average or other companies in
                      the same industry to help analyze the company's risk
                      exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A traditional financial leverage ratio determined by
                      dividing short and long-term debt by Total Capital
                      (long-term debt, minority interest, preferred stock and
                      common stockholders' equity). Investors can identify the
                      amount of leverage utilized by a specific company and
                      compare it to its industry average or other companies in
                      the same industry to help analyze and evaluate the
                      company's risk exposure. Generally, companies who finance
                      a greater portion of their Total Assets via debt are
                      considered riskier than those with lower leverage ratios.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt % of Total Capital and Short Term Debt
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A traditional financial leverage ratio determined by
                      dividing Total Debt by the sum of short-term debt and
                      Total Capital (long-term debt, minority interest,
                      preferred stock and common stockholders' equity).
                      Investors can identify the amount of leverage utilized by
                      a specific company and compare it to its industry average
                      or other companies in the same industry to help analyze
                      and evaluate the company's risk exposure.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Debt per Employee{" "}
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Total Debt to the number of
                      full-time employees. Capital-intensive companies would
                      have a higher ratio, however, for a meaningful analysis
                      the ratio should be compared with the industry average and
                      that of other companies in the same industry.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The capital received from investors (common shareholders,
                      preferred stockowners and minority owners) in exchange for
                      an interest in future earnings of the company.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Liabilities
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      Funds received from the creditors of the company including
                      Total Debt and other Current Liabilities. It can be
                      calculated by subtracting Common Shareholders' Equity from
                      Total Assets.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Liabilities &amp; Shareholders' Equity
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The sum of Current Liabilities, long-term liabilities,
                      preferred stock, minority interest if any, non-equity
                      reserves, preferred stock and Common Shareholders' Equity.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Total Shares Outstanding
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The total number of common shares currently owned by
                      investors including restricted shares held by the
                      company's officers and insiders. This number is usually
                      lower than authorized or issued shares because the company
                      has repurchased outstanding shares and holds them as
                      treasury shares. The number of outstanding shares are
                      recorded in the capital stock section of the liabilities
                      side of the company's balance sheet and are used to
                      compute book value per share and the weighted-average
                      number of outstanding shares, the divisor in basic
                      earnings per share and other important per share ratios.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>W</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Work in Process
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A component of Inventory, the value of any good that is
                      not considered to be a finished good ready for sale.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Working Capital % of Total Capital
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A ratio that is calculated by dividing Working Capital
                      (Current Assets less Current Liabilities) by Total
                      Capital. Investors can identify the level of Working
                      Capital relative to Total Capital by a specific company
                      and compare it to its industry average or other companies
                      in the same industry to help analyze and evaluate the
                      company's risk exposure.{" "}
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Working Capital per Employee
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The ratio of a company's Working Capital to the number of
                      full-time employees. The ratio is best used in comparison
                      with the industry average and that of other companies in
                      the same industry.
                    </td>
                  </tr>

                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Wright Quality Analysis
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      The means by which the fundamental criteria for
                      measurement of quality can be objectively evaluated. These
                      criteria are the specific components of : (1) Investment
                      Acceptance, (2)Financial Strength, (3) Profitability and
                      Stability, and (4) Growth, Wright Quality Ratings are made
                      up of three letters and a number. Each letter rating
                      reflects a composite qualitative measurement of eight
                      individual standards which may be summarized as A:
                      indicating Outstanding, B: Excellent, C: Good, D: Fair, L:
                      Limited, N: Not Rated or * Indeterminable because of
                      instability or recent or prospective developments which
                      cannot yet be reliably evaluated. The number is a
                      composite of eight individual standards, measures the
                      annual Corporate Growth Rate, and may range from 0 to as
                      high as 20.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h3>Y</h3>
              <Table
                responsive
                className="definitions_Of_Financial_Terms"
                border="3"
              >
                <tbody>
                  <tr
                    frame="box"
                    rules="groups"
                    style={{ background: " rgb(40, 104, 148)" }}
                  >
                    <th
                      width="40%"
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Financial Term
                    </th>
                    <th
                      className="summary-header"
                      style={{ color: "#fff", fontWeight: "bold" }}
                    >
                      Term Definition
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td align="left" className="summary-detail" width="40%">
                      {" "}
                      Year to Year % Change
                    </td>
                    <td align="left" className="summary-detail">
                      {" "}
                      A number showing a percent change of a data item over two
                      consecutive years.{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col
            md={2}
            style={{ marginTop: "1em" }}
            // className="pts_isAdvertisement"
          >
            <Advertisement advertisementType="verticalAddUnit1" />
            <br></br>
            <Advertisement advertisementType="verticalAddUnit2" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DefinitionsOfFinancialTerms;
