import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import "./AccountingRatios.css";
import BankAccountingRatios from "./BankAccountingRatios";
import FinancialAccountingRatios from "./FinancialAccountingRatios";
import IndAccountingRatios from "./IndAccountingRatios";
import InsuranceAccountingRatios from "./InsuranceAccountingRatios";
export default class AccountingRatios extends Component {
  constructor(props) {
    super(props);

    let type = props.type;
    this.state = {
      type: type,
      financialRatioData: [],
      render: false,
      data: props.data,
      isPdfReport: props.isPdfReport,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  componentDidMount() {
    let indType = this.state.data.ind_TYPE;
    this.currency = this.state.data.currency;
    this.unit = this.state.data.unit;
    this.setState({
      financialRatioData: this.state.data,
      render: true,
      indType: indType,
    });
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <BankAccountingRatios
                  data={this.state.financialRatioData}
                ></BankAccountingRatios>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InsuranceAccountingRatios
                  data={this.state.financialRatioData}
                ></InsuranceAccountingRatios>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <FinancialAccountingRatios
                  data={this.state.financialRatioData}
                ></FinancialAccountingRatios>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IndAccountingRatios
                  data={this.state.financialRatioData}
                  isPdfReport={this.state.isPdfReport}
                ></IndAccountingRatios>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
