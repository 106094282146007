import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./BankIncomeStatement.css";
export default class BankIncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      render: false,
      actualData: [],
      modalHeader: "",
    };
  }
  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let interestExpenseTotal = [];
      let netInterestIncome = [];
      let nonInterestIncome = [];
      let investmentIncome = [];
      let nonOperatingInterestIncome = [];
      let otherOperatingIncome = [];
      let otherOperatingExpenses = [];
      let provisionForLoanLosses = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.bankIncomeStmtList;

      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        netInterestIncome.push(actualData[i].netInterestIncome);
        nonInterestIncome.push(actualData[i].nonInterestIncome);
        investmentIncome.push(actualData[i].investmentIncome);
        nonOperatingInterestIncome.push(
          actualData[i].nonOperatingInterestIncome
        );
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        provisionForLoanLosses.push(actualData[i].provisionForLoanLosses);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].interestandFeesonLoans = this.convertToMillionaire(
          actualData[i].interestandFeesonLoans
        );
        actualData[i].interestIncomeonFederalFunds = this.convertToMillionaire(
          actualData[i].interestIncomeonFederalFunds
        );
        actualData[i].interestIncomeonBankDeposits = this.convertToMillionaire(
          actualData[i].interestIncomeonBankDeposits
        );
        actualData[i].interestIncomeonGovernmentSecurities =
          this.convertToMillionaire(
            actualData[i].interestIncomeonGovernmentSecurities
          );
        actualData[i].otherInterestorDividendIncome = this.convertToMillionaire(
          actualData[i].otherInterestorDividendIncome
        );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].interestExpenseonBankDeposits = this.convertToMillionaire(
          actualData[i].interestExpenseonBankDeposits
        );
        actualData[i].interestExpenseonFederalFunds = this.convertToMillionaire(
          actualData[i].interestExpenseonFederalFunds
        );
        actualData[i].interestExpenseonOtherBorrowedFunds =
          this.convertToMillionaire(
            actualData[i].interestExpenseonOtherBorrowedFunds
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].netInterestIncome = this.convertToMillionaire(
          actualData[i].netInterestIncome
        );
        actualData[i].nonInterestIncome = this.convertToMillionaire(
          actualData[i].nonInterestIncome
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].nonInterestExpense = this.convertToMillionaire(
          actualData[i].nonInterestExpense
        );
        actualData[i].staffCosts = this.convertToMillionaire(
          actualData[i].staffCosts
        );
        actualData[i].equipmentExpense = this.convertToMillionaire(
          actualData[i].equipmentExpense
        );
        actualData[i].depreciationandDepletion = this.convertToMillionaire(
          actualData[i].depreciationandDepletion
        );
        actualData[i].taxesOtherthanIncomeTaxes = this.convertToMillionaire(
          actualData[i].taxesOtherthanIncomeTaxes
        );
        actualData[i].operatingProvisions = this.convertToMillionaire(
          actualData[i].operatingProvisions
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );

        actualData[i].preferredDividendRequirements =
          actualData[i].preferredDividendRequirements;
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        interestExpenseTotal: interestExpenseTotal,
        netInterestIncome: netInterestIncome,
        nonInterestIncome: nonInterestIncome,
        investmentIncome: investmentIncome,
        nonOperatingInterestIncome: nonOperatingInterestIncome,
        otherOperatingIncome: otherOperatingIncome,
        otherOperatingExpenses: otherOperatingExpenses,
        provisionForLoanLosses: provisionForLoanLosses,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let interestExpenseTotal = [];
      let netInterestIncome = [];
      let nonInterestIncome = [];
      let investmentIncome = [];
      let nonOperatingInterestIncome = [];
      let otherOperatingIncome = [];
      let otherOperatingExpenses = [];
      let provisionForLoanLosses = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.bankIncomeStmtPercentChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        netInterestIncome.push(actualData[i].netInterestIncome);
        nonInterestIncome.push(actualData[i].nonInterestIncome);
        investmentIncome.push(actualData[i].investmentIncome);
        nonOperatingInterestIncome.push(
          actualData[i].nonOperatingInterestIncome
        );
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        provisionForLoanLosses.push(actualData[i].provisionForLoanLosses);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire1(
          actualData[i].interestIncomeTotal
        );
        actualData[i].interestandFeesonLoans = this.convertToMillionaire1(
          actualData[i].interestandFeesonLoans
        );
        actualData[i].interestIncomeonFederalFunds = this.convertToMillionaire1(
          actualData[i].interestIncomeonFederalFunds
        );
        actualData[i].interestIncomeonBankDeposits = this.convertToMillionaire1(
          actualData[i].interestIncomeonBankDeposits
        );
        actualData[i].interestIncomeonGovernmentSecurities =
          this.convertToMillionaire1(
            actualData[i].interestIncomeonGovernmentSecurities
          );
        actualData[i].otherInterestorDividendIncome =
          this.convertToMillionaire1(
            actualData[i].otherInterestorDividendIncome
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire1(
          actualData[i].interestExpenseTotal
        );
        actualData[i].interestExpenseonBankDeposits =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonBankDeposits
          );
        actualData[i].interestExpenseonFederalFunds =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonFederalFunds
          );
        actualData[i].interestExpenseonOtherBorrowedFunds =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonOtherBorrowedFunds
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );
        actualData[i].netInterestIncome = this.convertToMillionaire1(
          actualData[i].netInterestIncome
        );
        actualData[i].nonInterestIncome = this.convertToMillionaire1(
          actualData[i].nonInterestIncome
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire1(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire1(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire1(
          actualData[i].tradingAccountIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire1(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire1(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire1(
          actualData[i].commissionFees
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].nonInterestExpense = this.convertToMillionaire1(
          actualData[i].nonInterestExpense
        );
        actualData[i].staffCosts = this.convertToMillionaire1(
          actualData[i].staffCosts
        );
        actualData[i].equipmentExpense = this.convertToMillionaire1(
          actualData[i].equipmentExpense
        );
        actualData[i].depreciationandDepletion = this.convertToMillionaire1(
          actualData[i].depreciationandDepletion
        );
        actualData[i].taxesOtherthanIncomeTaxes = this.convertToMillionaire1(
          actualData[i].taxesOtherthanIncomeTaxes
        );
        actualData[i].operatingProvisions = this.convertToMillionaire1(
          actualData[i].operatingProvisions
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire1(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      this.setState({
        render: true,
        modalHeader: " Income Statement -Common Size",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        interestExpenseTotal: interestExpenseTotal,
        netInterestIncome: netInterestIncome,
        nonInterestIncome: nonInterestIncome,
        investmentIncome: investmentIncome,
        nonOperatingInterestIncome: nonOperatingInterestIncome,
        otherOperatingIncome: otherOperatingIncome,
        otherOperatingExpenses: otherOperatingExpenses,
        provisionForLoanLosses: provisionForLoanLosses,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let interestExpenseTotal = [];
      let netInterestIncome = [];
      let nonInterestIncome = [];
      let investmentIncome = [];
      let nonOperatingInterestIncome = [];
      let otherOperatingIncome = [];
      let otherOperatingExpenses = [];
      let provisionForLoanLosses = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.bankIncomeStmtYoYChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        netInterestIncome.push(actualData[i].netInterestIncome);
        nonInterestIncome.push(actualData[i].nonInterestIncome);
        investmentIncome.push(actualData[i].investmentIncome);
        nonOperatingInterestIncome.push(
          actualData[i].nonOperatingInterestIncome
        );
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        provisionForLoanLosses.push(actualData[i].provisionForLoanLosses);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire1(
          actualData[i].interestIncomeTotal
        );
        actualData[i].interestandFeesonLoans = this.convertToMillionaire1(
          actualData[i].interestandFeesonLoans
        );
        actualData[i].interestIncomeonFederalFunds = this.convertToMillionaire1(
          actualData[i].interestIncomeonFederalFunds
        );
        actualData[i].interestIncomeonBankDeposits = this.convertToMillionaire1(
          actualData[i].interestIncomeonBankDeposits
        );
        actualData[i].interestIncomeonGovernmentSecurities =
          this.convertToMillionaire1(
            actualData[i].interestIncomeonGovernmentSecurities
          );
        actualData[i].otherInterestorDividendIncome =
          this.convertToMillionaire1(
            actualData[i].otherInterestorDividendIncome
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire1(
          actualData[i].interestExpenseTotal
        );
        actualData[i].interestExpenseonBankDeposits =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonBankDeposits
          );
        actualData[i].interestExpenseonFederalFunds =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonFederalFunds
          );
        actualData[i].interestExpenseonOtherBorrowedFunds =
          this.convertToMillionaire1(
            actualData[i].interestExpenseonOtherBorrowedFunds
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );
        actualData[i].netInterestIncome = this.convertToMillionaire1(
          actualData[i].netInterestIncome
        );
        actualData[i].nonInterestIncome = this.convertToMillionaire1(
          actualData[i].nonInterestIncome
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire1(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire1(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire1(
          actualData[i].tradingAccountIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire1(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire1(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire1(
          actualData[i].commissionFees
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].nonInterestExpense = this.convertToMillionaire1(
          actualData[i].nonInterestExpense
        );
        actualData[i].staffCosts = this.convertToMillionaire1(
          actualData[i].staffCosts
        );
        actualData[i].equipmentExpense = this.convertToMillionaire1(
          actualData[i].equipmentExpense
        );
        actualData[i].depreciationandDepletion = this.convertToMillionaire1(
          actualData[i].depreciationandDepletion
        );
        actualData[i].taxesOtherthanIncomeTaxes = this.convertToMillionaire1(
          actualData[i].taxesOtherthanIncomeTaxes
        );
        actualData[i].operatingProvisions = this.convertToMillionaire1(
          actualData[i].operatingProvisions
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire1(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      this.setState({
        render: true,
        modalHeader: " Income Statement -Yr-yr % change",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        interestExpenseTotal: interestExpenseTotal,
        netInterestIncome: netInterestIncome,
        nonInterestIncome: nonInterestIncome,
        investmentIncome: investmentIncome,
        nonOperatingInterestIncome: nonOperatingInterestIncome,
        otherOperatingIncome: otherOperatingIncome,
        otherOperatingExpenses: otherOperatingExpenses,
        provisionForLoanLosses: provisionForLoanLosses,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let interestExpenseTotal = [];
      let netInterestIncome = [];
      let nonInterestIncome = [];
      let investmentIncome = [];
      let nonOperatingInterestIncome = [];
      let otherOperatingIncome = [];
      let otherOperatingExpenses = [];
      let provisionForLoanLosses = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.bankIncomeStmt3YearAverageList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        netInterestIncome.push(actualData[i].netInterestIncome);
        nonInterestIncome.push(actualData[i].nonInterestIncome);
        investmentIncome.push(actualData[i].investmentIncome);
        nonOperatingInterestIncome.push(
          actualData[i].nonOperatingInterestIncome
        );
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        provisionForLoanLosses.push(actualData[i].provisionForLoanLosses);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].interestandFeesonLoans = this.convertToMillionaire(
          actualData[i].interestandFeesonLoans
        );
        actualData[i].interestIncomeonFederalFunds = this.convertToMillionaire(
          actualData[i].interestIncomeonFederalFunds
        );
        actualData[i].interestIncomeonBankDeposits = this.convertToMillionaire(
          actualData[i].interestIncomeonBankDeposits
        );
        actualData[i].interestIncomeonGovernmentSecurities =
          this.convertToMillionaire(
            actualData[i].interestIncomeonGovernmentSecurities
          );
        actualData[i].otherInterestorDividendIncome = this.convertToMillionaire(
          actualData[i].otherInterestorDividendIncome
        );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].interestExpenseonBankDeposits = this.convertToMillionaire(
          actualData[i].interestExpenseonBankDeposits
        );
        actualData[i].interestExpenseonFederalFunds = this.convertToMillionaire(
          actualData[i].interestExpenseonFederalFunds
        );
        actualData[i].interestExpenseonOtherBorrowedFunds =
          this.convertToMillionaire(
            actualData[i].interestExpenseonOtherBorrowedFunds
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].netInterestIncome = this.convertToMillionaire(
          actualData[i].netInterestIncome
        );
        actualData[i].nonInterestIncome = this.convertToMillionaire(
          actualData[i].nonInterestIncome
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].nonInterestExpense = this.convertToMillionaire(
          actualData[i].nonInterestExpense
        );
        actualData[i].staffCosts = this.convertToMillionaire(
          actualData[i].staffCosts
        );
        actualData[i].equipmentExpense = this.convertToMillionaire(
          actualData[i].equipmentExpense
        );
        actualData[i].depreciationandDepletion = this.convertToMillionaire(
          actualData[i].depreciationandDepletion
        );
        actualData[i].taxesOtherthanIncomeTaxes = this.convertToMillionaire(
          actualData[i].taxesOtherthanIncomeTaxes
        );
        actualData[i].operatingProvisions = this.convertToMillionaire(
          actualData[i].operatingProvisions
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      this.setState({
        render: true,
        modalHeader: "Income Statement -3 yr Averages",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        interestExpenseTotal: interestExpenseTotal,
        netInterestIncome: netInterestIncome,
        nonInterestIncome: nonInterestIncome,
        investmentIncome: investmentIncome,
        nonOperatingInterestIncome: nonOperatingInterestIncome,
        otherOperatingIncome: otherOperatingIncome,
        otherOperatingExpenses: otherOperatingExpenses,
        provisionForLoanLosses: provisionForLoanLosses,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let interestExpenseTotal = [];
      let netInterestIncome = [];
      let nonInterestIncome = [];
      let investmentIncome = [];
      let nonOperatingInterestIncome = [];
      let otherOperatingIncome = [];
      let otherOperatingExpenses = [];
      let provisionForLoanLosses = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.bankIncomeStmtList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        netInterestIncome.push(actualData[i].netInterestIncome);
        nonInterestIncome.push(actualData[i].nonInterestIncome);
        investmentIncome.push(actualData[i].investmentIncome);
        nonOperatingInterestIncome.push(
          actualData[i].nonOperatingInterestIncome
        );
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        provisionForLoanLosses.push(actualData[i].provisionForLoanLosses);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].interestandFeesonLoans = this.convertToMillionaire(
          actualData[i].interestandFeesonLoans
        );
        actualData[i].interestIncomeonFederalFunds = this.convertToMillionaire(
          actualData[i].interestIncomeonFederalFunds
        );
        actualData[i].interestIncomeonBankDeposits = this.convertToMillionaire(
          actualData[i].interestIncomeonBankDeposits
        );
        actualData[i].interestIncomeonGovernmentSecurities =
          this.convertToMillionaire(
            actualData[i].interestIncomeonGovernmentSecurities
          );
        actualData[i].otherInterestorDividendIncome = this.convertToMillionaire(
          actualData[i].otherInterestorDividendIncome
        );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].interestExpenseonBankDeposits = this.convertToMillionaire(
          actualData[i].interestExpenseonBankDeposits
        );
        actualData[i].interestExpenseonFederalFunds = this.convertToMillionaire(
          actualData[i].interestExpenseonFederalFunds
        );
        actualData[i].interestExpenseonOtherBorrowedFunds =
          this.convertToMillionaire(
            actualData[i].interestExpenseonOtherBorrowedFunds
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].netInterestIncome = this.convertToMillionaire(
          actualData[i].netInterestIncome
        );
        actualData[i].nonInterestIncome = this.convertToMillionaire(
          actualData[i].nonInterestIncome
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].gainsLossesonSaleofSecuritiesPretax =
          this.convertToMillionaire(
            actualData[i].gainsLossesonSaleofSecuritiesPretax
          );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].nonInterestExpense = this.convertToMillionaire(
          actualData[i].nonInterestExpense
        );
        actualData[i].staffCosts = this.convertToMillionaire(
          actualData[i].staffCosts
        );
        actualData[i].equipmentExpense = this.convertToMillionaire(
          actualData[i].equipmentExpense
        );
        actualData[i].depreciationandDepletion = this.convertToMillionaire(
          actualData[i].depreciationandDepletion
        );
        actualData[i].taxesOtherthanIncomeTaxes = this.convertToMillionaire(
          actualData[i].taxesOtherthanIncomeTaxes
        );
        actualData[i].operatingProvisions = this.convertToMillionaire(
          actualData[i].operatingProvisions
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );

        actualData[i].preferredDividendRequirements =
          actualData[i].preferredDividendRequirements;
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        interestExpenseTotal: interestExpenseTotal,
        netInterestIncome: netInterestIncome,
        nonInterestIncome: nonInterestIncome,
        investmentIncome: investmentIncome,
        nonOperatingInterestIncome: nonOperatingInterestIncome,
        otherOperatingIncome: otherOperatingIncome,
        otherOperatingExpenses: otherOperatingExpenses,
        provisionForLoanLosses: provisionForLoanLosses,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    }
  }
  getDataInGraphicalForm(graphType) {
    // if (tab === "Interim Income Statement Actual") {
    if (graphType === "Net Sales or Revenues ") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netSalesOrRevenues,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales or Revenues ",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Income - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.interestIncomeTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Income - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest and Fees on Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestandFeesonLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest and Fees on Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Income on Federal Funds") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestIncomeonFederalFunds,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Income on Federal Funds",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Income on Bank Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestIncomeonBankDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Income on Bank Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Income on Government Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestIncomeonGovernmentSecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Income on Government Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Interest or Dividend Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherInterestorDividendIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Interest or Dividend Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.interestExpenseTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense on Bank Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestExpenseonBankDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense on Bank Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense on Federal Funds") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestExpenseonFederalFunds,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense on Federal Funds",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense on Other Borrowed Funds") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestExpenseonOtherBorrowedFunds,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense on Other Borrowed Funds",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense on Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestExpenseonDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense on Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Capitalized") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestCapitalized,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Capitalized",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Interest Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netInterestIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Interest Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Interest Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.nonInterestIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Interest Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investment Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Foreign Exchange Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ForeignExchangeIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Foreign Exchange Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Gains/Losses on Sale of Securities - Pretax") {
      this.setState({
        graphDetails: {
          graphValues: this.state.GainsLossesonSaleofSecuritiesPretax,
          labelData: this.state.LabelList,
          yAxisTitle: "Gains/Losses on Sale of Securities - Pretax",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Operating Interest Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.nonOperatingInterestIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Operating Interest Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Trading Account Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TradingAccountIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Trading Account Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Trusts & Fiduciary Income/Commission & Fees") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TrustsandFiduciaryIncomeCommissionandFees,
          labelData: this.state.LabelList,
          yAxisTitle: "Trusts & Fiduciary Income/Commission & Fees",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Trust Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TrustIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Trust Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Commission & Fees") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommissionandFees,
          labelData: this.state.LabelList,
          yAxisTitle: "Commission & Fees",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherOperatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Interest Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NonInterestExpense,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Interest Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Staff Costs") {
      this.setState({
        graphDetails: {
          graphValues: this.state.StaffCosts,
          labelData: this.state.LabelList,
          yAxisTitle: "Staff Costs",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Equipment Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EquipmentExpense,
          labelData: this.state.LabelList,
          yAxisTitle: "Equipment Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Depreciation and Depletion") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DepreciationandDepletion,
          labelData: this.state.LabelList,
          yAxisTitle: "Depreciation and Depletion",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Taxes Other than Income Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TaxesOtherthanIncomeTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Taxes Other than Income Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Provisions") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OperatingProvisions,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Provisions",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Operating Expenses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherOperatingExpenses,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Operating Expenses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Provision for Loan Losses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.provisionForLoanLosses,
          labelData: this.state.LabelList,
          yAxisTitle: "Provision for Loan Losses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Credit - Pretax") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ExtraordinaryCreditPretax,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Credit - Pretax",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Charge - Pretax") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ExtraordinaryChargePretax,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Charge - Pretax",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Income/Expense - Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherIncomeExpenseNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Income/Expense - Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Equity in Earnings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PretaxEquityinEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Equity in Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Reserves - Increase/Decrease") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReservesIncreaseDecrease,
          labelData: this.state.LabelList,
          yAxisTitle: "Reserves - Increase/Decrease",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Earnings before Interest & Taxes(EBIT)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.earningsBeforeInterestTaxesEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Earnings before Interest & Taxes(EBIT)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Income Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncomeTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Income Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MinorityInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Equity in Earnings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EquityinEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Equity in Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "After Tax Other Income/Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AfterTaxOtherIncomeExpense,
          labelData: this.state.LabelList,
          yAxisTitle: "After Tax Other Income/Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Discontinued Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DiscontinuedOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Discontinued Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Income before Extraordinary Items/Preferred Dividends"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomebeforeExtraordinaryItemsPreferredDividends,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income before Extraordinary Items/Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Items & Gain/Loss Sale of Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ExtraordinaryItemsandGainLossSaleofAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Items & Gain/Loss Sale of Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Dividend Requirements") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredDividendRequirements,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Dividend Requirements",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income after Preferred Dividends") {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomeafterPreferredDividendsavailabletoCommon,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income after Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-2em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <span className="unit_str">
                All figures in <span>{this.toTitleCase(this.state.unit)}</span>{" "}
                of {this.state.currency}
              </span>
              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Net Sales or Revenues</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netSalesorRevenues}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Interest Income - Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestIncomeTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest and Fees on Loans</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestandFeesonLoans}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Income on Federal Funds</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestIncomeonFederalFunds}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Income on Bank Deposits</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestIncomeonBankDeposits}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Income on Government Securities</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestIncomeonGovernmentSecurities}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Interest or Dividend Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherInterestorDividendIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense - Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Bank Deposits</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseonBankDeposits}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Federal Funds</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseonFederalFunds}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Other Borrowed Funds</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseonOtherBorrowedFunds}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Debt</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseonDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Capitalized </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestCapitalized}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Interest Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.netInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Interest Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.nonInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Investment Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.investmentIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Foreign Exchange Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.foreignExchangeIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Gains/Losses on Sale of Securities - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.gainsLossesonSaleofSecuritiesPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trading Account Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.tradingAccountIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trusts & Fiduciary Income/Commission & Fees</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.trustsFiduciaryIncomeCommissionFees}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trust Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.trustIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Commission & Fees</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.commissionFees}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Interest Expense </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.nonInterestExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Staff Costs</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.staffCosts}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equipment Expense</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.equipmentExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation and Depletion </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.depreciationandDepletion}</td>;
                  })}
                </tr>
                <tr>
                  <th>Taxes Other than Income Taxes </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.taxesOtherthanIncomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Provisions</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingProvisions}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Provision for Loan Losses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.provisionforLoanLosses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Reserves - Increase/Decrease</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Earnings before Interest, Taxes, Depreciation & Amortization
                    (EBITDA)
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {
                          data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Earnings before Interest & Taxes (EBIT)</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Income Taxes </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Minority Interest </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income before Extraordinary Items/Preferred Dividends
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {
                          data.netIncomebeforeExtraordinaryItemsPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income after Preferred Dividends - available to Common
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {data.netIncomeafterPreferredDividendsavailabletoCommon}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                <span className="unit_str">
                  All figures in{" "}
                  <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                  {this.state.currency}
                </span>
                <Table responsive className="financial_analysis1">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Sales or Revenues
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales or Revenues")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSalesorRevenues}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Interest Income - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Interest Income - Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestIncomeTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest and Fees on Loans</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestandFeesonLoans}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Income on Federal Funds</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestIncomeonFederalFunds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Income on Bank Deposits</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestIncomeonBankDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Income on Government Securities</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.interestIncomeonGovernmentSecurities}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Interest or Dividend Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherInterestorDividendIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Interest Expense - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Interest Expense - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Bank Deposits</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseonBankDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Federal Funds</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseonFederalFunds}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Other Borrowed Funds</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.interestExpenseonOtherBorrowedFunds}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Debt</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseonDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Capitalized </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestCapitalized}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Interest Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Interest Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.netInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Non-Interest Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Non-Interest Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.nonInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investment Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investment Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.investmentIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Foreign Exchange Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.foreignExchangeIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Gains/Losses on Sale of Securities - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.gainsLossesonSaleofSecuritiesPretax}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Non-Operating Interest Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Non-Operating Interest Income"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.nonOperatingInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Trading Account Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.tradingAccountIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Trusts & Fiduciary Income/Commission & Fees</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.trustsFiduciaryIncomeCommissionFees}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Trust Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.trustIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Commission & Fees</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.commissionFees}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Operating Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Interest Expense </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.nonInterestExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Staff Costs</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.staffCosts}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equipment Expense</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.equipmentExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation and Depletion </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.depreciationandDepletion}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Taxes Other than Income Taxes </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.taxesOtherthanIncomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Operating Provisions</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingProvisions}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Operating Expenses</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Provision for Loan Losses</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.provisionforLoanLosses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Operating Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Credit - Pretax </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryCreditPretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Charge - Pretax </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryChargePretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Income/Expense - Net</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherIncomeExpenseNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Pretax Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxEquityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves - Increase/Decrease</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.reservesIncreaseDecrease}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Earnings before Interest, Taxes, Depreciation &
                      Amortization (EBITDA)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Earnings before Interest & Taxes (EBIT)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Earnings before Interest & Taxes(EBIT)"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Pretax Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pretax Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.incomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.equityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>After Tax Other Income/Expense</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.afterTaxOtherIncomeExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Discontinued Operations </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.discontinuedOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income before Extraordinary Items/Preferred Dividends
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income before Extraordinary Items/Preferred Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomebeforeExtraordinaryItemsPreferredDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items & Gain/Loss Sale of Assets </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividend Requirements</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.preferredDividendRequirements}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income after Preferred Dividends - available to Common
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income after Preferred Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomeafterPreferredDividendsavailabletoCommon
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#162c50",
                    textAlign: "center",
                    float: "left",
                  }}
                ></Label>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
