import { GET_ADVANCE_FILTER, SET_ADVANCE_FILTER } from "./advanceFilterTypes";

export const getAdvanceFilter = () => {
  return {
    type: GET_ADVANCE_FILTER,
  };
};

export const setAdvanceFilter = (data) => {
  return {
    type: SET_ADVANCE_FILTER,
    payload: data,
  };
};

export default { getAdvanceFilter, setAdvanceFilter };
