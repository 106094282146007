import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import CompanySnapshotPage from "../ptsComponentes/companySnapshotPage/CompanySnapshotPage";
import CompanySnapshotPageContent from "../ptsComponentes/companySnapshotPageContent/CompanySnapshotPageContent";
import Footer from "../ptsComponentes/footer/Footer";
import routes from "../ptsComponentes/views";
import "./CompanyDetailLayout.css";

export class CompanyDetailLayout extends Component {
  headerLoginRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="app-body">
          {/* <CompanyDetailLayoutHeader
            history={this.props.history}
            {...this.props}
            ref={this.headerLoginRef}
            routes={routes}
          ></CompanyDetailLayoutHeader> */}
          <CompanySnapshotPage header={this.headerLoginRef}>
            <CompanySnapshotPageContent>
              <Switch>
                {routes.map((page, key) => (
                  <Route
                    path={page.path}
                    component={page.component}
                    key={key}
                  />
                ))}
                <Redirect to="/" />
              </Switch>
            </CompanySnapshotPageContent>
          </CompanySnapshotPage>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyDetailLayout);
