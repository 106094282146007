import React, { useState, useEffect } from "react";
import FetchServerData from "../../provider/FetchServerData";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Row, Col } from "reactstrap";
import "./NewsSlider.css";
import moment from "moment";
function NewsSlider(props) {
  const [newsList1, setNewsList1] = useState([]);
  const [newsList2, setNewsList2] = useState([]);
  useEffect(() => {
    async function fetchNews1() {
      FetchServerData.callPostService("./news/getSource1", {})
        .then((output) => {
          if (output.status === "SUCCESS") {
            let responseData = [];
            responseData = output.data;
            setNewsList1(responseData);
          }
        })
        .catch((error) => {});
    }
    fetchNews1();
    fetchNews2();
  }, []);

  function fetchNews2() {
    FetchServerData.callPostService("./news/getSource2", {})
      .then((output) => {
        if (output.status === "SUCCESS") {
          let responseData = [];
          responseData = output.data;
          setNewsList2(responseData);
        }
      })
      .catch((error) => {});
  }

  let newsArr1 = [];
  newsList1.forEach((item, index) => {
    newsArr1.push(
      <>
        <Row>
          {/* <Col md={2}>
            <span style={{ color: "#fff" }}>
              {moment(item.realtime_start).format("dddd")}
            </span>
          </Col> */}
          <Col md={12} className="news_slide_box">
            <span>
              <b
                style={{
                  color: "#229954",

                  fontSize: "0.8em",
                }}
              >
                {item.name}
              </b>
              <span
                style={{
                  float: "right",
                  fontSize: "0.8em",
                }}
              >
                {moment(item.realtime_start).format("ddd,MMM D,yyyy")}
              </span>
            </span>
            <br></br>
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#fff",
                padding: "0px 12px",
                float: "right",
                textDecoration: "underline",
                background: "#4154f1",
                fontSize: "0.7em",
                marginRight: "0.5em",
              }}
            >
              Read More
            </a>
            <br></br>
            <p
              className=""
              style={{
                fontSize: "0.7em",
                padding: "3px 5px 0px 5px",
                color: "#212121",
                lineHeight: "18px",
                maxHeight: "54px",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {item.notes}
            </p>
          </Col>
        </Row>
      </>
    );
  });
  let newsArr2 = [];
  newsList2.forEach((item, index) => {
    newsArr2.push(
      <>
        <Row>
          {/* <Col md={2}>
            <span style={{ color: "#fff" }}>
              {moment(item.realtime_start).format("dddd")}
            </span>
          </Col> */}
          <Col md={12} className="news_slide_box">
            <span>
              <b
                style={{
                  color: "#229954",

                  fontSize: "0.8em",
                }}
              >
                {item.name}
              </b>
              <span
                style={{
                  float: "right",
                  fontSize: "0.8em",
                }}
              >
                {moment(item.realtime_start).format("ddd,MMM D,yyyy")}
              </span>
            </span>
            <br></br>
            <a
              href={item.link}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#fff",
                padding: "0px 12px",
                float: "right",
                textDecoration: "underline",
                background: "#4154f1",
                fontSize: "0.7em",
                marginRight: "0.5em",
              }}
            >
              Read More
            </a>
            <br></br>
            <p
              className=""
              style={{
                fontSize: "0.7em",
                padding: "3px 5px 0px 5px",
                color: "#212121",
                lineHeight: "18px",
                maxHeight: "54px",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {item.notes}
            </p>
          </Col>
        </Row>
      </>
    );
  });
  if (props.type === "slider1") {
    return (
      <div
        id="nt-example2-container"
        style={{ textAlign: "left", padding: "0 10px" }}
      >
        <AliceCarousel
          autoPlay
          autoPlayControls={false}
          autoPlayStrategy="default"
          autoPlayInterval={4000}
          animationDuration={300}
          animationType="fadeout"
          infinite
          touchTracking={false}
          disableDotsControls
          disableButtonsControls
          items={newsArr1}
        />
      </div>
    );
  } else {
    return (
      <div
        id="nt-example2-container"
        style={{ textAlign: "left", padding: "0 10px" }}
      >
        <AliceCarousel
          autoPlay
          autoPlayControls={false}
          autoPlayStrategy="default"
          autoPlayInterval={4000}
          animationDuration={300}
          animationType="fadeout"
          infinite
          touchTracking={false}
          disableDotsControls
          disableButtonsControls
          items={newsArr2}
        />
      </div>
    );
  }
}

export default NewsSlider;
