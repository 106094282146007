import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./IncomeStatement.css";

export default class IncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      showModal: false,
      render: false,
      actual: false,
      common: false,
      year: false,
      IncomeStatementActualData: [],
      IncomeStatementCommonSizeData: [],
      IncomeStatementYearToYearData: [],
      IncomeStatementThreeYearData: [],
      modalHeader: "",
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  getDataInGraphicalForm(graphType, tab) {
    if (tab === "Income Statement Actual") {
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      for (let i = 0; i < this.state.IncomeStatementActualData.length; i++) {
        this.state.IncomeStatementActualData[i].netSalesorRevenues =
          this.state.IncomeStatementActualData[i].netSalesorRevenues;
        incomeStatementList.push(
          this.state.IncomeStatementActualData[i].netSalesorRevenues
        );
        LabelList.push(this.state.IncomeStatementActualData[i].fiscalYear);
        costOfGoodSold.push(
          this.state.IncomeStatementActualData[i].costOfGoodsSold
        );
        grossIncome.push(this.state.IncomeStatementActualData[i].grossIncome);
        operatingExpensesTotal.push(
          this.state.IncomeStatementActualData[i].operatingExpensesTotal
        );
        operatingIncome.push(
          this.state.IncomeStatementActualData[i].operatingIncome
        );
        EBITDA.push(
          this.state.IncomeStatementActualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(
          this.state.IncomeStatementActualData[i]
            .earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(this.state.IncomeStatementActualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          this.state.IncomeStatementActualData[i]
            .netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          this.state.IncomeStatementActualData[i]
            .netIncomeafterPreferredDividendsAavailabletoCommon
        );
      }
      if (graphType === "Net Sales or Revenues") {
        this.setState({
          graphDetails: {
            graphValues: incomeStatementList,
            labelData: LabelList,
            yAxisTitle: "Net Sales or Revenues",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cost Of Good Sold") {
        this.setState({
          graphDetails: {
            graphValues: costOfGoodSold,
            labelData: LabelList,
            yAxisTitle: "Cost Of Good Sold",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Gross Income") {
        this.setState({
          graphDetails: {
            graphValues: grossIncome,
            labelData: LabelList,
            yAxisTitle: "Gross Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Expenses - Total") {
        this.setState({
          graphDetails: {
            graphValues: operatingExpensesTotal,
            labelData: LabelList,
            yAxisTitle: "Operating Expenses - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Income") {
        this.setState({
          graphDetails: {
            graphValues: operatingIncome,
            labelData: LabelList,
            yAxisTitle: "Operating Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBITDA") {
        this.setState({
          graphDetails: {
            graphValues: EBITDA,
            labelData: LabelList,
            yAxisTitle: "EBITDA",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBIT") {
        this.setState({
          graphDetails: {
            graphValues: EBIT,
            labelData: LabelList,
            yAxisTitle: "EBIT",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Pretax Income") {
        this.setState({
          graphDetails: {
            graphValues: pretaxIncome,
            labelData: LabelList,
            yAxisTitle: "Pretax Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (
        graphType ===
        "Net Income before Extraordinary Items/Preferred Dividends"
      ) {
        this.setState({
          graphDetails: {
            graphValues: netIncomeBeforeExtraordinaryItemsPreferredDividends,
            labelData: LabelList,
            yAxisTitle:
              "Net Income before Extraordinary Items/Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else {
        this.setState({
          graphDetails: {
            graphValues: netIncomeAfterPreferredDividends,
            labelData: LabelList,
            yAxisTitle: "Net Income after Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else if (tab === "Income Statement Common Size") {
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      for (
        let i = 0;
        i < this.state.IncomeStatementCommonSizeData.length;
        i++
      ) {
        incomeStatementList.push(
          this.state.IncomeStatementCommonSizeData[i].netSalesorRevenues
        );
        LabelList.push(this.state.IncomeStatementCommonSizeData[i].fiscalYear);
        costOfGoodSold.push(
          this.state.IncomeStatementCommonSizeData[i].costOfGoodsSold
        );
        grossIncome.push(
          this.state.IncomeStatementCommonSizeData[i].grossIncome
        );
        operatingExpensesTotal.push(
          this.state.IncomeStatementCommonSizeData[i].operatingExpensesTotal
        );
        operatingIncome.push(
          this.state.IncomeStatementCommonSizeData[i].operatingIncome
        );
        EBITDA.push(
          this.state.IncomeStatementCommonSizeData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(
          this.state.IncomeStatementCommonSizeData[i]
            .earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(
          this.state.IncomeStatementCommonSizeData[i].pretaxIncome
        );
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          this.state.IncomeStatementCommonSizeData[i]
            .netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          this.state.IncomeStatementCommonSizeData[i]
            .netIncomeafterPreferredDividendsAavailabletoCommon
        );
      }
      if (graphType === "Net Sales or Revenues") {
        this.setState({
          graphDetails: {
            graphValues: incomeStatementList,
            labelData: LabelList,
            yAxisTitle: "Net Sales or Revenues",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cost Of Good Sold") {
        this.setState({
          graphDetails: {
            graphValues: costOfGoodSold,
            labelData: LabelList,
            yAxisTitle: "Cost Of Good Sold",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Gross Income") {
        this.setState({
          graphDetails: {
            graphValues: grossIncome,
            labelData: LabelList,
            yAxisTitle: "Gross Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Expenses - Total") {
        this.setState({
          graphDetails: {
            graphValues: operatingExpensesTotal,
            labelData: LabelList,
            yAxisTitle: "Operating Expenses - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Income") {
        this.setState({
          graphDetails: {
            graphValues: operatingIncome,
            labelData: LabelList,
            yAxisTitle: "Operating Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBITDA") {
        this.setState({
          graphDetails: {
            graphValues: EBITDA,
            labelData: LabelList,
            yAxisTitle: "EBITDA",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBIT") {
        this.setState({
          graphDetails: {
            graphValues: EBIT,
            labelData: LabelList,
            yAxisTitle: "EBIT",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Pretax Income") {
        this.setState({
          graphDetails: {
            graphValues: pretaxIncome,
            labelData: LabelList,
            yAxisTitle: "Pretax Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (
        graphType ===
        "Net Income before Extraordinary Items/Preferred Dividends"
      ) {
        this.setState({
          graphDetails: {
            graphValues: netIncomeBeforeExtraordinaryItemsPreferredDividends,
            labelData: LabelList,
            yAxisTitle:
              "Net Income before Extraordinary Items/Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else {
        this.setState({
          graphDetails: {
            graphValues: netIncomeAfterPreferredDividends,
            labelData: LabelList,
            yAxisTitle: "Net Income after Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else if (tab === "Income Statement Year-Year") {
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      for (
        let i = 0;
        i < this.state.IncomeStatementYearToYearData.length;
        i++
      ) {
        incomeStatementList.push(
          this.state.IncomeStatementYearToYearData[i].netSalesorRevenues
        );
        LabelList.push(this.state.IncomeStatementYearToYearData[i].fiscalYear);
        costOfGoodSold.push(
          this.state.IncomeStatementYearToYearData[i].costOfGoodsSold
        );
        grossIncome.push(
          this.state.IncomeStatementYearToYearData[i].grossIncome
        );
        operatingExpensesTotal.push(
          this.state.IncomeStatementYearToYearData[i].operatingExpensesTotal
        );
        operatingIncome.push(
          this.state.IncomeStatementYearToYearData[i].operatingIncome
        );
        EBITDA.push(
          this.state.IncomeStatementYearToYearData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(
          this.state.IncomeStatementYearToYearData[i]
            .earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(
          this.state.IncomeStatementYearToYearData[i].pretaxIncome
        );
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          this.state.IncomeStatementYearToYearData[i]
            .netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          this.state.IncomeStatementYearToYearData[i]
            .netIncomeafterPreferredDividendsAavailabletoCommon
        );
      }
      if (graphType === "Net Sales or Revenues") {
        this.setState({
          graphDetails: {
            graphValues: incomeStatementList,
            labelData: LabelList,
            yAxisTitle: "Net Sales or Revenues",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cost Of Good Sold") {
        this.setState({
          graphDetails: {
            graphValues: costOfGoodSold,
            labelData: LabelList,
            yAxisTitle: "Cost Of Good Sold",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Gross Income") {
        this.setState({
          graphDetails: {
            graphValues: grossIncome,
            labelData: LabelList,
            yAxisTitle: "Gross Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Expenses - Total") {
        this.setState({
          graphDetails: {
            graphValues: operatingExpensesTotal,
            labelData: LabelList,
            yAxisTitle: "Operating Expenses - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Income") {
        this.setState({
          graphDetails: {
            graphValues: operatingIncome,
            labelData: LabelList,
            yAxisTitle: "Operating Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBITDA") {
        this.setState({
          graphDetails: {
            graphValues: EBITDA,
            labelData: LabelList,
            yAxisTitle: "EBITDA",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBIT") {
        this.setState({
          graphDetails: {
            graphValues: EBIT,
            labelData: LabelList,
            yAxisTitle: "EBIT",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Pretax Income") {
        this.setState({
          graphDetails: {
            graphValues: pretaxIncome,
            labelData: LabelList,
            yAxisTitle: "Pretax Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (
        graphType ===
        "Net Income before Extraordinary Items/Preferred Dividends"
      ) {
        this.setState({
          graphDetails: {
            graphValues: netIncomeBeforeExtraordinaryItemsPreferredDividends,
            labelData: LabelList,
            yAxisTitle:
              "Net Income before Extraordinary Items/Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else {
        this.setState({
          graphDetails: {
            graphValues: netIncomeAfterPreferredDividends,
            labelData: LabelList,
            yAxisTitle: "Net Income after Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    } else {
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      for (let i = 0; i < this.state.IncomeStatementThreeYearData.length; i++) {
        incomeStatementList.push(
          this.state.IncomeStatementThreeYearData[i].netSalesorRevenues
        );
        LabelList.push(this.state.IncomeStatementThreeYearData[i].fiscalYear);
        costOfGoodSold.push(
          this.state.IncomeStatementThreeYearData[i].costOfGoodsSold
        );
        grossIncome.push(
          this.state.IncomeStatementThreeYearData[i].grossIncome
        );
        operatingExpensesTotal.push(
          this.state.IncomeStatementThreeYearData[i].operatingExpensesTotal
        );
        operatingIncome.push(
          this.state.IncomeStatementThreeYearData[i].operatingIncome
        );
        EBITDA.push(
          this.state.IncomeStatementThreeYearData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(
          this.state.IncomeStatementThreeYearData[i]
            .earningsbeforeInterestTaxesEBIT
        );
        pretaxIncome.push(
          this.state.IncomeStatementThreeYearData[i].pretaxIncome
        );
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          this.state.IncomeStatementThreeYearData[i]
            .netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          this.state.IncomeStatementThreeYearData[i]
            .netIncomeafterPreferredDividendsAavailabletoCommon
        );
      }
      if (graphType === "Net Sales or Revenues") {
        this.setState({
          graphDetails: {
            graphValues: incomeStatementList,
            labelData: LabelList,
            yAxisTitle: "Net Sales or Revenues",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Cost Of Good Sold") {
        this.setState({
          graphDetails: {
            graphValues: costOfGoodSold,
            labelData: LabelList,
            yAxisTitle: "Cost Of Good Sold",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Gross Income") {
        this.setState({
          graphDetails: {
            graphValues: grossIncome,
            labelData: LabelList,
            yAxisTitle: "Gross Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Expenses - Total") {
        this.setState({
          graphDetails: {
            graphValues: operatingExpensesTotal,
            labelData: LabelList,
            yAxisTitle: "Operating Expenses - Total",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Operating Income") {
        this.setState({
          graphDetails: {
            graphValues: operatingIncome,
            labelData: LabelList,
            yAxisTitle: "Operating Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBITDA") {
        this.setState({
          graphDetails: {
            graphValues: EBITDA,
            labelData: LabelList,
            yAxisTitle: "EBITDA",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "EBIT") {
        this.setState({
          graphDetails: {
            graphValues: EBIT,
            labelData: LabelList,
            yAxisTitle: "EBIT",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (graphType === "Pretax Income") {
        this.setState({
          graphDetails: {
            graphValues: pretaxIncome,
            labelData: LabelList,
            yAxisTitle: "Pretax Income",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else if (
        graphType ===
        "Net Income before Extraordinary Items/Preferred Dividends"
      ) {
        this.setState({
          graphDetails: {
            graphValues: netIncomeBeforeExtraordinaryItemsPreferredDividends,
            labelData: LabelList,
            yAxisTitle:
              "Net Income before Extraordinary Items/Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      } else {
        this.setState({
          graphDetails: {
            graphValues: netIncomeAfterPreferredDividends,
            labelData: LabelList,
            yAxisTitle: "Net Income after Preferred Dividends",
            xAxisTitle: "Year",
            yAxisTitleSign: "",
          },
          showModal: true,
          graphType: graphType,
        });
      }
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.actualData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      let actualData = this.state.data.incomeStmtList;
      // if (actualData.length > 0) {
      //   actualData.reverse();
      // }
      for (let i = 0; i < actualData.length; i++) {
        actualData[i].netSalesorRevenues = actualData[i].netSalesorRevenues;
        incomeStatementList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].fiscalYear);
        costOfGoodSold.push(actualData[i].costOfGoodsSold);
        grossIncome.push(actualData[i].grossIncome);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        EBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(actualData[i].earningsbeforeInterestTaxesEBIT);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
        );
        actualData[i].costOfGoodsSold = this.convertToMillionaire(
          actualData[i].costOfGoodsSold
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        IncomeStatementActualData: actualData,
        costOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        netIncomeBeforeExtraordinaryItemsPreferredDividends:
          netIncomeBeforeExtraordinaryItemsPreferredDividends,
        netIncomeAfterPreferredDividends: netIncomeAfterPreferredDividends,
        actual: true,
        common: false,
        year: false,
        labelData: LabelList,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      this.actualData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      let actualData = this.state.data.incomeStmtPercentChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        incomeStatementList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].fiscalYear);
        costOfGoodSold.push(actualData[i].costOfGoodsSold);
        grossIncome.push(actualData[i].grossIncome);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        EBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(actualData[i].earningsbeforeInterestTaxesEBIT);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
        );
        actualData[i].netSalesOrRevenues = this.convertToMillionaire1(
          actualData[i].netSalesOrRevenues
        );

        actualData[i].costOfGoodsSold = this.convertToMillionaire1(
          actualData[i].costOfGoodsSold
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire1(
            actualData[i].depreciationDepletionAmortization
          );

        actualData[i].grossIncome = this.convertToMillionaire1(
          actualData[i].grossIncome
        );

        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );

        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );

        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );

        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );

        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );

        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );

        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );

        // actualData[i].reservesIncreaseDecrease =
        //   actualData[i].reservesIncreaseDecrease );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          this.convertToMillionaire1(actualData[i].reservesIncreaseDecrease)
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );

        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );

        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );

        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );

        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );

        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );

        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );

        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );

        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );

        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );

        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense !== null
            ? actualData[i].afterTaxOtherIncomeExpense
            : "0"
        );
        // actualData[i].afterTaxOtherIncomeExpense );

        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );

        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );

        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );

        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );

        actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        modalHeader: " Income Statement -Common Size",
        actualData: actualData,
        IncomeStatementCommonSizeData: actualData,
        ostOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        netIncomeBeforeExtraordinaryItemsPreferredDividends:
          netIncomeBeforeExtraordinaryItemsPreferredDividends,
        netIncomeAfterPreferredDividends: netIncomeAfterPreferredDividends,
        actual: true,
        actual: false,
        common: true,
        year: false,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      this.actualData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      let actualData = this.state.data.incomeStmtYoYChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        incomeStatementList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].fiscalYear);
        costOfGoodSold.push(actualData[i].costOfGoodsSold);
        grossIncome.push(actualData[i].grossIncome);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        EBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(actualData[i].earningsbeforeInterestTaxesEBIT);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
        );
        actualData[i].netSalesOrRevenues = this.convertToMillionaire1(
          actualData[i].netSalesOrRevenues
        );

        actualData[i].costOfGoodsSold = this.convertToMillionaire1(
          actualData[i].costOfGoodsSold
        );

        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire1(
            actualData[i].depreciationDepletionAmortization
          );

        actualData[i].grossIncome = this.convertToMillionaire1(
          actualData[i].grossIncome
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );

        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );

        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );

        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );

        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        // actualData[i].reservesIncreaseDecrease =
        //   actualData[i].reservesIncreaseDecrease);
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        // actualData[i].pretaxEquityinEarnings =
        //   actualData[i].pretaxEquityinEarnings);

        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );

        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );

        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );

        actualData[i].interestExpenseonDebt = this.convertToMillionaire1(
          actualData[i].interestExpenseonDebt
        );

        actualData[i].interestCapitalized = this.convertToMillionaire1(
          actualData[i].interestCapitalized
        );

        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );

        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );

        // actualData[i].minorityInterest = actualData[i].minorityInterest);
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        // actualData[i].equityinEarnings = actualData[i].equityinEarnings);
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        // actualData[i].afterTaxOtherIncomeExpense =
        //   actualData[i].afterTaxOtherIncomeExpense);
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        // actualData[i].discontinuedOperations =
        //   actualData[i].discontinuedOperations);
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );

        // actualData[i].extraordinaryItemsGainLossSaleofAssets =
        //   actualData[i].extraordinaryItemsGainLossSaleofAssets);
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        // actualData[i].preferredDividendRequirements =
        //   actualData[i].preferredDividendRequirements);
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        modalHeader: " Income Statement -Yr-yr % change",
        actualData: actualData,
        IncomeStatementYearToYearData: actualData,
        ostOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        netIncomeBeforeExtraordinaryItemsPreferredDividends:
          netIncomeBeforeExtraordinaryItemsPreferredDividends,
        netIncomeAfterPreferredDividends: netIncomeAfterPreferredDividends,
        actual: true,
        actual: false,
        common: false,
        year: true,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
              labels: {
                style: {
                  colors: "#808b96 ",
                },
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            labels: {
              style: {
                colors: "#808b96 ",
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      this.actualData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      let actualData = this.state.data.incomeStmt3YearAverageList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        incomeStatementList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].fiscalYear);
        costOfGoodSold.push(actualData[i].costOfGoodsSold);
        grossIncome.push(actualData[i].grossIncome);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        EBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(actualData[i].earningsbeforeInterestTaxesEBIT);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
        );
        actualData[i].netSalesOrRevenues = this.convertToMillionaire(
          actualData[i].netSalesOrRevenues
        );
        actualData[i].costOfGoodsSold = this.convertToMillionaire(
          actualData[i].costOfGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        modalHeader: "Income Statement -3 yr Averages",
        actualData: actualData,
        IncomeStatementThreeYearData: actualData,
        ostOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        netIncomeBeforeExtraordinaryItemsPreferredDividends:
          netIncomeBeforeExtraordinaryItemsPreferredDividends,
        netIncomeAfterPreferredDividends: netIncomeAfterPreferredDividends,
        actual: true,
        actual: true,
        common: false,
        year: false,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            type: "year",
          },
          yaxis: {
            title: {
              text: "values",
            },
            min: 0,
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else {
      this.actualData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let netIncomeBeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeAfterPreferredDividends = [];
      let actualData = this.state.data.incomeStmtList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        incomeStatementList.push(actualData[i].netSalesorRevenues);
        LabelList.push(actualData[i].fiscalYear);
        costOfGoodSold.push(actualData[i].costOfGoodsSold);
        grossIncome.push(actualData[i].grossIncome);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        EBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        EBIT.push(actualData[i].earningsbeforeInterestTaxesEBIT);
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomeBeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeAfterPreferredDividends.push(
          actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
        );
        actualData[i].costOfGoodsSold = this.convertToMillionaire(
          actualData[i].costOfGoodsSold
        );

        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].interestExpenseonDebt = this.convertToMillionaire(
          actualData[i].interestExpenseonDebt
        );
        actualData[i].interestCapitalized = this.convertToMillionaire(
          actualData[i].interestCapitalized
        );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsAavailabletoCommon
          );
      }
      // this.actualData = actualData;
      this.setState({
        modalHeader: "Income Statement -Actual ",
        actualData: actualData,
        IncomeStatementActualData: actualData,
        costOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        netIncomeBeforeExtraordinaryItemsPreferredDividends:
          netIncomeBeforeExtraordinaryItemsPreferredDividends,
        netIncomeAfterPreferredDividends: netIncomeAfterPreferredDividends,
        actual: true,
        common: false,
        year: false,
        labelData: LabelList,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-3em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              {/* <TableContainer component={Paper}>
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ fontSize: "smalle" }}

                > */}
              <Row>
                <Col md={8} style={{ padding: "9px" }}>
                  {this.state.actual && (
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  )}

                  {this.state.common && (
                    <>
                      <span className="unit_str">
                        {" "}
                        Figures are expressed as Percent of Net Sales or
                        Revenues.
                      </span>
                      <br></br>
                      <span className="unit_str">
                        Net Sales or Revenues are in in{" "}
                        <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                        {this.state.currency}
                      </span>
                    </>
                  )}

                  {this.state.year && (
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  )}
                </Col>

                <Col md={4}></Col>
              </Row>

              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.actualData.map((data, index) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Net Sales or Revenues</span>
                  </th>
                  {this.state.actualData.map((data, index) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netSalesorRevenues}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Cost of Goods Sold</span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.costOfGoodsSold}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation, Depletion & Amortization</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.depreciationDepletionAmortization}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Gross Income</span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.grossIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Selling, General & Administrative Expenses</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.sellingGeneralAdministrativeExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Operating Expenses - Total</span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.operatingExpensesTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Operating Income</span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.operatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Reserves - Increase/Decrease</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings </th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">
                      {" "}
                      Earnings before Interest, Taxes, Depreciation &
                      Amortization (EBITDA)
                    </span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return (
                      <td>
                        {
                          data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">
                      Earnings before Interest & Taxes (EBIT)
                    </span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Debt</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.interestExpenseonDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Capitalized</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.interestCapitalized}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Pretax Income</span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return <td>{data.pretaxIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Income Taxes</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings </th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense </th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations </th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">
                      {" "}
                      Net Income before Extraordinary Items/Preferred Dividends
                    </span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return (
                      <td>
                        {
                          data.netIncomebeforeExtraordinaryItemsPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets </th>
                  {this.state.actualData.map((data, index) => {
                    return (
                      <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.actualData.map((data, index) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">
                      Net Income after Preferred Dividends - available to Common
                    </span>
                  </th>

                  {this.state.actualData.map((data, index) => {
                    return (
                      <td>
                        {
                          data.netIncomeafterPreferredDividendsAavailabletoCommon
                        }
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "9px" }}>
                    {this.state.actual && (
                      <span className="unit_str">
                        All figures in{" "}
                        <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                        {this.state.currency}
                      </span>
                    )}

                    {this.state.common && (
                      <>
                        <span className="unit_str">
                          {" "}
                          Figures are expressed as Percent of Net Sales or
                          Revenues.
                        </span>
                        <br></br>
                        <span className="unit_str">
                          Net Sales or Revenues are in{" "}
                          <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                          {this.state.currency}
                        </span>
                      </>
                    )}

                    {this.state.year && (
                      <span className="unit_str">
                        Figures are the Percent Changes from the Prior Year.
                      </span>
                    )}
                  </Col>

                  <Col md={4}></Col>
                </Row>

                <Table responsive className="financial_analysis1">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.actualData.map((data, index) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Net Sales or Revenues</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Sales or Revenues",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data, index) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSalesorRevenues}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Cost of Goods Sold</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cost Of Good Sold",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.costOfGoodsSold}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation, Depletion & Amortization</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.depreciationDepletionAmortization}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Gross Income</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Gross Income",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.grossIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Selling, General & Administrative Expenses</th>
                    {this.state.actualData.map((data, index) => {
                      return (
                        <td>{data.sellingGeneralAdministrativeExpenses}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Operating Expenses</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.otherOperatingExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Operating Expenses - Total</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Operating Expenses - Total",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.operatingExpensesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Operating Income</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Operating Income",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.operatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Credit - Pretax</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.extraordinaryCreditPretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Charge - Pretax</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.extraordinaryChargePretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Operating Interest Income</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.nonOperatingInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves - Increase/Decrease</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.reservesIncreaseDecrease}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Pretax Equity in Earnings </th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.pretaxEquityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Income/Expense - Net</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.otherIncomeExpenseNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        {" "}
                        Earnings before Interest, Taxes, Depreciation &
                        Amortization (EBITDA)
                      </span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EBITDA", this.state.type)
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return (
                        <td>
                          {
                            data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        Earnings before Interest & Taxes (EBIT)
                      </span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EBIT", this.state.type)
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Debt</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.interestExpenseonDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Capitalized</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.interestCapitalized}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Pretax Income</span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Pretax Income",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return <td>{data.pretaxIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.incomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equity in Earnings </th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.equityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>After Tax Other Income/Expense </th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.afterTaxOtherIncomeExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Discontinued Operations </th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.discontinuedOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        {" "}
                        Net Income before Extraordinary Items/Preferred
                        Dividends
                      </span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income before Extraordinary Items/Preferred Dividends",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return (
                        <td>
                          {
                            data.netIncomebeforeExtraordinaryItemsPreferredDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items & Gain/Loss Sale of Assets </th>
                    {this.state.actualData.map((data, index) => {
                      return (
                        <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividend Requirements</th>
                    {this.state.actualData.map((data, index) => {
                      return <td>{data.preferredDividendRequirements}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">
                        Net Income after Preferred Dividends - available to
                        Common
                      </span>
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income after Preferred Dividends",
                            this.state.type
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.actualData.map((data, index) => {
                      return (
                        <td>
                          {
                            data.netIncomeafterPreferredDividendsAavailabletoCommon
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                      type="flowOfFunds"
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
