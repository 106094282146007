import {
  GET_COMPANY_SNAPSHOT,
  SET_COMPANY_SNAPSHOT,
} from "./companySnapshotType";

const initialState = {
  companyName: "",
};

const companySnapshotReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_SNAPSHOT:
      return {
        ...state,
      };
    case SET_COMPANY_SNAPSHOT:
      return {
        ...state,
        companyName: action.payload,
      };
    default:
      return state;
  }
};

export default companySnapshotReducer;
