import TextField from "@material-ui/core/TextField";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { default as VisibilityIcon } from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { countries } from "country-data";
import MaterialTable from "material-table";
import React, { Component, forwardRef } from "react";
import { Button } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import "react-tabs/style/react-tabs.css";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import GenericSearch from "../search/GenericSearch";
import "./TopHundredItem.css";

export default class TopHundredItem extends Component {
  constructor(props) {
    super(props);

    this.tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Visibility: forwardRef((props, ref) => (
        <VisibilityIcon {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
    };

    let columns = [];
    let type = props.type;
    let countries = Utils.getCountryData();
    this.state = {
      countryList: countries,
      columns: columns,
      type: type,
      topHundredListData: [],
      chartLabelData: [],
      chartValuesData: [],
      render: false,
      chartData: {},
      tableHeaderBackground: "",
      tableRowBackground: "",
      heading: "",
      countryAdvanceList: [],
      selectedCountry: {
        code: 0,
        country: "All",
      },
      industryAdvanceList: [],
      selectedIndustry: "All",
      criteriaAdvanceList: [],
      selectedCriteria: {
        rankName: "MktCap",
        dropDownEntry: "Market Capitalization",
      },
      currentCriteria: "Market Capitalization",
      wrightQualityRating: true,
      unitValue: "",
    };
  }

  componentDidMount() {
    this.getStaticDataOfTopHunderedCountryList();
    this.getStaticDataOfTopHunderedIndustryList();
    this.getStaticDataOfCriteriaList();
    this.getAdvanceListData();
  }

  newLegendClickHandler() {}
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  getDocumentTypeForRow(rowData) {
    return (
      rowData.country +
      " " +
      (
        <ReactCountryFlag
          countryCode={rowData.codeCountry}
          svg
          style={{
            fontSize: "1.5em",
            marginLeft: "0.3em",
          }}
        />
      )
    );
  }
  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    if (rowData.country.toUpperCase() === "KOREA (SOUTH)") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/KR/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp; &nbsp;
          {" " + rowData.country}
        </span>
      );
    } else if (rowData.country.toUpperCase() === "COTE D'IVOIRE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/CI/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp; &nbsp;
          {" " + rowData.country}
        </span>
      );
    } else if (rowData.country.toUpperCase() === "VIETNAM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/VN/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp; &nbsp;
          {" " + rowData.country}
        </span>
      );
    } else if (rowData.country.toUpperCase() === "UNITED KINGDOM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/GB/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp; &nbsp;
          {" " + rowData.country}
        </span>
      );
    } else {
      getCountryWithFlag = (
        <span>
          {
            <img
              alt="flag"
              src={`https://www.countryflags.io/${rowData.codeCountry}/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
          &nbsp; &nbsp;
          {" " + rowData.country}
        </span>
      );
    }
    return getCountryWithFlag;
  }

  getGraphData(url, postObject) {
    let color = "";
    let rowcolor = "";

    color = "#4682B4";
    rowcolor = "rgb(22,160,133,0.1)";

    FetchServerData.callPostService(url, postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data.searchList;
          let chartLabelData = [];
          let chartValuesData = [];
          for (let i = 0; i < data.length; i++) {
            chartLabelData.push(data[i].ticker);
            chartValuesData.push(data[i].topValue);
          }
          var items = chartValuesData.slice(0, 25);
          var items1 = chartLabelData.slice(0, 25);

          this.setState({
            render: true,

            tableHeaderBackground: color,
            tableRowBackground: rowcolor,
            chartData: {
              labels: items1,
              datasets: [
                {
                  label: this.state.type,
                  data: items,
                  backgroundColor: color,
                },
              ],
            },
            series: [
              {
                name: this.state.type,
                data: items,
              },
            ],
            options: {
              chart: {
                type: "bar",
                height: 350,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: true,
                  type: "x",
                  resetIcon: {
                    offsetX: -10,
                    offsetY: 0,
                    fillColor: "#fff",
                    strokeColor: "#37474F",
                  },
                  selection: {
                    background: "#90CAF9",
                    border: "#0D47A1",
                  },
                },
              },
              grid: {
                show: false,
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: true,
                  },
                },
              },

              title: {
                text: undefined,
                align: "left",
              },
              colors: [color],
              legend: {
                position: "top",
                show: true,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: items1,
              },
              yaxis: {},
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  getStaticDataOfTopHunderedCountryList() {
    const postObject = {};

    FetchServerData.callPostService("top100/getStaticData", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          const array = output.data.countryList;
          const newCountryList = {
            code: 0,
            country: "All",
          };

          const newData = array.slice(0);
          newData.splice(array, 0, newCountryList);

          this.setState({
            countryAdvanceList: newData,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleRegionChange(event, values, reason) {
    this.setState({
      selectedCountry: values,
    });
  }

  getStaticDataOfTopHunderedIndustryList() {
    const postObject = {};

    FetchServerData.callPostService("top100/getStaticData", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          const array = output.data.industryList;
          const newIndustry = "All";

          const newData = array.slice(0);
          newData.splice(array, 0, newIndustry);

          this.setState({
            industryAdvanceList: newData,
          });
          this.getSelectedIndustryData(this.state.selectedIndustry);
        } else {
        }
      })
      .catch((error) => {});
  }
  handleIndustryChange(event, values, reason) {
    this.setState({
      selectedIndustry: values,
    });
  }

  getStaticDataOfCriteriaList() {
    const postObject = {};

    FetchServerData.callPostService("top100/getStaticData", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          this.setState({
            criteriaAdvanceList: data.sectionCriteriaList,
          });
          this.getSelectedCriteriaData(this.state.selectedCriteria);
        } else {
        }
      })
      .catch((error) => {});
  }
  handleCriteriaChange(event, values, reason) {
    this.setState({
      selectedCriteria: values,
    });
  }

  getAdvanceListData() {
    var industryVar = this.state.selectedIndustry;
    var countryVar = this.state.selectedCountry.code;
    var criteriaVar = this.state.selectedCriteria.rankName;

    var postObject = {};
    if (countryVar === 0 || countryVar === undefined) {
      countryVar = "";
    }

    postObject = {
      industry: industryVar,
      country: countryVar,
      sectorCriteria: criteriaVar,
    };
    // }

    this.getGraphData("top100/advanceSearch", postObject);

    FetchServerData.callPostService("top100/advanceSearch", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data.searchList;

          for (let i = 0; i < this.state.countryList.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (
                this.toTitleCase(data[j].country) ===
                this.state.countryList[i].name
              ) {
                data[j].codeCountry = this.state.countryList[i].alpha2;
              }
            }
          }

          for (let i = 0; i < data.length; i++) {
            data[i].rowNumber = i + 1;
          }

          if (
            output.data.topField === "MktCap" ||
            output.data.topField === "Sales"
          ) {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = "$ " + data[i].topValue;
            }
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = data[i].topValue + " %";
            }
          }

          if (this.state.selectedCriteria.dropDownEntry === undefined) {
            this.setState({
              topHundredListData: data,
              wrightQualityRating: false,
              unitValue: output.data.unit,
              render: true,
            });
          } else {
            this.setState({
              topHundredListData: data,
              currentCriteria: this.state.selectedCriteria.dropDownEntry,
              wrightQualityRating: false,
              unitValue: output.data.unit,
              render: true,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  toCompanyDetails = (e, companyData) => {
    window.location.href = `/company_details?` + companyData.cusip;
  };

  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      return (
        <>
          <section className="breadcrumbs">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4>Advanced Top 100 Lists</h4>
                </div>
                <div className="col-lg-6">
                  <GenericSearch history={this.props.history} />
                </div>
              </div>
            </div>
          </section>
          <Row>
            <Col md={6}>
              <div className="advdivstyle">
                <label className="advanceDivStyle">
                  Companies Ranked by{" "}
                  <span style={{ color: "#4682b4" }}>
                    {" "}
                    {this.state.currentCriteria}{" "}
                  </span>
                </label>
                <div className="advDivStyl">
                  {this.state.currentCriteria === "Market Capitalization" ||
                  this.state.currentCriteria === "Sales" ? (
                    <div>
                      Figures in{" "}
                      <span style={{ color: "#4682b4" }}>
                        <i>{this.state.unitValue}</i> of U.S. Dollars{" "}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <div className="advanceFeature">
            <div className="advFeatureDiv">
              <Row className="rowAlign">
                <Col md={3} style={{ float: "right" }} className="mobStyle">
                  <h6 className="exchange_h6">COUNTRY</h6>
                  <Autocomplete
                    type="text"
                    id="tags-outlined"
                    options={this.state.countryAdvanceList}
                    getOptionLabel={(option) => option.country}
                    value={this.state.selectedCountry}
                    filterSelectedOption
                    onChange={this.handleRegionChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </Col>
                <Col
                  md={3}
                  style={{ float: "right", margin: "0px 0px 0px 45px" }}
                  className="mobStyle"
                >
                  <h6 className="exchange_h6">INDUSTRY</h6>
                  <Autocomplete
                    type="text"
                    id="tags-outlined"
                    options={this.state.industryAdvanceList}
                    getOptionLabel={(option) => option}
                    value={this.state.selectedIndustry}
                    filterSelectedOptions
                    onChange={this.handleIndustryChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </Col>
                <Col
                  md={3}
                  style={{ float: "right", margin: "0px 0px 0px 45px" }}
                  className="mobStyle"
                >
                  <h6 className="exchange_h6">SELECTION CRITERIA</h6>
                  <Autocomplete
                    type="text"
                    id="tags-outlined"
                    options={this.state.criteriaAdvanceList}
                    getOptionLabel={(option) => option.dropDownEntry}
                    value={this.state.selectedCriteria}
                    filterSelectedOptions
                    onChange={this.handleCriteriaChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </Col>
                <Col md={2} style={{ padding: "15px 0px 0px 0px" }}>
                  <Button
                    style={{ float: "right", margin: "30px 65px 0px 0px" }}
                    className="btnMobCss"
                    onClick={this.getAdvanceListData.bind(this)}
                  >
                    Go
                  </Button>
                </Col>
              </Row>
            </div>

            <Row>
              <Col md={12}>
                <Card style={{ marginBottom: "1em" }}></Card>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h6>{this.state.heading}</h6>

                <MaterialTable
                  icons={this.tableIcons}
                  title="Top 100 Lists"
                  columns={[
                    {
                      title: "Rank",
                      field: "rowNumber",
                      align: "left",
                      width: "2%",
                    },
                    {
                      title: "Company",
                      field: "name",
                      align: "left",
                      width: "13%",
                    },
                    {
                      title: "Ticker",
                      field: "ticker",
                      align: "left",
                      width: "10%",
                      sorting: false,
                    },
                    {
                      title: "Wright Quality Rating",
                      field: "wqr",
                      align: "center",
                      width: "18%",
                      hidden: this.state.wrightQualityRating,
                      cellStyle: {
                        fontWeight: "bold",
                      },
                    },
                    {
                      title: this.state.currentCriteria,
                      field: "topValue",
                      align: "right",
                      width: "28%",
                      customSort: (a, b) => {
                        let newA = a.topValue;

                        let charA = newA
                          .toString()
                          .substring(newA.toString().length - 1);

                        if (newA && newA.toString().charAt(0) === "$") {
                          newA = newA
                            .toString()
                            .substring(1, newA.toString().length);
                        } else if (a && charA === "%") {
                          newA = a.topValue
                            .toString()
                            .substring(0, newA.toString().length - 1);
                        }

                        let newB = b.topValue;
                        let charB = newB
                          .toString()
                          .substring(newB.toString().length - 1);
                        if (newB && newB.toString().charAt(0) === "$") {
                          newB = newB
                            .toString()
                            .substring(1, newB.toString().length);
                        } else if (b && charB === "%") {
                          newB = newB
                            .toString()
                            .substring(0, newB.toString().length - 1);
                        }

                        return newA - newB;
                      },
                    },

                    {
                      title: "Country",
                      render: (rowData) => this.getCountryWithFlag(rowData),
                      align: "left",
                      width: "39%",
                      padding: "0px 0px 0px 30px",
                      customSort: (a, b) => {
                        return a.country
                          .toString()
                          .localeCompare(b.country.toString());
                      },
                    },
                  ]}
                  data={this.state.topHundredListData}
                  options={{
                    search: false,
                    sorting: true,
                    pageSizeOptions: [10, 25, 50, 75, 100],
                    actionsColumnIndex: -1,
                    pageSize: 100,
                    paging: false,
                    headerStyle: {
                      backgroundColor: this.state.tableHeaderBackground,
                      fontWeight: "bold",
                      color: "#fff",
                      align: "center",
                    },
                    rowStyle: {
                      height: "10px",
                      backgroundColor: this.state.tableRowBackground,
                    },
                    filtering: false,
                  }}
                  style={{
                    fontSize: "14px",
                  }}
                  onRowClick={(event, rowData) => {
                    this.toCompanyDetails(event, rowData);
                    event.stopPropagation();
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}
