import TextField from "@material-ui/core/TextField";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import {
  default as Search,
  default as SearchIcon,
} from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { default as VisibilityIcon } from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { countries } from "country-data";
import MaterialTable from "material-table";
import moment from "moment";
import React, { Component, forwardRef } from "react";
import { Bar } from "react-chartjs-2";
import ReactCountryFlag from "react-country-flag";
import { Slide } from "react-slideshow-image";
import "react-tabs/style/react-tabs.css";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import GenericButton from "../buttons/GenericButton";
import Advertisement from "../ciadvertisement/Advertisement";
import GenericSearch from "../search/GenericSearch";
import "./TopHundredFilter.css";

export default class TopHundredFilter extends Component {
  constructor(props) {
    super(props);

    this.tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Visibility: forwardRef((props, ref) => (
        <VisibilityIcon {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
    };
    let countries = Utils.getCountryData();
    this.state = {
      countryList: countries,
      topHundredListData: [],
      chartLabelData: [],
      chartValuesData: [],
      render: true,
      chartData: {},
      tableHeaderBackground: "",
      tableRowBackground: "",
      heading: "",
      countryAdvanceList: [],
      selectedCountry: {
        code: 0,
        country: "All",
      },
      industryAdvanceList: [],
      selectedIndustry: "All",
      criteriaAdvanceList: [],
      selectedCriteria: {
        rankName: "MktCap",
        dropDownEntry: "Market Capitalization",
      },
      currentCriteria: "Market Capitalization",
      currentCriteriaHeading: "Market Capitalization",
      wrightQualityRating: true,
      unitValue: "",
      criteriaVar: "Market Capitalization",
      isPeriodDate: true,
      isDate: false,
      isWqrTopValue: true,
      isTopValue: false,
      isPercentileData: false,
      isUsdData: false,
      isPlainData: false,
      isDataDisplay: false,
    };
  }

  componentDidMount() {
    this.getTopHundredStaticData();
    this.getAdvanceListData();
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  getDocumentTypeForRow(rowData) {
    return (
      rowData.country +
      " " +
      (
        <ReactCountryFlag
          countryCode={rowData.codeCountry}
          svg
          style={{
            fontSize: "1.5em",
            marginLeft: "-1em",
          }}
        />
      )
    );
  }
  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    getCountryWithFlag = (
      <span>
        {Utils.getCountryFlag(rowData.country)}
        {rowData.country}
      </span>
    );
    return getCountryWithFlag;
  }

  getGraphData(advanceSearchList) {
    let color = "";

    color = "#4682B4";

    let data = advanceSearchList;
    let chartLabelData = [];
    let chartValuesData = [];
    for (let i = 0; i < data.length; i++) {
      chartLabelData.push(data[i].ticker);
      chartValuesData.push(data[i].topValue);
    }

    this.setState({
      render: true,
      tableHeaderBackground: color,
    });
  }

  getTopHundredStaticData() {
    const postObject = {};
    FetchServerData.callPostService("top100/getStaticData", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          const array = output.data.countryList;
          const newCountryList = {
            code: 0,
            country: "All",
          };
          const newData = array.slice(0);
          newData.splice(array, 0, newCountryList);

          const industryList = output.data.industryList;
          const newIndustry = "All";
          const industryArr = industryList.slice(0);
          industryArr.splice(industryList, 0, newIndustry);

          this.setState({
            countryAdvanceList: newData,
            industryAdvanceList: industryArr,
            criteriaAdvanceList: output.data.sectionCriteriaList,
            render: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleRegionChange(event, values) {
    this.setState({
      selectedCountry: values,
    });
  }
  handleIndustryChange(event, values) {
    this.setState({
      selectedIndustry: values,
    });
  }
  handleCriteriaChange(event, values) {
    this.setState({
      selectedCriteria: values,
    });
  }

  getAdvanceListData() {
    var industryVar = this.state.selectedIndustry;
    var countryVar = this.state.selectedCountry.code;

    var criteriaVar = this.state.selectedCriteria.rankName;
    var postObject = {};
    if (countryVar === 0 || countryVar === undefined) {
      countryVar = "";
    }

    postObject = {
      industry: industryVar,
      country: countryVar,
      sectorCriteria: criteriaVar,
    };
    // }

    FetchServerData.callPostService("top100/advanceSearch", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data.searchList;
          this.getGraphData(data);
          if (
            output.data.topField === "MktCap" ||
            output.data.topField === "Sales"
          ) {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = data[i].topValue.toFixed(0);
            }
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = data[i].topValue.toFixed(2);
            }
          }

          let color = "";
          let rowcolor = "";
          color = "#4682B4";
          rowcolor = "rgb(22,160,133,0.1)";
          let chartLabelData = [];
          let chartValuesData = [];

          for (let i = 0; i < data.length; i++) {
            chartLabelData.push(data[i].name.trim());
            data[i].name = data[i].name.trim();
            chartValuesData.push(data[i].topValue);
          }
          var items = chartValuesData.slice(0, 25);
          var items1 = chartLabelData.slice(0, 25);
          for (let i = 0; i < data.length; i++) {
            data[i].rowNumber = i + 1;
          }

          if (
            output.data.topField === "MktCap" ||
            output.data.topField === "Sales"
          ) {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = "$" + data[i].topValue;
            }
          } else if (
            output.data.topField === "WQR_5" ||
            output.data.topField === "WQR_4" ||
            output.data.topField === "WQR_3" ||
            output.data.topField === "WQR_2" ||
            output.data.topField === "PECurr" ||
            output.data.topField === "PBVFY"
          ) {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = data[i].topValue;
            }
          } else {
            for (let i = 0; i < data.length; i++) {
              data[i].topValue = data[i].topValue + "%";
            }
          }

          let isWqrTopValue = true;
          let isTopValue = false;

          if (
            output.data.topField === "WQR_5" ||
            output.data.topField === "WQR_4" ||
            output.data.topField === "WQR_3" ||
            output.data.topField === "WQR_2" ||
            output.data.topField === "WQR_1"
          ) {
            isWqrTopValue = false;
            isTopValue = true;
          } else {
            isWqrTopValue = true;
            isTopValue = false;
          }
          let isPercentileData = false;
          let isUsdData = false;
          let isPlainData = false;
          if (
            output.data.topField === "MktCap" ||
            output.data.topField === "Sales"
          ) {
            isPercentileData = false;
            isUsdData = true;
            isPlainData = false;
          } else if (
            output.data.topField === "PBVFY" ||
            output.data.topField === "PECurr" ||
            output.data.topField === "WQR_5" ||
            output.data.topField === "WQR_1" ||
            output.data.topField === "WQR_2" ||
            output.data.topField === "WQR_3" ||
            output.data.topField === "WQR_4"
          ) {
            isPercentileData = false;
            isUsdData = false;
            isPlainData = true;
          } else {
            isPercentileData = true;
            isUsdData = false;
            isPlainData = false;
          }
          if (this.state.selectedCriteria.dropDownEntry === undefined) {
            this.setState({
              topHundredListData: data,
              wrightQualityRating: false,
              unitValue: output.data.unit,
              chartData: {
                labels: items1,
                datasets: [
                  {
                    label: this.state.selectedCriteria.dropDownEntry,
                    data: items,
                    backgroundColor: items.map((value) =>
                      value < 0 ? "#F15B46" : "#4682B4"
                    ),
                  },
                ],
              },
              tableHeaderBackground: color,
              tableRowBackground: rowcolor,
              criteriaVar: criteriaVar,
            });
          } else {
            let selectedCriteria = this.state.selectedCriteria.dropDownEntry;
            let tableHead = this.state.selectedCriteria.dropDownEntry;
            let isDate = true;
            let isPeriodDate = false;

            if (
              this.state.selectedCriteria.dropDownEntry === "Debt/Equity  Ratio"
            ) {
              selectedCriteria = "Total Debt/Common Equity Ratio";
              tableHead = "Debt/Equity  Ratio";
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Dividend Payout"
            ) {
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Earned Yield"
            ) {
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Price/Book Value"
            ) {
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Price/Earnings Ratio"
            ) {
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Return on Assets"
            ) {
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Return on Equity"
            ) {
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Total Return - Five Year"
            ) {
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Total Return - One Year"
            ) {
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Total Return - Three Month"
            ) {
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Total Return - One Month"
            ) {
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "EPS Growth - One Year "
            ) {
              selectedCriteria = "Earnings per Share Growth - One Year";
              tableHead = this.state.selectedCriteria.dropDownEntry;
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry === "Net Income Margin"
            ) {
              selectedCriteria = "Fiscal Year Net Income Margin";
              tableHead = this.state.selectedCriteria.dropDownEntry;
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Price Trend - 52 Week"
            ) {
              selectedCriteria = "52-Week Price Trend";
              tableHead = this.state.selectedCriteria.dropDownEntry;
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Return on Inv Capital"
            ) {
              selectedCriteria = "Return on Invested Capital";
              tableHead = this.state.selectedCriteria.dropDownEntry;
              isDate = false;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Sales Growth - Three Year"
            ) {
              selectedCriteria = "Sales Growth - Three Year Annual Growth Rate";
              tableHead = this.state.selectedCriteria.dropDownEntry;
              isDate = true;
              isPeriodDate = false;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "WQR - Composite Score"
            ) {
              selectedCriteria = "Wright Quality Rating - Composite Score";
              tableHead = " Composite Score";
              isDate = true;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "WQR - Financial Strength"
            ) {
              selectedCriteria = "Wright Quality Rating - Financial Strength";
              tableHead = "Financial Strength";
              isDate = true;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "WQR - Investment Acceptance"
            ) {
              selectedCriteria =
                "Wright Quality Rating - Investment Acceptance";
              tableHead = "Investment Acceptance";
              isDate = true;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "WQR - Profitability"
            ) {
              selectedCriteria = "Wright Quality Rating - Profitability";
              tableHead = "Profitability";
              isDate = true;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "WQR - Corporate Growth"
            ) {
              selectedCriteria = "Wright Quality Rating - Corporate Growth";
              tableHead = "Corporate Growth";
              isDate = true;
              isPeriodDate = true;
            } else if (
              this.state.selectedCriteria.dropDownEntry ===
              "Market Capitalization"
            ) {
              selectedCriteria = "Market Capitalization";
              tableHead = "Market Capitalization";
              isDate = false;
              isPeriodDate = true;
            } else if (this.state.selectedCriteria.dropDownEntry === "Sales") {
              isDate = false;
              isPeriodDate = true;
            }

            this.setState({
              topHundredListData: data,
              isDate: isDate,
              isPeriodDate: isPeriodDate,
              currentCriteria: tableHead,
              isWqrTopValue: isWqrTopValue,
              isTopValue: isTopValue,
              currentCriteriaHeading: selectedCriteria,
              wrightQualityRating: false,
              unitValue: output.data.unit,
              isPercentileData: isPercentileData,
              isUsdData: isUsdData,
              isPlainData: isPlainData,
              chartData: {
                labels: items1,
                datasets: [
                  {
                    label: this.state.selectedCriteria.dropDownEntry,
                    data: items,
                    backgroundColor: items.map((value) =>
                      value < 0 ? "#F15B46" : "#4682B4"
                    ),
                  },
                ],
              },
              criteriaVar: criteriaVar,
              isDataDisplay: true,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  toCompanyDetails = (e, companyData) => {
    let cusip = companyData.cusip;
    if (cusip && cusip.length > 0) {
      window.location.href = "#/company?" + cusip;
      // this.props.history.push({
      //   pathname: "/company",
      //   state: {
      //     cusip: cusip,
      //   },
      // });
    }
  };

  showCompanyDetails = (companyData) => {
    let cusip = companyData.cusip;
    let company = {
      cusip: companyData.cusip,
      ticker: companyData.ticker,
      country: companyData.country,
      codeCountry: companyData.codeCountry,
      planId: companyData.planId,
      price: companyData.price,
      type: companyData.type,
      companyName: companyData.name,
    };
    if (cusip && cusip.length > 0) {
      window.location.href = "#/company?" + cusip;
      // this.props.history.push({
      //   pathname: "/company",
      //   state: {
      //     cusip: cusip,
      //     data: company,
      //   },
      // });
    }
  };

  getItemLink(item) {
    return (
      <a
        onClick={() => this.showCompanyDetails(item)}
        style={{ cursor: "pointer" }}
      >
        {item.name}
      </a>
    );
  }
  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>Advanced Screening</h4>
                  </div>
                  <div className="col-lg-6">
                    <GenericSearch history={this.props.history} color="white" />
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <div style={{ minHeight: "100em" }}></div>
        </>
      );
    } else {
      const style = {};
      let sliderItems = [];
      this.state.topHundredListData.forEach((topHundredList, index) => {
        sliderItems.push(
          <div style={style} key={index}>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-delay={300}
              style={{ cursor: "pointer", width: "90%" }}
              onClick={() => this.toCompanyDetails(null, topHundredList)}
            >
              <div className=" ">
                <Row>
                  <Col md={3} style={{ paddingRight: "0px" }}>
                    {index + 1 + ") "}{" "}
                    {Utils.getCountryFlag(topHundredList.country)}
                  </Col>
                  <Col md={9} style={{ paddingLeft: "0px" }}>
                    <span
                      style={{
                        color: "rgb(242, 109, 33)",
                        fontSize: "small",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {topHundredList.name}
                    </span>
                    <p
                      className="description"
                      style={{
                        color: "#305388",
                        fontSize: "small",
                      }}
                    >
                      {topHundredList.topValue}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      });
      let sliderItemsMobile = [];
      this.state.topHundredListData.forEach((topHundredList, index) => {
        sliderItemsMobile.push(
          <div style={style} key={index}>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-delay={300}
              style={{ cursor: "pointer", width: "90%" }}
              onClick={() => this.toCompanyDetails(null, topHundredList)}
            >
              <div className=" ">
                <Row>
                  <Col md={3}>
                    {index + 1 + ") "}
                    {Utils.getCountryFlag(topHundredList.country)}
                  </Col>
                  <Col md={9}>
                    <span
                      style={{
                        color: "rgb(242, 109, 33)",
                        fontSize: "small",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {topHundredList.name}
                    </span>
                    <p
                      className="description"
                      style={{
                        color: "#305388",
                        fontSize: "small",
                      }}
                    >
                      {topHundredList.topValue}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      });

      const webProperties = {
        duration: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        indicators: false,
        arrows: false,
      };
      const phoneProperties = {
        duration: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        indicators: false,
        arrows: false,
      };
      return (
        <>
          <div
            style={{ minHeight: "100em" }}
            className="top_100_filter_table_row"
          >
            <Row style={{ marginTop: "5em" }}>
              <Row className="pts_mobile_view">
                <Col md={6}>
                  <div className="advdivstyle" style={{ height: "auto" }}>
                    <label className="advanceDivStyle">
                      Companies Ranked by{" "}
                      <span style={{ color: "#4682b4" }}>
                        {" "}
                        {this.state.currentCriteriaHeading}{" "}
                      </span>
                    </label>
                    <div className="advDivStyl">
                      {this.state.currentCriteria === "Market Capitalization" ||
                      this.state.currentCriteria === "Sales" ||
                      this.state.currentCriteria === "Return on Assets" ? (
                        <div className="filter_subheading">
                          Figures in{" "}
                          <span>
                            <span>
                              {" "}
                              {this.toTitleCase(this.state.unitValue)}
                            </span>{" "}
                            of U.S. Dollars{" "}
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <section style={{ padding: "0px" }}>
                    <Advertisement advertisementType="fixHorizontalAddUnit" />
                  </section>
                </Col>
                <a
                  href="/topHundredFilterMethodology"
                  style={{
                    cursor: "pointer",
                    color: "rgb(70, 130, 180)",
                    // marginTop: "0em",
                    fontWeight: "bold",
                    marginLeft: "3.8em",
                  }}
                  className="rank_methodology"
                  target="_blank"
                >
                  Rank Methodology
                </a>
              </Row>
            </Row>
            <Row
              style={{
                margin: 0,

                background: "#D6EAF8",
                padding: "0em 2em",
              }}
            >
              <Col
                md={3}
                style={{
                  fontSize: "small",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <b style={{ display: "flex", width: "19%" }}>Country</b>
                  <Autocomplete
                    style={{ display: "flex", width: "70%" }}
                    type="text"
                    id="tags-outlined"
                    options={this.state.countryAdvanceList}
                    getOptionLabel={(option) => option.country}
                    value={this.state.selectedCountry}
                    onChange={this.handleRegionChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </Col>
              <Col
                md={3}
                style={{
                  fontSize: "small",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <b style={{ display: "flex", width: "19%" }}>Industry</b>
                  <Autocomplete
                    style={{ display: "flex", width: "70%" }}
                    type="text"
                    id="tags-outlined"
                    options={this.state.industryAdvanceList}
                    getOptionLabel={(option) => option}
                    value={this.state.selectedIndustry}
                    onChange={this.handleIndustryChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </Col>
              <Col
                md={4}
                style={{
                  fontSize: "small",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <b className="selected_criteria">Selection Criteria</b>
                  <Autocomplete
                    style={{ display: "flex", width: "70%" }}
                    type="text"
                    id="tags-outlined"
                    options={this.state.criteriaAdvanceList}
                    getOptionLabel={(option) => option.dropDownEntry}
                    value={this.state.selectedCriteria}
                    onChange={this.handleCriteriaChange.bind(this)}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        {...params}
                        //label="Select Criteria"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </Col>

              <Col
                md={1}
                style={{
                  display: "flex",
                  padding: "0em 0em",
                  margin: "1em",
                  alignItems: "center",
                }}
              >
                <GenericButton
                  type="submit"
                  name="Search"
                  onClick={this.getAdvanceListData.bind(this)}
                  icon={<SearchIcon />}
                />
              </Col>
            </Row>
            {this.state.isDataDisplay === true && (
              <div className="advanceFeature" style={{ marginTop: "1em" }}>
                <Row style={{ padding: "0em 2em" }}>
                  <Row className="top_100_filter_slide">
                    <Slide {...webProperties} className="slider_web">
                      {sliderItems}
                    </Slide>
                    <Slide {...phoneProperties} className="slider_mobile">
                      {sliderItemsMobile}
                    </Slide>
                  </Row>
                  <Card
                    style={{
                      marginBottom: "1em",
                      borderRadius: "0px",
                      padding: "26px",
                      boxShadow:
                        "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                    }}
                    className="top_100_filter_slide"
                  >
                    {this.state.isPercentileData && (
                      <Bar
                        data={this.state.chartData}
                        height={80}
                        options={{
                          title: {
                            display: false,
                            text: "Average Rainfall per month",
                            fontSize: 15,
                          },

                          legend: {
                            display: false,
                            position: "top",
                          },
                          plugins: {
                            legend: {
                              labels: {
                                // This more specific font property overrides the global property
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                return tooltipItem.yLabel + "%";
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  fontSize: 10,
                                },

                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: "#8f9092",
                                  callback: function (value) {
                                    return value + "%";
                                  },
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}

                    {this.state.isUsdData && (
                      <Bar
                        data={this.state.chartData}
                        height={70}
                        options={{
                          title: {
                            display: false,
                            text: "Average Rainfall per month",
                            fontSize: 15,
                          },

                          legend: {
                            display: false,
                            position: "top",
                          },
                          plugins: {
                            legend: {
                              labels: {
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                return "$" + tooltipItem.yLabel;
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  fontSize: 10,
                                },

                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: "#8f9092",
                                  callback: function (value) {
                                    return "$" + value;
                                  },
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}

                    {this.state.isPlainData && (
                      <Bar
                        data={this.state.chartData}
                        height={70}
                        options={{
                          title: {
                            display: false,
                            text: "Average Rainfall per month",
                            fontSize: 15,
                          },

                          legend: {
                            display: false,
                            position: "top",
                          },
                          plugins: {
                            legend: {
                              labels: {
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                return tooltipItem.yLabel;
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  fontSize: 10,
                                },

                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                  drawBorder: true,
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: "#8f9092",
                                  callback: function (value) {
                                    return value;
                                  },
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </Card>
                </Row>

                <Row
                  md={12}
                  style={{ padding: "0em 2em", marginBottom: "2em" }}
                >
                  <MaterialTable
                    style={{
                      marginBottom: "1em",
                      boxShadow:
                        "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                    }}
                    icons={this.tableIcons}
                    title="Top 100 List"
                    columns={[
                      {
                        title: "#",
                        field: "rowNumber",
                        align: "left",
                        width: "1%",
                      },
                      {
                        title: "Company",
                        field: "name",
                        align: "left",
                        render: (rowData) => this.getItemLink(rowData),
                      },
                      {
                        title: "Ticker",
                        field: "ticker",
                        align: "left",
                      },
                      {
                        title: "Wright Quality Rating",
                        field: "wqr",
                        align: "center",

                        hidden: this.state.wrightQualityRating,
                        cellStyle: {
                          fontWeight: "bold",
                          width: "20%",
                        },
                      },
                      {
                        title: this.state.currentCriteria,
                        field: "topValue",
                        align: "left",
                        hidden: this.state.isTopValue,
                        customSort: (a, b) => {
                          let newA = a.topValue;

                          let charA = newA
                            .toString()
                            .substring(newA.toString().length - 1);

                          if (newA && newA.toString().charAt(0) === "$") {
                            newA = newA
                              .toString()
                              .substring(1, newA.toString().length);
                          } else if (a && charA === "%") {
                            newA = a.topValue
                              .toString()
                              .substring(0, newA.toString().length - 1);
                          }

                          let newB = b.topValue;
                          let charB = newB
                            .toString()
                            .substring(newB.toString().length - 1);
                          if (newB && newB.toString().charAt(0) === "$") {
                            newB = newB
                              .toString()
                              .substring(1, newB.toString().length);
                          } else if (b && charB === "%") {
                            newB = newB
                              .toString()
                              .substring(0, newB.toString().length - 1);
                          }

                          return newA - newB;
                        },
                      },
                      {
                        title: this.state.currentCriteria,
                        field: "wqrTopValue",
                        align: "left",
                        hidden: this.state.isWqrTopValue,
                      },
                      {
                        title: "Date",

                        render: (rowData) =>
                          moment(rowData.latestDate).format("MM/DD/YYYY"),
                        align: "left",
                        type: "date",

                        hidden: this.state.isDate,
                        padding: "0px 0px 0px 30px",
                        customSort: (a, b) => {
                          return (
                            new Date(a.latestDate) - new Date(b.latestDate)
                          );
                        },
                      },
                      {
                        title: "Period Ending",

                        render: (rowData) =>
                          moment(rowData.latestDate).format("MM/DD/YYYY"),
                        align: "left",
                        type: "date",

                        hidden: this.state.isPeriodDate,
                        padding: "0px 0px 0px 30px",
                        customSort: (a, b) => {
                          return (
                            new Date(a.latestDate) - new Date(b.latestDate)
                          );
                        },
                      },
                      {
                        title: "Country",
                        render: (rowData) => this.getCountryWithFlag(rowData),
                        align: "left",
                        width: "10% !important",
                        padding: "0px 0px 0px 30px",
                        customSort: (a, b) => {
                          return a.country
                            .toString()
                            .localeCompare(b.country.toString());
                        },
                      },
                    ]}
                    data={this.state.topHundredListData}
                    options={{
                      search: false,
                      sorting: true,
                      pageSizeOptions: [10, 25, 50, 75, 100],
                      actionsColumnIndex: -1,
                      pageSize: 100,
                      paging: false,
                      headerStyle: {
                        backgroundColor: this.state.tableHeaderBackground,
                        fontWeight: "bold",
                        color: "#fff",
                        align: "center",
                      },
                      rowStyle: {
                        height: "10px",
                        backgroundColor: "rgb(22,160,133,0.1)",
                      },
                      filtering: false,
                    }}
                    style={{
                      fontSize: "14px",
                    }}
                  />
                </Row>
              </div>
            )}
          </div>
        </>
      );
    }
  }
}
