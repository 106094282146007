import { GET_CART, SET_CART } from "./cartTypes";

export const getCart = () => {
  return {
    type: GET_CART,
  };
};

export const setCart = (data) => {
  return {
    type: SET_CART,
    payload: data,
  };
};

export default { getCart, setCart };
