import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import PublishIcon from "@material-ui/icons/Publish";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import GenericButton from "../buttons/GenericButton";
import Advertisement from "../ciadvertisement/Advertisement";
import "./Feedback.css";
export default class Feedback extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {
      phone: "",
      isMessageSent: false,
      isFeedbackSuccessfullySent: false,
      company: "",
      general: true,
      type: "General",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handlePhoneChange(e) {
    this.setState({
      phone: e.target.value,
    });
  }
  handleCompanyChange(e) {
    this.setState({
      company: e.target.value,
    });
  }
  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value,
    });
  }
  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  handleBillingChange = (e) => {
    this.setState({ billing: e.target.checked });
  };
  handleTechnicalChange = (e) => {
    this.setState({ technical: e.target.checked });
  };
  handleGeneralChange = (e) => {
    this.setState({ general: e.target.checked });
  };
  onChangeTypeValue(event) {
    this.setState({ type: event.target.value });
  }
  sendFeedback = () => {
    const postObject = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.comment,
      type: this.state.type,
      company: this.state.company,
    };
    FetchServerData.callPostService("contactusms/sendEnquiry", postObject)
      .then((response) => {
        let output = response;
        if (output.status === "SUCCESS") {
          window.scrollTo(0, 0);
          this.setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            company: "",
            comment: "",
            isFeedbackSuccessfullySent: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    return (
      <>
        <Row style={{ marginTop: "5em" }}></Row>
        <Row style={{ marginTop: "3em" }}>
          <Col md={1}></Col>
          <Col md={9}>
            <section id="feedback" className="feedback feedback_section">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="box"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      {this.state.isFeedbackSuccessfullySent === true && (
                        <>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#f26d21",
                            }}
                          >
                            Thank you for your interest in CorporateInformation!
                          </p>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#f26d21",
                            }}
                          >
                            We appreciate your feedback!
                          </p>
                        </>
                      )}

                      <p style={{ fontSize: "17px" }}>
                        <b style={{ fontSize: "17px" }}>
                          CorporateInformation.com is not affiliated in anyway
                          with the companies that we report on.
                        </b>
                        <br></br>
                      </p>
                      <p style={{ fontSize: "17px" }}>
                        {" "}
                        We are an independent research organization.{" "}
                      </p>
                      <p style={{ fontSize: "17px" }}>
                        Please feel free to submit any comments, suggestions or
                        questions about our service. We look forward to hearing
                        from you.
                      </p>
                      <p style={{ fontSize: "17px" }}>
                        If you are a customer in need of technical support or
                        assistance please identify the problem that requires
                        attention.
                      </p>
                      <div className="footer-contact text-center text-md-start">
                        <p style={{ fontSize: "17px" }}>
                          CorporateInformation.com Customer Support <br></br>
                          Wright Investors' Service Inc
                          <br />
                          2 Corporate Drive Suite 770
                          <br />
                          Shelton, CT 06484
                          <br />
                          USA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <main id="main">
              {this.state.isFeedbackSuccessfullySent === false && (
                <section id="contact" className="contact feedback_contact">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.sendFeedback.bind(this)}
                  >
                    <Row style={{ margin: "21px 0px" }}>
                      <Col md={12}>
                        <div
                          onChange={this.onChangeTypeValue.bind(this)}
                          value={this.state.type}
                        >
                          <input
                            type="radio"
                            value="General"
                            name="type"
                            defaultChecked
                          />{" "}
                          General &nbsp; &nbsp;
                          <input
                            type="radio"
                            value="Billing"
                            name="type"
                          />{" "}
                          Billing &nbsp; &nbsp;
                          <input
                            type="radio"
                            value="Technical"
                            name="type"
                          />{" "}
                          Technical
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ margin: "21px 0px" }}>
                      <Col md={6} className="feedback_column">
                        <TextField
                          name="lastName"
                          variant="outlined"
                          required
                          value={this.state.name}
                          onChange={this.handleNameChange.bind(this)}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          fullWidth
                          id="name"
                          label="Name"
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={6} className="feedback_column">
                        <TextValidator
                          name="email"
                          required
                          variant="outlined"
                          value={this.state.email}
                          onChange={this.handleEmailChange.bind(this)}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "this field is required",
                            "email is not valid",
                          ]}
                          fullWidth
                          id="email"
                          label="Email"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                    <Row style={{ margin: "21px 0px" }}>
                      <Col md={6} className="feedback_column">
                        <TextField
                          name="subject"
                          variant="outlined"
                          value={this.state.subject}
                          onChange={this.handleSubjectChange.bind(this)}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          required
                          fullWidth
                          id="subject"
                          label="Subject"
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={6} className="feedback_column">
                        <TextField
                          name="company"
                          variant="outlined"
                          value={this.state.company}
                          onChange={this.handleCompanyChange.bind(this)}
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          fullWidth
                          id="company"
                          label="Company"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>

                    <Row style={{ margin: "21px 0px" }}>
                      <Col md={12}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          required
                          value={this.state.comment}
                          onChange={this.handleCommentChange.bind(this)}
                          style={{ width: "100%" }}
                          rowsMin={6}
                          placeholder="Message"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} style={{ textAlign: "center" }}>
                        <GenericButton
                          type="submit"
                          name="Send Message"
                          icon={<PublishIcon />}
                        />
                      </Col>
                    </Row>
                  </ValidatorForm>
                </section>
              )}
            </main>
          </Col>
          <Col md={2}>
            <Advertisement advertisementType="verticalAddUnit1" />
          </Col>
        </Row>
      </>
    );
  }
}
