import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./SourceOfCapital.css";
export default class SourceOfCapital extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    let columns = [];
    let type = props.type;

    this.state = {
      cusip: props.data,
      type: type,
      isPdfReport: isPdfReport,
      actualData: [],
      render: false,
      data: props.data,
      showModal: false,
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }
  newLegendClickHandler(e, legendItem) {}
  valueFormater = (value) => {
    let values = "";
    if (value) {
      values = value.toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    } else {
      return (values = "");
    }
  };
  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilities1,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquity1,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital1,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilities2,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquity2,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital2,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilities3,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquity3,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital3,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities4") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilities4,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity4") {
      this.setState({
        graphDetails: {
          graphValues: this.state.commonEquity4,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Financing Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.financingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Financing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Short Term Debt1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.shortTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Retained Earnings1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.retainedEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Retained Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Short Term Debt2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.shortTermDebt2,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebt2,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherLiabilities2,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterest2,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStock2,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Retained Earnings2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.retainedEarnings2,
          labelData: this.state.LabelList,
          yAxisTitle: "Retained Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Short Term Debt3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.shortTermDebt3,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebt3,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherLiabilities3,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.minorityInterest3,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.preferredStock3,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Retained Earnings3") {
      this.setState({
        graphDetails: {
          graphValues: this.state.retainedEarnings3,
          labelData: this.state.LabelList,
          yAxisTitle: "Retained Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Change in Liabilities as % of Total Liabilities"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.NetChangeinLiabilitiesasPercentofTotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Change in Liabilities as % of Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Change in Common Equity as % of Common Equity"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.NetChangeinCommonEquityasPercentofCommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Change in Common Equity as % of Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues: this.state.investingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Investing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  componentDidMount() {
    if (this.state.isPdfReport === true) {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService("financial/sourceOfCapital", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.currency = output.data.currency;
            this.unit = output.data.unit;
            let data = output.data;
            this.setState({
              actualData: data.sourceOfCapitalList,
              render: true,
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      this.currency = this.state.data.currency;
      this.unit = this.state.data.unit;
      let LabelList = [];
      let totalCapital = [];
      let totalLiabilities1 = [];
      let commonEquity1 = [];
      let totalCapital1 = [];
      let shortTermDebt1 = [];
      let longTermDebt1 = [];
      let otherLiabilities1 = [];
      let minorityInterest1 = [];
      let preferredStock1 = [];
      let retainedEarnings1 = [];

      let totalLiabilities2 = [];
      let commonEquity2 = [];
      let totalCapital2 = [];
      let shortTermDebt2 = [];
      let longTermDebt2 = [];
      let otherLiabilities2 = [];
      let minorityInterest2 = [];
      let preferredStock2 = [];
      let retainedEarnings2 = [];

      let totalLiabilities3 = [];
      let commonEquity3 = [];
      let totalCapital3 = [];
      let shortTermDebt3 = [];
      let longTermDebt3 = [];
      let otherLiabilities3 = [];
      let minorityInterest3 = [];
      let preferredStock3 = [];
      let retainedEarnings3 = [];

      let totalLiabilities4 = [];
      let commonEquity4 = [];

      let operatingActivities = [];
      let financingActivities = [];
      let investingActivities = [];
      let NetChangeinLiabilitiesasPercentofTotalLiabilities = [];
      let NetChangeinCommonEquityasPercentofCommonEquity = [];
      if (this.state.data.sourceOfCapitalList.length > 0) {
       // this.state.data.sourceOfCapitalList.reverse();
      }
      for (let i = 0; i < this.state.data.sourceOfCapitalList.length; i++) {
        totalCapital.push(this.state.data.sourceOfCapitalList[i].totalCapital);
        LabelList.push(this.state.data.sourceOfCapitalList[i].fiscalYear);
        NetChangeinLiabilitiesasPercentofTotalLiabilities.push(
          this.state.data.sourceOfCapitalList[i]
            .netChangeinLiabilitiesasofTotalLiabilities
        );
        NetChangeinCommonEquityasPercentofCommonEquity.push(
          this.state.data.sourceOfCapitalList[i]
            .netChangeinCommonEquityasofCommonEquity
        );
        totalLiabilities1.push(
          this.state.data.sourceOfCapitalList[i]
            .totalLiabilitiesPercentOfTotalCapital
        );
        commonEquity1.push(
          this.state.data.sourceOfCapitalList[i]
            .commonEquityPercentOfTotalCapital
        );
        totalCapital1.push(
          this.state.data.sourceOfCapitalList[i]
            .totalCapitalPercentOfTotalCapital
        );
        shortTermDebt1.push(
          this.state.data.sourceOfCapitalList[i]
            .shortTermDebtPercentOfTotalCapital
        );
        longTermDebt1.push(
          this.state.data.sourceOfCapitalList[i]
            .longTermDebtPercentOfTotalCapital
        );
        otherLiabilities1.push(
          this.state.data.sourceOfCapitalList[i]
            .otherLiabilitiesPercentOfTotalCapital
        );
        minorityInterest1.push(
          this.state.data.sourceOfCapitalList[i]
            .minorityInterestPercentOfTotalCapital
        );
        preferredStock1.push(
          this.state.data.sourceOfCapitalList[i]
            .preferredStockPercentOfTotalCapital
        );
        retainedEarnings1.push(
          this.state.data.sourceOfCapitalList[i]
            .retainedEarningsPercentOfTotalCapital
        );
        totalLiabilities2.push(
          this.state.data.sourceOfCapitalList[i].totalLiabilitiesYoYNetChange
        );
        commonEquity2.push(
          this.state.data.sourceOfCapitalList[i].commonEquityYoYNetChange
        );
        totalCapital2.push(
          this.state.data.sourceOfCapitalList[i].totalCapitalYoYNetChange
        );
        shortTermDebt2.push(
          this.state.data.sourceOfCapitalList[i].shortTermDebtYoYNetChange
        );
        longTermDebt2.push(
          this.state.data.sourceOfCapitalList[i].longTermDebtYoYNetChange
        );
        otherLiabilities2.push(
          this.state.data.sourceOfCapitalList[i].otherLiabilitiesYoYNetChange
        );
        minorityInterest2.push(
          this.state.data.sourceOfCapitalList[i].minorityInterestYoYNetChange
        );
        preferredStock2.push(
          this.state.data.sourceOfCapitalList[i].preferredStockYoYNetChange
        );
        retainedEarnings2.push(
          this.state.data.sourceOfCapitalList[i].retainedEarningsYoYNetChange
        );

        totalLiabilities3.push(
          this.state.data.sourceOfCapitalList[i]
            .totalLiabilitiesYoYPercentChange
        );
        commonEquity3.push(
          this.state.data.sourceOfCapitalList[i].commonEquityYoYPercentChange
        );
        totalCapital3.push(
          this.state.data.sourceOfCapitalList[i].totalCapitalYoYPercentChange
        );
        shortTermDebt3.push(
          this.state.data.sourceOfCapitalList[i].shortTermDebtYoYPercentChange
        );
        longTermDebt3.push(
          this.state.data.sourceOfCapitalList[i].longTermDebtYoYPercentChange
        );
        otherLiabilities3.push(
          this.state.data.sourceOfCapitalList[i]
            .otherLiabilitiesYoYPercentChange
        );
        minorityInterest3.push(
          this.state.data.sourceOfCapitalList[i]
            .minorityInterestYoYPercentChange
        );
        preferredStock3.push(
          this.state.data.sourceOfCapitalList[i].preferredStockYoYPercentChange
        );
        retainedEarnings3.push(
          this.state.data.sourceOfCapitalList[i]
            .retainedEarningsYoYPercentChange
        );

        totalLiabilities4.push(
          this.state.data.sourceOfCapitalList[i].totalLiabilities
        );
        commonEquity4.push(this.state.data.sourceOfCapitalList[i].commonEquity);

        operatingActivities.push(
          this.state.data.sourceOfCapitalList[i].operatingActivitiesCashflow
        );
        financingActivities.push(
          this.state.data.sourceOfCapitalList[i].financingActivitiesCashflow
        );
        investingActivities.push(
          this.state.data.sourceOfCapitalList[i].investingActivitiesCashflow
        );
      }
      this.setState({
        actualData: this.state.data.sourceOfCapitalList,
        retainedEarnings1: retainedEarnings1,
        preferredStock1: preferredStock1,
        minorityInterest1: minorityInterest1,
        otherLiabilities1: otherLiabilities1,
        longTermDebt1: longTermDebt1,
        shortTermDebt1: shortTermDebt1,
        retainedEarnings2: retainedEarnings2,
        preferredStock2: preferredStock2,
        minorityInterest2: minorityInterest2,
        otherLiabilities2: otherLiabilities2,
        longTermDebt2: longTermDebt2,
        shortTermDebt2: shortTermDebt2,
        retainedEarnings3: retainedEarnings3,
        preferredStock3: preferredStock3,
        minorityInterest3: minorityInterest3,
        otherLiabilities3: otherLiabilities3,
        longTermDebt3: longTermDebt3,
        shortTermDebt3: shortTermDebt3,
        NetChangeinLiabilitiesasPercentofTotalLiabilities:
          NetChangeinLiabilitiesasPercentofTotalLiabilities,
        NetChangeinCommonEquityasPercentofCommonEquity:
          NetChangeinCommonEquityasPercentofCommonEquity,
        totalLiabilities1: totalLiabilities1,
        commonEquity1: commonEquity1,
        totalCapital1: totalCapital1,
        totalLiabilities2: totalLiabilities2,
        commonEquity2: commonEquity2,
        totalCapital2: totalCapital2,
        totalLiabilities3: totalLiabilities3,
        commonEquity3: commonEquity3,
        totalCapital3: totalCapital3,
        totalLiabilities4: totalLiabilities4,
        commonEquity4: commonEquity4,
        operatingActivities: operatingActivities,
        financingActivities: financingActivities,
        investingActivities: investingActivities,
        totalCapital: totalCapital,
        LabelList: LabelList,

        series: [
          {
            name: "Total Capital",
            type: "bar",
            data: totalCapital,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Total Capital",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          dataLabels: {
            enabled: false,
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: totalCapital,
          },
        ],

        render: true,
      });
    }

    //   } else {
    //   }
    // })
    // .catch((error) => {});
  }
  // toTitleCase(str) {
  //   return str.replace(/\w\S*/g, function (txt) {
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
              <Row>
                <Col md={8}>
                  <span>
                    <span className="section_header"> Sources of Capital</span>
                  </span>
                  <br></br>
                  <span className="unit_str">
                    All figures in <span>{this.toTitleCase(this.unit)}</span> of{" "}
                    {this.currency}
                  </span>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="source_of_capital">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className=""> Total Capital</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {Utils.valueFormater(data.totalCapital)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Percent of Total Capital
                  </th>
                  {this.state.actualData.map((data) => {
                    return <td></td>;
                  })}
                </tr>
                <tr>
                  <th>Short Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.shortTermDebtPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.longTermDebtPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Liabilities</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.otherLiabilitiesPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Liabilities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.totalLiabilitiesPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.minorityInterestPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.preferredStockPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Retained Earnings</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.retainedEarningsPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Common Equity</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.commonEquityPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Capital</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {this.valueFormater(
                          data.totalCapitalPercentOfTotalCapital
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Year to Year Net Changes{" "}
                  </th>
                  {this.state.actualData.map((data) => {
                    return <td></td>;
                  })}
                </tr>
                <tr>
                  <th>Short Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.shortTermDebtYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.longTermDebtYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Liabilities</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.otherLiabilitiesYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Liabilities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.totalLiabilitiesYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.minorityInterestYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.preferredStockYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Retained Earnings</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.retainedEarningsYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Common Equity</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.commonEquityYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Capital</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.totalCapitalYoYNetChange)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    {" "}
                    Year to Year Percent Changes
                  </th>
                  {this.state.actualData.map((data) => {
                    return <td></td>;
                  })}
                </tr>

                <tr>
                  <th>Short Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.shortTermDebtYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Debt</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.longTermDebtYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Liabilities</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.otherLiabilitiesYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Liabilities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.totalLiabilitiesYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>

                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.minorityInterestYoYPercentChange
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Stock</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(
                          data.preferredStockYoYPercentChange
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Retained Earnings</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.retainedEarningsYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Common Equity</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.commonEquityYoYPercentChange
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Capital</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {this.valueFormater(data.totalCapitalYoYPercentChange)}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Total Liabilities & Common Equity
                  </th>
                  {this.state.actualData.map((data) => {
                    return <td></td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Liabilities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>{Utils.valueFormater(data.totalLiabilities)}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Change in Liabilities as % of Total Liabilities</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.netChangeinLiabilitiesasofTotalLiabilities
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Common Equity</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return <td>{Utils.valueFormater(data.commonEquity)}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Change in Common Equity as % of Common Equity</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.netChangeinCommonEquityasofCommonEquity
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th style={{ fontWeight: "bold" }}>Cash Flow </th>
                  {this.state.actualData.map((data) => {
                    return <td></td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Operating Activities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.operatingActivitiesCashflow)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Financing Activities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.financingActivitiesCashflow)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Investing Activities</span>
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.investingActivitiesCashflow)}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Sources of Capital
                      </span>
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in <span>{this.toTitleCase(this.unit)}</span>{" "}
                      of {this.currency}
                    </span>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="source_of_capital">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Fiscal Year End Date{" "}
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className=""> Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Capital")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {Utils.valueFormater(data.totalCapital)}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Percent of Total Capital
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th>Short Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.shortTermDebtPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.longTermDebtPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.otherLiabilitiesPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Liabilities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities1")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.totalLiabilitiesPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.minorityInterestPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.preferredStockPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Retained Earnings</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.retainedEarningsPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity1")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.commonEquityPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Capital1")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {this.valueFormater(
                            data.totalCapitalPercentOfTotalCapital
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Year to Year Net Changes{" "}
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th>Short Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.shortTermDebtYoYNetChange)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.longTermDebtYoYNetChange)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.otherLiabilitiesYoYNetChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Liabilities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities2")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.totalLiabilitiesYoYNetChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.minorityInterestYoYNetChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.preferredStockYoYNetChange)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Retained Earnings</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.retainedEarningsYoYNetChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity2")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.commonEquityYoYNetChange)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Capital2")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.totalCapitalYoYNetChange)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      {" "}
                      Year to Year Percent Changes
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>

                  <tr>
                    <th>Short Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.shortTermDebtYoYPercentChange
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Long Term Debt</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.longTermDebtYoYPercentChange
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.otherLiabilitiesYoYPercentChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Liabilities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities3")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.totalLiabilitiesYoYPercentChange
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>

                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.minorityInterestYoYPercentChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            this.preferredStockYoYPercentChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Retained Earnings</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.retainedEarningsYoYPercentChange
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity3")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.commonEquityYoYPercentChange
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Capital</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Capital3")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {this.valueFormater(
                            data.totalCapitalYoYPercentChange
                          )}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Common Equity
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Total Liabilities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities4")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{Utils.valueFormater(data.totalLiabilities)}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Net Change in Liabilities as % of Total Liabilities</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.netChangeinLiabilitiesasofTotalLiabilities
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Common Equity</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity4")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{Utils.valueFormater(data.commonEquity)}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Net Change in Common Equity as % of Common Equity</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.netChangeinCommonEquityasofCommonEquity
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>Cash Flow </th>
                    {this.state.actualData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Operating Activities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Activities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.operatingActivitiesCashflow
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Financing Activities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Financing Activities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.financingActivitiesCashflow
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      <span className="">Investing Activities</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investing Activities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.investingActivitiesCashflow
                          )}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Sources of Capital
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in <span>{this.toTitleCase(this.unit)}</span>{" "}
                      of {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
