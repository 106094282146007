import React from "react";

export default function CompanySnapshotPageContent({ children }) {
  return (
    <main
      tabIndex="-1"
      role="main"
      style={{ minHeight: "30em", overflow: "auto" }}
    >
      {children}
    </main>
  );
}
