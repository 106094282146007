import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { Component } from "react";
import { matchPath, NavLink } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import cartAction from "../../redux/cart/cartAction";
import companySnapshotAction from "../../redux/companyDetails/companySnapshotAction";
import store from "../../redux/store";
import userDataAction from "../../redux/userData/userDataAction";
import Login from "../loginComponent/Login";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Header.css";
export default class ApplicationLayoutHeader extends Component {
  userData = store.getState().userData.userData;
  cartData = store.getState().cartData.cart;
  history = store.getState().history.history;
  loginRefList = [];
  // useHistory = useHistory;
  constructor(props) {
    super(props);

    let isUserLogin = false;
    let user = "";
    let isLoginMenu = true;
    let isUserProfile = false;
    let isPaidLoginUser = false;
    var lengthObj = Object.keys(this.userData).length;

    if (lengthObj > 0) {
      if (this.userData) {
        isUserLogin = true;
        user = this.userData.firstName + " " + this.userData.lastName;
        isLoginMenu = false;
        isUserProfile = true;
      }

      if (this.userData === undefined) {
        isPaidLoginUser = true;
      } else if (this.userData.activeUser === false) {
        isPaidLoginUser = true;
      } else {
        isPaidLoginUser = false;
      }
    } else {
      isPaidLoginUser = true;
    }
    this.state = {
      isOpen: false,
      className: "header1 fixed-top",
      showModal: false,
      password: "",
      userName: "",
      isLogin: true,
      isResetPass: false,
      isForgotPass: false,
      isPaidLoginUser: isPaidLoginUser,
      modalShow: false,
      user: user,
      isUserLogin: isUserLogin,
      isLoginMenu: isLoginMenu,
      isUserProfile: isUserProfile,
      totalCartItems: this.cartData.length,
      isMenu: "open",
      toggleBtnClass: "bi bi-list mobile-nav-toggle",
      isNavbar: "navbar",
      dropDownMenu: "isNotDropdown",
      servicesDropDownMenu: "isNotDropdown",
    };
    this.loginRefList = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  getPageTitle = () => {
    let name;
    this.props.routes.map((prop) => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false,
        })
      ) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };
  logout() {
    let data = {};
    store.dispatch(userDataAction.setUserData(data));
    let cart = [];
    store.dispatch(cartAction.setCart(cart));
    // let prices = [];
    // store.dispatch(reportPriceAction.setPriceData(prices));
    let name = "";
    store.dispatch(companySnapshotAction.setCompanySnapshot(name));
    this.setState({
      isPaidLoginUser: true,
      isUserProfile: false,
      isLoginMenu: true,
      user: "",
      isUserLogin: false,
    });
    window.location.href = "/";
  }
  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (this.state.className === "header1 fixed-top") {
        this.setState({ className: "header1 fixed-top header-scrolled" });
      }
    } else {
      if (this.state.className !== "header1 fixed-top") {
        this.setState({ className: "header1 fixed-top" });
      }
    }
  };
  getMobileNavbar(e, type) {
    if (type === "open") {
      this.setState({
        isMenu: "close",
        toggleBtnClass: "bi mobile-nav-toggle bi-x",
        isNavbar: "navbar navbar-mobile",
      });
    } else {
      this.setState({
        isMenu: "open",
        toggleBtnClass: "bi bi-list mobile-nav-toggle",
        isNavbar: "navbar",
      });
    }
  }
  getDropDownMenu(e) {
    if (this.state.dropDownMenu === "isNotDropdown") {
      this.setState({
        dropDownMenu: "dropdown-active",
      });
    } else {
      this.setState({
        dropDownMenu: "isNotDropdown",
      });
    }
  }
  getServicesDropDownMenu(e) {
    if (this.state.servicesDropDownMenu === "isNotDropdown") {
      this.setState({
        servicesDropDownMenu: "dropdown-active",
      });
    } else {
      this.setState({
        servicesDropDownMenu: "isNotDropdown",
      });
    }
  }
  onUpdate() {
    let isUserLogin = false;
    let user = "";
    let isLoginMenu = true;
    let isUserProfile = false;
    let isPaidLoginUser = false;
    let userData = store.getState().userData.userData;

    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      if (userData) {
        isUserLogin = true;
        user = userData.firstName + " " + userData.lastName;
        isLoginMenu = false;
        isUserProfile = true;
      }
      if (userData === undefined || this.userData === null) {
        isPaidLoginUser = true;
      } else if (userData.activeUser === false) {
        isPaidLoginUser = true;
      } else {
        isPaidLoginUser = false;
      }
    } else {
      isPaidLoginUser = true;
    }
    this.setState({
      isPaidLoginUser: isPaidLoginUser,
      isUserProfile: isUserProfile,
      isLoginMenu: isLoginMenu,
      user: user,
      isUserLogin: isUserLogin,
    });
  }

  isUserSubscribe() {
    let userData = store.getState().userData.userData;
    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      if (userData === undefined || userData === null) {
        this.setState({ showModal: true });
      } else if (userData.activeUser === false) {
        this.setState({ showModal: true });
      } else {
        // this.useHistory.push("/advancescreening");
        // this.history.push({
        //   pathname: "/advancescreening",
        // });
        window.location.href = "/#/advancescreening";
        // return (
        //   <NavLink to="/advancescreening" className="nav-link scrollto">
        //     Advanced Screening
        //   </NavLink>
        // );
      }
    } else {
      this.setState({ showModal: true });
    }
  }

  toBuyPlan() {
    this.setState({ showModal: false });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleTempPassChange(e) {
    this.setState({ tempPass: e.target.value });
  }
  userLogin(e) {
    const postObject = {
      registeredUsers: {
        email: this.state.userName,
        password: this.state.password,
      },
    };
    FetchServerData.callPostService("regms/login", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({ showModal: !this.state.showModal });
        }
      })
      .catch((error) => {});
  }

  userResetPass(e) {
    const postObject = {
      registeredUsers: {
        email: this.state.userName,
      },
    };
    FetchServerData.callPostService("regms/forgetPassword", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            isResetPass: false,
            isForgotPass: true,
          });
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }

  resetPassword(e) {
    const postObject = {
      registeredUsers: {
        email: this.state.userName,
        password: this.state.password,
        tempPassword: this.state.tempPass,
      },
    };
    FetchServerData.callPostService("regms/resetPassword", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({ showModal: !this.state.showModal });
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }
  getModalPopup(e) {
    if (this.loginRefList && this.loginRefList.current) {
      this.loginRefList.current.state.showModal = true;
      this.loginRefList.current.state.isLogin = true;
    }
    this.setState({
      modalShow: true,
    });
  }

  toUserProfile(e) {
    this.props.history.push({
      pathname: "userProfile",
    });
  }
  userForgotPass(e) {
    this.setState({
      isResetPass: true,
      isLogin: false,
    });
  }
  login(e) {
    this.setState({
      isResetPass: false,
      isLogin: true,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    this.cartData = store.getState().cartData.cart;
    return (
      <>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />

        {/* <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="assets/vendor/swiper/swiper-bundle.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/glightbox/css/glightbox.min.css"
          rel="stylesheet"
        />
        <link href="assets/css/style.css" rel="stylesheet" /> */}

        <header
          id="header"
          className={this.state.className}
          style={{ zIndex: 200 }}
        >
          <Container maxWidth="lg">
            <div className="d-flex align-items-center justify-content-between">
              <a href="/" className="logo d-flex align-items-center">
                <img src="/home/assets/img/logo5.png" alt="" />
              </a>
              <br></br>

              <Row className="isNotMobileViewHeader">
                <nav id="navbar" className={this.state.isNavbar}>
                  <ul>
                    <li>
                      <NavLink to="/ci" className="nav-link scrollto ">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/currency" className="nav-link scrollto ">
                        Currency Exchange Rates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/tophundred" className="nav-link scrollto ">
                        Top 100 Lists
                      </NavLink>
                    </li>

                    <li>
                      {this.state.isPaidLoginUser === true ? (
                        <a
                          style={{ cursor: "pointer" }}
                          className="nav-link scrollto"
                          onClick={(e) => this.isUserSubscribe(e)}
                        >
                          Advanced Screening
                        </a>
                      ) : (
                        <NavLink
                          to="/advancescreening"
                          className="nav-link scrollto"
                        >
                          Advanced Screening
                        </NavLink>
                      )}
                    </li>
                    <li
                      className="dropdown"
                      onClick={(e) => this.getDropDownMenu(e)}
                    >
                      <a href="#">
                        {" "}
                        Reports <ExpandMoreIcon />
                      </a>
                      <ul className={this.state.dropDownMenu}>
                        <li>
                          <NavLink
                            to="/companyreport"
                            className="nav-link scrollto"
                          >
                            Company Reports
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/industryreport"
                            className="nav-link scrollto"
                          >
                            Industry Averages Reports
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="dropdown"
                      onClick={(e) => this.getServicesDropDownMenu(e)}
                    >
                      <a href="#">
                        {" "}
                        Services <ExpandMoreIcon />
                      </a>
                      <ul className={this.state.servicesDropDownMenu}>
                        <li>
                          <NavLink to="/plans" className="nav-link scrollto">
                            Plans
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/definitions"
                            className="nav-link scrollto"
                          >
                            Definitions &amp; Extensions
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/financialterms"
                            className="nav-link scrollto"
                          >
                            Definitions of Financial Terms
                          </NavLink>
                          <li>
                            <NavLink
                              to="/feedback"
                              className="nav-link scrollto"
                            >
                              Contact Us
                            </NavLink>
                          </li>
                        </li>
                      </ul>
                    </li>
                    {this.state.isLoginMenu && (
                      <li>
                        <a
                          onClick={(e) => this.getModalPopup(e)}
                          className="nav-link scrollto"
                          style={{ cursor: "pointer" }}
                        >
                          Login
                        </a>
                      </li>
                    )}

                    {this.state.isUserProfile && (
                      <li>
                        <NavLink to="/user" className="nav-link scrollto">
                          User
                        </NavLink>
                      </li>
                    )}

                    <li>
                      <NavLink to="/cart" className="nav-link scrollto">
                        <ShoppingCartIcon
                          style={{ cursor: "pointer" }}
                        ></ShoppingCartIcon>
                        <Badge
                          badgeContent={
                            this.cartData ? this.cartData.length : ""
                          }
                          color="secondary"
                          style={{ padding: "8px 0px" }}
                        />
                      </NavLink>
                    </li>
                  </ul>
                  <i
                    className={this.state.toggleBtnClass}
                    onClick={(e) => this.getMobileNavbar(e, this.state.isMenu)}
                  />
                </nav>
              </Row>
              <Row className="isMobileViewHeader">
                <nav id="navbar" className={this.state.isNavbar}>
                  <ul>
                    <li>
                      <NavLink to="/ci" className="nav-link scrollto ">
                        Home
                      </NavLink>
                    </li>
                    <li
                      onClick={(e) =>
                        this.getMobileNavbar(e, this.state.isMenu)
                      }
                    >
                      <NavLink to="/currency" className="nav-link scrollto">
                        Currency Exchange Rates
                      </NavLink>
                    </li>
                    <li
                      onClick={(e) =>
                        this.getMobileNavbar(e, this.state.isMenu)
                      }
                    >
                      <NavLink to="/tophundred" className="nav-link scrollto">
                        Top 100 Lists
                      </NavLink>
                    </li>

                    <li
                      onClick={(e) =>
                        this.getMobileNavbar(e, this.state.isMenu)
                      }
                    >
                      {this.state.isPaidLoginUser === true ? (
                        <a
                          style={{ cursor: "pointer" }}
                          className="nav-link scrollto"
                          onClick={(e) => this.isUserSubscribe(e)}
                        >
                          Advanced Screening
                        </a>
                      ) : (
                        <NavLink
                          to="/advancescreening"
                          className="nav-link scrollto"
                        >
                          Advanced Screening
                        </NavLink>
                      )}
                    </li>
                    <li
                      className="dropdown"
                      onClick={(e) => this.getDropDownMenu(e)}
                    >
                      <a href="#">
                        {" "}
                        Reports <ExpandMoreIcon />
                      </a>
                      <ul className={this.state.dropDownMenu}>
                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink
                            to="/companyreport"
                            className="nav-link scrollto"
                          >
                            Company Reports
                          </NavLink>
                        </li>

                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink
                            to="/industryreport"
                            className="nav-link scrollto"
                          >
                            Industry Averages Reports
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li
                      className="dropdown"
                      onClick={(e) => this.getServicesDropDownMenu(e)}
                    >
                      <a href="#">
                        {" "}
                        Services <ExpandMoreIcon />
                      </a>
                      <ul className={this.state.servicesDropDownMenu}>
                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink to="/plans" className="nav-link scrollto">
                            Plans
                          </NavLink>
                        </li>
                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink
                            to="/definitions"
                            className="nav-link scrollto"
                          >
                            Definitions &amp; Extensions
                          </NavLink>
                        </li>

                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink
                            to="/financialterms"
                            className="nav-link scrollto"
                          >
                            Definitions of Financial Terms
                          </NavLink>
                        </li>
                        <li
                          onClick={(e) =>
                            this.getMobileNavbar(e, this.state.isMenu)
                          }
                        >
                          <NavLink to="/feedback" className="nav-link scrollto">
                            Contact Us
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    {this.state.isLoginMenu && (
                      <li
                        onClick={(e) =>
                          this.getMobileNavbar(e, this.state.isMenu)
                        }
                      >
                        <a
                          onClick={(e) => this.getModalPopup(e)}
                          className="nav-link scrollto"
                          style={{ cursor: "pointer" }}
                        >
                          Login
                        </a>
                      </li>
                    )}

                    {this.state.isUserProfile && (
                      <li
                        onClick={(e) =>
                          this.getMobileNavbar(e, this.state.isMenu)
                        }
                      >
                        <NavLink to="/user" className="nav-link scrollto">
                          User
                        </NavLink>
                      </li>
                    )}

                    <li
                      onClick={(e) =>
                        this.getMobileNavbar(e, this.state.isMenu)
                      }
                    >
                      <NavLink to="/cart" className="nav-link scrollto">
                        <ShoppingCartIcon
                          style={{ cursor: "pointer" }}
                        ></ShoppingCartIcon>
                        <Badge
                          badgeContent={
                            this.cartData ? this.cartData.length : ""
                          }
                          color="secondary"
                          style={{ padding: "8px 0px" }}
                        />
                      </NavLink>
                    </li>
                  </ul>
                  <i
                    className={this.state.toggleBtnClass}
                    onClick={(e) => this.getMobileNavbar(e, this.state.isMenu)}
                  />
                </nav>
              </Row>
            </div>
            <Row style={{}}>
              <Col md={6}>
                <h6
                  style={{
                    marginLeft: "0.9em",
                    marginTop: "-0.7em",
                    fontSize: "0.75em",
                  }}
                >
                  Wright Investors' Service, Inc
                </h6>
              </Col>
              <Col md={6} style={{ paddingTop: "0.48em" }}>
                {this.state.isUserLogin && (
                  <span className="loginUser">
                    Welcome{" "}
                    <span style={{ color: "#f26d21" }}>{this.state.user}</span>{" "}
                    <span className="exitToAppIcon_logout">
                      {" "}
                      <ExitToAppIcon
                        style={{ cursor: "pointer" }}
                        title="Logout"
                        onClick={(e) => this.logout(e)}
                      ></ExitToAppIcon>
                    </span>
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </header>

        <Login
          ref={this.loginRefList}
          data={this.state.selectedPlan}
          pageId="header"
          onSuccess={this.onUpdate.bind(this)}
          history={this.props.history}
          isLogin={true}
          modalShow={this.state.modalShow}
          showPage="Login"
        ></Login>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          size="md"
          className="modalContain"
        >
          <ModalHeader>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    float: "left",
                  }}
                ></Label>
                <br></br>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody style={{ minHeight: "12em" }}>
            <Row>
              <Col md={12}>
                <h4 style={{ textAlign: "center", color: "#0060a3" }}>
                  To View Advanced Screening please subscribe
                </h4>
              </Col>
            </Row>

            <br></br>
            <Row>
              <Col md={2}></Col>

              <Col md={6} style={{ textAlign: "center" }}>
                <NavLink to="/plans" onClick={this.toBuyPlan.bind(this)}>
                  <Button
                    style={{
                      background: "#f58d51",
                      fontSize: "0.8em",
                      float: "right",
                      webkitBorderRadius: "10px",

                      float: "right",
                      margin: "auto",
                      marginRight: "5px",
                      borderBottom: "2px solid #636262",
                    }}
                  >
                    <CreditCardIcon style={{ fontSize: "1.5em" }} /> Subscribe
                    Plans
                  </Button>
                </NavLink>
              </Col>
              <Col md={3}></Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
