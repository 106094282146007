import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { default as VisibilityIcon } from "@material-ui/icons/Visibility";
// import { countries } from "country-data";
import MaterialTable from "material-table";
import React, { Component, forwardRef } from "react";
import { Bar } from "react-chartjs-2";
import ReactCountryFlag from "react-country-flag";
import { Slide } from "react-slideshow-image";
import "react-tabs/style/react-tabs.css";
import { Card, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import companySnapshotAction from "../../redux/companyDetails/companySnapshotAction";
import store from "../../redux/store";
import "./TopHundredItem.css";
export default class TopHundredItem extends Component {
  constructor(props) {
    super(props);

    this.tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Visibility: forwardRef((props, ref) => (
        <VisibilityIcon {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
    };
    let columns = [];
    let type = props.type;
    if (type === "marketCapitalization") {
      columns = [
        {
          title: "Rank",
          field: "id",
          align: "left",
          width: "5%",
          type: "numeric",
        },
        {
          title: "Company",
          field: "name",
          align: "left",
          width: "40%",
          sorting: true,
          render: (rowData) => this.getItemLink(rowData),
        },
        {
          title: "Ticker",
          field: "ticker",
          align: "left",
          width: "10%",
          sorting: true,
        },
        {
          title: "Market Capitalization (U.S.$ billions)",
          // field: "value",
          render: (rowData) => "$" + rowData.value,
          align: "left",
          width: "25%",
          sorting: true,
          customSort: (a, b) => {
            let newA = a.topValue;

            let charA = newA.toString().substring(newA.toString().length - 1);

            if (newA && newA.toString().charAt(0) === "$") {
              newA = newA.toString().substring(1, newA.toString().length);
            } else if (a && charA === "%") {
              newA = a.topValue
                .toString()
                .substring(0, newA.toString().length - 1);
            }

            let newB = b.topValue;
            let charB = newB.toString().substring(newB.toString().length - 1);
            if (newB && newB.toString().charAt(0) === "$") {
              newB = newB.toString().substring(1, newB.toString().length);
            } else if (b && charB === "%") {
              newB = newB.toString().substring(0, newB.toString().length - 1);
            }
            return newA - newB;
          },
        },
        {
          width: "10%",
        },

        {
          title: "Country",
          render: (rowData) => this.getCountryWithFlag(rowData),
          type: "numeric",
          sorting: true,
          align: "left",
          width: "30%",
          padding: "0px 0px 0px 30px",
          customSort: (a, b) => {
            return a.country.toString().localeCompare(b.country.toString());
          },
        },
      ];
    } else if (type === "fiscalYearSale") {
      columns = [
        {
          title: "Rank",
          field: "id",
          align: "left",
          width: "5%",
          type: "numeric",
        },
        {
          title: "Company",
          field: "name",
          align: "left",
          width: "30%",
          sorting: true,
          render: (rowData) => this.getItemLink(rowData),
        },
        {
          title: "Ticker",
          field: "ticker",
          align: "left",
          width: "10%",
          sorting: true,
        },
        {
          title: "Net Sales (U.S.$ billions)",
          // field: "value",
          render: (rowData) => "$" + rowData.value,

          align: "left",
          width: "20%",
          sorting: true,
          customSort: (a, b) => {
            let newA = a.topValue;
            let charA = newA.toString().substring(newA.toString().length - 1);
            if (newA && newA.toString().charAt(0) === "$") {
              newA = newA.toString().substring(1, newA.toString().length);
            } else if (a && charA === "%") {
              newA = a.topValue
                .toString()
                .substring(0, newA.toString().length - 1);
            }

            let newB = b.topValue;
            let charB = newB.toString().substring(newB.toString().length - 1);
            if (newB && newB.toString().charAt(0) === "$") {
              newB = newB.toString().substring(1, newB.toString().length);
            } else if (b && charB === "%") {
              newB = newB.toString().substring(0, newB.toString().length - 1);
            }
            return newA - newB;
          },
        },
        {
          width: "10%",
        },
        {
          title: "Fiscal Year",
          field: "year",
          align: "left",
          width: "15%",
          sorting: true,
        },
        {
          title: "Country",
          render: (rowData) => this.getCountryWithFlag(rowData),
          align: "left",
          width: "30%",
          sorting: true,
          customSort: (a, b) => {
            return a.country.toString().localeCompare(b.country.toString());
          },
        },
      ];
    } else if (type === "operatingMargin") {
      columns = [
        {
          title: "Rank",
          field: "id",
          align: "left",
          width: "5%",
          type: "numeric",
        },
        {
          title: "Company",
          field: "name",
          align: "left",
          width: "30%",
          sorting: true,
          render: (rowData) => this.getItemLink(rowData),
        },
        {
          title: "Ticker",
          field: "ticker",
          align: "left",
          width: "10%",
          sorting: true,
        },
        {
          title: "Operating Profit Margin (%)",
          render: (rowData) => rowData.value + "%",
          align: "left",
          width: "20%",
          sorting: true,
          customSort: (a, b) => {
            let newA = a.topValue;

            let charA = newA.toString().substring(newA.toString().length - 1);

            if (newA && newA.toString().charAt(0) === "$") {
              newA = newA.toString().substring(1, newA.toString().length);
            } else if (a && charA === "%") {
              newA = a.topValue
                .toString()
                .substring(0, newA.toString().length - 1);
            }

            let newB = b.topValue;
            let charB = newB.toString().substring(newB.toString().length - 1);
            if (newB && newB.toString().charAt(0) === "$") {
              newB = newB.toString().substring(1, newB.toString().length);
            } else if (b && charB === "%") {
              newB = newB.toString().substring(0, newB.toString().length - 1);
            }

            return newA - newB;
          },
        },
        {
          width: "10%",
        },
        {
          title: "Year",
          field: "year",
          align: "left",
          width: "15%",
          sorting: true,
        },
        {
          title: "Country",
          render: (rowData) => this.getCountryWithFlag(rowData),
          align: "left",
          width: "30%",
          sorting: true,
          customSort: (a, b) => {
            return a.country.toString().localeCompare(b.country.toString());
          },
        },
      ];
    } else if (type === "52weekPriceChange") {
      columns = [
        {
          title: "Rank",
          field: "id",
          align: "left",
          width: "5%",
          type: "numeric",
        },
        {
          title: "Company",
          field: "name",
          align: "left",
          width: "45%",
          sorting: true,
          render: (rowData) => this.getItemLink(rowData),
        },
        {
          title: "Ticker",
          field: "ticker",
          align: "left",
          width: "10%",
          sorting: true,
        },
        {
          title: "52-Week Price Change (%)",
          render: (rowData) => rowData.value + "%",
          align: "left",
          width: "20%",
          sorting: true,
          customSort: (a, b) => {
            let newA = a.topValue;

            let charA = newA.toString().substring(newA.toString().length - 1);

            if (newA && newA.toString().charAt(0) === "$") {
              newA = newA.toString().substring(1, newA.toString().length);
            } else if (a && charA === "%") {
              newA = a.topValue
                .toString()
                .substring(0, newA.toString().length - 1);
            }

            let newB = b.topValue;
            let charB = newB.toString().substring(newB.toString().length - 1);
            if (newB && newB.toString().charAt(0) === "$") {
              newB = newB.toString().substring(1, newB.toString().length);
            } else if (b && charB === "%") {
              newB = newB.toString().substring(0, newB.toString().length - 1);
            }

            return newA - newB;
          },
        },
        {
          width: "10%",
        },

        {
          title: "Country",
          render: (rowData) => this.getCountryWithFlag(rowData),
          align: "left",
          width: "30%",
          sorting: true,
          customSort: (a, b) => {
            return a.country.toString().localeCompare(b.country.toString());
          },
        },
      ];
    } else {
      columns = [
        {
          title: "Rank",
          field: "id",
          align: "left",
          width: "5%",
          type: "numeric",
        },
        {
          title: "Company",
          field: "name",
          align: "left",
          width: "20%",
          sorting: true,
          render: (rowData) => this.getItemLink(rowData),
        },
        {
          title: "Ticker",
          field: "ticker",
          align: "left",
          width: "10%",
          sorting: true,
        },
        {
          title: "3-Year Sales Growth(%)",
          render: (rowData) => rowData.value + "%",
          align: "left",
          width: "20%",
          sorting: true,
          customSort: (a, b) => {
            let newA = a.topValue;

            let charA = newA.toString().substring(newA.toString().length - 1);

            if (newA && newA.toString().charAt(0) === "$") {
              newA = newA.toString().substring(1, newA.toString().length);
            } else if (a && charA === "%") {
              newA = a.topValue
                .toString()
                .substring(0, newA.toString().length - 1);
            }

            let newB = b.topValue;
            let charB = newB.toString().substring(newB.toString().length - 1);
            if (newB && newB.toString().charAt(0) === "$") {
              newB = newB.toString().substring(1, newB.toString().length);
            } else if (b && charB === "%") {
              newB = newB.toString().substring(0, newB.toString().length - 1);
            }

            return newA - newB;
          },
        },

        {
          title: "Fiscal Year Ending",
          field: "year",
          align: "left",
          width: "20%",
          sorting: true,
        },

        {
          title: "Country",
          render: (rowData) => this.getCountryWithFlag(rowData),
          align: "left",
          width: "30%",
          sorting: true,
          customSort: (a, b) => {
            return a.country.toString().localeCompare(b.country.toString());
          },
        },
      ];
    }
    let countries = Utils.getCountryData();
    this.state = {
      countryList: countries,
      columns: columns,
      type: type,
      topHundredListData: [],
      chartLabelData: [],
      chartValuesData: [],
      render: false,
      chartData: {},
      tableHeaderBackground: "",
      tableRowBackground: "",
      heading: "",
      selectedCountry: "",
      sliderClass: "top_100_slider1",
    };
  }
  newLegendClickHandler() {}
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  getDocumentTypeForRow(rowData) {
    return (
      rowData.country +
      " " +
      (
        <ReactCountryFlag
          countryCode={rowData.codeCountry}
          svg
          style={{
            fontSize: "1.5em",
            marginLeft: "0.3em",
          }}
        />
      )
    );
  }

  showCompanyDetails = (companyData) => {
    let company = {
      cusip: companyData.cusip,
      ticker: companyData.ticker,
      country: companyData.country,
      codeCountry: companyData.codeCountry,
      planId: companyData.planId,
      price: companyData.price,
      type: companyData.type,
      companyName: companyData.name,
    };
    let cusip = companyData.cusip;
    store.dispatch(companySnapshotAction.setCompanySnapshot(cusip));
    if (cusip && cusip.length > 0) {
      window.location.href = "#/company?" + cusip;
      // this.props.history.push({
      //   pathname: "/company?c="+cusip+"&",
      //   state: {
      //     cusip: cusip,
      //     data: company,
      //   },
      // });
    }
  };

  getItemLink(item) {
    return (
      <a
        onClick={() => this.showCompanyDetails(item)}
        style={{ cursor: "pointer" }}
      >
        {item.name}
      </a>
    );
  }
  getCountryWithFlag(rowData) {
    let getCountryWithFlag = "";
    getCountryWithFlag = (
      <span>
        {Utils.getCountryFlag(rowData.country)}&nbsp;
        {rowData.country}
      </span>
    );
    return getCountryWithFlag;
  }

  getCountryWithFlag1(rowData) {
    let getCountryWithFlag = "";
    if (rowData.country === "KOREA (SOUTH)") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/KR/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
        </span>
      );
    } else if (rowData.country === "COTE D'IVOIRE") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/CI/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
        </span>
      );
    } else if (rowData.country === "VIETNAM") {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/VN/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
        </span>
      );
    } else {
      getCountryWithFlag = (
        <span>
          {
            <img
              src={`https://www.countryflags.io/${rowData.codeCountry}/flat/64.png`}
              style={{
                height: "2em",
                marginLeft: "0.3em",
              }}
            ></img>
          }
        </span>
      );
    }
    return getCountryWithFlag;
  }
  componentDidMount() {
    const postObject = {};
    let url = "";
    let heading = "";
    let color = "";
    let rowcolor = "";
    let isChart1 = "";
    let isChart2 = "";
    let tooltipTitle = "";
    let chartHeading = "";
    let sliderClass = "top_100_slider1";
    if (this.state.type === "marketCapitalization") {
      url = "top100/byMarketCap";
      color = "#16A085";
      rowcolor = "rgb(22,160,133,0.1)";
      isChart1 = true;
      isChart2 = false;
      tooltipTitle = "Market Capitalization :";
      chartHeading = "Market Capitalization";
      heading =
        "Top 25 Companies Ranked by Current Market Capitalization (U.S.$ billions)";
      sliderClass = "top_100_slider1";
    } else if (this.state.type === "fiscalYearSale") {
      url = "top100/bySales";
      color = "#2980b9";
      rowcolor = "rgb(41,128,185,0.1)";
      isChart1 = true;
      isChart2 = false;
      tooltipTitle = "Net Sales:";
      chartHeading = "Net Sales";
      heading =
        "Top 25 Companies Ranked by the Latest Net Sales (U.S.$ billions)";
      sliderClass = "top_100_slider2";
    } else if (this.state.type === "operatingMargin") {
      url = "top100/byOpMargin";
      color = "#c0392b";
      rowcolor = "rgb(192,57,43,0.1)";
      isChart1 = false;
      isChart2 = true;
      tooltipTitle = "Net Sales:";
      chartHeading = "Operating Margin";
      heading =
        " Top 25 Companies Ranked by Latest Fiscal Year Operating Profit Margin(%)";
      sliderClass = "top_100_slider3";
    } else if (this.state.type === "52weekPriceChange") {
      url = "top100/byPriceChange";
      color = "#884ea0";
      rowcolor = "rgb(136,78,160,0.1)";
      isChart1 = false;
      isChart2 = true;
      tooltipTitle = "Net Sales:";
      chartHeading = "52-Week Price Change";
      heading = " Top 25 Companies Ranked by 52-Week Price Change (%)";
      sliderClass = "top_100_slider4";
    } else if (this.state.type === "3yearSalesGrowth") {
      url = "top100/bySalesGrowth";
      color = "#d35400";
      rowcolor = "rgb(211,84,0,0.1)";
      isChart1 = false;
      isChart2 = true;
      tooltipTitle = "Net Sales:";
      chartHeading = "3-Year Sales Growth";
      heading = "Top 25 Companies Ranked by 3-Year Sales Growth";
      sliderClass = "top_100_slider5";
    }

    FetchServerData.callPostService(url, postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data.top100List;
          let chartLabelData = [];
          let chartValuesData = [];
          let categories = [];

          for (let i = 0; i < data.length; i++) {
            chartLabelData.push(data[i].name.trim());
            categories.push(data[i].name.trim());
            chartValuesData.push(data[i].value);
          }
          var items = chartValuesData.slice(0, 25);
          var items1 = chartLabelData.slice(0, 25);
          var items2 = categories.slice(0, 25);
          for (let i = 0; i < this.state.countryList.length; i++) {
            if (this.state.countryList[i].name === "United Kingdom") {
              this.state.countryList[i].alpha2 = "GB";
            }

            for (let j = 0; j < data.length; j++) {
              if (
                this.toTitleCase(data[j].country) ==
                this.state.countryList[i].name
              ) {
                data[j].codeCountry = this.state.countryList[i].alpha2;
              }
            }
          }
          if (
            this.state.type === "3yearSalesGrowth" ||
            this.state.type === "52weekPriceChange" ||
            this.state.type === "operatingMargin"
          ) {
            this.setState({
              tooltipTitle: tooltipTitle,
              render: true,
              isChart2: isChart2,
              isChart1: isChart1,
              chartHeading: chartHeading,
              heading: heading,
              topHundredListData: data,
              tableHeaderBackground: color,
              tableRowBackground: rowcolor,
              sliderClass: sliderClass,
              chartData: {
                labels: items2,
                datasets: [
                  {
                    label: chartHeading,
                    data: items,
                    backgroundColor: color,
                  },
                ],
              },
            });
          } else {
            this.setState({
              render: true,
              heading: heading,
              isChart2: isChart2,
              isChart1: isChart1,
              chartHeading: chartHeading,
              topHundredListData: data,
              tableHeaderBackground: color,
              tableRowBackground: rowcolor,
              sliderClass: sliderClass,
              chartData: {
                labels: items2,
                datasets: [
                  {
                    label: chartHeading,
                    data: items,
                    backgroundColor: color,
                  },
                ],
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  toCompanyDetails = (e, companyData) => {
    window.location.href = `/company?` + companyData.cusip;
  };

  render() {
    if (this.state.render === false) {
      return <div style={{ minHeight: "100em" }}></div>;
    }
    const style = {};
    let sliderItems = [];
    this.state.topHundredListData.forEach((topHundredList, index) => {
      if (
        this.state.type === "marketCapitalization" ||
        this.state.type === "fiscalYearSale"
      ) {
        sliderItems.push(
          <div style={style} key={index}>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-delay={300}
              style={{ cursor: "pointer", width: "90%" }}
              onClick={() => this.showCompanyDetails(topHundredList)}
            >
              <div>
                <Row>
                  <Col md={4} style={{ paddingRight: "0px" }}>
                    {index + 1 + ") "}{" "}
                    {Utils.getCountryFlag(topHundredList.country)}
                  </Col>
                  <Col md={8} style={{ paddingLeft: "0px" }}>
                    <span
                      style={{
                        color: "rgb(242, 109, 33)",
                        fontSize: "small",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {topHundredList.name}
                    </span>

                    <p
                      className="description"
                      style={{
                        color: "#305388",
                        fontSize: "small",
                        padding: "3px 0px",
                      }}
                    >
                      {"$" + topHundredList.value}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      } else {
        sliderItems.push(
          <div style={style}>
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-delay={300}
              style={{ cursor: "pointer", width: "90%" }}
              onClick={() => this.showCompanyDetails(topHundredList)}
            >
              <div>
                <Row>
                  <Col md={4} style={{ paddingRight: "0px" }}>
                    {index + 1 + ") "}
                    {Utils.getCountryFlag(topHundredList.country)}
                  </Col>
                  <Col md={8} style={{ paddingLeft: "0px" }}>
                    <span
                      style={{
                        color: "rgb(242, 109, 33)",
                        fontSize: "small",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {topHundredList.name}
                    </span>
                    <p
                      className="description"
                      style={{
                        color: "#305388",
                        fontSize: "small",
                      }}
                    >
                      {topHundredList.value + "%"}
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        );
      }
    });
    let sliderItemsMobile = [];
    this.state.topHundredListData.forEach((topHundredList, index) => {
      sliderItemsMobile.push(
        <div style={style} key={index}>
          <div
            className="col-md-6"
            data-aos="fade-up"
            data-aos-delay={300}
            style={{ cursor: "pointer", width: "90%" }}
            onClick={() => this.showCompanyDetails(topHundredList)}
          >
            <div>
              <Row>
                <Col md={3}>
                  {index + 1 + ") "}
                  {Utils.getCountryFlag(topHundredList.country)}
                </Col>
                <Col md={9}>
                  <span style={{ color: "rgb(242, 109, 33)" }}>
                    {" "}
                    {topHundredList.name}
                  </span>
                  <p className="description" style={{ color: "#305388" }}>
                    {topHundredList.value}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    });
    const fadeProperties = {
      duration: 3000,
      canSwipe: false,
    };

    const webProperties = {
      duration: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      indicators: false,
      arrows: false,
    };
    const phoneProperties = {
      duration: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      indicators: false,
      arrows: false,
    };
    return (
      <>
        <Row>
          {this.state.render === true && (
            <Col md={12} className={this.state.sliderClass}>
              <Slide {...webProperties} className="slider_web">
                {sliderItems}
              </Slide>
              <Slide {...phoneProperties} className="slider_mobile">
                {sliderItemsMobile}
              </Slide>
              <Row md={12}>
                <Col md={12}>
                  <Card
                    style={{
                      marginBottom: "2em",
                      boxShadow:
                        "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                    }}
                  >
                    {this.state.isChart1 && (
                      <Bar
                        data={this.state.chartData}
                        options={{
                          title: {
                            display: true,
                            text: this.state.heading,
                            fontSize: 15,
                          },
                          legend: {
                            display: false,
                            position: "top",
                          },
                          plugins: {
                            legend: {
                              labels: {
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          tooltips: {
                            custom: function (tooltip) {
                              if (!tooltip) return;

                              tooltip.displayColors = false;
                            },
                            callbacks: {
                              label: function (tooltipItem, data) {
                                return "$" + tooltipItem.yLabel;
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  fontSize: 10,
                                },
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: "#8f9092",
                                  callback: function (value) {
                                    return "$ " + value;
                                  },
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: this.state.chartHeading,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}

                    {this.state.isChart2 && (
                      <Bar
                        data={this.state.chartData}
                        options={{
                          title: {
                            display: true,
                            text: this.state.heading,
                            fontSize: 15,
                          },
                          legend: {
                            display: false,
                            position: "top",
                          },
                          plugins: {
                            legend: {
                              labels: {
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          tooltips: {
                            custom: function (tooltip) {
                              if (!tooltip) return;

                              tooltip.displayColors = false;
                            },
                            callbacks: {
                              label: function (tooltipItem, data) {
                                return tooltipItem.yLabel + "%";
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  fontSize: 10,
                                },
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: "#8f9092",
                                  callback: function (value) {
                                    return value + " %";
                                  },
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: this.state.chartHeading,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row md={12}>
                <Col md={12}>
                  <MaterialTable
                    icons={this.tableIcons}
                    title="Top 100 List "
                    columns={this.state.columns}
                    data={this.state.topHundredListData}
                    options={{
                      search: false,
                      sorting: true,
                      pageSizeOptions: [10, 25, 50, 75, 100],
                      actionsColumnIndex: -1,
                      pageSize: 100,
                      paging: false,
                      headerStyle: {
                        backgroundColor: this.state.tableHeaderBackground,
                        fontWeight: "bold",
                        color: "#fff",
                        align: "center",
                        fontSize: "1.2em",
                      },
                      rowStyle: {
                        height: "10px",
                        backgroundColor: this.state.tableRowBackground,
                      },
                      filtering: false,
                    }}
                    style={{
                      marginBottom: "2em",
                      fontSize: "14px",
                      boxShadow:
                        "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </>
    );
  }
}
