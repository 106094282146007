import EqualizerIcon from "@material-ui/icons/Equalizer";
import React, { Component } from "react";
export class GraphIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <>
        <EqualizerIcon
          onClick={this.onClick.bind(this)}
          title="View Graph"
          style={{
            color: "#012970",
            fontSize: "3em",
            cursor: "pointer",
            height: "0.5em",
            float: "right",
            padding: "0px 9px",
          }}
        ></EqualizerIcon>
      </>
    );
  }
}
export default GraphIconButton;
