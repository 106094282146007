import React from "react";
import PageAlertContext from "../PageAlert/PageAlertContext";
import "./HomeLayoutPage.css";
const HomeLayoutPage = ({ children }) => {
  window.scrollTo(0, 0);
  return (
    <PageAlertContext.Consumer>
      {(context) => {
        return (
          <>
            <div>{children}</div>
          </>
        );
      }}
    </PageAlertContext.Consumer>
  );
};

export default HomeLayoutPage;
