import { loadStripe } from "@stripe/stripe-js";
import Axios from "axios";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import store from "../../redux/store";
import ApplicationLayoutHeader from "../header/ApplicationLayoutHeader";
import Login from "../loginComponent/Login";
import "./Checkout.css";

const fetchCheckoutSession = async ({ quantity }) => {
  let input = { quantity: quantity };
  Axios.post("pg/create-checkout-session", input).then((response) => {});
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case "useEffectUpdate":
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case "increment":
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case "decrement":
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case "setLoading":
      return { ...state, loading: action.payload.loading };
    case "setError":
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}
let userData = "";
let modalShow = true;

const Checkout = (props) => {
  let userInfo1 = {};
  userData = store.getState().userData.userData;
  if (userData) {
    userInfo1 = Object.getOwnPropertyNames(userData);
  }

  if (userInfo1.length > 0) {
    modalShow = false;
  }

  // var size = Object.getOwnPropertyNames(userData);
  let selectedPlan = "";
  if (props.history && props.history.location && props.history.location.state) {
    selectedPlan = props.history.location.state.planDetails;
  }

  let featureList = [];
  if (selectedPlan) {
    for (let i = 0; i < selectedPlan.planItems.length; i++) {
      featureList.push(<li>{selectedPlan.planItems[i].name}</li>);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    quantity: 1,
    price: null,
    loading: false,
    error: null,
    stripe: null,
    planDetails: selectedPlan,
    // userData: userData,
  });
  const headerRef = useRef(null);
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    setUserInfo(userData);
    async function fetchConfig() {
      await FetchServerData.callPostService("pg/config", "")
        .then((output) => {
          if (output.status === "SUCCESS") {
            let responseData = output.data;
            loadStripe(responseData.publicKey).then((stripeResp) => {
              dispatch({
                type: "useEffectUpdate",
                payload: {
                  unitAmount: responseData.unitAmount,
                  currency: responseData.currency,
                  stripe: stripeResp,
                },
              });
            });
          }
        })
        .catch((error) => {});
    }
    fetchConfig();
  }, []);

  function onUpdate(props) {
    headerRef.current.onUpdate();
    modalShow = false;
    userData = store.getState().userData.userData;
    var lengthObj = Object.keys(userData).length;

    if (lengthObj > 0) {
      setUserInfo(userData);
    }
  }
  const handleClick = async (event) => {
    dispatch({ type: "setLoading", payload: { loading: true } });

    let sessionId = "";

    let input = {
      user: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
      },
      order: {
        email: userInfo.email,
        price: state.planDetails.price,
        type: "Subscription",

        orderItems: [
          {
            description: state.planDetails.description,
            plan: {
              id: state.planDetails.id,
            },
          },
        ],
      },
    };

    FetchServerData.callPostService("pg/create-checkout-subscription", input)
      .then((output) => {
        if (output.status === "SUCCESS") {
          sessionId = output.data.sessionId;
          let responseData = output.data;
          const { error } = state.stripe.redirectToCheckout({
            sessionId,
          });
          if (error) {
            dispatch({ type: "setError", payload: { error } });
            dispatch({ type: "setLoading", payload: { loading: false } });
          }
        } else {
          props.history.push({
            pathname: "/plans",
          });
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div style={{ minHeight: "40em" }}>
        <ApplicationLayoutHeader
          history={props.history}
          ref={headerRef}
        ></ApplicationLayoutHeader>
        <Row style={{ marginTop: "5em" }}>
          <Col md={12}>
            <Card
              style={{
                width: "100%",
                marginTop: "3em",
                background: "transparent",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "1em",
                  fontWeight: "bold",
                }}
              >
                <u>Review Your Order &amp; Complete Checkout</u>
              </span>
            </Card>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "1em",
            marginLeft: "4em",
            marginRight: "4em",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Col md={8} style={{ display: "flex" }}>
            <Card
              style={{
                width: "100%",
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <CardHeader
                style={{
                  background: "#2b7d6d",
                  minHeight: "2em",
                  padding: "0.5em",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "0.5em",
                  }}
                >
                  Order Details
                </span>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td colSpan={2}>
                            <b>{selectedPlan.name}</b>{" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              <b>{"$" + selectedPlan.price}</b>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <ul>{featureList}</ul>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                width: "100%",
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <CardHeader
                style={{
                  background: "#2b7d6d",
                  minHeight: "2em",
                  padding: "0.5em",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "0.5em",
                  }}
                >
                  Billing Details
                </span>
              </CardHeader>
              <CardBody style={{ minHeight: "8em" }}>
                {userInfo ? (
                  <>
                    <Row>
                      <Col md={12} style={{ margin: "0.5em" }}>
                        <h5 style={{ fontSize: "1em" }}>
                          {userInfo
                            ? userInfo.firstName
                            : "" + " " + userInfo
                            ? userInfo.lastName
                            : ""}
                        </h5>
                        <h5 style={{ fontSize: "1em" }}>{userInfo.email}</h5>
                        {userInfo.phone === null ||
                        userInfo.phone === "null" ||
                        userInfo.phone === undefined ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>{userInfo.phone}</h5>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} style={{ margin: "0.5em" }}>
                        {userInfo.address === null ||
                        userInfo.address === undefined ||
                        userInfo.address === {} ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>
                            {userInfo.address.line1}
                          </h5>
                        )}
                        {userInfo.address === null ||
                        userInfo.address === undefined ||
                        userInfo.address === {} ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>
                            {userInfo.address.line2}
                          </h5>
                        )}
                        {userInfo.address === null ||
                        userInfo.address === undefined ||
                        userInfo.address === {} ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>
                            {userInfo.address.city}
                          </h5>
                        )}
                        {userInfo.address === null ||
                        userInfo.address === undefined ||
                        userInfo.address === {} ||
                        userInfo.address.country === undefined ||
                        userInfo.address.country === null ||
                        userInfo.address.country.state === null ||
                        userInfo.address.country.state === undefined ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>
                            {userInfo.address.state.name +
                              ", " +
                              userInfo.address.country.name}
                          </h5>
                        )}
                        {userInfo.address === null ||
                        userInfo.address === undefined ||
                        userInfo.address === {} ? (
                          <h6 style={{ display: "none" }}> </h6>
                        ) : (
                          <h5 style={{ fontSize: "1em" }}>
                            {userInfo.address.zicode}
                          </h5>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
            <Card
              style={{
                width: "100%",
                marginTop: "2em",
                boxShadow:
                  "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
              }}
            >
              <CardHeader
                style={{
                  background: "#2b7d6d",
                  minHeight: "2em",
                  padding: "0.5em",
                }}
              >
                <span
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    margin: "0.5em",
                  }}
                >
                  Payments
                </span>
              </CardHeader>
              <CardBody>
                <Row style={{ margin: "0.5em", alignItems: "center" }}>
                  <Col md={6} style={{ margin: "0 auto" }}>
                    <b>
                      <span>Order Total : </span>
                      <span style={{ color: "green" }}>
                        {"$" + selectedPlan.price}
                      </span>
                    </b>
                  </Col>
                  <Col md={6} style={{ margin: "0 auto" }}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: "#f26d21",
                        color: "#fff",
                        // height: '100%',
                        borderBottom: "3px solid #636262",
                        borderRadius: "5px",
                        margin: "0 auto",
                        width: "100%",
                      }}
                      onClick={handleClick}
                    >
                      {state.loading || !state.price ? `Loading...` : `Pay Now`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Login
          // ref={this.loginRefList}
          // data={this.state.selectedPlan}
          pageId="plans"
          onSuccess={onUpdate.bind(this)}
          // history={this.props.history}
          isLogin={false}
          isSignup={true}
          modalShow={modalShow}
          showPage="plan"
        ></Login>
      </div>
    </>
  );
};

export default Checkout;
