import { GET_SECTOR_FILTER, SET_SECTOR_FILTER } from "./filterSectorTypes";

export const getSectorFilter = () => {
  return {
    type: GET_SECTOR_FILTER,
  };
};

export const setSectorFilter = (data) => {
  return {
    type: SET_SECTOR_FILTER,
    payload: data,
  };
};

export default { getSectorFilter, setSectorFilter };
