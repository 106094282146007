import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./EmployeeEfficiency.css";
export default class EmployeeEfficiency extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }

    let type = props.type;
    this.state = {
      isPdfReport: isPdfReport,
      type: type,
      employeeEfficiencyData: [],
      render: false,
      data: props.data,
      isDataNotEmpty: false,
      isDataEmpty: false,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Employees") {
      this.setState({
        graphDetails: {
          graphValues: this.state.Employees,
          labelData: this.state.LabelList,
          yAxisTitle: "Employees",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalSalesPerEmployee,
          labelData: this.state.LabelList,
          yAxisTitle: "Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Earning") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashEarnings,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Earning",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Employees1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.Employees1,
          labelData: this.state.LabelList,
          yAxisTitle: "Employees",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Sales1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.sales,
          labelData: this.state.LabelList,
          yAxisTitle: "Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netIncome1,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Earnings1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashEarnings1,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Working Capital1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.workingCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Working Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebt1,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt1",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Capital1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCapital1,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAssets1,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  componentDidMount() {
    if (this.state.data.employeeEfficiencyList.length === 0) {
      this.setState({
        isDataNotEmpty: false,
        isDataEmpty: true,
        render: true,
      });
    } else {
      let indType = this.state.data.ind_TYPE;
      this.currency = this.state.data.currency;
      this.unit = this.state.data.unit;
      let employeeEfficiencyList = this.state.data.employeeEfficiencyList.slice(
        0,
        5
      );
      if (employeeEfficiencyList.length > 0) {
        employeeEfficiencyList.reverse();
      }
      let totalSalesPerEmployee = [];
      let LabelList = [];

      let Employees = [];
      let netIncome = [];
      let cashEarnings = [];
      let totalDebt = [];
      let totalCapital = [];
      let totalAssets = [];
      let sales = [];
      let Employees1 = [];
      let netIncome1 = [];
      let cashEarnings1 = [];
      let totalDebt1 = [];
      let totalCapital1 = [];
      let totalAssets1 = [];
      let workingCapital = [];

      for (let i = 0; i < employeeEfficiencyList.length; i++) {
        totalSalesPerEmployee.push(employeeEfficiencyList[i].sales);
        LabelList.push(employeeEfficiencyList[i].fiscalYear);

        Employees.push(employeeEfficiencyList[i].employees);
        netIncome.push(employeeEfficiencyList[i].netIncome);
        cashEarnings.push(employeeEfficiencyList[i].cashEarningsPerEmployee);
        totalDebt.push(employeeEfficiencyList[i].totalDebtPerEmployee);
        totalCapital.push(employeeEfficiencyList[i].totalCapitalPerEmployee);
        totalAssets.push(employeeEfficiencyList[i].totalAssetsPerEmployee);

        Employees1.push(employeeEfficiencyList[i].employeYoYChangePercent);
        sales.push(employeeEfficiencyList[i].salesYoYChangePercent);
        netIncome1.push(employeeEfficiencyList[i].netIncomeYoYChangePercent);
        cashEarnings1.push(
          employeeEfficiencyList[i].cashEarningsYoYChangePercent
        );
        totalDebt1.push(employeeEfficiencyList[i].totalDebtYoYChangePercent);
        totalCapital1.push(
          employeeEfficiencyList[i].totalCapitalYoYChangePercent
        );
        totalAssets1.push(employeeEfficiencyList[i].totalAssetYoYChangePercent);
        workingCapital.push(
          employeeEfficiencyList[i].workingCapitalYoYChangePercent
        );
      }
      this.setState({
        employeeEfficiencyData: employeeEfficiencyList,
        indType: indType,
        isDataNotEmpty: true,
        isDataEmpty: false,
        LabelList: LabelList,
        totalSalesPerEmployee: totalSalesPerEmployee,
        Employees: Employees,
        netIncome: netIncome,
        cashEarnings: cashEarnings,
        totalDebt: totalDebt,
        totalCapital: totalCapital,
        totalAssets: totalAssets,
        Employees1: Employees1,
        netIncome1: netIncome1,
        cashEarnings1: cashEarnings1,
        totalDebt1: totalDebt1,
        totalCapital1: totalCapital1,
        totalAssets1: totalAssets1,
        workingCapital: workingCapital,
        sales: sales,
        series: [
          {
            name: "Sales Per Employee",
            type: "bar",
            data: totalSalesPerEmployee,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Sales Per Employee",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: totalSalesPerEmployee,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            {this.state.isDataNotEmpty && (
              <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
                <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
                  <Row>
                    <Col md={8}>
                      <span>
                        <span className="section_header">
                          {" "}
                          Employee Efficiency
                        </span>
                      </span>{" "}
                      <br></br>
                      <span className="unit_str" style={{ marginLeft: "-3px" }}>
                        Values per Employee are in&nbsp; {this.currency}
                      </span>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                  <Table responsive className="emp_efficiency">
                    <tr>
                      <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td style={{ fontWeight: "bold" }}>
                            {data.fiscalYear}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>
                        Fiscal Year End Date
                      </th>

                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td style={{ fontWeight: "bold" }}>
                            {data.fiscalYearEndDate !== null &&
                            data.fiscalYearEndDate !== ""
                              ? moment(data.fiscalYearEndDate).format(
                                  "MM/DD/YYYY"
                                )
                              : ""}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>
                        <span className="">Employees</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td style={{ fontWeight: "bold" }}>
                            {data.employees !== null
                              ? Utils.valueFormat(data.employees)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>
                        Values per Employee
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return <td></td>;
                      })}
                    </tr>
                    <tr>
                      <th style={{ fontWeight: "bold" }}>
                        <span className="">Sales</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td style={{ fontWeight: "bold" }}>
                            {data.sales !== null
                              ? Utils.valueFormater(data.sales)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Net Income</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {" "}
                            {data.netIncome !== null
                              ? Utils.valueFormater(data.netIncome)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Cash Earning</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {" "}
                            {data.cashEarningsPerEmployee !== null
                              ? Utils.valueFormater(
                                  data.cashEarningsPerEmployee
                                )
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>Working Capital</th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {" "}
                            {data.workingCapital !== null
                              ? Utils.valueFormater(data.workingCapital)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Debt</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {" "}
                            {data.totalDebtPerEmployee !== null
                              ? Utils.valueFormater(data.totalDebtPerEmployee)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Capital</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {" "}
                            {data.totalCapitalPerEmployee !== null
                              ? Utils.valueFormater(
                                  data.totalCapitalPerEmployee
                                )
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Assets</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.totalAssets !== null
                              ? Utils.valueFormater(data.totalAssets)
                              : "0"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>Year to Year % Change per Employee</th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return <td></td>;
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Employees</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.employeYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.employeYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Sales</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.salesYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.salesYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Net Income</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.netIncomeYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.netIncomeYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Cash Earnings</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.cashEarningsYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.cashEarningsYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Working Capital</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.workingCapitalYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.workingCapitalYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Debt</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.totalDebtYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.totalDebtYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Capital</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.totalCapitalYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.totalCapitalYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        <span className="">Total Assets</span>
                      </th>
                      {this.state.employeeEfficiencyData.map((data) => {
                        return (
                          <td>
                            {data.totalAssetYoYChangePercent !== null
                              ? Utils.valueFormater(
                                  data.totalAssetYoYChangePercent
                                ) + "%"
                              : "0%"}
                          </td>
                        );
                      })}
                    </tr>
                  </Table>
                </Col>
              </Row>
            )}

            {this.state.isDataEmpty && (
              <>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Employee Efficiency
                      </span>
                    </span>{" "}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h4 className="emp_data_error_msg">Data Not Available</h4>
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      } else {
        return (
          <>
            {this.state.isDataNotEmpty && (
              <>
                <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
                  <Col md={12}>
                    <Row>
                      <Col md={8}>
                        <span>
                          <span className="section_header">
                            {" "}
                            Employee Efficiency
                          </span>
                        </span>{" "}
                        <br></br>
                        <span
                          className="unit_str"
                          style={{ marginLeft: "-3px" }}
                        >
                          Values per Employee are in&nbsp; {this.currency}
                        </span>
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                    <Table responsive className="emp_efficiency">
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.fiscalYear}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>
                          Fiscal Year End Date
                        </th>

                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.fiscalYearEndDate !== null &&
                              data.fiscalYearEndDate !== ""
                                ? moment(data.fiscalYearEndDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>
                          <span className="">Employees</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Employees")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.employees !== null
                                ? Utils.valueFormat(data.employees)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>
                          Values per Employee
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return <td></td>;
                        })}
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>
                          <span className="">Sales</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Sales")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.sales !== null
                                ? Utils.valueFormater(data.sales)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Net Income</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Net Income")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {" "}
                              {data.netIncome !== null
                                ? Utils.valueFormater(data.netIncome)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Cash Earning</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Cash Earning")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {" "}
                              {data.cashEarningsPerEmployee !== null
                                ? Utils.valueFormater(
                                    data.cashEarningsPerEmployee
                                  )
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Working Capital</th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {" "}
                              {data.workingCapital !== null
                                ? Utils.valueFormater(data.workingCapital)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Debt</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Debt")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {" "}
                              {data.totalDebtPerEmployee !== null
                                ? Utils.valueFormater(data.totalDebtPerEmployee)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Capital</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Capital")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {" "}
                              {data.totalCapitalPerEmployee !== null
                                ? Utils.valueFormater(
                                    data.totalCapitalPerEmployee
                                  )
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Assets</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Assets")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.totalAssets !== null
                                ? Utils.valueFormater(data.totalAssets)
                                : "0"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th style={{ fontWeight: "bold" }}>
                          Year to Year % Change per Employee
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return <td></td>;
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Employees</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Employees1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.employeYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.employeYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Sales</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Sales1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.salesYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.salesYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Net Income</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Net Income1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.netIncomeYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.netIncomeYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Cash Earnings</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Cash Earnings1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.cashEarningsYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.cashEarningsYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Working Capital</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Working Capital1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.workingCapitalYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.workingCapitalYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Debt</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Debt1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.totalDebtYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.totalDebtYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Capital</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Capital1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.totalCapitalYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.totalCapitalYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>
                          <span className="">Total Assets</span>

                          <GraphIconButton
                            onClick={(e) =>
                              this.getDataInGraphicalForm("Total Assets1")
                            }
                          ></GraphIconButton>
                        </th>
                        {this.state.employeeEfficiencyData.map((data) => {
                          return (
                            <td>
                              {data.totalAssetYoYChangePercent !== null
                                ? Utils.valueFormater(
                                    data.totalAssetYoYChangePercent
                                  ) + "%"
                                : "0%"}
                            </td>
                          );
                        })}
                      </tr>
                    </Table>
                  </Col>
                </Row>
                <Modal
                  isOpen={this.state.showModal}
                  size="md"
                  className="modalContain"
                >
                  <ModalHeader>
                    <Row>
                      <Col md={10}>
                        <Label
                          style={{
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "center",
                            float: "left",
                          }}
                        >
                          Employee Efficiency&nbsp;
                        </Label>
                        <br></br>
                        {this.state.graphType !== "Employees" && (
                          <span className="unit_str">
                            Values per Employee are in&nbsp; {this.currency}
                          </span>
                        )}
                      </Col>
                      <Col md={2}>
                        <Fab
                          aria-label="add"
                          className="modal_close_btn"
                          onClick={this.toggle}
                        >
                          <CloseIcon />
                        </Fab>
                      </Col>
                    </Row>
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <SingleAxisGenericGraph
                          graphData={this.state.graphDetails}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </>
            )}

            {this.state.isDataEmpty && (
              <>
                <Row>
                  <Col md={8}>
                    <span>
                      {/* <span className="section_header_count">5.3</span> */}
                      <span className="section_header">
                        {" "}
                        {/*<i class="bi bi-table section_icon"></i>*/}
                        Employee Efficiency
                      </span>
                    </span>{" "}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h4 className="emp_data_error_msg">Data Not Available</h4>
                  </Col>
                </Row>
              </>
            )}
          </>
        );
      }
    }
  }
}
