import {
  GET_COMPANY_SNAPSHOT,
  SET_COMPANY_SNAPSHOT,
} from "./companySnapshotType";

export const getCompanySnapshot = () => {
  return {
    type: GET_COMPANY_SNAPSHOT,
  };
};

export const setCompanySnapshot = (data) => {
  return {
    type: SET_COMPANY_SNAPSHOT,
    payload: data,
  };
};

export default { getCompanySnapshot, setCompanySnapshot };
