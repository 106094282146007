import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { Row } from "reactstrap";
export default class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container maxWidth="lg" style={{ marginTop: "5em" }}>
          <main id="main">
            <Row style={{ marginTop: "1em" }}>
              <section id="termscondition" className="termscondition">
                <div className="container" data-aos="fade-up">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        data-aos="fade-up"
                        data-aos-delay={200}
                        style={{ textAlign: "left", fontFamily: "auto" }}
                      >
                        <ul>
                          <p>
                            <b>
                              By choosing to access CorporateInformation.com
                              (the “Service”), you represent that you have read
                              and agree to comply with all of these Terms and
                              Conditions of Use (the “Terms”).
                            </b>
                            <br />
                            <br />
                          </p>
                        </ul>
                        <ul>
                          <li>General</li>
                          <p>
                            The Winthrop Corporation and its licensee, Wright
                            Investors' Service, Inc. (together, "Wright") may
                            change, supplement or remove any of the Terms at any
                            time. Such changes will be posted at the Terms of
                            Use link. Accordingly, you should review these Terms
                            often.
                          </p>
                          <p>
                            <b>
                              If any of these Terms or any future changes are
                              unacceptable to you, you may discontinue your use
                              of the Service. Your continued use of the Service
                              indicates acceptance by you of the Terms in effect
                              at the time of your log-in.
                            </b>
                          </p>
                          <p>
                            Wright may modify or discontinue any part of the
                            Service at any time, including the availability of
                            any feature or content. Wright may also impose
                            limits on certain features or restrict your access
                            to parts or all of the Service without notice or
                            liability.
                          </p>
                          <li>Copyright and Other Proprietary Rights</li>
                          <p>
                            The Service and all content accessed through the
                            Service are protected by copyright pursuant to U.S.
                            and international law. You agree to abide by all
                            copyright notices, information or restrictions
                            contained in any content accessed through the
                            Service. All present and future rights in and to
                            copyrights, trademarks and service marks of any type
                            shall, as between you and Wright, at all times be
                            and remain the sole and exclusive property of
                            Wright.
                          </p>
                          <li>Restrictions on Use</li>
                          <p>
                            You may display any part of the Service on your
                            monitor, and you may print screens, but only for
                            your personal use and provided you retain and
                            observe all copyright and other proprietary notices
                            and restrictions on use.
                          </p>
                          <p>
                            YOU MAY NOT STORE ANY PORTION OF THE SERVICE IN ANY
                            COMPUTER SYSTEM (OTHER THAN IN YOUR COMPUTER'S
                            TEMPORARY MEMORY SOLELY AS A RESULT OF YOUR
                            ACCESSING THE SERVICE THROUGH A BROWSER), COPY,
                            PUBLISH, DISTRIBUTE, TRANSMIT, ALTER, OR IN ANY WAY
                            EXPLOIT ANY PART OF THE SERVICE. YOU MAY NOT
                            RECIRCULATE, REDISTRIBUTE, OR CREATE DERIVATIVE
                            WORKS FROM, ANY PART OF THE SERVICE WITHOUT WRIGHT'S
                            PRIOR WRITTEN CONSENT. ADDITIONALLY, YOU MAY NOT
                            DISTRIBUTE ANY PART OF THE SERVICE THROUGH A
                            COMPUTER NETWORK OR VIA THE INTERNET WITHOUT
                            WRIGHT'S PRIOR WRITTEN CONSENT.
                          </p>
                          <li>Disclaimer and Limitation of Liability</li>
                          <p>
                            YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE IS
                            INTENDED ONLY FOR RESEARCH AND INFORMATION AND NOT
                            FOR INDIVIDUAL INVESTMENT ADVICE. YOU ACKNOWLEDGE
                            AND AGREE THAT IT IS YOUR RESPONSIBILITY TO EVALUATE
                            INDEPENDENTLY ANY INFORMATION OR OPINION PROVIDED IN
                            THE SERVICE.
                          </p>
                          <p>
                            YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE IS
                            DISTRIBUTED ON AN “AS IS” BASIS, WITHOUT WARRANTIES
                            OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
                            WITHOUT LIMITATION, WARRANTIES OF TITLE,
                            NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                            PARTICULAR PURPOSE. WHILE THE SERVICE OBTAINS
                            INFORMATION FROM SOURCES THAT ARE BELIEVED RELIABLE,
                            WRIGHT DOES NOT GUARANTEE THE ACCURACY, COMPLETENESS
                            OR AVAILABILITY OF THE SERVICE. YOU HEREBY
                            ACKNOWLEDGE THAT USE OF THE SERVICE IS AT YOUR SOLE
                            RISK.
                          </p>
                          <p>
                            IF YOU ARE DISSATISFIED WITH THE SERVICE OR OBJECT
                            TO THE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
                            IS TO DISCONTINUE ACCESSING THE SERVICE. TO THE
                            GREATEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER
                            WRIGHT NOR ITS CONTRIBUTORS WILL BE LIABLE TO YOU
                            FOR DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                            SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES, BASED ON OR
                            ARISING FROM YOUR USE OF OR INABILITY TO USE, THE
                            SERVICE OR ANY PART OF THE SERVICE.
                          </p>
                          <li>Indemnification</li>
                          <p>
                            You hereby indemnify, defend and hold harmless
                            Wright, and all of its officers, directors, owners,
                            agents, contributors, affiliates, and licensees from
                            and against any and all liability and costs,
                            including without limitation, reasonable attorney's
                            fees, incurred by any of them in connection with any
                            claim based on or arising out of any breach of these
                            Terms by you or any user of your e-mail address.
                          </p>
                          <li>Termination</li>
                          <p>
                            Wright may, in its sole discretion, suspend or
                            terminate your access to all or any part of the
                            Service for any reason, including without
                            limitation, breach of these Terms.
                          </p>
                          <li>Links</li>
                          <p>
                            The inclusion of links from the Service to other Web
                            sites does not imply any endorsement of the sites or
                            of any products or services offered by those sites.
                            Wright does not control and is not responsible for
                            the content or policies of such sites.
                          </p>
                          <li>Governing Law</li>
                          <p>
                            These Terms shall be interpreted and enforced in
                            accordance with Connecticut law without reference to
                            its conflicts of law rules. Any action to enforce
                            any rights under these Terms shall be brought in the
                            federal or state courts located in Connecticut. You
                            agree to submit to the personal jurisdiction of the
                            state and federal courts located in Connecticut with
                            respect to any dispute based on, or arising from,
                            your use of the Service or breach of the Terms.
                          </p>
                          <li>Severability</li>
                          <p>
                            If any provision of these Terms is held to be
                            invalid or unenforceable, that provision will be
                            enforced to the maximum extent permissible, and all
                            other provisions of the Terms will remain in force
                            and effect.
                          </p>
                          <li>Entire Agreement</li>
                          <p>
                            The Terms and any other terms and conditions of
                            service on CorporateInformation.com constitute the
                            entire agreement between you and Wright relating to
                            use of the Service.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Row>
          </main>
        </Container>
      </>
    );
  }
}
