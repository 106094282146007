import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import "./ComparativeBusinessAnalysis.css";

export default class ComparativeBusinessAnalysis extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = true;
    if (props.competitor === "false") {
      isPdfReport = false;
    }
    this.state = {
      render: false,
      cusip: props.data,
      companyDescription: "",
      compititorAnalysis: "",
      salesAnalysis: "",
      yearWisesalesDataList: [],
      comparisonData: {},
      competitor1: "",
      competitor2: "",
      competitor3: "",
      competitor1Data: "",
      competitor2Data: "",
      competitor3Data: "",
      companyList: [],
      tickerList: [],
      keywordList: [],
      keywordCount: "",
      companyCount: "",
      tickerCount: "",
      isPdfReport: isPdfReport,
      isReportNotAvailable: false,
      isReportAvailable: false,
      SelectedCompetitorCusip: "",
      onClick: props.onClick,
    };
  }
  getSelectedCompetitor(SelectedCompetitor, e) {
    if (this.state.competitorType === "Competitor 1") {
      this.setState({
        competitor1: SelectedCompetitor.companyName,
        competitor1Data: SelectedCompetitor,
        showModal: !this.state.showModal,
      });
    } else if (this.state.competitorType === "Competitor 2") {
      this.setState({
        competitor2: SelectedCompetitor.companyName,
        competitor2Data: SelectedCompetitor,
        showModal: !this.state.showModal,
      });
    } else {
      this.setState({
        competitor3: SelectedCompetitor.companyName,
        competitor3Data: SelectedCompetitor,
        showModal: !this.state.showModal,
      });
    }
  }
  toCompetitorDetails(cusipId) {
    window.location.href = "#/company?" + cusipId;
    // this.props.history.push({
    //   pathname: "/company",
    //   state: {
    //     data: "companyData",
    //     pageId: "company_Search",
    //     cusip: cusipId,
    //   },
    // });
  }

  toCompanyDetails = (companyData) => {
    this.props.onClick(companyData);
  };
  valueFormat(val) {
    return String(val).replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }
  handleCompetitor1Change(e) {
    this.setState({
      competitor1: e.target.value,
      userCompetitor: e.target.value,
    });
  }
  handleCompetitor2Change(e) {
    this.setState({
      competitor2: e.target.value,
      userCompetitor: e.target.value,
    });
  }
  handleCompetitor3Change(e) {
    this.setState({
      competitor3: e.target.value,
      userCompetitor: e.target.value,
    });
  }
  handleSearchCompetitorChange(e) {
    this.setState({
      userCompetitor: e.target.value,
    });
  }
  addCompetitor1(competitor) {
    this.setState({
      searchLabel: "Search For " + competitor,
      competitorType: competitor,
      userCompetitor: this.state.userCompetitor,
      companyList: [],
      tickerList: [],
      keywordList: [],
      keywordCount: "",
      companyCount: "",
      tickerCount: "",
      showModal: true,
    });
    this.searchCompetitor(this.state.userCompetitor);
  }
  addCompetitor2(competitor) {
    this.setState({
      searchLabel: "Search For " + competitor,
      competitorType: competitor,
      userCompetitor: this.state.userCompetitor,
      companyList: [],
      tickerList: [],
      keywordList: [],
      keywordCount: "",
      companyCount: "",
      tickerCount: "",
      showModal: true,
    });
    this.searchCompetitor(this.state.userCompetitor);
  }
  addCompetitor3(competitor) {
    this.setState({
      searchLabel: "Search For " + competitor,
      competitorType: competitor,
      userCompetitor: this.state.userCompetitor,
      companyList: [],
      tickerList: [],
      keywordList: [],
      keywordCount: "",
      companyCount: "",
      tickerCount: "",
      showModal: true,
    });
    this.searchCompetitor(this.state.userCompetitor);
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  addCompetitor = () => {
    window.scrollTo(0, document.body.scrollHeight);
    this.setState({ showModal: !this.state.showModal });
  };

  getCompareData() {
    const postObject = {
      cusip: this.state.cusip,
      competitor1Cusip: this.state.competitor1Data.cusip,
      competitor2Cusip: this.state.competitor2Data.cusip,
      competitor3Cusip: this.state.competitor3Data.cusip,
    };
    FetchServerData.callPostService("company/cbaForCusip", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          window.scrollTo(0, 0);
          let data = output.data;
          let isReportNotAvailable = false;
          let isReportAvailable = false;
          let errorMessage = "";
          if (data.messageToShow) {
            isReportNotAvailable = true;
            isReportAvailable = false;
            errorMessage = data.messageToShow;
            this.setState({
              errorMessage: errorMessage,
              isReportAvailable: isReportAvailable,
              isReportNotAvailable: isReportNotAvailable,
              render: true,
              financialPositionComparisonData: [],
              companyValuationData: [],
              profitabilityComparisonData: [],
              salesComparisonData: [],
              tickerList: [],
              companyList: [],
              keywordList: [],
            });
          } else {
            isReportNotAvailable = false;
            isReportAvailable = true;
            let yearWiseSalesDataList =
              data.salesAnalysisData.yearWisesalesData.salesData;
            let comparisonData = {};
            let valuationData = {};
            let profitabilityAnalysisData = {};
            let profitabilityComparisonDataObj = {};
            let dividendAnalysisData = {};
            let inventoryAnalysisData = {};
            let researchAndDevelopmentData = {};
            let financialPosition = {};
            let salesComparisonData = [];
            let companyValuationData = [];
            let profitabilityComparisonData = [];
            let financialPositionComparisonData = [];
            let competitor1 = "";
            let competitor2 = "";
            let competitor3 = "";
            let competitor1Data = "";
            let competitor2Data = "";
            let competitor3Data = "";
            let salesAnalysisUnit = "";
            let quarterlyEarningList = output.data.quarterlyEarningList.sort();

            let quarterlyDividendList = output.data.quarterlyDividendList;
            let prices = output.data.priceData;

            let quarterlyEarningValues = [];
            let quarterlyDividendValues = [];

            let earningsDividendLabel = [];
            if (quarterlyEarningList.length !== 0) {
              for (let i = 0; i < quarterlyEarningList.length; i++) {
                if (
                  quarterlyEarningList[i].eps !== 0 ||
                  quarterlyEarningList[i].eps !== null ||
                  quarterlyEarningList[i].eps !== undefined
                ) {
                  quarterlyEarningValues.push(quarterlyEarningList[i].eps);
                  earningsDividendLabel.push(
                    moment(quarterlyEarningList[i].date).format("YYYY MMM")
                  );
                }
              }
            }

            if (quarterlyDividendList.length !== 0) {
              for (let i = 0; i < quarterlyDividendList.length; i++) {
                if (
                  quarterlyDividendList[i].dps !== 0 ||
                  quarterlyDividendList[i].dps !== null ||
                  quarterlyDividendList[i].dps !== undefined
                ) {
                  quarterlyDividendValues.push(quarterlyDividendList[i].dps);
                }
              }
            }
            let priceValues = [];
            if (prices.length !== 0) {
              for (let i = 0; i < prices.length; i++) {
                if (prices[i].highMar !== null) {
                  let obj3 = {
                    year: prices[i].year + " MAR",
                    highPrice: prices[i].highMar,
                  };
                  priceValues.push(obj3);
                }

                if (prices[i].highMar !== null) {
                  let obj6 = {
                    year: prices[i].year + " JUN",
                    highPrice: prices[i].highJun,
                  };
                  priceValues.push(obj6);
                }

                if (prices[i].highMar !== null) {
                  let obj9 = {
                    year: prices[i].year + " SEP",
                    highPrice: prices[i].highSep,
                  };
                  priceValues.push(obj9);
                }

                // let highDec = prices[i].highDec;
                // if (prices[i].highDec === null) {
                //   highDec = 0;
                // }
                if (prices[i].highDec !== null) {
                  let obj12 = {
                    year: prices[i].year + " DEC",
                    highPrice: prices[i].highDec,
                  };
                  priceValues.push(obj12);
                }
              }
            }
            let priceValuesData = [];
            let priceLabel = [];
            if (priceValues.length !== 0) {
              for (let i = 0; i < priceValues.length; i++) {
                if (priceValues[i].highPrice !== 0) {
                  priceValuesData.push(priceValues[i].highPrice);
                }

                priceLabel.push(priceValues[i].year);
              }
            }
            if (data.salesAnalysisData.comparisonData) {
              salesAnalysisUnit = data.salesAnalysisData.comparisonData.unit;
              comparisonData = data.salesAnalysisData.comparisonData;
              salesComparisonData =
                data.salesAnalysisData.comparisonData.salesComparisonData;
            }
            if (data.salesAnalysisData.valuationData) {
              valuationData = data.salesAnalysisData.valuationData;
              companyValuationData =
                data.salesAnalysisData.valuationData.companyValuationData;
            }

            if (data.profitabilityAnalysisData) {
              profitabilityComparisonData =
                data.profitabilityAnalysisData.profitabilityComparisonData
                  .comparisonData;
              profitabilityAnalysisData = data.profitabilityAnalysisData;
              profitabilityComparisonDataObj =
                data.profitabilityAnalysisData.profitabilityComparisonData;
            }
            if (data.dividentAnalysisData) {
              dividendAnalysisData = data.dividentAnalysisData;
            }
            if (data.inventoryAnalysisData) {
              inventoryAnalysisData = data.inventoryAnalysisData;
            }
            if (data.rndAnalysisData) {
              researchAndDevelopmentData = data.rndAnalysisData;
            }

            if (data.financialPositionData) {
              financialPosition = data.financialPositionData;
              if (data.financialPositionData.faComparisonData) {
                financialPositionComparisonData =
                  data.financialPositionData.faComparisonData.comparisonData;
              }
            }
            if (data.competitorList !== null) {
              if (data.competitorList.length === 3) {
                competitor1 = data.competitorList[0].name;
                competitor2 = data.competitorList[1].name;
                competitor3 = data.competitorList[2].name;
                competitor1Data = data.competitorList[0];
                competitor2Data = data.competitorList[1];
                competitor3Data = data.competitorList[2];
              } else if (data.competitorList.length === 2) {
                competitor1 = data.competitorList[0].name;
                competitor2 = data.competitorList[1].name;

                competitor1Data = data.competitorList[0];
                competitor2Data = data.competitorList[1];
              } else if (data.competitorList.length === 1) {
                competitor1 = data.competitorList[0].name;

                competitor1Data = data.competitorList[0];
              }
            }

            let saleAnalysisValues = [];
            let saleAnalysisLabel = [];
            for (let i = 0; i < yearWiseSalesDataList.length; i++) {
              saleAnalysisValues.push(yearWiseSalesDataList[i].sales);
              saleAnalysisLabel.push(yearWiseSalesDataList[i].year);
            }

            let urlArr = "";
            let competitorArr1 = "";
            let competitorArr2 = "";
            let competitorArr3 = "";
            if (data.competitorAnalysis) {
              urlArr = data.competitorAnalysis.split("###");
              competitorArr1 = urlArr[1].split(",");
              competitorArr2 = urlArr[3].split(",");
              competitorArr3 = urlArr[5].split(",");
              // email = emailArr[1];
            }
            this.setState({
              unit: data.unit,
              urlArr: urlArr,
              competitorArr1: competitorArr1,
              competitorArr2: competitorArr2,
              competitorArr3: competitorArr3,
              errorMessage: errorMessage,
              isReportAvailable: isReportAvailable,
              isReportNotAvailable: isReportNotAvailable,
              companyDescription: data.companyDescription,
              // compititorAnalysis: data.competitorAnalysis,
              // compititorAnalysis: competitorAnalysis,
              salesComparisonData: salesComparisonData,
              salesAnalysisUnit: salesAnalysisUnit,
              companyValuationData: companyValuationData,
              valuationData: valuationData,
              comparisonData: comparisonData,
              inventoryAnalysisData: inventoryAnalysisData,
              researchAndDevelopmentData: researchAndDevelopmentData,
              financialPositionComparisonData: financialPositionComparisonData,
              financialPosition: financialPosition,
              dividendAnalysisData: dividendAnalysisData,
              salesAnalysisData: data.salesAnalysisData,
              profitabilityAnalysisData: profitabilityAnalysisData,
              profitabilityComparisonDataObj: profitabilityComparisonDataObj,
              profitabilityComparisonData: profitabilityComparisonData,
              salesAnalysis: data.salesAnalysis,
              competitor1: competitor1,
              competitor2: competitor2,
              competitor3: competitor3,
              competitor1Data: competitor1Data,
              competitor2Data: competitor2Data,
              competitor3Data: competitor3Data,
              render: true,
              series: [
                {
                  name: "Sales",
                  type: "bar",
                  data: saleAnalysisValues,
                },
              ],
              options: {
                chart: {
                  height: 350,
                  type: "bar",
                  stacked: false,
                  toolbar: {
                    show: false,
                  },
                },

                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                title: {
                  text: data.name,
                  align: "center",
                },
                stroke: {
                  width: [0, 2, 5],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    colors: {
                      ranges: [
                        {
                          to: 0,
                          from: -100000000000,
                          color: "#F15B46",
                        },
                      ],
                    },
                    columnWidth: "50%",
                  },
                },
                colors: ["#1f77b4"],

                legend: {
                  show: true,
                  showForSingleSeries: true,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "bottom",
                  horizontalAlign: "center",
                  floating: false,
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial",
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  offsetX: 0,
                  offsetY: 0,
                  labels: {
                    colors: ["#7d6608", "#0b5345"],
                    useSeriesColors: true,
                  },
                },

                labels: saleAnalysisLabel,
                markers: {
                  size: 0,
                },
                dataLabels: {
                  enabled: false,
                },

                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
                yaxis: {
                  labels: {
                    formatter: function (val, index) {
                      return String(val).replace(/(.)(?=(\d{3})+$)/g, "$1,");
                    },
                  },
                  title: {
                    text: "Sales",
                  },
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: "#808b96 ",
                  },
                },

                tooltip: {
                  shared: true,
                  intersect: false,
                },
              },
              earnAndDndSeries: [
                {
                  name: "EPS",
                  type: "bar",
                  data: quarterlyEarningValues,
                },
                {
                  name: "DPS",
                  type: "bar",
                  data: quarterlyDividendValues,
                },
                // {
                //   name: "price",
                //   type: "line",
                //   data: priceValuesData,
                // },
              ],
              earnAndDndOptions: {
                chart: {
                  height: 350,
                  // type: "line",
                  stacked: false,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },

                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                title: {
                  text: data.name,
                  align: "center",
                },
                stroke: {
                  width: [0, 2, 5],
                  curve: "smooth",
                },
                plotOptions: {
                  colors: {
                    ranges: [
                      {
                        to: 0,
                        from: -100000000000,
                        color: "#F15B46",
                      },
                    ],
                  },
                  bar: {
                    columnWidth: "50%",
                  },
                },
                colors: ["#ff7f0e", "#1f77b4"],

                legend: {
                  show: true,
                  showForSingleSeries: false,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "bottom",
                  horizontalAlign: "center",
                  floating: false,
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial",
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  offsetX: 0,
                  offsetY: 0,
                  labels: {
                    colors: ["#7d6608", "#0b5345"],
                    useSeriesColors: true,
                  },
                },

                labels: earningsDividendLabel,
                // markers: {
                //   size: 0,
                // },
                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                    min: 0,
                    labels: {
                      style: {
                        colors: "#808b96 ",
                      },
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
                yaxis: [
                  {
                    axisTicks: {
                      show: true,
                    },
                    forceNiceScale: false,
                    axisBorder: {
                      show: true,
                      color: "#000",
                    },
                    labels: {
                      style: {
                        colors: "#000",
                      },
                    },
                    title: {
                      text: "EPS",
                      style: {
                        color: "#7d6608",
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  {
                    seriesName: "DPS",
                    opposite: true,

                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#000",
                    },
                    labels: {
                      style: {
                        colors: "#000",
                      },
                    },
                    title: {
                      text: "DPS",
                      style: {
                        color: "#0b5345",
                      },
                    },
                  },
                ],
                // yaxis: [
                //   {
                //     axisTicks: {
                //       show: true,
                //     },
                //     axisBorder: {
                //       show: true,
                //       color: "#247BA0",
                //     },
                //     labels: {
                //       formatter: function (val, index) {
                //         if (val) {
                //           return val.toFixed();
                //         }
                //       },
                //       style: {
                //         colors: "#247BA0",
                //       },
                //     },
                //     min: 0,
                //     title: {
                //       text: "Earnings & Dividends ",
                //       style: {
                //         color: "#247BA0",
                //       },
                //     },
                //   },
                // ],

                tooltip: {
                  shared: true,
                  intersect: false,
                },
              },
              priceSeries: [
                {
                  name: "Price",
                  type: "line",
                  data: priceValuesData,
                },
              ],
              priceOptions: {
                chart: {
                  height: 350,
                  type: "line",
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: data.name,
                  align: "center",
                },
                legend: {
                  show: true,
                  showForSingleSeries: true,
                  position: "bottom",
                },
                labels: priceLabel,
                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                    labels: {
                      style: {
                        colors: "#808b96 ",
                      },
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
                yaxis: {
                  title: {
                    text: "values",
                  },
                  min: 0,
                },
                yaxis: [
                  {
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#247BA0",
                    },

                    labels: {
                      formatter: function (val, index) {
                        return val.toFixed();
                      },
                      style: {
                        colors: "#247BA0",
                      },
                    },
                    title: {
                      text: "Price",
                      style: {
                        color: "#247BA0",
                      },
                    },
                  },
                ],
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  searchCompetitor() {
    const postObject = {
      searchText: this.state.userCompetitor,
      page: { pageNo: 1, pageSize: 10 },
    };
    FetchServerData.callPostService("searchService/simpleSearch", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let tickerList = output.data.tickerList;

          let keywordList = output.data.keywordList;

          let companyList = output.data.companyList;

          this.setState({
            companyList: companyList,
            tickerList: tickerList,
            keywordList: keywordList,
            keywordCount: keywordList.length,
            companyCount: companyList.length,
            tickerCount: tickerList.length,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  componentDidMount() {
    const postObject = { cusip: this.state.cusip };

    FetchServerData.callPostService("company/comparativeBA", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let isReportNotAvailable = false;
          let isReportAvailable = false;
          let errorMessage = "";
          if (data.messageToShow) {
            isReportNotAvailable = true;
            isReportAvailable = false;
            errorMessage = data.messageToShow;
            this.setState({
              errorMessage: errorMessage,
              isReportAvailable: isReportAvailable,
              isReportNotAvailable: isReportNotAvailable,
              render: true,
              financialPositionComparisonData: [],
              companyValuationData: [],
              profitabilityComparisonData: [],
              salesComparisonData: [],
              tickerList: [],
              companyList: [],
              keywordList: [],
            });
          } else {
            isReportNotAvailable = false;
            isReportAvailable = true;
            let yearWiseSalesDataList =
              data.salesAnalysisData.yearWisesalesData.salesData;
            yearWiseSalesDataList.sort(function compare(a, b) {
              var dateA = new Date(a.year);
              var dateB = new Date(b.year);
              return dateA - dateB;
            });
            let comparisonData = {};
            let valuationData = {};
            let profitabilityAnalysisData = {};
            let profitabilityComparisonDataObj = {};
            let dividendAnalysisData = {};
            let inventoryAnalysisData = {};
            let researchAndDevelopmentData = {};
            let financialPosition = {};
            let salesComparisonData = [];
            let companyValuationData = [];
            let profitabilityComparisonData = [];
            let financialPositionComparisonData = [];
            let competitor1 = "";
            let competitor2 = "";
            let competitor3 = "";
            let competitor1Data = "";
            let competitor2Data = "";
            let competitor3Data = "";
            let salesAnalysisUnit = "";
            let quarterlyEarningList = output.data.quarterlyEarningList.sort();

            let quarterlyDividendList = output.data.quarterlyDividendList;

            let prices = output.data.priceData;

            let quarterlyEarningValues = [];
            let quarterlyDividendValues = [];

            let earningsDividendLabel = [];
            if (quarterlyEarningList.length !== 0) {
              for (let i = 0; i < quarterlyEarningList.length; i++) {
                if (
                  quarterlyEarningList[i].eps !== 0 ||
                  quarterlyEarningList[i].eps !== null ||
                  quarterlyEarningList[i].eps !== undefined
                ) {
                  quarterlyEarningValues.push(quarterlyEarningList[i].eps);
                  // let qtrEarnObj = {
                  //   x: new Date(quarterlyEarningList[i].date),
                  //   // x:new Date(1538778600000),
                  // y: parseFloat(quarterlyEarningList[i].eps)
                  // }
                  // quarterlyEarningValues.push(
                  //   qtrEarnObj
                  // );
                  if (
                    moment(quarterlyEarningList[i].date).format("MMM") === "Mar"
                  ) {
                    earningsDividendLabel.push(
                      moment(quarterlyEarningList[i].date).format("YYYY")
                    );
                  } else {
                    earningsDividendLabel.push("");
                  }
                  // earningsDividendLabel.push(
                  //   moment(quarterlyEarningList[i].date).format("YYYY MMM")
                  // );
                }
              }
            }
            if (quarterlyDividendList.length !== 0) {
              for (let i = 0; i < quarterlyDividendList.length; i++) {
                if (
                  quarterlyDividendList[i].dps !== 0 ||
                  quarterlyDividendList[i].dps !== null ||
                  quarterlyDividendList[i].dps !== undefined
                ) {
                  // let qtrDivObj = {
                  //   x: new Date(quarterlyDividendList[i].date),
                  // y: parseFloat(quarterlyDividendList[i].dps)
                  // }
                  // quarterlyDividendValues.push(
                  //   qtrDivObj
                  // );
                  quarterlyDividendValues.push(quarterlyDividendList[i].dps);
                }
              }
            }
            let priceAnalysisArr = data.monthlyPriceAnalysis;
            // var items = priceAnalysisArr.splice(0, 4);
            let graphPriceData = [];
            let graphPriceList = [];
            for (let i = 0; i < priceAnalysisArr.length; i++) {
              for (
                let j = 0;
                j < priceAnalysisArr[i].monthlyPriceList.length;
                j++
              ) {
                if (
                  priceAnalysisArr[i].monthlyPriceList[j].highPrice !== null ||
                  priceAnalysisArr[i].monthlyPriceList[j].lowPrice !== null
                ) {
                  graphPriceData.push(priceAnalysisArr[i].monthlyPriceList[j]);
                }
              }
            }
            var minValue = graphPriceData.reduce(function (res, obj) {
              if (res.lowPrice !== null || obj.lowPrice !== null) {
                return obj.lowPrice < res.lowPrice ? obj : res;
              }
            });

            var maxValue = graphPriceData.reduce(function (res, obj) {
              return obj.highPrice > res.highPrice ? obj : res;
            });

            let minRangeValue = minValue.highPrice;
            let maxRangeValue = maxValue.highPrice;

            for (let i = 0; i < graphPriceData.length; i++) {
              let priceObj = {
                x: new Date(graphPriceData[i].date),
                y: [
                  graphPriceData[i].closingPrice,
                  graphPriceData[i].highPrice,
                  graphPriceData[i].lowPrice,
                  graphPriceData[i].closingPrice,
                ],
              };
              graphPriceList.push(priceObj);
            }
            let priceValues = [];
            if (prices.length !== 0) {
              for (let i = 0; i < prices.length; i++) {
                if (prices[i].highMar !== null) {
                  let obj3 = {
                    year: prices[i].year + " MAR",
                    highPrice: prices[i].highMar,
                  };
                  priceValues.push(obj3);
                }

                if (prices[i].highMar !== null) {
                  let obj6 = {
                    year: prices[i].year + " JUN",
                    highPrice: prices[i].highJun,
                  };
                  priceValues.push(obj6);
                }

                if (prices[i].highMar !== null) {
                  let obj9 = {
                    year: prices[i].year + " SEP",
                    highPrice: prices[i].highSep,
                  };
                  priceValues.push(obj9);
                }

                // let highDec = prices[i].highDec;
                // if (prices[i].highDec === null) {
                //   highDec = 0;
                // }
                if (prices[i].highDec !== null) {
                  let obj12 = {
                    year: prices[i].year + " DEC",
                    highPrice: prices[i].highDec,
                  };
                  priceValues.push(obj12);
                }
              }
            }

            let priceValuesData = [];
            let priceLabel = [];
            if (priceValues.length !== 0) {
              for (let i = 0; i < priceValues.length; i++) {
                if (priceValues[i].highPrice !== 0) {
                  priceValuesData.push(priceValues[i].highPrice);
                }

                priceLabel.push(priceValues[i].year);
              }
            }
            if (data.salesAnalysisData.comparisonData) {
              salesAnalysisUnit = data.salesAnalysisData.comparisonData.unit;
              comparisonData = data.salesAnalysisData.comparisonData;
              salesComparisonData =
                data.salesAnalysisData.comparisonData.salesComparisonData;
            }
            if (data.salesAnalysisData.valuationData) {
              valuationData = data.salesAnalysisData.valuationData;
              companyValuationData =
                data.salesAnalysisData.valuationData.companyValuationData;
            }

            if (data.profitabilityAnalysisData) {
              profitabilityComparisonData = [];
              if (data.profitabilityAnalysisData.profitabilityComparisonData) {
                profitabilityComparisonData =
                  data.profitabilityAnalysisData.profitabilityComparisonData
                    .comparisonData;
              }
              profitabilityAnalysisData = data.profitabilityAnalysisData;
              profitabilityComparisonDataObj =
                data.profitabilityAnalysisData.profitabilityComparisonData;
            }
            if (data.dividentAnalysisData) {
              dividendAnalysisData = data.dividentAnalysisData;
            }
            if (data.inventoryAnalysisData) {
              inventoryAnalysisData = data.inventoryAnalysisData;
            }
            if (data.rndAnalysisData) {
              researchAndDevelopmentData = data.rndAnalysisData;
            }

            if (data.financialPositionData) {
              financialPosition = data.financialPositionData;
              if (data.financialPositionData.faComparisonData) {
                financialPositionComparisonData =
                  data.financialPositionData.faComparisonData.comparisonData;
              }
            }
            if (data.competitorList !== null) {
              if (data.competitorList.length > 0) {
                competitor1 = data.competitorList[0]
                  ? data.competitorList[0].name
                  : "";
                competitor2 = data.competitorList[1]
                  ? data.competitorList[1].name
                  : "";
                competitor3 = data.competitorList[2]
                  ? data.competitorList[2].name
                  : "";
                competitor1Data = data.competitorList[0]
                  ? data.competitorList[0]
                  : {};
                competitor2Data = data.competitorList[1]
                  ? data.competitorList[1]
                  : {};

                competitor3Data = data.competitorList[2]
                  ? data.competitorList[2]
                  : {};
              }
            }

            let saleAnalysisValues = [];
            let saleAnalysisLabel = [];

            for (let i = 0; i < yearWiseSalesDataList.length; i++) {
              saleAnalysisValues.push(yearWiseSalesDataList[i].sales);
              saleAnalysisLabel.push(yearWiseSalesDataList[i].year);
            }

            let urlArr = "";
            let competitorArr1 = "";
            let competitorArr2 = "";
            let competitorArr3 = "";
            if (data.competitorAnalysis) {
              urlArr = data.competitorAnalysis.split("###");
              competitorArr1 = urlArr[1] ? urlArr[1].split(",") : "";
              competitorArr2 = urlArr[3] ? urlArr[3].split(",") : "";
              competitorArr3 = urlArr[5] ? urlArr[5].split(",") : "";
              // email = emailArr[1];
            }
            this.setState({
              unit: data.unit,
              urlArr: urlArr,
              competitorArr1: competitorArr1,
              competitorArr2: competitorArr2,
              competitorArr3: competitorArr3,
              errorMessage: errorMessage,
              isReportAvailable: isReportAvailable,
              isReportNotAvailable: isReportNotAvailable,
              companyDescription: data.companyDescription,
              salesComparisonData: salesComparisonData,
              salesAnalysisUnit: salesAnalysisUnit,
              companyValuationData: companyValuationData,
              valuationData: valuationData,
              comparisonData: comparisonData,
              inventoryAnalysisData: inventoryAnalysisData,
              researchAndDevelopmentData: researchAndDevelopmentData,
              financialPositionComparisonData: financialPositionComparisonData,
              financialPosition: financialPosition,
              dividendAnalysisData: dividendAnalysisData,
              salesAnalysisData: data.salesAnalysisData,
              profitabilityAnalysisData: profitabilityAnalysisData,
              profitabilityComparisonDataObj: profitabilityComparisonDataObj,
              profitabilityComparisonData: profitabilityComparisonData,
              salesAnalysis: data.salesAnalysis,
              competitor1: competitor1,
              competitor2: competitor2,
              competitor3: competitor3,
              competitor1Data: competitor1Data,
              competitor2Data: competitor2Data,
              competitor3Data: competitor3Data,
              render: true,
              series: [
                {
                  name: "Sales",
                  type: "bar",
                  data: saleAnalysisValues,
                },
              ],
              options: {
                chart: {
                  height: 350,
                  type: "bar",
                  stacked: false,
                  toolbar: {
                    show: false,
                  },
                },

                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                title: {
                  text: data.name,
                  align: "center",
                },
                stroke: {
                  width: [0, 2, 5],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    colors: {
                      ranges: [
                        {
                          to: 0,
                          from: -100000000000,
                          color: "#F15B46",
                        },
                      ],
                    },
                    columnWidth: "50%",
                  },
                },
                colors: ["#1f77b4"],

                legend: {
                  show: true,
                  showForSingleSeries: true,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "top",
                  horizontalAlign: "right",
                  floating: false,
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial",
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  offsetX: 0,
                  offsetY: 0,
                  labels: {
                    colors: ["#7d6608", "#0b5345"],
                    useSeriesColors: true,
                  },
                },

                labels: saleAnalysisLabel,
                markers: {
                  size: 0,
                },
                dataLabels: {
                  enabled: false,
                },

                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
                yaxis: {
                  labels: {
                    formatter: function (val, index) {
                      return String(val).replace(/(.)(?=(\d{3})+$)/g, "$1,");
                    },
                  },
                  title: {
                    text: "Sales",
                  },
                  axisTicks: {
                    show: true,
                  },
                  axisBorder: {
                    show: true,
                    color: "#808b96 ",
                  },
                },

                tooltip: {
                  shared: true,
                  intersect: false,
                },
              },
              earnAndDndSeries: [
                {
                  name: "EPS",
                  type: "bar",
                  data: quarterlyEarningValues,
                },
                {
                  name: "DPS",
                  type: "bar",
                  data: quarterlyDividendValues,
                },
                // {
                //   name: "price",
                //   type: "line",
                //   data: priceValuesData,
                // },
              ],
              earnAndDndOptions: {
                chart: {
                  height: 350,
                  type: "line",
                  stacked: false,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },

                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                title: {
                  text: data.name,
                  align: "center",
                },
                stroke: {
                  width: [0, 2, 5],
                  curve: "smooth",
                },

                plotOptions: {
                  bar: {
                    colors: {
                      ranges: [
                        {
                          to: 0,
                          from: -100000000000,
                          color: "#F15B46",
                        },
                      ],
                    },
                    columnWidth: "50%",
                  },
                },
                colors: ["#ff7f0e", "#1f77b4"],

                legend: {
                  show: true,
                  showForSingleSeries: false,
                  showForNullSeries: true,
                  showForZeroSeries: true,
                  position: "top",
                  horizontalAlign: "right",
                  floating: false,
                  fontSize: "14px",
                  fontFamily: "Helvetica, Arial",
                  fontWeight: 400,
                  formatter: undefined,
                  inverseOrder: false,
                  width: undefined,
                  height: undefined,
                  tooltipHoverFormatter: undefined,
                  offsetX: 0,
                  offsetY: 0,
                  labels: {
                    colors: ["#7d6608", "#0b5345"],
                    useSeriesColors: true,
                  },
                },

                labels: earningsDividendLabel,
                // markers: {
                //   size: 0,
                // },
                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                    labels: {
                      style: {
                        colors: "#808b96 ",
                      },
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },
                yaxis: [
                  {
                    axisTicks: {
                      show: true,
                    },
                    forceNiceScale: false,
                    axisBorder: {
                      show: true,
                      color: "#000",
                    },
                    labels: {
                      style: {
                        colors: "#000",
                      },
                    },
                    title: {
                      text: "EPS & DPS",
                      style: {
                        color: "#7d6608",
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  // {
                  //   seriesName: 'DPS',
                  //   opposite: true,

                  //   axisTicks: {
                  //     show: true,
                  //   },
                  //   axisBorder: {
                  //     show: true,
                  //     color: '#000'
                  //   },
                  //   labels: {
                  //     style: {
                  //       colors: '#000',
                  //     }
                  //   },
                  //   title: {
                  //     text: "DPS",
                  //     style: {
                  //       color: '#0b5345',
                  //     }
                  //   },
                  // },
                ],
                // yaxis: [
                //   {
                //     axisTicks: {
                //       show: true,
                //     },
                //     axisBorder: {
                //       show: true,
                //       color: "#247BA0",
                //     },
                //     labels: {
                //       formatter: function (val, index) {
                //         if (val) {
                //           return val.toFixed(2);
                //         }
                //       },
                //       style: {
                //         colors: "#247BA0",
                //       },
                //     },
                //     title: {
                //       text: "Earnings & Dividends ",
                //       style: {
                //         color: "#247BA0",
                //       },
                //     },
                //     // min: (min) => {
                //     //   return min;
                //     // },
                //   },
                // ],

                tooltip: {
                  shared: true,
                  intersect: false,
                },
              },
              priceSeries: [
                {
                  name: "Price",
                  type: "line",
                  data: priceValuesData,
                },
              ],
              priceOptions: {
                chart: {
                  height: 350,
                  type: "line",
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: data.name,
                  align: "center",
                },

                legend: {
                  show: true,
                  showForSingleSeries: true,
                  position: "top",
                  horizontalAlign: "right",
                },
                labels: priceLabel,
                xaxis: {
                  title: {
                    // text: "Year",
                    style: {
                      colors: "#808b96",
                    },
                    labels: {
                      style: {
                        colors: "#808b96 ",
                      },
                    },
                  },

                  axisBorder: {
                    show: true,
                    color: "#808b96",
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                },

                yaxis: [
                  {
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#247BA0",
                    },

                    labels: {
                      formatter: function (val, index) {
                        return val.toFixed();
                      },
                      style: {
                        colors: "#247BA0",
                      },
                    },
                    title: {
                      text: "Price",
                      style: {
                        color: "#247BA0",
                      },
                    },
                  },
                ],
              },

              series1: [
                {
                  name: "Price",
                  type: "candlestick",
                  data: graphPriceList,
                },
                {
                  name: "Earnings",
                  type: "line",
                  data: quarterlyEarningValues,
                },
                {
                  name: "Dividends",
                  type: "line",
                  data: quarterlyDividendValues,
                },
              ],
              options1: {
                chart: {
                  height: 350,
                  type: "line",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                plotOptions: {
                  candlestick: {
                    colors: {
                      upward: "#000",
                      downward: "#000",
                    },
                    wick: {
                      useFillColor: true,
                    },
                  },
                },
                // title: {
                //   text: output.data.companySnashot.companyInfo.name,
                //   align: 'left'
                // },

                // labels: earningsDividendLabel,
                stroke: {
                  width: [2, 2, 2],
                },
                // tooltip: {
                //   shared: true,
                //   custom: [function({seriesIndex, dataPointIndex, w}) {
                //     return w.globals.series[seriesIndex][dataPointIndex]
                //   }, function({ seriesIndex, dataPointIndex, w }) {
                //     var o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
                //     var h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
                //     var l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
                //     var c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
                //     return (
                //       ''
                //     )
                //   }]
                // },
                // xaxis: {
                //   type:  'datetime',
                // },
                colors: ["#000", "#27AE60", "#F15B46"],
                xaxis: {
                  type: "datetime",
                  labels: {
                    format: "yyyy",
                  },
                },
                // xaxis: {
                //   labels: {
                //     formatter: function (value, timestamp) {
                //       return value // The formatter function overrides format property
                //     },
                //   }
                // },
                yaxis: [
                  {
                    axisTicks: {
                      show: true,
                    },
                    forceNiceScale: false,
                    // min:minRangeValue,
                    min: 0,
                    max: maxRangeValue,
                    axisBorder: {
                      show: true,
                      color: "#000",
                    },
                    labels: {
                      style: {
                        colors: "#000",
                      },
                    },
                    title: {
                      text: "Price",
                      style: {
                        color: "#000",
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  {
                    seriesName: "Earning ",
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#27AE60",
                    },
                    labels: {
                      style: {
                        colors: "#27AE60",
                      },
                    },
                    title: {
                      text: "Earning ",
                      style: {
                        color: "#27AE60",
                      },
                    },
                  },
                  {
                    seriesName: " Dividends",
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#F15B46",
                    },
                    labels: {
                      style: {
                        colors: "#F15B46",
                      },
                    },
                    title: {
                      text: " Dividends",
                      style: {
                        color: "#F15B46",
                      },
                    },
                  },
                ],
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  getCountryWithFlag(company) {
    let getCountryWithFlag = "";

    getCountryWithFlag = <span>{Utils.getCountryFlag(company.country)}</span>;

    return getCountryWithFlag;
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      let financialPositionComparisonData =
        this.state.financialPositionComparisonData.map(
          (financialPositionComparison, i) => {
            return (
              <tr
                style={
                  i % 2 ? { background: "#cae1ff" } : { background: "white" }
                }
                key={i}
              >
                <td
                  style={
                    i % 4
                      ? { borderBottomWidth: " 1px", textAlign: "left" }
                      : {
                          borderTop: "1px solid",
                          borderBottomWidth: " 1px",
                          textAlign: "left",
                        }
                  }
                  className="quarterYear"
                >
                  {financialPositionComparison.name}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {financialPositionComparison.year}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {financialPositionComparison.ltDebtEqu}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {financialPositionComparison.daysAr}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {financialPositionComparison.daysInv}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {financialPositionComparison.rndSale}
                </td>
              </tr>
            );
          }
        );
      let valuationData = this.state.companyValuationData.map(
        (companyValuation, i) => {
          return (
            <tr
              style={
                i % 2 ? { background: "#cae1ff" } : { background: "white" }
              }
              key={i}
            >
              <td
                style={
                  i % 4
                    ? { borderBottomWidth: " 1px", textAlign: "left" }
                    : {
                        borderTop: "1px solid",
                        borderBottomWidth: " 1px",
                        textAlign: "left",
                      }
                }
                className="quarterYear"
              >
                {companyValuation.company}
              </td>
              <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                {companyValuation.pe}
              </td>
              <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                {companyValuation.priceBook !== null
                  ? companyValuation.priceBook.toFixed(2)
                  : ""}
              </td>
              <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                {companyValuation.priceSales}
              </td>
              <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                {companyValuation.perChange52Week !== null
                  ? companyValuation.perChange52Week.toFixed(2)
                  : ""}
              </td>
            </tr>
          );
        }
      );
      let profitabilityAnalysisData =
        this.state.profitabilityComparisonData.map(
          (profitabilityAnalysis, i) => {
            return (
              <tr
                style={
                  i % 2 ? { background: "#cae1ff" } : { background: "white" }
                }
                key={i}
              >
                <td
                  style={
                    i % 4
                      ? { borderBottomWidth: " 1px", textAlign: "left" }
                      : {
                          borderTop: "1px solid",
                          borderBottomWidth: " 1px",
                          textAlign: "left",
                        }
                  }
                  className="quarterYear"
                >
                  {profitabilityAnalysis.name}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {profitabilityAnalysis.year}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {profitabilityAnalysis.grossProfitMargin}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {profitabilityAnalysis.ebitaMargin}
                </td>
                <td style={{ borderRight: "1px solid", textAlign: "right" }}>
                  {profitabilityAnalysis.ernBefrExtra}
                </td>
              </tr>
            );
          }
        );

      let itemRow = this.state.salesComparisonData.map((salesComparison, i) => {
        return (
          <tr
            key={i}
            style={i % 2 ? { background: "#cae1ff" } : { background: "white" }}
          >
            <td
              style={
                i % 4
                  ? { borderBottomWidth: " 1px", textAlign: "left" }
                  : {
                      borderTop: "1px solid",
                      borderBottomWidth: " 1px",
                      textAlign: "left",
                    }
              }
              className="quarterYear"
            >
              {salesComparison.name}
            </td>
            <td style={{ borderRight: "1px solid", textAlign: "right" }}>
              {salesComparison.financialEnd}
            </td>
            <td style={{ borderRight: "1px solid", textAlign: "right" }}>
              {salesComparison.sales}
            </td>
            <td style={{ borderRight: "1px solid", textAlign: "right" }}>
              {salesComparison.salesGrowth}
            </td>
            <td style={{ borderRight: "1px solid", textAlign: "right" }}>
              {this.valueFormat(salesComparison.salesPerEmployee.toFixed())}
            </td>
            <td style={{ borderRight: "1px solid", textAlign: "right" }}>
              {salesComparison.largestRegion}
            </td>
          </tr>
        );
      });

      let tickerSearchList = [];
      this.state.tickerList.forEach((ticker, index) => {
        tickerSearchList.push(
          <Row style={{ alignItems: "center", padding: "10px" }} key={index}>
            <Col
              class="buy"
              style={{ fontSize: "small", alignItems: "center" }}
            >
              <span style={{ color: "blue", cursor: "pointer" }}>
                {ticker.companyName}
              </span>{" "}
              &nbsp; (
              <span
                style={{
                  fontWeight: "bold",
                  color: "brown",
                  cursor: "pointer",
                }}
              >
                {ticker.ticker}
              </span>
              )
            </Col>
            <Col
              style={{
                fontSize: "small",
                alignItems: "center",
                display: "flex",
              }}
            >
              &nbsp;&nbsp;
              <span>
                <i>
                  {this.getCountryWithFlag(ticker)}
                  {" " + ticker.region},{ticker.country}
                </i>
              </span>
              <Button
                style={{
                  background: "#f58d51",

                  float: "right",
                  webkitBorderRadius: "10px",
                  fontSize: "0.8em",

                  margin: "auto",
                  marginRight: "5px",
                  borderBottom: "2px solid #636262",
                }}
                onClick={this.getSelectedCompetitor.bind(this, ticker)}
              >
                Add Competitor
              </Button>
            </Col>
          </Row>
        );
      });

      let companySearchList = [];
      this.state.companyList.forEach((Company, index) => {
        companySearchList.push(
          <Row style={{ alignItems: "center", padding: "10px" }}>
            {" "}
            <Col
              class="buy"
              style={{ fontSize: "small", alignItems: "center" }}
            >
              <span style={{ color: "blue", cursor: "pointer" }}>
                {Company.companyName}
              </span>{" "}
              &nbsp; (
              <span
                style={{
                  fontWeight: "bold",
                  color: "brown",
                  cursor: "pointer",
                }}
              >
                {Company.ticker}
              </span>
              ) )
            </Col>
            <Col
              style={{
                fontSize: "small",
                alignItems: "center",
                display: "flex",
              }}
            >
              &nbsp;&nbsp;
              <span>
                <i>
                  {this.getCountryWithFlag(Company)}
                  {" " + Company.region},{Company.country}
                </i>
              </span>
              <Button
                style={{
                  background: "#f58d51",
                  fontSize: "0.8em",

                  webkitBorderRadius: "10px",

                  float: "right",
                  margin: "auto",
                  marginRight: "5px",
                  borderBottom: "2px solid #636262",
                }}
                onClick={this.getSelectedCompetitor.bind(this, Company)}
              >
                Add Competitor
              </Button>
            </Col>
          </Row>
        );
      });

      let keywordSearchList = [];
      this.state.keywordList.forEach((Keyword, index) => {
        keywordSearchList.push(
          <Row style={{ alignItems: "center", padding: "10px" }} key={index}>
            <Col
              class="buy"
              style={{ fontSize: "small", alignItems: "center" }}
            >
              <span style={{ color: "blue", cursor: "pointer" }}>
                {Keyword.companyName}
              </span>{" "}
              &nbsp; (
              <span
                style={{
                  fontWeight: "bold",
                  color: "brown",
                  cursor: "pointer",
                }}
              >
                {Keyword.ticker}
              </span>
              ) )
            </Col>
            <Col
              style={{
                fontSize: "small",
                alignItems: "center",
                display: "flex",
              }}
            >
              &nbsp;&nbsp;
              <span>
                <i>
                  {this.getCountryWithFlag(Keyword)}
                  {" " + Keyword.region},{Keyword.country}
                </i>
              </span>
              <Button
                style={{
                  background: "#f58d51",
                  fontSize: "0.8em",
                  float: "right",
                  webkitBorderRadius: "10px",
                  fontSize: "0.8em",
                  float: "right",
                  margin: "auto",
                  marginRight: "5px",
                  borderBottom: "2px solid #636262",
                }}
                onClick={this.getSelectedCompetitor.bind(this, Keyword)}
              >
                Add Competitor
              </Button>
            </Col>
          </Row>
        );
      });

      if (this.state.isPdfReport === false) {
        return (
          <>
            {this.state.isReportAvailable && (
              <>
                <Row style={{ padding: "20px", marginTop: "-1.5em" }}>
                  <Col md={12}>
                    <h5
                      className="cba_section_header"
                      style={{ marginTop: "1em" }}
                    >
                      <b>Company Description</b>
                    </h5>
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.companyDescription}
                    </p>
                  </Col>

                  <Col md={12}>
                    <h5 className="cba_section_header">
                      <b>Competitor Analysis</b>
                    </h5>
                    <p style={{ fontSize: "0.9em" }}>
                      <>
                        <span>{this.state.urlArr[0]}</span>
                        <a
                          //href="#"
                          onClick={(e) =>
                            this.toCompanyDetails(this.state.competitorArr1[1])
                          }
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr1[0]}
                        </a>
                        <span>{this.state.urlArr[2]}</span>
                        <a
                          onClick={(e) =>
                            this.toCompanyDetails(this.state.competitorArr2[1])
                          }
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr2[0]}
                        </a>
                        <span>{this.state.urlArr[4]}</span>
                        <a
                          onClick={(comp) =>
                            this.toCompanyDetails(this.state.competitorArr3[1])
                          }
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr3[0]}
                        </a>
                        <span>{this.state.urlArr[6]}</span>
                      </>
                    </p>
                  </Col>
                  <Col md={12}>
                    <h5 className="cba_section_header">
                      <b>Sales Analysis</b>
                    </h5>
                    {this.state.salesAnalysisData.p0 === "" ||
                    this.state.salesAnalysisData.p0 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p0}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p2 === "" ||
                    this.state.salesAnalysisData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p2}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p1 === "" ||
                    this.state.salesAnalysisData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p1}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p3 === "" ||
                    this.state.salesAnalysisData.p3 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p3}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p4 === "" ||
                    this.state.salesAnalysisData.p4 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p4}
                      </p>
                    )}
                  </Col>
                  <div className="">
                    <div className="">
                      <Row>
                        {" "}
                        <span class="unit_str" style={{ marginLeft: "1em" }}>
                          All figures in <span>{this.state.unit}</span>
                        </span>
                        <Col md={12}>
                          <ReactApexCharts
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            height={350}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Col md={12} style={{ marginTop: "0em" }}>
                    {this.state.salesAnalysisData.p5 === "" ||
                    this.state.salesAnalysisData.p5 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p5}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p6 === "" ||
                    this.state.salesAnalysisData.p6 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p6}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p7 === "" ||
                    this.state.salesAnalysisData.p7 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p7}
                      </p>
                    )}
                  </Col>

                  <Col md={12}>
                    {this.state.salesComparisonData.length > 0 && (
                      <>
                        <h5 className="cba_section_Subheader">
                          {this.state.comparisonData === "" ||
                          this.state.comparisonData === undefined ||
                          this.state.comparisonData === null ? (
                            <></>
                          ) : (
                            <b style={{ fontSize: "0.9em" }}>
                              {this.state.comparisonData.title}
                            </b>
                          )}
                        </h5>
                        <span class="unit_str">
                          All figures in <span>{this.state.unit}</span>
                        </span>
                        <Table
                          responsive
                          className="cba_report_table"
                          style={{
                            border: "1px solid",
                            margin: "0.5em 0em 0em 0em",
                          }}
                        >
                          <thead
                            style={{ fontSize: "0.8em", background: "#ebe5db" }}
                          >
                            <tr>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                  textAlign: "left",
                                }}
                              >
                                Company
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Year Ended
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales Growth
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales/ Emp
                              </th>
                              <th class="price_analysis">Largest Region</th>
                            </tr>
                          </thead>
                          <tbody>{itemRow}</tbody>
                        </Table>
                      </>
                    )}
                  </Col>
                  <div className="ptsPage">
                    <Row style={{ marginTop: "0.5em" }}>
                      <h5 className="cba_section_header">
                        <b>Recent Stock Performance</b>
                      </h5>
                      <span class="unit_str" style={{ marginLeft: "1em" }}>
                        All figures in <span>{this.state.unit}</span>
                      </span>
                      <Col md={12} style={{ marginTop: "1em" }}>
                        <div>
                          <ReactApexCharts
                            options={this.state.earnAndDndOptions}
                            series={this.state.earnAndDndSeries}
                            type="line"
                            height={300}
                          />

                          {/* <ReactApexCharts options={this.state.options1} series={this.state.series1}  height={350} /> */}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row className="">
                    <h5 className="cba_section_header">
                      <b>Recent Stock Performance</b>
                    </h5>
                    <span class="unit_str" style={{ marginLeft: "1em" }}>
                      All figures in <span>{this.state.unit}</span>
                    </span>
                    <Col md={12} style={{ marginTop: "-1em" }}>
                      <ReactApexCharts
                        options={this.state.priceOptions}
                        series={this.state.priceSeries}
                        type="line"
                        height={300}
                      />
                    </Col>
                  </Row>

                  <div
                    className="cba_table_data_space ptsPage"
                    style={{ marginTop: "5em" }}
                  >
                    {this.state.salesAnalysisData.p8 === "" ||
                    this.state.salesAnalysisData.p8 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p8}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p9 === "" ||
                    this.state.salesAnalysisData.p9 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p9}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p10 === "" ||
                    this.state.salesAnalysisData.p10 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p10}
                      </p>
                    )}
                  </div>
                  <Col md={12}>
                    {this.state.companyValuationData.length > 0 && (
                      <>
                        <h5 className="cba_section_Subheader">
                          {this.state.valuationData === "" ||
                          this.state.valuationData === undefined ||
                          this.state.valuationData === null ? (
                            <></>
                          ) : (
                            <b style={{ fontSize: "0.9em" }}>
                              {this.state.valuationData.title}
                            </b>
                          )}
                        </h5>
                        <Table
                          responsive
                          className="cba_report_table"
                          style={{
                            border: "1px solid",
                            margin: "0.5em 0em 0em 0em",
                          }}
                        >
                          <thead
                            style={{ fontSize: "0.8em", background: "#ebe5db" }}
                          >
                            <tr>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                  textAlign: "left",
                                }}
                              >
                                Company
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                P/E
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Price/Book
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Price/Sales
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                per Change 52Week
                              </th>
                            </tr>
                          </thead>
                          <tbody>{valuationData}</tbody>
                        </Table>
                      </>
                    )}
                  </Col>
                  <div className="cba_table_data_space">
                    {this.state.salesAnalysisData.p11 === "" ||
                    this.state.salesAnalysisData.p11 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p11}
                      </p>
                    )}
                  </div>
                </Row>

                {this.state.dividendAnalysisData.p1 === null &&
                this.state.dividendAnalysisData.p2 === null ? (
                  <></>
                ) : (
                  <Col
                    md={12}
                    style={{ padding: "20px", margin: "-2.5em 0em" }}
                  >
                    <h5 className="cba_section_header">
                      <b>Dividend Analysis</b>
                    </h5>
                    {this.state.dividendAnalysisData.p1 === "" ||
                    this.state.dividendAnalysisData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.dividendAnalysisData.p1}
                      </p>
                    )}
                    {this.state.dividendAnalysisData.p2 === "" ||
                    this.state.dividendAnalysisData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.dividendAnalysisData.p2}
                      </p>
                    )}
                  </Col>
                )}

                <Col md={12} style={{ padding: "20px", margin: "-2.5em 0em" }}>
                  <h5 className="cba_section_header">
                    <b>Profitability Analysis</b>
                  </h5>
                  {this.state.profitabilityAnalysisData.p1 === "" ||
                  this.state.profitabilityAnalysisData.p1 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p1}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p2 === "" ||
                  this.state.profitabilityAnalysisData.p2 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p2}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p3 === "" ||
                  this.state.profitabilityAnalysisData.p3 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p3}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p4 === "" ||
                  this.state.profitabilityAnalysisData.p4 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p4}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p5 === "" ||
                  this.state.profitabilityAnalysisData.p5 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p5}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p6 === "" ||
                  this.state.profitabilityAnalysisData.p6 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p6}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p7 === "" ||
                  this.state.profitabilityAnalysisData.p7 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p7}
                    </p>
                  )}

                  {this.state.profitabilityComparisonData.length > 0 && (
                    <div style={{ marginTop: "3em" }}>
                      <br></br>
                      <h5 className="cba_section_Subheader">
                        {this.state.profitabilityComparisonDataObj === "" ||
                        this.state.profitabilityComparisonDataObj ===
                          undefined ||
                        this.state.profitabilityComparisonDataObj === null ? (
                          <></>
                        ) : (
                          <b style={{ fontSize: "0.9em" }}>
                            {this.state.profitabilityComparisonDataObj.title}
                          </b>
                        )}
                        {/* <b>{this.state.profitabilityComparisonDataObj.title}</b> */}
                      </h5>
                      <Table
                        responsive
                        className="cba_report_table"
                        style={{
                          border: "1px solid",
                          margin: "0.5em 0em 0em 0em",
                        }}
                      >
                        <thead
                          style={{ fontSize: "0.8em", background: "#ebe5db" }}
                        >
                          <tr>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                                textAlign: "left",
                              }}
                            >
                              Company
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Year
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Gross Profit Margin
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              EBITDA Margin
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Earns bef. extra
                            </th>
                          </tr>
                        </thead>
                        <tbody>{profitabilityAnalysisData}</tbody>
                      </Table>
                    </div>
                  )}
                  <div className="cba_table_data_space">
                    {this.state.profitabilityAnalysisData.p8 === "" ||
                    this.state.profitabilityAnalysisData.p8 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p8}
                      </p>
                    )}
                    {this.state.profitabilityAnalysisData.p9 === "" ||
                    this.state.profitabilityAnalysisData.p9 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p9}
                      </p>
                    )}

                    {this.state.profitabilityAnalysisData.p10 === "" ||
                    this.state.profitabilityAnalysisData.p10 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p10}
                      </p>
                    )}
                  </div>
                </Col>

                <Col md={12} style={{ padding: "20px" }}>
                  <h5 className="cba_section_header">
                    <b>Inventory Analysis</b>
                  </h5>
                  {this.state.inventoryAnalysisData.p1 === "" ||
                  this.state.inventoryAnalysisData.p1 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.inventoryAnalysisData.p1}
                    </p>
                  )}
                  {this.state.inventoryAnalysisData.p2 === "" ||
                  this.state.inventoryAnalysisData.p2 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.inventoryAnalysisData.p2}
                    </p>
                  )}
                </Col>

                {this.state.researchAndDevelopmentData.p1 === null &&
                this.state.researchAndDevelopmentData.p2 === null ? (
                  <></>
                ) : (
                  <Col
                    md={12}
                    style={{ padding: "20px", margin: "-2.5em 0em" }}
                  >
                    <h5 className="cba_section_header">
                      <b>Research and Development</b>
                    </h5>
                    {this.state.researchAndDevelopmentData.p1 === "" ||
                    this.state.researchAndDevelopmentData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.researchAndDevelopmentData.p1}
                      </p>
                    )}
                    {this.state.researchAndDevelopmentData.p2 === "" ||
                    this.state.researchAndDevelopmentData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.researchAndDevelopmentData.p2}
                      </p>
                    )}
                  </Col>
                )}

                <Col md={12} style={{ padding: "20px", margin: "-2.5em 0em" }}>
                  {this.state.financialPositionComparisonData.length > 0 && (
                    <>
                      <h5 className="cba_section_header">
                        <b>Financial Position</b>
                      </h5>
                      {this.state.financialPosition.p1 === "" ||
                      this.state.financialPosition.p1 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p1}
                        </p>
                      )}
                      {this.state.financialPosition.p2 === "" ||
                      this.state.financialPosition.p2 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p2}
                        </p>
                      )}
                      {this.state.financialPosition.p3 === "" ||
                      this.state.financialPosition.p3 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p3}
                        </p>
                      )}
                      {this.state.financialPosition.p4 === "" ||
                      this.state.financialPosition.p4 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p4}
                        </p>
                      )}
                      {this.state.financialPosition.p5 === "" ||
                      this.state.financialPosition.p5 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p5}
                        </p>
                      )}

                      {this.state.financialPosition.p6 === "" ||
                      this.state.financialPosition.p6 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p6}
                        </p>
                      )}
                    </>
                  )}
                  {this.state.financialPositionComparisonData.length > 0 && (
                    <>
                      <h5 className="cba_section_Subheader">
                        <b>Financial Positions</b>
                      </h5>

                      <Table
                        responsive
                        className="cba_report_table"
                        style={{
                          border: "1px solid",
                          margin: "0.5em 0em 0em 0em",
                        }}
                      >
                        <thead
                          style={{ fontSize: "0.8em", background: "#ebe5db" }}
                        >
                          <tr>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                                textAlign: "left",
                              }}
                            >
                              Company
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Year
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              LT Debt/ Equity
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Days AR
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Days Inv.
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              R&D/ Sales
                            </th>
                          </tr>
                        </thead>
                        <tbody>{financialPositionComparisonData}</tbody>
                      </Table>
                    </>
                  )}
                </Col>
              </>
            )}

            {this.state.isReportNotAvailable && (
              <h4 className="cba_data_error_msg_pdf">
                {this.state.errorMessage}
              </h4>
            )}
          </>
        );
      } else {
        return (
          <>
            {this.state.isReportAvailable && (
              <>
                <Row style={{ padding: "20px", marginTop: "-1.5em" }}>
                  <Col md={12}>
                    <h5
                      className="cba_section_header"
                      style={{ marginTop: "1em" }}
                    >
                      <b>Company Description</b>
                    </h5>
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.companyDescription}
                    </p>
                  </Col>

                  <Col md={12}>
                    <h5 className="cba_section_header">
                      <b>Competitor Analysis</b>
                    </h5>
                    <p style={{ fontSize: "0.9em" }}>
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.compititorAnalysis,
                        }}
                      /> */}
                      <>
                        <span>{this.state.urlArr[0]}</span>
                        <a
                          //href="#"
                          onClick={(e) =>
                            this.toCompanyDetails(this.state.competitorArr1[1])
                          }
                          // onClick={this.toCompanyDetails.bind(
                          //   this,
                          //   this.state.competitorArr1[1]
                          // )}
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr1[0]}
                        </a>
                        <span>{this.state.urlArr[2]}</span>
                        <a
                          //href="#"
                          onClick={(e) =>
                            this.toCompanyDetails(this.state.competitorArr2[1])
                          }
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr2[0]}
                        </a>
                        <span>{this.state.urlArr[4]}</span>
                        <a
                          onClick={(comp) =>
                            this.toCompanyDetails(this.state.competitorArr3[1])
                          }
                          //href="#"
                          target="_blank"
                          style={{
                            color: "rgb(242, 109, 33)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                        >
                          {this.state.competitorArr3[0]}
                        </a>
                        <span>{this.state.urlArr[6]}</span>
                      </>
                    </p>
                  </Col>
                  <Col md={12}>
                    <h5 className="cba_section_header">
                      <b>Sales Analysis</b>
                    </h5>
                    {this.state.salesAnalysisData.p0 === "" ||
                    this.state.salesAnalysisData.p0 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p0}
                      </p>
                    )}

                    {this.state.salesAnalysisData.p1 === "" ||
                    this.state.salesAnalysisData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p1}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p2 === "" ||
                    this.state.salesAnalysisData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p2}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p3 === "" ||
                    this.state.salesAnalysisData.p3 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p3}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p4 === "" ||
                    this.state.salesAnalysisData.p4 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p4}
                      </p>
                    )}
                  </Col>
                  <div className="cba_table_data_space">
                    <Row>
                      <span class="unit_str" style={{ marginLeft: "1em" }}>
                        All figures in <span>{this.state.unit}</span>
                      </span>
                      <Col
                        md={6}
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      >
                        <ReactApexCharts
                          options={this.state.options}
                          series={this.state.series}
                          type="bar"
                          height={350}
                        />
                      </Col>
                    </Row>
                  </div>

                  <Col md={12}>
                    {this.state.salesAnalysisData.p5 === "" ||
                    this.state.salesAnalysisData.p5 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p5}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p6 === "" ||
                    this.state.salesAnalysisData.p6 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p6}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p7 === "" ||
                    this.state.salesAnalysisData.p7 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p7}
                      </p>
                    )}
                  </Col>

                  <Col md={12}>
                    {this.state.salesComparisonData.length > 0 && (
                      <>
                        <h5 className="cba_section_Subheader">
                          {this.state.comparisonData === "" ||
                          this.state.comparisonData === undefined ||
                          this.state.comparisonData === null ? (
                            <></>
                          ) : (
                            <b style={{ fontSize: "0.9em" }}>
                              {this.state.comparisonData.title}
                            </b>
                          )}
                        </h5>
                        <span class="unit_str">
                          All figures in <span>{this.state.unit}</span>
                        </span>
                        <Table
                          responsive
                          className="cba_report_table"
                          style={{
                            border: "1px solid",
                            margin: "0.5em 0em 0em 0em",
                          }}
                        >
                          <thead
                            style={{ fontSize: "0.8em", background: "#ebe5db" }}
                          >
                            <tr>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                  textAlign: "left",
                                }}
                              >
                                Company
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Year Ended
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales
                                <div style={{ fontSize: "0.8em" }}>
                                  ({"US$ " + this.state.salesAnalysisUnit})
                                </div>
                                {/* ({this.toTitleCase(this.state.comparisonData.unit)}) */}
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales Growth
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Sales/ Emp
                                <div style={{ fontSize: "0.8em" }}>(US$)</div>
                              </th>
                              <th class="price_analysis">Largest Region</th>
                            </tr>
                          </thead>
                          <tbody>{itemRow}</tbody>
                        </Table>
                      </>
                    )}
                  </Col>
                  <div className="cba_table_data_space">
                    <Row>
                      <h5 className="cba_section_header">
                        <b>Recent Stock Performance</b>
                      </h5>
                      <span class="unit_str" style={{ marginLeft: "1em" }}>
                        All figures in <span>{this.state.unit}</span>
                      </span>
                      <Col md={6} style={{ marginTop: "1em" }}>
                        <div>
                          <ReactApexCharts
                            options={this.state.earnAndDndOptions}
                            series={this.state.earnAndDndSeries}
                            type="line"
                            height={350}
                          />
                        </div>
                      </Col>
                      <Col md={6} style={{ marginTop: "1em" }}>
                        <ReactApexCharts
                          options={this.state.priceOptions}
                          series={this.state.priceSeries}
                          type="line"
                          height={350}
                        />
                      </Col>
                      {/* <Col md={2}></Col>
                      <Col md={8}>
                      <ReactApexCharts options={this.state.options1} series={this.state.series1}  height={350} />
                      </Col>
                      <Col md={2}></Col> */}
                    </Row>
                  </div>

                  <div className="cba_table_data_space">
                    {this.state.salesAnalysisData.p8 === "" ||
                    this.state.salesAnalysisData.p8 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p8}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p9 === "" ||
                    this.state.salesAnalysisData.p9 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p9}
                      </p>
                    )}
                    {this.state.salesAnalysisData.p10 === "" ||
                    this.state.salesAnalysisData.p10 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p10}
                      </p>
                    )}
                  </div>
                  <Col md={12}>
                    {this.state.companyValuationData.length > 0 && (
                      <>
                        <h5 className="cba_section_Subheader">
                          {this.state.valuationData === "" ||
                          this.state.valuationData === undefined ||
                          this.state.valuationData === null ? (
                            <></>
                          ) : (
                            <b style={{ fontSize: "0.9em" }}>
                              {this.state.valuationData.title}
                            </b>
                          )}
                        </h5>
                        <Table
                          className="cba_report_table"
                          responsive
                          style={{
                            border: "1px solid",
                            margin: "0.5em 0em 0em 0em",
                          }}
                        >
                          <thead
                            style={{ fontSize: "0.8em", background: "#ebe5db" }}
                          >
                            <tr>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                  textAlign: "left",
                                }}
                              >
                                Company
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                P/E
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Price/Book
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                Price/Sales
                              </th>
                              <th
                                class="price_analysis"
                                style={{
                                  borderRight: "1px solid",
                                }}
                              >
                                per Change 52Week
                              </th>
                            </tr>
                          </thead>
                          <tbody>{valuationData}</tbody>
                        </Table>
                      </>
                    )}
                  </Col>
                  <div className="cba_table_data_space">
                    {this.state.salesAnalysisData.p11 === "" ||
                    this.state.salesAnalysisData.p11 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.salesAnalysisData.p11}
                      </p>
                    )}
                  </div>
                </Row>

                {this.state.dividendAnalysisData.p1 === null &&
                this.state.dividendAnalysisData.p2 === null ? (
                  <></>
                ) : (
                  <Col md={12} style={{ padding: "20px" }}>
                    <h5 className="cba_section_header">
                      <b>Dividend Analysis</b>
                    </h5>
                    {this.state.dividendAnalysisData.p1 === "" ||
                    this.state.dividendAnalysisData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.dividendAnalysisData.p1}
                      </p>
                    )}
                    {this.state.dividendAnalysisData.p2 === "" ||
                    this.state.dividendAnalysisData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.dividendAnalysisData.p2}
                      </p>
                    )}
                  </Col>
                )}

                <Col md={12} style={{ padding: "20px" }}>
                  <h5 className="cba_section_header">
                    <b>Profitability Analysis</b>
                  </h5>
                  {this.state.profitabilityAnalysisData.p1 === "" ||
                  this.state.profitabilityAnalysisData.p1 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p1}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p2 === "" ||
                  this.state.profitabilityAnalysisData.p2 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p2}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p3 === "" ||
                  this.state.profitabilityAnalysisData.p3 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p3}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p4 === "" ||
                  this.state.profitabilityAnalysisData.p4 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p4}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p5 === "" ||
                  this.state.profitabilityAnalysisData.p5 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p5}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p6 === "" ||
                  this.state.profitabilityAnalysisData.p6 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p6}
                    </p>
                  )}
                  {this.state.profitabilityAnalysisData.p7 === "" ||
                  this.state.profitabilityAnalysisData.p7 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.profitabilityAnalysisData.p7}
                    </p>
                  )}

                  {this.state.profitabilityComparisonData.length > 0 && (
                    <>
                      <h5 className="cba_section_Subheader">
                        {this.state.profitabilityComparisonDataObj === "" ||
                        this.state.profitabilityComparisonDataObj ===
                          undefined ||
                        this.state.profitabilityComparisonDataObj === null ? (
                          <></>
                        ) : (
                          <b style={{ fontSize: "0.9em" }}>
                            {this.state.profitabilityComparisonDataObj.title}
                          </b>
                        )}
                        {/* <b>{this.state.profitabilityComparisonDataObj.title}</b> */}
                      </h5>
                      <Table
                        className="cba_report_table"
                        responsive
                        style={{
                          border: "1px solid",
                          margin: "0.5em 0em 0em 0em",
                        }}
                      >
                        <thead
                          style={{ fontSize: "0.8em", background: "#ebe5db" }}
                        >
                          <tr>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                                textAlign: "left",
                              }}
                            >
                              Company
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Year
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Gross Profit Margin
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              EBITDA Margin
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Earns bef. extra
                            </th>
                          </tr>
                        </thead>
                        <tbody>{profitabilityAnalysisData}</tbody>
                      </Table>
                    </>
                  )}
                  <div className="cba_table_data_space">
                    {this.state.profitabilityAnalysisData.p8 === "" ||
                    this.state.profitabilityAnalysisData.p8 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p8}
                      </p>
                    )}
                    {this.state.profitabilityAnalysisData.p9 === "" ||
                    this.state.profitabilityAnalysisData.p9 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p9}
                      </p>
                    )}

                    {this.state.profitabilityAnalysisData.p10 === "" ||
                    this.state.profitabilityAnalysisData.p10 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.profitabilityAnalysisData.p10}
                      </p>
                    )}
                  </div>
                </Col>

                <Col md={12} style={{ padding: "20px" }}>
                  <h5 className="cba_section_header">
                    <b>Inventory Analysis</b>
                  </h5>
                  {this.state.inventoryAnalysisData.p1 === "" ||
                  this.state.inventoryAnalysisData.p1 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.inventoryAnalysisData.p1}
                    </p>
                  )}
                  {this.state.inventoryAnalysisData.p2 === "" ||
                  this.state.inventoryAnalysisData.p2 === null ? (
                    <></>
                  ) : (
                    <p style={{ fontSize: "0.9em" }}>
                      {this.state.inventoryAnalysisData.p2}
                    </p>
                  )}
                </Col>

                {this.state.researchAndDevelopmentData.p1 === null &&
                this.state.researchAndDevelopmentData.p2 === null ? (
                  <></>
                ) : (
                  <Col md={12} style={{ padding: "20px" }}>
                    <h5 className="cba_section_header">
                      <b>Research and Development</b>
                    </h5>
                    {this.state.researchAndDevelopmentData.p1 === "" ||
                    this.state.researchAndDevelopmentData.p1 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.researchAndDevelopmentData.p1}
                      </p>
                    )}
                    {this.state.researchAndDevelopmentData.p2 === "" ||
                    this.state.researchAndDevelopmentData.p2 === null ? (
                      <></>
                    ) : (
                      <p style={{ fontSize: "0.9em" }}>
                        {this.state.researchAndDevelopmentData.p2}
                      </p>
                    )}
                  </Col>
                )}

                <Col md={12} style={{ padding: "20px" }}>
                  {this.state.financialPositionComparisonData.length > 0 && (
                    <>
                      <h5 className="cba_section_header">
                        <b>Financial Position</b>
                      </h5>
                      {this.state.financialPosition.p1 === "" ||
                      this.state.financialPosition.p1 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p1}
                        </p>
                      )}
                      {this.state.financialPosition.p2 === "" ||
                      this.state.financialPosition.p2 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p2}
                        </p>
                      )}
                      {this.state.financialPosition.p3 === "" ||
                      this.state.financialPosition.p3 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p3}
                        </p>
                      )}
                      {this.state.financialPosition.p4 === "" ||
                      this.state.financialPosition.p4 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p4}
                        </p>
                      )}
                      {this.state.financialPosition.p5 === "" ||
                      this.state.financialPosition.p5 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p5}
                        </p>
                      )}

                      {this.state.financialPosition.p6 === "" ||
                      this.state.financialPosition.p6 === null ? (
                        <></>
                      ) : (
                        <p style={{ fontSize: "0.9em" }}>
                          {this.state.financialPosition.p6}
                        </p>
                      )}
                    </>
                  )}
                  {this.state.financialPositionComparisonData.length > 0 && (
                    <>
                      <h5 className="cba_section_Subheader">
                        <b>Financial Positions</b>
                      </h5>

                      <Table
                        className="cba_report_table"
                        responsive
                        style={{
                          border: "1px solid",
                          margin: "0.5em 0em 0em 0em",
                        }}
                      >
                        <thead
                          style={{ fontSize: "0.8em", background: "#ebe5db" }}
                        >
                          <tr>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                                textAlign: "left",
                              }}
                            >
                              Company
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Year
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              LT Debt/ Equity
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Days AR
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              Days Inv.
                            </th>
                            <th
                              class="price_analysis"
                              style={{
                                borderRight: "1px solid",
                              }}
                            >
                              R&D/ Sales
                            </th>
                          </tr>
                        </thead>
                        <tbody>{financialPositionComparisonData}</tbody>
                      </Table>
                    </>
                  )}
                </Col>
                <br></br>
                {this.state.isPdfReport && (
                  <Row
                    style={{
                      padding: "30px 0px 25px 0px",
                      background: "#fff",
                      margin: "3px",
                      marginBottom: "2em",
                    }}
                  >
                    <h5 className="cba_section_header">
                      <b>User Selected Comparisons</b>
                    </h5>
                    <p style={{ fontSize: "0.9em", marginBottom: "2em" }}>
                      If you would rather compare this company to different
                      companies than the ones above, simply enter their names or
                      ticker symbols below, and this report will be re-written
                      with these new companies. You may enter the ticker symbol
                      or the first few letters of a word in the company's name
                      (you must enter at least four characters for a name, and
                      at least one character for a ticker). You must enter in
                      three companies; if you don't, there can be no competitive
                      analysis and the computer will choose.
                    </p>
                    <Col md={4} className="Competitor_column">
                      <TextField
                        name="Competitor1"
                        variant="outlined"
                        required
                        value={this.state.competitor1}
                        onChange={this.handleCompetitor1Change.bind(this)}
                        fullWidth
                        id="competitor"
                        label="Competitor1"
                        autoComplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              title="Change Competitor 1"
                            >
                              <Button
                                style={{
                                  background: "#f58d51",

                                  webkitBorderRadius: "10px",
                                  fontSize: "0.8em",
                                  float: "right",
                                  margin: "-18px auto auto",
                                  marginRight: "5px",
                                  borderBottom: "2px solid #636262",
                                }}
                                onClick={(e) =>
                                  this.addCompetitor1("Competitor 1")
                                }
                              >
                                Change Competitor1
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>
                    <Col md={4} className="Competitor_column">
                      <TextField
                        name="Competitor2"
                        variant="outlined"
                        required
                        value={this.state.competitor2}
                        onChange={this.handleCompetitor2Change.bind(this)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              title="Change Competitor 2"
                            >
                              <Button
                                style={{
                                  background: "#f58d51",

                                  webkitBorderRadius: "10px",
                                  fontSize: "0.8em",
                                  float: "right",
                                  margin: "-18px auto auto",
                                  marginRight: "5px",
                                  borderBottom: "2px solid #636262",
                                }}
                                onClick={(e) =>
                                  this.addCompetitor2("Competitor 2")
                                }
                              >
                                Change Competitor2
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        id="competitor2"
                        label="Competitor2"
                        autoComplete="off"
                      />
                    </Col>
                    <Col md={4} className="Competitor_column">
                      <TextField
                        name="Competitor3"
                        variant="outlined"
                        required
                        value={this.state.competitor3}
                        onChange={this.handleCompetitor3Change.bind(this)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              title="Change Competitor 3"
                            >
                              <Button
                                style={{
                                  background: "#f58d51",

                                  float: "right",
                                  webkitBorderRadius: "10px",
                                  fontSize: "0.8em",

                                  margin: "-18px auto auto",
                                  marginRight: "5px",
                                  borderBottom: "2px solid #636262",
                                }}
                                onClick={(e) =>
                                  this.addCompetitor3("Competitor 3")
                                }
                              >
                                Change Competitor3
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        id="competito3"
                        label="Competitor3"
                        autoComplete="off"
                      />
                    </Col>
                    <Row style={{ padding: "20px 0px 0px 0px" }}>
                      <Col md={6}>
                        <Button
                          style={{
                            background: "#f58d51",

                            float: "right",
                            webkitBorderRadius: "10px",
                            fontSize: "0.8em",

                            margin: "auto",
                            marginRight: "5px",
                            borderBottom: "2px solid #636262",
                          }}
                          onClick={(e) => this.getCompareData()}
                        >
                          Compare
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                )}
                <Modal
                  isOpen={this.state.showModal}
                  toggle={this.toggle}
                  size="xl"
                  className="modalContain"
                >
                  <ModalHeader>
                    <Row>
                      <Col md={6}>
                        <Label
                          style={{
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "center",
                            float: "left",
                          }}
                        >
                          Search Competitor
                        </Label>
                      </Col>
                      <Col md={6}>
                        <Fab
                          aria-label="add"
                          className="modal_close_btn"
                          onClick={this.toggle}
                        >
                          <CloseIcon />
                        </Fab>
                      </Col>
                    </Row>
                  </ModalHeader>
                  <ModalBody>
                    <Row style={{ marginTop: "1em" }}>
                      <Col md={4}>
                        <TextField
                          name="Competitor3"
                          variant="outlined"
                          required
                          value={this.state.userCompetitor}
                          onChange={this.handleSearchCompetitorChange.bind(
                            this
                          )}
                          fullWidth
                          id="competitor3"
                          label={this.state.searchLabel}
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={2}>
                        <Button
                          style={{
                            background: "#f58d51",
                            fontSize: "0.8em",
                            float: "right",
                            webkitBorderRadius: "10px",
                            fontSize: "0.8em",
                            float: "right",
                            margin: "auto",
                            marginRight: "5px",
                            borderBottom: "2px solid #636262",
                          }}
                          onClick={this.searchCompetitor.bind(this)}
                        >
                          Search Competitor
                        </Button>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "1em" }}>
                      <Col md={12}>
                        <Row style={{ maxWidth: "55em" }}>
                          <h6>
                            <b>
                              Ticker Search Results for&nbsp; "
                              {this.state.userCompetitor}"&nbsp; found{" "}
                              {this.state.tickerCount} matches.
                            </b>
                          </h6>
                        </Row>
                        {tickerSearchList}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "1em" }}>
                      <Col md={12}>
                        <Row style={{ maxWidth: "55em" }}>
                          <hr
                            style={{
                              boxShadow: "1px 1px 2px #1d1d1d",
                            }}
                          ></hr>
                          <h6>
                            <b>
                              Company Name search results for&nbsp; "
                              {this.state.userCompetitor}" &nbsp;found&nbsp;{" "}
                              {this.state.companyCount}&nbsp; matches.
                            </b>
                          </h6>
                        </Row>
                        {companySearchList}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "1em" }}>
                      <Col md={12}>
                        <Row style={{ maxWidth: "55em" }}>
                          <hr
                            style={{
                              boxShadow: "1px 1px 2px #1d1d1d",
                            }}
                          ></hr>
                          <h6>
                            <b>
                              Keyword search results for &nbsp;"
                              {this.state.userCompetitor}" &nbsp;found&nbsp;
                              {this.state.keywordCount} matches.
                            </b>
                          </h6>
                        </Row>
                        {keywordSearchList}
                      </Col>
                    </Row>
                    <Row></Row>
                  </ModalBody>
                </Modal>
              </>
            )}

            {this.state.isReportNotAvailable && (
              <h4 className="cba_data_error_msg">{this.state.errorMessage}</h4>
            )}
          </>
        );
      }
    }
  }
}
