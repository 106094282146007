import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./IndInterimIncomeStatement.css";
export default class IndInterimIncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      data: props.data,
      isPdfReport: isPdfReport,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      interimIncomeStatementData: [],
      showModal: false,
      render: false,
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType, tab) {
    if (graphType === "Net Sales or Revenues") {
      this.setState({
        graphDetails: {
          graphValues: this.state.incomeStatementList,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales or Revenues",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cost Of Good Sold") {
      this.setState({
        graphDetails: {
          graphValues: this.state.costOfGoodSold,
          labelData: this.state.LabelList,
          yAxisTitle: "Cost Of Good Sold",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Gross Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.grossIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Gross Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Expenses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingExpensesTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Expenses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Selling, General & Administrative Expenses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.sellingGeneralAndAdministrativeExpenses,
          labelData: this.state.LabelList,
          yAxisTitle: "Selling, General & Administrative Expenses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDA,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Net Income before Extraordinary Items and Preferred Dividends"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomeBeforeExtraordinaryItemsAndPreferredDividends,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income before Extraordinary Items and Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomeAfterPreferredDividendsAvailableToCommonShares,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income after Preferred Dividends - Available to Common Shares",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  componentDidMount() {
    if (this.state.type === "Interim Income Statement Actual") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let costOfGoodSold = [];
      let grossIncome = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let EBITDA = [];
      let EBIT = [];
      let pretaxIncome = [];
      let sellingGeneralAndAdministrativeExpenses = [];
      let totalOperatingExpenses = [];
      let netIncomeBeforeExtraordinaryItemsAndPreferredDividends = [];
      let netIncomeAfterPreferredDividendsAvailableToCommonShares = [];
      let interimIncomeStatementData = this.state.data.indIncomeStatement;
      if (interimIncomeStatementData.length > 0) {
        interimIncomeStatementData.reverse();
      }
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        incomeStatementList.push(
          interimIncomeStatementData[i].netSalesorRevenues
        );
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMM YYYY")
        );
        costOfGoodSold.push(interimIncomeStatementData[i].costofGoodsSold);
        grossIncome.push(interimIncomeStatementData[i].grossIncome);
        operatingExpensesTotal.push(
          interimIncomeStatementData[i].operatingIncome
        );
        operatingIncome.push(interimIncomeStatementData[i].operatingIncome);
        EBITDA.push(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        EBIT.push(
          interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
        );
        pretaxIncome.push(interimIncomeStatementData[i].pretaxIncome);
        sellingGeneralAndAdministrativeExpenses.push(
          interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses
        );
        totalOperatingExpenses.push(
          interimIncomeStatementData[i].totalOperatingExpenses
        );
        netIncomeBeforeExtraordinaryItemsAndPreferredDividends.push(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        netIncomeAfterPreferredDividendsAvailableToCommonShares.push(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );

        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].netSalesorRevenues =
          this.convertToMillionaire(
            interimIncomeStatementData[i].netSalesorRevenues
          );
        interimIncomeStatementData[i].interestExpenseonDebt =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestExpenseonDebt
          );
        interimIncomeStatementData[i].interestCapitalized =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestCapitalized
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        LabelList: LabelList,
        incomeStatementList: incomeStatementList,
        interimIncomeStatementData: interimIncomeStatementData,
        costOfGoodSold: costOfGoodSold,
        grossIncome: grossIncome,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        EBITDA: EBITDA,
        EBIT: EBIT,
        pretaxIncome: pretaxIncome,
        sellingGeneralAndAdministrativeExpenses:
          sellingGeneralAndAdministrativeExpenses,
        totalOperatingExpenses: totalOperatingExpenses,
        netIncomeBeforeExtraordinaryItemsAndPreferredDividends:
          netIncomeBeforeExtraordinaryItemsAndPreferredDividends,
        netIncomeAfterPreferredDividendsAvailableToCommonShares:
          netIncomeAfterPreferredDividendsAvailableToCommonShares,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],

        render: true,
      });
    } else if (this.state.type === "Interim Income Statement Common Size") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.indIncomeStatement;
      if (interimIncomeStatementData.length > 0) {
        interimIncomeStatementData.reverse();
      }
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        incomeStatementList.push(
          interimIncomeStatementData[i].netSalesorRevenues
        );

        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].netSalesorRevenues =
          this.convertToMillionaire(
            interimIncomeStatementData[i].netSalesorRevenues
          );
        interimIncomeStatementData[i].interestExpenseonDebt =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestExpenseonDebt
          );
        interimIncomeStatementData[i].interestCapitalized =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestCapitalized
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization +
              "%"
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (this.state.type === "Interim Income Statement Year-Year") {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.indIncomeStatement;
      if (interimIncomeStatementData.length > 0) {
        interimIncomeStatementData.reverse();
      }
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        incomeStatementList.push(
          interimIncomeStatementData[i].netSalesorRevenues
        );
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMM YYYY")
        );
        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].netSalesorRevenues =
          this.convertToMillionaire(
            interimIncomeStatementData[i].netSalesorRevenues
          );
        interimIncomeStatementData[i].interestExpenseonDebt =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestExpenseonDebt
          );
        interimIncomeStatementData[i].interestCapitalized =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestCapitalized
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization +
              "%"
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    } else if (
      this.state.type === "Interim Income Statement Three Year Average"
    ) {
      this.interimIncomeStatementData = [];
      let incomeStatementList = [];
      let LabelList = [];
      let interimIncomeStatementData = this.state.data.indIncomeStatement;
      if (interimIncomeStatementData.length > 0) {
        interimIncomeStatementData.reverse();
      }
      for (let i = 0; i < interimIncomeStatementData.length; i++) {
        incomeStatementList.push(
          interimIncomeStatementData[i].netSalesorRevenues
        );
        LabelList.push(
          moment(interimIncomeStatementData[i].year).format("MMM YYYY")
        );
        interimIncomeStatementData[i].minorityInterest =
          this.convertToMillionaire(
            interimIncomeStatementData[i].minorityInterest
          );

        interimIncomeStatementData[i].netSalesorRevenues =
          this.convertToMillionaire(
            interimIncomeStatementData[i].netSalesorRevenues
          );
        interimIncomeStatementData[i].interestExpenseonDebt =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestExpenseonDebt
          );
        interimIncomeStatementData[i].interestCapitalized =
          this.convertToMillionaire(
            interimIncomeStatementData[i].interestCapitalized
          );
        interimIncomeStatementData[i].nonOperatingInterestIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].nonOperatingInterestIncome
          );
        interimIncomeStatementData[i].costofGoodsSold =
          this.convertToMillionaire(
            interimIncomeStatementData[i].costofGoodsSold
          );
        interimIncomeStatementData[i].grossIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].grossIncome
        );
        interimIncomeStatementData[i].otherOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherOperatingExpenses
          );
        interimIncomeStatementData[i].operatingIncome =
          this.convertToMillionaire(
            interimIncomeStatementData[i].operatingIncome
          );
        interimIncomeStatementData[i].extraordinaryCreditPretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryCreditPretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].extraordinaryChargePretax =
          this.convertToMillionaire(
            interimIncomeStatementData[i].extraordinaryChargePretax
          );
        interimIncomeStatementData[i].otherIncomeExpenseNet =
          this.convertToMillionaire(
            interimIncomeStatementData[i].otherIncomeExpenseNet
          );
        interimIncomeStatementData[i].pretaxEquityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].pretaxEquityinEarnings
          );
        interimIncomeStatementData[i].reservesIncreaseDecrease =
          this.convertToMillionaire(
            interimIncomeStatementData[i].reservesIncreaseDecrease
          );
        interimIncomeStatementData[i].pretaxIncome = this.convertToMillionaire(
          interimIncomeStatementData[i].pretaxIncome
        );
        interimIncomeStatementData[i].incomeTaxes = this.convertToMillionaire(
          interimIncomeStatementData[i].incomeTaxes
        );
        interimIncomeStatementData[i].equityinEarnings =
          this.convertToMillionaire(
            interimIncomeStatementData[i].equityinEarnings
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].afterTaxOtherIncomeExpense =
          this.convertToMillionaire(
            interimIncomeStatementData[i].afterTaxOtherIncomeExpense
          );
        interimIncomeStatementData[i].discontinuedOperations =
          this.convertToMillionaire(
            interimIncomeStatementData[i].discontinuedOperations
          );
        interimIncomeStatementData[i].preferredDividendRequirements =
          this.convertToMillionaire(
            interimIncomeStatementData[i].preferredDividendRequirements
          );
        interimIncomeStatementData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            interimIncomeStatementData[i].depreciationDepletionAndAmortization
          );

        interimIncomeStatementData[i].sellingGeneralAndAdministrativeExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i]
              .sellingGeneralAndAdministrativeExpenses
          );
        interimIncomeStatementData[i].totalOperatingExpenses =
          this.convertToMillionaire(
            interimIncomeStatementData[i].totalOperatingExpenses
          );
        interimIncomeStatementData[
          i
        ].earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
        );
        interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT =
          this.convertToMillionaire(
            interimIncomeStatementData[i].earningsbeforeInterestAndTaxesEBIT
          );
        interimIncomeStatementData[
          i
        ].netIncomebeforeExtraordinaryItemsandPreferredDividends = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomebeforeExtraordinaryItemsandPreferredDividends
        );
        interimIncomeStatementData[
          i
        ].extraordinaryItemsAndGainLossSaleofAssets = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .extraordinaryItemsAndGainLossSaleofAssets
        );
        interimIncomeStatementData[
          i
        ].netIncomeafterPreferredDividendsAvailabletoCommonShares = this.convertToMillionaire(
          interimIncomeStatementData[i]
            .netIncomeafterPreferredDividendsAvailabletoCommonShares
        );
      }

      this.setState({
        interimIncomeStatementData: interimIncomeStatementData,
        series: [
          {
            name: "Sales",
            type: "bar",
            data: incomeStatementList,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          markers: {
            size: 0,
          },
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Net Sales or Revenues",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: incomeStatementList,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-3em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <Row>
                <Col md={8} style={{ padding: "9px" }}></Col>
                <Col md={4}></Col>
              </Row>

              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Quarter </th>

                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {moment(data.year).format("MM/DD/YYYY")}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Net Sales or Revenues</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netSalesorRevenues}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cost of Goods Sold</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.costofGoodsSold}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation Depletion And Amortization</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.depreciationDepletionAndAmortization}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Gross Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.grossIncome}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Selling, General & Administrative Expenses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td>{data.sellingGeneralAndAdministrativeExpenses}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Total Operating Expenses
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.totalOperatingExpenses}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Operating Income </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.operatingIncome}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Reserves - Increase/Decrease</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Earnings Before Interest Taxes Depreciation And Amortization
                    (EBITDA)
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Earnings Before Interest And Taxes EBIT
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.earningsbeforeInterestAndTaxesEBIT}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Interest Expense on Debt</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.interestExpenseonDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Capitalized</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.interestCapitalized}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Expenses</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.operatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Pretax Income</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.pretaxIncome}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Income Taxes </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Net Income before Extraordinary Items and Preferred
                    Dividends
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.netIncomebeforeExtraordinaryItemsandPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td>{data.extraordinaryItemsAndGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>{" "}
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Net Income after Preferred Dividends - Available to Common
                    Shares
                  </th>
                  {this.state.interimIncomeStatementData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {
                          data.netIncomeafterPreferredDividendsAvailabletoCommonShares
                        }
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "9px" }}></Col>
                  <Col md={4}></Col>
                </Row>

                <Table responsive className="financial_analysis1">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>

                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Sales or Revenues
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales or Revenues")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSalesorRevenues}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cost of Goods Sold
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cost of Goods Sold")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.costofGoodsSold}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation Depletion And Amortization</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td>{data.depreciationDepletionAndAmortization}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Gross Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Gross Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.grossIncome}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Selling, General & Administrative Expenses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Selling, General & Administrative Expenses"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td>{data.sellingGeneralAndAdministrativeExpenses}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Operating Expenses</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.otherOperatingExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Operating Expenses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Operating Expenses"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalOperatingExpenses}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Operating Income </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.operatingIncome}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Credit - Pretax</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.extraordinaryCreditPretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Charge - Pretax</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.extraordinaryChargePretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Operating Interest Income</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.nonOperatingInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves - Increase/Decrease</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.reservesIncreaseDecrease}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Pretax Equity in Earnings </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.pretaxEquityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Income/Expense - Net</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.otherIncomeExpenseNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Earnings Before Interest Taxes Depreciation And
                      Amortization (EBITDA)
                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("EBITDA")}
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {
                            data.earningsbeforeInterestTaxesDepreciationAndAmortizationEBITDA
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Earnings Before Interest And Taxes EBIT
                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("EBIT")}
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.earningsbeforeInterestAndTaxesEBIT}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Interest Expense on Debt</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.interestExpenseonDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Interest Capitalized</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.interestCapitalized}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Operating Expenses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Expenses")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.operatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Pretax Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pretax Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.pretaxIncome}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.incomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equity in Earnings</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.equityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>After Tax Other Income/Expense</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.afterTaxOtherIncomeExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Discontinued Operations</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.discontinuedOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Income before Extraordinary Items and Preferred
                      Dividends
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income before Extraordinary Items and Preferred Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {
                            data.netIncomebeforeExtraordinaryItemsandPreferredDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td>
                          {data.extraordinaryItemsAndGainLossSaleofAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividend Requirements</th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return <td>{data.preferredDividendRequirements}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Income after Preferred Dividends - Available to Common
                      Shares
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income after Preferred Dividends - Available to Common Shares"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.interimIncomeStatementData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {
                            data.netIncomeafterPreferredDividendsAvailabletoCommonShares
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Interim Balance Sheet({" "}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
