import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import "./BalanceSheet.css";
import BankBalanceSheet from "./BankBalanceSheet";
import InsuranceBalanceSheet from "./InsuranceBalanceSheet";
import IndBalanceSheet from "./IndBalanceSheet";
import FinancialBalanceSheet from "./FinancialBalanceSheet";

export default class BalanceSheet extends Component {
  constructor(props) {
    super(props);

    let type = props.type;
    if (
      type === "Income Statement Common Size" ||
      type === "Income Statement Year-Year" ||
      type === "Income Statement Three Year Average" ||
      type === "Income Statement Actual Pdf"
    ) {
      this.state = {
        type: type,
        balanceSheetData: [],
        render: false,
        cusip: props.data,
        isPdfReport: props.isPdfReport,
      };
    } else {
      this.state = {
        type: type,
        balanceSheetData: props.data,
        render: true,
        indType: props.data.ind_type,
        cusip: props.data,
      };
    }
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  getFinancialStatementReportData() {
    if (this.state.type === "Income Statement Common Size") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/balanceSheetPercentChange",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let balanceSheetData = data;
            let indType = data.ind_type;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              balanceSheetData: balanceSheetData,
              indType: indType,
              render: true,
              heading: "Common Size",
            });
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Income Statement Year-Year") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/balanceSheetYoYChange",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let balanceSheetData = data;
            let indType = data.ind_type;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              balanceSheetData: balanceSheetData,
              indType: indType,
              render: true,
              heading: "Year-to-Year Percent Change",
            });
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Income Statement Three Year Average") {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/balanceSheet3yearAverage",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let balanceSheetData = data;
            let indType = data.ind_type;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              balanceSheetData: balanceSheetData,
              indType: indType,
              render: true,
              heading: "3-Year Averages",
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = { cusip: this.state.cusip };

      FetchServerData.callPostService(
        "financial/balanceSheetActual",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            let balanceSheetData = data;
            let indType = data.ind_type;
            this.currency = data.currency;
            this.unit = data.unit;
            this.setState({
              balanceSheetData: balanceSheetData,
              indType: indType,
              render: true,
              heading: "Actual Values",
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  componentDidMount() {
    if (this.state.type !== "Income Statement Actual") {
      this.getFinancialStatementReportData();
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.indType === "BANK") {
        return (
          <>
            <Row>
              <Col md={12}>
                <BankBalanceSheet
                  data={this.state.balanceSheetData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></BankBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "INS") {
        return (
          <>
            <Row>
              <Col md={12}>
                <InsuranceBalanceSheet
                  data={this.state.balanceSheetData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></InsuranceBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else if (this.state.indType === "OFIN") {
        return (
          <>
            <Row>
              <Col md={12}>
                <FinancialBalanceSheet
                  data={this.state.balanceSheetData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></FinancialBalanceSheet>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row>
              <Col md={12}>
                <IndBalanceSheet
                  data={this.state.balanceSheetData}
                  type={this.state.type}
                  isPdfReport={this.state.isPdfReport}
                ></IndBalanceSheet>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
}
