import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import HomeHeader from '../ptsComponentes/header/HomeHeader';
import HomeLayoutPage from '../ptsComponentes/homeLayoutPage/HomeLayoutPage';
import HomeLayoutPageContent from '../ptsComponentes/homeLayoutPageContent/HomeLayoutPageContent';
import routes from '../ptsComponentes/views';

export class HomePageLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="app-body">
        <HomeHeader history={this.props.history}></HomeHeader>
        {/* <Page type="isHome"> */}

        <HomeLayoutPage>
          <HomeLayoutPageContent style={{ minHeight: '100vh' }}>
            {/* <PageContent style={{ minHeight: "100vh" }}> */}
            <Switch>
              {routes.map((page, key) => (
                <Route path={page.path} component={page.component} key={key} />
              ))}
              <Redirect to="/" />
            </Switch>
            {/* </PageContent> */}
          </HomeLayoutPageContent>
        </HomeLayoutPage>
        {/* </Page> */}
      </div>
    );
  }
}
export default withRouter(HomePageLayout);
