import { GET_USER_DATA, SET_USER_DATA } from "./userDataTypes";

const initialState = {
  userData: {},
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

export default headerReducer;
