import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./BankBalanceSheet.css";

export default class BankBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      render: false,
      modalHeader: "",
      isCommonSize: false,
      noYrToYr: true,
      isYrToYr: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash & Due from Banks") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashAndDueFromBanks,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash & Due from Banks",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentsTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "IInvestments – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Treasury Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.treasurySecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Treasury Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Federal Agency Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.federalAgencySecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Federal Agency Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "State and Municipal Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.stateAndMunicipalSecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "State and Municipal Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Trading Account Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.tradingAccountSecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Trading Account Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Securities Purchased under Resale Agreements") {
      this.setState({
        graphDetails: {
          graphValues: this.state.SecuritiesPurchasedunderResaleAgreements,
          labelData: this.state.LabelList,
          yAxisTitle: "Securities Purchased under Resale Agreements",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Mortgage Backed Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MortgageBackedSecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Mortgage Backed Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Federal Funds") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FederalFunds,
          labelData: this.state.LabelList,
          yAxisTitle: "Federal Funds",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Securities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherSecurities,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Securities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Loans – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LoansTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Loans – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interbank Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterbankLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Interbank Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Lease Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LeaseFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Lease Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Commercial & Industrial Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommercialAndIndustrialLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Commercial & Industrial Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Real Estate Mortgage Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.RealEstateMortgageLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Real Estate Mortgage Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Foreign Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ForeignLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Foreign Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Broker & Financial Institution Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.BrokerAndFinancialInstitutionLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Broker & Financial Institution Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unspecified/Other Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnspecifiedOtherLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Unspecified/Other Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unearned Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnearnedIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Unearned Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Reserves for Loan Losses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReservesForLoanLosses,
          labelData: this.state.LabelList,
          yAxisTitle: "Reserves for Loan Losses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Loans - Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LoansNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Loans - Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investment in Associated Companies") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentInAssociatedCompanies,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment in Associated Companies",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Customer Liabilities on Acceptances (Assets)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CustomerLiabilitiesOnAcceptancesAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Customer Liabilities on Acceptances (Assets)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Real Estate Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.RealEstateAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Real Estate Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property Plant and Equipment - Gross") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PropertyPlantAndEquipmentGross,
          labelData: this.state.LabelList,
          yAxisTitle: "Property Plant and Equipment - Gross",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Accumulated Depreciation") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AccumulatedDepreciation,
          labelData: this.state.LabelList,
          yAxisTitle: "Accumulated Depreciation",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property Plant and Equipment – Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PropertyPlantAndEquipmentNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Property Plant and Equipment – Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Receivable") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InterestReceivable,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Receivable",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Tangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Tangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Intangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IntangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Intangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities & Shareholders' Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilitiesAndShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities & Shareholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deposits - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DepositsTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Deposits - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Demand Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DemandDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Demand Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Savings/Other Time Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.SavingsOtherTimeDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Savings/Other Time Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Foreign Office Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ForeignOfficeDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Foreign Office Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unspecified Deposits") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnspecifiedDeposits,
          labelData: this.state.LabelList,
          yAxisTitle: "Unspecified Deposits",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Short Term Debt & Current Portion of Long Term De"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.ShortTermDebtAndCurrentPortionOfLongTermDe,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt & Current Portion of Long Term De",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt Excluding Capitalized Leases") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebtExcludingCapitalizedLeases,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt Excluding Capitalized Leases",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capitalized Lease Obligations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CapitalizedLeaseObligations,
          labelData: this.state.LabelList,
          yAxisTitle: "Capitalized Lease Obligations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Provision for Risks and Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ProvisionForRisksAndCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Provision for Risks and Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes - Credit") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxesCredit,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes - Credit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes - Debit") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxesDebit,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes - Debit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Tax Liability in Untaxed Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxLiabilityInUntaxedReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Tax Liability in Untaxed Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Equity Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NonEquityReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Equity Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MinorityInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock Issued for ESOP") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStockIssuedforESOP,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock Issued for ESOP",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "ESOP Guarantees - Preferred Issued") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ESOPGuaranteesPreferredIssued,
          labelData: this.state.LabelList,
          yAxisTitle: "ESOP Guarantees - Preferred Issued",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.state.balanceSheetData = [];
      let totalAssets = [];
      let CashAndDueFromBanks = [];
      let investmentsTotal = [];
      let treasurySecurities = [];
      let federalAgencySecurities = [];
      let stateAndMunicipalSecurities = [];
      let tradingAccountSecurities = [];
      let SecuritiesPurchasedunderResaleAgreements = [];
      let MortgageBackedSecurities = [];
      let FederalFunds = [];
      let OtherSecurities = [];
      let OtherInvestments = [];
      let LoansTotal = [];
      let InterbankLoans = [];
      let LeaseFinancing = [];
      let CommercialAndIndustrialLoans = [];
      let RealEstateMortgageLoans = [];
      let ForeignLoans = [];
      let BrokerAndFinancialInstitutionLoans = [];
      let UnspecifiedOtherLoans = [];
      let UnearnedIncome = [];
      let ReservesForLoanLosses = [];
      let LoansNet = [];
      let InvestmentInAssociatedCompanies = [];
      let CustomerLiabilitiesOnAcceptancesAssets = [];
      let RealEstateAssets = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let InterestReceivable = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let SavingsOtherTimeDeposits = [];
      let ForeignOfficeDeposits = [];
      let UnspecifiedDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];

      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.bankBalanceSheetList;

      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndDueFromBanks.push(balanceSheetData[i].cashDuefromBanks);
        investmentsTotal.push(balanceSheetData[i].investmentsTotal);
        treasurySecurities.push(balanceSheetData[i].treasurySecurities);
        federalAgencySecurities.push(
          balanceSheetData[i].federalAgencySecurities
        );
        stateAndMunicipalSecurities.push(
          balanceSheetData[i].stateandMunicipalSecurities
        );
        tradingAccountSecurities.push(
          balanceSheetData[i].tradingAccountSecurities
        );
        SecuritiesPurchasedunderResaleAgreements.push(
          balanceSheetData[i].securitiesPurchasedunderResaleAgreements
        );
        MortgageBackedSecurities.push(
          balanceSheetData[i].mortgageBackedSecurities
        );
        FederalFunds.push(balanceSheetData[i].federalFunds);
        OtherSecurities.push(balanceSheetData[i].otherSecurities);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        InterbankLoans.push(balanceSheetData[i].interbankLoans);
        LeaseFinancing.push(balanceSheetData[i].leaseFinancing);
        CommercialAndIndustrialLoans.push(
          balanceSheetData[i].commercialIndustrialLoans
        );
        RealEstateMortgageLoans.push(
          balanceSheetData[i].realEstateMortgageLoans
        );
        ForeignLoans.push(balanceSheetData[i].foreignLoans);
        BrokerAndFinancialInstitutionLoans.push(
          balanceSheetData[i].brokerFinancialInstitutionLoans
        );
        UnspecifiedOtherLoans.push(balanceSheetData[i].unspecifiedOtherLoans);
        UnearnedIncome.push(balanceSheetData[i].unearnedIncome);
        ReservesForLoanLosses.push(balanceSheetData[i].reservesforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        InvestmentInAssociatedCompanies.push(
          balanceSheetData[i].investmentinAssociatedCompanies
        );
        CustomerLiabilitiesOnAcceptancesAssets.push(
          balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        InterestReceivable.push(balanceSheetData[i].interestReceivable);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        DemandDeposits.push(balanceSheetData[i].demandDeposits);
        SavingsOtherTimeDeposits.push(
          balanceSheetData[i].savingsOtherTimeDeposits
        );
        ForeignOfficeDeposits.push(balanceSheetData[i].foreignOfficeDeposits);
        UnspecifiedDeposits.push(balanceSheetData[i].unspecifiedDeposits);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cashDuefromBanks = this.convertToMillionaire(
          balanceSheetData[i].cashDuefromBanks
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities = this.convertToMillionaire(
          balanceSheetData[i].federalAgencySecurities
        );

        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire(
          balanceSheetData[i].interbankLoans
        );

        balanceSheetData[i].leaseFinancing = this.convertToMillionaire(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].commercialIndustrialLoans =
          this.convertToMillionaire(
            balanceSheetData[i].commercialIndustrialLoans
          );
        balanceSheetData[i].consumerInstalmentLoans = this.convertToMillionaire(
          balanceSheetData[i].consumerInstalmentLoans
        );
        balanceSheetData[i].realEstateMortgageLoans = this.convertToMillionaire(
          balanceSheetData[i].realEstateMortgageLoans
        );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].brokerFinancialInstitutionLoans =
          this.convertToMillionaire(
            balanceSheetData[i].brokerFinancialInstitutionLoans
          );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        totalAssets: totalAssets,
        CashAndDueFromBanks: CashAndDueFromBanks,

        investmentsTotal: investmentsTotal,
        treasurySecurities: treasurySecurities,
        federalAgencySecurities: federalAgencySecurities,
        stateAndMunicipalSecurities: stateAndMunicipalSecurities,
        tradingAccountSecurities: tradingAccountSecurities,
        SecuritiesPurchasedunderResaleAgreements:
          SecuritiesPurchasedunderResaleAgreements,
        MortgageBackedSecurities: MortgageBackedSecurities,
        FederalFunds: FederalFunds,
        OtherSecurities: OtherSecurities,
        OtherInvestments: OtherInvestments,
        LoansTotal: LoansTotal,
        InterbankLoans: InterbankLoans,
        LeaseFinancing: LeaseFinancing,
        CommercialAndIndustrialLoans: CommercialAndIndustrialLoans,
        RealEstateMortgageLoans: RealEstateMortgageLoans,
        ForeignLoans: ForeignLoans,
        BrokerAndFinancialInstitutionLoans: BrokerAndFinancialInstitutionLoans,
        UnspecifiedOtherLoans: UnspecifiedOtherLoans,
        UnearnedIncome: UnearnedIncome,
        ReservesForLoanLosses: ReservesForLoanLosses,
        LoansNet: LoansNet,
        InvestmentInAssociatedCompanies: InvestmentInAssociatedCompanies,
        CustomerLiabilitiesOnAcceptancesAssets:
          CustomerLiabilitiesOnAcceptancesAssets,
        RealEstateAssets: RealEstateAssets,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        InterestReceivable: InterestReceivable,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        SavingsOtherTimeDeposits: SavingsOtherTimeDeposits,
        ForeignOfficeDeposits: ForeignOfficeDeposits,
        UnspecifiedDeposits: UnspecifiedDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      let totalAssets = [];
      let CashAndDueFromBanks = [];
      let investmentsTotal = [];
      let treasurySecurities = [];
      let federalAgencySecurities = [];
      let stateAndMunicipalSecurities = [];
      let tradingAccountSecurities = [];
      let SecuritiesPurchasedunderResaleAgreements = [];
      let MortgageBackedSecurities = [];
      let FederalFunds = [];
      let OtherSecurities = [];
      let OtherInvestments = [];
      let LoansTotal = [];
      let InterbankLoans = [];
      let LeaseFinancing = [];
      let CommercialAndIndustrialLoans = [];
      let RealEstateMortgageLoans = [];
      let ForeignLoans = [];
      let BrokerAndFinancialInstitutionLoans = [];
      let UnspecifiedOtherLoans = [];
      let UnearnedIncome = [];
      let ReservesForLoanLosses = [];
      let LoansNet = [];
      let InvestmentInAssociatedCompanies = [];
      let CustomerLiabilitiesOnAcceptancesAssets = [];
      let RealEstateAssets = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let InterestReceivable = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let SavingsOtherTimeDeposits = [];
      let ForeignOfficeDeposits = [];
      let UnspecifiedDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.bankBalanceSheetPercentChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndDueFromBanks.push(balanceSheetData[i].cashDuefromBanks);
        investmentsTotal.push(balanceSheetData[i].investmentsTotal);
        treasurySecurities.push(balanceSheetData[i].treasurySecurities);
        federalAgencySecurities.push(
          balanceSheetData[i].federalAgencySecurities
        );
        stateAndMunicipalSecurities.push(
          balanceSheetData[i].stateandMunicipalSecurities
        );
        tradingAccountSecurities.push(
          balanceSheetData[i].tradingAccountSecurities
        );
        SecuritiesPurchasedunderResaleAgreements.push(
          balanceSheetData[i].securitiesPurchasedunderResaleAgreements
        );
        MortgageBackedSecurities.push(
          balanceSheetData[i].mortgageBackedSecurities
        );
        FederalFunds.push(balanceSheetData[i].federalFunds);
        OtherSecurities.push(balanceSheetData[i].otherSecurities);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        InterbankLoans.push(balanceSheetData[i].interbankLoans);
        LeaseFinancing.push(balanceSheetData[i].leaseFinancing);
        CommercialAndIndustrialLoans.push(
          balanceSheetData[i].commercialIndustrialLoans
        );
        RealEstateMortgageLoans.push(
          balanceSheetData[i].realEstateMortgageLoans
        );
        ForeignLoans.push(balanceSheetData[i].foreignLoans);
        BrokerAndFinancialInstitutionLoans.push(
          balanceSheetData[i].brokerFinancialInstitutionLoans
        );
        UnspecifiedOtherLoans.push(balanceSheetData[i].unspecifiedOtherLoans);
        UnearnedIncome.push(balanceSheetData[i].unearnedIncome);
        ReservesForLoanLosses.push(balanceSheetData[i].reservesforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        InvestmentInAssociatedCompanies.push(
          balanceSheetData[i].investmentinAssociatedCompanies
        );
        CustomerLiabilitiesOnAcceptancesAssets.push(
          balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        InterestReceivable.push(balanceSheetData[i].interestReceivable);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        DemandDeposits.push(balanceSheetData[i].demandDeposits);
        SavingsOtherTimeDeposits.push(
          balanceSheetData[i].savingsOtherTimeDeposits
        );
        ForeignOfficeDeposits.push(balanceSheetData[i].foreignOfficeDeposits);
        UnspecifiedDeposits.push(balanceSheetData[i].unspecifiedDeposits);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        // balanceSheetData[i].totalAssets = this.convertToMillionaire1(
        //   balanceSheetData[i].totalAssets
        // );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cashDuefromBanks = this.convertToMillionaire1(
          balanceSheetData[i].cashDuefromBanks
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire1(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].federalAgencySecurities
          );

        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire1(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire1(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire1(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire1(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire1(
          balanceSheetData[i].interbankLoans
        );

        balanceSheetData[i].leaseFinancing = this.convertToMillionaire1(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].commercialIndustrialLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].commercialIndustrialLoans
          );
        balanceSheetData[i].consumerInstalmentLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].consumerInstalmentLoans
          );
        balanceSheetData[i].realEstateMortgageLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].realEstateMortgageLoans
          );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire1(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].brokerFinancialInstitutionLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].brokerFinancialInstitutionLoans
          );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire1(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire1(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire1(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire1(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire1(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire1(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire1(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire1(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire1(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire1(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].currentLiabilitiesTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].currentLiabilitiesTotal
          );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        totalAssets: totalAssets,
        CashAndDueFromBanks: CashAndDueFromBanks,

        investmentsTotal: investmentsTotal,
        treasurySecurities: treasurySecurities,
        federalAgencySecurities: federalAgencySecurities,
        stateAndMunicipalSecurities: stateAndMunicipalSecurities,
        tradingAccountSecurities: tradingAccountSecurities,
        SecuritiesPurchasedunderResaleAgreements:
          SecuritiesPurchasedunderResaleAgreements,
        MortgageBackedSecurities: MortgageBackedSecurities,
        FederalFunds: FederalFunds,
        OtherSecurities: OtherSecurities,
        OtherInvestments: OtherInvestments,
        LoansTotal: LoansTotal,
        InterbankLoans: InterbankLoans,
        LeaseFinancing: LeaseFinancing,
        CommercialAndIndustrialLoans: CommercialAndIndustrialLoans,
        RealEstateMortgageLoans: RealEstateMortgageLoans,
        ForeignLoans: ForeignLoans,
        BrokerAndFinancialInstitutionLoans: BrokerAndFinancialInstitutionLoans,
        UnspecifiedOtherLoans: UnspecifiedOtherLoans,
        UnearnedIncome: UnearnedIncome,
        ReservesForLoanLosses: ReservesForLoanLosses,
        LoansNet: LoansNet,
        InvestmentInAssociatedCompanies: InvestmentInAssociatedCompanies,
        CustomerLiabilitiesOnAcceptancesAssets:
          CustomerLiabilitiesOnAcceptancesAssets,
        RealEstateAssets: RealEstateAssets,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        InterestReceivable: InterestReceivable,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        SavingsOtherTimeDeposits: SavingsOtherTimeDeposits,
        ForeignOfficeDeposits: ForeignOfficeDeposits,
        UnspecifiedDeposits: UnspecifiedDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        render: true,
        isCommonSize: true,
        noYrToYr: false,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Common Size",
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      let totalAssets = [];
      let CashAndDueFromBanks = [];
      let investmentsTotal = [];
      let treasurySecurities = [];
      let federalAgencySecurities = [];
      let stateAndMunicipalSecurities = [];
      let tradingAccountSecurities = [];
      let SecuritiesPurchasedunderResaleAgreements = [];
      let MortgageBackedSecurities = [];
      let FederalFunds = [];
      let OtherSecurities = [];
      let OtherInvestments = [];
      let LoansTotal = [];
      let InterbankLoans = [];
      let LeaseFinancing = [];
      let CommercialAndIndustrialLoans = [];
      let RealEstateMortgageLoans = [];
      let ForeignLoans = [];
      let BrokerAndFinancialInstitutionLoans = [];
      let UnspecifiedOtherLoans = [];
      let UnearnedIncome = [];
      let ReservesForLoanLosses = [];
      let LoansNet = [];
      let InvestmentInAssociatedCompanies = [];
      let CustomerLiabilitiesOnAcceptancesAssets = [];
      let RealEstateAssets = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let InterestReceivable = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let SavingsOtherTimeDeposits = [];
      let ForeignOfficeDeposits = [];
      let UnspecifiedDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.bankBalanceSheetYoYChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndDueFromBanks.push(balanceSheetData[i].cashDuefromBanks);
        investmentsTotal.push(balanceSheetData[i].investmentsTotal);
        treasurySecurities.push(balanceSheetData[i].treasurySecurities);
        federalAgencySecurities.push(
          balanceSheetData[i].federalAgencySecurities
        );
        stateAndMunicipalSecurities.push(
          balanceSheetData[i].stateandMunicipalSecurities
        );
        tradingAccountSecurities.push(
          balanceSheetData[i].tradingAccountSecurities
        );
        SecuritiesPurchasedunderResaleAgreements.push(
          balanceSheetData[i].securitiesPurchasedunderResaleAgreements
        );
        MortgageBackedSecurities.push(
          balanceSheetData[i].mortgageBackedSecurities
        );
        FederalFunds.push(balanceSheetData[i].federalFunds);
        OtherSecurities.push(balanceSheetData[i].otherSecurities);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        InterbankLoans.push(balanceSheetData[i].interbankLoans);
        LeaseFinancing.push(balanceSheetData[i].leaseFinancing);
        CommercialAndIndustrialLoans.push(
          balanceSheetData[i].commercialIndustrialLoans
        );
        RealEstateMortgageLoans.push(
          balanceSheetData[i].realEstateMortgageLoans
        );
        ForeignLoans.push(balanceSheetData[i].foreignLoans);
        BrokerAndFinancialInstitutionLoans.push(
          balanceSheetData[i].brokerFinancialInstitutionLoans
        );
        UnspecifiedOtherLoans.push(balanceSheetData[i].unspecifiedOtherLoans);
        UnearnedIncome.push(balanceSheetData[i].unearnedIncome);
        ReservesForLoanLosses.push(balanceSheetData[i].reservesforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        InvestmentInAssociatedCompanies.push(
          balanceSheetData[i].investmentinAssociatedCompanies
        );
        CustomerLiabilitiesOnAcceptancesAssets.push(
          balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        InterestReceivable.push(balanceSheetData[i].interestReceivable);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        DemandDeposits.push(balanceSheetData[i].demandDeposits);
        SavingsOtherTimeDeposits.push(
          balanceSheetData[i].savingsOtherTimeDeposits
        );
        ForeignOfficeDeposits.push(balanceSheetData[i].foreignOfficeDeposits);
        UnspecifiedDeposits.push(balanceSheetData[i].unspecifiedDeposits);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire1(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cashDuefromBanks = this.convertToMillionaire1(
          balanceSheetData[i].cashDuefromBanks
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire1(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].federalAgencySecurities
          );

        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire1(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire1(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire1(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire1(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire1(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire1(
          balanceSheetData[i].interbankLoans
        );

        balanceSheetData[i].leaseFinancing = this.convertToMillionaire1(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].commercialIndustrialLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].commercialIndustrialLoans
          );
        balanceSheetData[i].consumerInstalmentLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].consumerInstalmentLoans
          );
        balanceSheetData[i].realEstateMortgageLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].realEstateMortgageLoans
          );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire1(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].brokerFinancialInstitutionLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].brokerFinancialInstitutionLoans
          );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire1(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire1(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire1(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire1(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation =
          this.convertToMillionaire1(
            balanceSheetData[i].accumulatedDepreciation
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire1(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire1(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire1(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire1(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire1(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire1(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].currentLiabilitiesTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].currentLiabilitiesTotal
          );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        totalAssets: totalAssets,
        CashAndDueFromBanks: CashAndDueFromBanks,

        investmentsTotal: investmentsTotal,
        treasurySecurities: treasurySecurities,
        federalAgencySecurities: federalAgencySecurities,
        stateAndMunicipalSecurities: stateAndMunicipalSecurities,
        tradingAccountSecurities: tradingAccountSecurities,
        SecuritiesPurchasedunderResaleAgreements:
          SecuritiesPurchasedunderResaleAgreements,
        MortgageBackedSecurities: MortgageBackedSecurities,
        FederalFunds: FederalFunds,
        OtherSecurities: OtherSecurities,
        OtherInvestments: OtherInvestments,
        LoansTotal: LoansTotal,
        InterbankLoans: InterbankLoans,
        LeaseFinancing: LeaseFinancing,
        CommercialAndIndustrialLoans: CommercialAndIndustrialLoans,
        RealEstateMortgageLoans: RealEstateMortgageLoans,
        ForeignLoans: ForeignLoans,
        BrokerAndFinancialInstitutionLoans: BrokerAndFinancialInstitutionLoans,
        UnspecifiedOtherLoans: UnspecifiedOtherLoans,
        UnearnedIncome: UnearnedIncome,
        ReservesForLoanLosses: ReservesForLoanLosses,
        LoansNet: LoansNet,
        InvestmentInAssociatedCompanies: InvestmentInAssociatedCompanies,
        CustomerLiabilitiesOnAcceptancesAssets:
          CustomerLiabilitiesOnAcceptancesAssets,
        RealEstateAssets: RealEstateAssets,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        InterestReceivable: InterestReceivable,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        SavingsOtherTimeDeposits: SavingsOtherTimeDeposits,
        ForeignOfficeDeposits: ForeignOfficeDeposits,
        UnspecifiedDeposits: UnspecifiedDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: false,
        isYrToYr: true,
        modalHeader: " Balance Sheet -Yr-yr % change",
        LabelList: LabelList,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      let totalAssets = [];
      let CashAndDueFromBanks = [];
      let investmentsTotal = [];
      let treasurySecurities = [];
      let federalAgencySecurities = [];
      let stateAndMunicipalSecurities = [];
      let tradingAccountSecurities = [];
      let SecuritiesPurchasedunderResaleAgreements = [];
      let MortgageBackedSecurities = [];
      let FederalFunds = [];
      let OtherSecurities = [];
      let OtherInvestments = [];
      let LoansTotal = [];
      let InterbankLoans = [];
      let LeaseFinancing = [];
      let CommercialAndIndustrialLoans = [];
      let RealEstateMortgageLoans = [];
      let ForeignLoans = [];
      let BrokerAndFinancialInstitutionLoans = [];
      let UnspecifiedOtherLoans = [];
      let UnearnedIncome = [];
      let ReservesForLoanLosses = [];
      let LoansNet = [];
      let InvestmentInAssociatedCompanies = [];
      let CustomerLiabilitiesOnAcceptancesAssets = [];
      let RealEstateAssets = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let InterestReceivable = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let SavingsOtherTimeDeposits = [];
      let ForeignOfficeDeposits = [];
      let UnspecifiedDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];
      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.bankBalanceSheet3YearAverageList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndDueFromBanks.push(balanceSheetData[i].cashDuefromBanks);
        investmentsTotal.push(balanceSheetData[i].investmentsTotal);
        treasurySecurities.push(balanceSheetData[i].treasurySecurities);
        federalAgencySecurities.push(
          balanceSheetData[i].federalAgencySecurities
        );
        stateAndMunicipalSecurities.push(
          balanceSheetData[i].stateandMunicipalSecurities
        );
        tradingAccountSecurities.push(
          balanceSheetData[i].tradingAccountSecurities
        );
        SecuritiesPurchasedunderResaleAgreements.push(
          balanceSheetData[i].securitiesPurchasedunderResaleAgreements
        );
        MortgageBackedSecurities.push(
          balanceSheetData[i].mortgageBackedSecurities
        );
        FederalFunds.push(balanceSheetData[i].federalFunds);
        OtherSecurities.push(balanceSheetData[i].otherSecurities);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        InterbankLoans.push(balanceSheetData[i].interbankLoans);
        LeaseFinancing.push(balanceSheetData[i].leaseFinancing);
        CommercialAndIndustrialLoans.push(
          balanceSheetData[i].commercialIndustrialLoans
        );
        RealEstateMortgageLoans.push(
          balanceSheetData[i].realEstateMortgageLoans
        );
        ForeignLoans.push(balanceSheetData[i].foreignLoans);
        BrokerAndFinancialInstitutionLoans.push(
          balanceSheetData[i].brokerFinancialInstitutionLoans
        );
        UnspecifiedOtherLoans.push(balanceSheetData[i].unspecifiedOtherLoans);
        UnearnedIncome.push(balanceSheetData[i].unearnedIncome);
        ReservesForLoanLosses.push(balanceSheetData[i].reservesforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        InvestmentInAssociatedCompanies.push(
          balanceSheetData[i].investmentinAssociatedCompanies
        );
        CustomerLiabilitiesOnAcceptancesAssets.push(
          balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        InterestReceivable.push(balanceSheetData[i].interestReceivable);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        DemandDeposits.push(balanceSheetData[i].demandDeposits);
        SavingsOtherTimeDeposits.push(
          balanceSheetData[i].savingsOtherTimeDeposits
        );
        ForeignOfficeDeposits.push(balanceSheetData[i].foreignOfficeDeposits);
        UnspecifiedDeposits.push(balanceSheetData[i].unspecifiedDeposits);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cashDuefromBanks = this.convertToMillionaire(
          balanceSheetData[i].cashDuefromBanks
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities = this.convertToMillionaire(
          balanceSheetData[i].federalAgencySecurities
        );

        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire(
          balanceSheetData[i].interbankLoans
        );

        balanceSheetData[i].leaseFinancing = this.convertToMillionaire(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].commercialIndustrialLoans =
          this.convertToMillionaire(
            balanceSheetData[i].commercialIndustrialLoans
          );
        balanceSheetData[i].consumerInstalmentLoans = this.convertToMillionaire(
          balanceSheetData[i].consumerInstalmentLoans
        );
        balanceSheetData[i].realEstateMortgageLoans = this.convertToMillionaire(
          balanceSheetData[i].realEstateMortgageLoans
        );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].brokerFinancialInstitutionLoans =
          this.convertToMillionaire(
            balanceSheetData[i].brokerFinancialInstitutionLoans
          );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        totalAssets: totalAssets,
        CashAndDueFromBanks: CashAndDueFromBanks,

        investmentsTotal: investmentsTotal,
        treasurySecurities: treasurySecurities,
        federalAgencySecurities: federalAgencySecurities,
        stateAndMunicipalSecurities: stateAndMunicipalSecurities,
        tradingAccountSecurities: tradingAccountSecurities,
        SecuritiesPurchasedunderResaleAgreements:
          SecuritiesPurchasedunderResaleAgreements,
        MortgageBackedSecurities: MortgageBackedSecurities,
        FederalFunds: FederalFunds,
        OtherSecurities: OtherSecurities,
        OtherInvestments: OtherInvestments,
        LoansTotal: LoansTotal,
        InterbankLoans: InterbankLoans,
        LeaseFinancing: LeaseFinancing,
        CommercialAndIndustrialLoans: CommercialAndIndustrialLoans,
        RealEstateMortgageLoans: RealEstateMortgageLoans,
        ForeignLoans: ForeignLoans,
        BrokerAndFinancialInstitutionLoans: BrokerAndFinancialInstitutionLoans,
        UnspecifiedOtherLoans: UnspecifiedOtherLoans,
        UnearnedIncome: UnearnedIncome,
        ReservesForLoanLosses: ReservesForLoanLosses,
        LoansNet: LoansNet,
        InvestmentInAssociatedCompanies: InvestmentInAssociatedCompanies,
        CustomerLiabilitiesOnAcceptancesAssets:
          CustomerLiabilitiesOnAcceptancesAssets,
        RealEstateAssets: RealEstateAssets,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        InterestReceivable: InterestReceivable,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        SavingsOtherTimeDeposits: SavingsOtherTimeDeposits,
        ForeignOfficeDeposits: ForeignOfficeDeposits,
        UnspecifiedDeposits: UnspecifiedDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: "Balance Sheet -3 yr Averages",
        LabelList: LabelList,
      });
    } else {
      this.state.balanceSheetData = [];
      let totalAssets = [];
      let CashAndDueFromBanks = [];
      let investmentsTotal = [];
      let treasurySecurities = [];
      let federalAgencySecurities = [];
      let stateAndMunicipalSecurities = [];
      let tradingAccountSecurities = [];
      let SecuritiesPurchasedunderResaleAgreements = [];
      let MortgageBackedSecurities = [];
      let FederalFunds = [];
      let OtherSecurities = [];
      let OtherInvestments = [];
      let LoansTotal = [];
      let InterbankLoans = [];
      let LeaseFinancing = [];
      let CommercialAndIndustrialLoans = [];
      let RealEstateMortgageLoans = [];
      let ForeignLoans = [];
      let BrokerAndFinancialInstitutionLoans = [];
      let UnspecifiedOtherLoans = [];
      let UnearnedIncome = [];
      let ReservesForLoanLosses = [];
      let LoansNet = [];
      let InvestmentInAssociatedCompanies = [];
      let CustomerLiabilitiesOnAcceptancesAssets = [];
      let RealEstateAssets = [];
      let PropertyPlantAndEquipmentGross = [];
      let AccumulatedDepreciation = [];
      let PropertyPlantAndEquipmentNet = [];
      let OtherAssets = [];
      let InterestReceivable = [];
      let DeferredCharges = [];
      let TangibleOtherAssets = [];
      let IntangibleOtherAssets = [];
      let TotalLiabilitiesAndShareholdersEquity = [];
      let DepositsTotal = [];
      let DemandDeposits = [];
      let SavingsOtherTimeDeposits = [];
      let ForeignOfficeDeposits = [];
      let UnspecifiedDeposits = [];
      let TotalDebt = [];
      let ShortTermDebtAndCurrentPortionOfLongTermDe = [];
      let LongTermDebt = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let CapitalizedLeaseObligations = [];
      let ProvisionForRisksAndCharges = [];
      let DeferredIncome = [];
      let DeferredTaxes = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxLiabilityInUntaxedReserves = [];

      let OtherLiabilities = [];
      let TotalLiabilities = [];
      let NonEquityReserves = [];
      let MinorityInterest = [];
      let PreferredStock = [];
      let PreferredStockIssuedforESOP = [];
      let ESOPGuaranteesPreferredIssued = [];
      let CommonEquity = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.bankBalanceSheetList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalAssets.push(balanceSheetData[i].totalAssets);
        CashAndDueFromBanks.push(balanceSheetData[i].cashDuefromBanks);
        investmentsTotal.push(balanceSheetData[i].investmentsTotal);
        treasurySecurities.push(balanceSheetData[i].treasurySecurities);
        federalAgencySecurities.push(
          balanceSheetData[i].federalAgencySecurities
        );
        stateAndMunicipalSecurities.push(
          balanceSheetData[i].stateandMunicipalSecurities
        );
        tradingAccountSecurities.push(
          balanceSheetData[i].tradingAccountSecurities
        );
        SecuritiesPurchasedunderResaleAgreements.push(
          balanceSheetData[i].securitiesPurchasedunderResaleAgreements
        );
        MortgageBackedSecurities.push(
          balanceSheetData[i].mortgageBackedSecurities
        );
        FederalFunds.push(balanceSheetData[i].federalFunds);
        OtherSecurities.push(balanceSheetData[i].otherSecurities);
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        LoansTotal.push(balanceSheetData[i].loansTotal);
        InterbankLoans.push(balanceSheetData[i].interbankLoans);
        LeaseFinancing.push(balanceSheetData[i].leaseFinancing);
        CommercialAndIndustrialLoans.push(
          balanceSheetData[i].commercialIndustrialLoans
        );
        RealEstateMortgageLoans.push(
          balanceSheetData[i].realEstateMortgageLoans
        );
        ForeignLoans.push(balanceSheetData[i].foreignLoans);
        BrokerAndFinancialInstitutionLoans.push(
          balanceSheetData[i].brokerFinancialInstitutionLoans
        );
        UnspecifiedOtherLoans.push(balanceSheetData[i].unspecifiedOtherLoans);
        UnearnedIncome.push(balanceSheetData[i].unearnedIncome);
        ReservesForLoanLosses.push(balanceSheetData[i].reservesforLoanLosses);
        LoansNet.push(balanceSheetData[i].loansNet);
        InvestmentInAssociatedCompanies.push(
          balanceSheetData[i].investmentinAssociatedCompanies
        );
        CustomerLiabilitiesOnAcceptancesAssets.push(
          balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        PropertyPlantAndEquipmentGross.push(
          balanceSheetData[i].propertyPlantandEquipmentGross
        );
        AccumulatedDepreciation.push(
          balanceSheetData[i].accumulatedDepreciation
        );
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        InterestReceivable.push(balanceSheetData[i].interestReceivable);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TotalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        DepositsTotal.push(balanceSheetData[i].depositsTotal);
        DemandDeposits.push(balanceSheetData[i].demandDeposits);
        SavingsOtherTimeDeposits.push(
          balanceSheetData[i].savingsOtherTimeDeposits
        );
        ForeignOfficeDeposits.push(balanceSheetData[i].foreignOfficeDeposits);
        UnspecifiedDeposits.push(balanceSheetData[i].unspecifiedDeposits);
        TotalDebt.push(balanceSheetData[i].totalDebt);
        ShortTermDebtAndCurrentPortionOfLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionforRisksandCharges
        );
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxLiabilityInUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        PreferredStock.push(balanceSheetData[i].preferredStock);
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        CommonEquity.push(balanceSheetData[i].commonEquity);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cashDuefromBanks = this.convertToMillionaire(
          balanceSheetData[i].cashDuefromBanks
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].treasurySecurities = this.convertToMillionaire(
          balanceSheetData[i].treasurySecurities
        );
        balanceSheetData[i].federalAgencySecurities = this.convertToMillionaire(
          balanceSheetData[i].federalAgencySecurities
        );

        balanceSheetData[i].stateandMunicipalSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].stateandMunicipalSecurities
          );
        balanceSheetData[i].tradingAccountSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].tradingAccountSecurities
          );
        balanceSheetData[i].securitiesPurchasedunderResaleAgreements =
          this.convertToMillionaire(
            balanceSheetData[i].securitiesPurchasedunderResaleAgreements
          );
        balanceSheetData[i].mortgageBackedSecurities =
          this.convertToMillionaire(
            balanceSheetData[i].mortgageBackedSecurities
          );
        balanceSheetData[i].federalFunds = this.convertToMillionaire(
          balanceSheetData[i].federalFunds
        );
        balanceSheetData[i].otherSecurities = this.convertToMillionaire(
          balanceSheetData[i].otherSecurities
        );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].loansTotal = this.convertToMillionaire(
          balanceSheetData[i].loansTotal
        );
        balanceSheetData[i].interbankLoans = this.convertToMillionaire(
          balanceSheetData[i].interbankLoans
        );

        balanceSheetData[i].leaseFinancing = this.convertToMillionaire(
          balanceSheetData[i].leaseFinancing
        );
        balanceSheetData[i].commercialIndustrialLoans =
          this.convertToMillionaire(
            balanceSheetData[i].commercialIndustrialLoans
          );
        balanceSheetData[i].consumerInstalmentLoans = this.convertToMillionaire(
          balanceSheetData[i].consumerInstalmentLoans
        );
        balanceSheetData[i].realEstateMortgageLoans = this.convertToMillionaire(
          balanceSheetData[i].realEstateMortgageLoans
        );
        balanceSheetData[i].foreignLoans = this.convertToMillionaire(
          balanceSheetData[i].foreignLoans
        );
        balanceSheetData[i].brokerFinancialInstitutionLoans =
          this.convertToMillionaire(
            balanceSheetData[i].brokerFinancialInstitutionLoans
          );
        balanceSheetData[i].unspecifiedOtherLoans = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedOtherLoans
        );
        balanceSheetData[i].unearnedIncome = this.convertToMillionaire(
          balanceSheetData[i].unearnedIncome
        );
        balanceSheetData[i].reservesforLoanLosses = this.convertToMillionaire(
          balanceSheetData[i].reservesforLoanLosses
        );
        balanceSheetData[i].loansNet = this.convertToMillionaire(
          balanceSheetData[i].loansNet
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].customerLiabilitiesonAcceptancesAssets =
          this.convertToMillionaire(
            balanceSheetData[i].customerLiabilitiesonAcceptancesAssets
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].interestReceivable = this.convertToMillionaire(
          balanceSheetData[i].interestReceivable
        );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].depositsTotal = this.convertToMillionaire(
          balanceSheetData[i].depositsTotal
        );
        balanceSheetData[i].demandDeposits = this.convertToMillionaire(
          balanceSheetData[i].demandDeposits
        );
        balanceSheetData[i].savingsOtherTimeDeposits =
          this.convertToMillionaire(
            balanceSheetData[i].savingsOtherTimeDeposits
          );
        balanceSheetData[i].foreignOfficeDeposits = this.convertToMillionaire(
          balanceSheetData[i].foreignOfficeDeposits
        );
        balanceSheetData[i].unspecifiedDeposits = this.convertToMillionaire(
          balanceSheetData[i].unspecifiedDeposits
        );
        balanceSheetData[i].currentLiabilitiesTotal = this.convertToMillionaire(
          balanceSheetData[i].currentLiabilitiesTotal
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        totalAssets: totalAssets,
        CashAndDueFromBanks: CashAndDueFromBanks,

        investmentsTotal: investmentsTotal,
        treasurySecurities: treasurySecurities,
        federalAgencySecurities: federalAgencySecurities,
        stateAndMunicipalSecurities: stateAndMunicipalSecurities,
        tradingAccountSecurities: tradingAccountSecurities,
        SecuritiesPurchasedunderResaleAgreements:
          SecuritiesPurchasedunderResaleAgreements,
        MortgageBackedSecurities: MortgageBackedSecurities,
        FederalFunds: FederalFunds,
        OtherSecurities: OtherSecurities,
        OtherInvestments: OtherInvestments,
        LoansTotal: LoansTotal,
        InterbankLoans: InterbankLoans,
        LeaseFinancing: LeaseFinancing,
        CommercialAndIndustrialLoans: CommercialAndIndustrialLoans,
        RealEstateMortgageLoans: RealEstateMortgageLoans,
        ForeignLoans: ForeignLoans,
        BrokerAndFinancialInstitutionLoans: BrokerAndFinancialInstitutionLoans,
        UnspecifiedOtherLoans: UnspecifiedOtherLoans,
        UnearnedIncome: UnearnedIncome,
        ReservesForLoanLosses: ReservesForLoanLosses,
        LoansNet: LoansNet,
        InvestmentInAssociatedCompanies: InvestmentInAssociatedCompanies,
        CustomerLiabilitiesOnAcceptancesAssets:
          CustomerLiabilitiesOnAcceptancesAssets,
        RealEstateAssets: RealEstateAssets,
        PropertyPlantAndEquipmentGross: PropertyPlantAndEquipmentGross,
        AccumulatedDepreciation: AccumulatedDepreciation,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        OtherAssets: OtherAssets,
        InterestReceivable: InterestReceivable,
        DeferredCharges: DeferredCharges,
        TangibleOtherAssets: TangibleOtherAssets,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TotalLiabilitiesAndShareholdersEquity:
          TotalLiabilitiesAndShareholdersEquity,
        DepositsTotal: DepositsTotal,
        DemandDeposits: DemandDeposits,
        SavingsOtherTimeDeposits: SavingsOtherTimeDeposits,
        ForeignOfficeDeposits: ForeignOfficeDeposits,
        UnspecifiedDeposits: UnspecifiedDeposits,
        TotalDebt: TotalDebt,
        ShortTermDebtAndCurrentPortionOfLongTermDe:
          ShortTermDebtAndCurrentPortionOfLongTermDe,
        LongTermDebt: LongTermDebt,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        DeferredIncome: DeferredIncome,
        DeferredTaxes: DeferredTaxes,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxLiabilityInUntaxedReserves:
          DeferredTaxLiabilityInUntaxedReserves,
        OtherLiabilities: OtherLiabilities,
        TotalLiabilities: TotalLiabilities,
        NonEquityReserves: NonEquityReserves,
        MinorityInterest: MinorityInterest,
        PreferredStock: PreferredStock,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        CommonEquity: CommonEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        LabelList: LabelList,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash & Due from Banks
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash & Due from Banks",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashDuefromBanks}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investments – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Treasury Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Treasury Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.treasurySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Federal Agency Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Federal Agency Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalAgencySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      State and Municipal Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "State and Municipal Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.stateandMunicipalSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Trading Account Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Trading Account Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tradingAccountSecurities}</td>;
                    })}
                  </tr>
                  <tr style={{ paddingLeft: "2em" }}>
                    <th>
                      Securities Purchased under Resale Agreements
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Securities Purchased under Resale Agreements",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.securitiesPurchasedunderResaleAgreements}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Mortgage Backed Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Mortgage Backed Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgageBackedSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Federal Funds{" "}
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Federal Funds",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalFunds}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Other Securities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Securities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherSecurities}</td>;
                    })}
                  </tr>{" "}
                  <tr style={{ paddingLeft: "2em" }}>
                    <th>
                      Other Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Loans – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Interbank Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Interbank Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interbankLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Lease Financing
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Lease Financing",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.leaseFinancing}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Commercial & Industrial Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Commercial & Industrial Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commercialIndustrialLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Consumer & Instalment Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Consumer & Instalment Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.consumerInstalmentLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Real Estate Mortgage Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Real Estate Mortgage Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateMortgageLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Foreign Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Foreign Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Broker & Financial Institution Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Broker & Financial Institution Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.brokerFinancialInstitutionLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Unspecified/Other Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unspecified/Other Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedOtherLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Unearned Income
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unearned Income",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedIncome}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Reserves for Loan Losses
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Reserves for Loan Losses",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reservesforLoanLosses}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans - Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Loans - Net",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Investment in Associated Companies
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investment in Associated Companies",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Customer Liabilities on Acceptances (Assets)
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Customer Liabilities on Acceptances (Assets)",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.customerLiabilitiesonAcceptancesAssets}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Real Estate Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Real Estate Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Property Plant and Equipment - Gross
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property Plant and Equipment - Gross",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Accumulated Depreciation",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property Plant and Equipment – Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property Plant and Equipment – Net",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Interest Receivable
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Interest Receivable",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interestReceivable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Tangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Intangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilitiesShareholdersEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deposits - Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deposits - Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.depositsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Demand Deposits
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Demand Deposits",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.demandDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Savings/Other Time Deposits
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Savings/Other Time Deposits",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.savingsOtherTimeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Foreign Office Deposits
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Foreign Office Deposits",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignOfficeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Unspecified Deposits
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unspecified Deposits",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term De
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term De",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Excluding Capitalized Leases",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Capitalized Lease Obligations
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Capitalized Lease Obligations",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Provision for Risks and Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Provision for Risks and Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Income
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Income",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Taxes
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes - Credit",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes - Debit",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Tax Liability in Untaxed Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Tax Liability in Untaxed Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Non-Equity Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Non-Equity Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Minority Interest
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Minority Interest",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Preferred Stock
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock Issued for ESOP",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "ESOP Guarantees - Preferred Issued",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {/*<ableInlineGraph

                          yAxisTitle="Absolute Free Cash Flow Percent Enterprise Value"
                          yAxisTitleSign=""
                          tableGraphData={
                            this.state.ESOPGuaranteesPreferredIssued
                          }
                        ></TableInlineGraph>*/}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {/*<TableInlineGraph
                          yAxisTitle="Absolute Free Cash Flow Percent Enterprise Value"
                          yAxisTitleSign=""
                          tableGraphData={this.state.CommonEquity}
                        ></TableInlineGraph>*/}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      >
                        {/*<TableInlineGraph
                          yAxisTitle="Absolute Free Cash Flow Percent Enterprise Value"
                          yAxisTitleSign=""
                          tableGraphData={
                            this.state.TotalLiabilitiesAndShareholdersEquity
                          }
                        ></TableInlineGraph>*/}
                      </div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>

                {/* {itemRow}
                </Table>
              </TableContainer> */}
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow Percent Enterprise Value"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash & Due from Banks
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash & Due from Banks")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashDuefromBanks}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investments – Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investments – Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Treasury Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.treasurySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Federal Agency Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalAgencySecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      State and Municipal Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.stateandMunicipalSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Trading Account Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tradingAccountSecurities}</td>;
                    })}
                  </tr>
                  <tr style={{ paddingLeft: "2em" }}>
                    <th>
                      Securities Purchased under Resale Agreements
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Securities Purchased under Resale Agreements"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.securitiesPurchasedunderResaleAgreements}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Mortgage Backed Securities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgageBackedSecurities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Federal Funds </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.federalFunds}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Other Securities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherSecurities}</td>;
                    })}
                  </tr>{" "}
                  <tr style={{ paddingLeft: "2em" }}>
                    <th>
                      Other Investments
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Investments")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans – Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Loans – Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Interbank Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interbankLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Lease Financing</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.leaseFinancing}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Commercial & Industrial Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commercialIndustrialLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Consumer & Instalment Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.consumerInstalmentLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Real Estate Mortgage Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateMortgageLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Foreign Loans</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Broker & Financial Institution Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.brokerFinancialInstitutionLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Unspecified/Other Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedOtherLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Unearned Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedIncome}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Reserves for Loan Losses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Reserves for Loan Losses"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.reservesforLoanLosses}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Loans - Net
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Loans - Net")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.loansNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Customer Liabilities on Acceptances (Assets)</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.customerLiabilitiesonAcceptancesAssets}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Property Plant and Equipment - Gross</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Interest Receivable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.interestReceivable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilitiesShareholdersEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deposits - Total</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.depositsTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Demand Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.demandDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Savings/Other Time Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.savingsOtherTimeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Foreign Office Deposits
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.foreignOfficeDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Unspecified Deposits</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unspecifiedDeposits}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term De
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term De"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long-Term Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capitalized Lease Obligations</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Provision for Risks and Charges
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Provision for Risks and Charges"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Tax Liability in Untaxed Reserves
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>

                {/* {itemRow}
                </Table>
              </TableContainer> */}
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
