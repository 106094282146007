import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./FixedChargesCoverage.css";
export default class FixedChargesCoverage extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }

    let type = props.type;
    this.state = {
      isPdfReport: isPdfReport,
      type: type,
      fixedChargeCoverageData: [],
      render: false,
      data: props.data,
    };
  }
  newLegendClickHandler(e, legendItem) {}
  convertToMillionaire(value) {
    let values = value;
    if (!values) {
      let values = "";
      return values;
    } else {
      return values;
    }
  }
  componentDidMount() {
    let indType = this.state.data.ind_TYPE;
    this.currency = this.state.data.currency;
    this.unit = this.state.data.unit;
    let fixedChargeCoveragesList =
      this.state.data.fixedChargeCoveragesList.slice(0, 5);
    if (fixedChargeCoveragesList.length > 0) {
      fixedChargeCoveragesList.reverse();
    }
    let eBITDATotalInterestExp = [];
    let LabelList = [];
    let EBITTotalInterestExpense = [];
    let EBITNetInterest = [];
    let EBITTotalInterestExpPlusPfdDiv = [];
    let EBITDividendsOnCommonShares = [];
    let EBITDividendsOnCommonPlusPfd = [];
    let EBITDANetInterest = [];
    let EBITDATotalInterestExpPlusPfdDiv = [];
    let EBITDADividendsOnComShares = [];
    let EBITDADividendsOnComPlusPfd = [];
    for (let i = 0; i < fixedChargeCoveragesList.length; i++) {
      eBITDATotalInterestExp.push(
        fixedChargeCoveragesList[i].eBITDATotalInterestExpPfdDiv
      );
      LabelList.push(fixedChargeCoveragesList[i].fiscalYear);

      EBITTotalInterestExpense.push(
        fixedChargeCoveragesList[i].eBITTotalInterestExpense
      );
      EBITNetInterest.push(fixedChargeCoveragesList[i].eBITNetInterest);
      EBITTotalInterestExpPlusPfdDiv.push(
        fixedChargeCoveragesList[i].eBITTotalInterestExpPfdDiv
      );
      EBITDividendsOnCommonShares.push(
        fixedChargeCoveragesList[i].eBITDividendsonCommonShares
      );
      EBITDividendsOnCommonPlusPfd.push(
        fixedChargeCoveragesList[i].eBITDividendsonCommonPfd
      );
      EBITDANetInterest.push(fixedChargeCoveragesList[i].eBITDANetInterest);
      EBITDATotalInterestExpPlusPfdDiv.push(
        fixedChargeCoveragesList[i].eBITDATotalInterestExpPfdDivPercent
      );
      EBITDADividendsOnComShares.push(
        fixedChargeCoveragesList[i].eBITDADividendsonComShares
      );
      EBITDADividendsOnComPlusPfd.push(
        fixedChargeCoveragesList[i].eBITDADividendsonComPfd
      );
    }
    this.setState({
      fixedChargeCoverageData: fixedChargeCoveragesList,
      EBITTotalInterestExpense: EBITTotalInterestExpense,
      EBITNetInterest: EBITNetInterest,
      EBITTotalInterestExpPlusPfdDiv: EBITTotalInterestExpPlusPfdDiv,
      EBITDividendsOnCommonShares: EBITDividendsOnCommonShares,
      EBITDividendsOnCommonPlusPfd: EBITDividendsOnCommonPlusPfd,
      EBITDANetInterest: EBITDANetInterest,
      EBITDATotalInterestExpPlusPfdDiv: EBITDATotalInterestExpPlusPfdDiv,
      EBITDADividendsOnComShares: EBITDADividendsOnComShares,
      EBITDADividendsOnComPlusPfd: EBITDADividendsOnComPlusPfd,
      indType: indType,
      eBITDATotalInterestExp: eBITDATotalInterestExp,
      LabelList: LabelList,
      render: true,
    });
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "EBIT/Total Interest Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITTotalInterestExpense,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT/Total Interest Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT/Net Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITNetInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT/Net Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT/(Total Interest Exp + Pfd Div)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITTotalInterestExpPlusPfdDiv,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT/(Total Interest Exp + Pfd Div)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT/Dividends on Common Shares") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDividendsOnCommonShares,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT/Dividends on Common Shares",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBIT/(Dividends on Common + Pfd)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDividendsOnCommonPlusPfd,
          labelData: this.state.LabelList,
          yAxisTitle: "EBIT/(Dividends on Common + Pfd)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA/Total Interest Expense") {
      this.setState({
        graphDetails: {
          graphValues: this.state.eBITDATotalInterestExp,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA/Total Interest Expense",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA/Net Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDANetInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA/Net Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA/(Total Interest Exp + Pfd Div)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDATotalInterestExpPlusPfdDiv,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA/(Total Interest Exp + Pfd Div)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA/Dividends on Com Shares") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDADividendsOnComShares,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA/Dividends on Com Shares",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EBITDA/(Dividends on Com + Pfd)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EBITDADividendsOnComPlusPfd,
          labelData: this.state.LabelList,
          yAxisTitle: "EBITDA/(Dividends on Com + Pfd)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12} style={{ padding: "0em 0em 0em 3em" }}>
              <Row style={{ padding: "0px 0px 7px 0px" }}>
                <Col md={8}>
                  <span>
                    <span className="section_header">
                      {" "}
                      Fixed Charges Coverage
                    </span>
                  </span>{" "}
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="fixCharge_coverage">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    EBIT/Total Interest Expense
                  </th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.eBITTotalInterestExpense !== null
                          ? Utils.valueFormater(data.eBITTotalInterestExpense)
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBIT/Net Interest</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITNetInterestPercent !== null
                          ? Utils.valueFormater(data.eBITNetInterestPercent)
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBIT/(Total Interest Exp + Pfd Div)</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITTotalInterestExpPfdDivPercent !== null
                          ? Utils.valueFormater(
                              data.eBITTotalInterestExpPfdDivPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBIT/Dividends on Common Shares</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITDividendsonCommonSharesPercent !== null
                          ? Utils.valueFormater(
                              data.eBITDividendsonCommonSharesPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBIT/(Dividends on Common + Pfd)</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITDividendsonCommonPfdPercent !== null
                          ? Utils.valueFormater(
                              data.eBITDividendsonCommonPfdPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBITDA/Total Interest Expense</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.eBITDATotalInterestExpensePercent !== null
                          ? Utils.valueFormater(
                              data.eBITDATotalInterestExpensePercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBITDA/Net Interest</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {data.eBITDANetInterestPercent !== null
                          ? Utils.valueFormater(data.eBITDANetInterestPercent)
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th onClick={(e) => this.getDataInGraphicalForm()}>
                    <span className="">
                      {" "}
                      EBITDA/(Total Interest Exp + Pfd Div)
                    </span>
                  </th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {data.eBITDATotalInterestExpPfdDivPercent !== null
                          ? Utils.valueFormater(
                              data.eBITDATotalInterestExpPfdDivPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <th>EBITDA/Dividends on Com Shares</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {data.eBITDADividendsonComSharesPercent !== null
                          ? Utils.valueFormater(
                              data.eBITDADividendsonComSharesPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>EBITDA/(Dividends on Com + Pfd)</th>
                  {this.state.fixedChargeCoverageData.map((data) => {
                    return (
                      <td>
                        {data.eBITDADividendsonComPfdPercent !== null
                          ? Utils.valueFormater(
                              data.eBITDADividendsonComPfdPercent
                            )
                          : ""}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row style={{ padding: "0px 0px 7px 0px" }}>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Fixed Charges Coverage
                      </span>
                    </span>{" "}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="fixCharge_coverage">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      EBIT/Total Interest Expense
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "EBIT/Total Interest Expense"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.eBITTotalInterestExpense !== null
                            ? Utils.valueFormater(data.eBITTotalInterestExpense)
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBIT/Net Interest</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITNetInterestPercent !== null
                            ? Utils.valueFormater(data.eBITNetInterestPercent)
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBIT/(Total Interest Exp + Pfd Div)</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITTotalInterestExpPfdDivPercent !== null
                            ? Utils.valueFormater(
                                data.eBITTotalInterestExpPfdDivPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBIT/Dividends on Common Shares</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITDividendsonCommonSharesPercent !== null
                            ? Utils.valueFormater(
                                data.eBITDividendsonCommonSharesPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBIT/(Dividends on Common + Pfd)</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITDividendsonCommonPfdPercent !== null
                            ? Utils.valueFormater(
                                data.eBITDividendsonCommonPfdPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBITDA/Total Interest Expense</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {" "}
                          {data.eBITDATotalInterestExpensePercent !== null
                            ? Utils.valueFormater(
                                data.eBITDATotalInterestExpensePercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBITDA/Net Interest</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {data.eBITDANetInterestPercent !== null
                            ? Utils.valueFormater(data.eBITDANetInterestPercent)
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th onClick={(e) => this.getDataInGraphicalForm()}>
                      <span className="">
                        {" "}
                        EBITDA/(Total Interest Exp + Pfd Div)
                      </span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "EBITDA/(Total Interest Exp + Pfd Div)"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {data.eBITDATotalInterestExpPfdDivPercent !== null
                            ? Utils.valueFormater(
                                data.eBITDATotalInterestExpPfdDivPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>EBITDA/Dividends on Com Shares</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {data.eBITDADividendsonComSharesPercent !== null
                            ? Utils.valueFormater(
                                data.eBITDADividendsonComSharesPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>EBITDA/(Dividends on Com + Pfd)</th>
                    {this.state.fixedChargeCoverageData.map((data) => {
                      return (
                        <td>
                          {data.eBITDADividendsonComPfdPercent !== null
                            ? Utils.valueFormater(
                                data.eBITDADividendsonComPfdPercent
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Fixed Charges Coverage
                    </Label>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
