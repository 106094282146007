import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./FinancialIncomeStatement.css";
export default class FinancialIncomeStatement extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      render: false,
      modalHeader: "",
    };
  }
  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  Case(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Net Sales or Revenues") {
      this.setState({
        graphDetails: {
          graphValues: this.state.netSalesOrRevenues,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales or Revenues",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Income - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.interestIncomeTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Income - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investment Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investmentIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherOperatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Gross Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.grossIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Gross Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Interest Expense – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.interestExpenseTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Interest Expense – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Provision for Loan Losses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.provisionForLoanLosses,
          labelData: this.state.LabelList,
          yAxisTitle: "Provision for Loan Losses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Operating Expenses") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherOperatingExpenses,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Operating Expenses",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Expenses - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingExpensesTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Expenses - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Earnings before Interest & Taxes(EBIT)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.earningsBeforeInterestTaxesEBIT,
          labelData: this.state.LabelList,
          yAxisTitle: "Earnings before Interest & Taxes(EBIT)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Pretax Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.pretaxIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Pretax Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Income before Extraordinary Items/Preferred Dividends"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomebeforeExtraordinaryItemsPreferredDividends,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income before Extraordinary Items/Preferred Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Income after Preferred Dividends - available to Common"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.netIncomeafterPreferredDividendsavailabletoCommon,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Income after Preferred Dividends - available to Common",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let investmentIncome = [];
      let otherOperatingIncome = [];
      let grossIncome = [];
      let interestExpenseTotal = [];
      let provisionForLoanLosses = [];
      let otherOperatingExpenses = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.oFinIncomeStmtList;

      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        investmentIncome.push(actualData[i].investmentIncome);
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        grossIncome.push(actualData[i].grossIncome);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        provisionForLoanLosses.push(actualData[i].provisionforLoanLosses);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsBeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].costofGoodsSold = this.convertToMillionaire(
          actualData[i].costofGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      //this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        investmentIncome: investmentIncome,
        otherOperatingIncome: otherOperatingIncome,
        grossIncome: grossIncome,
        interestExpenseTotal: interestExpenseTotal,
        provisionForLoanLosses: provisionForLoanLosses,
        otherOperatingExpenses: otherOperatingExpenses,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let investmentIncome = [];
      let otherOperatingIncome = [];
      let grossIncome = [];
      let interestExpenseTotal = [];
      let provisionForLoanLosses = [];
      let otherOperatingExpenses = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.oFinIncomeStmtPercentChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        investmentIncome.push(actualData[i].investmentIncome);
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        grossIncome.push(actualData[i].grossIncome);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        provisionForLoanLosses.push(actualData[i].provisionforLoanLosses);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsBeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire1(
          actualData[i].interestIncomeTotal
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire1(
          actualData[i].tradingAccountIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire1(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire1(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire1(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire1(
          actualData[i].commissionFees
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].costofGoodsSold = this.convertToMillionaire1(
          actualData[i].costofGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire1(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire1(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire1(
          actualData[i].interestExpenseTotal
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire1(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      //this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Common Size",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        investmentIncome: investmentIncome,
        otherOperatingIncome: otherOperatingIncome,
        grossIncome: grossIncome,
        interestExpenseTotal: interestExpenseTotal,
        provisionForLoanLosses: provisionForLoanLosses,
        otherOperatingExpenses: otherOperatingExpenses,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let investmentIncome = [];
      let otherOperatingIncome = [];
      let grossIncome = [];
      let interestExpenseTotal = [];
      let provisionForLoanLosses = [];
      let otherOperatingExpenses = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.oFinIncomeStmtYoYChangeList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        investmentIncome.push(actualData[i].investmentIncome);
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        grossIncome.push(actualData[i].grossIncome);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        provisionForLoanLosses.push(actualData[i].provisionforLoanLosses);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsBeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire1(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire1(
          actualData[i].interestIncomeTotal
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire1(
          actualData[i].tradingAccountIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire1(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire1(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire1(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire1(
          actualData[i].commissionFees
        );
        actualData[i].investmentIncome = this.convertToMillionaire1(
          actualData[i].investmentIncome
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire1(
          actualData[i].otherOperatingIncome
        );
        actualData[i].costofGoodsSold = this.convertToMillionaire1(
          actualData[i].costofGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire1(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire1(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire1(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire1(
          actualData[i].interestExpenseTotal
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire1(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire1(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire1(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire1(
          actualData[i].operatingIncome
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire1(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire1(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire1(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire1(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire1(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire1(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire1(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire1(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire1(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire1(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire1(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire1(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire1(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire1(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire1(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire1(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements =
          this.convertToMillionaire1(
            actualData[i].preferredDividendRequirements
          );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire1(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      //this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Yr-yr % change",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        investmentIncome: investmentIncome,
        otherOperatingIncome: otherOperatingIncome,
        grossIncome: grossIncome,
        interestExpenseTotal: interestExpenseTotal,
        provisionForLoanLosses: provisionForLoanLosses,
        otherOperatingExpenses: otherOperatingExpenses,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let investmentIncome = [];
      let otherOperatingIncome = [];
      let grossIncome = [];
      let interestExpenseTotal = [];
      let provisionForLoanLosses = [];
      let otherOperatingExpenses = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.oFinIncomeStmt3YearAverageList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        investmentIncome.push(actualData[i].investmentIncome);
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        grossIncome.push(actualData[i].grossIncome);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        provisionForLoanLosses.push(actualData[i].provisionforLoanLosses);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsBeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].costofGoodsSold = this.convertToMillionaire(
          actualData[i].costofGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      //this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: "Income Statement -3 yr Averages",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        investmentIncome: investmentIncome,
        otherOperatingIncome: otherOperatingIncome,
        grossIncome: grossIncome,
        interestExpenseTotal: interestExpenseTotal,
        provisionForLoanLosses: provisionForLoanLosses,
        otherOperatingExpenses: otherOperatingExpenses,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    } else {
      this.actualData = [];
      let LabelList = [];
      let netSalesOrRevenues = [];
      let interestIncomeTotal = [];
      let investmentIncome = [];
      let otherOperatingIncome = [];
      let grossIncome = [];
      let interestExpenseTotal = [];
      let provisionForLoanLosses = [];
      let otherOperatingExpenses = [];
      let operatingExpensesTotal = [];
      let operatingIncome = [];
      let earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = [];
      let earningsBeforeInterestTaxesEBIT = [];
      let pretaxIncome = [];
      let netIncomebeforeExtraordinaryItemsPreferredDividends = [];
      let netIncomeafterPreferredDividendsavailabletoCommon = [];
      let actualData = this.state.data.oFinIncomeStmtList;
      if (actualData.length > 0) {
        actualData.reverse();
      }
      for (let i = 0; i < actualData.length; i++) {
        LabelList.push(actualData[i].fiscalYear);
        netSalesOrRevenues.push(actualData[i].netSalesorRevenues);
        interestIncomeTotal.push(actualData[i].interestIncomeTotal);
        investmentIncome.push(actualData[i].investmentIncome);
        otherOperatingIncome.push(actualData[i].otherOperatingIncome);
        grossIncome.push(actualData[i].grossIncome);
        interestExpenseTotal.push(actualData[i].interestExpenseTotal);
        provisionForLoanLosses.push(actualData[i].provisionforLoanLosses);
        otherOperatingExpenses.push(actualData[i].otherOperatingExpenses);
        operatingExpensesTotal.push(actualData[i].operatingExpensesTotal);
        operatingIncome.push(actualData[i].operatingIncome);
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA.push(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        earningsBeforeInterestTaxesEBIT.push(
          actualData[i].earningsBeforeInterestTaxesEBIT
        );
        pretaxIncome.push(actualData[i].pretaxIncome);
        netIncomebeforeExtraordinaryItemsPreferredDividends.push(
          actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
        );
        netIncomeafterPreferredDividendsavailabletoCommon.push(
          actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
        );
        actualData[i].netSalesorRevenues = this.convertToMillionaire(
          actualData[i].netSalesorRevenues
        );

        actualData[i].interestIncomeTotal = this.convertToMillionaire(
          actualData[i].interestIncomeTotal
        );
        actualData[i].tradingAccountIncome = this.convertToMillionaire(
          actualData[i].tradingAccountIncome
        );
        actualData[i].foreignExchangeIncome = this.convertToMillionaire(
          actualData[i].foreignExchangeIncome
        );
        actualData[i].trustsFiduciaryIncomeCommissionFees =
          this.convertToMillionaire(
            actualData[i].trustsFiduciaryIncomeCommissionFees
          );
        actualData[i].trustIncome = this.convertToMillionaire(
          actualData[i].trustIncome
        );
        actualData[i].commissionFees = this.convertToMillionaire(
          actualData[i].commissionFees
        );
        actualData[i].investmentIncome = this.convertToMillionaire(
          actualData[i].investmentIncome
        );
        actualData[i].otherOperatingIncome = this.convertToMillionaire(
          actualData[i].otherOperatingIncome
        );
        actualData[i].costofGoodsSold = this.convertToMillionaire(
          actualData[i].costofGoodsSold
        );
        actualData[i].depreciationDepletionAmortization =
          this.convertToMillionaire(
            actualData[i].depreciationDepletionAmortization
          );
        actualData[i].grossIncome = this.convertToMillionaire(
          actualData[i].grossIncome
        );
        actualData[i].sellingGeneralAdministrativeExpenses =
          this.convertToMillionaire(
            actualData[i].sellingGeneralAdministrativeExpenses
          );
        actualData[i].interestExpenseTotal = this.convertToMillionaire(
          actualData[i].interestExpenseTotal
        );
        actualData[i].provisionforLoanLosses = this.convertToMillionaire(
          actualData[i].provisionforLoanLosses
        );
        actualData[i].otherOperatingExpenses = this.convertToMillionaire(
          actualData[i].otherOperatingExpenses
        );
        actualData[i].operatingExpensesTotal = this.convertToMillionaire(
          actualData[i].operatingExpensesTotal
        );
        actualData[i].operatingIncome = this.convertToMillionaire(
          actualData[i].operatingIncome
        );
        actualData[i].nonOperatingInterestIncome = this.convertToMillionaire(
          actualData[i].nonOperatingInterestIncome
        );
        actualData[i].pretaxEquityinEarnings = this.convertToMillionaire(
          actualData[i].pretaxEquityinEarnings
        );
        actualData[i].extraordinaryCreditPretax = this.convertToMillionaire(
          actualData[i].extraordinaryCreditPretax
        );
        actualData[i].extraordinaryChargePretax = this.convertToMillionaire(
          actualData[i].extraordinaryChargePretax
        );
        actualData[i].otherIncomeExpenseNet = this.convertToMillionaire(
          actualData[i].otherIncomeExpenseNet
        );
        actualData[i].reservesIncreaseDecrease = this.convertToMillionaire(
          actualData[i].reservesIncreaseDecrease
        );
        actualData[
          i
        ].earningsbeforeInterestTaxesDepreciationAmortizationEBITDA = this.convertToMillionaire(
          actualData[i]
            .earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
        );
        actualData[i].earningsbeforeInterestTaxesEBIT =
          this.convertToMillionaire(
            actualData[i].earningsbeforeInterestTaxesEBIT
          );
        actualData[i].pretaxIncome = this.convertToMillionaire(
          actualData[i].pretaxIncome
        );
        actualData[i].incomeTaxes = this.convertToMillionaire(
          actualData[i].incomeTaxes
        );
        actualData[i].minorityInterest = this.convertToMillionaire(
          actualData[i].minorityInterest
        );
        actualData[i].equityinEarnings = this.convertToMillionaire(
          actualData[i].equityinEarnings
        );
        actualData[i].afterTaxOtherIncomeExpense = this.convertToMillionaire(
          actualData[i].afterTaxOtherIncomeExpense
        );
        actualData[i].discontinuedOperations = this.convertToMillionaire(
          actualData[i].discontinuedOperations
        );
        actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends =
          this.convertToMillionaire(
            actualData[i].netIncomebeforeExtraordinaryItemsPreferredDividends
          );
        actualData[i].extraordinaryItemsGainLossSaleofAssets =
          this.convertToMillionaire(
            actualData[i].extraordinaryItemsGainLossSaleofAssets
          );
        actualData[i].preferredDividendRequirements = this.convertToMillionaire(
          actualData[i].preferredDividendRequirements
        );
        actualData[i].netIncomeafterPreferredDividendsavailabletoCommon =
          this.convertToMillionaire(
            actualData[i].netIncomeafterPreferredDividendsavailabletoCommon
          );
      }
      //this.actualData = actualData;
      this.setState({
        render: true,
        modalHeader: " Income Statement -Actual ",
        actualData: actualData,
        LabelList: LabelList,
        netSalesOrRevenues: netSalesOrRevenues,
        interestIncomeTotal: interestIncomeTotal,
        investmentIncome: investmentIncome,
        otherOperatingIncome: otherOperatingIncome,
        grossIncome: grossIncome,
        interestExpenseTotal: interestExpenseTotal,
        provisionForLoanLosses: provisionForLoanLosses,
        otherOperatingExpenses: otherOperatingExpenses,
        operatingExpensesTotal: operatingExpensesTotal,
        operatingIncome: operatingIncome,
        earningsbeforeInterestTaxesDepreciationAmortizationEBITDA:
          earningsbeforeInterestTaxesDepreciationAmortizationEBITDA,
        earningsBeforeInterestTaxesEBIT: earningsBeforeInterestTaxesEBIT,
        pretaxIncome: pretaxIncome,
        netIncomebeforeExtraordinaryItemsPreferredDividends:
          netIncomebeforeExtraordinaryItemsPreferredDividends,
        netIncomeafterPreferredDividendsavailabletoCommon:
          netIncomeafterPreferredDividendsavailabletoCommon,
      });
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-2em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <span className="unit_str">
                All figures in <span>{this.toTitleCase(this.state.unit)}</span>{" "}
                of {this.state.currency}
              </span>
              <Table responsive className="financial_analysis1">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Net Sales or Revenues</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestIncomeTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trading Account Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.tradingAccountIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Foreign Exchange Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.foreignExchangeIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trusts & Fiduciary Income/Commission & Fees</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.trustsFiduciaryIncomeCommissionFees}</td>;
                  })}
                </tr>
                <tr>
                  <th>Trust Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.trustIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Commission & Fees</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.commissionFees}</td>;
                  })}
                </tr>
                <tr>
                  <th>Investment Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.investmentIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Cost of Goods Sold</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.costofGoodsSold}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation, Depletion & Amortization</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.depreciationDepletionAmortization}</td>;
                  })}
                </tr>
                <tr>
                  <th>Gross Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.grossIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Selling, General & Administrative Expenses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.sellingGeneralAdministrativeExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Interest Expense – Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.interestExpenseTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Provision for Loan Losses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.provisionforLoanLosses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Operating Expenses</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherOperatingExpenses}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Expenses - Total</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingExpensesTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Operating Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.operatingIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Non-Operating Interest Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.nonOperatingInterestIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxEquityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Credit - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryCreditPretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Charge - Pretax</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.extraordinaryChargePretax}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Income/Expense - Net</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.otherIncomeExpenseNet}</td>;
                  })}
                </tr>
                <tr>
                  <th>Reserves - Increase/Decrease </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.reservesIncreaseDecrease}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Earnings before Interest, Taxes, Depreciation & Amortization
                    (EBITDA)
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {
                          data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Earnings before Interest & Taxes (EBIT)</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                  })}
                </tr>
                <tr>
                  <th>Pretax Income</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.pretaxIncome}</td>;
                  })}
                </tr>
                <tr>
                  <th>Income Taxes </th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.incomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Minority Interest</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.minorityInterest}</td>;
                  })}
                </tr>
                <tr>
                  <th>Equity in Earnings</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.equityinEarnings}</td>;
                  })}
                </tr>
                <tr>
                  <th>After Tax Other Income/Expense</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.afterTaxOtherIncomeExpense}</td>;
                  })}
                </tr>
                <tr>
                  <th>Discontinued Operations</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.discontinuedOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income before Extraordinary Items/Preferred Dividends
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {
                          data.netIncomebeforeExtraordinaryItemsPreferredDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividend Requirements</th>
                  {this.state.actualData.map((data) => {
                    return <td>{data.preferredDividendRequirements}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Income after Preferred Dividends - available to Common
                  </th>
                  {this.state.actualData.map((data) => {
                    return (
                      <td>
                        {data.netIncomeafterPreferredDividendsavailabletoCommon}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                <span className="unit_str">
                  All figures in{" "}
                  <span>
                    {this.toTitleCase(
                      this.state.unit !== null ? this.state.unit : ""
                    )}
                  </span>{" "}
                  of {this.state.currency}
                </span>
                <Table responsive className="financial_analysis1">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Sales or Revenues
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales or Revenues")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netSalesorRevenues}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>
                      Interest Income - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Interest Income - Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestIncomeTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Trading Account Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.tradingAccountIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Foreign Exchange Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.foreignExchangeIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Trusts & Fiduciary Income/Commission & Fees</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.trustsFiduciaryIncomeCommissionFees}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Trust Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.trustIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Commission & Fees</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.commissionFees}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Investment Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investment Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.investmentIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Operating Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Cost of Goods Sold</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.costofGoodsSold}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation, Depletion & Amortization</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.depreciationDepletionAmortization}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Gross Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Gross Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.grossIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Selling, General & Administrative Expenses</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.sellingGeneralAdministrativeExpenses}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Interest Expense – Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Interest Expense – Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.interestExpenseTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Provision for Loan Losses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Provision for Loan Losses"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.provisionforLoanLosses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Operating Expenses
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Operating Expenses"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherOperatingExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Operating Expenses - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Operating Expenses - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingExpensesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Operating Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.operatingIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Operating Interest Income</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.nonOperatingInterestIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Pretax Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxEquityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Credit - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryCreditPretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Charge - Pretax</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.extraordinaryChargePretax}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Income/Expense - Net</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.otherIncomeExpenseNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Reserves - Increase/Decrease </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.reservesIncreaseDecrease}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Earnings before Interest, Taxes, Depreciation &
                      Amortization (EBITDA)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Earnings before Interest, Taxes, Depreciation & Amortization (EBITDA)"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.earningsbeforeInterestTaxesDepreciationAmortizationEBITDA
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Earnings before Interest & Taxes (EBIT)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Earnings before Interest & Taxes(EBIT)"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.earningsbeforeInterestTaxesEBIT}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Pretax Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Pretax Income")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.pretaxIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Income Taxes </th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.incomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Equity in Earnings</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.equityinEarnings}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>After Tax Other Income/Expense</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.afterTaxOtherIncomeExpense}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Discontinued Operations</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.discontinuedOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income before Extraordinary Items/Preferred Dividends
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income before Extraordinary Items/Preferred Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomebeforeExtraordinaryItemsPreferredDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items & Gain/Loss Sale of Assets</th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>{data.extraordinaryItemsGainLossSaleofAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividend Requirements</th>
                    {this.state.actualData.map((data) => {
                      return <td>{data.preferredDividendRequirements}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Income after Preferred Dividends - available to Common
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Income after Preferred Dividends - available to Common"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.actualData.map((data) => {
                      return (
                        <td>
                          {
                            data.netIncomeafterPreferredDividendsavailabletoCommon
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
