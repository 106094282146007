import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./Ratios.css";
export default class Ratios extends Component {
  country = "";
  currency = "";
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      type: props.type,
      data: props.data,
      cusip: props.cusip,
      flowOfFundsData: [],
      render: false,
      graphDetails: {},
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }
  getDataInGraphicalForm(graphType) {
    if (
      graphType ===
      "Cash Dividends Coverage Ratio: Funds from Operations / Cash Dividends"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .CashDividendsCoverageRatioFundsfromOperationsCashDividends,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Cash Dividends Coverage Ratio: Funds from Operations / Cash Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cost of Goods Sold / Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CostOfGoodsSoldFundsFromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Cost of Goods Sold / Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Accumulated DDA / Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AccumulatedDDAFundsFromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Accumulated DDA / Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Current Liabilities Coverage Ratio: Funds from Operations / Total Current Liabilities"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Current Liabilities Coverage Ratio: Funds from Operations / Total Current Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Adjusted Current Liabilities Coverage Ratio: [Funds from Operation - Dividends] /Total Current Liabilities"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Adjusted Current Liabilities Coverage Ratio: [Funds from Operation - Dividends] /Total Current Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Interest Coverage Ratio: [Cash Flow from Operations + Interest Paid + Taxes] / Interest Paid]"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Interest Coverage Ratio: [Cash Flow from Operations + Interest Paid + Taxes] / Interest Paid]",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Comprehensive Interest Coverage Ratio: [Free Cash Flow + Interest Paid + Taxes] / Interest Paid]"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Comprehensive Interest Coverage Ratio: [Free Cash Flow + Interest Paid + Taxes] / Interest Paid]",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "External Financing Index Ratio : Cash from Financing / Funds from Operations"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations,
          labelData: this.state.LabelList,
          yAxisTitle:
            "External Financing Index Ratio : Cash from Financing / Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Flow - Operating Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashFlowOperatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Flow - Operating Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Flow - Financing Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashFlowFinancingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Flow - Financing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Flow - Investing Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashFlowInvestingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Flow - Investing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Cash Flow Management: Financing Cash Flow / Investing Cash Flow"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.CashFlowManagementFinancingCashFlowInvestingCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Cash Flow Management: Financing Cash Flow / Investing Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Free Cash Flow : Funds from Operations - Capital Expenditures"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.FreeCashFlowFundsfromOperationsCapitalExpenditures,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Free Cash Flow : Funds from Operations - Capital Expenditures",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow - Year to Year % Change") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowYeartoYearPercentChange,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow - Year to Year % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Unlevered Free Cash Flow: EBITDA - Capital Expenditure - Working Capital - Taxes"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Unlevered Free Cash Flow: EBITDA - Capital Expenditure - Working Capital - Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unlevered Free Cash Flow % Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnleveredFreeCashFlowPercentTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Unlevered Free Cash Flow % Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unlevered Free Cash Flow % Enterprise Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnleveredFreeCashFlowPercentEnterpriseValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Unlevered Free Cash Flow % Enterprise Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unlevered Free Cash Flow % Shareholders Equity") {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.UnleveredFreeCashFlowPercentShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Unlevered Free Cash Flow % Shareholders Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Levered Free Cash Flow") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LeveredFreeCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Levered Free Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Levered Free Cash Flow % Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LeveredFreeCashFlowPercentTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Levered Free Cash Flow % Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Levered Free Cash Flow % Enterprise Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LeveredFreeCashFlowPercentEnterpriseValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Levered Free Cash Flow % Enterprise Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Levered Free Cash Flow % Shareholders Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LeveredFreeCashFlowPercentShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Levered Free Cash Flow % Shareholders Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Levered Free Cash Flow") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentLeveredFreeCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Levered Free Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Unlevered Free Cash Flow") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentUnleveredFreeCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Unlevered Free Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Levered Free Cash Flow % Unlevered Free Cash Flow"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.LeveredFreeCashFlowPercentUnleveredFreeCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Levered Free Cash Flow % Unlevered Free Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Short-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentShortTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Short-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Long-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentFundsFromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentNetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentOperatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentTotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Market Capitalization") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentMarketCapitalization,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Market Capitalization",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Enterprise Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentEnterpriseValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Enterprise Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Free Cash Flow % Common Shareholders' Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FreeCashFlowPercentCommonShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Free Cash Flow % Common Shareholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Absolute Free Cash Flow - Year to Year % Change"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowYeartoYearPercentChange,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow - Year to Year % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Short-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentShortTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Short-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Long-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Operating Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentOperatingIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Operating Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentTotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentNetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Absolute Free Cash Flow % Funds from Operations"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.AbsoluteFreeCashFlowPercentFundsfromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Absolute Free Cash Flow % Market Capitalization"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.AbsoluteFreeCashFlowPercentMarketCapitalization,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Market Capitalization",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Absolute Free Cash Flow % Enterprise Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AbsoluteFreeCashFlowPercentEnterpriseValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Enterprise Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Absolute Free Cash Flow % Common Shareholders' Equity"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.AbsoluteFreeCashFlowPercentCommonShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Absolute Free Cash Flow % Common Shareholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Flow Ratio") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashFlowRatio,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Flow Ratio",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Market Capitalization") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MarketCapitalization,
          labelData: this.state.LabelList,
          yAxisTitle: "Market Capitalization",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Market Capitalization - Year to Year % Change") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MarketCapitalizationYeartoYearPercentChange,
          labelData: this.state.LabelList,
          yAxisTitle: "Market Capitalization - Year to Year % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Sales - Year to Year % Change") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetSalesYeartoYearPercentChange,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales - Year to Year % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations - Year to Year % Change") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsfromOperationsYeartoYearPercentChange,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations - Year to Year % Change",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Short-Term Debt Coverage Ratio : Funds from Operations % Short-Term Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Short-Term Debt Coverage Ratio : Funds from Operations % Short-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Long-Term Debt Coverage Ratio: Funds from Operations % Long-Term Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Long-Term Debt Coverage Ratio: Funds from Operations % Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Adjusted Long-Term Debt Coverage Ratio: [Funds from Operations - Dividends] / Total Long-Term Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Adjusted Long-Term Debt Coverage Ratio: [Funds from Operations - Dividends] / Total Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Total Debt Coverage Ratio: Funds From Operations % Total Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Total Debt Coverage Ratio: Funds From Operations % Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType ===
      "Adjusted Total Debt Coverage Ratio: [Funds From Operations - Dividends] % Total Debt"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state
              .AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Adjusted Total Debt Coverage Ratio: [Funds From Operations - Dividends] % Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Dividends % Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashDividendsPercentFundsfromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Dividends % Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Sales % Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetSalesPercentFundsfromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales % Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Stock Price / Cash Flow per Share") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonStockPriceCashFlowperShare,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Stock Price / Cash Flow per Share",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Stock Price / Earnings per Share") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonStockPriceEarningsPerShare,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Stock Price / Earnings per Share",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Earnings per Share / Cash Flow per Share") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EarningsperShareCashFlowPerShare,
          labelData: this.state.LabelList,
          yAxisTitle: "Earnings per Share / Cash Flow per Share",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentTotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Long-Term Borrowing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentLongTermBorrowing,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Long-Term Borrowing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Capital Expenditures") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentCapitalExpenditures,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Capital Expenditures",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Cash Dividends Paid") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentCashDividendsPaid,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Cash Dividends Paid",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentNetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Total Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentTotalCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Total Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Working Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentWorkingCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Working Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Funds From Operations % Property, Plant & Equipment"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.FundsFromOperationsPercentPropertyPlantAndEquipment,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Property, Plant & Equipment",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Funds From Operations % Reasearch & Development"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.FundsFromOperationsPercentReasearchAndDevelopment,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Reasearch & Development",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Provision for Bad Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentProvisionforBadDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Provision for Bad Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Market Capitalization") {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.FundsFromOperationsPercentMarketCapitalization,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Market Capitalization",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations % Enterprise Value") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsPercentEnterpriseValue,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Enterprise Value",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Funds From Operations % Common Shareholders Equity"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.FundsFromOperationsPercentCommonShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations % Common Shareholders Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations / Fixed Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsFixedCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations / Fixed Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations / DDA") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsDDA,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations / DDA",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsfromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }

  componentDidMount() {
    if (this.state.isPdfReport === true) {
      const postObject = { cusip: this.state.cusip };
      FetchServerData.callPostService("flowOfFunds/fofRatios", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            this.currency = data.currency;
            this.country = data.country;
            let LabelList = [];
            let CashDividendsCoverageRatioFundsfromOperationsCashDividends = [];
            let CostOfGoodsSoldFundsFromOperations = [];
            let AccumulatedDDAFundsFromOperations = [];
            let CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities =
              [];
            let AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities =
              [];
            let interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid =
              [];
            let ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid =
              [];
            let ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations =
              [];
            let CashFlowOperatingActivities = [];
            let CashFlowFinancingActivities = [];
            let CashFlowInvestingActivities = [];
            let CashFlowManagementFinancingCashFlowInvestingCashFlow = [];
            let FreeCashFlowFundsfromOperationsCapitalExpenditures = [];
            let FreeCashFlowYeartoYearPercentChange = [];
            let UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes =
              [];
            let UnleveredFreeCashFlowPercentTotalAssets = [];
            let UnleveredFreeCashFlowPercentEnterpriseValue = [];
            let UnleveredFreeCashFlowPercentShareholdersEquity = [];
            let LeveredFreeCashFlow = [];
            let LeveredFreeCashFlowPercentTotalAssets = [];
            let LeveredFreeCashFlowPercentEnterpriseValue = [];
            let LeveredFreeCashFlowPercentShareholdersEquity = [];
            let FreeCashFlowPercentLeveredFreeCashFlow = [];
            let FreeCashFlowPercentUnleveredFreeCashFlow = [];
            let LeveredFreeCashFlowPercentUnleveredFreeCashFlow = [];
            let FreeCashFlowPercentShortTermDebt = [];
            let FreeCashFlowPercentLongTermDebt = [];
            let FreeCashFlowPercentFundsFromOperations = [];
            let FreeCashFlowPercentNetSales = [];
            let FreeCashFlowPercentTotalAssets = [];
            let FreeCashFlowPercentOperatingIncome = [];
            let FreeCashFlowPercentTotalLiabilities = [];
            let FreeCashFlowPercentMarketCapitalization = [];
            let FreeCashFlowPercentEnterpriseValue = [];
            let FreeCashFlowPercentCommonShareholdersEquity = [];
            let AbsoluteFreeCashFlow = [];
            let AbsoluteFreeCashFlowYeartoYearPercentChange = [];
            let AbsoluteFreeCashFlowPercentShortTermDebt = [];
            let AbsoluteFreeCashFlowPercentLongTermDebt = [];
            let AbsoluteFreeCashFlowPercentOperatingIncome = [];
            let AbsoluteFreeCashFlowPercentTotalLiabilities = [];

            let AbsoluteFreeCashFlowPercentNetSales = [];
            let AbsoluteFreeCashFlowPercentTotalAssets = [];
            let AbsoluteFreeCashFlowPercentFundsfromOperations = [];
            let AbsoluteFreeCashFlowPercentMarketCapitalization = [];
            let AbsoluteFreeCashFlowPercentEnterpriseValue = [];
            let AbsoluteFreeCashFlowPercentCommonShareholdersEquity = [];
            let CashFlowRatio = [];
            let MarketCapitalization = [];
            let MarketCapitalizationYeartoYearPercentChange = [];
            let NetSalesYeartoYearPercentChange = [];
            let FundsfromOperationsYeartoYearPercentChange = [];
            let ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt =
              [];
            let LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt =
              [];
            let AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt =
              [];
            let TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt = [];
            let AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt =
              [];
            let CashDividendsPercentFundsfromOperations = [];
            let NetSalesPercentFundsfromOperations = [];
            let CommonStockPriceCashFlowperShare = [];
            let CommonStockPriceEarningsPerShare = [];
            let EarningsperShareCashFlowPerShare = [];
            let FundsFromOperationsPercentTotalLiabilities = [];
            let FundsFromOperationsPercentLongTermBorrowing = [];
            let FundsFromOperationsPercentCapitalExpenditures = [];
            let FundsFromOperationsPercentCashDividendsPaid = [];
            let FundsFromOperationsPercentNetSales = [];
            let FundsFromOperationsPercentTotalAssets = [];
            let FundsFromOperationsPercentTotalCurrentAssets = [];
            let FundsFromOperationsPercentWorkingCapital = [];
            let FundsFromOperationsPercentPropertyPlantAndEquipment = [];
            let FundsFromOperationsPercentReasearchAndDevelopment = [];
            let FundsFromOperationsPercentTotalCapital = [];
            let FundsFromOperationsPercentProvisionforBadDebt = [];
            let FundsFromOperationsPercentMarketCapitalization = [];
            let FundsFromOperationsPercentEnterpriseValue = [];
            let FundsFromOperationsPercentCommonShareholdersEquity = [];
            let FundsFromOperationsFixedCharges = [];
            let FundsFromOperationsDDA = [];
            let NetSales = [];
            let FundsfromOperations = [];
            let flowOfFundsData = data.fOFRatioList;
            if (flowOfFundsData.length > 0) {
             // flowOfFundsData.reverse();
            }
            for (let i = 0; i < flowOfFundsData.length; i++) {
              LabelList.push(flowOfFundsData[i].year);
              CashDividendsCoverageRatioFundsfromOperationsCashDividends.push(
                flowOfFundsData[i]
                  .cashDividendsCoverageRatioFundsfromOperationsCashDividends
              );
              CostOfGoodsSoldFundsFromOperations.push(
                flowOfFundsData[i].costofGoodsSoldFundsfromOperations
              );
              AccumulatedDDAFundsFromOperations.push(
                flowOfFundsData[i].accumulatedDDAFundsfromOperations
              );
              CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities.push(
                flowOfFundsData[i]
                  .currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
              );
              AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities.push(
                flowOfFundsData[i]
                  .adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
              );
              interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid.push(
                flowOfFundsData[i]
                  .interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid
              );
              ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid.push(
                flowOfFundsData[i]
                  .comprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid
              );
              ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations.push(
                flowOfFundsData[i]
                  .externalFinancingIndexRatioCashfromFinancingFundsfromOperations
              );
              CashFlowOperatingActivities.push(
                flowOfFundsData[i].cashFlowOperatingActivities
              );
              CashFlowFinancingActivities.push(
                flowOfFundsData[i].cashFlowFinancingActivities
              );
              CashFlowInvestingActivities.push(
                flowOfFundsData[i].cashFlowInvestingActivities
              );
              CashFlowManagementFinancingCashFlowInvestingCashFlow.push(
                flowOfFundsData[i]
                  .cashFlowManagementFinancingCashFlowInvestingCashFlow
              );
              FreeCashFlowFundsfromOperationsCapitalExpenditures.push(
                flowOfFundsData[i]
                  .freeCashFlowFundsfromOperationsCapitalExpenditures
              );
              FreeCashFlowYeartoYearPercentChange.push(
                flowOfFundsData[i].freeCashFlowYeartoYearPercentChange
              );
              UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes.push(
                flowOfFundsData[i]
                  .unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
              );
              UnleveredFreeCashFlowPercentTotalAssets.push(
                flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets
              );
              UnleveredFreeCashFlowPercentEnterpriseValue.push(
                flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue
              );
              UnleveredFreeCashFlowPercentShareholdersEquity.push(
                flowOfFundsData[i]
                  .unleveredFreeCashFlowPercentShareholdersEquity
              );
              LeveredFreeCashFlow.push(flowOfFundsData[i].leveredFreeCashFlow);
              LeveredFreeCashFlowPercentTotalAssets.push(
                flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets
              );
              LeveredFreeCashFlowPercentEnterpriseValue.push(
                flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue
              );
              LeveredFreeCashFlowPercentShareholdersEquity.push(
                flowOfFundsData[i].leveredFreeCashFlowPercentShareholdersEquity
              );
              FreeCashFlowPercentLeveredFreeCashFlow.push(
                flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow
              );
              FreeCashFlowPercentUnleveredFreeCashFlow.push(
                flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow
              );
              LeveredFreeCashFlowPercentUnleveredFreeCashFlow.push(
                flowOfFundsData[i]
                  .leveredFreeCashFlowPercentUnleveredFreeCashFlow
              );
              FreeCashFlowPercentShortTermDebt.push(
                flowOfFundsData[i].freeCashFlowPercentShortTermDebt
              );
              FreeCashFlowPercentLongTermDebt.push(
                flowOfFundsData[i].freeCashFlowPercentLongTermDebt
              );
              FreeCashFlowPercentFundsFromOperations.push(
                flowOfFundsData[i].freeCashFlowPercentFundsfromOperations
              );
              FreeCashFlowPercentNetSales.push(
                flowOfFundsData[i].freeCashFlowPercentNetSales
              );
              FreeCashFlowPercentTotalAssets.push(
                flowOfFundsData[i].freeCashFlowPercentTotalAssets
              );
              FreeCashFlowPercentOperatingIncome.push(
                flowOfFundsData[i].freeCashFlowPercentOperatingIncome
              );
              FreeCashFlowPercentTotalLiabilities.push(
                flowOfFundsData[i].freeCashFlowPercentTotalLiabilities
              );
              FreeCashFlowPercentMarketCapitalization.push(
                flowOfFundsData[i].freeCashFlowPercentMarketCapitalization
              );
              FreeCashFlowPercentEnterpriseValue.push(
                flowOfFundsData[i].freeCashFlowPercentEnterpriseValue
              );
              FreeCashFlowPercentCommonShareholdersEquity.push(
                flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity
              );
              AbsoluteFreeCashFlow.push(
                flowOfFundsData[i].absoluteFreeCashFlow
              );
              AbsoluteFreeCashFlowYeartoYearPercentChange.push(
                flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange
              );
              AbsoluteFreeCashFlowPercentShortTermDebt.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentShortTermDebt
              );
              AbsoluteFreeCashFlowPercentLongTermDebt.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentLongTermDebt
              );
              AbsoluteFreeCashFlowPercentOperatingIncome.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome
              );
              AbsoluteFreeCashFlowPercentTotalLiabilities.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities
              );

              AbsoluteFreeCashFlowPercentNetSales.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales
              );
              AbsoluteFreeCashFlowPercentTotalAssets.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets
              );
              AbsoluteFreeCashFlowPercentFundsfromOperations.push(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentFundsfromOperations
              );
              AbsoluteFreeCashFlowPercentMarketCapitalization.push(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentMarketCapitalization
              );
              AbsoluteFreeCashFlowPercentEnterpriseValue.push(
                flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue
              );
              AbsoluteFreeCashFlowPercentCommonShareholdersEquity.push(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentCommonShareholdersEquity
              );
              CashFlowRatio.push(flowOfFundsData[i].cashFlowRatio);
              MarketCapitalization.push(
                flowOfFundsData[i].marketCapitalization
              );
              MarketCapitalizationYeartoYearPercentChange.push(
                flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange
              );
              NetSalesYeartoYearPercentChange.push(
                flowOfFundsData[i].netSalesYeartoYearPercentChange
              );
              FundsfromOperationsYeartoYearPercentChange.push(
                flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange
              );
              ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt.push(
                flowOfFundsData[i]
                  .shortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt
              );
              LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt.push(
                flowOfFundsData[i]
                  .longTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt
              );
              AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt.push(
                flowOfFundsData[i]
                  .adjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt
              );
              TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt.push(
                flowOfFundsData[i]
                  .totalDebtCoverageRatioFundsFromOperationsPercentTotalDebt
              );
              AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt.push(
                flowOfFundsData[i]
                  .adjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt
              );
              CashDividendsPercentFundsfromOperations.push(
                flowOfFundsData[i].cashDividendsPercentFundsfromOperations
              );
              NetSalesPercentFundsfromOperations.push(
                flowOfFundsData[i].netSalesPercentFundsfromOperations
              );
              CommonStockPriceCashFlowperShare.push(
                flowOfFundsData[i].commonStockPriceCashFlowperShare
              );
              CommonStockPriceEarningsPerShare.push(
                flowOfFundsData[i].commonStockPriceEarningsperShare
              );
              EarningsperShareCashFlowPerShare.push(
                flowOfFundsData[i].earningsperShareCashFlowperShare
              );
              FundsFromOperationsPercentTotalLiabilities.push(
                flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities
              );
              FundsFromOperationsPercentLongTermBorrowing.push(
                flowOfFundsData[i].fundsFromOperationsPercentLongTermBorrowing
              );
              FundsFromOperationsPercentCapitalExpenditures.push(
                flowOfFundsData[i].fundsFromOperationsPercentCapitalExpenditures
              );
              FundsFromOperationsPercentCashDividendsPaid.push(
                flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid
              );
              FundsFromOperationsPercentNetSales.push(
                flowOfFundsData[i].fundsFromOperationsPercentNetSales
              );
              FundsFromOperationsPercentTotalAssets.push(
                flowOfFundsData[i].fundsFromOperationsPercentTotalAssets
              );
              FundsFromOperationsPercentTotalCurrentAssets.push(
                flowOfFundsData[i].fundsFromOperationsPercentTotalCurrentAssets
              );
              FundsFromOperationsPercentWorkingCapital.push(
                flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital
              );
              FundsFromOperationsPercentPropertyPlantAndEquipment.push(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentPropertyPlantAndEquipment
              );
              FundsFromOperationsPercentReasearchAndDevelopment.push(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentReasearchAndDevelopment
              );
              FundsFromOperationsPercentTotalCapital.push(
                flowOfFundsData[i].fundsFromOperationsPercentTotalCapital
              );
              FundsFromOperationsPercentProvisionforBadDebt.push(
                flowOfFundsData[i].fundsFromOperationsPercentProvisionforBadDebt
              );
              FundsFromOperationsPercentMarketCapitalization.push(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentMarketCapitalization
              );
              FundsFromOperationsPercentEnterpriseValue.push(
                flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue
              );
              FundsFromOperationsPercentCommonShareholdersEquity.push(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentCommonShareholdersEquity
              );
              FundsFromOperationsFixedCharges.push(
                flowOfFundsData[i].fundsFromOperationsFixedCharges
              );
              FundsFromOperationsDDA.push(
                flowOfFundsData[i].fundsFromOperationsDDA
              );
              NetSales.push(flowOfFundsData[i].netSales);
              FundsfromOperations.push(flowOfFundsData[i].fundsfromOperations);
              flowOfFundsData[
                i
              ].cashDividendsCoverageRatioFundsfromOperationsCashDividends = this.convertToMillionaire(
                flowOfFundsData[i]
                  .cashDividendsCoverageRatioFundsfromOperationsCashDividends
              );
              flowOfFundsData[i].costofGoodsSoldFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].costofGoodsSoldFundsfromOperations
                );
              flowOfFundsData[i].accumulatedDDAFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].accumulatedDDAFundsfromOperations
                );
              flowOfFundsData[
                i
              ].currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
                );
              flowOfFundsData[
                i
              ].adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
                );
              flowOfFundsData[
                i
              ].externalFinancingIndexRatioCashfromFinancingFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .externalFinancingIndexRatioCashfromFinancingFundsfromOperations
                );
              flowOfFundsData[i].cashFlowOperatingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashFlowOperatingActivities
                );
              flowOfFundsData[i].cashFlowFinancingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashFlowFinancingActivities
                );
              flowOfFundsData[i].cashFlowInvestingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashFlowInvestingActivities
                );
              flowOfFundsData[
                i
              ].cashFlowManagementFinancingCashFlowInvestingCashFlow = this.convertToMillionaire(
                flowOfFundsData[i]
                  .cashFlowManagementFinancingCashFlowInvestingCashFlow
              );
              flowOfFundsData[
                i
              ].freeCashFlowFundsfromOperationsCapitalExpenditures = this.convertToMillionaire(
                flowOfFundsData[i]
                  .freeCashFlowFundsfromOperationsCapitalExpenditures
              );
              flowOfFundsData[i].freeCashFlowYeartoYearPercentChange =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowYeartoYearPercentChange
                );
              flowOfFundsData[
                i
              ].unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
                );
              flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets
                );
              flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue =
                this.convertToMillionaire(
                  flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue
                );
              flowOfFundsData[
                i
              ].unleveredFreeCashFlowPercentShareholdersEquity = this.convertToMillionaire(
                flowOfFundsData[i]
                  .unleveredFreeCashFlowPercentShareholdersEquity
              );
              flowOfFundsData[i].leveredFreeCashFlow =
                this.convertToMillionaire(
                  flowOfFundsData[i].leveredFreeCashFlow
                );
              flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets
                );
              flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue =
                this.convertToMillionaire(
                  flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue
                );
              flowOfFundsData[i].leveredFreeCashFlowPercentShareholdersEquity =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .leveredFreeCashFlowPercentShareholdersEquity
                );
              flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow
                );
              flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow
                );
              flowOfFundsData[
                i
              ].leveredFreeCashFlowPercentUnleveredFreeCashFlow = this.convertToMillionaire(
                flowOfFundsData[i]
                  .leveredFreeCashFlowPercentUnleveredFreeCashFlow
              );
              flowOfFundsData[i].freeCashFlowPercentFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentFundsfromOperations
                );
              flowOfFundsData[i].freeCashFlowPercentNetSales =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentNetSales
                );
              flowOfFundsData[i].freeCashFlowPercentTotalAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentTotalAssets
                );
              flowOfFundsData[i].freeCashFlowPercentOperatingIncome =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentOperatingIncome
                );
              flowOfFundsData[i].freeCashFlowPercentTotalLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentTotalLiabilities
                );
              flowOfFundsData[i].freeCashFlowPercentMarketCapitalization =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentMarketCapitalization
                );
              flowOfFundsData[i].freeCashFlowPercentEnterpriseValue =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentEnterpriseValue
                );
              flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity =
                this.convertToMillionaire(
                  flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity
                );
              flowOfFundsData[i].absoluteFreeCashFlow =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlow
                );
              flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange
                );

              flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome
                );
              flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities
                );
              flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales
                );
              flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets
                );
              flowOfFundsData[
                i
              ].absoluteFreeCashFlowPercentFundsfromOperations = this.convertToMillionaire(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentFundsfromOperations
              );
              flowOfFundsData[
                i
              ].absoluteFreeCashFlowPercentMarketCapitalization = this.convertToMillionaire(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentMarketCapitalization
              );
              flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue =
                this.convertToMillionaire(
                  flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue
                );
              flowOfFundsData[
                i
              ].absoluteFreeCashFlowPercentCommonShareholdersEquity = this.convertToMillionaire(
                flowOfFundsData[i]
                  .absoluteFreeCashFlowPercentCommonShareholdersEquity
              );
              flowOfFundsData[i].cashFlowRatio = this.convertToMillionaire(
                flowOfFundsData[i].cashFlowRatio
              );
              flowOfFundsData[i].marketCapitalization =
                this.convertToMillionaire(
                  flowOfFundsData[i].marketCapitalization
                );
              flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange =
                this.convertToMillionaire(
                  flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange
                );
              flowOfFundsData[i].netSalesYeartoYearPercentChange =
                this.convertToMillionaire(
                  flowOfFundsData[i].netSalesYeartoYearPercentChange
                );
              flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange
                );

              flowOfFundsData[i].cashDividendsPercentFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashDividendsPercentFundsfromOperations
                );
              flowOfFundsData[i].netSalesPercentFundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].netSalesPercentFundsfromOperations
                );
              flowOfFundsData[i].commonStockPriceCashFlowperShare =
                this.convertToMillionaire(
                  flowOfFundsData[i].commonStockPriceCashFlowperShare
                );
              flowOfFundsData[i].commonStockPriceEarningsperShare =
                this.convertToMillionaire(
                  flowOfFundsData[i].commonStockPriceEarningsperShare
                );
              flowOfFundsData[i].earningsperShareCashFlowperShare =
                this.convertToMillionaire(
                  flowOfFundsData[i].earningsperShareCashFlowperShare
                );
              flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities
                );
              flowOfFundsData[i].fundsFromOperationsPercentCapitalExpenditures =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .fundsFromOperationsPercentCapitalExpenditures
                );
              flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid
                );
              flowOfFundsData[i].fundsFromOperationsPercentNetSales =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentNetSales
                );
              flowOfFundsData[i].fundsFromOperationsPercentTotalAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentTotalAssets
                );
              flowOfFundsData[i].fundsFromOperationsPercentTotalCurrentAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i]
                    .fundsFromOperationsPercentTotalCurrentAssets
                );
              flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital
                );
              flowOfFundsData[
                i
              ].fundsFromOperationsPercentPropertyPlantAndEquipment = this.convertToMillionaire(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentPropertyPlantAndEquipment
              );
              flowOfFundsData[
                i
              ].fundsFromOperationsPercentReasearchAndDevelopment = this.convertToMillionaire(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentReasearchAndDevelopment
              );
              flowOfFundsData[i].fundsFromOperationsPercentTotalCapital =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentTotalCapital
                );
              flowOfFundsData[
                i
              ].fundsFromOperationsPercentMarketCapitalization = this.convertToMillionaire(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentMarketCapitalization
              );
              flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue
                );
              flowOfFundsData[
                i
              ].fundsFromOperationsPercentCommonShareholdersEquity = this.convertToMillionaire(
                flowOfFundsData[i]
                  .fundsFromOperationsPercentCommonShareholdersEquity
              );
              flowOfFundsData[i].fundsFromOperationsDDA =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsFromOperationsDDA
                );
              flowOfFundsData[i].netSales = this.convertToMillionaire(
                flowOfFundsData[i].netSales
              );
              flowOfFundsData[i].fundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromOperations
                );
            }

            this.setState({
              render: true,
              flowOfFundsData: flowOfFundsData,
              CashDividendsCoverageRatioFundsfromOperationsCashDividends:
                CashDividendsCoverageRatioFundsfromOperationsCashDividends,
              CostOfGoodsSoldFundsFromOperations:
                CostOfGoodsSoldFundsFromOperations,
              AccumulatedDDAFundsFromOperations:
                AccumulatedDDAFundsFromOperations,
              CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities:
                CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities,
              AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities:
                AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities,
              interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid:
                interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid,
              ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid:
                ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid,
              ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations:
                ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations,
              CashFlowOperatingActivities: CashFlowOperatingActivities,
              CashFlowFinancingActivities: CashFlowFinancingActivities,
              CashFlowInvestingActivities: CashFlowInvestingActivities,
              CashFlowManagementFinancingCashFlowInvestingCashFlow:
                CashFlowManagementFinancingCashFlowInvestingCashFlow,
              FreeCashFlowFundsfromOperationsCapitalExpenditures:
                FreeCashFlowFundsfromOperationsCapitalExpenditures,
              FreeCashFlowYeartoYearPercentChange:
                FreeCashFlowYeartoYearPercentChange,
              UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes:
                UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes,
              UnleveredFreeCashFlowPercentTotalAssets:
                UnleveredFreeCashFlowPercentTotalAssets,
              UnleveredFreeCashFlowPercentEnterpriseValue:
                UnleveredFreeCashFlowPercentEnterpriseValue,
              UnleveredFreeCashFlowPercentShareholdersEquity:
                UnleveredFreeCashFlowPercentShareholdersEquity,
              LeveredFreeCashFlow: LeveredFreeCashFlow,
              LeveredFreeCashFlowPercentTotalAssets:
                LeveredFreeCashFlowPercentTotalAssets,
              LeveredFreeCashFlowPercentEnterpriseValue:
                LeveredFreeCashFlowPercentEnterpriseValue,
              LeveredFreeCashFlowPercentShareholdersEquity:
                LeveredFreeCashFlowPercentShareholdersEquity,
              FreeCashFlowPercentLeveredFreeCashFlow:
                FreeCashFlowPercentLeveredFreeCashFlow,
              FreeCashFlowPercentUnleveredFreeCashFlow:
                FreeCashFlowPercentUnleveredFreeCashFlow,
              LeveredFreeCashFlowPercentUnleveredFreeCashFlow:
                LeveredFreeCashFlowPercentUnleveredFreeCashFlow,
              FreeCashFlowPercentShortTermDebt: FreeCashFlowPercentLongTermDebt,
              FreeCashFlowPercentLongTermDebt: FreeCashFlowPercentLongTermDebt,
              FreeCashFlowPercentFundsFromOperations:
                FreeCashFlowPercentFundsFromOperations,
              FreeCashFlowPercentNetSales: FreeCashFlowPercentNetSales,
              FreeCashFlowPercentTotalAssets: FreeCashFlowPercentTotalAssets,
              FreeCashFlowPercentOperatingIncome:
                FreeCashFlowPercentOperatingIncome,
              FreeCashFlowPercentTotalLiabilities:
                FreeCashFlowPercentTotalLiabilities,
              FreeCashFlowPercentMarketCapitalization:
                FreeCashFlowPercentMarketCapitalization,
              FreeCashFlowPercentEnterpriseValue:
                FreeCashFlowPercentEnterpriseValue,
              FreeCashFlowPercentCommonShareholdersEquity:
                FreeCashFlowPercentCommonShareholdersEquity,
              AbsoluteFreeCashFlow: AbsoluteFreeCashFlow,
              AbsoluteFreeCashFlowYeartoYearPercentChange:
                AbsoluteFreeCashFlowYeartoYearPercentChange,
              AbsoluteFreeCashFlowPercentShortTermDebt:
                AbsoluteFreeCashFlowPercentShortTermDebt,
              AbsoluteFreeCashFlowPercentLongTermDebt:
                AbsoluteFreeCashFlowPercentLongTermDebt,
              AbsoluteFreeCashFlowPercentOperatingIncome:
                AbsoluteFreeCashFlowPercentOperatingIncome,
              AbsoluteFreeCashFlowPercentTotalLiabilities:
                AbsoluteFreeCashFlowPercentTotalLiabilities,
              LabelList: LabelList,
              AbsoluteFreeCashFlowPercentNetSales:
                AbsoluteFreeCashFlowPercentNetSales,
              AbsoluteFreeCashFlowPercentTotalAssets:
                AbsoluteFreeCashFlowPercentTotalAssets,
              AbsoluteFreeCashFlowPercentFundsfromOperations:
                AbsoluteFreeCashFlowPercentFundsfromOperations,
              AbsoluteFreeCashFlowPercentMarketCapitalization:
                AbsoluteFreeCashFlowPercentMarketCapitalization,
              AbsoluteFreeCashFlowPercentEnterpriseValue:
                AbsoluteFreeCashFlowPercentEnterpriseValue,
              AbsoluteFreeCashFlowPercentCommonShareholdersEquity:
                AbsoluteFreeCashFlowPercentCommonShareholdersEquity,
              CashFlowRatio: CashFlowRatio,
              MarketCapitalization: MarketCapitalization,
              MarketCapitalizationYeartoYearPercentChange:
                MarketCapitalizationYeartoYearPercentChange,
              NetSalesYeartoYearPercentChange: NetSalesYeartoYearPercentChange,
              FundsfromOperationsYeartoYearPercentChange:
                FundsfromOperationsYeartoYearPercentChange,
              ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt:
                ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt,
              LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt:
                LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt,
              AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt:
                AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt,
              TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt:
                TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt,
              AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt:
                AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt,
              CashDividendsPercentFundsfromOperations:
                CashDividendsPercentFundsfromOperations,
              NetSalesPercentFundsfromOperations:
                NetSalesPercentFundsfromOperations,
              CommonStockPriceCashFlowperShare:
                CommonStockPriceCashFlowperShare,
              CommonStockPriceEarningsPerShare:
                CommonStockPriceEarningsPerShare,
              EarningsperShareCashFlowPerShare:
                EarningsperShareCashFlowPerShare,
              FundsFromOperationsPercentTotalLiabilities:
                FundsFromOperationsPercentTotalLiabilities,
              FundsFromOperationsPercentLongTermBorrowing:
                FundsFromOperationsPercentLongTermBorrowing,
              FundsFromOperationsPercentCapitalExpenditures:
                FundsFromOperationsPercentCapitalExpenditures,
              FundsFromOperationsPercentCashDividendsPaid:
                FundsFromOperationsPercentCashDividendsPaid,
              FundsFromOperationsPercentNetSales:
                FundsFromOperationsPercentNetSales,
              FundsFromOperationsPercentTotalAssets:
                FundsFromOperationsPercentTotalAssets,
              FundsFromOperationsPercentTotalCurrentAssets:
                FundsFromOperationsPercentTotalCurrentAssets,
              FundsFromOperationsPercentWorkingCapital:
                FundsFromOperationsPercentWorkingCapital,
              FundsFromOperationsPercentPropertyPlantAndEquipment:
                FundsFromOperationsPercentPropertyPlantAndEquipment,
              FundsFromOperationsPercentReasearchAndDevelopment:
                FundsFromOperationsPercentReasearchAndDevelopment,
              FundsFromOperationsPercentTotalCapital:
                FundsFromOperationsPercentTotalCapital,
              FundsFromOperationsPercentProvisionforBadDebt:
                FundsFromOperationsPercentProvisionforBadDebt,
              FundsFromOperationsPercentMarketCapitalization:
                FundsFromOperationsPercentMarketCapitalization,
              FundsFromOperationsPercentEnterpriseValue:
                FundsFromOperationsPercentEnterpriseValue,
              FundsFromOperationsPercentCommonShareholdersEquity:
                FundsFromOperationsPercentCommonShareholdersEquity,
              FundsFromOperationsFixedCharges: FundsFromOperationsFixedCharges,
              FundsFromOperationsDDA: FundsFromOperationsDDA,
              NetSales: NetSales,
              FundsfromOperations: FundsfromOperations,
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      this.currency = this.state.data.currency;
      this.country = this.state.data.country;
      let LabelList = [];
      let CashDividendsCoverageRatioFundsfromOperationsCashDividends = [];
      let CostOfGoodsSoldFundsFromOperations = [];
      let AccumulatedDDAFundsFromOperations = [];
      let CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities =
        [];
      let AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities =
        [];
      let interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid =
        [];
      let ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid =
        [];
      let ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations = [];
      let CashFlowOperatingActivities = [];
      let CashFlowFinancingActivities = [];
      let CashFlowInvestingActivities = [];
      let CashFlowManagementFinancingCashFlowInvestingCashFlow = [];
      let FreeCashFlowFundsfromOperationsCapitalExpenditures = [];
      let FreeCashFlowYeartoYearPercentChange = [];
      let UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes = [];
      let UnleveredFreeCashFlowPercentTotalAssets = [];
      let UnleveredFreeCashFlowPercentEnterpriseValue = [];
      let UnleveredFreeCashFlowPercentShareholdersEquity = [];
      let LeveredFreeCashFlow = [];
      let LeveredFreeCashFlowPercentTotalAssets = [];
      let LeveredFreeCashFlowPercentEnterpriseValue = [];
      let LeveredFreeCashFlowPercentShareholdersEquity = [];
      let FreeCashFlowPercentLeveredFreeCashFlow = [];
      let FreeCashFlowPercentUnleveredFreeCashFlow = [];
      let LeveredFreeCashFlowPercentUnleveredFreeCashFlow = [];
      let FreeCashFlowPercentShortTermDebt = [];
      let FreeCashFlowPercentLongTermDebt = [];
      let FreeCashFlowPercentFundsFromOperations = [];
      let FreeCashFlowPercentNetSales = [];
      let FreeCashFlowPercentTotalAssets = [];
      let FreeCashFlowPercentOperatingIncome = [];
      let FreeCashFlowPercentTotalLiabilities = [];
      let FreeCashFlowPercentMarketCapitalization = [];
      let FreeCashFlowPercentEnterpriseValue = [];
      let FreeCashFlowPercentCommonShareholdersEquity = [];
      let AbsoluteFreeCashFlow = [];
      let AbsoluteFreeCashFlowYeartoYearPercentChange = [];
      let AbsoluteFreeCashFlowPercentShortTermDebt = [];
      let AbsoluteFreeCashFlowPercentLongTermDebt = [];
      let AbsoluteFreeCashFlowPercentOperatingIncome = [];
      let AbsoluteFreeCashFlowPercentTotalLiabilities = [];

      let AbsoluteFreeCashFlowPercentNetSales = [];
      let AbsoluteFreeCashFlowPercentTotalAssets = [];
      let AbsoluteFreeCashFlowPercentFundsfromOperations = [];
      let AbsoluteFreeCashFlowPercentMarketCapitalization = [];
      let AbsoluteFreeCashFlowPercentEnterpriseValue = [];
      let AbsoluteFreeCashFlowPercentCommonShareholdersEquity = [];
      let CashFlowRatio = [];
      let MarketCapitalization = [];
      let MarketCapitalizationYeartoYearPercentChange = [];
      let NetSalesYeartoYearPercentChange = [];
      let FundsfromOperationsYeartoYearPercentChange = [];
      let ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt =
        [];
      let LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt = [];
      let AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt =
        [];
      let TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt = [];
      let AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt =
        [];
      let CashDividendsPercentFundsfromOperations = [];
      let NetSalesPercentFundsfromOperations = [];
      let CommonStockPriceCashFlowperShare = [];
      let CommonStockPriceEarningsPerShare = [];
      let EarningsperShareCashFlowPerShare = [];
      let FundsFromOperationsPercentTotalLiabilities = [];
      let FundsFromOperationsPercentLongTermBorrowing = [];
      let FundsFromOperationsPercentCapitalExpenditures = [];
      let FundsFromOperationsPercentCashDividendsPaid = [];
      let FundsFromOperationsPercentNetSales = [];
      let FundsFromOperationsPercentTotalAssets = [];
      let FundsFromOperationsPercentTotalCurrentAssets = [];
      let FundsFromOperationsPercentWorkingCapital = [];
      let FundsFromOperationsPercentPropertyPlantAndEquipment = [];
      let FundsFromOperationsPercentReasearchAndDevelopment = [];
      let FundsFromOperationsPercentTotalCapital = [];
      let FundsFromOperationsPercentProvisionforBadDebt = [];
      let FundsFromOperationsPercentMarketCapitalization = [];
      let FundsFromOperationsPercentEnterpriseValue = [];
      let FundsFromOperationsPercentCommonShareholdersEquity = [];
      let FundsFromOperationsFixedCharges = [];
      let FundsFromOperationsDDA = [];
      let NetSales = [];
      let FundsfromOperations = [];
      let flowOfFundsData = this.state.data.fOFRatioList;
      if (flowOfFundsData.length > 0) {
       // flowOfFundsData.reverse();
      }
      for (let i = 0; i < flowOfFundsData.length; i++) {
        LabelList.push(flowOfFundsData[i].year);
        CashDividendsCoverageRatioFundsfromOperationsCashDividends.push(
          flowOfFundsData[i]
            .cashDividendsCoverageRatioFundsfromOperationsCashDividends
        );
        CostOfGoodsSoldFundsFromOperations.push(
          flowOfFundsData[i].costofGoodsSoldFundsfromOperations
        );
        AccumulatedDDAFundsFromOperations.push(
          flowOfFundsData[i].accumulatedDDAFundsfromOperations
        );
        CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities.push(
          flowOfFundsData[i]
            .currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
        );
        AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities.push(
          flowOfFundsData[i]
            .adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
        );
        interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid.push(
          flowOfFundsData[i]
            .interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid
        );
        ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid.push(
          flowOfFundsData[i]
            .comprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid
        );
        ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations.push(
          flowOfFundsData[i]
            .externalFinancingIndexRatioCashfromFinancingFundsfromOperations
        );
        CashFlowOperatingActivities.push(
          flowOfFundsData[i].cashFlowOperatingActivities
        );
        CashFlowFinancingActivities.push(
          flowOfFundsData[i].cashFlowFinancingActivities
        );
        CashFlowInvestingActivities.push(
          flowOfFundsData[i].cashFlowInvestingActivities
        );
        CashFlowManagementFinancingCashFlowInvestingCashFlow.push(
          flowOfFundsData[i]
            .cashFlowManagementFinancingCashFlowInvestingCashFlow
        );
        FreeCashFlowFundsfromOperationsCapitalExpenditures.push(
          flowOfFundsData[i].freeCashFlowFundsfromOperationsCapitalExpenditures
        );
        FreeCashFlowYeartoYearPercentChange.push(
          flowOfFundsData[i].freeCashFlowYeartoYearPercentChange
        );
        UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes.push(
          flowOfFundsData[i]
            .unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
        );
        UnleveredFreeCashFlowPercentTotalAssets.push(
          flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets
        );
        UnleveredFreeCashFlowPercentEnterpriseValue.push(
          flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue
        );
        UnleveredFreeCashFlowPercentShareholdersEquity.push(
          flowOfFundsData[i].unleveredFreeCashFlowPercentShareholdersEquity
        );
        LeveredFreeCashFlow.push(flowOfFundsData[i].leveredFreeCashFlow);
        LeveredFreeCashFlowPercentTotalAssets.push(
          flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets
        );
        LeveredFreeCashFlowPercentEnterpriseValue.push(
          flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue
        );
        LeveredFreeCashFlowPercentShareholdersEquity.push(
          flowOfFundsData[i].leveredFreeCashFlowPercentShareholdersEquity
        );
        FreeCashFlowPercentLeveredFreeCashFlow.push(
          flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow
        );
        FreeCashFlowPercentUnleveredFreeCashFlow.push(
          flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow
        );
        LeveredFreeCashFlowPercentUnleveredFreeCashFlow.push(
          flowOfFundsData[i].leveredFreeCashFlowPercentUnleveredFreeCashFlow
        );
        FreeCashFlowPercentShortTermDebt.push(
          flowOfFundsData[i].freeCashFlowPercentShortTermDebt
        );
        FreeCashFlowPercentLongTermDebt.push(
          flowOfFundsData[i].freeCashFlowPercentLongTermDebt
        );
        FreeCashFlowPercentFundsFromOperations.push(
          flowOfFundsData[i].freeCashFlowPercentFundsfromOperations
        );
        FreeCashFlowPercentNetSales.push(
          flowOfFundsData[i].freeCashFlowPercentNetSales
        );
        FreeCashFlowPercentTotalAssets.push(
          flowOfFundsData[i].freeCashFlowPercentTotalAssets
        );
        FreeCashFlowPercentOperatingIncome.push(
          flowOfFundsData[i].freeCashFlowPercentOperatingIncome
        );
        FreeCashFlowPercentTotalLiabilities.push(
          flowOfFundsData[i].freeCashFlowPercentTotalLiabilities
        );
        FreeCashFlowPercentMarketCapitalization.push(
          flowOfFundsData[i].freeCashFlowPercentMarketCapitalization
        );
        FreeCashFlowPercentEnterpriseValue.push(
          flowOfFundsData[i].freeCashFlowPercentEnterpriseValue
        );
        FreeCashFlowPercentCommonShareholdersEquity.push(
          flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity
        );
        AbsoluteFreeCashFlow.push(flowOfFundsData[i].absoluteFreeCashFlow);
        AbsoluteFreeCashFlowYeartoYearPercentChange.push(
          flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange
        );
        AbsoluteFreeCashFlowPercentShortTermDebt.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentShortTermDebt
        );
        AbsoluteFreeCashFlowPercentLongTermDebt.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentLongTermDebt
        );
        AbsoluteFreeCashFlowPercentOperatingIncome.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome
        );
        AbsoluteFreeCashFlowPercentTotalLiabilities.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities
        );

        AbsoluteFreeCashFlowPercentNetSales.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales
        );
        AbsoluteFreeCashFlowPercentTotalAssets.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets
        );
        AbsoluteFreeCashFlowPercentFundsfromOperations.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentFundsfromOperations
        );
        AbsoluteFreeCashFlowPercentMarketCapitalization.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentMarketCapitalization
        );
        AbsoluteFreeCashFlowPercentEnterpriseValue.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue
        );
        AbsoluteFreeCashFlowPercentCommonShareholdersEquity.push(
          flowOfFundsData[i].absoluteFreeCashFlowPercentCommonShareholdersEquity
        );
        CashFlowRatio.push(flowOfFundsData[i].cashFlowRatio);
        MarketCapitalization.push(flowOfFundsData[i].marketCapitalization);
        MarketCapitalizationYeartoYearPercentChange.push(
          flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange
        );
        NetSalesYeartoYearPercentChange.push(
          flowOfFundsData[i].netSalesYeartoYearPercentChange
        );
        FundsfromOperationsYeartoYearPercentChange.push(
          flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange
        );
        ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt.push(
          flowOfFundsData[i]
            .shortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt
        );
        LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt.push(
          flowOfFundsData[i]
            .longTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt
        );
        AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt.push(
          flowOfFundsData[i]
            .adjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt
        );
        TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt.push(
          flowOfFundsData[i]
            .totalDebtCoverageRatioFundsFromOperationsPercentTotalDebt
        );
        AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt.push(
          flowOfFundsData[i]
            .adjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt
        );
        CashDividendsPercentFundsfromOperations.push(
          flowOfFundsData[i].cashDividendsPercentFundsfromOperations
        );
        NetSalesPercentFundsfromOperations.push(
          flowOfFundsData[i].netSalesPercentFundsfromOperations
        );
        CommonStockPriceCashFlowperShare.push(
          flowOfFundsData[i].commonStockPriceCashFlowperShare
        );
        CommonStockPriceEarningsPerShare.push(
          flowOfFundsData[i].commonStockPriceEarningsperShare
        );
        EarningsperShareCashFlowPerShare.push(
          flowOfFundsData[i].earningsperShareCashFlowperShare
        );
        FundsFromOperationsPercentTotalLiabilities.push(
          flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities
        );
        FundsFromOperationsPercentLongTermBorrowing.push(
          flowOfFundsData[i].fundsFromOperationsPercentLongTermBorrowing
        );
        FundsFromOperationsPercentCapitalExpenditures.push(
          flowOfFundsData[i].fundsFromOperationsPercentCapitalExpenditures
        );
        FundsFromOperationsPercentCashDividendsPaid.push(
          flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid
        );
        FundsFromOperationsPercentNetSales.push(
          flowOfFundsData[i].fundsFromOperationsPercentNetSales
        );
        FundsFromOperationsPercentTotalAssets.push(
          flowOfFundsData[i].fundsFromOperationsPercentTotalAssets
        );
        FundsFromOperationsPercentTotalCurrentAssets.push(
          flowOfFundsData[i].fundsFromOperationsPercentTotalCurrentAssets
        );
        FundsFromOperationsPercentWorkingCapital.push(
          flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital
        );
        FundsFromOperationsPercentPropertyPlantAndEquipment.push(
          flowOfFundsData[i].fundsFromOperationsPercentPropertyPlantAndEquipment
        );
        FundsFromOperationsPercentReasearchAndDevelopment.push(
          flowOfFundsData[i].fundsFromOperationsPercentReasearchAndDevelopment
        );
        FundsFromOperationsPercentTotalCapital.push(
          flowOfFundsData[i].fundsFromOperationsPercentTotalCapital
        );
        FundsFromOperationsPercentProvisionforBadDebt.push(
          flowOfFundsData[i].fundsFromOperationsPercentProvisionforBadDebt
        );
        FundsFromOperationsPercentMarketCapitalization.push(
          flowOfFundsData[i].fundsFromOperationsPercentMarketCapitalization
        );
        FundsFromOperationsPercentEnterpriseValue.push(
          flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue
        );
        FundsFromOperationsPercentCommonShareholdersEquity.push(
          flowOfFundsData[i].fundsFromOperationsPercentCommonShareholdersEquity
        );
        FundsFromOperationsFixedCharges.push(
          flowOfFundsData[i].fundsFromOperationsFixedCharges
        );
        FundsFromOperationsDDA.push(flowOfFundsData[i].fundsFromOperationsDDA);
        NetSales.push(flowOfFundsData[i].netSales);
        FundsfromOperations.push(flowOfFundsData[i].fundsfromOperations);
        flowOfFundsData[
          i
        ].cashDividendsCoverageRatioFundsfromOperationsCashDividends = this.convertToMillionaire(
          flowOfFundsData[i]
            .cashDividendsCoverageRatioFundsfromOperationsCashDividends
        );
        flowOfFundsData[i].costofGoodsSoldFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].costofGoodsSoldFundsfromOperations
          );
        flowOfFundsData[i].accumulatedDDAFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].accumulatedDDAFundsfromOperations
          );
        flowOfFundsData[
          i
        ].currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
          );
        flowOfFundsData[
          i
        ].adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
          );
        flowOfFundsData[
          i
        ].externalFinancingIndexRatioCashfromFinancingFundsfromOperations = this.convertToMillionaire(
          flowOfFundsData[i]
            .externalFinancingIndexRatioCashfromFinancingFundsfromOperations
        );
        flowOfFundsData[i].cashFlowOperatingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].cashFlowOperatingActivities
          );
        flowOfFundsData[i].cashFlowFinancingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].cashFlowFinancingActivities
          );
        flowOfFundsData[i].cashFlowInvestingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].cashFlowInvestingActivities
          );
        flowOfFundsData[
          i
        ].cashFlowManagementFinancingCashFlowInvestingCashFlow = this.convertToMillionaire(
          flowOfFundsData[i]
            .cashFlowManagementFinancingCashFlowInvestingCashFlow
        );
        flowOfFundsData[i].freeCashFlowFundsfromOperationsCapitalExpenditures =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .freeCashFlowFundsfromOperationsCapitalExpenditures
          );
        flowOfFundsData[i].freeCashFlowYeartoYearPercentChange =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowYeartoYearPercentChange
          );
        flowOfFundsData[
          i
        ].unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes = this.convertToMillionaire(
          flowOfFundsData[i]
            .unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
        );
        flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].unleveredFreeCashFlowPercentTotalAssets
          );
        flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue =
          this.convertToMillionaire(
            flowOfFundsData[i].unleveredFreeCashFlowPercentEnterpriseValue
          );
        flowOfFundsData[i].unleveredFreeCashFlowPercentShareholdersEquity =
          this.convertToMillionaire(
            flowOfFundsData[i].unleveredFreeCashFlowPercentShareholdersEquity
          );
        flowOfFundsData[i].leveredFreeCashFlow = this.convertToMillionaire(
          flowOfFundsData[i].leveredFreeCashFlow
        );
        flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].leveredFreeCashFlowPercentTotalAssets
          );
        flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue =
          this.convertToMillionaire(
            flowOfFundsData[i].leveredFreeCashFlowPercentEnterpriseValue
          );
        flowOfFundsData[i].leveredFreeCashFlowPercentShareholdersEquity =
          this.convertToMillionaire(
            flowOfFundsData[i].leveredFreeCashFlowPercentShareholdersEquity
          );
        flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentLeveredFreeCashFlow
          );
        flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentUnleveredFreeCashFlow
          );
        flowOfFundsData[i].leveredFreeCashFlowPercentUnleveredFreeCashFlow =
          this.convertToMillionaire(
            flowOfFundsData[i].leveredFreeCashFlowPercentUnleveredFreeCashFlow
          );
        flowOfFundsData[i].freeCashFlowPercentFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentFundsfromOperations
          );
        flowOfFundsData[i].freeCashFlowPercentNetSales =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentNetSales
          );
        flowOfFundsData[i].freeCashFlowPercentTotalAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentTotalAssets
          );
        flowOfFundsData[i].freeCashFlowPercentOperatingIncome =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentOperatingIncome
          );
        flowOfFundsData[i].freeCashFlowPercentTotalLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentTotalLiabilities
          );
        flowOfFundsData[i].freeCashFlowPercentMarketCapitalization =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentMarketCapitalization
          );
        flowOfFundsData[i].freeCashFlowPercentEnterpriseValue =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentEnterpriseValue
          );
        flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity =
          this.convertToMillionaire(
            flowOfFundsData[i].freeCashFlowPercentCommonShareholdersEquity
          );
        flowOfFundsData[i].absoluteFreeCashFlow = this.convertToMillionaire(
          flowOfFundsData[i].absoluteFreeCashFlow
        );
        flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowYeartoYearPercentChange
          );

        flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentOperatingIncome
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentTotalLiabilities
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentNetSales
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentTotalAssets
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentFundsfromOperations
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentMarketCapitalization =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentMarketCapitalization
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue =
          this.convertToMillionaire(
            flowOfFundsData[i].absoluteFreeCashFlowPercentEnterpriseValue
          );
        flowOfFundsData[i].absoluteFreeCashFlowPercentCommonShareholdersEquity =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .absoluteFreeCashFlowPercentCommonShareholdersEquity
          );
        flowOfFundsData[i].cashFlowRatio = this.convertToMillionaire(
          flowOfFundsData[i].cashFlowRatio
        );
        flowOfFundsData[i].marketCapitalization = this.convertToMillionaire(
          flowOfFundsData[i].marketCapitalization
        );
        flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange =
          this.convertToMillionaire(
            flowOfFundsData[i].marketCapitalizationYeartoYearPercentChange
          );
        flowOfFundsData[i].netSalesYeartoYearPercentChange =
          this.convertToMillionaire(
            flowOfFundsData[i].netSalesYeartoYearPercentChange
          );
        flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsfromOperationsYeartoYearPercentChange
          );

        flowOfFundsData[i].cashDividendsPercentFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].cashDividendsPercentFundsfromOperations
          );
        flowOfFundsData[i].netSalesPercentFundsfromOperations =
          this.convertToMillionaire(
            flowOfFundsData[i].netSalesPercentFundsfromOperations
          );
        flowOfFundsData[i].commonStockPriceCashFlowperShare =
          this.convertToMillionaire(
            flowOfFundsData[i].commonStockPriceCashFlowperShare
          );
        flowOfFundsData[i].commonStockPriceEarningsperShare =
          this.convertToMillionaire(
            flowOfFundsData[i].commonStockPriceEarningsperShare
          );
        flowOfFundsData[i].earningsperShareCashFlowperShare =
          this.convertToMillionaire(
            flowOfFundsData[i].earningsperShareCashFlowperShare
          );
        flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentTotalLiabilities
          );
        flowOfFundsData[i].fundsFromOperationsPercentCapitalExpenditures =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentCapitalExpenditures
          );
        flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentCashDividendsPaid
          );
        flowOfFundsData[i].fundsFromOperationsPercentNetSales =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentNetSales
          );
        flowOfFundsData[i].fundsFromOperationsPercentTotalAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentTotalAssets
          );
        flowOfFundsData[i].fundsFromOperationsPercentTotalCurrentAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentTotalCurrentAssets
          );
        flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentWorkingCapital
          );
        flowOfFundsData[i].fundsFromOperationsPercentPropertyPlantAndEquipment =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .fundsFromOperationsPercentPropertyPlantAndEquipment
          );
        flowOfFundsData[i].fundsFromOperationsPercentReasearchAndDevelopment =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentReasearchAndDevelopment
          );
        flowOfFundsData[i].fundsFromOperationsPercentTotalCapital =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentTotalCapital
          );
        flowOfFundsData[i].fundsFromOperationsPercentMarketCapitalization =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentMarketCapitalization
          );
        flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsFromOperationsPercentEnterpriseValue
          );
        flowOfFundsData[i].fundsFromOperationsPercentCommonShareholdersEquity =
          this.convertToMillionaire(
            flowOfFundsData[i]
              .fundsFromOperationsPercentCommonShareholdersEquity
          );
        flowOfFundsData[i].fundsFromOperationsDDA = this.convertToMillionaire(
          flowOfFundsData[i].fundsFromOperationsDDA
        );
        flowOfFundsData[i].netSales = this.convertToMillionaire(
          flowOfFundsData[i].netSales
        );
        flowOfFundsData[i].fundsfromOperations = this.convertToMillionaire(
          flowOfFundsData[i].fundsfromOperations
        );
      }

      this.setState({
        render: true,
        flowOfFundsData: flowOfFundsData,
        CashDividendsCoverageRatioFundsfromOperationsCashDividends:
          CashDividendsCoverageRatioFundsfromOperationsCashDividends,
        CostOfGoodsSoldFundsFromOperations: CostOfGoodsSoldFundsFromOperations,
        AccumulatedDDAFundsFromOperations: AccumulatedDDAFundsFromOperations,
        CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities:
          CurrentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities,
        AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities:
          AdjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities,
        interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid:
          interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid,
        ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid:
          ComprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid,
        ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations:
          ExternalFinancingIndexRatioCashfromFinancingFundsfromOperations,
        CashFlowOperatingActivities: CashFlowOperatingActivities,
        CashFlowFinancingActivities: CashFlowFinancingActivities,
        CashFlowInvestingActivities: CashFlowInvestingActivities,
        CashFlowManagementFinancingCashFlowInvestingCashFlow:
          CashFlowManagementFinancingCashFlowInvestingCashFlow,
        FreeCashFlowFundsfromOperationsCapitalExpenditures:
          FreeCashFlowFundsfromOperationsCapitalExpenditures,
        FreeCashFlowYeartoYearPercentChange:
          FreeCashFlowYeartoYearPercentChange,
        UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes:
          UnleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes,
        UnleveredFreeCashFlowPercentTotalAssets:
          UnleveredFreeCashFlowPercentTotalAssets,
        UnleveredFreeCashFlowPercentEnterpriseValue:
          UnleveredFreeCashFlowPercentEnterpriseValue,
        UnleveredFreeCashFlowPercentShareholdersEquity:
          UnleveredFreeCashFlowPercentShareholdersEquity,
        LeveredFreeCashFlow: LeveredFreeCashFlow,
        LeveredFreeCashFlowPercentTotalAssets:
          LeveredFreeCashFlowPercentTotalAssets,
        LeveredFreeCashFlowPercentEnterpriseValue:
          LeveredFreeCashFlowPercentEnterpriseValue,
        LeveredFreeCashFlowPercentShareholdersEquity:
          LeveredFreeCashFlowPercentShareholdersEquity,
        FreeCashFlowPercentLeveredFreeCashFlow:
          FreeCashFlowPercentLeveredFreeCashFlow,
        FreeCashFlowPercentUnleveredFreeCashFlow:
          FreeCashFlowPercentUnleveredFreeCashFlow,
        LeveredFreeCashFlowPercentUnleveredFreeCashFlow:
          LeveredFreeCashFlowPercentUnleveredFreeCashFlow,
        FreeCashFlowPercentShortTermDebt: FreeCashFlowPercentLongTermDebt,
        FreeCashFlowPercentLongTermDebt: FreeCashFlowPercentLongTermDebt,
        FreeCashFlowPercentFundsFromOperations:
          FreeCashFlowPercentFundsFromOperations,
        FreeCashFlowPercentNetSales: FreeCashFlowPercentNetSales,
        FreeCashFlowPercentTotalAssets: FreeCashFlowPercentTotalAssets,
        FreeCashFlowPercentOperatingIncome: FreeCashFlowPercentOperatingIncome,
        FreeCashFlowPercentTotalLiabilities:
          FreeCashFlowPercentTotalLiabilities,
        FreeCashFlowPercentMarketCapitalization:
          FreeCashFlowPercentMarketCapitalization,
        FreeCashFlowPercentEnterpriseValue: FreeCashFlowPercentEnterpriseValue,
        FreeCashFlowPercentCommonShareholdersEquity:
          FreeCashFlowPercentCommonShareholdersEquity,
        AbsoluteFreeCashFlow: AbsoluteFreeCashFlow,
        AbsoluteFreeCashFlowYeartoYearPercentChange:
          AbsoluteFreeCashFlowYeartoYearPercentChange,
        AbsoluteFreeCashFlowPercentShortTermDebt:
          AbsoluteFreeCashFlowPercentShortTermDebt,
        AbsoluteFreeCashFlowPercentLongTermDebt:
          AbsoluteFreeCashFlowPercentLongTermDebt,
        AbsoluteFreeCashFlowPercentOperatingIncome:
          AbsoluteFreeCashFlowPercentOperatingIncome,
        AbsoluteFreeCashFlowPercentTotalLiabilities:
          AbsoluteFreeCashFlowPercentTotalLiabilities,
        LabelList: LabelList,
        AbsoluteFreeCashFlowPercentNetSales:
          AbsoluteFreeCashFlowPercentNetSales,
        AbsoluteFreeCashFlowPercentTotalAssets:
          AbsoluteFreeCashFlowPercentTotalAssets,
        AbsoluteFreeCashFlowPercentFundsfromOperations:
          AbsoluteFreeCashFlowPercentFundsfromOperations,
        AbsoluteFreeCashFlowPercentMarketCapitalization:
          AbsoluteFreeCashFlowPercentMarketCapitalization,
        AbsoluteFreeCashFlowPercentEnterpriseValue:
          AbsoluteFreeCashFlowPercentEnterpriseValue,
        AbsoluteFreeCashFlowPercentCommonShareholdersEquity:
          AbsoluteFreeCashFlowPercentCommonShareholdersEquity,
        CashFlowRatio: CashFlowRatio,
        MarketCapitalization: MarketCapitalization,
        MarketCapitalizationYeartoYearPercentChange:
          MarketCapitalizationYeartoYearPercentChange,
        NetSalesYeartoYearPercentChange: NetSalesYeartoYearPercentChange,
        FundsfromOperationsYeartoYearPercentChange:
          FundsfromOperationsYeartoYearPercentChange,
        ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt:
          ShortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt,
        LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt:
          LongTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt,
        AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt:
          AdjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt,
        TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt:
          TotalDebtCoverageRatioFundsFromOperationsPercentTotalDebt,
        AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt:
          AdjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt,
        CashDividendsPercentFundsfromOperations:
          CashDividendsPercentFundsfromOperations,
        NetSalesPercentFundsfromOperations: NetSalesPercentFundsfromOperations,
        CommonStockPriceCashFlowperShare: CommonStockPriceCashFlowperShare,
        CommonStockPriceEarningsPerShare: CommonStockPriceEarningsPerShare,
        EarningsperShareCashFlowPerShare: EarningsperShareCashFlowPerShare,
        FundsFromOperationsPercentTotalLiabilities:
          FundsFromOperationsPercentTotalLiabilities,
        FundsFromOperationsPercentLongTermBorrowing:
          FundsFromOperationsPercentLongTermBorrowing,
        FundsFromOperationsPercentCapitalExpenditures:
          FundsFromOperationsPercentCapitalExpenditures,
        FundsFromOperationsPercentCashDividendsPaid:
          FundsFromOperationsPercentCashDividendsPaid,
        FundsFromOperationsPercentNetSales: FundsFromOperationsPercentNetSales,
        FundsFromOperationsPercentTotalAssets:
          FundsFromOperationsPercentTotalAssets,
        FundsFromOperationsPercentTotalCurrentAssets:
          FundsFromOperationsPercentTotalCurrentAssets,
        FundsFromOperationsPercentWorkingCapital:
          FundsFromOperationsPercentWorkingCapital,
        FundsFromOperationsPercentPropertyPlantAndEquipment:
          FundsFromOperationsPercentPropertyPlantAndEquipment,
        FundsFromOperationsPercentReasearchAndDevelopment:
          FundsFromOperationsPercentReasearchAndDevelopment,
        FundsFromOperationsPercentTotalCapital:
          FundsFromOperationsPercentTotalCapital,
        FundsFromOperationsPercentProvisionforBadDebt:
          FundsFromOperationsPercentProvisionforBadDebt,
        FundsFromOperationsPercentMarketCapitalization:
          FundsFromOperationsPercentMarketCapitalization,
        FundsFromOperationsPercentEnterpriseValue:
          FundsFromOperationsPercentEnterpriseValue,
        FundsFromOperationsPercentCommonShareholdersEquity:
          FundsFromOperationsPercentCommonShareholdersEquity,
        FundsFromOperationsFixedCharges: FundsFromOperationsFixedCharges,
        FundsFromOperationsDDA: FundsFromOperationsDDA,
        NetSales: NetSales,
        FundsfromOperations: FundsfromOperations,
      });
    }
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px" }}>
            <Col md={12} style={{ padding: "0em 0em 0.3em 2.9em" }}>
              <Row>
                <Col md={8} style={{ padding: "8px 10px" }}>
                  <span style={{ padding: "20px 0px 0px 2px" }}>
                    <span className="section_header"> Ratios Analysis</span>
                  </span>
                  <br></br>
                  <span className="unit_str">
                    All figures in {this.currency}
                  </span>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="balance_sheet">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td style={{ fontWeight: "bold" }}>{data.year}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEnd}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Cash Dividends Coverage Ratio: Funds from Operations / Cash
                    Dividends
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.cashDividendsCoverageRatioFundsfromOperationsCashDividends
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cost of Goods Sold / Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.costofGoodsSoldFundsfromOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>Accumulated DDA / Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.accumulatedDDAFundsfromOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Current Liabilities Coverage Ratio: Funds from Operations /
                    Total Current Liabilities
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Adjusted Current Liabilities Coverage Ratio: [Funds from
                    Operation - Dividends] /Total Current Liabilities
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Interest Coverage Ratio: [Cash Flow from Operations +
                    Interest Paid + Taxes] / Interest Paid]
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Comprehensive Interest Coverage Ratio: [Free Cash Flow +
                    Interest Paid + Taxes] / Interest Paid]
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.comprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    External Financing Index Ratio : Cash from Financing / Funds
                    from Operations
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.externalFinancingIndexRatioCashfromFinancingFundsfromOperations
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash Flow - Operating Activities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.cashFlowOperatingActivities}</td>;
                  })}
                </tr>
                <tr>
                  <th>Cash Flow - Financing Activities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.cashFlowFinancingActivities}</td>;
                  })}
                </tr>
                <tr>
                  <th>Cash Flow - Investing Activities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.cashFlowInvestingActivities}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Cash Flow Management: Financing Cash Flow / Investing Cash
                    Flow
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.cashFlowManagementFinancingCashFlowInvestingCashFlow
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Free Cash Flow : Funds from Operations - Capital
                    Expenditures
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.freeCashFlowFundsfromOperationsCapitalExpenditures
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow - Year to Year % Change</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowYeartoYearPercentChange}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Unlevered Free Cash Flow: EBITDA - Capital Expenditure -
                    Working Capital - Taxes
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Unlevered Free Cash Flow % Total Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.unleveredFreeCashFlowPercentTotalAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Unlevered Free Cash Flow % Enterprise Value</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.unleveredFreeCashFlowPercentEnterpriseValue}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Unlevered Free Cash Flow % Shareholders Equity</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.unleveredFreeCashFlowPercentShareholdersEquity}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Levered Free Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.leveredFreeCashFlow}</td>;
                  })}
                </tr>
                <tr>
                  <th>Levered Free Cash Flow % Total Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.leveredFreeCashFlowPercentTotalAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Levered Free Cash Flow % Enterprise Value</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.leveredFreeCashFlowPercentEnterpriseValue}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Levered Free Cash Flow % Shareholders Equity</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.leveredFreeCashFlowPercentShareholdersEquity}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Levered Free Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.freeCashFlowPercentLeveredFreeCashFlow}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Unlevered Free Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.freeCashFlowPercentUnleveredFreeCashFlow}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Levered Free Cash Flow % Unlevered Free Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.leveredFreeCashFlowPercentUnleveredFreeCashFlow}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Short-Term Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentShortTermDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Long-Term Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentLongTermDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.freeCashFlowPercentFundsfromOperations}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Net Sales</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentNetSales}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Total Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentTotalAssets}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Operating Income</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentOperatingIncome}</td>;
                  })}
                </tr>

                <tr>
                  <th>Free Cash Flow Percent Total Liabilities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentTotalLiabilities}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Market Capitalization</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.freeCashFlowPercentMarketCapitalization}</td>
                    );
                  })}
                </tr>

                <tr>
                  <th>Free Cash Flow % Enterprise Value</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.freeCashFlowPercentEnterpriseValue}</td>;
                  })}
                </tr>
                <tr>
                  <th>Free Cash Flow % Common Shareholders' Equity</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.freeCashFlowPercentCommonShareholdersEquity}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.absoluteFreeCashFlow}</td>;
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow - Year to Year % Change</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.absoluteFreeCashFlowYeartoYearPercentChange}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Short-Term Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.absoluteFreeCashFlowPercentShortTermDebt}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Long-Term Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.absoluteFreeCashFlowPercentLongTermDebt}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Operating Income</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.absoluteFreeCashFlowPercentOperatingIncome}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Total Liabilities</th>

                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.absoluteFreeCashFlowPercentTotalLiabilities}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Net Sales </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.absoluteFreeCashFlowPercentNetSales}</td>;
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Total Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.absoluteFreeCashFlowPercentTotalAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.absoluteFreeCashFlowPercentFundsfromOperations}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Market Capitalization</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.absoluteFreeCashFlowPercentMarketCapitalization}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Enterprise Value</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.absoluteFreeCashFlowPercentEnterpriseValue}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Absolute Free Cash Flow % Common Shareholders' Equity</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.absoluteFreeCashFlowPercentCommonShareholdersEquity
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash Flow Ratio</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.cashFlowRatio}</td>;
                  })}
                </tr>
                <tr>
                  <th>Market Capitalization</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.marketCapitalization}</td>;
                  })}
                </tr>
                <tr>
                  <th>Market Capitalization - Year to Year % Change</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.marketCapitalizationYeartoYearPercentChange}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Sales - Year to Year % Change</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netSalesYeartoYearPercentChange}</td>;
                  })}
                </tr>
                <tr>
                  <th>Funds from Operations - Year to Year % Change</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsfromOperationsYeartoYearPercentChange}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Short-Term Debt Coverage Ratio : Funds from Operations %
                    Short-Term Debt
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.shortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Long-Term Debt Coverage Ratio: Funds from Operations %
                    Long-Term Debt
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.longTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Adjusted Long-Term Debt Coverage Ratio: [Funds from
                    Operations - Dividends] / Total Long-Term Debt
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.adjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Total Debt Coverage Ratio: Funds From Operations % Total
                    Debt
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.totalDebtCoverageRatioFundsFromOperationsPercentTotalDebt
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Adjusted Total Debt Coverage Ratio: [Funds From Operations -
                    Dividends] % Total Debt
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.adjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash Dividends % Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.cashDividendsPercentFundsfromOperations}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Sales % Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netSalesPercentFundsfromOperations}</td>;
                  })}
                </tr>
                <tr>
                  <th>Common Stock Price / Cash Flow per Share</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.commonStockPriceCashFlowperShare}</td>;
                  })}
                </tr>
                <tr>
                  <th>Common Stock Price / Earnings per Share</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.commonStockPriceEarningsperShare}</td>;
                  })}
                </tr>
                <tr>
                  <th>Earnings per Share / Cash Flow per Share</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.earningsperShareCashFlowperShare}</td>;
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Total Liabilities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsFromOperationsPercentTotalLiabilities}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Long-Term Borrowing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentLongTermBorrowing}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Capital Expenditures</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentCapitalExpenditures}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Cash Dividends Paid</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentCashDividendsPaid}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Net Sales</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.fundsFromOperationsPercentNetSales}</td>;
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Total Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsFromOperationsPercentTotalAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Total Current Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentTotalCurrentAssets}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Working Capital</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsFromOperationsPercentWorkingCapital}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Property, Plant & Equipment</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.fundsFromOperationsPercentPropertyPlantAndEquipment
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Reasearch & Development</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentReasearchAndDevelopment}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Total Capital</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsFromOperationsPercentTotalCapital}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Provision for Bad Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentProvisionforBadDebt}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Market Capitalization</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.fundsFromOperationsPercentMarketCapitalization}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Enterprise Value</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.fundsFromOperationsPercentEnterpriseValue}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations % Common Shareholders Equity</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {
                          data.fundsFromOperationsPercentCommonShareholdersEquity
                        }
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations / Fixed Charges</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.fundsFromOperationsFixedCharges}</td>;
                  })}
                </tr>
                <tr>
                  <th>Funds From Operations / DDA</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.fundsFromOperationsDDA}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Sales</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netSales}</td>;
                  })}
                </tr>
                <tr>
                  <th>Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.fundsfromOperations}</td>;
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px 10px" }}>
                    <span style={{ padding: "20px 0px 0px 2px" }}>
                      <span className="section_header"> Ratios Analysis</span>
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.year}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEnd}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Market Capitalization
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.marketCapitalization}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Market Capitalization - Year to Year % Change</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.marketCapitalizationYeartoYearPercentChange}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Sales
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Sales")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netSales}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Sales - Year to Year % Change
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Sales - Year to Year % Change"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netSalesYeartoYearPercentChange}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Funds from Operations")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fundsfromOperations}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Cash Flow Indicator Ratios
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds from Operations - Year to Year % Change
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds from Operations - Year to Year % Change"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsfromOperationsYeartoYearPercentChange}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Short-Term Debt Coverage Ratio : Funds from Operations %
                      Short-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short-Term Debt Coverage Ratio : Funds from Operations % Short-Term Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.shortTermDebtCoverageRatioFundsfromOperationsPercentShortTermDebt
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Long-Term Debt Coverage Ratio: Funds from Operations %
                      Long-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Coverage Ratio: Funds from Operations % Long-Term Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.longTermDebtCoverageRatioFundsfromOperationsPercentLongTermDebt
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Adjusted Long-Term Debt Coverage Ratio: [Funds from
                      Operations - Dividends] / Total Long-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Adjusted Long-Term Debt Coverage Ratio: [Funds from Operations - Dividends] / Total Long-Term Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.adjustedLongTermDebtCoverageRatioFundsfromOperationsDividendsTotalLongTermDebt
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt Coverage Ratio: Funds From Operations % Total
                      Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt Coverage Ratio: Funds From Operations % Total Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.totalDebtCoverageRatioFundsFromOperationsPercentTotalDebt
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Adjusted Total Debt Coverage Ratio: [Funds From Operations
                      - Dividends] % Total Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Adjusted Total Debt Coverage Ratio: [Funds From Operations - Dividends] % Total Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.adjustedTotalDebtCoverageRatioFundsFromOperationsDividendsPercentTotalDebt
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash Dividends % Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Dividends % Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.cashDividendsPercentFundsfromOperations}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Sales % Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Sales % Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netSalesPercentFundsfromOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Stock Price / Cash Flow per Share</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.commonStockPriceCashFlowperShare}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Stock Price / Earnings per Share</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.commonStockPriceEarningsperShare}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Earnings per Share / Cash Flow per Share
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Earnings per Share / Cash Flow per Share"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.earningsperShareCashFlowperShare}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations % Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations % Total Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentTotalLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Long-Term Borrowing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentLongTermBorrowing}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Capital Expenditures</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentCapitalExpenditures}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Cash Dividends Paid</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentCashDividendsPaid}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations % Net Sales
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations % Net Sales"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.fundsFromOperationsPercentNetSales}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Total Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.fundsFromOperationsPercentTotalAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Total Current Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentTotalCurrentAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Working Capital</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.fundsFromOperationsPercentWorkingCapital}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Property, Plant & Equipment</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.fundsFromOperationsPercentPropertyPlantAndEquipment
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Reasearch & Development</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.fundsFromOperationsPercentReasearchAndDevelopment
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations % Total Capital
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations % Total Capital"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.fundsFromOperationsPercentTotalCapital}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Provision for Bad Debt</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentProvisionforBadDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Market Capitalization</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentMarketCapitalization}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations % Enterprise Value
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations % Enterprise Value"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.fundsFromOperationsPercentEnterpriseValue}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations % Common Shareholders Equity</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.fundsFromOperationsPercentCommonShareholdersEquity
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds From Operations / Fixed Charges
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds From Operations / Fixed Charges"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.fundsFromOperationsFixedCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Funds From Operations / DDA</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.fundsFromOperationsDDA}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash Dividends Coverage Ratio: Funds from Operations /
                      Cash Dividends
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Dividends Coverage Ratio: Funds from Operations / Cash Dividends"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.cashDividendsCoverageRatioFundsfromOperationsCashDividends
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cost of Goods Sold / Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cost of Goods Sold / Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.costofGoodsSoldFundsfromOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Accumulated DDA / Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Accumulated DDA / Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.accumulatedDDAFundsfromOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Current Liabilities Coverage Ratio: Funds from Operations
                      / Total Current Liabilities
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.currentLiabilitiesCoverageRatioFundsfromOperationsTotalCurrentLiabilities
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Adjusted Current Liabilities Coverage Ratio: [Funds from
                      Operation - Dividends] /Total Current Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Adjusted Current Liabilities Coverage Ratio: [Funds from Operation - Dividends] /Total Current Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.adjustedCurrentLiabilitiesCoverageRatioFundsfromOperationDividendsTotalCurrentLiabilities
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Interest Coverage Ratio: [Cash Flow from Operations +
                      Interest Paid + Taxes] / Interest Paid]
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Interest Coverage Ratio: [Cash Flow from Operations + Interest Paid + Taxes] / Interest Paid]"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.interestCoverageRatioCashFlowfromOperationsInterestPaidTaxesInterestPaid
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Comprehensive Interest Coverage Ratio: [Free Cash Flow +
                      Interest Paid + Taxes] / Interest Paid]
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Comprehensive Interest Coverage Ratio: [Free Cash Flow + Interest Paid + Taxes] / Interest Paid]"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.comprehensiveInterestCoverageRatioFreeCashFlowInterestPaidTaxesInterestPaid
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      External Financing Index Ratio : Cash from Financing /
                      Funds from Operations
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.externalFinancingIndexRatioCashfromFinancingFundsfromOperations
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Cash Flow - Operating Activities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Flow - Operating Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.cashFlowOperatingActivities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Cash Flow - Financing Activities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Flow - Financing Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.cashFlowFinancingActivities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Cash Flow - Investing Activities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Flow - Investing Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.cashFlowInvestingActivities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash Flow Management: Financing Cash Flow / Investing Cash
                      Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Flow Management: Financing Cash Flow / Investing Cash Flow"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          { 
                            data.cashFlowManagementFinancingCashFlowInvestingCashFlow
                            /* data ?
                            data.cashFlowManagementFinancingCashFlowInvestingCashFlow
                            .replace('%', '') : data */
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Cash Flow Ratio</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.cashFlowRatio}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Free Cash Flow : Funds from Operations - Capital
                      Expenditures
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow : Funds from Operations - Capital Expenditures"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {
                            data.freeCashFlowFundsfromOperationsCapitalExpenditures
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow - Year to Year % Change
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow - Year to Year % Change"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowYeartoYearPercentChange}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Unlevered Free Cash Flow: EBITDA - Capital Expenditure -
                      Working Capital - Taxes
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unlevered Free Cash Flow: EBITDA - Capital Expenditure - Working Capital - Taxes"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.unleveredFreeCashFlowEBITDACapitalExpenditureWorkingCapitalTaxes
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Unlevered Free Cash Flow % Total Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.unleveredFreeCashFlowPercentTotalAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Unlevered Free Cash Flow % Enterprise Value
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unlevered Free Cash Flow % Enterprise Value"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.unleveredFreeCashFlowPercentEnterpriseValue}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Unlevered Free Cash Flow % Shareholders Equity</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.unleveredFreeCashFlowPercentShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Levered Free Cash Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Levered Free Cash Flow")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.leveredFreeCashFlow}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Levered Free Cash Flow % Total Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.leveredFreeCashFlowPercentTotalAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Levered Free Cash Flow % Enterprise Value
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Levered Free Cash Flow % Enterprise Value"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.leveredFreeCashFlowPercentEnterpriseValue}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Levered Free Cash Flow % Shareholders Equity</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.leveredFreeCashFlowPercentShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Levered Free Cash Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Levered Free Cash Flow"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowPercentLeveredFreeCashFlow}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Unlevered Free Cash Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Unlevered Free Cash Flow"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowPercentUnleveredFreeCashFlow}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Levered Free Cash Flow % Unlevered Free Cash Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Levered Free Cash Flow % Unlevered Free Cash Flow"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.leveredFreeCashFlowPercentUnleveredFreeCashFlow}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Free Cash Flow % Short-Term Debt</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentShortTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Free Cash Flow % Long-Term Debt</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentLongTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowPercentFundsfromOperations}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Net Sales
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Net Sales"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentNetSales}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentTotalAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Free Cash Flow % Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Operating Income"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentOperatingIncome}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>
                      Free Cash Flow % Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Free Cash Flow % Total Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowPercentTotalLiabilities}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Free Cash Flow % Market Capitalization</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.freeCashFlowPercentMarketCapitalization}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <th>Free Cash Flow % Enterprise Value</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.freeCashFlowPercentEnterpriseValue}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Free Cash Flow % Common Shareholders' Equity</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.freeCashFlowPercentCommonShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Absolute Free Cash Flow
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Absolute Free Cash Flow")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.absoluteFreeCashFlow}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow - Year to Year % Change
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow - Year to Year % Change"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowYeartoYearPercentChange}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Absolute Free Cash Flow % Short-Term Debt</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.absoluteFreeCashFlowPercentShortTermDebt}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Absolute Free Cash Flow % Long-Term Debt</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.absoluteFreeCashFlowPercentLongTermDebt}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Operating Income
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Operating Income"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowPercentOperatingIncome}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Total Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>

                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowPercentTotalLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Absolute Free Cash Flow % Net Sales </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.absoluteFreeCashFlowPercentNetSales}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Total Assets"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.absoluteFreeCashFlowPercentTotalAssets}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Funds from Operations
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Funds from Operations"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowPercentFundsfromOperations}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Market Capitalization
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Market Capitalization"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowPercentMarketCapitalization}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Enterprise Value
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Absolute Free Cash Flow % Enterprise Value"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.absoluteFreeCashFlowPercentEnterpriseValue}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Absolute Free Cash Flow % Common Shareholders' Equity
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.absoluteFreeCashFlowPercentCommonShareholdersEquity
                          }
                        </td>
                      );
                    })}
                  </tr>
                </Table>

                {/* {itemRow} depreciationDepletionAndAmortization
                </Table>
              </TableContainer> */}
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Ratio Analysis
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
