import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./InsuranceBalanceSheet.css";

export default class InsuranceBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      actualData: [],
      balanceSheetData: [],
      render: false,
      modalHeader: "",
      isCommonSize: false,
      noYrToYr: true,
      isYrToYr: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values;
    } else {
      values = "";
      return values;
    }
  }
  convertToMillionaire1(value) {
    let values = value;
    if (values === null || values === undefined || values === "") {
      values = "";
      return values;
    } else {
      values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
      values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return values + "%";
    }
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentsTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Investments – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.Cash,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Fixed Income Securities Investment – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FixedIncomeSecuritiesInvestmentTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Fixed Income Securities Investment – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Bonds") {
      this.setState({
        graphDetails: {
          graphValues: this.state.Bonds,
          labelData: this.state.LabelList,
          yAxisTitle: "Bonds",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Redeemable Preferred Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.RedeemablePreferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Redeemable Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Equity Securities Investment – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EquitySecuritiesInvestmentTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Equity Securities Investment – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Stocks") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonStocks,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Stocks",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Redeemable Preferred Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NonRedeemablePreferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Redeemable Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Real Estate Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.RealEstateAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Real Estate Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Mortgage, Policy & Other Loans") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MortgagePolicyAndOtherLoans,
          labelData: this.state.LabelList,
          yAxisTitle: "Mortgage, Policy & Other Loans",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investments in Associated Companies") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentsInAssociatedCompanies,
          labelData: this.state.LabelList,
          yAxisTitle: "Investments in Associated Companies",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Premium Balance Receivables") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PremiumBalanceReceivables,
          labelData: this.state.LabelList,
          yAxisTitle: "Premium Balance Receivables",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Property, Plant and Equipment – Net") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PropertyPlantAndEquipmentNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Property, Plant and Equipment – Net",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Separate & Variable Account Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.SeparateAndVariableAccountAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Separate & Variable Account Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Tangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Tangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Intangible Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IntangibleOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Intangible Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Insurance Reserves – Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InsuranceReservesTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Insurance Reserves – Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Benefit & Loss Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.BenefitAndLossReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Benefit & Loss Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Unearned Premiums") {
      this.setState({
        graphDetails: {
          graphValues: this.state.UnearnedPremiums,
          labelData: this.state.LabelList,
          yAxisTitle: "Unearned Premiums",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Policy & Contract Claims") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PolicyAndContractClaims,
          labelData: this.state.LabelList,
          yAxisTitle: "Policy & Contract Claims",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Insurance Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherInsuranceReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Insurance Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Short Term Debt & Current Portion of Long Term De"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.ShortTermDebtAndCurrentPortionofLongTermDe,
          labelData: this.state.LabelList,
          yAxisTitle: "Short Term Debt & Current Portion of Long Term De",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long-Term Debt Excluding Capitalized Leases") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermDebtExcludingCapitalizedLeases,
          labelData: this.state.LabelList,
          yAxisTitle: "Long-Term Debt Excluding Capitalized Leases",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capitalized Lease Obligations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CapitalizedLeaseObligations,
          labelData: this.state.LabelList,
          yAxisTitle: "Capitalized Lease Obligations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Provision for Risks and Charges") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ProvisionForRisksAndCharges,
          labelData: this.state.LabelList,
          yAxisTitle: "Provision for Risks and Charges",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Income") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredIncome,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Income",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes - Credit") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxesCredit,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes - Credit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Taxes - Debit") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxesDebit,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Taxes - Debit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Tax Liability in Untaxed Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredTaxLiabilityinUntaxedReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Tax Liability in Untaxed Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Non-Equity Reserves") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NonEquityReserves,
          labelData: this.state.LabelList,
          yAxisTitle: "Non-Equity Reserves",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Minority Interest") {
      this.setState({
        graphDetails: {
          graphValues: this.state.MinorityInterest,
          labelData: this.state.LabelList,
          yAxisTitle: "Minority Interest",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Stock Issued for ESOP") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredStockIssuedforESOP,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Stock Issued for ESOP",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "ESOP Guarantees - Preferred Issued") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ESOPGuaranteesPreferredIssued,
          labelData: this.state.LabelList,
          yAxisTitle: "ESOP Guarantees - Preferred Issued",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Policyholders' Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PolicyholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Policyholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities & Shareholders' Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilitiesShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities & Shareholders' Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  componentDidMount() {
    if (this.state.type === "Income Statement Actual") {
      this.state.balanceSheetData = [];
      let PolicyholdersEquity = [];
      let CommonEquity = [];
      let ESOPGuaranteesPreferredIssued = [];
      let PreferredStockIssuedforESOP = [];
      let PreferredStock = [];
      let MinorityInterest = [];
      let NonEquityReserves = [];
      let TotalLiabilities = [];
      let OtherLiabilities = [];
      let DeferredTaxLiabilityinUntaxedReserves = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxes = [];
      let DeferredIncome = [];
      let ProvisionForRisksAndCharges = [];
      let CapitalizedLeaseObligations = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let LongTermDebt = [];
      let ShortTermDebtAndCurrentPortionofLongTermDe = [];
      let TotalDebt = [];
      let OtherInsuranceReserves = [];
      let PolicyAndContractClaims = [];
      let UnearnedPremiums = [];
      let BenefitAndLossReserves = [];
      let InsuranceReservesTotal = [];
      let IntangibleOtherAssets = [];
      let TangibleOtherAssets = [];
      let DeferredCharges = [];
      let SeparateAndVariableAccountAssets = [];
      let OtherAssets = [];
      let PropertyPlantAndEquipmentNet = [];
      let PremiumBalanceReceivables = [];
      let InvestmentsInAssociatedCompanies = [];
      let OtherInvestments = [];
      let MortgagePolicyAndOtherLoans = [];
      let RealEstateAssets = [];
      let NonRedeemablePreferredStock = [];
      let CommonStocks = [];
      let EquitySecuritiesInvestmentTotal = [];
      let RedeemablePreferredStock = [];
      let Bonds = [];
      let FixedIncomeSecuritiesInvestmentTotal = [];
      let Cash = [];
      let InvestmentsTotal = [];
      let totalAssets = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.insBalanceSheetList;

      let totalLiabilitiesShareholdersEquity = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalLiabilitiesShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        PolicyholdersEquity.push(balanceSheetData[i].policyholdersEquity);
        CommonEquity.push(balanceSheetData[i].commonEquity);
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        PreferredStock.push(balanceSheetData[i].preferredStock);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        DeferredTaxLiabilityinUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionsforRisksandCharges
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        ShortTermDebtAndCurrentPortionofLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        TotalDebt.push(balanceSheetData[i].totalDebt);
        OtherInsuranceReserves.push(balanceSheetData[i].otherInsuranceReserves);
        PolicyAndContractClaims.push(balanceSheetData[i].policyContractClaims);
        UnearnedPremiums.push(balanceSheetData[i].unearnedPremiums);
        BenefitAndLossReserves.push(balanceSheetData[i].benefitLossReserves);
        InsuranceReservesTotal.push(balanceSheetData[i].insuranceReservesTotal);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        SeparateAndVariableAccountAssets.push(
          balanceSheetData[i].separateVariableAccountAssets
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        PremiumBalanceReceivables.push(
          balanceSheetData[i].premiumBalanceReceivables
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        MortgagePolicyAndOtherLoans.push(
          balanceSheetData[i].mortgagePolicyOtherLoans
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        NonRedeemablePreferredStock.push(
          balanceSheetData[i].nonRedeemablePreferredStock
        );
        CommonStocks.push(balanceSheetData[i].commonStocks);
        EquitySecuritiesInvestmentTotal.push(
          balanceSheetData[i].equitySecuritiesInvestmentTotal
        );
        RedeemablePreferredStock.push(
          balanceSheetData[i].redeemablePreferredStock
        );
        Bonds.push(balanceSheetData[i].bonds);
        FixedIncomeSecuritiesInvestmentTotal.push(
          balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
        );
        Cash.push(balanceSheetData[i].cash);
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        totalAssets.push(balanceSheetData[i].totalAssets);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
          );
        balanceSheetData[i].bonds = this.convertToMillionaire(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].equitySecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].equitySecuritiesInvestmentTotal
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].mortgagePolicyOtherLoans =
          this.convertToMillionaire(
            balanceSheetData[i].mortgagePolicyOtherLoans
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].insuranceReservesTotal = this.convertToMillionaire(
          balanceSheetData[i].insuranceReservesTotal
        );
        balanceSheetData[i].benefitLossReserves = this.convertToMillionaire(
          balanceSheetData[i].benefitLossReserves
        );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].policyContractClaims = this.convertToMillionaire(
          balanceSheetData[i].policyContractClaims
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].policyholdersEquity = this.convertToMillionaire(
          balanceSheetData[i].policyholdersEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        LabelList: LabelList,
        PolicyholdersEquity: PolicyholdersEquity,
        CommonEquity: CommonEquity,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        PreferredStock: PreferredStock,
        MinorityInterest: MinorityInterest,
        NonEquityReserves: NonEquityReserves,
        TotalLiabilities: TotalLiabilities,
        OtherLiabilities: OtherLiabilities,
        DeferredTaxLiabilityinUntaxedReserves:
          DeferredTaxLiabilityinUntaxedReserves,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxes: DeferredTaxes,
        DeferredIncome: DeferredIncome,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        LongTermDebt: LongTermDebt,
        ShortTermDebtAndCurrentPortionofLongTermDe:
          ShortTermDebtAndCurrentPortionofLongTermDe,
        TotalDebt: TotalDebt,
        OtherInsuranceReserves: OtherInsuranceReserves,
        PolicyAndContractClaims: PolicyAndContractClaims,
        UnearnedPremiums: UnearnedPremiums,
        BenefitAndLossReserves: BenefitAndLossReserves,
        InsuranceReservesTotal: InsuranceReservesTotal,
        TotalLiabilities: TotalLiabilities,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TangibleOtherAssets: TangibleOtherAssets,
        DeferredCharges: DeferredCharges,
        SeparateAndVariableAccountAssets: SeparateAndVariableAccountAssets,
        OtherAssets: OtherAssets,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        PremiumBalanceReceivables: PremiumBalanceReceivables,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        OtherInvestments: OtherInvestments,
        MortgagePolicyAndOtherLoans: MortgagePolicyAndOtherLoans,
        RealEstateAssets: RealEstateAssets,
        NonRedeemablePreferredStock: NonRedeemablePreferredStock,
        CommonStocks: CommonStocks,
        EquitySecuritiesInvestmentTotal: EquitySecuritiesInvestmentTotal,
        RedeemablePreferredStock: RedeemablePreferredStock,
        Bonds: Bonds,
        FixedIncomeSecuritiesInvestmentTotal:
          FixedIncomeSecuritiesInvestmentTotal,
        Cash: Cash,
        InvestmentsTotal: InvestmentsTotal,
        totalAssets: totalAssets,
      });
    } else if (this.state.type === "Income Statement Common Size") {
      let PolicyholdersEquity = [];
      let CommonEquity = [];
      let ESOPGuaranteesPreferredIssued = [];
      let PreferredStockIssuedforESOP = [];
      let PreferredStock = [];
      let MinorityInterest = [];
      let NonEquityReserves = [];
      let TotalLiabilities = [];
      let OtherLiabilities = [];
      let DeferredTaxLiabilityinUntaxedReserves = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxes = [];
      let DeferredIncome = [];
      let ProvisionForRisksAndCharges = [];
      let CapitalizedLeaseObligations = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let LongTermDebt = [];
      let ShortTermDebtAndCurrentPortionofLongTermDe = [];
      let TotalDebt = [];
      let OtherInsuranceReserves = [];
      let PolicyAndContractClaims = [];
      let UnearnedPremiums = [];
      let BenefitAndLossReserves = [];
      let InsuranceReservesTotal = [];
      let IntangibleOtherAssets = [];
      let TangibleOtherAssets = [];
      let DeferredCharges = [];
      let SeparateAndVariableAccountAssets = [];
      let OtherAssets = [];
      let PropertyPlantAndEquipmentNet = [];
      let PremiumBalanceReceivables = [];
      let InvestmentsInAssociatedCompanies = [];
      let OtherInvestments = [];
      let MortgagePolicyAndOtherLoans = [];
      let RealEstateAssets = [];
      let NonRedeemablePreferredStock = [];
      let CommonStocks = [];
      let EquitySecuritiesInvestmentTotal = [];
      let RedeemablePreferredStock = [];
      let Bonds = [];
      let FixedIncomeSecuritiesInvestmentTotal = [];
      let Cash = [];
      let InvestmentsTotal = [];
      let totalAssets = [];
      let LabelList = [];
      let totalLiabilitiesShareholdersEquity = [];
      let balanceSheetData = this.state.data.insBalanceSheetPercentChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      // let balanceSheetData = this.state.data.insBalanceSheetPercentChangeList;
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalLiabilitiesShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        PolicyholdersEquity.push(balanceSheetData[i].policyholdersEquity);
        CommonEquity.push(balanceSheetData[i].commonEquity);
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        PreferredStock.push(balanceSheetData[i].preferredStock);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        DeferredTaxLiabilityinUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionsforRisksandCharges
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        ShortTermDebtAndCurrentPortionofLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        TotalDebt.push(balanceSheetData[i].totalDebt);
        OtherInsuranceReserves.push(balanceSheetData[i].otherInsuranceReserves);
        PolicyAndContractClaims.push(balanceSheetData[i].policyContractClaims);
        UnearnedPremiums.push(balanceSheetData[i].unearnedPremiums);
        BenefitAndLossReserves.push(balanceSheetData[i].benefitLossReserves);
        InsuranceReservesTotal.push(balanceSheetData[i].insuranceReservesTotal);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        SeparateAndVariableAccountAssets.push(
          balanceSheetData[i].separateVariableAccountAssets
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        PremiumBalanceReceivables.push(
          balanceSheetData[i].premiumBalanceReceivables
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        MortgagePolicyAndOtherLoans.push(
          balanceSheetData[i].mortgagePolicyOtherLoans
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        NonRedeemablePreferredStock.push(
          balanceSheetData[i].nonRedeemablePreferredStock
        );
        CommonStocks.push(balanceSheetData[i].commonStocks);
        EquitySecuritiesInvestmentTotal.push(
          balanceSheetData[i].equitySecuritiesInvestmentTotal
        );
        RedeemablePreferredStock.push(
          balanceSheetData[i].redeemablePreferredStock
        );
        Bonds.push(balanceSheetData[i].bonds);
        FixedIncomeSecuritiesInvestmentTotal.push(
          balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
        );
        Cash.push(balanceSheetData[i].cash);
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        totalAssets.push(balanceSheetData[i].totalAssets);

        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
          );
        balanceSheetData[i].bonds = this.convertToMillionaire1(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire1(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].equitySecuritiesInvestmentTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].equitySecuritiesInvestmentTotal
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire1(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire1(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].mortgagePolicyOtherLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].mortgagePolicyOtherLoans
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire1(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].insuranceReservesTotal = this.convertToMillionaire1(
          balanceSheetData[i].insuranceReservesTotal
        );
        balanceSheetData[i].benefitLossReserves = this.convertToMillionaire1(
          balanceSheetData[i].benefitLossReserves
        );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire1(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].policyContractClaims = this.convertToMillionaire1(
          balanceSheetData[i].policyContractClaims
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire1(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].policyholdersEquity = this.convertToMillionaire1(
          balanceSheetData[i].policyholdersEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: true,
        noYrToYr: false,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Common Size",
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        LabelList: LabelList,
        PolicyholdersEquity: PolicyholdersEquity,
        CommonEquity: CommonEquity,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        PreferredStock: PreferredStock,
        MinorityInterest: MinorityInterest,
        NonEquityReserves: NonEquityReserves,
        TotalLiabilities: TotalLiabilities,
        OtherLiabilities: OtherLiabilities,
        DeferredTaxLiabilityinUntaxedReserves:
          DeferredTaxLiabilityinUntaxedReserves,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxes: DeferredTaxes,
        DeferredIncome: DeferredIncome,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        LongTermDebt: LongTermDebt,
        ShortTermDebtAndCurrentPortionofLongTermDe:
          ShortTermDebtAndCurrentPortionofLongTermDe,
        TotalDebt: TotalDebt,
        OtherInsuranceReserves: OtherInsuranceReserves,
        PolicyAndContractClaims: PolicyAndContractClaims,
        UnearnedPremiums: UnearnedPremiums,
        BenefitAndLossReserves: BenefitAndLossReserves,
        InsuranceReservesTotal: InsuranceReservesTotal,
        TotalLiabilities: TotalLiabilities,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TangibleOtherAssets: TangibleOtherAssets,
        DeferredCharges: DeferredCharges,
        SeparateAndVariableAccountAssets: SeparateAndVariableAccountAssets,
        OtherAssets: OtherAssets,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        PremiumBalanceReceivables: PremiumBalanceReceivables,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        OtherInvestments: OtherInvestments,
        MortgagePolicyAndOtherLoans: MortgagePolicyAndOtherLoans,
        RealEstateAssets: RealEstateAssets,
        NonRedeemablePreferredStock: NonRedeemablePreferredStock,
        CommonStocks: CommonStocks,
        EquitySecuritiesInvestmentTotal: EquitySecuritiesInvestmentTotal,
        RedeemablePreferredStock: RedeemablePreferredStock,
        Bonds: Bonds,
        FixedIncomeSecuritiesInvestmentTotal:
          FixedIncomeSecuritiesInvestmentTotal,
        Cash: Cash,
        InvestmentsTotal: InvestmentsTotal,
        totalAssets: totalAssets,
      });
    } else if (this.state.type === "Income Statement Year-Year") {
      let PolicyholdersEquity = [];
      let CommonEquity = [];
      let ESOPGuaranteesPreferredIssued = [];
      let PreferredStockIssuedforESOP = [];
      let PreferredStock = [];
      let MinorityInterest = [];
      let NonEquityReserves = [];
      let TotalLiabilities = [];
      let OtherLiabilities = [];
      let DeferredTaxLiabilityinUntaxedReserves = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxes = [];
      let DeferredIncome = [];
      let ProvisionForRisksAndCharges = [];
      let CapitalizedLeaseObligations = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let LongTermDebt = [];
      let ShortTermDebtAndCurrentPortionofLongTermDe = [];
      let TotalDebt = [];
      let OtherInsuranceReserves = [];
      let PolicyAndContractClaims = [];
      let UnearnedPremiums = [];
      let BenefitAndLossReserves = [];
      let InsuranceReservesTotal = [];
      let IntangibleOtherAssets = [];
      let TangibleOtherAssets = [];
      let DeferredCharges = [];
      let SeparateAndVariableAccountAssets = [];
      let OtherAssets = [];
      let PropertyPlantAndEquipmentNet = [];
      let PremiumBalanceReceivables = [];
      let InvestmentsInAssociatedCompanies = [];
      let OtherInvestments = [];
      let MortgagePolicyAndOtherLoans = [];
      let RealEstateAssets = [];
      let NonRedeemablePreferredStock = [];
      let CommonStocks = [];
      let EquitySecuritiesInvestmentTotal = [];
      let RedeemablePreferredStock = [];
      let Bonds = [];
      let FixedIncomeSecuritiesInvestmentTotal = [];
      let Cash = [];
      let InvestmentsTotal = [];
      let totalAssets = [];
      let LabelList = [];
      let totalLiabilitiesShareholdersEquity = [];
      let balanceSheetData = this.state.data.insBalanceSheetYoYChangeList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      // let balanceSheetData = this.state.data.insBalanceSheetPercentChangeList;
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalLiabilitiesShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        PolicyholdersEquity.push(balanceSheetData[i].policyholdersEquity);
        CommonEquity.push(balanceSheetData[i].commonEquity);
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        PreferredStock.push(balanceSheetData[i].preferredStock);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        DeferredTaxLiabilityinUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionsforRisksandCharges
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        ShortTermDebtAndCurrentPortionofLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        TotalDebt.push(balanceSheetData[i].totalDebt);
        OtherInsuranceReserves.push(balanceSheetData[i].otherInsuranceReserves);
        PolicyAndContractClaims.push(balanceSheetData[i].policyContractClaims);
        UnearnedPremiums.push(balanceSheetData[i].unearnedPremiums);
        BenefitAndLossReserves.push(balanceSheetData[i].benefitLossReserves);
        InsuranceReservesTotal.push(balanceSheetData[i].insuranceReservesTotal);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        SeparateAndVariableAccountAssets.push(
          balanceSheetData[i].separateVariableAccountAssets
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        PremiumBalanceReceivables.push(
          balanceSheetData[i].premiumBalanceReceivables
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        MortgagePolicyAndOtherLoans.push(
          balanceSheetData[i].mortgagePolicyOtherLoans
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        NonRedeemablePreferredStock.push(
          balanceSheetData[i].nonRedeemablePreferredStock
        );
        CommonStocks.push(balanceSheetData[i].commonStocks);
        EquitySecuritiesInvestmentTotal.push(
          balanceSheetData[i].equitySecuritiesInvestmentTotal
        );
        RedeemablePreferredStock.push(
          balanceSheetData[i].redeemablePreferredStock
        );
        Bonds.push(balanceSheetData[i].bonds);
        FixedIncomeSecuritiesInvestmentTotal.push(
          balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
        );
        Cash.push(balanceSheetData[i].cash);
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        totalAssets.push(balanceSheetData[i].totalAssets);
        balanceSheetData[i].totalAssets = this.convertToMillionaire1(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire1(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cash = this.convertToMillionaire1(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire1(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
          );
        balanceSheetData[i].bonds = this.convertToMillionaire1(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire1(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].equitySecuritiesInvestmentTotal =
          this.convertToMillionaire1(
            balanceSheetData[i].equitySecuritiesInvestmentTotal
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire1(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire1(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire1(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].mortgagePolicyOtherLoans =
          this.convertToMillionaire1(
            balanceSheetData[i].mortgagePolicyOtherLoans
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire1(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire1(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire1(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire1(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire1(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire1(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire1(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire1(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].insuranceReservesTotal = this.convertToMillionaire1(
          balanceSheetData[i].insuranceReservesTotal
        );
        balanceSheetData[i].benefitLossReserves = this.convertToMillionaire1(
          balanceSheetData[i].benefitLossReserves
        );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire1(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].policyContractClaims = this.convertToMillionaire1(
          balanceSheetData[i].policyContractClaims
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire1(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire1(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire1(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire1(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire1(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire1(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire1(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire1(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire1(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire1(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire1(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire1(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire1(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire1(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire1(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire1(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire1(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].policyholdersEquity = this.convertToMillionaire1(
          balanceSheetData[i].policyholdersEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: false,
        isYrToYr: true,
        modalHeader: " Balance Sheet -Yr-yr % change",
        LabelList: LabelList,
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        PolicyholdersEquity: PolicyholdersEquity,
        CommonEquity: CommonEquity,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        PreferredStock: PreferredStock,
        MinorityInterest: MinorityInterest,
        NonEquityReserves: NonEquityReserves,
        TotalLiabilities: TotalLiabilities,
        OtherLiabilities: OtherLiabilities,
        DeferredTaxLiabilityinUntaxedReserves:
          DeferredTaxLiabilityinUntaxedReserves,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxes: DeferredTaxes,
        DeferredIncome: DeferredIncome,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        LongTermDebt: LongTermDebt,
        ShortTermDebtAndCurrentPortionofLongTermDe:
          ShortTermDebtAndCurrentPortionofLongTermDe,
        TotalDebt: TotalDebt,
        OtherInsuranceReserves: OtherInsuranceReserves,
        PolicyAndContractClaims: PolicyAndContractClaims,
        UnearnedPremiums: UnearnedPremiums,
        BenefitAndLossReserves: BenefitAndLossReserves,
        InsuranceReservesTotal: InsuranceReservesTotal,
        TotalLiabilities: TotalLiabilities,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TangibleOtherAssets: TangibleOtherAssets,
        DeferredCharges: DeferredCharges,
        SeparateAndVariableAccountAssets: SeparateAndVariableAccountAssets,
        OtherAssets: OtherAssets,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        PremiumBalanceReceivables: PremiumBalanceReceivables,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        OtherInvestments: OtherInvestments,
        MortgagePolicyAndOtherLoans: MortgagePolicyAndOtherLoans,
        RealEstateAssets: RealEstateAssets,
        NonRedeemablePreferredStock: NonRedeemablePreferredStock,
        CommonStocks: CommonStocks,
        EquitySecuritiesInvestmentTotal: EquitySecuritiesInvestmentTotal,
        RedeemablePreferredStock: RedeemablePreferredStock,
        Bonds: Bonds,
        FixedIncomeSecuritiesInvestmentTotal:
          FixedIncomeSecuritiesInvestmentTotal,
        Cash: Cash,
        InvestmentsTotal: InvestmentsTotal,
        totalAssets: totalAssets,
      });
    } else if (this.state.type === "Income Statement Three Year Average") {
      let PolicyholdersEquity = [];
      let CommonEquity = [];
      let ESOPGuaranteesPreferredIssued = [];
      let PreferredStockIssuedforESOP = [];
      let PreferredStock = [];
      let MinorityInterest = [];
      let NonEquityReserves = [];
      let TotalLiabilities = [];
      let OtherLiabilities = [];
      let DeferredTaxLiabilityinUntaxedReserves = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxes = [];
      let DeferredIncome = [];
      let ProvisionForRisksAndCharges = [];
      let CapitalizedLeaseObligations = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let LongTermDebt = [];
      let ShortTermDebtAndCurrentPortionofLongTermDe = [];
      let TotalDebt = [];
      let OtherInsuranceReserves = [];
      let PolicyAndContractClaims = [];
      let UnearnedPremiums = [];
      let BenefitAndLossReserves = [];
      let InsuranceReservesTotal = [];
      let IntangibleOtherAssets = [];
      let TangibleOtherAssets = [];
      let DeferredCharges = [];
      let SeparateAndVariableAccountAssets = [];
      let OtherAssets = [];
      let PropertyPlantAndEquipmentNet = [];
      let PremiumBalanceReceivables = [];
      let InvestmentsInAssociatedCompanies = [];
      let OtherInvestments = [];
      let MortgagePolicyAndOtherLoans = [];
      let RealEstateAssets = [];
      let NonRedeemablePreferredStock = [];
      let CommonStocks = [];
      let EquitySecuritiesInvestmentTotal = [];
      let RedeemablePreferredStock = [];
      let Bonds = [];
      let FixedIncomeSecuritiesInvestmentTotal = [];
      let Cash = [];
      let InvestmentsTotal = [];
      let totalAssets = [];
      let LabelList = [];
      let totalLiabilitiesShareholdersEquity = [];
      let balanceSheetData = this.state.data.insBalanceSheet3YearAverageList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalLiabilitiesShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        PolicyholdersEquity.push(balanceSheetData[i].policyholdersEquity);
        CommonEquity.push(balanceSheetData[i].commonEquity);
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        PreferredStock.push(balanceSheetData[i].preferredStock);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        DeferredTaxLiabilityinUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionsforRisksandCharges
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        ShortTermDebtAndCurrentPortionofLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        TotalDebt.push(balanceSheetData[i].totalDebt);
        OtherInsuranceReserves.push(balanceSheetData[i].otherInsuranceReserves);
        PolicyAndContractClaims.push(balanceSheetData[i].policyContractClaims);
        UnearnedPremiums.push(balanceSheetData[i].unearnedPremiums);
        BenefitAndLossReserves.push(balanceSheetData[i].benefitLossReserves);
        InsuranceReservesTotal.push(balanceSheetData[i].insuranceReservesTotal);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        SeparateAndVariableAccountAssets.push(
          balanceSheetData[i].separateVariableAccountAssets
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        PremiumBalanceReceivables.push(
          balanceSheetData[i].premiumBalanceReceivables
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        MortgagePolicyAndOtherLoans.push(
          balanceSheetData[i].mortgagePolicyOtherLoans
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        NonRedeemablePreferredStock.push(
          balanceSheetData[i].nonRedeemablePreferredStock
        );
        CommonStocks.push(balanceSheetData[i].commonStocks);
        EquitySecuritiesInvestmentTotal.push(
          balanceSheetData[i].equitySecuritiesInvestmentTotal
        );
        RedeemablePreferredStock.push(
          balanceSheetData[i].redeemablePreferredStock
        );
        Bonds.push(balanceSheetData[i].bonds);
        FixedIncomeSecuritiesInvestmentTotal.push(
          balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
        );
        Cash.push(balanceSheetData[i].cash);
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        totalAssets.push(balanceSheetData[i].totalAssets);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
          );
        balanceSheetData[i].bonds = this.convertToMillionaire(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].equitySecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].equitySecuritiesInvestmentTotal
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].mortgagePolicyOtherLoans =
          this.convertToMillionaire(
            balanceSheetData[i].mortgagePolicyOtherLoans
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].insuranceReservesTotal = this.convertToMillionaire(
          balanceSheetData[i].insuranceReservesTotal
        );
        balanceSheetData[i].benefitLossReserves = this.convertToMillionaire(
          balanceSheetData[i].benefitLossReserves
        );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].policyContractClaims = this.convertToMillionaire(
          balanceSheetData[i].policyContractClaims
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].policyholdersEquity = this.convertToMillionaire(
          balanceSheetData[i].policyholdersEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: "Balance Sheet -3 yr Averages",
        LabelList: LabelList,
        PolicyholdersEquity: PolicyholdersEquity,
        CommonEquity: CommonEquity,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        PreferredStock: PreferredStock,
        MinorityInterest: MinorityInterest,
        NonEquityReserves: NonEquityReserves,
        TotalLiabilities: TotalLiabilities,
        OtherLiabilities: OtherLiabilities,
        DeferredTaxLiabilityinUntaxedReserves:
          DeferredTaxLiabilityinUntaxedReserves,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxes: DeferredTaxes,
        DeferredIncome: DeferredIncome,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        LongTermDebt: LongTermDebt,
        ShortTermDebtAndCurrentPortionofLongTermDe:
          ShortTermDebtAndCurrentPortionofLongTermDe,
        TotalDebt: TotalDebt,
        OtherInsuranceReserves: OtherInsuranceReserves,
        PolicyAndContractClaims: PolicyAndContractClaims,
        UnearnedPremiums: UnearnedPremiums,
        BenefitAndLossReserves: BenefitAndLossReserves,
        InsuranceReservesTotal: InsuranceReservesTotal,
        TotalLiabilities: TotalLiabilities,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TangibleOtherAssets: TangibleOtherAssets,
        DeferredCharges: DeferredCharges,
        SeparateAndVariableAccountAssets: SeparateAndVariableAccountAssets,
        OtherAssets: OtherAssets,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        PremiumBalanceReceivables: PremiumBalanceReceivables,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        OtherInvestments: OtherInvestments,
        MortgagePolicyAndOtherLoans: MortgagePolicyAndOtherLoans,
        RealEstateAssets: RealEstateAssets,
        NonRedeemablePreferredStock: NonRedeemablePreferredStock,
        CommonStocks: CommonStocks,
        EquitySecuritiesInvestmentTotal: EquitySecuritiesInvestmentTotal,
        RedeemablePreferredStock: RedeemablePreferredStock,
        Bonds: Bonds,
        FixedIncomeSecuritiesInvestmentTotal:
          FixedIncomeSecuritiesInvestmentTotal,
        Cash: Cash,
        InvestmentsTotal: InvestmentsTotal,
        totalAssets: totalAssets,
      });
    } else {
      this.state.balanceSheetData = [];
      let PolicyholdersEquity = [];
      let CommonEquity = [];
      let ESOPGuaranteesPreferredIssued = [];
      let PreferredStockIssuedforESOP = [];
      let PreferredStock = [];
      let MinorityInterest = [];
      let NonEquityReserves = [];
      let TotalLiabilities = [];
      let OtherLiabilities = [];
      let DeferredTaxLiabilityinUntaxedReserves = [];
      let DeferredTaxesDebit = [];
      let DeferredTaxesCredit = [];
      let DeferredTaxes = [];
      let DeferredIncome = [];
      let ProvisionForRisksAndCharges = [];
      let CapitalizedLeaseObligations = [];
      let LongTermDebtExcludingCapitalizedLeases = [];
      let LongTermDebt = [];
      let ShortTermDebtAndCurrentPortionofLongTermDe = [];
      let TotalDebt = [];
      let OtherInsuranceReserves = [];
      let PolicyAndContractClaims = [];
      let UnearnedPremiums = [];
      let BenefitAndLossReserves = [];
      let InsuranceReservesTotal = [];
      let IntangibleOtherAssets = [];
      let TangibleOtherAssets = [];
      let DeferredCharges = [];
      let SeparateAndVariableAccountAssets = [];
      let OtherAssets = [];
      let PropertyPlantAndEquipmentNet = [];
      let PremiumBalanceReceivables = [];
      let InvestmentsInAssociatedCompanies = [];
      let OtherInvestments = [];
      let MortgagePolicyAndOtherLoans = [];
      let RealEstateAssets = [];
      let NonRedeemablePreferredStock = [];
      let CommonStocks = [];
      let EquitySecuritiesInvestmentTotal = [];
      let RedeemablePreferredStock = [];
      let Bonds = [];
      let FixedIncomeSecuritiesInvestmentTotal = [];
      let Cash = [];
      let InvestmentsTotal = [];
      let totalAssets = [];
      let LabelList = [];
      let balanceSheetData = this.state.data.insBalanceSheetList;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let totalLiabilitiesShareholdersEquity = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        LabelList.push(balanceSheetData[i].fiscalYear);
        totalLiabilitiesShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesShareholdersEquity
        );
        PolicyholdersEquity.push(balanceSheetData[i].policyholdersEquity);
        CommonEquity.push(balanceSheetData[i].commonEquity);
        ESOPGuaranteesPreferredIssued.push(
          balanceSheetData[i].esopguaranteesPreferredIssued
        );
        PreferredStockIssuedforESOP.push(
          balanceSheetData[i].preferredStockIssuedforESOP
        );
        PreferredStock.push(balanceSheetData[i].preferredStock);
        MinorityInterest.push(balanceSheetData[i].minorityInterest);
        NonEquityReserves.push(balanceSheetData[i].nonEquityReserves);
        TotalLiabilities.push(balanceSheetData[i].totalLiabilities);
        OtherLiabilities.push(balanceSheetData[i].otherLiabilities);
        DeferredTaxLiabilityinUntaxedReserves.push(
          balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
        );
        DeferredTaxesDebit.push(balanceSheetData[i].deferredTaxesDebit);
        DeferredTaxesCredit.push(balanceSheetData[i].deferredTaxesCredit);
        DeferredTaxes.push(balanceSheetData[i].deferredTaxes);
        DeferredIncome.push(balanceSheetData[i].deferredIncome);
        ProvisionForRisksAndCharges.push(
          balanceSheetData[i].provisionsforRisksandCharges
        );
        CapitalizedLeaseObligations.push(
          balanceSheetData[i].capitalizedLeaseObligations
        );
        LongTermDebtExcludingCapitalizedLeases.push(
          balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
        );
        LongTermDebt.push(balanceSheetData[i].longTermDebt);
        ShortTermDebtAndCurrentPortionofLongTermDe.push(
          balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
        );
        TotalDebt.push(balanceSheetData[i].totalDebt);
        OtherInsuranceReserves.push(balanceSheetData[i].otherInsuranceReserves);
        PolicyAndContractClaims.push(balanceSheetData[i].policyContractClaims);
        UnearnedPremiums.push(balanceSheetData[i].unearnedPremiums);
        BenefitAndLossReserves.push(balanceSheetData[i].benefitLossReserves);
        InsuranceReservesTotal.push(balanceSheetData[i].insuranceReservesTotal);
        IntangibleOtherAssets.push(balanceSheetData[i].intangibleOtherAssets);
        TangibleOtherAssets.push(balanceSheetData[i].tangibleOtherAssets);
        DeferredCharges.push(balanceSheetData[i].deferredCharges);
        SeparateAndVariableAccountAssets.push(
          balanceSheetData[i].separateVariableAccountAssets
        );
        OtherAssets.push(balanceSheetData[i].otherAssets);
        PropertyPlantAndEquipmentNet.push(
          balanceSheetData[i].propertyPlantandEquipmentNet
        );
        PremiumBalanceReceivables.push(
          balanceSheetData[i].premiumBalanceReceivables
        );
        InvestmentsInAssociatedCompanies.push(
          balanceSheetData[i].investmentsinAssociatedCompanies
        );
        OtherInvestments.push(balanceSheetData[i].otherInvestments);
        MortgagePolicyAndOtherLoans.push(
          balanceSheetData[i].mortgagePolicyOtherLoans
        );
        RealEstateAssets.push(balanceSheetData[i].realEstateAssets);
        NonRedeemablePreferredStock.push(
          balanceSheetData[i].nonRedeemablePreferredStock
        );
        CommonStocks.push(balanceSheetData[i].commonStocks);
        EquitySecuritiesInvestmentTotal.push(
          balanceSheetData[i].equitySecuritiesInvestmentTotal
        );
        RedeemablePreferredStock.push(
          balanceSheetData[i].redeemablePreferredStock
        );
        Bonds.push(balanceSheetData[i].bonds);
        FixedIncomeSecuritiesInvestmentTotal.push(
          balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
        );
        Cash.push(balanceSheetData[i].cash);
        InvestmentsTotal.push(balanceSheetData[i].investmentsTotal);
        totalAssets.push(balanceSheetData[i].totalAssets);
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );

        balanceSheetData[i].cash = this.convertToMillionaire(
          balanceSheetData[i].cash
        );
        balanceSheetData[i].investmentsTotal = this.convertToMillionaire(
          balanceSheetData[i].investmentsTotal
        );
        balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].fixedIncomeSecuritiesInvestmentTotal
          );
        balanceSheetData[i].bonds = this.convertToMillionaire(
          balanceSheetData[i].bonds
        );
        balanceSheetData[i].redeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].redeemablePreferredStock
          );
        balanceSheetData[i].equitySecuritiesInvestmentTotal =
          this.convertToMillionaire(
            balanceSheetData[i].equitySecuritiesInvestmentTotal
          );
        balanceSheetData[i].commonStocks = this.convertToMillionaire(
          balanceSheetData[i].commonStocks
        );
        balanceSheetData[i].nonRedeemablePreferredStock =
          this.convertToMillionaire(
            balanceSheetData[i].nonRedeemablePreferredStock
          );
        balanceSheetData[i].realEstateAssets = this.convertToMillionaire(
          balanceSheetData[i].realEstateAssets
        );
        balanceSheetData[i].mortgagePolicyOtherLoans =
          this.convertToMillionaire(
            balanceSheetData[i].mortgagePolicyOtherLoans
          );
        balanceSheetData[i].otherInvestments = this.convertToMillionaire(
          balanceSheetData[i].otherInvestments
        );
        balanceSheetData[i].investmentsinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentsinAssociatedCompanies
          );
        balanceSheetData[i].premiumBalanceReceivables =
          this.convertToMillionaire(
            balanceSheetData[i].premiumBalanceReceivables
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].separateVariableAccountAssets =
          this.convertToMillionaire(
            balanceSheetData[i].separateVariableAccountAssets
          );
        balanceSheetData[i].deferredCharges = this.convertToMillionaire(
          balanceSheetData[i].deferredCharges
        );
        balanceSheetData[i].tangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].tangibleOtherAssets
        );

        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].insuranceReservesTotal = this.convertToMillionaire(
          balanceSheetData[i].insuranceReservesTotal
        );
        balanceSheetData[i].benefitLossReserves = this.convertToMillionaire(
          balanceSheetData[i].benefitLossReserves
        );
        balanceSheetData[i].unearnedPremiums = this.convertToMillionaire(
          balanceSheetData[i].unearnedPremiums
        );
        balanceSheetData[i].policyContractClaims = this.convertToMillionaire(
          balanceSheetData[i].policyContractClaims
        );
        balanceSheetData[i].otherInsuranceReserves = this.convertToMillionaire(
          balanceSheetData[i].otherInsuranceReserves
        );
        balanceSheetData[i].totalDebt = this.convertToMillionaire(
          balanceSheetData[i].totalDebt
        );
        balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].provisionforRisksandCharges =
          this.convertToMillionaire(
            balanceSheetData[i].provisionforRisksandCharges
          );
        balanceSheetData[i].deferredIncome = this.convertToMillionaire(
          balanceSheetData[i].deferredIncome
        );
        balanceSheetData[i].deferredTaxes = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxes
        );
        balanceSheetData[i].deferredTaxesCredit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesCredit
        );
        balanceSheetData[i].deferredTaxesDebit = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxesDebit
        );
        balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves =
          this.convertToMillionaire(
            balanceSheetData[i].deferredTaxLiabilityinUntaxedReserves
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].preferredStockIssuedforESOP =
          this.convertToMillionaire(
            balanceSheetData[i].preferredStockIssuedforESOP
          );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );
        balanceSheetData[i].esopguaranteesPreferredIssued =
          this.convertToMillionaire(
            balanceSheetData[i].esopguaranteesPreferredIssued
          );
        balanceSheetData[i].policyholdersEquity = this.convertToMillionaire(
          balanceSheetData[i].policyholdersEquity
        );
        balanceSheetData[i].totalLiabilitiesShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesShareholdersEquity
          );
      }
      this.setState({
        balanceSheetData: balanceSheetData,
        render: true,
        isCommonSize: false,
        noYrToYr: true,
        isYrToYr: false,
        modalHeader: " Balance Sheet -Actual ",
        totalLiabilitiesShareholdersEquity: totalLiabilitiesShareholdersEquity,
        LabelList: LabelList,
        PolicyholdersEquity: PolicyholdersEquity,
        CommonEquity: CommonEquity,
        ESOPGuaranteesPreferredIssued: ESOPGuaranteesPreferredIssued,
        PreferredStockIssuedforESOP: PreferredStockIssuedforESOP,
        PreferredStock: PreferredStock,
        MinorityInterest: MinorityInterest,
        NonEquityReserves: NonEquityReserves,
        TotalLiabilities: TotalLiabilities,
        OtherLiabilities: OtherLiabilities,
        DeferredTaxLiabilityinUntaxedReserves:
          DeferredTaxLiabilityinUntaxedReserves,
        DeferredTaxesDebit: DeferredTaxesDebit,
        DeferredTaxesCredit: DeferredTaxesCredit,
        DeferredTaxes: DeferredTaxes,
        DeferredIncome: DeferredIncome,
        ProvisionForRisksAndCharges: ProvisionForRisksAndCharges,
        CapitalizedLeaseObligations: CapitalizedLeaseObligations,
        LongTermDebtExcludingCapitalizedLeases:
          LongTermDebtExcludingCapitalizedLeases,
        LongTermDebt: LongTermDebt,
        ShortTermDebtAndCurrentPortionofLongTermDe:
          ShortTermDebtAndCurrentPortionofLongTermDe,
        TotalDebt: TotalDebt,
        OtherInsuranceReserves: OtherInsuranceReserves,
        PolicyAndContractClaims: PolicyAndContractClaims,
        UnearnedPremiums: UnearnedPremiums,
        BenefitAndLossReserves: BenefitAndLossReserves,
        InsuranceReservesTotal: InsuranceReservesTotal,
        TotalLiabilities: TotalLiabilities,
        IntangibleOtherAssets: IntangibleOtherAssets,
        TangibleOtherAssets: TangibleOtherAssets,
        DeferredCharges: DeferredCharges,
        SeparateAndVariableAccountAssets: SeparateAndVariableAccountAssets,
        OtherAssets: OtherAssets,
        PropertyPlantAndEquipmentNet: PropertyPlantAndEquipmentNet,
        PremiumBalanceReceivables: PremiumBalanceReceivables,
        InvestmentsInAssociatedCompanies: InvestmentsInAssociatedCompanies,
        OtherInvestments: OtherInvestments,
        MortgagePolicyAndOtherLoans: MortgagePolicyAndOtherLoans,
        RealEstateAssets: RealEstateAssets,
        NonRedeemablePreferredStock: NonRedeemablePreferredStock,
        CommonStocks: CommonStocks,
        EquitySecuritiesInvestmentTotal: EquitySecuritiesInvestmentTotal,
        RedeemablePreferredStock: RedeemablePreferredStock,
        Bonds: Bonds,
        FixedIncomeSecuritiesInvestmentTotal:
          FixedIncomeSecuritiesInvestmentTotal,
        Cash: Cash,
        InvestmentsTotal: InvestmentsTotal,
        totalAssets: totalAssets,
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Fiscal fiscalYearEndDate
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>

                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Cash
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Cash", this.state.type)
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Investments – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Fixed Income Securities Investment – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Fixed Income Securities Investment – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.fixedIncomeSecuritiesInvestmentTotal}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Bonds
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm("test", this.state.type)
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.bonds}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Redeemable Preferred Stock
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Redeemable Preferred Stock",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.redeemablePreferredStock}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Equity Securities Investment – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Equity Securities Investment – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.equitySecuritiesInvestmentTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Common Stocks
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Stocks",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonStocks}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Non-Redeemable Preferred Stock
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Non-Redeemable Preferred Stock",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonRedeemablePreferredStock}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Real Estate Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Real Estate Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage, Policy & Other Loans
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Mortgage, Policy & Other Loans",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgagePolicyOtherLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Other Investments
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Investments",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Investments in Associated Companies
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Investments in Associated Companies",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Premium Balance Receivables
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Premium Balance Receivables",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.premiumBalanceReceivables}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property, Plant and Equipment – Net
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Property, Plant and Equipment – Net",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Separate & Variable Account Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Separate & Variable Account Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.separateVariableAccountAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Deferred Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Tangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Intangible Other Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Assets",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{"100%"}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{data.totalAssets}</td>;
                        })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Insurance Reserves – Total
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Insurance Reserves – Total",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.insuranceReservesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Benefit & Loss Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Benefit & Loss Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.benefitLossReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Unearned Premiums
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Unearned Premiums",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedPremiums}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Policy & Contract Claims
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Policy & Contract Claims",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyContractClaims}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Insurance Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Insurance Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInsuranceReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term De
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term De",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Excluding Capitalized Leases",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Capitalized Lease Obligations
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Capitalized Lease Obligations",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Provision for Risks and Charges
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Provision for Risks and Charges",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Income
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Income",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Taxes
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes - Credit",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Taxes - Debit",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Deferred Tax Liability in Untaxed Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Deferred Tax Liability in Untaxed Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Other Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Non-Equity Reserves
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Non-Equity Reserves",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Minority Interest
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Minority Interest",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Preferred Stock
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Preferred Stock Issued for ESOP",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "ESOP Guarantees - Preferred Issued",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Common Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Policyholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Policyholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyholdersEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <div
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity",
                            this.state.type
                          )
                        }
                        style={{
                          display: "inline-block",
                          float: "right",
                          cursor: "pointer",
                        }}
                      ></div>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>{"100%"}</td>
                          );
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.totalLiabilitiesShareholdersEquity}
                            </td>
                          );
                        })}
                  </tr>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                {this.state.noYrToYr && (
                  <>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                {this.state.isYrToYr && (
                  <>
                    <span className="unit_str">
                      Figures are the Percent Changes from the Prior Year.
                    </span>
                  </>
                )}
                {this.state.isCommonSize && (
                  <>
                    <span className="unit_str">
                      Figures are expressed as Percent of Total Assets.
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All figures in{" "}
                      <span>{this.toTitleCase(this.state.unit)}</span> of{" "}
                      {this.state.currency}
                    </span>
                  </>
                )}
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Fiscal fiscalYearEndDate
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Cash
                      <GraphIconButton
                        onClick={(e) => this.getDataInGraphicalForm("Cash")}
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cash}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>
                      Investments – Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Investments – Total")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Fixed Income Securities Investment – Total
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.fixedIncomeSecuritiesInvestmentTotal}</td>
                      );
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Bonds</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.bonds}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Redeemable Preferred Stock
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.redeemablePreferredStock}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Equity Securities Investment – Total
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.equitySecuritiesInvestmentTotal}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Stocks</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonStocks}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Non-Redeemable Preferred Stock
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonRedeemablePreferredStock}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Real Estate Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.realEstateAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Mortgage, Policy & Other Loans
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.mortgagePolicyOtherLoans}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Other Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInvestments}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Investments in Associated Companies
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentsinAssociatedCompanies}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Premium Balance Receivables
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.premiumBalanceReceivables}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property, Plant and Equipment – Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th>Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>{" "}
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Separate & Variable Account Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.separateVariableAccountAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Deferred Charges</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredCharges}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Tangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.tangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{"100%"}</td>;
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return <td style={{}}>{data.totalAssets}</td>;
                        })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Insurance Reserves – Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Insurance Reserves – Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.insuranceReservesTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Benefit & Loss Reserves
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Benefit & Loss Reserves")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.benefitLossReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Unearned Premiums
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Unearned Premiums")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.unearnedPremiums}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Policy & Contract Claims
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Policy & Contract Claims"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyContractClaims}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Insurance Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherInsuranceReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short Term Debt & Current Portion of Long Term De
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Short Term Debt & Current Portion of Long Term De"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long-Term Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Long-Term Debt Excluding Capitalized Leases"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capitalized Lease Obligations</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  {/* <tr>
                    <th>
                      Provision for Risks and Charges

                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.provisionforRisksandCharges}</td>;
                    })}
                  </tr> */}
                  <tr>
                    <th>Deferred Income</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredIncome}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Taxes</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Credit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Deferred Taxes - Debit
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxesDebit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability in Untaxed Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.deferredTaxLiabilityinUntaxedReserves}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Other Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Other Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Liabilities")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      Preferred Stock Issued for ESOP
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStockIssuedforESOP}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      {" "}
                      ESOP Guarantees - Preferred Issued
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.esopguaranteesPreferredIssued}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Common Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Common Equity")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Policyholders' Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.policyholdersEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities & Shareholders' Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities & Shareholders' Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.type === "Income Statement Common Size"
                      ? this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>{"100%"}</td>
                          );
                        })
                      : this.state.balanceSheetData.map((data) => {
                          return (
                            <td style={{ fontWeight: "bold" }}>
                              {data.totalLiabilitiesShareholdersEquity}
                            </td>
                          );
                        })}
                  </tr>
                </Table>

                {/* {itemRow}
              </Table>
            </TableContainer> */}
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
