import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../provider/Utils";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./EarningAndDividends.css";
export default class EarningAndDividends extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      edAnalysisData: [],
      quarterHeading: {},
      render: false,
    };
  }

  componentDidMount() {
    let quarterlyEarningValues = [];
    let quarterlyDividendValues = [];
    let priceValues = [];
    let earningsDividendLabel = [];
    let EarningQ1 = [];
    let EarningQ2 = [];
    let EarningQ3 = [];
    let EarningQ4 = [];
    let dividendQ1 = [];
    let dividendQ2 = [];
    let dividend = [];
    let dividendQ3 = [];
    let dividendQ4 = [];
    let payOutData = [];
    for (let i = 0; i < this.state.data.earningDividendList.length; i++) {
      quarterlyEarningValues.push(
        this.state.data.earningDividendList[i].yearEarning
      );
      quarterlyDividendValues.push(
        this.state.data.earningDividendList[i].yearDividend
      );
      earningsDividendLabel.push(this.state.data.earningDividendList[i].year);
      payOutData.push(this.state.data.earningDividendList[i].payoutPercent);
      EarningQ1.push(this.state.data.earningDividendList[i].q1ReportedEarning);
      EarningQ2.push(this.state.data.earningDividendList[i].q2ReportedEarning);
      EarningQ3.push(this.state.data.earningDividendList[i].q3ReportedEarning);
      EarningQ4.push(this.state.data.earningDividendList[i].q4ReportedEarning);
      dividend.push(this.state.data.earningDividendList[i].yearDividend);
      dividendQ1.push(
        this.state.data.earningDividendList[i].q1ReportedDividend
      );
      dividendQ2.push(
        this.state.data.earningDividendList[i].q2ReportedDividend
      );
      dividendQ3.push(
        this.state.data.earningDividendList[i].q3ReportedDividend
      );
      dividendQ4.push(
        this.state.data.earningDividendList[i].q4ReportedDividend
      );
    }
    this.setState({
      edAnalysisData: this.state.data.earningDividendList,
      quarterHeading: this.state.data.quarterHeading,
      earningsDividendLabel: earningsDividendLabel,
      currency: this.state.data.currency,
      fis_end: this.state.data.fis_end,
      quarterlyEarningValues: quarterlyEarningValues,
      quarterlyDividendValues: quarterlyDividendValues,
      dividend: dividend,
      EarningQ1: EarningQ1,
      EarningQ2: EarningQ2,
      EarningQ3: EarningQ3,
      EarningQ4: EarningQ4,
      dividendQ1: dividendQ1,
      dividendQ2: dividendQ2,
      dividendQ3: dividendQ3,
      dividendQ4: dividendQ4,
      payOutData: payOutData,
      render: true,
      series: [
        {
          name: "Earning",
          type: "bar",
          data: quarterlyEarningValues,
        },
        {
          name: "Dividend",
          type: "bar",
          data: quarterlyDividendValues,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },

        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  to: 0,
                  from: -100000000000,
                  color: "#F15B46",
                },
              ],
            },
            columnWidth: "50%",
          },
        },
        colors: ["#ff7f0e", "#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: earningsDividendLabel,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "year",
        },
        yaxis: {
          title: {
            text: "values",
          },
          min: 0,
        },

        tooltip: {
          shared: true,
          intersect: false,
        },
      },

      TableOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    });
  }

  getDataInGraphicalForm(graphType) {
    let labelData = [];
    if (graphType === "Earnings") {
      let earningsData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        earningsData.push(this.state.edAnalysisData[i].yearEarning);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: earningsData,
          labelData: labelData,
          yAxisTitle: "Earnings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EarningQ1") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EarningQ1,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "EarningQ1",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EarningQ2") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EarningQ2,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "EarningQ2",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EarningQ3") {
      let dividendData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        dividendData.push(this.state.edAnalysisData[i].yearDividend);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: this.state.EarningQ3,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "EarningQ3",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "EarningQ4") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EarningQ4,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "EarningQ4",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Dividends") {
      let dividendData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        dividendData.push(this.state.edAnalysisData[i].yearDividend);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: this.state.dividend,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "Dividends",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "dividendQ2") {
      let dividendData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        dividendData.push(this.state.edAnalysisData[i].yearDividend);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: this.state.dividendQ2,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "dividendQ2",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "dividendQ3") {
      let dividendData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        dividendData.push(this.state.edAnalysisData[i].yearDividend);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: this.state.dividendQ3,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "dividendQ3",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "dividendQ4") {
      let dividendData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        dividendData.push(this.state.edAnalysisData[i].yearDividend);
        labelData.push(this.state.edAnalysisData[i].year);
      }

      this.setState({
        graphDetails: {
          graphValues: this.state.dividendQ4,
          labelData: this.state.earningsDividendLabel,
          yAxisTitle: "dividendQ4",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      let payOutData = [];
      for (let i = 0; i < this.state.edAnalysisData.length; i++) {
        payOutData.push(this.state.edAnalysisData[i].payoutPercent);
        labelData.push(this.state.edAnalysisData[i].year);
      }
      this.setState({
        graphDetails: {
          graphValues: payOutData,
          labelData: labelData,
          yAxisTitle: "payout",
          xAxisTitle: "Year",
          yAxisTitleSign: "%",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  getTableHeadByQuarter(itemRow) {
    let tableHead = "";
    if (this.state.fis_end.toUpperCase() === "MARCH") {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Mar.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Mar.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    } else if (this.state.fis_end.toUpperCase() === "DECEMBER") {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Dec.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Dec.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    } else if (this.state.fis_end.toUpperCase() === "SEPTEMBER") {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>

              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Sep.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Sep.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    } else if (this.state.fis_end.toUpperCase() === "JANUARY") {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>

              <th className="ed_analysis"> Apr.</th>
              <th className="ed_analysis"> Jul.</th>
              <th className="ed_analysis"> Oct.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Jan.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Apr.</th>
              <th className="ed_analysis"> Jul.</th>
              <th className="ed_analysis"> Oct.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Jan.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    } else if (this.state.fis_end.toUpperCase() === "OCTOBER") {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>

              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Apr. </th>
              <th className="ed_analysis"> Jul.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Oct.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Jun.</th>
              <th className="ed_analysis"> Apr.</th>
              <th className="ed_analysis"> Jul.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Oct.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    } else {
      tableHead = (
        <Table
          responsive
          style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
        >
          <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
            <tr>
              <th
                rowSpan="3"
                className="ed_analysis"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Calendar period for U.S. companies and Fiscal Year for international companies."
              >
                Year
              </th>
              <th
                className="ed_analysis"
                colSpan="6"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
              >
                Earnings Per Share
              </th>
              <th
                className="ed_analysis"
                colSpan="7"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
              >
                Dividends Per Share
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                style={{ borderRight: "1px solid" }}
                colSpan="2"
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Earnings
              </th>
              <th
                className="ed_analysis"
                colSpan="2"
                style={{ borderRight: "1px solid" }}
              >
                12 Months
              </th>
              <th
                className="ed_analysis"
                colSpan="4"
                style={{ borderRight: "1px solid" }}
              >
                Quarterly Reported Dividends
              </th>
              <th
                className="ed_analysis"
                rowSpan="2"
                title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Payout")}
                ></GraphIconButton>
                % Payout
              </th>
            </tr>
            <tr>
              <th
                className="ed_analysis"
                title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                ></GraphIconButton>
                Earnings
              </th>
              <th
                className="ed_analysis"
                title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>

              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Jun.
              </th>
              <th
                className="ed_analysis"
                title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <GraphIconButton
                  onClick={(e) => this.getDataInGraphicalForm("Dividends")}
                ></GraphIconButton>
                Dividends
              </th>
              <th
                className="ed_analysis"
                title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                style={{
                  borderRight: "1px solid",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                % Change
              </th>
              <th className="ed_analysis"> Sep.</th>
              <th className="ed_analysis"> Dec.</th>
              <th className="ed_analysis"> Mar.</th>
              <th className="ed_analysis" style={{ borderRight: "1px solid" }}>
                Jun.
              </th>
            </tr>
          </thead>
          <tbody>{itemRow}</tbody>
        </Table>
      );
    }
    return tableHead;
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      let itemRow = "";
      itemRow = this.state.edAnalysisData.map((edAnalysis, i) => {
        return (
          <tr
            style={i % 2 ? { background: "#cae1ff" } : { background: "white" }}
          >
            <td style={{ borderRight: "1px solid" }}>{edAnalysis.year}</td>
            <td>
              {edAnalysis.yearEarning !== null
                ? Utils.valueFormat(edAnalysis.yearEarning.toFixed(2))
                : "n/a"}
            </td>
            <td style={{ borderRight: "1px solid" }}>
              {edAnalysis.yearEarningPercentChange !== null
                ? edAnalysis.yearEarningPercentChange.toFixed(2) + "%"
                : "n/c"}
            </td>
            <td>
              {edAnalysis.q1ReportedEarning !== null
                ? Utils.valueFormat(edAnalysis.q1ReportedEarning.toFixed(2))
                : "n/a"}
            </td>
            <td>
              {edAnalysis.q2ReportedEarning !== null
                ? Utils.valueFormat(edAnalysis.q2ReportedEarning.toFixed(2))
                : "n/a"}
            </td>
            <td>
              {edAnalysis.q3ReportedEarning !== null
                ? Utils.valueFormat(edAnalysis.q3ReportedEarning.toFixed(2))
                : "n/a"}
            </td>
            <td style={{ borderRight: "1px solid" }}>
              {edAnalysis.q4ReportedEarning !== null
                ? Utils.valueFormat(edAnalysis.q4ReportedEarning.toFixed(2))
                : "n/a"}
            </td>

            <td>
              {edAnalysis.yearDividend !== null
                ? Utils.valueFormat(edAnalysis.yearDividend.toFixed(2))
                : "n/a"}
            </td>
            <td style={{ borderRight: "1px solid" }}>
              {edAnalysis.yearDividendPercentChange !== null
                ? edAnalysis.yearDividendPercentChange.toFixed(2) + "%"
                : "n/c"}
            </td>
            <td>
              {edAnalysis.q1ReportedDividend !== null
                ? Utils.valueFormat(edAnalysis.q1ReportedDividend.toFixed(2))
                : "n/a"}
            </td>
            <td>
              {edAnalysis.q2ReportedDividend !== null
                ? Utils.valueFormat(edAnalysis.q2ReportedDividend.toFixed(2))
                : "n/a"}
            </td>
            <td>
              {edAnalysis.q3ReportedDividend !== null
                ? Utils.valueFormat(edAnalysis.q3ReportedDividend.toFixed(2))
                : "n/a"}
            </td>
            <td style={{ borderRight: "1px solid" }}>
              {edAnalysis.q4ReportedDividend !== null
                ? Utils.valueFormat(edAnalysis.q4ReportedDividend.toFixed(2))
                : "n/a"}
            </td>

            <td>
              {edAnalysis.payoutPercent !== null
                ? edAnalysis.payoutPercent.toFixed(2) + "%"
                : "n/c"}
            </td>
          </tr>
        );
      });

      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "20px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        Earnings & Dividends Analysis
                      </span>
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp;
                      <span>
                        {(this.state.currency !== null) !== null
                          ? this.state.currency
                          : ""}
                      </span>
                    </span>
                    <Row>
                      <Col md={12}>
                        <span className="unit_str">
                          Fiscal Year Ends in {this.state.fis_end}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table
                  style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        rowSpan="3"
                        className="ed_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="6"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                      >
                        Earnings Per Share
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="7"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                      >
                        Dividends Per Share
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                        colSpan="2"
                      >
                        12 Months
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="4"
                        style={{ borderRight: "1px solid" }}
                      >
                        Quarterly Reported Earnings
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                      >
                        12 Months
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="4"
                        style={{ borderRight: "1px solid" }}
                      >
                        Quarterly Reported Dividends
                      </th>
                      <th
                        className="ed_analysis"
                        rowSpan="2"
                        title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) => this.getDataInGraphicalForm("Payout")}
                      >
                        % Payout
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="ed_analysis"
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) => this.getDataInGraphicalForm("Earnings")}
                      >
                        Earnings
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        % Change
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EarningQ1")
                        }
                      >
                        {this.state.quarterHeading.q1}
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EarningQ2")
                        }
                      >
                        {this.state.quarterHeading.q2}
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EarningQ3")
                        }
                      >
                        {this.state.quarterHeading.q3}
                      </th>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("EarningQ4")
                        }
                      >
                        {this.state.quarterHeading.q4}
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Dividends")
                        }
                      >
                        Dividends
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        % Change
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("dividendQ1")
                        }
                      >
                        {this.state.quarterHeading.q1}
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("dividendQ2")
                        }
                      >
                        {this.state.quarterHeading.q2}
                      </th>
                      <th
                        className="ed_analysis"
                        onClick={(e) =>
                          this.getDataInGraphicalForm("dividendQ3")
                        }
                      >
                        {this.state.quarterHeading.q3}
                      </th>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                        onClick={(e) =>
                          this.getDataInGraphicalForm("dividendQ4")
                        }
                      >
                        {this.state.quarterHeading.q4}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Earnings & Dividends Analysis
                      </span>
                    </span>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp;
                      <span>
                        {(this.state.currency !== null) !== null
                          ? this.state.currency
                          : ""}
                      </span>
                    </span>
                    <Row>
                      <Col md={12}>
                        <span className="unit_str">
                          Fiscal Year Ends in {this.state.fis_end}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                {/* {this.getTableHeadByQuarter(itemRow)} */}
                <Table
                  responsive
                  style={{ border: "1px solid", margin: "0.5em 0em 0em 0em" }}
                >
                  <thead style={{ fontSize: "0.8em", background: "#ebe5db" }}>
                    <tr>
                      <th
                        rowSpan="3"
                        className="ed_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Calendar period for U.S. companies and Fiscal Year for international companies."
                      >
                        Year
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="6"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                      >
                        Earnings Per Share
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="7"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                      >
                        Dividends Per Share
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                        colSpan="2"
                      >
                        12 Months
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="4"
                        style={{ borderRight: "1px solid" }}
                      >
                        Quarterly Reported Earnings
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="2"
                        style={{ borderRight: "1px solid" }}
                      >
                        12 Months
                      </th>
                      <th
                        className="ed_analysis"
                        colSpan="4"
                        style={{ borderRight: "1px solid" }}
                      >
                        Quarterly Reported Dividends
                      </th>
                      <th
                        className="ed_analysis"
                        rowSpan="2"
                        title="Represents (12 month Dividends Per Share for the Year / 12 month Earnings Per Share for the Year) * 100"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <GraphIconButton
                          onClick={(e) => this.getDataInGraphicalForm("Payout")}
                        ></GraphIconButton>
                        % Payout
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="ed_analysis"
                        title="Represents the sum of the interim earnings reported by the company in the latest twelve months.  For companies with more than one type of common/ordinary share, earnings per share is adjusted to reflect the par value of the share type.  Earnings are computed on a calendar year basis for U.S. companies and a fiscal year basis for international companies."
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Earnings")
                          }
                        ></GraphIconButton>
                        Earnings
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents (Earnings Per Share Last 12 Months / Earnings Per Share Prior 12 Month period) * 100.  The % change is limited to +/- 100%."
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        % Change
                      </th>

                      <th className="ed_analysis">
                        {" "}
                        {this.state.quarterHeading.q1}
                      </th>
                      <th className="ed_analysis">
                        {" "}
                        {this.state.quarterHeading.q2}
                      </th>
                      <th className="ed_analysis">
                        {" "}
                        {this.state.quarterHeading.q3}
                      </th>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        {this.state.quarterHeading.q4}
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents Dividends Per Share Paid out over Last 12 months.  U.S. companies are on a Calendar year basis and international companies are on a Fiscal year basis."
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        <GraphIconButton
                          onClick={(e) =>
                            this.getDataInGraphicalForm("Dividends")
                          }
                        ></GraphIconButton>
                        Dividends
                      </th>
                      <th
                        className="ed_analysis"
                        title="Represents (Dividends Per Share Last 12 Months / Dividends Per Share Prior 12 month period) * 100"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        % Change
                      </th>
                      <th className="ed_analysis">
                        {" "}
                        {this.state.quarterHeading.q1}
                      </th>
                      <th className="ed_analysis">
                        {this.state.quarterHeading.q2}
                      </th>
                      <th className="ed_analysis">
                        {" "}
                        {this.state.quarterHeading.q3}
                      </th>
                      <th
                        className="ed_analysis"
                        style={{ borderRight: "1px solid" }}
                      >
                        {this.state.quarterHeading.q4}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.graphType}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <div id="chart">
                      <SingleAxisGenericGraph
                        graphData={this.state.graphDetails}
                      ></SingleAxisGenericGraph>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
