import { GET_SECTOR_FILTER, SET_SECTOR_FILTER } from "./filterSectorTypes";

const initialState = {
  selectedSectors: [],
};

const filterSectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTOR_FILTER:
      return {
        ...state,
      };
    case SET_SECTOR_FILTER:
      return {
        ...state,
        selectedSectors: action.payload,
      };
    default:
      return state;
  }
};

export default filterSectorReducer;
