import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';

export default class WrightQualityRatingInfo extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {
      phone: '',
      isMessageSent: false,
      isFeedbackSuccessfullySent: false,
      company: '',
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div style={{ minHeight: '37.2em' }}>
          <Row style={{ marginTop: '5em' }}>
            <Row style={{ marginTop: '2em', padding: '0 6em' }}>
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Ratings, in use for over 40 years,
                    measure the overall investment quality of a company. This
                    proprietary rating is based on numerous individual measures
                    of quality, grouped into four principal components: (1)
                    Investment Acceptance (i.e. stock liquidity), (2) Financial
                    Strength, (3) Profitability & Stability, and (4) Growth.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Rating is expressed as three letters and
                    a number. Each letter reflects a composite qualitative
                    measurement of numerous individual standards which may be
                    summarized as “A”; Outstanding, “B”; Excellent, “C”; Good,
                    “D” Fair, “L”; Limited and “N”; Not Rated The number
                    component of the Quality Rating is also a composite of
                    numerous individual standards that measure Corporate Growth
                    (i.e. sales, earnings, assets, etc.). It ranges from a low
                    of 0 to high of 20. (See sample Quality Rating below.)
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The highest quality rating assigned by Wright is AAA20. This
                    rating would be assigned to a company that has a large and
                    broad base of shareholders, and has an outstanding balance
                    sheet and profitability. The company also has experienced
                    superior growth over the past several years.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    The Wright Quality Rating assigned to a company also takes
                    into consideration country and industry variations. If there
                    is not sufficient information available, the quality rating
                    will not be assigned or an “N” (not-rated) will be applied
                    for that particular quality criteria.
                  </p>
                </Col>
              </Row>
              <Row>
                {/* <Col md={3}></Col> */}
                <Col md={4}>
                  <h5 className="qualityRatingHeading">
                    Sample Quality Rating
                  </h5>
                  <img
                    src="/home/assets/img/qalityRatings.png"
                    alt="qalityRatings"
                  ></img>
                </Col>
                <Col md={4} style={{ padding: '2em' }}>
                  <Radar
                    data={{
                      // labels: ["Running", "Swimming", "Eating", "Cycling", "Reading"],

                      labels: [
                        'Investment Acceptance',
                        'Financial Strength',
                        ' Profitability & Stability',
                        ' Growth',
                      ],
                      datasets: [
                        {
                          backgroundColor: 'rgba(  44, 92, 202,0.8)',
                          // backgroundColor:
                          //   "linear-gradient(to right, rgba( 255, 99, 132 , 0.3), rgba( 255, 99, 132 , 0.3))",
                          borderColor: '#5dade2',
                          borderJoinStyle: 'round',

                          borderDashOffset: '5',
                          lineTension: '0.5',
                          fontColor: '#fff',

                          // data: [20, 40, 60, 80, 95],
                          fill: true,
                          chart: {
                            height: 350,
                            type: 'radar',
                          },
                          scaleLineWidth: 16,
                          scaleLineColor: '#000',
                          data: ['200.0', '150.0', '160.0', '100.0'],
                          borderWidth: 3,
                        },
                      ],
                    }}
                    style={{ margin: '3em 0 0 0' }}
                    options={{
                      scale: {
                        gridLines: {
                          circular: true,
                          color: '#7B7D7D ',
                          backdropColor: '#000',
                        },
                        pointLabels: {
                          fontColor: '#2c3e50',
                        },
                        ticks: {
                          beginAtZero: true,
                          backdropColor: 'transparent',
                          fontColor: '#000',
                          display: false,
                        },
                        chartArea: {
                          backgroundColor: 'rgba (247, 247, 246)',
                        },
                      },

                      scaleLineWidth: 16,

                      stroke: {
                        show: true,
                        width: 6,
                        colors: [],
                        dashArray: 0,
                      },
                      pointStyle: 'circle',
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </Col>
                <Col md={4} style={{ marginTop: '2em' }}>
                  <img
                    src="/home/assets/img/snowflake_Rating.png"
                    alt="snowflake_Rating"
                  ></img>
                </Col>
              </Row>
            </Row>
          </Row>
        </div>
      </>
    );
  }
}
