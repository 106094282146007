import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./InterimIndBalanceSheet.css";
export default class InterimIndBalanceSheet extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      balanceSheetData: [],
      render: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values) {
      if (value !== "-") {
        values = parseFloat(values.toString().replace(/,/g, "")).toFixed(2);
        values = values.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return values;
      } else {
        return "-";
      }
    } else {
      values = "";
      return values;
    }
  }

  componentDidMount() {
    if (this.state.type === "Interim Balance Sheet Actual") {
      this.data = [];
      let LabelList = [];
      let longTermDebt = [];
      let totalAsset = [];
      let totalCurrentAsset = [];
      let receivableNet = [];
      let totalInventories = [];
      let totalCurrentLiabilities = [];
      let totalLiabilitiesAndShareholdersEquity = [];

      let balanceSheetData = this.state.data.indInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);
        totalAsset.push(balanceSheetData[i].totalAssets);
        totalCurrentAsset.push(balanceSheetData[i].totalCurrentAssets);
        receivableNet.push(balanceSheetData[i].receivablesNet);
        totalInventories.push(balanceSheetData[i].totalInventories);
        totalCurrentLiabilities.push(
          balanceSheetData[i].totalCurrentLiabilities
        );
        totalLiabilitiesAndShareholdersEquity.push(
          balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
        );
        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].totalCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentAssets
        );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire(
          balanceSheetData[i].prepaidExpenses
        );
        balanceSheetData[i].accountsPayable = this.convertToMillionaire(
          balanceSheetData[i].accountsPayable
        );
        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire(
          balanceSheetData[i].incomeTaxesPayable
        );
        balanceSheetData[i].otherCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentLiabilities
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );

        balanceSheetData[i].cashAndShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashAndShortTermInvestments
          );
        balanceSheetData[i].totalInventories = this.convertToMillionaire(
          balanceSheetData[i].totalInventories
        );
        balanceSheetData[i].deferredTaxAsset = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxAsset
        );
        balanceSheetData[i].totalCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentLiabilities
        );
        balanceSheetData[i].deferredTaxLiability = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxLiability
        );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        LabelList: LabelList,
        longTermDebt: longTermDebt,
        totalAsset: totalAsset,
        totalCurrentAsset: totalCurrentAsset,
        receivableNet: receivableNet,
        totalInventories: totalInventories,
        totalCurrentLiabilities: totalCurrentLiabilities,
        totalLiabilitiesAndShareholdersEquity:
          totalLiabilitiesAndShareholdersEquity,

        render: true,
      });
    } else if (this.state.type === "Interim Balance Sheet Common Size") {
      let balanceSheetData = this.state.data.indInterimBalanceSheet;
      if (balanceSheetData.length > 0) {
        balanceSheetData.reverse();
      }
      let LabelList = [];
      let longTermDebt = [];
      let shortTermDebt = [];
      for (let i = 0; i < balanceSheetData.length; i++) {
        longTermDebt.push(balanceSheetData[i].longTermDebt);

        LabelList.push(moment(balanceSheetData[i].year).format("MMMYYYY"));
        balanceSheetData[i].totalAssets = this.convertToMillionaire(
          balanceSheetData[i].totalAssets
        );
        balanceSheetData[i].otherCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentAssets
        );
        balanceSheetData[i].totalCurrentAssets = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentAssets
        );
        balanceSheetData[i].accumulatedDepreciation = this.convertToMillionaire(
          balanceSheetData[i].accumulatedDepreciation
        );
        balanceSheetData[i].otherAssets = this.convertToMillionaire(
          balanceSheetData[i].otherAssets
        );
        balanceSheetData[i].receivablesNet = this.convertToMillionaire(
          balanceSheetData[i].receivablesNet
        );
        balanceSheetData[i].prepaidExpenses = this.convertToMillionaire(
          balanceSheetData[i].prepaidExpenses
        );
        balanceSheetData[i].accountsPayable = this.convertToMillionaire(
          balanceSheetData[i].accountsPayable
        );
        balanceSheetData[i].incomeTaxesPayable = this.convertToMillionaire(
          balanceSheetData[i].incomeTaxesPayable
        );
        balanceSheetData[i].otherCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherCurrentLiabilities
        );
        balanceSheetData[i].investmentinAssociatedCompanies =
          this.convertToMillionaire(
            balanceSheetData[i].investmentinAssociatedCompanies
          );
        balanceSheetData[i].propertyPlantandEquipmentGross =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentGross
          );
        balanceSheetData[i].propertyPlantandEquipmentNet =
          this.convertToMillionaire(
            balanceSheetData[i].propertyPlantandEquipmentNet
          );
        balanceSheetData[i].intangibleOtherAssets = this.convertToMillionaire(
          balanceSheetData[i].intangibleOtherAssets
        );
        balanceSheetData[i].longTermDebt = this.convertToMillionaire(
          balanceSheetData[i].longTermDebt
        );
        balanceSheetData[i].longTermDebtExcludingCapitalizedLeases =
          this.convertToMillionaire(
            balanceSheetData[i].longTermDebtExcludingCapitalizedLeases
          );
        balanceSheetData[i].capitalizedLeaseObligations =
          this.convertToMillionaire(
            balanceSheetData[i].capitalizedLeaseObligations
          );
        balanceSheetData[i].otherLiabilities = this.convertToMillionaire(
          balanceSheetData[i].otherLiabilities
        );
        balanceSheetData[i].totalLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalLiabilities
        );
        balanceSheetData[i].nonEquityReserves = this.convertToMillionaire(
          balanceSheetData[i].nonEquityReserves
        );
        balanceSheetData[i].minorityInterest = this.convertToMillionaire(
          balanceSheetData[i].minorityInterest
        );
        balanceSheetData[i].preferredStock = this.convertToMillionaire(
          balanceSheetData[i].preferredStock
        );
        balanceSheetData[i].commonEquity = this.convertToMillionaire(
          balanceSheetData[i].commonEquity
        );

        balanceSheetData[i].cashAndShortTermInvestments =
          this.convertToMillionaire(
            balanceSheetData[i].cashAndShortTermInvestments
          );
        balanceSheetData[i].totalInventories = this.convertToMillionaire(
          balanceSheetData[i].totalInventories
        );
        balanceSheetData[i].deferredTaxAsset = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxAsset
        );
        balanceSheetData[i].totalCurrentLiabilities = this.convertToMillionaire(
          balanceSheetData[i].totalCurrentLiabilities
        );
        balanceSheetData[i].deferredTaxLiability = this.convertToMillionaire(
          balanceSheetData[i].deferredTaxLiability
        );
        balanceSheetData[i].liabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].liabilitiesAndShareholdersEquity
          );
        balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt =
          this.convertToMillionaire(
            balanceSheetData[i].shortTermDebtAndCurrentPortionofLongTermDebt
          );
        balanceSheetData[i].totalLiabilitiesAndShareholdersEquity =
          this.convertToMillionaire(
            balanceSheetData[i].totalLiabilitiesAndShareholdersEquity
          );
      }

      this.setState({
        balanceSheetData: balanceSheetData,
        series: [
          {
            name: "Long Term Debit",
            type: "bar",
            data: longTermDebt,
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "bar",
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          grid: {
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          title: {
            text: "",
            align: "left",
          },
          stroke: {
            width: [0, 2, 5],
            curve: "smooth",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          colors: ["#1f77b4"],

          legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "bottom",
            horizontalAlign: "center",
            floating: false,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial",
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            offsetX: 0,
            offsetY: 0,
            labels: {
              colors: ["#7d6608", "#0b5345"],
              useSeriesColors: true,
            },
          },

          labels: LabelList,
          xaxis: {
            title: {
              text: "Year",
              style: {
                colors: "#808b96",
              },
            },

            axisBorder: {
              show: true,
              color: "#808b96",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0,
            },
          },
          yaxis: {
            title: {
              text: "Long Term Debt",
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#808b96 ",
            },
          },

          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (y) {
                if (typeof y !== "undefined") {
                  return "$ " + y;
                }
                return y;
              },
            },
          },
        },
        tableGraphSeries: [
          {
            data: longTermDebt,
          },
        ],
        tableGraphOptions: {
          chart: {
            type: "bar",
            width: 100,
            height: 35,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },

          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
          xaxis: {
            crosshairs: {
              width: 1,
            },
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return "";
                },
              },
            },
            marker: {
              show: false,
            },
          },
        },
        render: true,
      });
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCurrentAsset,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivables (Net)") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivableNet,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables (Net)",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Inventories") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalInventories,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Inventories",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalAsset,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Current Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCurrentLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Current Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.longTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Liabilities And Shareholders Equity") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalLiabilitiesAndShareholdersEquity,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Liabilities And Shareholders Equity",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12} style={{ marginLeft: "1.5em" }}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Total Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalAssets}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ fontWeight: "bold" }}>Other Current Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherCurrentAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Total Current Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalCurrentAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Receivables (Net)</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Prepaid Expenses</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.prepaidExpenses}</td>;
                    })}
                  </tr>

                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Income Taxes Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.incomeTaxesPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Other Current Liabilities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Investment in Associated Companies
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Gross </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Net</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Intangible Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={(e) => this.getDataInGraphicalForm()}
                    >
                      <span className=""> Long Term Debt</span>
                      <div
                        onClick={(e) => this.getDataInGraphicalForm()}
                        style={{ display: "inline-block", float: "right" }}
                      ></div>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Long-Term Debt Excluding Capitalized Leases </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capitalized Lease Obligations</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}> Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Cash And Short Term Investments
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashAndShortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Inventories</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalInventories}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Deferred Tax Asset</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.deferredTaxAsset}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Total Current Liabilities </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalCurrentLiabilities}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>Deferred Tax Liability</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxLiability}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Short-Term Debt & Current Portion of Long-Term Debt</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Total Liabilities And Shareholders Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.totalLiabilitiesAndShareholdersEquity}</td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px" }}></Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Quarter </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {moment(data.year).format("MM/DD/YYYY")}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Current Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Current Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalCurrentAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Cash And Short Term Investments</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.cashAndShortTermInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Receivables (Net)
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Receivables (Net)")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.receivablesNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Total Inventories
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Inventories")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalInventories}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Prepaid Expenses</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.prepaidExpenses}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Current Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment in Associated Companies</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.investmentinAssociatedCompanies}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Property Plant and Equipment - Gross </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentGross}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Accumulated Depreciation
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accumulatedDepreciation}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Property Plant and Equipment - Net
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.propertyPlantandEquipmentNet}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Other Assets</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Deferred Tax Asset</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxAsset}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Intangible Other Assets
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.intangibleOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Assets
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Total Assets")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Liabilities & Shareholders' Equity
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td></td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Current Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Current Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalCurrentLiabilities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Accounts Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.accountsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Short-Term Debt & Current Portion of Long-Term Debt
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>
                          {data.shortTermDebtAndCurrentPortionofLongTermDebt}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>Income Taxes Payable</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.incomeTaxesPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Other Current Liabilities
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherCurrentLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      <span className=""> Long Term Debt</span>

                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long Term Debt")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.longTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Long-Term Debt Excluding Capitalized Leases{" "}
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td>{data.longTermDebtExcludingCapitalizedLeases}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ paddingLeft: "2em" }}>
                      Capitalized Lease Obligations
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.capitalizedLeaseObligations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Tax Liability</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.deferredTaxLiability}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.otherLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Total Liabilities</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.totalLiabilities}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Non-Equity Reserves</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.nonEquityReserves}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Minority Interest</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.minorityInterest}</td>;
                    })}
                  </tr>
                  <tr>
                    <th> Preferred Stock</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.preferredStock}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Equity</th>
                    {this.state.balanceSheetData.map((data) => {
                      return <td>{data.commonEquity}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Total Liabilities And Shareholders Equity
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Total Liabilities And Shareholders Equity"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.balanceSheetData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.totalLiabilitiesAndShareholdersEquity}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Interim Balance Sheet({" "}
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
