import { GET_HISTORY, SET_HISTORY } from "./historyTypes";

const initialState = {
  history: {},
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY:
      return {
        ...state,
      };
    case SET_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
};

export default historyReducer;
