import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import "react-tabs/style/react-tabs.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import reportPriceAction from "../../redux/reportPrice/reportPriceAction";
import store from "../../redux/store";
import userDataAction from "../../redux/userData/userDataAction";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);
    let selectedPlan = "";
    let modalShow = false;
    let modalSize = "sm";
    let modelTitle = "Login";
    if (
      props.history &&
      props.history.location &&
      props.history.location.state
    ) {
      selectedPlan = props.history.location.state.selectedPlan;
      modalShow = props.history.location.state.modalShow;
    }

    if (props.modalShow === true) {
      modalShow = true;
    }
    if (props.pageId === "plans") {
      modalSize = "xl";
      modelTitle = "Signup";
    }

    this.state = {
      password: "",
      userName: "",
      firstName: "",
      lastName: "",
      isLogin: props.isLogin,
      isResetPass: false,
      isForgotPass: false,
      isSignup: props.isSignup,
      selectedPlan: selectedPlan,
      pageId: props.pageId,
      state: props.state,
      showModal: modalShow,
      modalSize: modalSize,
      false: false,
      countryList: [],
      stateList: [],
      companyStateList: [],
      onSuccess: props.onSuccess,
      modelTitle: modelTitle,
      showPage: props.showPage,
    };
    // }
  }
  componentDidMount() {
    const postObject = {};
    FetchServerData.callPostService("staticms/countryList", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let country = output.data.defaultCountry;
          store.dispatch(reportPriceAction.setPriceData(output.data.plans));
          this.setState({
            countryList: output.data.countryList,
            stateList: output.data.stateList,
            companyStateList: output.data.stateList,
            country: country,
            companyCountry: country,
          });
        }
      })
      .catch((error) => {});
  }

  newLegendClickHandler(e, legendItem) {}
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }
  handlePasswordChange(e) {
    if (e.charCode === 13) {
      this.userLogin(e);
    }
    this.setState({ password: e.target.value });
  }
  handleTempPassChange(e) {
    this.setState({ tempPass: e.target.value });
  }
  handleRePasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  handleCompanyNameChange(e) {
    this.setState({ companyName: e.target.value });
  }
  handleLane1Change(e) {
    this.setState({ lane1: e.target.value });
  }
  handleLane2Change(e) {
    this.setState({ lane2: e.target.value });
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleCountryChange(selectedCountry) {
    this.state.countryId = selectedCountry;
    this.setState({
      countryId: selectedCountry,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    } else if (selectedCountry) {
      const postObject = { countryId: selectedCountry.id };
      FetchServerData.callPostService("staticms/statesForCountry", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            this.setState({
              stateList: output.data,
              state: {},
            });
          }
        })
        .catch((error) => {});
    }
  }
  handleStateChange(selectedState) {
    this.setState({ state: selectedState });
  }
  handleCityChange(e) {
    this.setState({ city: e.target.value });
  }
  handlePostalCodeChange(e) {
    this.setState({ postalCode: e.target.value });
  }

  handleCompanyLane1Change(e) {
    this.setState({ companyLane1: e.target.value });
  }
  handleCompanyLane2Change(e) {
    this.setState({ companyLane2: e.target.value });
  }
  handleCompanyCountryChange(selectedCountry) {
    this.setState({
      companyCountry: selectedCountry,
    });

    const postObject = { countryId: selectedCountry.id };
    FetchServerData.callPostService("staticms/statesForCountry", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            companyStateList: output.data,
            companyState: {},
          });
        }
      })
      .catch((error) => {});
  }
  handleCompanyStateChange(selectedState) {
    this.setState({ companyState: selectedState });
  }
  handleCompanyCityChange(e) {
    this.setState({ companyCity: e.target.value });
  }
  handleCompanyPostalCodeChange(e) {
    this.setState({ companyPostalCode: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleCompanyPhoneChange(e) {
    this.setState({ companyPhone: e.target.value });
  }
  handleFaxChange(e) {
    this.setState({ fax: e.target.value });
  }
  handleTandcChange = (e) => {
    this.setState({ checked: e.target.checked });
  };
  userLogin(e) {
    const postObject = {
      user: {
        email: this.state.userName,
        password: this.state.password,
      },
    };
    FetchServerData.callPostService("userms/login", postObject)
      .then((output) => {
        this.setState({ showModal: false });
        if (output.status === "SUCCESS") {
          store.dispatch(userDataAction.setUserData(output.data.user));

          this.props.onSuccess();
        } else {
          this.setState({ showModal: true });
        }
      })
      .catch((error) => {});
  }
  userRegister() {
    if (this.state.password.length < 8) {
      PtsAlert.error("Credential length must be 8 characters long");
      document.getElementById("password").focus();
    } else if (this.state.password !== this.state.confirmPassword) {
      PtsAlert.error("Password and confirm password are not same");
    } else {
      const postObject = {
        user: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.userName,
          phone: this.state.phone,
          password: this.state.password,
          address: {
            line1: this.state.lane1,
            line2: this.state.lane2,
            city: this.state.city,
            zicode: this.state.postalCode,
            country: this.state.country,
            state: this.state.state,
          },
          company: {
            name: this.state.companyName,
            phone: this.state.companyPhone,
            address: {
              line1: this.state.companyLane1,
              line2: this.state.companyLane2,
              city: this.state.companyCity,
              zicode: this.state.companyPostalCode,
              country: this.state.companyCountry,
              state: this.state.companyState,
            },
          },
        },
      };
      FetchServerData.callPostService("userms/signUp", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            PtsAlert.success(output.message);
            store.dispatch(userDataAction.setUserData(output.data));
            // window.location.href = "/";
            if (this.props.pageId === "header") {
              this.props.history.push({
                pathname: "/plans",
              });
              this.setState({
                isLogin: true,
                isSignup: false,
                modalSize: "sm",
                password: "",
                modelTitle: "Login",
                modalShow: false,
              });
            } else {
              this.props.onSuccess();
              this.setState({
                isLogin: false,
                isSignup: false,
                showModal: false,
                // modalSize: "sm",
                // password: "",
                // modelTitle: "Login",
                modalShow: false,
              });
            }
          } else {
            this.setState({
              isLogin: false,
              isSignup: true,
              modalSize: "xl",
              password: "",
              modelTitle: "Signup",
              modalShow: false,
              showModal: true,
            });
          }
        })
        .catch((error) => {});
    }
  }
  userResetPass(e) {
    const postObject = {
      user: {
        email: this.state.userName,
      },
    };
    FetchServerData.callPostService("userms/forgetPassword", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            isResetPass: false,
            isForgotPass: true,
            modelTitle: "Forget Password",
            password: "",
          });
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }
  alertMessage() {
    alert(this.props.name);
  }
  resetPassword(e) {
    const postObject = {
      user: {
        email: this.state.userName,
        password: this.state.password,
        tempPassword: this.state.tempPass,
      },
    };
    FetchServerData.callPostService("userms/resetPassword", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            isLogin: true,
            isSignup: false,
            isResetPass: false,
            isForgotPass: false,
            modalSize: "sm",
            password: "",
            modelTitle: "Login",
          });
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }
  getModalPopup(e) {
    this.setState({
      showModal: true,
    });
  }
  userForgotPass(e) {
    this.setState({
      isResetPass: true,
      modelTitle: "Reset Password",
      isLogin: false,
      isForgotPass: false,
      modalSize: "sm",
      showPage: "",
    });
  }
  signUp(e) {
    this.setState({
      isResetPass: false,
      isLogin: false,
      isSignup: true,
      modelTitle: "Signup",
      isForgotPass: false,
      modalSize: "xl",
      showPage: "",
    });
  }
  login(e) {
    this.setState({
      isResetPass: false,
      isForgotPass: false,
      isSignup: false,
      isLogin: true,
      modelTitle: "Login",
      modalSize: "sm",
    });
  }
  toggle = () => {
    if (this.state.showModal === true) {
      if (this.state.modelTitle === "Signup") {
        this.setState({
          isResetPass: false,
          isForgotPass: false,
          isSignup: false,
          isLogin: true,
          confirmPassword: "",
          userName: "",
          phone: "",
          password: "",
          modelTitle: "Login",
          modalSize: "sm",
          showModal: !this.state.showModal,
        });
        window.location.href = "/";
      } else {
        this.setState({
          isResetPass: false,
          isForgotPass: false,
          isSignup: false,
          isLogin: true,
          confirmPassword: "",
          userName: "",
          phone: "",
          password: "",
          modelTitle: "Login",
          modalSize: "sm",
          showModal: !this.state.showModal,
        });
      }
    } else {
      this.setState({ showModal: !this.state.showModal });
    }
  };

  render() {
    if (this.state.showPage === "Login") {
      this.state.isLogin = true;
      this.state.isResetPass = false;
      this.state.isForgotPass = false;
      this.state.isSignup = false;
    }
    return (
      <Modal
        isOpen={this.state.showModal}
        size={this.state.modalSize}
        className="modalContain"
      >
        <ModalHeader style={{ background: "#286894", color: "#fff" }}>
          <Row>
            <Col md={9}>
              <Label
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "center",
                  float: "left",
                }}
              >
                {this.state.modelTitle}
              </Label>
            </Col>
            <Col md={3}>
              <Fab
                aria-label="add"
                className="modal_close_btn"
                onClick={this.toggle.bind(this)}
              >
                <CloseIcon />
              </Fab>
            </Col>
          </Row>
        </ModalHeader>
        {this.state.isLogin && this.generateLoginForm()}
        {this.state.isResetPass && this.generateResetPasswordForm()}
        {this.state.isForgotPass && this.generateForgotPasswordForm()}
        {this.state.isSignup && this.generateSignUpForm()}
      </Modal>
    );
  }
  generateLoginForm() {
    return (
      <ModalBody style={{ marginTop: "1em" }}>
        <Row style={{ margin: "0 auto" }}>
          <TextField
            name="email"
            required
            variant="outlined"
            value={this.state.userName}
            onChange={this.handleUserNameChange.bind(this)}
            fullWidth
            id="email"
            label="Email"
            autoComplete="off"
          />
        </Row>
        <Row style={{ margin: "0 auto", marginTop: "1em" }}>
          <TextField
            variant="outlined"
            autoComplete="off"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={this.state.showPassword ? "text" : "password"}
            id="password"
            value={this.state.password}
            onChange={this.handlePasswordChange.bind(this)}
            onKeyPress={this.handlePasswordChange.bind(this)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword.bind(this)}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <Row style={{ margin: "1em" }}>
          <Button
            variant="contained"
            size="small"
            style={{
              background: "#f26d21",
              color: "#fff",
              height: "100%",
              borderBottom: "3px solid #636262",
              borderRadius: "5px",
              margin: 0,
            }}
            onClick={this.userLogin.bind(this)}
          >
            Sign In
          </Button>
        </Row>
        <Row>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ textAlign: "center" }}>Or Sign in with: </span>
            <div className="socialicons">
              <a
                href="https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dwww.corporateinformation.com&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB"
                target="_blank"
                rel="noreferrer"
                className="facebook"
              >
                <img src="/home/assets/img/fb.png" alt="fb"></img>
              </a>
            </div>
            <div className="socialicons">
              <a
                href="https://plus.google.com/up/?continue=https://plus.google.com/share?url%3Dwww.corporateinformation.com"
                target="_blank"
                rel="noreferrer"
                className="google"
              >
                <img src="/home/assets/img/google.png " alt="google"></img>
              </a>
            </div>
          </div>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            <a
              href="#"
              onClick={this.userForgotPass.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>Forgot Password?</u>
            </a>
          </span>
        </Row>
        {/* <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            Not a member?{" "}
            <a
              href="#"
              onClick={this.signUp.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>SignUp</u>
            </a>
          </span>
        </Row> */}
      </ModalBody>
    );
  }
  generateResetPasswordForm() {
    return (
      <ModalBody style={{ marginTop: "1em" }}>
        <Row style={{ margin: "0 auto" }}>
          <TextField
            name="email"
            required
            variant="outlined"
            value={this.state.userName}
            onChange={this.handleUserNameChange.bind(this)}
            fullWidth
            id="email"
            label="Email"
            autoComplete="off"
          />
        </Row>
        <Row style={{ margin: "1em" }}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{
              background: "#f26d21",
              color: "#fff",
              height: "100%",
              borderBottom: "3px solid #636262",
              borderRadius: "5px",
              margin: "0",
            }}
            onClick={this.userResetPass.bind(this)}
          >
            Reset password
          </Button>
        </Row>

        <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            Already member?{" "}
            <a
              onClick={this.login.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>Login</u>
            </a>
          </span>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            Not a member?{" "}
            <a
              onClick={this.signUp.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>SignUp</u>
            </a>
          </span>
        </Row>
      </ModalBody>
    );
  }
  generateForgotPasswordForm() {
    return (
      <ModalBody style={{ marginTop: "1em" }}>
        <Row style={{ margin: "0 auto" }}>
          <TextField
            name="email"
            required
            variant="outlined"
            value={this.state.userName}
            onChange={this.handleUserNameChange.bind(this)}
            fullWidth
            id="email"
            label="Email"
            autoComplete="off"
          />
        </Row>
        <Row style={{ margin: "0 auto", marginTop: "1.5em" }}>
          <TextField
            name="authenticationCode"
            required
            variant="outlined"
            value={this.state.tempPass}
            onChange={this.handleTempPassChange.bind(this)}
            fullWidth
            id="email"
            label="Authentication Code"
            autoComplete="off"
          />
        </Row>
        <Row style={{ margin: "0 auto", marginTop: "0.5em" }}>
          <TextField
            variant="outlined"
            autoComplete="off"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type={this.state.showPassword ? "text" : "password"}
            id="password"
            value={this.state.password}
            onChange={this.handlePasswordChange.bind(this)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword.bind(this)}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Row>

        <Row style={{ margin: "1em" }}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            style={{
              background: "#f26d21",
              color: "#fff",
              height: "100%",
              borderBottom: "3px solid #636262",
              borderRadius: "5px",
              margin: "0",
            }}
            onClick={this.resetPassword.bind(this)}
          >
            Reset password
          </Button>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            Already member?{" "}
            <a
              onClick={this.login.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>Login</u>
            </a>
          </span>
        </Row>
        <Row style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "center" }}>
            Not a member?{" "}
            <a
              onClick={this.signUp.bind(this)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <u>SignUp</u>
            </a>
          </span>
        </Row>
      </ModalBody>
    );
  }
  generateSignUpForm() {
    return (
      <ModalBody style={{}}>
        <ValidatorForm ref="form" onSubmit={this.userRegister.bind(this)}>
          <Row style={{ marginTop: "1em" }}>
            <span>
              Already member?{" "}
              <a
                onClick={this.login.bind(this)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                <u>Login</u>
              </a>
            </span>
          </Row>
          <Row style={{}}>
            <Col md={6}>
              <Card
                style={{
                  margin: "0.5em",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                  height: "100%",
                }}
              >
                <CardHeader style={{ background: "#163f37", color: "#fff" }}>
                  <h6 style={{ margin: "0.5em" }}>Subscriber Details</h6>
                </CardHeader>
                <CardBody style={{ margin: "0.5em" }}>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <TextField
                        name="firstName"
                        variant="outlined"
                        // required
                        value={this.state.firstName}
                        onChange={this.handleFirstNameChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        required
                        fullWidth
                        id="name"
                        label="First Name"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <TextField
                        name="lastName"
                        variant="outlined"
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        required
                        fullWidth
                        id="name"
                        label="Last Name"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1.5em" }}>
                    <Col>
                      <TextValidator
                        name="email"
                        required
                        variant="outlined"
                        value={this.state.userName}
                        onChange={this.handleUserNameChange.bind(this)}
                        validators={["required", "isEmail"]}
                        fullWidth
                        id="email"
                        label="Email"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <TextValidator
                        name="phone"
                        variant="outlined"
                        value={this.state.phone}
                        onChange={this.handlePhoneChange.bind(this)}
                        fullWidth
                        id="phone"
                        label="Phone"
                        autoComplete="off"
                        validators={["isNumber"]}
                        errorMessages={["phone is not valid"]}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <TextField
                        variant="outlined"
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={this.state.showPassword ? "text" : "password"}
                        id="password"
                        value={this.state.password}
                        onChange={this.handlePasswordChange.bind(this)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(
                                  this
                                )}
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            height: "2.8em",
                          },
                        }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        variant="outlined"
                        autoComplete="off"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type={this.state.showPassword ? "text" : "password"}
                        id="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleRePasswordChange.bind(this)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(
                                  this
                                )}
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            height: "2.8em",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              <Card
                style={{
                  margin: "0.5em",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                  height: "100%",
                }}
              >
                <CardHeader style={{ background: "#163f37", color: "#fff" }}>
                  <h6 style={{ margin: "0.5em" }}>Subscriber Address</h6>
                </CardHeader>
                <CardBody style={{ margin: "0.5em" }}>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <TextField
                        name="lane"
                        variant="outlined"
                        value={this.state.lane1}
                        onChange={this.handleLane1Change.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="lane"
                        label="Address Line 1"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <TextField
                        name="lane2"
                        variant="outlined"
                        value={this.state.lane2}
                        onChange={this.handleLane2Change.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="lane2"
                        label="Address Line 2"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1.5em" }}>
                    <Col>
                      <TextField
                        name="city"
                        variant="outlined"
                        value={this.state.city}
                        onChange={this.handleCityChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="city"
                        label="City"
                        autoComplete="off"
                      />
                    </Col>

                    <Col>
                      <Autocomplete
                        id="state"
                        options={this.state.stateList}
                        getOptionLabel={(option) => option.name}
                        defaultValue={this.state.state}
                        value={this.state.state}
                        onChange={(event, value) => {
                          this.handleStateChange(value);
                        }}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1.5em" }}>
                    <Col>
                      <TextValidator
                        name="postalCode"
                        variant="outlined"
                        value={this.state.postalCode}
                        onChange={this.handlePostalCodeChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="postalCode"
                        label="Postal Code"
                        autoComplete="off"
                        errorMessages={[
                          "this field is required",
                          "postal code is not valid",
                        ]}
                      />
                    </Col>
                    <Col>
                      <Autocomplete
                        id="country"
                        style={{ height: "3.5em" }}
                        options={this.state.countryList}
                        defaultValue={this.state.country}
                        getOptionLabel={(option) => option.name}
                        value={this.state.country}
                        onChange={(event, value) => {
                          this.handleCountryChange(value);
                        }}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "1.5em" }}>
            <Col md={6}>
              <Card
                style={{
                  margin: "0.5em",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                  height: "100%",
                }}
              >
                <CardHeader style={{ background: "#163f37", color: "#fff" }}>
                  <h6 style={{ margin: "0.5em" }}>
                    Company Details{" "}
                    <span style={{ fontSize: "0.8em" }}>(If applicable)</span>
                  </h6>
                </CardHeader>
                <CardBody style={{ margin: "0.5em" }}>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <TextField
                        name="company"
                        variant="outlined"
                        value={this.state.companyName}
                        onChange={this.handleCompanyNameChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="company"
                        label="Company"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <TextValidator
                        name="companyPhone"
                        variant="outlined"
                        value={this.state.companyPhone}
                        onChange={this.handleCompanyPhoneChange.bind(this)}
                        inputProps={{}}
                        fullWidth
                        id="companyPhone"
                        label="Phone"
                        autoComplete="off"
                        validators={["isNumber"]}
                        errorMessages={["phone is not valid"]}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6}>
              {/* <Card
                style={{
                  margin: "0.5em",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                  height: "100%",
                }}
              >
                <CardHeader style={{ background: "#163f37", color: "#fff" }}>
                  <h6 style={{ margin: "0.5em" }}>Company Address</h6>
                </CardHeader>
                <CardBody style={{ margin: "0.5em" }}>
                  <Row style={{ marginTop: "1em" }}>
                    <Col>
                      <TextField
                        name="clane"
                        variant="outlined"
                        value={this.state.companyLane1}
                        onChange={this.handleCompanyLane1Change.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="clane"
                        label="Address Line 1"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <TextField
                        name="clane2"
                        variant="outlined"
                        value={this.state.companyLane2}
                        onChange={this.handleCompanyLane2Change.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="clane2"
                        label="Address Line 2"
                        autoComplete="off"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1.5em" }}>
                    <Col>
                      <TextField
                        name="ccity"
                        variant="outlined"
                        value={this.state.companyCity}
                        onChange={this.handleCompanyCityChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="ccity"
                        label="City"
                        autoComplete="off"
                      />
                    </Col>
                    <Col>
                      <Autocomplete
                        id="state"
                        options={this.state.companyStateList}
                        getOptionLabel={(option) => option.name}
                        defaultValue={this.state.state}
                        value={this.state.companyState}
                        onChange={(event, value) => {
                          this.handleCompanyStateChange(value);
                        }}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "1.5em" }}>
                    <Col>
                      <TextValidator
                        name="cpostalCode"
                        variant="outlined"
                        value={this.state.companyPostalCode}
                        onChange={this.handleCompanyPostalCodeChange.bind(this)}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        fullWidth
                        id="cpostalCode"
                        label="Postal Code"
                        autoComplete="off"
                        errorMessages={[
                          "this field is required",
                          "postal code is not valid",
                        ]}
                      />
                    </Col>
                    <Col>
                      <Autocomplete
                        id="ccountry"
                        options={this.state.countryList}
                        defaultValue={this.state.country}
                        getOptionLabel={(option) => option.name}
                        value={this.state.companyCountry}
                        onChange={(event, value) => {
                          this.handleCompanyCountryChange(value);
                        }}
                        inputProps={{
                          style: {
                            textTransform: "capitalize",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row style={{ marginTop: "1.5em" }}>
            <Col>
              <Card
                style={{
                  margin: "0.5em",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                  height: "100%",
                }}
              >
                <CardHeader style={{ background: "#163f37", color: "#fff" }}>
                  <h6 style={{ margin: "0.5em" }}>Terms {"&"} Conditions</h6>
                </CardHeader>
                <CardBody style={{ margin: "0.5em" }}>
                  <div
                    className="WordSection1"
                    style={{
                      backgroundColor: "#FFFFFF",
                      padding: "0.5em",
                      maxHeight: "15em",
                      overflow: "auto",
                    }}
                  >
                    <h1
                      style={{
                        marginLeft: "0in",
                        textAlign: "center",
                        textIndent: "0in",
                      }}
                      align="center"
                    >
                      <span
                        style={{
                          fontSize: "12.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        WRIGHT REPORTS/CORPORATEINFORMATION.COM
                      </span>
                    </h1>
                    <p
                      className="MsoNormal"
                      style={{ textAlign: "center" }}
                      align="center"
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            msoBidiFontSize: "12.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Terms and Conditions of Use
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        <span style={{ msoTabCount: 1 }}></span>
                        <b style={{ msoBidiFontWeight: "normal" }}>
                          By choosing to purchase or subscribe to the Reports on
                          this site (the “Service”), you represent that you have
                          read and agree to comply with all of these Terms and
                          Conditions of Use (the “Terms”).
                        </b>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          General
                          <span style={{ msoTabCount: 1 }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </span>
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        The Winthrop Corporation and its licensee, Wright
                        Investors’ Service, Inc. (together, “Wright”) may
                        change, supplement or remove any of the Terms at any
                        time.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Such changes will be posted at the Terms and Conditions
                        link.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Accordingly, you should review these Terms often.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          If any of these Terms or any future changes are
                          unacceptable to you, you may discontinue your use of
                          the Service.
                          <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                          Your continued use of the Service indicates acceptance
                          by you of the Terms in effect at the time of your
                          log-in.
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        Wright may modify or discontinue any part of the Service
                        at any time, including the availability of any feature
                        or content.
                        <span style={{ msoSpacerun: "yes" }} />
                        Wright may also impose limits on certain features or
                        restrict your access to parts or all of the Service
                        without notice or liability.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Notwithstanding the foregoing, persons who have
                        subscribed to the Service for a specified period of time
                        (“Subscribers”) will not be subject to page restrictions
                        less than those specified in their subscription until
                        the next subscription renewal.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Registration
                        </span>
                      </b>
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        The registration process requires you to create an
                        account that includes an e-mail address and a password.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        You are completely liable for all uses of the Service by
                        you and any third party using your e-mail address and
                        password.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Subscribers will be notified if they reach the page
                        limit specified in their subscription (currently 125
                        daily pages for Global Basic subscribers and 200 daily
                        pages for Global Professional subscribers).
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Copyright and Other Proprietary Rights
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        The Service and all content accessed through the Service
                        are protected by copyright pursuant to U.S. and
                        international law.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        You agree to abide by all copyright notices, information
                        or restrictions contained in any content accessed
                        through the Service.
                        <span style={{ msoSpacerun: "yes" }} />
                        All present and future rights in and to copyrights,
                        trademarks and service marks of any type shall at all
                        times be and remain the sole and exclusive property of
                        Wright and its contributors.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        All rights not expressly granted to you herein are
                        reserved by Wright.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        There are no implied licenses to any of the Service.
                        <span style={{ msoSpacerun: "yes" }} />
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        Your use of the Service will be monitored to ensure
                        compliance with these Terms and Conditions.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        In the event of your non-compliance, Wright reserves the
                        right to take such action as it deems necessary or
                        desirable.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Restrictions on Use
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        Subscribers may not share the Service with other persons
                        or entities, either internally or externally.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        The Service is intended only for each Subscriber’s
                        individual use.
                        <span style={{ msoSpacerun: "yes" }} />
                        Subscribers may not make available their user name or
                        password to another party.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        You may display any part of the Service on your monitor,
                        and you may make one paper copy of each Report you
                        purchase or subscribe for, but only for your personal or
                        internal business use and provided you retain and
                        observe all copyright and other proprietary notices and
                        restrictions on use.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        EXCEPT AS PROVIDED IN THE PRECEDING PARAGRAPH, YOU MAY
                        NOT STORE ANY PORTION OF THE SERVICE IN ANY COMPUTER
                        SYSTEM (OTHER THAN IN YOUR COMPUTER’S TEMPORARY MEMORY
                        SOLELY AS A RESULT OF YOUR ACCESSING THE SERVICE THROUGH
                        A BROWSER), COPY (OTHER THAN ONE PAPER COPY FOR INTERNAL
                        USE AND ARCHIVAL PURPOSES), PUBLISH, DISTRIBUTE,
                        TRANSMIT, ALTER, OR IN ANY WAY EXPLOIT ANY PART OF THE
                        SERVICE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        YOU MAY NOT MAKE USE OF (i) ANY PROGRAMMATIC OR
                        AUTOMATED RETRIEVAL SYSTEM TO ACCESS ANY PART OF THE
                        SERVICE, OR (ii) ANY COMPUTER TOOLS TO ELECTRONICALLY
                        REFORMAT AND/OR RE-DISTRIBUTE THE SERVICE IN WHOLE OR IN
                        PART.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        YOU MAY NOT RECIRCULATE, REDISTRIBUTE, OR CREATE
                        DERIVATIVE WORKS FROM ANY PART OF THE SERVICE WITHOUT
                        WRIGHT’S PRIOR WRITTEN CONSENT.
                        <span style={{ msoSpacerun: "yes" }} />
                        ADDITIONALLY, YOU MAY NOT DISTRIBUTE ANY PART OF THE
                        SERVICE THROUGH A COMPUTER NETWORK (WHETHER LIMITED TO
                        ONE PHYSICAL LOCATION OR SERVING A WIDE AREA) OR VIA THE
                        INTERNET WITHOUT WRIGHT’S PRIOR WRITTEN CONSENT.
                        {"{"}" "{"}"}
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Disclaimer and Limitation of Liability
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE IS INTENDED
                        ONLY FOR RESEARCH AND INFORMATION AND NOT FOR INDIVIDUAL
                        INVESTMENT ADVICE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        YOU ACKNOWLEDGE AND AGREE THAT IT IS YOUR RESPONSIBILITY
                        TO EVALUATE INDEPENDENTLY ANY INFORMATION OR OPINIONS
                        PROVIDED IN THE SERVICE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE IS
                        DISTRIBUTED ON AN “AS IS” BASIS, WITHOUT WARRANTIES OF
                        ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                        LIMITATION, WARRANTIES OF TITLE, NON-INFRINGEMENT,
                        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        WHILE THE SERVICE OBTAINS INFORMATION FROM SOURCES THAT
                        ARE BELIEVED RELIABLE, WRIGHT DOES NOT GUARANTEE THE
                        ACCURACY, COMPLETENESS OR AVAILABILITY OF THE SERVICE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        YOU HEREBY ACKNOWLEDGE THAT USE OF THE SERVICE IS AT
                        YOUR SOLE RISK.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        IF YOU ARE DISSATISFIED WITH THE SERVICE OR OBJECT TO
                        THE TERMS OF USE, <span className="GramE">YOUR</span>
                        {"{"}" "{"}"}
                        SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING
                        THE SERVICE.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW,
                        NEITHER WRIGHT NOR ITS CONTRIBUTORS WILL BE LIABLE TO
                        YOU FOR DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                        SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES, BASED ON OR
                        ARISING FROM YOUR USE <span className="GramE">OF,</span>
                        OR INABILITY TO USE, THE SERVICE OR ANY PART OF THE
                        SERVICE.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Indemnification
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        You hereby indemnify, defend and hold harmless Wright,
                        and all of its officers, directors, owners, agents,
                        contributors, affiliates, and licensees from and against
                        any and all liability and costs, including without
                        limitation, reasonable attorney’s fees, incurred by any
                        of them in connection with any claim based on or arising
                        out of (i) infringement of any copyright or other
                        intellectual property right of Wright or its
                        contributors; (ii) any breach of these Terms by you or
                        any user of your registered e-mail address and password;
                        or (iii) any use or misuse of, or failure to use, the
                        Service, including the Reports.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        ></span>
                      </b>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Termination
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        Wright may, in its sole discretion, suspend or terminate
                        a Subscriber's access to all or any part of the Service
                        for any reason that, in Wright's sole judgment, might
                        expose Wright or any of its contributors to any
                        liability.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        In such event, and provided that you have not breached
                        any of these Terms, Wright shall return the unused
                        portion, if any, of your subscription fee.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            8.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Links
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        The inclusion of links from the Service to other Web
                        sites does not imply any endorsement of the sites or of
                        any products or services offered by those sites.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Wright does not control and is not responsible for the
                        content or policies of such sites.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            9.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Governing Law
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        These Terms, including all intellectual property issues,
                        shall be interpreted and enforced in accordance with
                        Connecticut law without reference to its conflicts of
                        law rules.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        Any action to enforce any rights under these Terms shall
                        be brought in the federal or state courts located in
                        Connecticut.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                        You agree to submit to the personal jurisdiction of the
                        state and federal courts located in Connecticut with
                        respect to any dispute based on arising from your use of
                        the Service or breach of the Terms.
                        <span style={{ msoSpacerun: "yes" }}>&nbsp; </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            10. &nbsp;
                            <span
                              style={{ font: '7.0pt "Times New Roman"' }}
                            ></span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Severability
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoBodyTextIndent"
                      style={{ textIndent: "0in" }}
                    >
                      <span className="GramE">
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          If any provision of these Terms is held to be invalid
                          or unenforceable, that provision will be enforced to
                          the maximum extent permissible, and all other
                          provisions of the Terms will remain in force and
                          effect.
                        </span>
                      </span>
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            11. &nbsp;{" "}
                            <span
                              style={{ font: '7.0pt "Times New Roman"' }}
                            ></span>
                          </span>
                        </span>
                      </b>
                      <b>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          No Assignment
                        </span>
                      </b>
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        You may not assign any of your rights and
                        responsibilities under this Agreement, and any attempt
                        to do so shall be null and void.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        ></span>
                      </b>
                    </p>
                    <p
                      className="MsoNormal"
                      style={{
                        marginLeft: ".25in",
                        textIndent: "-.25in",
                        msoList: "l0 level1 lfo1",
                      }}
                    >
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                            msoFareastFontFamily: "Cambria",
                            msoBidiFontFamily: "Cambria",
                          }}
                        >
                          <span style={{ msoList: "Ignore" }}>
                            12. &nbsp;{" "}
                            <span
                              style={{ font: '7.0pt "Times New Roman"' }}
                            ></span>
                          </span>
                        </span>
                      </b>
                      <b style={{ msoBidiFontWeight: "normal" }}>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            fontFamily: '"Cambria","serif"',
                          }}
                        >
                          Entire Agreement
                        </span>
                      </b>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        The Terms and any other terms and conditions of use on
                        this site constitute the entire agreement between you
                        and Wright relating to use of the Service.
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      >
                        <span style={{ msoTabCount: 1 }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </span>
                    </p>
                    <p className="MsoNormal">
                      <span
                        style={{
                          fontSize: "11.0pt",
                          fontFamily: '"Cambria","serif"',
                        }}
                      ></span>
                    </p>
                    <p className="MsoNormal"></p>
                    <p className="MsoNormal"></p>
                    <p className="MsoNormal"></p>
                    <p className="MsoNormal"></p>
                  </div>
                </CardBody>
                <CardFooter>
                  <Row style={{ margin: "auto" }}>
                    <Col md={6} style={{ textAlign: "center" }}>
                      <Checkbox
                        checked={this.state.checked}
                        onChange={this.handleTandcChange}
                        color="primary"
                      />
                      <span style={{ fontSize: "small" }}>
                        I agree to the terms and conditions above
                      </span>
                    </Col>
                    <Col md={6} style={{ textAlign: "center" }}>
                      {this.state.checked && this.state.checked === true && (
                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          style={{
                            background: "#f26d21",
                            color: "#fff",
                            height: "100%",
                            borderBottom: "3px solid #636262",
                            borderRadius: "5px",
                            margin: 0,
                          }}
                        >
                          Continue
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "1.5em" }}>
            <span style={{ textAlign: "center" }}>
              Already member?{" "}
              <a
                onClick={this.login.bind(this)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                <u>Login</u>
              </a>
            </span>
          </Row>
        </ValidatorForm>
      </ModalBody>
    );
  }
}
