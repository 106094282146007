import React, { Component } from "react";
import ReactApexCharts from "react-apexcharts";
import "react-tabs/style/react-tabs.css";
import "./SingleAxisGenericGraph.css";
export default class SingleAxisGenericGraph extends Component {
  constructor(props) {
    super(props);
    let graphWidth = "450";
    let isNotPriceGraph = true;
    let isPriceGraph = false;
    let graphtype = "";
    if (
      "Closing Price" === this.props.graphData.yAxisTitle ||
      "High Price" === this.props.graphData.yAxisTitle ||
      "Low Price" === this.props.graphData.yAxisTitle ||
      "Quarterly % Change" === this.props.graphData.yAxisTitle ||
      "12 months % Change" === this.props.graphData.yAxisTitle
    ) {
      graphWidth = 1;
      isNotPriceGraph = false;
      isPriceGraph = true;
    }
    if (this.props.type) {
      graphtype = this.props.type;
    }

    if (graphtype === "flowOfFunds") {
      if (this.props.graphData.yAxisTitleSign === "%") {
        this.state = {
          graphWidth: graphWidth,
          isPriceGraph: isPriceGraph,
          isNotPriceGraph: isNotPriceGraph,
          series: [
            {
              name: "",
              type: "bar",
              data: this.props.graphData.graphValues,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "bar",
              stacked: false,
              toolbar: {
                show: false,
              },
            },

            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            title: {
              text: this.props.graphData.yAxisTitle,
              align: "center",
              margin: 10,
              offsetX: 10,
              offsetY: 0,
              style: {
                fontSize: "12px",
              },
            },
            stroke: {
              width: [0, 2, 5],
              curve: "smooth",
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [
                    {
                      to: 0,
                      from: -100000000000,
                      color: "#F15B46",
                    },
                  ],
                },
                columnWidth: "50%",
              },
            },
            colors: ["#1f77b4"],

            legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial",
              fontWeight: 400,
              formatter: undefined,
              inverseOrder: false,
              width: undefined,
              height: undefined,
              tooltipHoverFormatter: undefined,
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: ["#7d6608", "#0b5345"],
                useSeriesColors: true,
              },
            },

            labels: this.props.graphData.labelData,
            xaxis: {
              title: {
                text: this.props.graphData.xAxisTitle,
                style: {
                  colors: "#808b96",
                },
              },

              axisBorder: {
                show: true,
                color: "#808b96",
                height: 1,
                width: "100%",
                offsetX: 0,
                offsetY: 0,
              },
            },
            yaxis: {
              title: {
                text: "",
                style: {
                  wordWrap: "break-word !important",
                },
              },
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#808b96 ",
              },
              labels: {
                formatter: (value) => {
                  return value + " %";
                },
              },
            },

            dataLabels: {
              enabled: false,
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y + " %";
                  }
                },
              },
            },
          },
        };
      } else {
        let dataArr = [];
        for (let i = 0; i < this.props.graphData.graphValues.length; i++) {
          var a = "";
          a = this.props.graphData.graphValues[i].replace(/\,/g, ""); // 1125, but a string, so convert it to number
          a = parseInt(a);
          dataArr.push(a);
        }
        this.state = {
          graphWidth: graphWidth,
          isPriceGraph: isPriceGraph,
          isNotPriceGraph: isNotPriceGraph,
          series: [
            {
              name: "",
              type: "bar",
              data: dataArr,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "bar",
              stacked: false,
              toolbar: {
                show: false,
              },
            },

            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            title: {
              text: this.props.graphData.yAxisTitle,
              align: "center",
              margin: 10,
              offsetX: 10,
              offsetY: 0,
              style: {
                fontSize: "12px",
              },
            },
            stroke: {
              width: [0, 2, 5],
              curve: "smooth",
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [
                    {
                      to: 0,
                      from: -100000000000,
                      color: "#F15B46",
                    },
                  ],
                },
                columnWidth: "50%",
              },
            },
            colors: ["#1f77b4"],

            legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial",
              fontWeight: 400,
              formatter: undefined,
              inverseOrder: false,
              width: undefined,
              height: undefined,
              tooltipHoverFormatter: undefined,
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: ["#7d6608", "#0b5345"],
                useSeriesColors: true,
              },
            },

            labels: this.props.graphData.labelData,
            xaxis: {
              title: {
                text: this.props.graphData.xAxisTitle,
                style: {
                  colors: "#808b96",
                },
              },

              axisBorder: {
                show: true,
                color: "#808b96",
                height: 1,
                width: "100%",
                offsetX: 0,
                offsetY: 0,
              },
            },
            yaxis: {
              title: {
                text: "",
                style: {
                  wordWrap: "break-word !important",
                },
              },
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#808b96",
              },
              labels: {
                formatter: function (val, index) {
                  return val.toFixed(0);
                },
              },
            },

            dataLabels: {
              enabled: false,
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (y) {
                    return y.toFixed(2);
                  }
                },
              },
            },
          },
        };
      }
    } else {
      if (this.props.graphData.yAxisTitleSign === "%") {
        this.state = {
          graphWidth: graphWidth,
          isPriceGraph: isPriceGraph,
          isNotPriceGraph: isNotPriceGraph,
          series: [
            {
              name: "",
              type: "bar",
              data: this.props.graphData.graphValues,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "bar",
              stacked: false,
              toolbar: {
                show: false,
              },
            },

            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            title: {
              text: this.props.graphData.yAxisTitle,
              align: "center",
              margin: 10,
              offsetX: 10,
              offsetY: 0,
              style: {
                fontSize: "12px",
              },
            },
            stroke: {
              width: [0, 2, 5],
              curve: "smooth",
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [
                    {
                      to: 0,
                      from: -100000000000,
                      color: "#F15B46",
                    },
                  ],
                },
                columnWidth: "50%",
              },
            },
            colors: ["#1f77b4"],

            legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial",
              fontWeight: 400,
              formatter: undefined,
              inverseOrder: false,
              width: undefined,
              height: undefined,
              tooltipHoverFormatter: undefined,
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: ["#7d6608", "#0b5345"],
                useSeriesColors: true,
              },
            },

            labels: this.props.graphData.labelData,
            xaxis: {
              title: {
                text: this.props.graphData.xAxisTitle,
                style: {
                  colors: "#808b96",
                },
              },

              axisBorder: {
                show: true,
                color: "#808b96",
                height: 1,
                width: "100%",
                offsetX: 0,
                offsetY: 0,
              },
            },
            yaxis: {
              title: {
                text: "",
                style: {
                  wordWrap: "break-word !important",
                },
              },
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#808b96 ",
              },
              labels: {
                formatter: (value) => {
                  return value + " %";
                },
              },
            },

            dataLabels: {
              enabled: false,
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y + " %";
                  }
                },
              },
            },
          },
        };
      } else {
        this.state = {
          graphWidth: graphWidth,
          isPriceGraph: isPriceGraph,
          isNotPriceGraph: isNotPriceGraph,
          series: [
            {
              name: "",
              type: "bar",
              data: this.props.graphData.graphValues,
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "bar",
              stacked: false,
              toolbar: {
                show: false,
              },
            },

            grid: {
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
            title: {
              text: this.props.graphData.yAxisTitle,
              align: "center",
              margin: 10,
              offsetX: 10,
              offsetY: 0,
              style: {
                fontSize: "12px",
              },
            },
            stroke: {
              width: [0, 2, 5],
              curve: "smooth",
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [
                    {
                      to: 0,
                      from: -100000000000,
                      color: "#F15B46",
                    },
                  ],
                },
                columnWidth: "50%",
              },
            },
            colors: ["#1f77b4"],

            legend: {
              show: true,
              showForSingleSeries: false,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: "bottom",
              horizontalAlign: "center",
              floating: false,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial",
              fontWeight: 400,
              formatter: undefined,
              inverseOrder: false,
              width: undefined,
              height: undefined,
              tooltipHoverFormatter: undefined,
              offsetX: 0,
              offsetY: 0,
              labels: {
                colors: ["#7d6608", "#0b5345"],
                useSeriesColors: true,
              },
            },

            labels: this.props.graphData.labelData,
            xaxis: {
              title: {
                text: this.props.graphData.xAxisTitle,
                style: {
                  colors: "#808b96",
                },
              },

              axisBorder: {
                show: true,
                color: "#808b96",
                height: 1,
                width: "100%",
                offsetX: 0,
                offsetY: 0,
              },
            },
            yaxis: {
              title: {
                text: "",
                style: {
                  wordWrap: "break-word !important",
                },
              },
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#808b96",
              },
              labels: {
                formatter: function (val, index) {
                  return val.toFixed(0);
                },
              },
            },

            dataLabels: {
              enabled: false,
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (y) {
                    return y.toFixed(2);
                  }
                },
              },
            },
          },
        };
      }
    }
  }

  render() {
    return (
      <>
        {this.state.isPriceGraph && (
          <ReactApexCharts
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        )}

        {this.state.isNotPriceGraph && (
          <ReactApexCharts
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
            width={450}
          />
        )}
      </>
    );
  }
}
