import React from "react";
import PageAlertContext from "../PageAlert/PageAlertContext";
import "./CompanySnapshotPage.css";
const CompanySnapshotPage = ({ children }) => {
  return (
    <PageAlertContext.Consumer>
      {(context) => {
        return (
          <>
            <div
              id="page-content"
              data-spy="scroll"
              data-target="#sidebar"
              data-offset="50"
            >
              {children}
            </div>
          </>
        );
      }}
    </PageAlertContext.Consumer>
  );
};

export default CompanySnapshotPage;
