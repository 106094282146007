import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Row } from "reactstrap";
import "./FinancialLiquidityAnalysis.css";
export default class FinancialLiquidityAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      accountingRatioIndData: [],
      note: props.data.note,
      noteHeading: props.data.noteHeading,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    let liquidityAnalysisList = this.state.data.liquidityAnalysisList;
    if (liquidityAnalysisList.length > 0) {
      liquidityAnalysisList.reverse();
    }
    return (
      <>
        <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
          <Col
            md={12}
            style={{ padding: "0em 0em 0em 1.4em", marginLeft: "1em" }}
          >
            <Row>
              <Col md={8}>
                {" "}
                <span>
                  <span className="section_header"> Liquidity Analysis</span>
                </span>{" "}
              </Col>
              <Col md={4}></Col>
            </Row>
            <Table responsive className="financial_analysis">
              <tr>
                <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                {liquidityAnalysisList.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                {liquidityAnalysisList.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.fiscalYearEndDate !== null &&
                      data.fiscalYearEndDate !== ""
                        ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                        : ""}
                    </td>
                  );
                })}
              </tr>
            </Table>
            <Row style={{ padding: "10px", textAlign: "center" }}>
              <Col md={12}>
                <h5 style={{ fontWeight: "bold", color: "#1a5276" }}>
                  {this.state.noteHeading}
                </h5>
              </Col>
            </Row>
            <Row style={{ padding: "10px" }}>
              <Col md={12}>
                <p style={{ color: "#1a5276" }}>{this.state.note}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}
