import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import { Button, Col, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import PtsAlert from "../ptsAlert/PtsAlert";
import "./ResetPassword.css";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    let email = "";
    let str = window.location.href;
    if (str) {
      let urlArr = str.split("?");
      let emailArr = urlArr[1].split("=");
      email = emailArr[1];
    }

    let pageId = "";
    let modalShow = false;
    if (props.modalShow === true) {
      modalShow = true;
    }

    this.state = {
      password: "",
      userName: email,
      firstName: "",
      lastName: "",
      isLogin: true,
      isResetPass: false,
      isForgotPass: false,
      isSignup: false,
      selectedPlan: "",
      pageId: props.pageId,
      state: props.state,
      showModal: modalShow,
      modalSize: "sm",
      false: false,
      countryList: [],
      stateList: [],
    };
    this.getCountryData();
  }
  componentDidMount() {
    const postObject = {};
    FetchServerData.callPostService("statems/getAll", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            stateList: output.data,
          });
        }
      })
      .catch((error) => {});
  }
  getCountryData() {
    const postObject = {};
    FetchServerData.callPostService("countryms/getAll", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          this.setState({
            countryList: output.data,
          });
        }
      })
      .catch((error) => {});
  }
  newLegendClickHandler(e, legendItem) {}
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleUserNameChange(e) {
    this.setState({ userName: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleTempPassChange(e) {
    this.setState({ tempPass: e.target.value });
  }
  handleRePasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }
  handleCompanyNameChange(e) {
    this.setState({ companyName: e.target.value });
  }
  handleLane1Change(e) {
    this.setState({ lane1: e.target.value });
  }
  handleLane2Change(e) {
    this.setState({ lane2: e.target.value });
  }
  handleCountryChange(e) {
    this.setState({ country: e.target.value });
  }
  handleStateChange(e) {
    this.setState({ state: e.target.value });
  }
  handleCityChange(e) {
    this.setState({ city: e.target.value });
  }
  handlePostalCodeChange(e) {
    this.setState({ postalCode: e.target.value });
  }
  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }
  handleFaxChange(e) {
    this.setState({ fax: e.target.value });
  }
  handleTandcChange = (e) => {
    this.setState({ checked: e.target.checked });
  };

  resetPassword(e) {
    const postObject = {
      user: {
        email: this.state.userName,
        password: this.state.password,
        tempPassword: this.state.tempPass,
      },
    };
    FetchServerData.callPostService("userms/resetPassword", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }
  getModalPopup(e) {
    this.setState({
      showModal: true,
    });
  }
  userForgotPass(e) {
    this.setState({
      isResetPass: true,
      isLogin: false,
      modalSize: "sm",
    });
  }
  signUp(e) {
    this.setState({
      isResetPass: false,
      isLogin: false,
      isSignup: true,
      modalSize: "lg",
    });
  }
  login(e) {
    this.setState({
      isResetPass: false,
      isSignup: false,
      isLogin: true,
      modalSize: "sm",
    });
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    return (
      <>
        <Row style={{ margin: "5em" }}>
          <Col md={4}></Col>
          <Col
            md={4}
            style={{
              background: "#fff",
              boxShadow:
                "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
            }}
          >
            <div className="loginCss">Reset Password</div>

            <div className="outerDivCss" style={{ marginTop: "1em" }}>
              <Row style={{ margin: "0 auto" }}>
                <TextField
                  name="email"
                  required
                  disabled
                  variant="outlined"
                  value={this.state.userName}
                  onChange={this.handleUserNameChange.bind(this)}
                  fullWidth
                  id="email"
                  label="Email"
                  autoComplete="off"
                />
              </Row>

              <Row style={{ margin: "0 auto", marginTop: "1em" }}>
                <TextField
                  variant="outlined"
                  autoComplete="off"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  value={this.state.password}
                  onChange={this.handlePasswordChange.bind(this)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword.bind(this)}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Row>
              <Row style={{ margin: "0 auto", marginTop: "1em" }}>
                <TextField
                  name="authenticationCode"
                  required
                  variant="outlined"
                  value={this.state.tempPass}
                  onChange={this.handleTempPassChange.bind(this)}
                  fullWidth
                  id="email"
                  label="Authentication Code"
                  autoComplete="off"
                />
              </Row>

              <Row style={{ margin: "1em" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  style={{
                    background: "#f26d21",
                    color: "#fff",
                    height: "100%",
                    borderBottom: "3px solid #636262",
                    borderRadius: "5px",
                    margin: "0",
                  }}
                  onClick={this.resetPassword.bind(this)}
                >
                  Reset password
                </Button>
              </Row>
              <Row style={{ marginTop: "1em" }}>
                <span style={{ textAlign: "center" }}>
                  Already member?{" "}
                  <a
                    onClick={this.login.bind(this)}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>Login</u>
                  </a>
                </span>
              </Row>
              <Row style={{ marginTop: "1em" }}>
                <span style={{ textAlign: "center" }}>
                  Not a member?{" "}
                  <a
                    onClick={this.signUp.bind(this)}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    <u>SignUp</u>
                  </a>
                </span>
              </Row>
            </div>
          </Col>
          <Col md={4}></Col>
        </Row>
      </>
    );
  }
}
