import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import cartAction from "../../redux/cart/cartAction";
import store from "../../redux/store";
import userDataAction from "../../redux/userData/userDataAction";
import PdfReport from "../pdfReport/PdfReport";
import "./Checkout1.css";

const Success = (props) => {
  store.dispatch(cartAction.setCart([]));
  function generatePdfReport(report) {
    setcusip(report.uid);
    setModal(true);
  }
  function generatePdfReport1(report) {
    setPdfUrl(report.uid);
    setPdfModal(true);
  }
  function toggle1() {
    setModal(false);
  }
  function toggle() {
    setPdfModal(false);
  }

  const [session, setSession] = useState({});
  const [showModal1, setModal] = useState(false);
  const [cusip, setcusip] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [showModal, setPdfModal] = useState(false);
  const [isOneTime, setOneTime] = useState(false);
  const [isSubscription, setSubscription] = useState(false);
  const location = useLocation();
  let sessionId = location.search.replace("?session_id=", "");
  let params = queryString.parse(location.search);
  let userUpdatedInfo = store.getState().userData.userData;
  sessionId = params.session_id;
  useEffect(() => {
    store.dispatch(cartAction.setCart([]));

    async function fetchSession() {
      FetchServerData.callPostService("pg/checkout-session", {
        sessionId: sessionId,
      })
        .then((output) => {
          if (output.status === "SUCCESS") {
            let responseData = output.data;
            let orderItemList = [];
            let orderData = {};
            if (responseData) {
              orderItemList = responseData.order.orderItems;
              orderData = responseData.order.user;
            }
            setOrderList(orderItemList);
            setOrderDetails(orderData);
            // setSession(sessionDetails);
            if (responseData.order.type === "OneTime") {
              setOneTime(true);
              setSubscription(false);
            } else {
              let userdata = {
                id: userUpdatedInfo.id,
                firstName: userUpdatedInfo.firstName,
                lastName: userUpdatedInfo.lastName,
                email: userUpdatedInfo.email,
                phone: userUpdatedInfo.phone,
                subscriptionStartDate: null,
                subscriptionEndDate: null,
                createdOn: null,
                userId: null,
                updatedOn: null,
                subscriptionId: null,
                stripeCustomerid: null,
                isSubcription: null,
                password: null,
                tempPassword: null,
                isGoogleLogin: null,
                isadmin: null,
                isFacebookLogin: null,
                googleSession: null,
                facebookSession: null,
                address: userUpdatedInfo.address,
                company: null,
                activeUser: true,
              };
              store.dispatch(userDataAction.setUserData(userdata));
              setOneTime(false);
              setSubscription(true);
            }
          }
        })
        .catch((error) => {});
    }
    fetchSession();
  }, [sessionId]);
  let itemRow = orderList.map((report, i) => {
    return (
      <tr style={{ background: "white" }}>
        <td style={{ borderRight: "1px solid", textAlign: "left" }}>
          {report.type === "Company" ? (
            <img
              src="/home/assets/img/CartReportImage.gif"
              alt="Company Report"
              height="20%"
            ></img>
          ) : (
            <img
              src="/home/assets/img/ind_cart_rpt.gif"
              alt="Company Report"
              height="20%"
            ></img>
          )}
          {report !== null ? (
            <>
              <span className="report_list" style={{ fontSize: "1em" }}>
                <span
                  style={{ color: "rgb(242, 109, 33)", fontWeight: "bold" }}
                >
                  {report.type + "-"}
                </span>{" "}
                {report.description}
              </span>
            </>
          ) : (
            ""
          )}
        </td>
        <td style={{ textAlign: "left", borderRight: "1px solid" }}>
          {"$" + report.plan.price}
        </td>
        <td
          style={{
            borderRight: "1px solid",
            textAlign: "center",
          }}
        >
          {report.type === "Company" ? (
            <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
              <VisibilityIcon
                style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
                onClick={(e) => generatePdfReport(report)}
              />
            </span>
          ) : (
            <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
              <VisibilityIcon
                style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
                onClick={(e) => generatePdfReport1(report)}
              />
            </span>
          )}
        </td>
      </tr>
    );
  });

  let itemRow1 = orderList.map((report, i) => {
    return (
      <tr style={{ background: "white" }}>
        <td style={{ borderRight: "1px solid", textAlign: "left" }}>
          {report !== null ? (
            <>
              <span className="report_list" style={{ fontSize: "1em" }}>
                {report.description}
              </span>
            </>
          ) : (
            ""
          )}
        </td>
        <td style={{ textAlign: "left", borderRight: "1px solid" }}>
          {"$" + report.plan.price}
        </td>
      </tr>
    );
  });
  return (
    <>
      <Container
        maxWidth="lg"
        className="success_component"
        style={{ marginTop: "5em", minHeight: "50em" }}
      >
        <Row style={{ marginTop: "4em" }}>
          <Row style={{ marginTop: "5em" }}>
            <Col md={12}>
              <Card
                style={{
                  height: "100%",
                  boxShadow:
                    "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                }}
              >
                <CardHeader
                  style={{
                    background: "#2b7d6d",
                    color: "#fff",
                    padding: "10px !important",
                  }}
                >
                  Personal Details
                </CardHeader>
                <CardBody>
                  <Row
                    style={{
                      margin: 0,
                      padding: "3px",
                      borderBottom: "0.5px solid #f3e5e5",
                    }}
                  >
                    <Col md={3}>Name</Col>
                    <Col md={9} style={{ textAlign: "left" }}>
                      {orderDetails ? orderDetails.firstName : ""}{" "}
                      {orderDetails ? orderDetails.lastName : ""}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: 0,
                      padding: "3px",
                      borderBottom: "0.5px solid #f3e5e5",
                    }}
                  >
                    <Col md={3}>Email</Col>
                    <Col md={9} style={{ textAlign: "left" }}>
                      {orderDetails ? orderDetails.email : ""}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: 0,
                      padding: "3px",
                      borderBottom: "0.5px solid #f3e5e5",
                    }}
                  >
                    <Col md={3}>Phone</Col>
                    <Col md={9} style={{ textAlign: "left" }}>
                      {orderDetails.phone === null ||
                      orderDetails.phone === "null" ||
                      orderDetails.phone === undefined
                        ? ""
                        : orderDetails.phone}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      margin: 0,
                      padding: "3px",
                    }}
                  >
                    <Col md={3}>Address</Col>
                    <Col md={9} style={{ textAlign: "left" }}>
                      {Utils.getAddressAsText(orderDetails.address)}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br></br>
          <Row style={{ marginTop: "2em" }}>
            <Col md={12}>
              {isOneTime && (
                <Table
                  responsive
                  className="sum_analysis"
                  style={{
                    border: "1px solid",
                    margin: "0.5em 0em 0em 0em",

                    boxShadow:
                      "rgb(128, 128, 128) 0px 3px, rgb(118, 118, 118) 0px 4px, rgb(108, 108, 108) 0px 5px, rgb(98, 98, 98) 0px 6px, rgb(88, 88, 88) 0px 7px, rgb(78, 78, 78) 0px 8px, rgb(128, 128, 128) 0px 14px 6px -1px",
                  }}
                >
                  <thead
                    style={{
                      fontSize: "0.8em",
                      background: "#2b7d6d",
                      color: "#fff",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        Reports:
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        Price
                      </th>
                      <th
                        className="sum_analysis"
                        colSpan="3"
                        style={{
                          borderRight: "1px solid",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow}</tbody>
                </Table>
              )}
              {isSubscription && (
                <Table
                  responsive
                  className="sum_analysis"
                  style={{
                    border: "1px solid",
                    margin: "0.5em 0em 0em 0em",

                    boxShadow:
                      "rgb(128, 128, 128) 0px 3px, rgb(118, 118, 118) 0px 4px, rgb(108, 108, 108) 0px 5px, rgb(98, 98, 98) 0px 6px, rgb(88, 88, 88) 0px 7px, rgb(78, 78, 78) 0px 8px, rgb(128, 128, 128) 0px 14px 6px -1px",
                  }}
                >
                  <thead
                    style={{
                      fontSize: "0.8em",
                      background: "#2b7d6d",
                      color: "#fff",
                    }}
                  >
                    <tr style={{ textAlign: "center" }}>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          textDecoration: "underline",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        Reports:
                      </th>
                      <th
                        className="sum_analysis"
                        style={{
                          borderRight: "1px solid",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemRow1}</tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Row>
      </Container>

      <Modal
        isOpen={showModal1}
        size="xl"
        className="modalContain"
        style={{ overflowY: "hidden" }}
      >
        <ModalHeader style={{ background: "#286894", color: "#fff" }}>
          <Row>
            <Col md={6}>
              <Label
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "center",
                  float: "left",
                }}
              >
                Report
              </Label>
            </Col>
            <Col md={6}>
              <Fab
                aria-label="add"
                className="modal_close_btn"
                onClick={toggle1}
              >
                <CloseIcon />
              </Fab>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody style={{ height: "35em", overflowY: "scroll" }}>
          <PdfReport cusip={cusip}></PdfReport>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showModal}
        size="xl"
        className="modalContain"
        style={{ overflowY: "hidden" }}
      >
        <ModalHeader style={{ background: "#286894", color: "#fff" }}>
          <Row>
            <Col md={6}>
              <Label
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  textAlign: "center",
                  float: "left",
                }}
              >
                Report
              </Label>
            </Col>
            <Col md={6}>
              <Fab
                aria-label="add"
                className="modal_close_btn"
                onClick={toggle}
              >
                <CloseIcon />
              </Fab>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody>
          <iframe
            src={"/indpdf/" + pdfUrl}
            style={{ width: "100%", height: "500px" }}
            frameborder="0"
            title="indpdf"
          ></iframe>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Success;
