import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import "./GenericButton.css";
export class GenericButton extends Component {
  constructor(props) {
    super(props);
    let pageId = "";
    let buttonType = "";

    if (props.pageId === "ciHome") {
      pageId = props.pageId;
    }
    if (props.buttonType === "cart") {
      buttonType = props.buttonType;
    }
    this.state = {
      pageId: pageId,
      onClick: props.onClick,
      caption: props.caption,
      buttonType: buttonType,
      type: props.type,
      name: props.name,
      icon: props.icon,
      tooltip: props.tooltip,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    if (this.state.pageId === "ciHome") {
      return (
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          style={{
            background: "#f26d21",
            color: "#fff",
            marginRight: "1rem",
            marginTop:'1.5em',
            height: "97%",
            display: "flex",
            margin: "0 auto",
          }}
          startIcon={this.state.icon ? this.state.icon : ""}
          onClick={this.onClick.bind(this)}
        >
          {this.state.name ? this.state.name : ""}
        </Button>
      );
    } else if (this.state.buttonType === "cart") {
      return (
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          className="cart_btn"
          startIcon={this.state.icon ? this.state.icon : ""}
          onClick={this.onClick.bind(this)}
        >
          {this.state.name ? this.state.name : ""}
        </Button>
      );
    } else {
      return (
        <Button
          type={this.state.type ? this.state.type : ""}
          variant="contained"
          size="small"
          style={{
            background: "#f26d21",
            color: "#fff",
            marginRight: "1rem",
            height: "100%",
            borderBottom: "3px solid #636262",
            display: "flex",
            margin: "0 auto",
          }}
          startIcon={this.state.icon ? this.state.icon : ""}
          onClick={this.onClick.bind(this)}
        >
          {this.state.name ? this.state.name : ""}
        </Button>
      );
    }
  }
}
export default GenericButton;
