import { GET_HISTORY, SET_HISTORY } from "./historyTypes";

export const getHistory = () => {
  return {
    type: GET_HISTORY,
  };
};

export const setHistory = (data) => {
  return {
    type: SET_HISTORY,
    payload: data,
  };
};

export default { getHistory, setHistory };
