import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';
import 'react-tabs/style/react-tabs.css';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import store from '../../redux/store';
import Advertisement from '../ciadvertisement/Advertisement';
import './CompanyReport.css';

export default class CompanyReport extends Component {
  price = store.getState().price.price;
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let price = [];
    if (this.price) {
      price = this.price;
    }
    this.state = {
      showModal: false,
      price: price[0].price,
    };
  }

  getDataInGraphicalForm() {
    this.setState({
      showModal: true,
      radarData: ['200.0', '150.0', '160.0', '100.0'],
    });
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    return (
      <>
        <Container maxWidth="lg" style={{ marginTop: '5em' }}>
          <Row style={{ marginTop: '1em' }}>
            <Row
              style={{ borderBottom: '1px solid #ABB2B9', marginTop: '4em' }}
            >
              <Col md={1}>
                <img
                  src="/home/assets/img/Reports_03.gif"
                  alt="Company Report"
                  height="70%"
                ></img>
              </Col>
              <Col md={11} style={{ margin: '1em 0em 0em -1em' }}>
                <span className="bold_text">Wright</span>{' '}
                <span className="bold_text2">Comprehensive Company Report</span>
                <Row>
                  <Col md={12} className="Cmp_reports">
                    <p>
                      We provide detailed comprehensive reports to help you
                      research the leading publicly traded companies in the
                      world. The company reports are presented in a standardized
                      format that includes extensive explanatory footnotes to
                      help you interpret company results. Each Wright Report
                      contains a broad range of information that includes up to
                      42 sub-reports.
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold', color: '#f26d21' }}>
                        Price: ${this.state.price}
                      </span>{' '}
                      <a
                        href="/indpdf/sample/CompanySampleReport.pdf"
                        target="_blank"
                        className="company_report_sample"
                      >
                        View Sample
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="Cmp_reports"></Col>
            </Row>
            <Row style={{ margin: '15px' }}>
              <span class="bodyTxtBold1">Standard Report Content:</span>
            </Row>
            <Row>
              <Col md={2}>
                <Advertisement advertisementType="verticalAddUnit" />
              </Col>
              <Col md={10}>
                <Row
                  style={{
                    position: 'relative',
                    display: 'flex',
                  }}
                >
                  <Col md={6} style={{ position: 'relative', padding: '1em' }}>
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          Fundamental Reports
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>Company Profile</li>

                          <li style={{ padding: '2px' }}>Summary Analysis</li>
                          <li style={{ padding: '2px' }}>Sales Analysis</li>
                          <li style={{ padding: '2px' }}>Price Analysis</li>
                          <li style={{ padding: '2px' }}>
                            Earnings & Dividends Analysis
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} style={{ position: 'relative', padding: '1em' }}>
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          {' '}
                          Quality Analysis Reports
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>
                            Investment Acceptance
                          </li>
                          <li style={{ padding: '2px' }}>Financial Strength</li>
                          <li style={{ padding: '2px' }}>
                            Profitability & Stability
                          </li>
                          <li style={{ padding: '2px' }}>Corporate Growth</li>
                          <li
                            style={{ padding: '2px' }}
                            className="quality_rating"
                            onClick={(e) =>
                              this.getDataInGraphicalForm('price')
                            }
                          >
                            What is Quality Analysis Rating?
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row
                  style={{
                    position: 'relative',

                    marginBottom: '1em',
                  }}
                >
                  <Col
                    md={6}
                    style={{
                      position: 'relative',
                      padding: '1em',
                      height: '25em',
                    }}
                  >
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          {' '}
                          Comparative Business Analysis Report
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>
                            Comparative Business Analysis
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      position: 'relative',
                      padding: '1em',
                    }}
                  >
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          {' '}
                          Financial Statement Reports
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>
                            Income Statement- Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Income Statement- Common Size
                          </li>
                          <li style={{ padding: '2px' }}>
                            Income Statement- Year- Year % Change
                          </li>
                          <li style={{ padding: '2px' }}>
                            Income Statement- Three-Year Averages
                          </li>
                          <li style={{ padding: '2px' }}>
                            Interim Income Statement- Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Balance Sheet- Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Balance Sheet- Common Size
                          </li>
                          <li style={{ padding: '2px' }}>
                            Balance Sheet- Year- Year % Change
                          </li>
                          <li style={{ padding: '2px' }}>
                            Balance Sheet- Three-Year Averages
                          </li>
                          <li style={{ padding: '2px' }}>
                            Interim Balance Sheet- Actual
                          </li>
                          <li style={{ padding: '2px' }}>Sources of Capital</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row
                  style={{
                    position: 'relative',

                    marginBottom: '1em',
                  }}
                >
                  <Col
                    md={6}
                    style={{
                      position: 'relative',
                      padding: '1em',
                    }}
                  >
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          {' '}
                          Financial Ratio Reports
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>Accounting Ratios</li>
                          <li style={{ padding: '2px' }}>Asset Utilization</li>
                          <li style={{ padding: '2px' }}>
                            Employee Efficiency
                          </li>
                          <li style={{ padding: '2px' }}>
                            Fixed Charges Coverage
                          </li>
                          <li style={{ padding: '2px' }}>Leverage Analysis</li>
                          <li style={{ padding: '2px' }}>Liquidity Analysis</li>
                          <li style={{ padding: '2px' }}>Per-Share Ratios</li>
                          <li style={{ padding: '2px' }}>
                            Profitability Analysis
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      position: 'relative',
                      padding: '1em',
                    }}
                  >
                    <Card
                      style={{
                        height: '100%',
                        boxShadow:
                          'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                      }}
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <CardHeader
                        style={{
                          background: '#2b7d6d',
                          color: '#fff',
                          height: '2em',
                        }}
                      >
                        <b style={{ paddingLeft: '10px' }}>
                          {' '}
                          Flow of Funds Reports
                        </b>
                      </CardHeader>
                      <CardBody>
                        <ul className="company_report_list">
                          <li style={{ padding: '2px' }}>
                            Total Sources and Uses - Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Total Sources and Uses - Common Size
                          </li>
                          <li style={{ padding: '2px' }}>
                            Total Sources and Uses - Year-Year % Change
                          </li>
                          <li style={{ padding: '2px' }}>
                            Total Sources and Uses – Three-Year averages
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: Cash Basis - Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: Cash Basis - Common Size
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: Cash Basis - Year-Year % Change
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: Cash Basis – Three-Year averages
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: FASB 95 - Actual
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: FASB 95 - Common Size
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: FASB 95 - Year-Year % Change
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: FASB 95 – Three-Year averages
                          </li>
                          <li style={{ padding: '2px' }}>
                            Flow of funds: Analyses Ratios
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          size="xl"
          className="modalContain"
        >
          <ModalHeader>
            <Row>
              <Col md={6}>
                <Label
                  style={{
                    fontWeight: '600',
                    color: '#fff',
                    textAlign: 'center',
                    float: 'left',
                  }}
                >
                  Wright Quality Ratings
                </Label>
              </Col>
              <Col md={6}>
                <Fab
                  aria-label="add"
                  className="modal_close_btn"
                  onClick={this.toggle}
                >
                  <CloseIcon />
                </Fab>
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Ratings, in use for over 40 years, measure
                  the overall investment quality of a company. This proprietary
                  rating is based on numerous individual measures of quality,
                  grouped into four principal components: (1) Investment
                  Acceptance (i.e. stock liquidity), (2) Financial Strength, (3)
                  Profitability & Stability, and (4) Growth.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Rating is expressed as three letters and a
                  number. Each letter reflects a composite qualitative
                  measurement of numerous individual standards which may be
                  summarized as “A”; Outstanding, “B”; Excellent, “C”; Good, “D”
                  Fair, “L”; Limited and “N”; Not Rated The number component of
                  the Quality Rating is also a composite of numerous individual
                  standards that measure Corporate Growth (i.e. sales, earnings,
                  assets, etc.). It ranges from a low of 0 to high of 20. (See
                  sample Quality Rating below.)
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The highest quality rating assigned by Wright is AAA20. This
                  rating would be assigned to a company that has a large and
                  broad base of shareholders, and has an outstanding balance
                  sheet and profitability. The company also has experienced
                  superior growth over the past several years.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The Wright Quality Rating assigned to a company also takes
                  into consideration country and industry variations. If there
                  is not sufficient information available, the quality rating
                  will not be assigned or an “N” (not-rated) will be applied for
                  that particular quality criteria.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h5 className="qualityRatingHeading">Sample Quality Rating</h5>
                <img
                  src="/home/assets/img/qalityRatings.png"
                  alt="qalityRatings"
                ></img>
              </Col>
              <Col md={4} style={{ padding: '2em' }}>
                <Radar
                  data={{
                    labels: [
                      'Investment Acceptance',
                      'Financial Strength',
                      ' Profitability & Stability',
                      ' Growth',
                    ],
                    datasets: [
                      {
                        backgroundColor: 'rgba(  44, 92, 202,0.8)',
                        borderColor: '#5dade2',
                        borderJoinStyle: 'round',
                        borderDashOffset: '5',
                        lineTension: '0.5',
                        fontColor: '#fff',

                        fill: true,
                        chart: {
                          height: 350,
                          type: 'radar',
                        },
                        scaleLineWidth: 16,
                        scaleLineColor: '#000',
                        data: this.state.radarData,
                        borderWidth: 3,
                      },
                    ],
                  }}
                  style={{ margin: '3em 0 0 0' }}
                  options={{
                    scale: {
                      gridLines: {
                        circular: true,
                        color: '#7B7D7D ',
                        backdropColor: '#000',
                      },
                      pointLabels: {
                        fontColor: '#2c3e50',
                      },
                      ticks: {
                        beginAtZero: true,
                        backdropColor: 'transparent',
                        fontColor: '#000',
                        display: false,
                      },
                      chartArea: {
                        backgroundColor: 'rgba (247, 247, 246)',
                      },
                    },

                    scaleLineWidth: 16,

                    stroke: {
                      show: true,
                      width: 6,
                      colors: [],
                      dashArray: 0,
                    },
                    pointStyle: 'circle',
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Col>
              <Col md={4} style={{ marginTop: '2em' }}>
                <img
                  src="/home/assets/img/snowflake_Rating.png"
                  alt="snowflake_Rating"
                ></img>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
