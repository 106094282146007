import Fab from "@material-ui/core/Fab";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import Utils from "../../provider/Utils";
import store from "../../redux/store";
import PdfReport from "../pdfReport/PdfReport";
import PtsAlert from "../ptsAlert/PtsAlert";
import GenericSearch from "../search/GenericSearch";
import "./UserProfile.css";
export default class UserProfile extends Component {
  userData = store.getState().userData.userData;
  loginRefList = [];
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    let isUserLogin = false;
    let user = {};
    if (this.userData) {
      user = this.userData;
    }
    this.state = {
      isOpen: false,
      selectedPlan: "",
      modalShow: false,
      isUserLogin: isUserLogin,
      checked: false,
      userData: {},
      company: {},
      userOrderList: [],
      selectedOrder: [],
      anchorEl: undefined,
      open: false,
      render: false,
      user: user,
      companyReportModal: false,
      industryReportModal: false,
      pdfUrl: "",
      cusip: "",
      afterSevenDaysDate: "",
      paidDate: "",
      // status: "EXPIRED",
    };
    this.loginRefList = React.createRef();
  }

  days_between(date1, date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);
    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }
  dateFormat(date) {
    let dateStr = "";
    if (date) {
      dateStr = moment(date).format("MM/DD/YYYY");
    } else {
      dateStr = "";
    }
    return dateStr;
  }
  toTopOfPage() {
    window.scrollTo(0, 0);
  }
  getOrderDetails(order) {
    var future = new Date(order.payment.date); // get today date

    future.setDate(future.getDate() + 7); // add 7 days
    var finalDate =
      future.getFullYear() +
      "-" +
      (future.getMonth() + 1 < 10 ? "0" : "") +
      (future.getMonth() + 1) +
      "-" +
      future.getDate();

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(finalDate);
    const secondDate = new Date();

    var getDaysLeft = function (date1, date2) {
      var daysDiffInMilliSec = Math.abs(new Date(date1) - new Date(date2));
      var daysLeft = daysDiffInMilliSec / (1000 * 60 * 60 * 24);
      return daysLeft;
    };

    var dateDiff = getDaysLeft(
      moment().format("YYYY-MM-DD"),
      order.payment.date
    );
    let selectedOrder = [];
    for (let i = 0; i < this.state.userOrderList.length; i++) {
      if (this.state.userOrderList[i].orderid === order.orderid) {
        selectedOrder = this.state.userOrderList[i];
      }
    }
    this.setState({
      selectedOrder: selectedOrder,
      showModal: true,
      afterSevenDaysDate: finalDate,
      paidDate: order.payment.date,
      dateDiff: dateDiff,
      reportStatus: order.status,
    });
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  companyReportModal = () => {
    this.setState({ companyReportModal: !this.state.companyReportModal });
  };
  industryReportModal = () => {
    this.setState({ industryReportModal: !this.state.industryReportModal });
  };
  componentDidMount() {
    const postObject = { user: { id: this.state.user.id } };
    FetchServerData.callPostService("userms/getProfile", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          let data = output.data;
          let orderList = data.orderList;
          orderList.sort(function compare(a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateB - dateA;
          });
          let userOrderList = [];
          for (let i = 0; i < orderList.length; i++) {
            let orders = [];

            orders = orderList[i].orderItems;

            userOrderList.push(...orders);
          }
          let btnClass = "";
          let cardClass = "";
          let url = "";
          let checked = false;
          if (data.subscription) {
            if (data.subscription.autoPaid === true) {
              checked = true;
            }
            if (data.subscription.plan.id === 2) {
              btnClass = "btn_plan";

              cardClass =
                "plan ultimite aos-init aos-animate plan_card plan_card_bottom";
              url = "/home/assets/img/GlobalProfessionalSample.pdf";
            } else {
              btnClass = "btn_plan1";

              cardClass =
                "plan standard aos-init aos-animate plan_card plan_card_bottom";
              url = "/home/assets/img/GlobalBasicSample.pdf";
            }
            this.setState({
              userOrderList: orderList,
              userData: data.user,
              company: data.user.company,
              render: true,
              selectedPlan: data.subscription.plan,
              subscription: data.subscription,
              planItems: data.subscription.plan.planItems,
              btnClass: btnClass,
              cardClass: cardClass,
              checked: checked,
              url: url,
            });
          } else {
            this.setState({
              userOrderList: orderList,
              userData: data.user,
              company: data.user.company,
              render: true,
              selectedPlan: "",
              subscription: null,
              planItems: [],
              btnClass: btnClass,
              cardClass: cardClass,
              checked: checked,
              url: url,
            });
          }
        }
      })
      .catch((error) => {});
  }
  planCard() {
    let plan = [];

    let feature = [];

    plan.push(
      // <Col md={3}>
      <div
        className={this.state.cardClass}
        style={{
          boxShadow:
            "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
        }}
      >
        <div className="plan-inner1 plan_card plan_card_bottom">
          <div className="entry-title plan_card">
            <h3 className="plan_card" style={{ padding: "7px" }}>
              {this.state.selectedPlan.name}
            </h3>
            {this.state.selectedPlan.name === "Unlimited Monthly" ? (
              <div className="price">
                <span style={{ left: "7px" }}>
                  {" "}
                  {"$" + this.state.selectedPlan.price}
                </span>
              </div>
            ) : (
              <div className="price">
                <span style={{ left: "14px" }}>
                  {" "}
                  {"$" + this.state.selectedPlan.price}
                </span>
              </div>
            )}
          </div>
          <div className="entry-content">{/* <ul>{feature}</ul> */}</div>

          <Row>
            <Col
              md={12}
              style={{
                textAlign: "center",
                color: "#0d6efd",
                textDecoration: "underline",
              }}
            ></Col>
          </Row>
        </div>
      </div>
      // </Col>
    );

    for (let j = 0; j < this.state.planItems.length; j++) {
      feature.push(<li>{this.state.planItems[j].name}</li>);
    }

    return plan;
  }
  handleTandcChange = (e) => {
    this.setState({ checked: e.target.checked });
    if (e.target.checked === true) {
      const postObject = {
        email: this.state.userData.email,
      };

      FetchServerData.callPostService(
        "pg/makeSubscriptionAutocharge",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            PtsAlert.success(output.message);
          }
        })
        .catch((error) => {});
    } else {
      const postObject = {
        email: this.state.userData.email,
      };

      FetchServerData.callPostService(
        "pg/makeSubscriptionManualCharge",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            PtsAlert.success(output.message);
          }
        })
        .catch((error) => {});
    }
  };
  renewSubscription() {
    const postObject = {
      email: this.state.userData.email,
      user: {
        firstName: this.state.userData.firstName,
        lastName: this.state.userData.lastName,
        email: this.state.userData.email,
      },
      orderItems: [
        {
          plan: {
            id: this.state.selectedPlan.id,
          },
        },
      ],
    };
    FetchServerData.callPostService("pg/renewExistingSubscription", postObject)
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }
  cancelSubscription() {
    const postObject = {
      email: this.state.userData.email,
    };

    FetchServerData.callPostService(
      "pg/cancelOnCurrentSubscriptionEnd",
      postObject
    )
      .then((output) => {
        if (output.status === "SUCCESS") {
          PtsAlert.success(output.message);
        }
      })
      .catch((error) => {});
  }

  // monthlyToAnnual() {
  //   const postObject = {
  //     email: this.state.userData.email,
  //     // subscriptionId: this.state.subscription ? this.state.subscription.id : "",
  //   };

  //   FetchServerData.callPostService("pg/upgradeToAnnual", postObject)
  //     .then((output) => {
  //       if (output.status === "SUCCESS") {
  //         PtsAlert.success(output.message);
  //       }
  //     })
  //     .catch((error) => {});
  // }
  monthlyToAnnual() {
    // const postObject = {
    //   email: this.state.userData.email,
    //   // subscriptionId: this.state.subscription ? this.state.subscription.id : "",
    // };

    this.props.history.push({
      pathname: "/payment_method",
      state: {
        userEmail: this.state.userData.email,
      },
    });
    // FetchServerData.callPostService("pg/upgradeToAnnual", postObject)
    //   .then((output) => {
    //     if (output.status === "SUCCESS") {
    //       PtsAlert.success(output.message);
    //       let data = output.data;
    //       let orderList = data.orderList;
    //       orderList.sort(function compare(a, b) {
    //         var dateA = new Date(a.date);
    //         var dateB = new Date(b.date);
    //         return dateB - dateA;
    //       });
    //       let userOrderList = [];
    //       for (let i = 0; i < orderList.length; i++) {
    //         let orders = [];

    //         orders = orderList[i].orderItems;

    //         userOrderList.push(...orders);
    //       }
    //       let btnClass = "";
    //       let cardClass = "";
    //       let url = "";
    //       let checked = false;
    //       if (data.subscription) {
    //         if (data.subscription.autoPaid === true) {
    //           checked = true;
    //         }
    //         if (data.subscription.plan.id === 2) {
    //           btnClass = "btn_plan";

    //           cardClass =
    //             "plan ultimite aos-init aos-animate plan_card plan_card_bottom";
    //           url = "/home/assets/img/GlobalProfessionalSample.pdf";
    //         } else {
    //           btnClass = "btn_plan1";

    //           cardClass =
    //             "plan standard aos-init aos-animate plan_card plan_card_bottom";
    //           url = "/home/assets/img/GlobalBasicSample.pdf";
    //         }
    //         this.setState({
    //           userOrderList: orderList,
    //           userData: data.user,
    //           company: data.user.company,
    //           render: true,
    //           selectedPlan: data.subscription.plan,
    //           subscription: data.subscription,
    //           planItems: data.subscription.plan.planItems,
    //           btnClass: btnClass,
    //           cardClass: cardClass,
    //           checked: checked,
    //           url: url,
    //         });
    //       } else {
    //         this.setState({
    //           userOrderList: orderList,
    //           userData: data.user,
    //           company: data.user.company,
    //           render: true,
    //           selectedPlan: "",
    //           subscription: null,
    //           planItems: [],
    //           btnClass: btnClass,
    //           cardClass: cardClass,
    //           checked: checked,
    //           url: url,
    //         });
    //       }
    //     }
    //   })
    //   .catch((error) => {});
  }
  goToPlan() {
    this.props.history.push({
      pathname: "/plans",
    });
  }
  generatePdfReport(report) {
    this.toTopOfPage();
    this.setState({
      cusip: report.uid,
      companyReportModal: true,
      showModal: false,
    });
  }
  generatePdfReport1(report) {
    this.toTopOfPage();
    this.setState({
      pdfUrl: report.uid,
      industryReportModal: true,
      showModal: false,
    });
  }

  viewReport(orderItem) {
    if (orderItem.type === "Company") {
      if (this.state.dateDiff < 7 && this.state.reportStatus === "PAID") {
        return (
          <>
            <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
              <VisibilityIcon
                style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
                onClick={(e) => this.generatePdfReport(orderItem)}
              />
            </span>
          </>
        );
      } else {
        return <></>;
      }
    } else if (orderItem.type === "Industry") {
      if (this.state.dateDiff < 7 && this.state.reportStatus === "PAID") {
        return (
          <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
            <VisibilityIcon
              style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
              onClick={(e) => this.generatePdfReport1(orderItem)}
            />
          </span>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }
  setOrderDetailInModel() {
    let itemRow1 = [];
    if (this.state.selectedOrder && this.state.selectedOrder.orderItems) {
      let itemRow2 = this.state.selectedOrder.orderItems.map((orderItem, i) => {
        return (
          <TableRow key={i} style={{ border: "0.5px solid lightgray" }}>
            <TableCell style={{ textAlign: "left" }}>{i + 1}</TableCell>
            <TableCell style={{ textAlign: "left" }}>
              {orderItem.description === null
                ? orderItem.plan.name
                : orderItem.description + "-" + orderItem.plan.name}{" "}
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
              ${orderItem.plan.price}
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
              {this.viewReport(orderItem)}
            </TableCell>
          </TableRow>
        );
      });
      itemRow1.push(itemRow2);
      itemRow1.push(
        <TableRow key={"a"} style={{ border: "0.5px solid lightgray" }}>
          <TableCell style={{ textAlign: "right" }}></TableCell>
          <TableCell style={{ textAlign: "right" }}></TableCell>
          <TableCell style={{ textAlign: "right" }}>
            <b>
              Total : {"$"}
              {this.state.selectedOrder ? this.state.selectedOrder.price : ""}
            </b>
          </TableCell>
          <TableCell style={{ textAlign: "right" }}></TableCell>
        </TableRow>
      );
    }
    return (
      <div>
        <TableContainer>
          {this.state.selectedOrder ? (
            <Table size="small">
              <TableRow key={"a"}>
                <TableCell style={{ textAlign: "left" }}>
                  <b style={{ color: "#673ab7" }}>
                    {this.state.selectedOrder.orderid}
                  </b>
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <b style={{ color: "#673ab7" }}>
                    {this.state.selectedOrder.date}
                  </b>
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <b style={{ color: "#673ab7" }}>
                    {this.state.selectedOrder.status}
                  </b>
                </TableCell>
              </TableRow>
            </Table>
          ) : (
            ""
          )}
        </TableContainer>
        <TableContainer
          style={{
            border: "0.5px solid lightgray",
          }}
        >
          <Table
            size="small"
            className="rateTable"
            style={{
              border: "0.5px solid lightgray",
              boxShadow:
                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
            }}
          >
            <TableHead style={{ background: "#2b7d6d", color: "#fff" }}>
              <TableRow>
                <TableCell align="left" style={{ color: "#fff" }}>
                  <b>#</b>
                </TableCell>
                <TableCell style={{ textAlign: "left", color: "#fff" }}>
                  <b>Order Item </b>
                </TableCell>

                <TableCell
                  style={{ textAlign: "right", width: "30%", color: "#fff" }}
                >
                  <b>Price </b>
                </TableCell>
                <TableCell
                  style={{ textAlign: "right", width: "30%", color: "#fff" }}
                >
                  <b>View </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{itemRow1}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  getViewReportBtn(order) {
    let getViewButton = "";
    var getDaysLeft = function (date1, date2) {
      var daysDiffInMilliSec = Math.abs(new Date(date1) - new Date(date2));
      var daysLeft = daysDiffInMilliSec / (1000 * 60 * 60 * 24);
      return daysLeft;
    };

    var dateDiff = getDaysLeft(moment().format("YYYY-MM-DD"), order.date);

    if (dateDiff < 7 && order.status === "PAID") {
      getViewButton = (
        <span style={{ cursor: "pointer", fontSize: "1.5em" }}>
          <VisibilityIcon
            style={{ color: "rgb(242, 109, 33)", cursor: "pointer" }}
            onClick={(e) => this.getOrderDetails(order)}
          />
        </span>
      );
    } else {
      getViewButton = <></>;
    }
    return getViewButton;
  }
  renderMonthlyToAnnualBtn(subscription) {
    if (subscription) {
      if (subscription.plan.id === 1) {
        return (
          <>
            <br></br>
            <Row>
              <Col md={12} style={{ textAlign: "center" }}>
                <Button
                  style={{
                    background: "#f58d51",

                    webkitBorderRadius: "10px",

                    margin: "auto",
                    marginRight: "5px",
                    borderBottom: "2px solid #636262",
                  }}
                  onClick={(e) => this.monthlyToAnnual()}
                >
                  Upgrade to Anual Subscription
                </Button>
              </Col>
            </Row>
          </>
        );
      }
    }
  }
  render() {
    if (this.state.render === false) {
      return (
        <>
          <Row className="breadcrumbs_fix_top_blank">
            <section className="breadcrumbs">
              <div className="container marquee">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>User Profile</h4>
                  </div>
                  <div className="col-lg-6">
                    <GenericSearch history={this.props.history} color="white" />
                  </div>
                </div>
              </div>
            </section>
          </Row>
          <div style={{ minHeight: "100em" }}></div>
        </>
      );
    }

    if (this.state.render === false) {
      return (
        <>
          <section className="breadcrumbs">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4>User Profile</h4>
                </div>
                <div className="col-lg-6">
                  <GenericSearch history={this.props.history} color="white" />
                </div>
              </div>
            </div>
          </section>
          <div style={{ minHeight: "110vh" }}></div>
        </>
      );
    } else {
      let featureList = [];
      for (let i = 0; i < this.state.planItems.length; i++) {
        featureList.push(<li>{this.state.planItems[i].name}</li>);
      }
      return (
        <>
          <Row style={{ marginTop: "5em" }}>
            <div
              className="container userProfile pts_mobile_view"
              style={{ minHeight: "90vh" }}
            >
              <div className="row gutters-sm">
                <Row style={{ marginBottom: "2em" }}>
                  <Col md={6}>
                    <Card
                      style={{
                        height: "100%",
                        boxShadow:
                          "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                      }}
                    >
                      <CardHeader
                        style={{ background: "#2b7d6d", color: "#fff" }}
                      >
                        Personal Details
                      </CardHeader>
                      <CardBody>
                        <Row
                          style={{
                            margin: 0,
                            padding: "3px",
                            borderBottom: "0.5px solid #D5D8DC",
                          }}
                        >
                          <Col md={3} style={{ fontWeight: "500" }}>
                            Name
                          </Col>
                          <Col md={9} className="userRow">
                            {this.state.userData.firstName
                              ? this.state.userData.firstName
                              : ""}{" "}
                            {this.state.userData.lastName
                              ? this.state.userData.lastName
                              : ""}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            margin: 0,
                            padding: "3px",
                            borderBottom: "0.5px solid #D5D8DC",
                          }}
                        >
                          <Col md={3} style={{ fontWeight: "500" }}>
                            Email
                          </Col>
                          <Col md={9} className="userRow">
                            {this.state.userData.email
                              ? this.state.userData.email
                              : ""}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            margin: 0,
                            padding: "3px",
                            borderBottom: "0.5px solid #D5D8DC",
                          }}
                        >
                          <Col md={3} style={{ fontWeight: "500" }}>
                            Phone
                          </Col>
                          <Col md={9} className="userRow">
                            {this.state.userData.phone === "null" ||
                            this.state.userData.phone === null
                              ? " "
                              : this.state.userData.phone}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            margin: 0,
                            padding: "3px",
                          }}
                        >
                          <Col md={3} style={{ fontWeight: "500" }}>
                            Address
                          </Col>
                          <Col md={9} className="userRow">
                            {Utils.getAddressAsText(
                              this.state.userData.address
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  {this.state.company === null ||
                  this.state.company === undefined ? (
                    <Col md={6}></Col>
                  ) : (
                    <Col md={6}>
                      <Card
                        style={{
                          height: "100%",
                          boxShadow:
                            "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                        }}
                      >
                        <CardHeader
                          style={{ background: "#2b7d6d", color: "#fff" }}
                        >
                          Company Details
                        </CardHeader>
                        <CardBody>
                          <Row
                            style={{
                              margin: 0,
                              padding: "3px",
                              borderBottom: "0.5px solid #D5D8DC",
                            }}
                          >
                            <Col md={3} style={{ fontWeight: "500" }}>
                              Name
                            </Col>
                            <Col md={9} className="userRow">
                              {this.state.company
                                ? this.state.company.name
                                : ""}
                            </Col>
                          </Row>
                          <Row
                            style={{
                              margin: 0,
                              padding: "3px",
                              borderBottom: "0.5px solid #D5D8DC",
                            }}
                          >
                            <Col md={3} style={{ fontWeight: "500" }}>
                              Phone
                            </Col>
                            <Col md={9} className="userRow">
                              {this.state.company
                                ? this.state.company.phone
                                : ""}
                            </Col>
                          </Row>
                          <Row
                            style={{
                              margin: 0,
                              padding: "3px",
                            }}
                          >
                            <Col md={3} style={{ fontWeight: "500" }}>
                              Address
                            </Col>
                            <Col md={9} className="userRow">
                              {this.state.company
                                ? Utils.getAddressAsText(
                                    this.state.company.address
                                  )
                                : ""}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
                {!this.state.subscription &&
                this.state.user.isadmin === true ? (
                  <></>
                ) : !this.state.subscription &&
                  this.state.user.isadmin === null ? (
                  <></>
                ) : (
                  <>
                    {this.state.subscription.source === "apple" ? (
                      <Row style={{ marginBottom: "2em" }}>
                        <Col md={12}>
                          <Card
                            style={{
                              height: "100%",
                              boxShadow:
                                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                            }}
                          >
                            <CardHeader
                              style={{ background: "#2b7d6d", color: "#fff" }}
                            >
                              Plan and Subscription
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md={6} style={{ padding: "1em" }}>
                                  {this.planCard()}
                                </Col>
                                <Col md={6} style={{ padding: "1em" }}>
                                  <div style={{ padding: "1em" }}>
                                    <Row md={12}>
                                      <Col
                                        md={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <b>Start Date:</b>
                                      </Col>
                                      <Col md={6} style={{ textAlign: "left" }}>
                                        {this.dateFormat(
                                          this.state.subscription.startDat
                                        )}
                                      </Col>
                                    </Row>
                                    <Row md={12}>
                                      <Col
                                        md={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <b>End Date:</b>
                                      </Col>
                                      <Col md={6} style={{ textAlign: "left" }}>
                                        {this.dateFormat(
                                          this.state.subscription.endDate
                                        )}
                                      </Col>
                                    </Row>
                                    {this.state.subscription.isExpired ===
                                    true ? (
                                      <Row>
                                        <Col
                                          md={12}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Button
                                            style={{
                                              background: "#f58d51",

                                              webkitBorderRadius: "10px",

                                              margin: "auto",
                                              marginRight: "5px",
                                              borderBottom: "2px solid #636262",
                                            }}
                                            onClick={(e) => this.goToPlan()}
                                          >
                                            Renew Subscription
                                          </Button>
                                        </Col>
                                      </Row>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <Row style={{ marginBottom: "2em" }}>
                        <Col md={12}>
                          <Card
                            style={{
                              height: "100%",
                              boxShadow:
                                "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                            }}
                          >
                            <CardHeader
                              style={{ background: "#2b7d6d", color: "#fff" }}
                            >
                              Plan and Subscription
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md={6} style={{ padding: "1em" }}>
                                  {this.planCard()}
                                </Col>
                                <Col md={6} style={{ padding: "1em" }}>
                                  <div style={{ padding: "1em" }}>
                                    <Row md={12}>
                                      <Col
                                        md={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <b>Start Date:</b>
                                      </Col>
                                      <Col md={6} style={{ textAlign: "left" }}>
                                        {this.dateFormat(
                                          this.state.subscription.startDate
                                        )}
                                      </Col>
                                    </Row>
                                    <Row md={12}>
                                      <Col
                                        md={6}
                                        style={{ textAlign: "right" }}
                                      >
                                        <b>End Date:</b>
                                      </Col>
                                      <Col md={6} style={{ textAlign: "left" }}>
                                        {this.dateFormat(
                                          this.state.subscription.endDate
                                        )}
                                      </Col>
                                    </Row>
                                    {this.state.subscription.isExpired ===
                                    true ? (
                                      <></>
                                    ) : (
                                      <Row md={12}>
                                        <Col
                                          md={12}
                                          style={{ textAlign: "center" }}
                                        >
                                          <b>Manual Charge</b>
                                          <Switch
                                            checked={this.state.checked}
                                            onChange={this.handleTandcChange}
                                            color="primary"
                                            name="checkedB"
                                          />
                                          <b>Auto Charge</b>
                                        </Col>
                                      </Row>
                                    )}
                                    {this.state.subscription.isExpired ===
                                    true ? (
                                      <Row>
                                        <Col
                                          md={12}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Button
                                            style={{
                                              background: "#f58d51",

                                              webkitBorderRadius: "10px",

                                              margin: "auto",
                                              marginRight: "5px",
                                              borderBottom: "2px solid #636262",
                                            }}
                                            onClick={(e) => this.goToPlan()}
                                          >
                                            Renew Subscription
                                          </Button>
                                        </Col>
                                      </Row>
                                    ) : (
                                      <Row>
                                        <Col
                                          md={12}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Button
                                            style={{
                                              background: "#f58d51",

                                              webkitBorderRadius: "10px",

                                              margin: "auto",
                                              marginRight: "5px",
                                              borderBottom: "2px solid #636262",
                                            }}
                                            onClick={(e) =>
                                              this.cancelSubscription()
                                            }
                                          >
                                            Cancel Subscription
                                          </Button>
                                        </Col>
                                      </Row>
                                    )}

                                    {this.renderMonthlyToAnnualBtn(
                                      this.state.subscription
                                    )}
                                    {/* {this.state.subscription.plan.id === 1 ? (
                                      <>
                                        <br></br>
                                        <Row>
                                          <Col
                                            md={12}
                                            style={{ textAlign: "center" }}
                                          >
                                            <Button
                                              style={{
                                                background: "#f58d51",

                                                webkitBorderRadius: "10px",

                                                margin: "auto",
                                                marginRight: "5px",
                                                borderBottom:
                                                  "2px solid #636262",
                                              }}
                                              onClick={(e) =>
                                                this.monthlyToAnnual()
                                              }
                                            >
                                              To annual subscription
                                            </Button>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      ""
                                    )} */}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {!this.state.userOrderList ? (
                  <></>
                ) : (
                  <Row style={{ marginBottom: "2em" }}>
                    <span
                      style={{
                        fontWeight: "600",
                        color: "rgb(242, 109, 33)",
                        textAlign: "left",
                        fontStyle: "italic",
                      }}
                    >
                      The Purchased reports are available for 7 days.
                    </span>
                    <Col md={12}>
                      <Card
                        style={{
                          height: "100%",
                          boxShadow:
                            "rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px",
                        }}
                      >
                        <CardHeader
                          style={{ background: "#2b7d6d", color: "#fff" }}
                        >
                          Order History
                        </CardHeader>
                        <CardBody>
                          <TableContainer>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              className="rateTable"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">
                                    <b>#</b>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left" }}>
                                    <b>Order </b>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "right" }}>
                                    <b>Date </b>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "right" }}>
                                    <b>Price </b>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "right" }}>
                                    <b>Status </b>
                                  </TableCell>
                                  <TableCell style={{ textAlign: "right" }}>
                                    <b>View </b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.userOrderList.map(
                                  (order, index) => (
                                    <TableRow key={order.orderid}>
                                      <TableCell style={{ textAlign: "left" }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell style={{ textAlign: "left" }}>
                                        <span
                                          onClick={(e) =>
                                            this.getOrderDetails(order)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {order.orderid}
                                        </span>
                                      </TableCell>
                                      <TableCell style={{ textAlign: "right" }}>
                                        {moment(order.date).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell style={{ textAlign: "right" }}>
                                        {"$" + order.price}
                                      </TableCell>
                                      <TableCell style={{ textAlign: "right" }}>
                                        {order.status}
                                      </TableCell>

                                      <TableCell style={{ textAlign: "right" }}>
                                        {this.getViewReportBtn(order)}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </div>
            </div>

            <Modal isOpen={this.state.showModal} toggle={this.toggle} size="md">
              <ModalHeader style={{ background: "#182a44", color: "#fff" }}>
                <Row>
                  <Col md={6}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Order Details
                    </Label>
                  </Col>
                  <Col md={6}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>{this.setOrderDetailInModel()}</Col>
                </Row>
              </ModalBody>
            </Modal>
          </Row>

          <Modal
            isOpen={this.state.companyReportModal}
            size="xl"
            className="modalContain"
            style={{ overflowY: "hidden" }}
          >
            <ModalHeader style={{ background: "#286894", color: "#fff" }}>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    Report
                  </Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.companyReportModal}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>

            <ModalBody style={{ height: "35em", overflowY: "scroll" }}>
              <PdfReport cusip={this.state.cusip}></PdfReport>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.industryReportModal}
            size="xl"
            className="modalContain"
            style={{ overflowY: "hidden" }}
          >
            <ModalHeader style={{ background: "#286894", color: "#fff" }}>
              <Row>
                <Col md={6}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    Report
                  </Label>
                </Col>
                <Col md={6}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.industryReportModal}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>

            <ModalBody>
              <iframe
                src={"/indpdf/" + this.state.pdfUrl}
                title="pdf"
                style={{ width: "100%", height: "500px" }}
                frameborder="0"
              ></iframe>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}
