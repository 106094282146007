import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FetchServerData from "../../provider/FetchServerData";
import GraphIconButton from "../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../genricGraph/SingleAxisGenericGraph";
import "./PerFasb.css";
export default class PerFasb extends Component {
  country = "";
  currency = "";
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      type: props.type,
      data: props.data,
      flowOfFundsData: [],
      render: false,
      modalHeader: "",
      actual: false,
      common: false,
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  componentDidMount() {
    this.currency = this.state.data.currency;
    this.country = this.state.data.country;
    if (this.state.type === "Per FASB Actual") {
      let LabelList = [];

      let IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable =
        [];
      let FundsFromForOtherOperatingActivities = [];
      let DecreaseIncreaseInReceivables = [];
      let DecreaseIncreaseInInventories = [];
      let IncreaseDecreaseInAccountsPayable = [];
      let IncreaseDecreaseInIncomeTaxesPayable = [];
      let IncreaseDecreaseInOtherAccruals = [];
      let DecreaseIncreaseinOtherAssetsLiabilities = [];
      let LongTermBorrowings = [];
      let NetProceedsfromSaleorIssuanceofCommonAndPreferred = [];
      let ProceedsFromStockOptions = [];
      let OtherProceedsFromSaleRrIssuanceOfStock = [];
      let DisposalOfFixedAssets = [];
      let DecreaseInInvestments = [];
      let IncreaseDecreaseInShortTermBorrowings = [];
      let EffectOfExchangeRateOnCash = [];
      let IncreaseDecreaseInCash = [];
      let OtherSourcesFinancing = [];
      let OtherSourcesInvesting = [];
      let CashDividendsPaidTotal = [];
      let CapitalExpendituresAdditionstoFixedAssets = [];
      let AdditionsToOtherAssets = [];
      let ReductionInLongTermDebt = [];
      let comPfdPurchasedRetiredConvertedAndRedeemed = [];
      let NetAssetsFromAcquisitions = [];
      let IncreaseInInvestments = [];
      let OtherUsesInvesting = [];
      let OtherUsesFinancing = [];
      let NetIncomeStartingLine = [];
      let AmortizationOfIntangibleAssets = [];
      let DeferredIncomeTaxes = [];
      let InvestmentTaxCredit = [];
      let TotalOtherCashFlow = [];
      let CommonDividendsCash = [];
      let PreferredDividendsCash = [];
      let DeferredIncomeTaxesAndInvestmentTaxCredit = [];
      let NetSales = [];
      let DepreciationAndDepletion = [];
      let FundsFromOperations = [];
      let ExtraordinaryItems = [];
      let DepreciationDepletionAndAmortization = [];
      let NetCashFlowOperatingActivities = [];
      let OtherUsesSourcesInvesting = [];
      let NetCashFlowInvesting = [];
      let otherSourcesUsesFinancing = [];
      let NetCashFlowFinancing = [];
      let flowOfFundsData = this.state.data.flowOfFundFASBActualList;

      for (let i = 0; i < flowOfFundsData.length; i++) {
        LabelList.push(flowOfFundsData[i].year);
        NetCashFlowOperatingActivities.push(
          flowOfFundsData[i].netCashFlowOperatingActivities
        );
        OtherUsesSourcesInvesting.push(
          flowOfFundsData[i].otherUsesSourcesInvesting
        );
        NetCashFlowInvesting.push(flowOfFundsData[i].netCashFlowInvesting);
        otherSourcesUsesFinancing.push(
          flowOfFundsData[i].otherSourcesUsesFinancing
        );
        NetCashFlowFinancing.push(flowOfFundsData[i].netCashFlowFinancing);
        FundsFromForOtherOperatingActivities.push(
          flowOfFundsData[i].fundsfromforOtherOperatingActivities
        );
        DecreaseIncreaseInReceivables.push(
          flowOfFundsData[i].decreaseIncreaseinReceivables
        );
        DecreaseIncreaseInInventories.push(
          flowOfFundsData[i].decreaseIncreaseinInventories
        );
        IncreaseDecreaseInAccountsPayable.push(
          flowOfFundsData[i].increaseDecreaseinAccountsPayable
        );
        IncreaseDecreaseInIncomeTaxesPayable.push(
          flowOfFundsData[i].increaseDecreaseinIncomeTaxesPayable
        );
        IncreaseDecreaseInOtherAccruals.push(
          flowOfFundsData[i].increaseDecreaseinOtherAccruals
        );
        DecreaseIncreaseinOtherAssetsLiabilities.push(
          flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
        );
        LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
        NetProceedsfromSaleorIssuanceofCommonAndPreferred.push(
          flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred
        );
        ProceedsFromStockOptions.push(
          flowOfFundsData[i].proceedsfromStockOptions
        );
        OtherProceedsFromSaleRrIssuanceOfStock.push(
          flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
        );
        DisposalOfFixedAssets.push(flowOfFundsData[i].disposalofFixedAssets);
        DecreaseInInvestments.push(flowOfFundsData[i].decreaseinInvestments);
        IncreaseDecreaseInShortTermBorrowings.push(
          flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
        );
        EffectOfExchangeRateOnCash.push(
          flowOfFundsData[i].effectofExchangeRateonCash
        );
        IncreaseDecreaseInCash.push(flowOfFundsData[i].increaseDecreaseinCash);
        OtherSourcesFinancing.push(flowOfFundsData[i].otherSourcesFinancing);
        OtherSourcesInvesting.push(flowOfFundsData[i].otherSourcesInvesting);
        CashDividendsPaidTotal.push(flowOfFundsData[i].cashDividendsPaidTotal);
        CapitalExpendituresAdditionstoFixedAssets.push(
          flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
        );
        AdditionsToOtherAssets.push(flowOfFundsData[i].additionstoOtherAssets);
        ReductionInLongTermDebt.push(
          flowOfFundsData[i].reductioninLongTermDebt
        );
        comPfdPurchasedRetiredConvertedAndRedeemed.push(
          flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
        );
        NetAssetsFromAcquisitions.push(
          flowOfFundsData[i].netAssetsfromAcquisitions
        );
        IncreaseInInvestments.push(flowOfFundsData[i].increaseinInvestments);
        OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
        OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
        NetIncomeStartingLine.push(flowOfFundsData[i].netIncomeStartingLine);
        AmortizationOfIntangibleAssets.push(
          flowOfFundsData[i].amortizationofIntangibleAssets
        );
        DeferredIncomeTaxes.push(flowOfFundsData[i].deferredIncomeTaxes);
        InvestmentTaxCredit.push(flowOfFundsData[i].investmentTaxCredit);
        TotalOtherCashFlow.push(flowOfFundsData[i].totalOtherCashFlow);
        CommonDividendsCash.push(flowOfFundsData[i].commonDividendsCash);
        PreferredDividendsCash.push(flowOfFundsData[i].preferredDividendsCash);
        DeferredIncomeTaxesAndInvestmentTaxCredit.push(
          flowOfFundsData[i].deferredIncomeTaxesAndInvestmentTaxCredit
        );
        NetSales.push(flowOfFundsData[i].netSales);
        DepreciationAndDepletion.push(
          flowOfFundsData[i].depreciationandDepletion
        );
        FundsFromOperations.push(flowOfFundsData[i].fundsfromOperations);
        ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
        DepreciationDepletionAndAmortization.push(
          flowOfFundsData[i].depreciationDepletionAndAmortization
        );

        flowOfFundsData[i].fundsfromforOtherOperatingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].fundsfromforOtherOperatingActivities
          );
        flowOfFundsData[i].decreaseIncreaseinReceivables =
          this.convertToMillionaire(
            flowOfFundsData[i].decreaseIncreaseinReceivables
          );
        flowOfFundsData[i].increaseDecreaseinAccountsPayable =
          this.convertToMillionaire(
            flowOfFundsData[i].increaseDecreaseinAccountsPayable
          );
        flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
          this.convertToMillionaire(
            flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
          );
        flowOfFundsData[i].longTermBorrowings = this.convertToMillionaire(
          flowOfFundsData[i].longTermBorrowings
        );
        flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred =
          this.convertToMillionaire(
            flowOfFundsData[i].netProceedsfromSaleorIssuanceofCommonAndPreferred
          );
        flowOfFundsData[i].proceedsfromStockOptions = this.convertToMillionaire(
          flowOfFundsData[i].proceedsfromStockOptions
        );
        flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
          this.convertToMillionaire(
            flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
          );
        flowOfFundsData[i].disposalofFixedAssets = this.convertToMillionaire(
          flowOfFundsData[i].disposalofFixedAssets
        );
        flowOfFundsData[i].decreaseinInvestments = this.convertToMillionaire(
          flowOfFundsData[i].decreaseinInvestments
        );
        flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
          this.convertToMillionaire(
            flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
          );
        flowOfFundsData[i].effectofExchangeRateonCash =
          this.convertToMillionaire(
            flowOfFundsData[i].effectofExchangeRateonCash
          );
        flowOfFundsData[i].increaseDecreaseinCash = this.convertToMillionaire(
          flowOfFundsData[i].increaseDecreaseinCash
        );
        flowOfFundsData[i].otherSourcesFinancing = this.convertToMillionaire(
          flowOfFundsData[i].otherSourcesFinancing
        );
        flowOfFundsData[i].otherSourcesInvesting = this.convertToMillionaire(
          flowOfFundsData[i].otherSourcesInvesting
        );
        flowOfFundsData[i].cashDividendsPaidTotal = this.convertToMillionaire(
          flowOfFundsData[i].cashDividendsPaidTotal
        );
        flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
          );
        flowOfFundsData[i].reductioninLongTermDebt = this.convertToMillionaire(
          flowOfFundsData[i].reductioninLongTermDebt
        );
        flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
          this.convertToMillionaire(
            flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
          );
        flowOfFundsData[i].netAssetsfromAcquisitions =
          this.convertToMillionaire(
            flowOfFundsData[i].netAssetsfromAcquisitions
          );
        flowOfFundsData[i].increaseinInvestments = this.convertToMillionaire(
          flowOfFundsData[i].increaseinInvestments
        );
        flowOfFundsData[i].otherUsesInvesting = this.convertToMillionaire(
          flowOfFundsData[i].otherUsesInvesting
        );
        flowOfFundsData[i].otherUsesFinancing = this.convertToMillionaire(
          flowOfFundsData[i].otherUsesFinancing
        );
        flowOfFundsData[i].netCashFlowOperatingActivities =
          this.convertToMillionaire(
            flowOfFundsData[i].netCashFlowOperatingActivities
          );
        flowOfFundsData[i].otherUsesSourcesInvesting =
          this.convertToMillionaire(
            flowOfFundsData[i].otherUsesSourcesInvesting
          );
        flowOfFundsData[i].netCashFlowInvesting = this.convertToMillionaire(
          flowOfFundsData[i].netCashFlowInvesting
        );
        flowOfFundsData[i].otherSourcesUsesFinancing =
          this.convertToMillionaire(
            flowOfFundsData[i].otherSourcesUsesFinancing
          );
        flowOfFundsData[i].netCashFlowFinancing = this.convertToMillionaire(
          flowOfFundsData[i].netCashFlowFinancing
        );
        flowOfFundsData[i].netIncomeStartingLine = this.convertToMillionaire(
          flowOfFundsData[i].netIncomeStartingLine
        );
        flowOfFundsData[i].amortizationofIntangibleAssets =
          this.convertToMillionaire(
            flowOfFundsData[i].amortizationofIntangibleAssets
          );
        flowOfFundsData[i].totalOtherCashFlow = this.convertToMillionaire(
          flowOfFundsData[i].totalOtherCashFlow
        );
        flowOfFundsData[i].commonDividendsCash = this.convertToMillionaire(
          flowOfFundsData[i].commonDividendsCash
        );
        flowOfFundsData[i].preferredDividendsCash = this.convertToMillionaire(
          flowOfFundsData[i].preferredDividendsCash
        );

        flowOfFundsData[i].netSales = this.convertToMillionaire(
          flowOfFundsData[i].netSales
        );
        flowOfFundsData[i].depreciationandDepletion = this.convertToMillionaire(
          flowOfFundsData[i].depreciationandDepletion
        );
        flowOfFundsData[i].fundsfromOperations = this.convertToMillionaire(
          flowOfFundsData[i].fundsfromOperations
        );
        flowOfFundsData[i].extraordinaryItems = this.convertToMillionaire(
          flowOfFundsData[i].extraordinaryItems
        );
        flowOfFundsData[i].depreciationDepletionAndAmortization =
          this.convertToMillionaire(
            flowOfFundsData[i].depreciationDepletionAndAmortization
          );
      }

      this.setState({
        render: true,
        actual: true,
        common: false,
        modalHeader: " FASB 95 -Actual ",
        NetCashFlowOperatingActivities: NetCashFlowOperatingActivities,
        OtherUsesSourcesInvesting: OtherUsesSourcesInvesting,
        NetCashFlowInvesting: NetCashFlowInvesting,
        otherSourcesUsesFinancing: otherSourcesUsesFinancing,
        NetCashFlowFinancing: NetCashFlowFinancing,
        flowOfFundsData: flowOfFundsData,
        LabelList: LabelList,
        FundsFromForOtherOperatingActivities:
          FundsFromForOtherOperatingActivities,
        DecreaseIncreaseInReceivables: DecreaseIncreaseInReceivables,
        DecreaseIncreaseInInventories: DecreaseIncreaseInInventories,
        IncreaseDecreaseInAccountsPayable: IncreaseDecreaseInAccountsPayable,
        IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable:
          IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable,
        IncreaseDecreaseInOtherAccruals: IncreaseDecreaseInOtherAccruals,
        DecreaseIncreaseinOtherAssetsLiabilities:
          DecreaseIncreaseinOtherAssetsLiabilities,
        LongTermBorrowings: LongTermBorrowings,
        NetProceedsfromSaleorIssuanceofCommonAndPreferred:
          NetProceedsfromSaleorIssuanceofCommonAndPreferred,
        ProceedsFromStockOptions: ProceedsFromStockOptions,
        OtherProceedsFromSaleRrIssuanceOfStock:
          OtherProceedsFromSaleRrIssuanceOfStock,
        DisposalOfFixedAssets: DisposalOfFixedAssets,
        DecreaseInInvestments: DecreaseInInvestments,
        IncreaseDecreaseInShortTermBorrowings:
          IncreaseDecreaseInShortTermBorrowings,
        EffectOfExchangeRateOnCash: EffectOfExchangeRateOnCash,
        IncreaseDecreaseInCash: IncreaseDecreaseInCash,
        OtherSourcesFinancing: OtherSourcesFinancing,
        OtherSourcesInvesting: OtherSourcesInvesting,
        CashDividendsPaidTotal: CashDividendsPaidTotal,
        CapitalExpendituresAdditionstoFixedAssets:
          CapitalExpendituresAdditionstoFixedAssets,
        AdditionsToOtherAssets: AdditionsToOtherAssets,
        ReductionInLongTermDebt: ReductionInLongTermDebt,
        comPfdPurchasedRetiredConvertedAndRedeemed:
          comPfdPurchasedRetiredConvertedAndRedeemed,
        NetAssetsFromAcquisitions: NetAssetsFromAcquisitions,
        IncreaseInInvestments: IncreaseInInvestments,
        OtherUsesInvesting: OtherUsesInvesting,
        OtherUsesFinancing: OtherUsesFinancing,
        NetIncomeStartingLine: NetIncomeStartingLine,
        AmortizationOfIntangibleAssets: AmortizationOfIntangibleAssets,
        DeferredIncomeTaxes: DeferredIncomeTaxes,
        InvestmentTaxCredit: InvestmentTaxCredit,
        TotalOtherCashFlow: TotalOtherCashFlow,
        CommonDividendsCash: CommonDividendsCash,
        PreferredDividendsCash: PreferredDividendsCash,
        DeferredIncomeTaxesAndInvestmentTaxCredit:
          DeferredIncomeTaxesAndInvestmentTaxCredit,
        NetSales: NetSales,
        DepreciationAndDepletion: DepreciationAndDepletion,
        FundsFromOperations: FundsFromOperations,
        ExtraordinaryItems: ExtraordinaryItems,
        DepreciationDepletionAndAmortization:
          DepreciationDepletionAndAmortization,
      });
    } else if (this.state.type === "Per FASB Common") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService("flowOfFunds/fasbCommon", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;

            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.flowOfFundFASBCommonList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];

              let IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable =
                [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseInReceivables = [];
              let DecreaseIncreaseInInventories = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let IncreaseDecreaseInIncomeTaxesPayable = [];
              let IncreaseDecreaseInOtherAccruals = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsfromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleRrIssuanceOfStock = [];
              let DisposalOfFixedAssets = [];
              let DecreaseInInvestments = [];
              let IncreaseDecreaseInShortTermBorrowings = [];
              let EffectOfExchangeRateOnCash = [];
              let IncreaseDecreaseInCash = [];
              let OtherSourcesFinancing = [];
              let OtherSourcesInvesting = [];
              let CashDividendsPaidTotal = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let AdditionsToOtherAssets = [];
              let ReductionInLongTermDebt = [];
              let comPfdPurchasedRetiredConvertedAndRedeemed = [];
              let NetAssetsFromAcquisitions = [];
              let IncreaseInInvestments = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let NetIncomeStartingLine = [];
              let AmortizationOfIntangibleAssets = [];
              let DeferredIncomeTaxes = [];
              let InvestmentTaxCredit = [];
              let TotalOtherCashFlow = [];
              let CommonDividendsCash = [];
              let PreferredDividendsCash = [];
              let DeferredIncomeTaxesAndInvestmentTaxCredit = [];
              let NetSales = [];
              let DepreciationAndDepletion = [];
              let FundsFromOperations = [];
              let ExtraordinaryItems = [];
              let DepreciationDepletionAndAmortization = [];
              let NetCashFlowOperatingActivities = [];
              let OtherUsesSourcesInvesting = [];
              let NetCashFlowInvesting = [];
              let otherSourcesUsesFinancing = [];
              let NetCashFlowFinancing = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                LabelList.push(flowOfFundsData[i].year);
                NetCashFlowOperatingActivities.push(
                  flowOfFundsData[i].netCashFlowOperatingActivities
                );
                OtherUsesSourcesInvesting.push(
                  flowOfFundsData[i].otherUsesSourcesInvesting
                );
                NetCashFlowInvesting.push(
                  flowOfFundsData[i].netCashFlowInvesting
                );
                otherSourcesUsesFinancing.push(
                  flowOfFundsData[i].otherSourcesUsesFinancing
                );
                NetCashFlowFinancing.push(
                  flowOfFundsData[i].netCashFlowFinancing
                );
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseInReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                DecreaseIncreaseInInventories.push(
                  flowOfFundsData[i].decreaseIncreaseinInventories
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                IncreaseDecreaseInIncomeTaxesPayable.push(
                  flowOfFundsData[i].increaseDecreaseinIncomeTaxesPayable
                );
                IncreaseDecreaseInOtherAccruals.push(
                  flowOfFundsData[i].increaseDecreaseinOtherAccruals
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsfromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleRrIssuanceOfStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalOfFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseInInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseInShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                EffectOfExchangeRateOnCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseInCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                OtherSourcesInvesting.push(
                  flowOfFundsData[i].otherSourcesInvesting
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                AdditionsToOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                ReductionInLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                comPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                NetAssetsFromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                IncreaseInInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                NetIncomeStartingLine.push(
                  flowOfFundsData[i].netIncomeStartingLine
                );
                AmortizationOfIntangibleAssets.push(
                  flowOfFundsData[i].amortizationofIntangibleAssets
                );
                DeferredIncomeTaxes.push(
                  flowOfFundsData[i].deferredIncomeTaxes
                );
                InvestmentTaxCredit.push(
                  flowOfFundsData[i].investmentTaxCredit
                );
                TotalOtherCashFlow.push(flowOfFundsData[i].totalOtherCashFlow);
                CommonDividendsCash.push(
                  flowOfFundsData[i].commonDividendsCash
                );
                PreferredDividendsCash.push(
                  flowOfFundsData[i].preferredDividendsCash
                );
                DeferredIncomeTaxesAndInvestmentTaxCredit.push(
                  flowOfFundsData[i].deferredIncomeTaxesAndInvestmentTaxCredit
                );
                NetSales.push(flowOfFundsData[i].netSales);
                DepreciationAndDepletion.push(
                  flowOfFundsData[i].depreciationandDepletion
                );
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                DepreciationDepletionAndAmortization.push(
                  flowOfFundsData[i].depreciationDepletionAndAmortization
                );

                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowOperatingActivities
                  );
                flowOfFundsData[i].otherUsesSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesSourcesInvesting
                  );
                flowOfFundsData[i].netCashFlowInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowInvesting
                  );
                flowOfFundsData[i].otherSourcesUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowFinancing
                  );
                flowOfFundsData[i].netIncomeStartingLine =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netIncomeStartingLine
                  );
                flowOfFundsData[i].amortizationofIntangibleAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].amortizationofIntangibleAssets
                  );
                flowOfFundsData[i].totalOtherCashFlow =
                  this.convertToMillionaire(
                    flowOfFundsData[i].totalOtherCashFlow
                  );
                flowOfFundsData[i].commonDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].commonDividendsCash
                  );
                flowOfFundsData[i].preferredDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividendsCash
                  );

                flowOfFundsData[i].netSales = this.convertToMillionaire(
                  flowOfFundsData[i].netSales
                );
                flowOfFundsData[i].depreciationandDepletion =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationandDepletion
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].depreciationDepletionAndAmortization =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationDepletionAndAmortization
                  );
              }

              this.setState({
                flowOfFundsData: flowOfFundsData,
                render: true,
                actual: false,
                common: true,
                modalHeader: " FASB 95 -Common Size",
                NetCashFlowOperatingActivities: NetCashFlowOperatingActivities,
                OtherUsesSourcesInvesting: OtherUsesSourcesInvesting,
                NetCashFlowInvesting: NetCashFlowInvesting,
                otherSourcesUsesFinancing: otherSourcesUsesFinancing,
                NetCashFlowFinancing: NetCashFlowFinancing,
                flowOfFundsData: flowOfFundsData,
                LabelList: LabelList,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseInReceivables: DecreaseIncreaseInReceivables,
                DecreaseIncreaseInInventories: DecreaseIncreaseInInventories,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable:
                  IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable,
                IncreaseDecreaseInOtherAccruals:
                  IncreaseDecreaseInOtherAccruals,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsfromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsfromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleRrIssuanceOfStock:
                  OtherProceedsFromSaleRrIssuanceOfStock,
                DisposalOfFixedAssets: DisposalOfFixedAssets,
                DecreaseInInvestments: DecreaseInInvestments,
                IncreaseDecreaseInShortTermBorrowings:
                  IncreaseDecreaseInShortTermBorrowings,
                EffectOfExchangeRateOnCash: EffectOfExchangeRateOnCash,
                IncreaseDecreaseInCash: IncreaseDecreaseInCash,
                OtherSourcesFinancing: OtherSourcesFinancing,
                OtherSourcesInvesting: OtherSourcesInvesting,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                AdditionsToOtherAssets: AdditionsToOtherAssets,
                ReductionInLongTermDebt: ReductionInLongTermDebt,
                comPfdPurchasedRetiredConvertedAndRedeemed:
                  comPfdPurchasedRetiredConvertedAndRedeemed,
                NetAssetsFromAcquisitions: NetAssetsFromAcquisitions,
                IncreaseInInvestments: IncreaseInInvestments,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                NetIncomeStartingLine: NetIncomeStartingLine,
                AmortizationOfIntangibleAssets: AmortizationOfIntangibleAssets,
                DeferredIncomeTaxes: DeferredIncomeTaxes,
                InvestmentTaxCredit: InvestmentTaxCredit,
                TotalOtherCashFlow: TotalOtherCashFlow,
                CommonDividendsCash: CommonDividendsCash,
                PreferredDividendsCash: PreferredDividendsCash,
                DeferredIncomeTaxesAndInvestmentTaxCredit:
                  DeferredIncomeTaxesAndInvestmentTaxCredit,
                NetSales: NetSales,
                DepreciationAndDepletion: DepreciationAndDepletion,
                FundsFromOperations: FundsFromOperations,
                ExtraordinaryItems: ExtraordinaryItems,
                DepreciationDepletionAndAmortization:
                  DepreciationDepletionAndAmortization,
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Per FASB Year-Year") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService("flowOfFunds/fasbYoY", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;

            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.flowOfFundFASBYrtoYrList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];

              let IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable =
                [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseInReceivables = [];
              let DecreaseIncreaseInInventories = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let IncreaseDecreaseInIncomeTaxesPayable = [];
              let IncreaseDecreaseInOtherAccruals = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsfromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleRrIssuanceOfStock = [];
              let DisposalOfFixedAssets = [];
              let DecreaseInInvestments = [];
              let IncreaseDecreaseInShortTermBorrowings = [];
              let EffectOfExchangeRateOnCash = [];
              let IncreaseDecreaseInCash = [];
              let OtherSourcesFinancing = [];
              let OtherSourcesInvesting = [];
              let CashDividendsPaidTotal = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let AdditionsToOtherAssets = [];
              let ReductionInLongTermDebt = [];
              let comPfdPurchasedRetiredConvertedAndRedeemed = [];
              let NetAssetsFromAcquisitions = [];
              let IncreaseInInvestments = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let NetIncomeStartingLine = [];
              let AmortizationOfIntangibleAssets = [];
              let DeferredIncomeTaxes = [];
              let InvestmentTaxCredit = [];
              let TotalOtherCashFlow = [];
              let CommonDividendsCash = [];
              let PreferredDividendsCash = [];
              let DeferredIncomeTaxesAndInvestmentTaxCredit = [];
              let NetSales = [];
              let DepreciationAndDepletion = [];
              let FundsFromOperations = [];
              let ExtraordinaryItems = [];
              let DepreciationDepletionAndAmortization = [];
              let NetCashFlowOperatingActivities = [];
              let OtherUsesSourcesInvesting = [];
              let NetCashFlowInvesting = [];
              let otherSourcesUsesFinancing = [];
              let NetCashFlowFinancing = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                LabelList.push(flowOfFundsData[i].year);
                NetCashFlowOperatingActivities.push(
                  flowOfFundsData[i].netCashFlowOperatingActivities
                );
                OtherUsesSourcesInvesting.push(
                  flowOfFundsData[i].otherUsesSourcesInvesting
                );
                NetCashFlowInvesting.push(
                  flowOfFundsData[i].netCashFlowInvesting
                );
                otherSourcesUsesFinancing.push(
                  flowOfFundsData[i].otherSourcesUsesFinancing
                );
                NetCashFlowFinancing.push(
                  flowOfFundsData[i].netCashFlowFinancing
                );
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseInReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                DecreaseIncreaseInInventories.push(
                  flowOfFundsData[i].decreaseIncreaseinInventories
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                IncreaseDecreaseInIncomeTaxesPayable.push(
                  flowOfFundsData[i].increaseDecreaseinIncomeTaxesPayable
                );
                IncreaseDecreaseInOtherAccruals.push(
                  flowOfFundsData[i].increaseDecreaseinOtherAccruals
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsfromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleRrIssuanceOfStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalOfFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseInInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseInShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                EffectOfExchangeRateOnCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseInCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                OtherSourcesInvesting.push(
                  flowOfFundsData[i].otherSourcesInvesting
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                AdditionsToOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                ReductionInLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                comPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                NetAssetsFromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                IncreaseInInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                NetIncomeStartingLine.push(
                  flowOfFundsData[i].netIncomeStartingLine
                );
                AmortizationOfIntangibleAssets.push(
                  flowOfFundsData[i].amortizationofIntangibleAssets
                );
                DeferredIncomeTaxes.push(
                  flowOfFundsData[i].deferredIncomeTaxes
                );
                InvestmentTaxCredit.push(
                  flowOfFundsData[i].investmentTaxCredit
                );
                TotalOtherCashFlow.push(flowOfFundsData[i].totalOtherCashFlow);
                CommonDividendsCash.push(
                  flowOfFundsData[i].commonDividendsCash
                );
                PreferredDividendsCash.push(
                  flowOfFundsData[i].preferredDividendsCash
                );
                DeferredIncomeTaxesAndInvestmentTaxCredit.push(
                  flowOfFundsData[i].deferredIncomeTaxesAndInvestmentTaxCredit
                );
                NetSales.push(flowOfFundsData[i].netSales);
                DepreciationAndDepletion.push(
                  flowOfFundsData[i].depreciationandDepletion
                );
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                DepreciationDepletionAndAmortization.push(
                  flowOfFundsData[i].depreciationDepletionAndAmortization
                );

                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowOperatingActivities
                  );
                flowOfFundsData[i].otherUsesSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesSourcesInvesting
                  );
                flowOfFundsData[i].netCashFlowInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowInvesting
                  );
                flowOfFundsData[i].otherSourcesUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowFinancing
                  );
                flowOfFundsData[i].netIncomeStartingLine =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netIncomeStartingLine
                  );
                flowOfFundsData[i].amortizationofIntangibleAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].amortizationofIntangibleAssets
                  );
                flowOfFundsData[i].totalOtherCashFlow =
                  this.convertToMillionaire(
                    flowOfFundsData[i].totalOtherCashFlow
                  );
                flowOfFundsData[i].commonDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].commonDividendsCash
                  );
                flowOfFundsData[i].preferredDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividendsCash
                  );

                flowOfFundsData[i].netSales = this.convertToMillionaire(
                  flowOfFundsData[i].netSales
                );
                flowOfFundsData[i].depreciationandDepletion =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationandDepletion
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].depreciationDepletionAndAmortization =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationDepletionAndAmortization
                  );
              }

              this.setState({
                flowOfFundsData: flowOfFundsData,
                render: true,
                actual: true,
                common: false,
                modalHeader: " FASB 95 -Yr-yr % change",
                NetCashFlowOperatingActivities: NetCashFlowOperatingActivities,
                OtherUsesSourcesInvesting: OtherUsesSourcesInvesting,
                NetCashFlowInvesting: NetCashFlowInvesting,
                otherSourcesUsesFinancing: otherSourcesUsesFinancing,
                NetCashFlowFinancing: NetCashFlowFinancing,
                flowOfFundsData: flowOfFundsData,
                LabelList: LabelList,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseInReceivables: DecreaseIncreaseInReceivables,
                DecreaseIncreaseInInventories: DecreaseIncreaseInInventories,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable:
                  IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable,
                IncreaseDecreaseInOtherAccruals:
                  IncreaseDecreaseInOtherAccruals,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsfromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsfromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleRrIssuanceOfStock:
                  OtherProceedsFromSaleRrIssuanceOfStock,
                DisposalOfFixedAssets: DisposalOfFixedAssets,
                DecreaseInInvestments: DecreaseInInvestments,
                IncreaseDecreaseInShortTermBorrowings:
                  IncreaseDecreaseInShortTermBorrowings,
                EffectOfExchangeRateOnCash: EffectOfExchangeRateOnCash,
                IncreaseDecreaseInCash: IncreaseDecreaseInCash,
                OtherSourcesFinancing: OtherSourcesFinancing,
                OtherSourcesInvesting: OtherSourcesInvesting,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                AdditionsToOtherAssets: AdditionsToOtherAssets,
                ReductionInLongTermDebt: ReductionInLongTermDebt,
                comPfdPurchasedRetiredConvertedAndRedeemed:
                  comPfdPurchasedRetiredConvertedAndRedeemed,
                NetAssetsFromAcquisitions: NetAssetsFromAcquisitions,
                IncreaseInInvestments: IncreaseInInvestments,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                NetIncomeStartingLine: NetIncomeStartingLine,
                AmortizationOfIntangibleAssets: AmortizationOfIntangibleAssets,
                DeferredIncomeTaxes: DeferredIncomeTaxes,
                InvestmentTaxCredit: InvestmentTaxCredit,
                TotalOtherCashFlow: TotalOtherCashFlow,
                CommonDividendsCash: CommonDividendsCash,
                PreferredDividendsCash: PreferredDividendsCash,
                DeferredIncomeTaxesAndInvestmentTaxCredit:
                  DeferredIncomeTaxesAndInvestmentTaxCredit,
                NetSales: NetSales,
                DepreciationAndDepletion: DepreciationAndDepletion,
                FundsFromOperations: FundsFromOperations,
                ExtraordinaryItems: ExtraordinaryItems,
                DepreciationDepletionAndAmortization:
                  DepreciationDepletionAndAmortization,
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else if (this.state.type === "Per FASB 3-Year") {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService(
        "flowOfFunds/fasb3YearAverage",
        postObject
      )
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;

            if (data) {
              this.currency = data.currency;
              this.country = data.country;
              let flowOfFundsData = data.flowOfFundFASB3YearAvgList;
              if (flowOfFundsData.length > 0) {
                flowOfFundsData.reverse();
              }
              let LabelList = [];

              let IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable =
                [];
              let FundsFromForOtherOperatingActivities = [];
              let DecreaseIncreaseInReceivables = [];
              let DecreaseIncreaseInInventories = [];
              let IncreaseDecreaseInAccountsPayable = [];
              let IncreaseDecreaseInIncomeTaxesPayable = [];
              let IncreaseDecreaseInOtherAccruals = [];
              let DecreaseIncreaseinOtherAssetsLiabilities = [];
              let LongTermBorrowings = [];
              let NetProceedsfromSaleorIssuanceofCommonAndPreferred = [];
              let ProceedsFromStockOptions = [];
              let OtherProceedsFromSaleRrIssuanceOfStock = [];
              let DisposalOfFixedAssets = [];
              let DecreaseInInvestments = [];
              let IncreaseDecreaseInShortTermBorrowings = [];
              let EffectOfExchangeRateOnCash = [];
              let IncreaseDecreaseInCash = [];
              let OtherSourcesFinancing = [];
              let OtherSourcesInvesting = [];
              let CashDividendsPaidTotal = [];
              let CapitalExpendituresAdditionstoFixedAssets = [];
              let AdditionsToOtherAssets = [];
              let ReductionInLongTermDebt = [];
              let comPfdPurchasedRetiredConvertedAndRedeemed = [];
              let NetAssetsFromAcquisitions = [];
              let IncreaseInInvestments = [];
              let OtherUsesInvesting = [];
              let OtherUsesFinancing = [];
              let NetIncomeStartingLine = [];
              let AmortizationOfIntangibleAssets = [];
              let DeferredIncomeTaxes = [];
              let InvestmentTaxCredit = [];
              let TotalOtherCashFlow = [];
              let CommonDividendsCash = [];
              let PreferredDividendsCash = [];
              let DeferredIncomeTaxesAndInvestmentTaxCredit = [];
              let NetSales = [];
              let DepreciationAndDepletion = [];
              let FundsFromOperations = [];
              let ExtraordinaryItems = [];
              let DepreciationDepletionAndAmortization = [];
              let NetCashFlowOperatingActivities = [];
              let OtherUsesSourcesInvesting = [];
              let NetCashFlowInvesting = [];
              let otherSourcesUsesFinancing = [];
              let NetCashFlowFinancing = [];
              for (let i = 0; i < flowOfFundsData.length; i++) {
                LabelList.push(flowOfFundsData[i].year);
                NetCashFlowOperatingActivities.push(
                  flowOfFundsData[i].netCashFlowOperatingActivities
                );
                OtherUsesSourcesInvesting.push(
                  flowOfFundsData[i].otherUsesSourcesInvesting
                );
                NetCashFlowInvesting.push(
                  flowOfFundsData[i].netCashFlowInvesting
                );
                otherSourcesUsesFinancing.push(
                  flowOfFundsData[i].otherSourcesUsesFinancing
                );
                NetCashFlowFinancing.push(
                  flowOfFundsData[i].netCashFlowFinancing
                );
                FundsFromForOtherOperatingActivities.push(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
                DecreaseIncreaseInReceivables.push(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
                DecreaseIncreaseInInventories.push(
                  flowOfFundsData[i].decreaseIncreaseinInventories
                );
                IncreaseDecreaseInAccountsPayable.push(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
                IncreaseDecreaseInIncomeTaxesPayable.push(
                  flowOfFundsData[i].increaseDecreaseinIncomeTaxesPayable
                );
                IncreaseDecreaseInOtherAccruals.push(
                  flowOfFundsData[i].increaseDecreaseinOtherAccruals
                );
                DecreaseIncreaseinOtherAssetsLiabilities.push(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
                LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
                NetProceedsfromSaleorIssuanceofCommonAndPreferred.push(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                ProceedsFromStockOptions.push(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
                OtherProceedsFromSaleRrIssuanceOfStock.push(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
                DisposalOfFixedAssets.push(
                  flowOfFundsData[i].disposalofFixedAssets
                );
                DecreaseInInvestments.push(
                  flowOfFundsData[i].decreaseinInvestments
                );
                IncreaseDecreaseInShortTermBorrowings.push(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
                EffectOfExchangeRateOnCash.push(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
                IncreaseDecreaseInCash.push(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
                OtherSourcesFinancing.push(
                  flowOfFundsData[i].otherSourcesFinancing
                );
                OtherSourcesInvesting.push(
                  flowOfFundsData[i].otherSourcesInvesting
                );
                CashDividendsPaidTotal.push(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
                CapitalExpendituresAdditionstoFixedAssets.push(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
                AdditionsToOtherAssets.push(
                  flowOfFundsData[i].additionstoOtherAssets
                );
                ReductionInLongTermDebt.push(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
                comPfdPurchasedRetiredConvertedAndRedeemed.push(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
                NetAssetsFromAcquisitions.push(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
                IncreaseInInvestments.push(
                  flowOfFundsData[i].increaseinInvestments
                );
                OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
                OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
                NetIncomeStartingLine.push(
                  flowOfFundsData[i].netIncomeStartingLine
                );
                AmortizationOfIntangibleAssets.push(
                  flowOfFundsData[i].amortizationofIntangibleAssets
                );
                DeferredIncomeTaxes.push(
                  flowOfFundsData[i].deferredIncomeTaxes
                );
                InvestmentTaxCredit.push(
                  flowOfFundsData[i].investmentTaxCredit
                );
                TotalOtherCashFlow.push(flowOfFundsData[i].totalOtherCashFlow);
                CommonDividendsCash.push(
                  flowOfFundsData[i].commonDividendsCash
                );
                PreferredDividendsCash.push(
                  flowOfFundsData[i].preferredDividendsCash
                );
                DeferredIncomeTaxesAndInvestmentTaxCredit.push(
                  flowOfFundsData[i].deferredIncomeTaxesAndInvestmentTaxCredit
                );
                NetSales.push(flowOfFundsData[i].netSales);
                DepreciationAndDepletion.push(
                  flowOfFundsData[i].depreciationandDepletion
                );
                FundsFromOperations.push(
                  flowOfFundsData[i].fundsfromOperations
                );
                ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
                DepreciationDepletionAndAmortization.push(
                  flowOfFundsData[i].depreciationDepletionAndAmortization
                );

                flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromforOtherOperatingActivities
                  );
                flowOfFundsData[i].decreaseIncreaseinReceivables =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinReceivables
                  );
                flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinAccountsPayable
                  );
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                  );
                flowOfFundsData[i].longTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].longTermBorrowings
                  );
                flowOfFundsData[
                  i
                ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                  flowOfFundsData[i]
                    .netProceedsfromSaleorIssuanceofCommonAndPreferred
                );
                flowOfFundsData[i].proceedsfromStockOptions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].proceedsfromStockOptions
                  );
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                  );
                flowOfFundsData[i].disposalofFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].disposalofFixedAssets
                  );
                flowOfFundsData[i].decreaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].decreaseinInvestments
                  );
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                  );
                flowOfFundsData[i].effectofExchangeRateonCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].effectofExchangeRateonCash
                  );
                flowOfFundsData[i].increaseDecreaseinCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseDecreaseinCash
                  );
                flowOfFundsData[i].otherSourcesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesFinancing
                  );
                flowOfFundsData[i].otherSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesInvesting
                  );
                flowOfFundsData[i].cashDividendsPaidTotal =
                  this.convertToMillionaire(
                    flowOfFundsData[i].cashDividendsPaidTotal
                  );
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                  );
                flowOfFundsData[i].reductioninLongTermDebt =
                  this.convertToMillionaire(
                    flowOfFundsData[i].reductioninLongTermDebt
                  );
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                  this.convertToMillionaire(
                    flowOfFundsData[i]
                      .comPfdPurchasedRetiredConvertedAndRedeemed
                  );
                flowOfFundsData[i].netAssetsfromAcquisitions =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netAssetsfromAcquisitions
                  );
                flowOfFundsData[i].increaseinInvestments =
                  this.convertToMillionaire(
                    flowOfFundsData[i].increaseinInvestments
                  );
                flowOfFundsData[i].otherUsesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesInvesting
                  );
                flowOfFundsData[i].otherUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowOperatingActivities =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowOperatingActivities
                  );
                flowOfFundsData[i].otherUsesSourcesInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherUsesSourcesInvesting
                  );
                flowOfFundsData[i].netCashFlowInvesting =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowInvesting
                  );
                flowOfFundsData[i].otherSourcesUsesFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].otherSourcesUsesFinancing
                  );
                flowOfFundsData[i].netCashFlowFinancing =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netCashFlowFinancing
                  );
                flowOfFundsData[i].netIncomeStartingLine =
                  this.convertToMillionaire(
                    flowOfFundsData[i].netIncomeStartingLine
                  );
                flowOfFundsData[i].amortizationofIntangibleAssets =
                  this.convertToMillionaire(
                    flowOfFundsData[i].amortizationofIntangibleAssets
                  );
                flowOfFundsData[i].totalOtherCashFlow =
                  this.convertToMillionaire(
                    flowOfFundsData[i].totalOtherCashFlow
                  );
                flowOfFundsData[i].commonDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].commonDividendsCash
                  );
                flowOfFundsData[i].preferredDividendsCash =
                  this.convertToMillionaire(
                    flowOfFundsData[i].preferredDividendsCash
                  );

                flowOfFundsData[i].netSales = this.convertToMillionaire(
                  flowOfFundsData[i].netSales
                );
                flowOfFundsData[i].depreciationandDepletion =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationandDepletion
                  );
                flowOfFundsData[i].fundsfromOperations =
                  this.convertToMillionaire(
                    flowOfFundsData[i].fundsfromOperations
                  );
                flowOfFundsData[i].extraordinaryItems =
                  this.convertToMillionaire(
                    flowOfFundsData[i].extraordinaryItems
                  );
                flowOfFundsData[i].depreciationDepletionAndAmortization =
                  this.convertToMillionaire(
                    flowOfFundsData[i].depreciationDepletionAndAmortization
                  );
              }

              this.setState({
                flowOfFundsData: flowOfFundsData,
                render: true,
                actual: true,
                common: false,
                modalHeader: "FASB 95 -3 yr Averages",
                NetCashFlowOperatingActivities: NetCashFlowOperatingActivities,
                OtherUsesSourcesInvesting: OtherUsesSourcesInvesting,
                NetCashFlowInvesting: NetCashFlowInvesting,
                otherSourcesUsesFinancing: otherSourcesUsesFinancing,
                NetCashFlowFinancing: NetCashFlowFinancing,
                flowOfFundsData: flowOfFundsData,
                LabelList: LabelList,
                FundsFromForOtherOperatingActivities:
                  FundsFromForOtherOperatingActivities,
                DecreaseIncreaseInReceivables: DecreaseIncreaseInReceivables,
                DecreaseIncreaseInInventories: DecreaseIncreaseInInventories,
                IncreaseDecreaseInAccountsPayable:
                  IncreaseDecreaseInAccountsPayable,
                IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable:
                  IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable,
                IncreaseDecreaseInOtherAccruals:
                  IncreaseDecreaseInOtherAccruals,
                DecreaseIncreaseinOtherAssetsLiabilities:
                  DecreaseIncreaseinOtherAssetsLiabilities,
                LongTermBorrowings: LongTermBorrowings,
                NetProceedsfromSaleorIssuanceofCommonAndPreferred:
                  NetProceedsfromSaleorIssuanceofCommonAndPreferred,
                ProceedsFromStockOptions: ProceedsFromStockOptions,
                OtherProceedsFromSaleRrIssuanceOfStock:
                  OtherProceedsFromSaleRrIssuanceOfStock,
                DisposalOfFixedAssets: DisposalOfFixedAssets,
                DecreaseInInvestments: DecreaseInInvestments,
                IncreaseDecreaseInShortTermBorrowings:
                  IncreaseDecreaseInShortTermBorrowings,
                EffectOfExchangeRateOnCash: EffectOfExchangeRateOnCash,
                IncreaseDecreaseInCash: IncreaseDecreaseInCash,
                OtherSourcesFinancing: OtherSourcesFinancing,
                OtherSourcesInvesting: OtherSourcesInvesting,
                CashDividendsPaidTotal: CashDividendsPaidTotal,
                CapitalExpendituresAdditionstoFixedAssets:
                  CapitalExpendituresAdditionstoFixedAssets,
                AdditionsToOtherAssets: AdditionsToOtherAssets,
                ReductionInLongTermDebt: ReductionInLongTermDebt,
                comPfdPurchasedRetiredConvertedAndRedeemed:
                  comPfdPurchasedRetiredConvertedAndRedeemed,
                NetAssetsFromAcquisitions: NetAssetsFromAcquisitions,
                IncreaseInInvestments: IncreaseInInvestments,
                OtherUsesInvesting: OtherUsesInvesting,
                OtherUsesFinancing: OtherUsesFinancing,
                NetIncomeStartingLine: NetIncomeStartingLine,
                AmortizationOfIntangibleAssets: AmortizationOfIntangibleAssets,
                DeferredIncomeTaxes: DeferredIncomeTaxes,
                InvestmentTaxCredit: InvestmentTaxCredit,
                TotalOtherCashFlow: TotalOtherCashFlow,
                CommonDividendsCash: CommonDividendsCash,
                PreferredDividendsCash: PreferredDividendsCash,
                DeferredIncomeTaxesAndInvestmentTaxCredit:
                  DeferredIncomeTaxesAndInvestmentTaxCredit,
                NetSales: NetSales,
                DepreciationAndDepletion: DepreciationAndDepletion,
                FundsFromOperations: FundsFromOperations,
                ExtraordinaryItems: ExtraordinaryItems,
                DepreciationDepletionAndAmortization:
                  DepreciationDepletionAndAmortization,
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = { cusip: this.state.data };

      FetchServerData.callPostService("flowOfFunds/fasbActual", postObject)
        .then((output) => {
          if (output.status === "SUCCESS") {
            let data = output.data;
            this.currency = data.currency;
            this.country = data.country;
            let LabelList = [];

            let IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable =
              [];
            let FundsFromForOtherOperatingActivities = [];
            let DecreaseIncreaseInReceivables = [];
            let DecreaseIncreaseInInventories = [];
            let IncreaseDecreaseInAccountsPayable = [];
            let IncreaseDecreaseInIncomeTaxesPayable = [];
            let IncreaseDecreaseInOtherAccruals = [];
            let DecreaseIncreaseinOtherAssetsLiabilities = [];
            let LongTermBorrowings = [];
            let NetProceedsfromSaleorIssuanceofCommonAndPreferred = [];
            let ProceedsFromStockOptions = [];
            let OtherProceedsFromSaleRrIssuanceOfStock = [];
            let DisposalOfFixedAssets = [];
            let DecreaseInInvestments = [];
            let IncreaseDecreaseInShortTermBorrowings = [];
            let EffectOfExchangeRateOnCash = [];
            let IncreaseDecreaseInCash = [];
            let OtherSourcesFinancing = [];
            let OtherSourcesInvesting = [];
            let CashDividendsPaidTotal = [];
            let CapitalExpendituresAdditionstoFixedAssets = [];
            let AdditionsToOtherAssets = [];
            let ReductionInLongTermDebt = [];
            let comPfdPurchasedRetiredConvertedAndRedeemed = [];
            let NetAssetsFromAcquisitions = [];
            let IncreaseInInvestments = [];
            let OtherUsesInvesting = [];
            let OtherUsesFinancing = [];
            let NetIncomeStartingLine = [];
            let AmortizationOfIntangibleAssets = [];
            let DeferredIncomeTaxes = [];
            let InvestmentTaxCredit = [];
            let TotalOtherCashFlow = [];
            let CommonDividendsCash = [];
            let PreferredDividendsCash = [];
            let DeferredIncomeTaxesAndInvestmentTaxCredit = [];
            let NetSales = [];
            let DepreciationAndDepletion = [];
            let FundsFromOperations = [];
            let ExtraordinaryItems = [];
            let DepreciationDepletionAndAmortization = [];
            let NetCashFlowOperatingActivities = [];
            let OtherUsesSourcesInvesting = [];
            let NetCashFlowInvesting = [];
            let otherSourcesUsesFinancing = [];
            let NetCashFlowFinancing = [];
            let flowOfFundsData = data.flowOfFundFASBActualList;
            if (flowOfFundsData.length > 0) {
              flowOfFundsData.reverse();
            }
            for (let i = 0; i < flowOfFundsData.length; i++) {
              LabelList.push(flowOfFundsData[i].year);
              NetCashFlowOperatingActivities.push(
                flowOfFundsData[i].netCashFlowOperatingActivities
              );
              OtherUsesSourcesInvesting.push(
                flowOfFundsData[i].otherUsesSourcesInvesting
              );
              NetCashFlowInvesting.push(
                flowOfFundsData[i].netCashFlowInvesting
              );
              otherSourcesUsesFinancing.push(
                flowOfFundsData[i].otherSourcesUsesFinancing
              );
              NetCashFlowFinancing.push(
                flowOfFundsData[i].netCashFlowFinancing
              );
              FundsFromForOtherOperatingActivities.push(
                flowOfFundsData[i].fundsfromforOtherOperatingActivities
              );
              DecreaseIncreaseInReceivables.push(
                flowOfFundsData[i].decreaseIncreaseinReceivables
              );
              DecreaseIncreaseInInventories.push(
                flowOfFundsData[i].decreaseIncreaseinInventories
              );
              IncreaseDecreaseInAccountsPayable.push(
                flowOfFundsData[i].increaseDecreaseinAccountsPayable
              );
              IncreaseDecreaseInIncomeTaxesPayable.push(
                flowOfFundsData[i].increaseDecreaseinIncomeTaxesPayable
              );
              IncreaseDecreaseInOtherAccruals.push(
                flowOfFundsData[i].increaseDecreaseinOtherAccruals
              );
              DecreaseIncreaseinOtherAssetsLiabilities.push(
                flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
              );
              LongTermBorrowings.push(flowOfFundsData[i].longTermBorrowings);
              NetProceedsfromSaleorIssuanceofCommonAndPreferred.push(
                flowOfFundsData[i]
                  .netProceedsfromSaleorIssuanceofCommonAndPreferred
              );
              ProceedsFromStockOptions.push(
                flowOfFundsData[i].proceedsfromStockOptions
              );
              OtherProceedsFromSaleRrIssuanceOfStock.push(
                flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
              );
              DisposalOfFixedAssets.push(
                flowOfFundsData[i].disposalofFixedAssets
              );
              DecreaseInInvestments.push(
                flowOfFundsData[i].decreaseinInvestments
              );
              IncreaseDecreaseInShortTermBorrowings.push(
                flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
              );
              EffectOfExchangeRateOnCash.push(
                flowOfFundsData[i].effectofExchangeRateonCash
              );
              IncreaseDecreaseInCash.push(
                flowOfFundsData[i].increaseDecreaseinCash
              );
              OtherSourcesFinancing.push(
                flowOfFundsData[i].otherSourcesFinancing
              );
              OtherSourcesInvesting.push(
                flowOfFundsData[i].otherSourcesInvesting
              );
              CashDividendsPaidTotal.push(
                flowOfFundsData[i].cashDividendsPaidTotal
              );
              CapitalExpendituresAdditionstoFixedAssets.push(
                flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
              );
              AdditionsToOtherAssets.push(
                flowOfFundsData[i].additionstoOtherAssets
              );
              ReductionInLongTermDebt.push(
                flowOfFundsData[i].reductioninLongTermDebt
              );
              comPfdPurchasedRetiredConvertedAndRedeemed.push(
                flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
              );
              NetAssetsFromAcquisitions.push(
                flowOfFundsData[i].netAssetsfromAcquisitions
              );
              IncreaseInInvestments.push(
                flowOfFundsData[i].increaseinInvestments
              );
              OtherUsesInvesting.push(flowOfFundsData[i].otherUsesInvesting);
              OtherUsesFinancing.push(flowOfFundsData[i].otherUsesFinancing);
              NetIncomeStartingLine.push(
                flowOfFundsData[i].netIncomeStartingLine
              );
              AmortizationOfIntangibleAssets.push(
                flowOfFundsData[i].amortizationofIntangibleAssets
              );
              DeferredIncomeTaxes.push(flowOfFundsData[i].deferredIncomeTaxes);
              InvestmentTaxCredit.push(flowOfFundsData[i].investmentTaxCredit);
              TotalOtherCashFlow.push(flowOfFundsData[i].totalOtherCashFlow);
              CommonDividendsCash.push(flowOfFundsData[i].commonDividendsCash);
              PreferredDividendsCash.push(
                flowOfFundsData[i].preferredDividendsCash
              );
              DeferredIncomeTaxesAndInvestmentTaxCredit.push(
                flowOfFundsData[i].deferredIncomeTaxesAndInvestmentTaxCredit
              );
              NetSales.push(flowOfFundsData[i].netSales);
              DepreciationAndDepletion.push(
                flowOfFundsData[i].depreciationandDepletion
              );
              FundsFromOperations.push(flowOfFundsData[i].fundsfromOperations);
              ExtraordinaryItems.push(flowOfFundsData[i].extraordinaryItems);
              DepreciationDepletionAndAmortization.push(
                flowOfFundsData[i].depreciationDepletionAndAmortization
              );

              flowOfFundsData[i].fundsfromforOtherOperatingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromforOtherOperatingActivities
                );
              flowOfFundsData[i].decreaseIncreaseinReceivables =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseIncreaseinReceivables
                );
              flowOfFundsData[i].increaseDecreaseinAccountsPayable =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinAccountsPayable
                );
              flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseIncreaseinOtherAssetsLiabilities
                );
              flowOfFundsData[i].longTermBorrowings = this.convertToMillionaire(
                flowOfFundsData[i].longTermBorrowings
              );
              flowOfFundsData[
                i
              ].netProceedsfromSaleorIssuanceofCommonAndPreferred = this.convertToMillionaire(
                flowOfFundsData[i]
                  .netProceedsfromSaleorIssuanceofCommonAndPreferred
              );
              flowOfFundsData[i].proceedsfromStockOptions =
                this.convertToMillionaire(
                  flowOfFundsData[i].proceedsfromStockOptions
                );
              flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherProceedsfromSaleorIssuanceofStock
                );
              flowOfFundsData[i].disposalofFixedAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].disposalofFixedAssets
                );
              flowOfFundsData[i].decreaseinInvestments =
                this.convertToMillionaire(
                  flowOfFundsData[i].decreaseinInvestments
                );
              flowOfFundsData[i].increaseDecreaseinShortTermBorrowings =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinShortTermBorrowings
                );
              flowOfFundsData[i].effectofExchangeRateonCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].effectofExchangeRateonCash
                );
              flowOfFundsData[i].increaseDecreaseinCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseDecreaseinCash
                );
              flowOfFundsData[i].otherSourcesFinancing =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherSourcesFinancing
                );
              flowOfFundsData[i].otherSourcesInvesting =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherSourcesInvesting
                );
              flowOfFundsData[i].cashDividendsPaidTotal =
                this.convertToMillionaire(
                  flowOfFundsData[i].cashDividendsPaidTotal
                );
              flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].capitalExpendituresAdditionstoFixedAssets
                );
              flowOfFundsData[i].reductioninLongTermDebt =
                this.convertToMillionaire(
                  flowOfFundsData[i].reductioninLongTermDebt
                );
              flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed =
                this.convertToMillionaire(
                  flowOfFundsData[i].comPfdPurchasedRetiredConvertedAndRedeemed
                );
              flowOfFundsData[i].netAssetsfromAcquisitions =
                this.convertToMillionaire(
                  flowOfFundsData[i].netAssetsfromAcquisitions
                );
              flowOfFundsData[i].increaseinInvestments =
                this.convertToMillionaire(
                  flowOfFundsData[i].increaseinInvestments
                );
              flowOfFundsData[i].otherUsesInvesting = this.convertToMillionaire(
                flowOfFundsData[i].otherUsesInvesting
              );
              flowOfFundsData[i].otherUsesFinancing = this.convertToMillionaire(
                flowOfFundsData[i].otherUsesFinancing
              );
              flowOfFundsData[i].netCashFlowOperatingActivities =
                this.convertToMillionaire(
                  flowOfFundsData[i].netCashFlowOperatingActivities
                );
              flowOfFundsData[i].otherUsesSourcesInvesting =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherUsesSourcesInvesting
                );
              flowOfFundsData[i].netCashFlowInvesting =
                this.convertToMillionaire(
                  flowOfFundsData[i].netCashFlowInvesting
                );
              flowOfFundsData[i].otherSourcesUsesFinancing =
                this.convertToMillionaire(
                  flowOfFundsData[i].otherSourcesUsesFinancing
                );
              flowOfFundsData[i].netCashFlowFinancing =
                this.convertToMillionaire(
                  flowOfFundsData[i].netCashFlowFinancing
                );
              flowOfFundsData[i].netIncomeStartingLine =
                this.convertToMillionaire(
                  flowOfFundsData[i].netIncomeStartingLine
                );
              flowOfFundsData[i].amortizationofIntangibleAssets =
                this.convertToMillionaire(
                  flowOfFundsData[i].amortizationofIntangibleAssets
                );
              flowOfFundsData[i].totalOtherCashFlow = this.convertToMillionaire(
                flowOfFundsData[i].totalOtherCashFlow
              );
              flowOfFundsData[i].commonDividendsCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].commonDividendsCash
                );
              flowOfFundsData[i].preferredDividendsCash =
                this.convertToMillionaire(
                  flowOfFundsData[i].preferredDividendsCash
                );

              flowOfFundsData[i].netSales = this.convertToMillionaire(
                flowOfFundsData[i].netSales
              );
              flowOfFundsData[i].depreciationandDepletion =
                this.convertToMillionaire(
                  flowOfFundsData[i].depreciationandDepletion
                );
              flowOfFundsData[i].fundsfromOperations =
                this.convertToMillionaire(
                  flowOfFundsData[i].fundsfromOperations
                );
              flowOfFundsData[i].extraordinaryItems = this.convertToMillionaire(
                flowOfFundsData[i].extraordinaryItems
              );
              flowOfFundsData[i].depreciationDepletionAndAmortization =
                this.convertToMillionaire(
                  flowOfFundsData[i].depreciationDepletionAndAmortization
                );
            }

            this.setState({
              render: true,
              actual: true,
              common: false,
              NetCashFlowOperatingActivities: NetCashFlowOperatingActivities,
              OtherUsesSourcesInvesting: OtherUsesSourcesInvesting,
              NetCashFlowInvesting: NetCashFlowInvesting,
              otherSourcesUsesFinancing: otherSourcesUsesFinancing,
              NetCashFlowFinancing: NetCashFlowFinancing,
              flowOfFundsData: flowOfFundsData,
              LabelList: LabelList,
              FundsFromForOtherOperatingActivities:
                FundsFromForOtherOperatingActivities,
              DecreaseIncreaseInReceivables: DecreaseIncreaseInReceivables,
              DecreaseIncreaseInInventories: DecreaseIncreaseInInventories,
              IncreaseDecreaseInAccountsPayable:
                IncreaseDecreaseInAccountsPayable,
              IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable:
                IncreaseDecreaseInIncomeTaxesPayableIncreaseDecreaseInIncomeTaxesPayable,
              IncreaseDecreaseInOtherAccruals: IncreaseDecreaseInOtherAccruals,
              DecreaseIncreaseinOtherAssetsLiabilities:
                DecreaseIncreaseinOtherAssetsLiabilities,
              LongTermBorrowings: LongTermBorrowings,
              NetProceedsfromSaleorIssuanceofCommonAndPreferred:
                NetProceedsfromSaleorIssuanceofCommonAndPreferred,
              ProceedsFromStockOptions: ProceedsFromStockOptions,
              OtherProceedsFromSaleRrIssuanceOfStock:
                OtherProceedsFromSaleRrIssuanceOfStock,
              DisposalOfFixedAssets: DisposalOfFixedAssets,
              DecreaseInInvestments: DecreaseInInvestments,
              IncreaseDecreaseInShortTermBorrowings:
                IncreaseDecreaseInShortTermBorrowings,
              EffectOfExchangeRateOnCash: EffectOfExchangeRateOnCash,
              IncreaseDecreaseInCash: IncreaseDecreaseInCash,
              OtherSourcesFinancing: OtherSourcesFinancing,
              OtherSourcesInvesting: OtherSourcesInvesting,
              CashDividendsPaidTotal: CashDividendsPaidTotal,
              CapitalExpendituresAdditionstoFixedAssets:
                CapitalExpendituresAdditionstoFixedAssets,
              AdditionsToOtherAssets: AdditionsToOtherAssets,
              ReductionInLongTermDebt: ReductionInLongTermDebt,
              comPfdPurchasedRetiredConvertedAndRedeemed:
                comPfdPurchasedRetiredConvertedAndRedeemed,
              NetAssetsFromAcquisitions: NetAssetsFromAcquisitions,
              IncreaseInInvestments: IncreaseInInvestments,
              OtherUsesInvesting: OtherUsesInvesting,
              OtherUsesFinancing: OtherUsesFinancing,
              NetIncomeStartingLine: NetIncomeStartingLine,
              AmortizationOfIntangibleAssets: AmortizationOfIntangibleAssets,
              DeferredIncomeTaxes: DeferredIncomeTaxes,
              InvestmentTaxCredit: InvestmentTaxCredit,
              TotalOtherCashFlow: TotalOtherCashFlow,
              CommonDividendsCash: CommonDividendsCash,
              PreferredDividendsCash: PreferredDividendsCash,
              DeferredIncomeTaxesAndInvestmentTaxCredit:
                DeferredIncomeTaxesAndInvestmentTaxCredit,
              NetSales: NetSales,
              DepreciationAndDepletion: DepreciationAndDepletion,
              FundsFromOperations: FundsFromOperations,
              ExtraordinaryItems: ExtraordinaryItems,
              DepreciationDepletionAndAmortization:
                DepreciationDepletionAndAmortization,
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  getDataInGraphicalForm(graphType) {
    if (graphType === "Funds from/for Other Operating Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromForOtherOperatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from/for Other Operating Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Decrease/(Increase) in Receivables") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseIncreaseInReceivables,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease/(Increase) in Receivables",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Decrease/(Increase) in Inventories") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseIncreaseInInventories,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease/(Increase) in Inventories",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/(Decrease) in Accounts Payable") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInAccountsPayable,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/(Decrease) in Accounts Payable",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase Decrease in Income Taxes Payable") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInIncomeTaxesPayable,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase Decrease in Income Taxes Payable",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase Decrease in Other Accruals") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInOtherAccruals,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase Decrease in Other Accruals",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Decrease/(Increase) in Other Assets/Liabilities"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseIncreaseinOtherAssetsLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease/(Increase) in Other Assets/Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Long Term Borrowings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.LongTermBorrowings,
          labelData: this.state.LabelList,
          yAxisTitle: "Long Term Borrowings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Net Proceeds from Sale or Issuance of Common And Preferred"
    ) {
      this.setState({
        graphDetails: {
          graphValues:
            this.state.NetProceedsfromSaleorIssuanceofCommonAndPreferred,
          labelData: this.state.LabelList,
          yAxisTitle:
            "Net Proceeds from Sale or Issuance of Common And Preferred",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Proceeds From Stock Options") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ProceedsFromStockOptions,
          labelData: this.state.LabelList,
          yAxisTitle: "Proceeds From Stock Options",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Proceeds from Sale or Issuance of Stock") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherProceedsFromSaleRrIssuanceOfStock,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Proceeds from Sale or Issuance of Stock",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Disposal of Fixed Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DisposalOfFixedAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Disposal of Fixed Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Decrease in Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DecreaseInInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Decrease in Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/(Decrease) in Short-Term Borrowings") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInShortTermBorrowings,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/(Decrease) in Short-Term Borrowings",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Effect of Exchange Rateon Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.EffectOfExchangeRateOnCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Effect of Exchange Rateon Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase/(Decrease) in Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseDecreaseInCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase/(Decrease) in Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Sources Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherSourcesFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Sources Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Sources Investing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherSourcesInvesting,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Sources Investing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash Dividends Paid - Total") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CashDividendsPaidTotal,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash Dividends Paid - Total",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Capital Expenditures Additionst oF ixedAssets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CapitalExpendituresAdditionstoFixedAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Capital Expenditures Additionst oF ixedAssets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Additions to Other Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AdditionsToOtherAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Additions to Other Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Reduction in Long Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ReductionInLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Reduction in Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "comPfd Purchased Retired Converted And Redeemed"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.comPfdPurchasedRetiredConvertedAndRedeemed,
          labelData: this.state.LabelList,
          yAxisTitle: "comPfd Purchased Retired Converted And Redeemed",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Assets from Acquisitions") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetAssetsFromAcquisitions,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Assets from Acquisitions",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Increase in Investments") {
      this.setState({
        graphDetails: {
          graphValues: this.state.IncreaseInInvestments,
          labelData: this.state.LabelList,
          yAxisTitle: "Increase in Investments",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses Investing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUsesInvesting,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses Investing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUsesFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Cash Flow Operating Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetCashFlowOperatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Cash Flow Operating Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Other Uses Sources Investing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.OtherUsesSourcesInvesting,
          labelData: this.state.LabelList,
          yAxisTitle: "Other Uses Sources Investing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Cash Flow Investing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetCashFlowInvesting,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Cash Flow Investing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "other Sources Uses Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.otherSourcesUsesFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "other Sources Uses Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Cash Flow Financing") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetCashFlowFinancing,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Cash Flow Financing",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Income Starting Line") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetIncomeStartingLine,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Income Starting Line",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Amortization of Intangible Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.AmortizationOfIntangibleAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Amortization of Intangible Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Deferred Income Taxes") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredIncomeTaxes,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Income Taxes",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investment Tax Credit") {
      this.setState({
        graphDetails: {
          graphValues: this.state.InvestmentTaxCredit,
          labelData: this.state.LabelList,
          yAxisTitle: "Investment Tax Credit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Other Cash Flow") {
      this.setState({
        graphDetails: {
          graphValues: this.state.TotalOtherCashFlow,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Other Cash Flow",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Common Dividends Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.CommonDividendsCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Common Dividends Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Preferred Dividends Cash") {
      this.setState({
        graphDetails: {
          graphValues: this.state.PreferredDividendsCash,
          labelData: this.state.LabelList,
          yAxisTitle: "Preferred Dividends Cash",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Deferred Income Taxes And Investment Tax Credit"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.DeferredIncomeTaxesAndInvestmentTaxCredit,
          labelData: this.state.LabelList,
          yAxisTitle: "Deferred Income Taxes And Investment Tax Credit",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.NetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Depreciationand Depletion") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DepreciationAndDepletion,
          labelData: this.state.LabelList,
          yAxisTitle: "Depreciationand Depletion",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds From Operations") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperations,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds From Operations",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Extraordinary Items") {
      this.setState({
        graphDetails: {
          graphValues: this.state.ExtraordinaryItems,
          labelData: this.state.LabelList,
          yAxisTitle: "Extraordinary Items",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Depreciation Depletion And Amortization") {
      this.setState({
        graphDetails: {
          graphValues: this.state.DepreciationDepletionAndAmortization,
          labelData: this.state.LabelList,
          yAxisTitle: "Depreciation Depletion And Amortization",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-3em" }}>
            <Col md={12} style={{ padding: "0em 0em 0.3em 2.9em" }}>
              <Row>
                <Col md={8} style={{ padding: "8px 10px" }}>
                  {this.state.actual && (
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  )}

                  {this.state.common && (
                    <>
                      <span className="unit_str">
                        {" "}
                        Figures are expressed as Percent of Net Sales
                      </span>
                      <br></br>
                    </>
                  )}
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="balance_sheet">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td style={{ fontWeight: "bold" }}>{data.year}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEnd}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Income Starting Line</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netIncomeStartingLine}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation Depletion And Amortization</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.depreciationDepletionAndAmortization}</td>;
                  })}
                </tr>
                <tr>
                  <th>Depreciation and Depletion </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.depreciationandDepletion}</td>;
                  })}
                </tr>
                <tr>
                  <th>Amortization of Intangible Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.amortizationofIntangibleAssets}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Deferred Income Taxes & Investment Tax Credit
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.deferredIncomeTaxesAndInvestmentTaxCredit}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Deferred Income Taxes</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.deferredIncomeTaxes}</td>;
                  })}
                </tr>
                <tr>
                  <th>Investment Tax Credit</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.investmentTaxCredit}</td>;
                  })}
                </tr>
                <tr>
                  <th>Total Other Cash Flow</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.totalOtherCashFlow}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Funds from Operations</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fundsfromOperations}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Extraordinary Items</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.extraordinaryItems}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Funds from/for Other Operating Activities
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fundsfromforOtherOperatingActivities}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Decrease/(Increase) in Receivables</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseIncreaseinReceivables}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease/(Increase) in Inventories</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseIncreaseinInventories}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Accounts Payable</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinAccountsPayable}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Income Taxes Payable</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinIncomeTaxesPayable}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Other Accruals</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinOtherAccruals}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease/(Increase) in Other Assets/Liabilities</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.decreaseIncreaseinOtherAssetsLiabilities}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Net Cash Flow Operating Activities
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.netCashFlowOperatingActivities}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>(Capital Expenditures (Additionst of Fixed Assets)</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.capitalExpendituresAdditionstoFixedAssets}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Additions to Other Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.additionstoOtherAssets}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Assets from Acquisitions</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netAssetsfromAcquisitions}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase in Investments</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseinInvestments}</td>;
                  })}
                </tr>
                <tr>
                  <th>Decrease in Investments</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.decreaseinInvestments}</td>;
                  })}
                </tr>
                <tr>
                  <th>Disposal of Fixed Assets</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.disposalofFixedAssets}</td>;
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    Other Uses/Sources - Investing
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.otherUsesSourcesInvesting}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Other Uses - Investing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherUsesInvesting}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources - Investing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSourcesInvesting}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Cash Flow - Investing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netCashFlowInvesting}</td>;
                  })}
                </tr>
                <tr>
                  <th>
                    Net Proceeds from Sale or Issuance of Common And Preferred
                  </th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>
                        {data.netProceedsfromSaleorIssuanceofCommonAndPreferred}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Com/Pfd - Purchased, Retired, Converted & Redeemed</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.comPfdPurchasedRetiredConvertedAndRedeemed}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Long Term Borrowings</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.longTermBorrowings}</td>;
                  })}
                </tr>

                <tr>
                  <th>Proceeds From Stock Options</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.proceedsfromStockOptions}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Proceeds from Sale or Issuance of Stock</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.otherProceedsfromSaleorIssuanceofStock}</td>
                    );
                  })}
                </tr>

                <tr>
                  <th>Increase/(Decrease) in Short-Term Borrowings</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return (
                      <td>{data.increaseDecreaseinShortTermBorrowings}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Reduction in Long Term Debt</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.reductioninLongTermDebt}</td>;
                  })}
                </tr>
                <tr>
                  <th>Cash Dividends Paid - Total</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.cashDividendsPaidTotal}</td>;
                  })}
                </tr>
                <tr>
                  <th>Common Dividends (Cash)</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.commonDividendsCash}</td>;
                  })}
                </tr>
                <tr>
                  <th>Preferred Dividends (Cash)</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.preferredDividendsCash}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources/Uses - Financing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSourcesUsesFinancing}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Sources - Financing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherSourcesFinancing}</td>;
                  })}
                </tr>
                <tr>
                  <th>Other Uses - Financing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.otherUsesFinancing}</td>;
                  })}
                </tr>
                <tr>
                  <th>Net Cash Flow Financing</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.netCashFlowFinancing}</td>;
                  })}
                </tr>
                <tr>
                  <th>Effect of Exchange Rateon Cash</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.effectofExchangeRateonCash}</td>;
                  })}
                </tr>
                <tr>
                  <th>Increase/(Decrease) in Cash</th>
                  {this.state.flowOfFundsData.map((data) => {
                    return <td>{data.increaseDecreaseinCash}</td>;
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-3em" }}>
              <Col md={12}>
                <Row>
                  <Col md={8} style={{ padding: "8px 10px" }}>
                    {this.state.actual && (
                      <span className="unit_str">
                        All figures in {this.currency}
                      </span>
                    )}

                    {this.state.common && (
                      <>
                        <span className="unit_str">
                          {" "}
                          Figures are expressed as Percent of Net Sales
                        </span>
                        <br></br>
                      </>
                    )}
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="balance_sheet">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>{data.year}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEnd}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Net Income Starting Line</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netIncomeStartingLine}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation Depletion And Amortization</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.depreciationDepletionAndAmortization}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Depreciation and Depletion </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.depreciationandDepletion}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Amortization of Intangible Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.amortizationofIntangibleAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Deferred Income Taxes & Investment Tax Credit
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.deferredIncomeTaxesAndInvestmentTaxCredit}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Deferred Income Taxes</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.deferredIncomeTaxes}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Investment Tax Credit</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.investmentTaxCredit}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Total Other Cash Flow</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.totalOtherCashFlow}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Funds from Operations</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.fundsfromOperations}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Extraordinary Items</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.extraordinaryItems}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Funds from/for Other Operating Activities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Funds from/for Other Operating Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.fundsfromforOtherOperatingActivities}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease/(Increase) in Receivables
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Decrease/(Increase) in Receivables"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseIncreaseinReceivables}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease/(Increase) in Inventories
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Decrease/(Increase) in Inventories"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseIncreaseinInventories}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Increase/(Decrease) in Accounts Payable
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Increase/(Decrease) in Accounts Payable"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinAccountsPayable}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Increase/(Decrease) in Income Taxes Payable</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.increaseDecreaseinIncomeTaxesPayable}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Increase/(Decrease) in Other Accruals</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinOtherAccruals}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease/(Increase) in Other Assets/Liabilities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Decrease/(Increase) in Other Assets/Liabilities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.decreaseIncreaseinOtherAssetsLiabilities}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Net Cash Flow Operating Activities
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Net Cash Flow Operating Activities"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.netCashFlowOperatingActivities}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Capital Expenditures (Additionst of Fixed Assets)</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.capitalExpendituresAdditionstoFixedAssets}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Additions to Other Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.additionstoOtherAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Net Assets from Acquisitions</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netAssetsfromAcquisitions}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Increase in Investments
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Increase in Investments")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseinInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Decrease in Investments
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Decrease in Investments")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.decreaseinInvestments}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Disposal of Fixed Assets</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.disposalofFixedAssets}</td>;
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>
                      Other Uses/Sources - Investing
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.otherUsesSourcesInvesting}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Other Uses - Investing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherUsesInvesting}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources - Investing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSourcesInvesting}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Cash Flow - Investing
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Cash Flow Investing")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netCashFlowInvesting}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Proceeds from Sale or Issuance of Common And Preferred
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {
                            data.netProceedsfromSaleorIssuanceofCommonAndPreferred
                          }
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Proceeds From Stock Options</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.proceedsfromStockOptions}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Proceeds from Sale or Issuance of Stock</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.otherProceedsfromSaleorIssuanceofStock}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Com/Pfd - Purchased, Retired, Converted & Redeemed</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>
                          {data.comPfdPurchasedRetiredConvertedAndRedeemed}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Long Term Borrowings
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Long Term Borrowings")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.longTermBorrowings}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>
                      Increase/(Decrease) in Short-Term Borrowings
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Increase/(Decrease) in Short-Term Borrowings"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return (
                        <td>{data.increaseDecreaseinShortTermBorrowings}</td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Reduction in Long Term Debt
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Reduction in Long Term Debt"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.reductioninLongTermDebt}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Cash Dividends Paid - Total
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Cash Dividends Paid - Total"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.cashDividendsPaidTotal}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Common Dividends (Cash)</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.commonDividendsCash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Preferred Dividends (Cash)</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.preferredDividendsCash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources/Uses - Financing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSourcesUsesFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Sources - Financing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherSourcesFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Other Uses - Financing</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.otherUsesFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Net Cash Flow Financing
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Net Cash Flow Financing")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.netCashFlowFinancing}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>Effect of Exchange Rateon Cash</th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.effectofExchangeRateonCash}</td>;
                    })}
                  </tr>
                  <tr>
                    <th>
                      Increase/(Decrease) in Cash
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm(
                            "Increase/(Decrease) in Cash"
                          )
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.flowOfFundsData.map((data) => {
                      return <td>{data.increaseDecreaseinCash}</td>;
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      {this.state.modalHeader}
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All figures in {this.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                      type="flowOfFunds"
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
