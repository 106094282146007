import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../../provider/Utils";
import GraphIconButton from "../../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../../genricGraph/SingleAxisGenericGraph";
import "./IndLiquidityAnalysis.css";

export default class IndLiquidityAnalysis extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    let liquidityAnalysisList = props.data.liquidityAnalysisList.slice(0, 5);

    if (liquidityAnalysisList.length > 0) {
      liquidityAnalysisList.reverse();
    }
    let currentRatioData = [];
    let LabelList = [];

    let totalCurrentAssetsNetSales = [];
    let cashAndEquivalentsOfCurrentAssets = [];
    let quickRatio = [];
    let receivablesOfCurrentAssets = [];
    let receivableTurnoverNumberOfDays = [];
    let receivablesOfTotalAssets = [];
    let totalDebtOfTotalCapital = [];
    let fundsFromOperationsOfTotalDebt = [];
    let fundsFromOperationsOfTotalCapital = [];
    let operatingActivities = [];
    let financingActivities = [];
    let investingActivities = [];

    let inventoriesOfCurrentAssets = [];
    let FundsFromOperationsOfLongTermDebt = [];
    let FundsFromOperationsOfCurrentLiabilities = [];
    let cashOfCurrentAssets = [];
    let inventoryToCashAndEquivalentsNumberOfDays = [];
    let inventoryTurnoverNumberOfDays = [];
    for (let i = 0; i < liquidityAnalysisList.length; i++) {
      inventoriesOfCurrentAssets.push(
        liquidityAnalysisList[i].inventoriesPercentofCurrentAssets
      );
      FundsFromOperationsOfLongTermDebt.push(
        liquidityAnalysisList[i].fundsfromOperationsPercentofLongTermDebt
      );
      FundsFromOperationsOfCurrentLiabilities.push(
        liquidityAnalysisList[i].fundsfromOperationsPercentofCurrentLiabilities
      );
      cashOfCurrentAssets.push(
        liquidityAnalysisList[i].cashPercentofCurrentAssets
      );
      inventoryToCashAndEquivalentsNumberOfDays.push(
        liquidityAnalysisList[i].inventorytoCashAndEquivalentsnumberofdays
      );
      inventoryTurnoverNumberOfDays.push(
        liquidityAnalysisList[i].inventoryTurnovernumberofdays
      );
      currentRatioData.push(liquidityAnalysisList[i].currentRatio);
      LabelList.push(liquidityAnalysisList[i].fiscalYear);

      totalCurrentAssetsNetSales.push(
        liquidityAnalysisList[i].totalCurrentAssetsPercentNetSales
      );
      cashAndEquivalentsOfCurrentAssets.push(
        liquidityAnalysisList[i].cashAndEquivalentsPercentofCurrentAssets
      );
      quickRatio.push(liquidityAnalysisList[i].quickRatio);
      receivablesOfCurrentAssets.push(
        liquidityAnalysisList[i].receivablesPercentofCurrentAssets
      );
      receivableTurnoverNumberOfDays.push(
        liquidityAnalysisList[i].receivableTurnovernumberofdays
      );
      receivablesOfTotalAssets.push(
        liquidityAnalysisList[i].receivablesPercentofTotalAssets
      );
      totalDebtOfTotalCapital.push(
        liquidityAnalysisList[i].totalDebtPercentofTotalCapital
      );
      fundsFromOperationsOfTotalDebt.push(
        liquidityAnalysisList[i].fundsfromOperationsPercentofTotalDebt
      );
      fundsFromOperationsOfTotalCapital.push(
        liquidityAnalysisList[i].fundsfromOperationsPercentofTotalCapital
      );
      operatingActivities.push(liquidityAnalysisList[i].operatingActivities);
      financingActivities.push(liquidityAnalysisList[i].financingActivities);
      investingActivities.push(liquidityAnalysisList[i].investingActivities);
    }
    this.state = {
      isPdfReport: isPdfReport,
      inventoriesOfCurrentAssets: inventoriesOfCurrentAssets,
      FundsFromOperationsOfLongTermDebt: FundsFromOperationsOfLongTermDebt,
      FundsFromOperationsOfCurrentLiabilities:
        FundsFromOperationsOfCurrentLiabilities,
      cashOfCurrentAssets: cashOfCurrentAssets,
      inventoryToCashAndEquivalentsNumberOfDays:
        inventoryToCashAndEquivalentsNumberOfDays,
      inventoryTurnoverNumberOfDays: inventoryTurnoverNumberOfDays,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      liquidityAnalysisList: liquidityAnalysisList,
      currentRatioData: currentRatioData,
      LabelList: LabelList,
      totalCurrentAssetsNetSales: totalCurrentAssetsNetSales,
      cashAndEquivalentsOfCurrentAssets: cashAndEquivalentsOfCurrentAssets,
      quickRatio: quickRatio,
      receivablesOfCurrentAssets: receivablesOfCurrentAssets,
      receivableTurnoverNumberOfDays: receivableTurnoverNumberOfDays,
      receivablesOfTotalAssets: receivablesOfTotalAssets,
      totalDebtOfTotalCapital: totalDebtOfTotalCapital,
      fundsFromOperationsOfTotalDebt: fundsFromOperationsOfTotalDebt,
      fundsFromOperationsOfTotalCapital: fundsFromOperationsOfTotalCapital,
      operatingActivities: operatingActivities,
      financingActivities: financingActivities,
      investingActivities: investingActivities,
      series: [
        {
          name: "Current Ratio",
          type: "bar",
          data: currentRatioData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },

        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          text: "",
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        colors: ["#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: LabelList,
        xaxis: {
          title: {
            text: "Year",
            style: {
              colors: "#808b96",
            },
          },

          axisBorder: {
            show: true,
            color: "#808b96",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "Current Ratio",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#808b96 ",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;
            },
          },
        },
      },
      tableGraphSeries: [
        {
          data: currentRatioData,
        },
      ],
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }

  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Total Current Assets % Net Sales") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalCurrentAssetsNetSales,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Current Assets % Net Sales",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash & Equivalents % of Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashAndEquivalentsOfCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash & Equivalents % of Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Quick Ratio") {
      this.setState({
        graphDetails: {
          graphValues: this.state.quickRatio,
          labelData: this.state.LabelList,
          yAxisTitle: "Quick Ratio",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivables % of Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivablesOfCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables % of Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivable Turnover - number of days") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivableTurnoverNumberOfDays,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivable Turnover - number of days",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivables % of Total Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivablesOfTotalAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables % of Total Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Current Ratio") {
      this.setState({
        graphDetails: {
          graphValues: this.state.currentRatioData,
          labelData: this.state.LabelList,
          yAxisTitle: "Current Ratio",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Total Debt % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.totalDebtOfTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Total Debt % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations % of Total Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.fundsFromOperationsOfTotalDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations % of Total Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations % of Total Capital") {
      this.setState({
        graphDetails: {
          graphValues: this.state.fundsFromOperationsOfTotalCapital,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations % of Total Capital",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Operating Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.operatingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Operating Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Financing Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.financingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Financing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Investing Activities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.investingActivities,
          labelData: this.state.LabelList,
          yAxisTitle: "Investing Activities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Inventories % of Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventoriesOfCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventories % of Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Inventory Turnover - number of days") {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventoryTurnoverNumberOfDays,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventory Turnover - number of days",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (
      graphType === "Inventory to Cash & Equivalents - number of days"
    ) {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventoryToCashAndEquivalentsNumberOfDays,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventory to Cash & Equivalents - number of days",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Cash % of Current Assets") {
      this.setState({
        graphDetails: {
          graphValues: this.state.cashOfCurrentAssets,
          labelData: this.state.LabelList,
          yAxisTitle: "Cash % of Current Assets",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations % of Current Liabilities") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsOfCurrentLiabilities,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations % of Current Liabilities",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Funds from Operations % of Long Term Debt") {
      this.setState({
        graphDetails: {
          graphValues: this.state.FundsFromOperationsOfLongTermDebt,
          labelData: this.state.LabelList,
          yAxisTitle: "Funds from Operations % of Long Term Debt",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.isPdfReport === true) {
      return (
        <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
          <Col
            md={12}
            style={{ padding: "0em 0em 0.3em 1.4em", marginLeft: "1em" }}
          >
            <Row style={{ padding: "0px 0px 7px 0px" }}>
              <Col md={8}>
                {" "}
                <span>
                  <span className="section_header"> Liquidity Analysis</span>
                </span>{" "}
              </Col>
              <Col md={4}></Col>
            </Row>
            <Table responsive className="liquid_analysis">
              <tr>
                <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.fiscalYearEndDate !== null &&
                      data.fiscalYearEndDate !== ""
                        ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                        : ""}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th style={{ fontWeight: "bold" }}>
                  <span className="">Total Current Assets % Net Sales</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td style={{ fontWeight: "bold" }}>
                      {data.totalCurrentAssetsPercentNetSales !== null
                        ? Utils.valueFormater(
                            data.totalCurrentAssetsPercentNetSales
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Cash % of Current Assets</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {" "}
                      {data.cashPercentofCurrentAssets !== null
                        ? Utils.valueFormater(data.cashPercentofCurrentAssets) +
                          "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">
                    Cash & Equivalents % of Current Assets
                  </span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {" "}
                      {data.cashAndEquivalentsPercentofCurrentAssets !== null
                        ? Utils.valueFormater(
                            data.cashAndEquivalentsPercentofCurrentAssets
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Quick Ratio</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {" "}
                      {data.quickRatio !== null
                        ? Utils.valueFormater(data.quickRatio)
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Receivables % of Current Assets</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {" "}
                      {data.receivablesPercentofCurrentAssets !== null
                        ? Utils.valueFormater(
                            data.receivablesPercentofCurrentAssets
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Receivable Turnover - number of days</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {" "}
                      {data.receivableTurnovernumberofdays !== null
                        ? Utils.valueFormater(
                            data.receivableTurnovernumberofdays
                          )
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Inventories % of Current Assets</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.inventoriesPercentofCurrentAssets !== null
                        ? Utils.valueFormater(
                            data.inventoriesPercentofCurrentAssets
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Inventory Turnover - number of days</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.inventoryTurnovernumberofdays !== null
                        ? Utils.valueFormater(
                            data.inventoryTurnovernumberofdays
                          )
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Inventory to Cash & Equivalents - number of days</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.inventorytoCashAndEquivalentsnumberofdays !== null
                        ? Utils.valueFormater(
                            data.inventorytoCashAndEquivalentsnumberofdays
                          )
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Receivables % of Total Assets</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.receivablesPercentofTotalAssets !== null
                        ? Utils.valueFormater(
                            data.receivablesPercentofTotalAssets
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className=""> Current Ratio</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.currentRatio !== null
                        ? Utils.valueFormater(data.currentRatio)
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Total Debt % of Total Capital</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.totalDebtPercentofTotalCapital !== null
                        ? Utils.valueFormater(
                            data.totalDebtPercentofTotalCapital
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Funds from Operations % of Current Liabilities</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.fundsfromOperationsPercentofCurrentLiabilities !==
                      null
                        ? Utils.valueFormater(
                            data.fundsfromOperationsPercentofCurrentLiabilities
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Funds from Operations % of Long Term Debt</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.fundsfromOperationsPercentofLongTermDebt !== null
                        ? Utils.valueFormater(
                            data.fundsfromOperationsPercentofLongTermDebt
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">
                    Funds from Operations % of Total Debt
                  </span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.fundsfromOperationsPercentofTotalDebt !== null
                        ? Utils.valueFormater(
                            data.fundsfromOperationsPercentofTotalDebt
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">
                    Funds from Operations % of Total Capital
                  </span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.fundsfromOperationsPercentofTotalCapital !== null
                        ? Utils.valueFormater(
                            data.fundsfromOperationsPercentofTotalCapital
                          ) + "%"
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>Cash Flow (in milllions of {this.state.currency} )</th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return <td></td>;
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Operating Activities</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.operatingActivities !== null
                        ? Utils.valueFormater(data.operatingActivities)
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Financing Activities</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.financingActivities !== null
                        ? Utils.valueFormater(data.financingActivities)
                        : "0"}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <th>
                  <span className="">Investing Activities</span>
                </th>
                {this.state.liquidityAnalysisList.map((data) => {
                  return (
                    <td>
                      {data.investingActivities !== null
                        ? Utils.valueFormater(data.investingActivities)
                        : "0"}
                    </td>
                  );
                })}
              </tr>
            </Table>
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <Row style={{ padding: "15px", marginTop: "-1.5em" }}>
            <Col md={12}>
              <Row style={{ padding: "0px 0px 7px 0px" }}>
                <Col md={8}>
                  {" "}
                  <span>
                    <span className="section_header"> Liquidity Analysis</span>
                  </span>{" "}
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="liquid_analysis">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>
                    <span className="">Total Current Assets % Net Sales</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Total Current Assets % Net Sales"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.totalCurrentAssetsPercentNetSales !== null
                          ? Utils.valueFormater(
                              data.totalCurrentAssetsPercentNetSales
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash % of Current Assets</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.cashPercentofCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.cashPercentofCurrentAssets
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Cash & Equivalents % of Current Assets
                    </span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Cash & Equivalents % of Current Assets"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.cashAndEquivalentsPercentofCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.cashAndEquivalentsPercentofCurrentAssets
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Quick Ratio</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm("Quick Ratio")
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.quickRatio !== null
                          ? Utils.valueFormater(data.quickRatio)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Receivables % of Current Assets</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Receivables % of Current Assets"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.receivablesPercentofCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.receivablesPercentofCurrentAssets
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Receivable Turnover - number of days
                    </span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Receivable Turnover - number of days"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {" "}
                        {data.receivableTurnovernumberofdays !== null
                          ? Utils.valueFormater(
                              data.receivableTurnovernumberofdays
                            )
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Inventories % of Current Assets</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.inventoriesPercentofCurrentAssets !== null
                          ? Utils.valueFormater(
                              data.inventoriesPercentofCurrentAssets
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Inventory Turnover - number of days</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.inventoryTurnovernumberofdays !== null
                          ? data.inventoryTurnovernumberofdays
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Inventory to Cash & Equivalents - number of days</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.inventorytoCashAndEquivalentsnumberofdays !== null
                          ? Utils.valueFormater(
                              data.inventorytoCashAndEquivalentsnumberofdays
                            )
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Receivables % of Total Assets</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Receivables % of Total Assets"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.receivablesPercentofTotalAssets !== null
                          ? Utils.valueFormater(
                              data.receivablesPercentofTotalAssets
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className=""> Current Ratio</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm("Current Ratio")
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.currentRatio !== null
                          ? Utils.valueFormater(data.currentRatio)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Total Debt % of Total Capital</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Total Debt % of Total Capital"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.totalDebtPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.totalDebtPercentofTotalCapital
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds from Operations % of Current Liabilities</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.fundsfromOperationsPercentofCurrentLiabilities !==
                        null
                          ? Utils.valueFormater(
                              data.fundsfromOperationsPercentofCurrentLiabilities
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Funds from Operations % of Long Term Debt</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.fundsfromOperationsPercentofLongTermDebt !== null
                          ? Utils.valueFormater(
                              data.fundsfromOperationsPercentofLongTermDebt
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Funds from Operations % of Total Debt
                    </span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Funds from Operations % of Total Debt"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.fundsfromOperationsPercentofTotalDebt !== null
                          ? Utils.valueFormater(
                              data.fundsfromOperationsPercentofTotalDebt
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">
                      Funds from Operations % of Total Capital
                    </span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm(
                          "Funds from Operations % of Total Capital"
                        )
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.fundsfromOperationsPercentofTotalCapital !== null
                          ? Utils.valueFormater(
                              data.fundsfromOperationsPercentofTotalCapital
                            ) + "%"
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Cash Flow (in milllions of {this.state.currency} )</th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return <td></td>;
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Operating Activities</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm("Operating Activities")
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.operatingActivities !== null
                          ? Utils.valueFormater(data.operatingActivities)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Financing Activities</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm("Financing Activities")
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.financingActivities !== null
                          ? Utils.valueFormater(data.financingActivities)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Investing Activities</span>

                    <GraphIconButton
                      onClick={(e) =>
                        this.getDataInGraphicalForm("Investing Activities")
                      }
                    ></GraphIconButton>
                  </th>
                  {this.state.liquidityAnalysisList.map((data) => {
                    return (
                      <td>
                        {data.investingActivities !== null
                          ? Utils.valueFormater(data.investingActivities)
                          : "0"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
          <Modal
            isOpen={this.state.showModal}
            toggle={this.toggle}
            size="md"
            className="modalContain"
          >
            <ModalHeader>
              <Row>
                <Col md={10}>
                  <Label
                    style={{
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      float: "left",
                    }}
                  >
                    Liquidity Analysis
                  </Label>
                </Col>
                <Col md={2}>
                  <Fab
                    aria-label="add"
                    className="modal_close_btn"
                    onClick={this.toggle}
                  >
                    <CloseIcon />
                  </Fab>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <SingleAxisGenericGraph graphData={this.state.graphDetails} />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}
