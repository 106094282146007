import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "react-tabs/style/react-tabs.css";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Utils from "../../../provider/Utils";
import GraphIconButton from "../../buttons/GraphIconButton";
import SingleAxisGenericGraph from "../../genricGraph/SingleAxisGenericGraph";
import "./IndAccountingRatios.css";
export default class IndAccountingRatios extends Component {
  constructor(props) {
    super(props);
    let isPdfReport = false;
    if (props.isPdfReport === "true") {
      isPdfReport = true;
    }
    this.state = {
      isPdfReport: isPdfReport,
      data: props.data,
      type: props.type,
      unit: props.data.unit,
      currency: props.data.currency,
      accountingRatioIndData: [],
      render: false,
      showModal: false,
      tableGraphOptions: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
    };
  }

  convertToMillionaire(value) {
    let values = value;
    if (values !== null) {
      return values;
    } else {
      values = "";
      return values;
    }
  }
  componentDidMount() {
    this.accountingRatioIndData = [];
    let accountingRatioIndData = this.state.data.accountingRatioIndList;
    if (accountingRatioIndData.length > 0) {
      //accountingRatioIndData.reverse();
    }
    let inventoryTurnover = [];
    let receivablesTurnover = [];
    let receivables = [];
    let inventory = [];
    let LabelList = [];

    for (let i = 0; i < accountingRatioIndData.length; i++) {
      accountingRatioIndData[i].netPropertyPlantEquipmentTurnoverPercent =
        this.convertToMillionaire(
          accountingRatioIndData[i].netPropertyPlantEquipmentTurnoverPercent
        );
      accountingRatioIndData[
        i
      ].depreciationDepletionAmortizationYeartoYearChangePercent = this.convertToMillionaire(
        accountingRatioIndData[i]
          .depreciationDepletionAmortizationYeartoYearChangePercent
      );
      accountingRatioIndData[i].receiveableTurnoverPercent =
        this.convertToMillionaire(
          accountingRatioIndData[i].receiveableTurnoverPercent
        );
      accountingRatioIndData[i].grossPropertyPlantEquipmentTurnoverPercent =
        this.convertToMillionaire(
          accountingRatioIndData[i].grossPropertyPlantEquipmentTurnoverPercent
        );
      accountingRatioIndData[
        i
      ].depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipmentPercent =
        this.convertToMillionaire(
          accountingRatioIndData[i]
            .depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipmentPercent
        );
      accountingRatioIndData[i].netSales = this.convertToMillionaire(
        accountingRatioIndData[i].netSales
      );
      accountingRatioIndData[i].depreciationDepletionAmortization =
        this.convertToMillionaire(
          accountingRatioIndData[i].depreciationDepletionAmortization
        );
      accountingRatioIndData[i].receiveableTurnoverPercent =
        this.convertToMillionaire(
          accountingRatioIndData[i].receiveableTurnoverPercent
        );
      accountingRatioIndData[i].receivablesNumberofDays =
        this.convertToMillionaire(
          accountingRatioIndData[i].receivablesNumberofDays
        );

      accountingRatioIndData[i].inventoryNumberofDays =
        this.convertToMillionaire(
          accountingRatioIndData[i].inventoryNumberofDays
        );
      accountingRatioIndData[i].grossPropertyPlantEquipmentTurnover =
        this.convertToMillionaire(
          accountingRatioIndData[i].grossPropertyPlantEquipmentTurnover
        );
      accountingRatioIndData[i].netPropertyPlantEquipmentTurnover =
        this.convertToMillionaire(
          accountingRatioIndData[i].netPropertyPlantEquipmentTurnover
        );
      accountingRatioIndData[
        i
      ].depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipment =
        this.convertToMillionaire(
          accountingRatioIndData[i]
            .depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipment
        );
      accountingRatioIndData[i].deprecation = this.convertToMillionaire(
        accountingRatioIndData[i].deprecation
      );
      accountingRatioIndData[i].deprecation1yearago = this.convertToMillionaire(
        accountingRatioIndData[i].deprecation1yearago
      );

      accountingRatioIndData[
        i
      ].g11NDepreciationDepletionAmortizationYeartoYearPercentChange = this.convertToMillionaire(
        accountingRatioIndData[i]
          .g11NDepreciationDepletionAmortizationYeartoYearPercentChange
      );
      inventoryTurnover.push(accountingRatioIndData[i].inventoryTurnover);
      receivablesTurnover.push(
        accountingRatioIndData[i].receiveableTurnoverPercent
      );
      LabelList.push(accountingRatioIndData[i].fiscalYear);
      receivables.push(accountingRatioIndData[i].receivablesNumberofDays);
      inventory.push(accountingRatioIndData[i].inventoryNumberofDays);
    }

    this.setState({
      accountingRatioIndData: accountingRatioIndData,
      receivables: receivables,
      inventory: inventory,
      inventoryTurnover: inventoryTurnover,
      receivablesTurnover: receivablesTurnover,

      LabelList: LabelList,
      series: [
        {
          name: "Inventory Turnover",
          type: "bar",
          data: inventoryTurnover,
        },
        {
          name: "Receivables Turnover",
          type: "bar",
          data: receivablesTurnover,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          toolbar: {
            show: false,
          },
        },

        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        title: {
          align: "left",
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        colors: ["#ff7f0e", "#1f77b4"],

        legend: {
          show: true,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: ["#7d6608", "#0b5345"],
            useSeriesColors: true,
          },
        },

        labels: LabelList,
        xaxis: {
          title: {
            text: "Year",
            style: {
              colors: "#808b96",
            },
          },

          axisBorder: {
            show: true,
            color: "#808b96",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: "Inventory And Receivables Turnover",
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#808b96 ",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
        },
      },
      tableGraphSeries: [
        {
          data: receivablesTurnover,
        },
      ],

      tableGraphSeries1: [
        {
          data: inventoryTurnover,
        },
      ],
      tableGraphOptions1: {
        chart: {
          type: "bar",
          width: 100,
          height: 35,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              },
            },
          },
          marker: {
            show: false,
          },
        },
      },
      render: true,
    });
  }
  toTitleCase(str) {
    if (str !== null || str !== undefined) {
      return str;
    } else {
      return "";
    }
  }
  getDataInGraphicalForm(graphType) {
    if (graphType === "Receivables Turnover") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivablesTurnover,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables Turnover",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Receivables") {
      this.setState({
        graphDetails: {
          graphValues: this.state.receivables,
          labelData: this.state.LabelList,
          yAxisTitle: "Receivables - Number of Days",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else if (graphType === "Inventory Turnover") {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventoryTurnover,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventory Turnover",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    } else {
      this.setState({
        graphDetails: {
          graphValues: this.state.inventory,
          labelData: this.state.LabelList,
          yAxisTitle: "Inventory - Number of Days",
          xAxisTitle: "Year",
          yAxisTitleSign: "",
        },
        showModal: true,
        graphType: graphType,
      });
    }
    // }
  }
  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      if (this.state.isPdfReport === true) {
        return (
          <Row style={{ padding: "15px", marginTop: "-2em" }}>
            <Col md={12} style={{ marginLeft: "1.5em" }}>
              <Row style={{ padding: "20px 0px 7px 0px" }}>
                <Col md={8}>
                  <span>
                    <span className="section_header">
                      {" "}
                      Financial Accounting Ratios
                    </span>
                  </span>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Table responsive className="accounting_ratio">
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>{data.fiscalYear}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td style={{ fontWeight: "bold" }}>
                        {data.fiscalYearEndDate !== null &&
                        data.fiscalYearEndDate !== ""
                          ? moment(data.fiscalYearEndDate).format("MM/DD/YYYY")
                          : ""}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className=""> Receivables Turnover</span>
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.receiveableTurnoverPercent)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className="">Receivables - Number of Days</span>
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(data.receivablesNumberofDays)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    <span className=""> Inventory Turnover</span>
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return <td>{data.inventoryTurnover}</td>;
                  })}
                </tr>

                <tr>
                  <th>Inventory - Number of Days</th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>{Utils.valueFormater(data.inventoryNumberofDays)}</td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Gross Property, Plant & Equipment Turnover</th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.grossPropertyPlantEquipmentTurnoverPercent
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>Net Property, Plant & Equipment Turnover</th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.netPropertyPlantEquipmentTurnoverPercent
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Depreciation, Depletion & Amortization % of Gross Property,
                    Plant & Equipment{" "}
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipmentPercent
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Depreciation, Depletion & Amortization Year to Year Change
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.depreciationDepletionAmortizationYeartoYearChange
                        )}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>
                    Depreciation, Depletion & Amortization Year to Year % Change
                  </th>
                  {this.state.accountingRatioIndData.map((data) => {
                    return (
                      <td>
                        {Utils.valueFormater(
                          data.depreciationDepletionAmortizationYeartoYearChangePercent
                        ) + "%"}
                      </td>
                    );
                  })}
                </tr>
              </Table>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            <Row style={{ padding: "15px", marginTop: "-2em" }}>
              <Col md={12}>
                <Row style={{ padding: "20px 0px 7px 0px" }}>
                  <Col md={8}>
                    <span>
                      <span className="section_header">
                        {" "}
                        Financial Accounting Ratios
                      </span>
                    </span>
                    <br></br>
                  </Col>
                  <Col md={4}></Col>
                </Row>
                <Table responsive className="accounting_ratio">
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year</th>

                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYear}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "bold" }}>Fiscal Year End Date</th>

                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td style={{ fontWeight: "bold" }}>
                          {data.fiscalYearEndDate !== null &&
                          data.fiscalYearEndDate !== ""
                            ? moment(data.fiscalYearEndDate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Receivables Turnover
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Receivables Turnover")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.receiveableTurnoverPercent)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Receivables - Number of Days
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Receivables")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.receivablesNumberofDays)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Inventory Turnover
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Inventory Turnover")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return <td>{data.inventoryTurnover}</td>;
                    })}
                  </tr>

                  <tr>
                    <th>
                      Inventory - Number of Days
                      <GraphIconButton
                        onClick={(e) =>
                          this.getDataInGraphicalForm("Inventory")
                        }
                      ></GraphIconButton>
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(data.inventoryNumberofDays)}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Gross Property, Plant & Equipment Turnover</th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.grossPropertyPlantEquipmentTurnoverPercent
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>Net Property, Plant & Equipment Turnover</th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.netPropertyPlantEquipmentTurnoverPercent
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Depreciation, Depletion & Amortization % of Gross
                      Property, Plant & Equipment{" "}
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.depreciationDepletionAmortizationPercentofGrossPropertyPlantEquipmentPercent
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Depreciation, Depletion & Amortization Year to Year Change
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.depreciationDepletionAmortizationYeartoYearChange
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>
                      Depreciation, Depletion & Amortization Year to Year %
                      Change
                    </th>
                    {this.state.accountingRatioIndData.map((data) => {
                      return (
                        <td>
                          {Utils.valueFormater(
                            data.depreciationDepletionAmortizationYeartoYearChangePercent
                          ) + "%"}
                        </td>
                      );
                    })}
                  </tr>
                </Table>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.showModal}
              toggle={this.toggle}
              size="md"
              className="modalContain"
            >
              <ModalHeader>
                <Row>
                  <Col md={10}>
                    <Label
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        float: "left",
                      }}
                    >
                      Financial Accounting Ratios
                    </Label>
                    <br></br>
                    <span className="unit_str">
                      All Figures in&nbsp;
                      <span>{this.state.unit}</span>&nbsp; of{" "}
                      {this.state.currency}
                    </span>
                  </Col>
                  <Col md={2}>
                    <Fab
                      aria-label="add"
                      className="modal_close_btn"
                      onClick={this.toggle}
                    >
                      <CloseIcon />
                    </Fab>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <SingleAxisGenericGraph
                      graphData={this.state.graphDetails}
                    />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </>
        );
      }
    }
  }
}
